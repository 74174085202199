import React from "react";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

//COMPONENTS
import ViewLayout from "../../components/Layouts/ViewLayout";
import MassiveLoadComponent from "../../components/MassiveLoad/MassiveLoadComponent";
import MassiveLoadInfo from "../../components/MassiveLoad/MassiveLoadInfo";
import MassiveLoadResult from "../../components/MassiveLoad/MassiveLoadResult";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";
import { LoadModal } from "../../components/modal/LoadModal";
import DataTable from "../../components/Table/DataTable";
import { TypeProcess } from "./TypeProcessForm";

//CUSTOM HOOKS
import useTypeProcess from "../../hooks/useTypeProcess";

//SLICES
import { notDataFound } from "./data/notDataFoundTP";
import { columns } from "./data/columnsTP";
import { GetButtons } from "./data/getButtonsTP";

type TypeProcessViewProps = {
  justTable?: boolean;
  nitCompany?: string;
  tabletOnly?: boolean;
};

/**
 * @param { boolean } props.justTable - Indica si se debe mostrar solo la tabla.
 * @param { string } props.nitCompany - El número de identificación tributaria de la empresa.
 * @param { boolean } props.tabletOnly - Indica si el componente debe mostrarse solo en tabla.
 * @returns { React.FC } - Un componente funcional de React.
 */
export const TypeProcessView: React.FC<TypeProcessViewProps> = ({
  justTable,
  nitCompany,
  tabletOnly,
}) => {
  // CUSTOM HOOK
  const {
    advanceSearchState,
    cancel,
    cancelText,
    confirmText,
    confirmate,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    handleBack,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleClick,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnCloseLoadModal,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingTypeProcess,
    messageModal,
    modal,
    onCancel,
    onContinue,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onFinalize,
    onUploadDataEvent,
    option,
    paginatorTypeProcess,
    privilege,
    rol,
    rowsPerPage,
    setFieldsAdvancedSearch,
    setLoad,
    setOption,
    setPage,
    setShowFormAdv,
    setTab,
    showForm,
    showFormAdv,
    sizeTypeProcess,
    tab,
    titleModal,
    typeProcessMassiveResponse,
    typeProcesses,
  } = useTypeProcess({ nitCompany });

  /**
   * Componente que renderiza un DataTable con los registros de los type process
   * @returns { JSX.Element } JSX.Element
   */
  const TabletComponent = (): JSX.Element => {
    return (
      <DataTable
        buttons={GetButtons(rol, privilege)}
        columns={columns(rol, handleConfirmClick)}
        data={typeProcesses}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        havePagination={sizeTypeProcess > 10 ? true : false}
        option={option}
        page={paginatorTypeProcess.paginator.page - 1}
        paginator={paginatorTypeProcess}
        rowsPerPage={rowsPerPage}
        setLoad={setLoad}
        setOption={setOption}
        setPage={setPage}
        setTab={setTab}
        size={sizeTypeProcess}
        handleCellClick={(e, value, column) => {
          if (rol !== "BIOMETRIA RNEC") {
            handleClick(e, value, column);
          }
        }}
        handleFilter={handleFilter}
        handleSort={handleSort}
        notDataFound={
          (paginatorTypeProcess.filter && typeProcesses.length === 0) ||
          advanceSearchState
            ? undefined
            : notDataFound(rol)
        }
        createDisabled={
          privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_CREATE") ===
          undefined
            ? true
            : false
        }
        deleteDiabled={
          privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_DELETE") ===
          undefined
            ? true
            : false
        }
        modifyDiabled={
          privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_MODIFY") ===
          undefined
            ? true
            : false
        }
        readDisabled={
          privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_READ") ===
          undefined
            ? true
            : false
        }
        handleOnOpenAdvSearch={handleOnOpen}
        rol={rol}
        download={onDownloadData}
        setShowFormAdvance={(value: boolean) => {
          setShowFormAdv(value);
          setFieldsAdvancedSearch(fieldsAdvanced);
        }}
        handleOnLoadButton={handleOnLoadButton}
        loading={loadingTypeProcess}
      />
    );
  };
  //#endregion

  return (
    <div>
      <LoadModal
        title={titleModal}
        open={modal}
        handleClose={handleOnCloseLoadModal}
        icon={<img src={imageModal} alt="hands" className={"imageInfo"} />}
        message={messageModal}
        handleConfirm={onFinalize}
        handleCancel={onCancel}
        isConfirm={confirmate}
        confirmText={confirmText}
        cancelText={cancelText}
        isCancel={cancel}
      />
      {/** Formulario de carga masiva */}
      {isLoad ? (
        <MassiveLoadComponent
          loadResult={
            <MassiveLoadResult
              sucessfull={typeProcessMassiveResponse?.sucess}
              items={itemsList}
              replaced={typeProcessMassiveResponse?.replace}
              error={typeProcessMassiveResponse?.wrong}
              onDownloadErrors={onDownloadErrors}
              onContinue={onContinue}
            />
          }
          loadInfo={
            <MassiveLoadInfo
              onDownload={onDownload}
              onUpload={onUploadDataEvent}
              title={
                "Bienvenido, así podrás hacer el cargue de tipos de tramite"
              }
            />
          }
          title={"Cargue masivo de trámites"}
          handleBack={handleBack}
          handleChangeTab={handleChangeTab}
          tab={tab}
        />
      ) : justTable ? ( // mostrar unicamente el DataTable
        <DataTable
          buttons={GetButtons(rol, privilege)}
          columns={columns(rol, handleConfirmClick)}
          data={typeProcesses}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          havePagination={sizeTypeProcess > 10 ? true : false}
          option={option}
          page={paginatorTypeProcess.paginator.page - 1}
          paginator={paginatorTypeProcess}
          rowsPerPage={rowsPerPage}
          setLoad={setLoad}
          setOption={setOption}
          setPage={handleChangePage}
          setTab={setTab}
          size={sizeTypeProcess}
          handleCellClick={(e, value, column) => {
            if (rol !== "BIOMETRIA RNEC") {
              handleClick(e, value, column);
            }
          }}
          handleFilter={handleFilter}
          handleSort={handleSort}
          notDataFound={
            (paginatorTypeProcess.filter && typeProcesses.length === 0) ||
            advanceSearchState
              ? undefined
              : notDataFound(rol)
          }
          createDisabled={
            privilege?.find(
              (privil) => privil === "BIOMETRIA_TRAMITE_CREATE"
            ) === undefined
              ? true
              : false
          }
          deleteDiabled={
            privilege?.find(
              (privil) => privil === "BIOMETRIA_TRAMITE_DELETE"
            ) === undefined
              ? true
              : false
          }
          modifyDiabled={
            privilege?.find(
              (privil) => privil === "BIOMETRIA_TRAMITE_MODIFY"
            ) === undefined
              ? true
              : false
          }
          readDisabled={
            privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_READ") ===
            undefined
              ? true
              : false
          }
          setShowFormAdvance={setShowFormAdv}
          rol={rol}
          download={onDownloadData}
          loading={loadingTypeProcess}
        />
      ) : showForm ? ( // Mostrar formulario de Type Process
        <TypeProcess />
      ) : !tabletOnly ? (
        <>
          {/** Modal de busqueda avanzada */}
          <AdvancedSearchModal
            open={showFormAdv}
            handleOnClose={handleOnClose}
            handleOnSearch={handleOnSearch}
            fieldsAdvancedSearch={fieldsAdvancedSearch}
            fieldsAdvanced={fieldsAdvanced}
          />
          <ViewLayout
            isNew={true}
            isEdit={false}
            headerTitle="Tipo de trámite"
            editOption={true}
          >
            <TabletComponent />
          </ViewLayout>
        </>
      ) : (
        <TabletComponent />
      )}
      <HashDowloandModal />
      <FullLoader open={loadingTypeProcess} viewLoader={true} />
    </div>
  );
};
