import { useEffect, useState } from "react";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from "recharts";

type dataInfo = {
  id: number;
  name: string;
  value: number;
};

interface HitNoHitProps {
  data: dataInfo[];
  colors: string[];
  refreshChart: boolean;
  setRefreshChart: Function;
}
/**
 * Grafica en forma de dona con los valores hit, no hit y novedades
 * @param { dataInfo } data - datos con los que se llena la grafica
 * @param { string[] } colors - colores que tendra cada fracción de la dona
 * @param { boolean } refreshChart - estado booleano que controla el refrescado de la grafica
 * @param { Function } setRefreshChart - función que actualiza el estado anterior
 * @returns { JSX.Element }
 */

const HitNoHitAndNovelties = ({
  data,
  colors,
  refreshChart,
  setRefreshChart,
}: HitNoHitProps) => {
  const classes = useStyles();
  const [pieIndex, setPieIndex] = useState(0);

  const dataWithSmallValues: dataInfo[] = data.map((item) => ({
    ...item,
    id: item.id - 1,
  }));

  const isTransactions = data.some((item) => item.value > 0);

  const withoutData = [{ id: 0, name: "Transacciones", value: 0.1 }];
  /**
   * Retorna un booleano si esta a la derecha o izquierda
   * @param { number } midAngle - Angulo en la cual se encuentra la mitad de la seccion
   * @returns { boolean } validación si se encuentra en la izquierda o en la derecha
   */
  const midAngleIsLeftOrRigth = (midAngle: number): boolean => {
    if (
      (midAngle >= 155 && midAngle <= 205) ||
      (midAngle >= 0 && midAngle <= 25) ||
      (midAngle >= 335 && midAngle <= 360)
    ) {
      return true;
    }
    return false;
  };
  /**
   * @param { any } props - datos enviados por la gráfica.
   * @returns componente que se le implementa a la grafica para mostrar los datosz.
   */
  const renderActiveShape = (props: any): any => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx =
      cx + (outerRadius + (midAngleIsLeftOrRigth(midAngle) ? 5 : 10)) * cos;
    const sy =
      cy + (outerRadius + (midAngleIsLeftOrRigth(midAngle) ? 5 : 10)) * sin;
    const mx =
      cx + (outerRadius + (midAngleIsLeftOrRigth(midAngle) ? 15 : 30)) * cos;
    const my =
      cy + (outerRadius + (midAngleIsLeftOrRigth(midAngle) ? 15 : 30)) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        {window.innerWidth > 600 &&
        window.innerWidth < 850 &&
        midAngleIsLeftOrRigth(midAngle) ? (
          <>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey - 18}
              textAnchor={textAnchor}
              fill="#333"
            >
              Realizadas
            </text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`${Math.round(value)}`}</text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              Rate
            </text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey + 18}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              {`${(percent * 100).toFixed(2)}%`}
            </text>
          </>
        ) : (
          <>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`Realizadas ${Math.round(value)}`}</text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
          </>
        )}
      </g>
    );
  };

  useEffect(() => {
    if ( isTransactions ) {
      let section = dataWithSmallValues.find((item) => item.value > 0);
      setPieIndex(section!.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isTransactions]);

  return (
    <Box height={400} position="relative">
      {!isTransactions ? (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                activeIndex={pieIndex}
                activeShape={renderActiveShape}
                data={withoutData}
                innerRadius={`42%`}
                outerRadius={"50%"}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                onMouseEnter={(e) => setPieIndex(e.payload.id)}
              >
                {withoutData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#CCCCCC" />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            className={classes.refresh}
            onClick={() => setRefreshChart(!refreshChart)}
          >
            <RefreshRoundedIcon />
          </IconButton>
        </>
      ) : (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                activeIndex={pieIndex}
                activeShape={renderActiveShape}
                data={dataWithSmallValues}
                innerRadius={`42%`}
                outerRadius={"50%"}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                onMouseEnter={(e) => setPieIndex(e.payload.id)}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            className={classes.refresh}
            onClick={() => setRefreshChart(!refreshChart)}
          >
            <RefreshRoundedIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  refresh: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default HitNoHitAndNovelties;
