import { useState, useRef, useEffect } from "react";
import { Grid, Box, Typography, Card } from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { useForm } from "react-hook-form";
import { ICaptchaValidation } from "../../store/models/CreateAtdp";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputTinyRounded } from "../../components/Inputs/InputTinyRounded";
import { MESSAGES } from "../../utils/types/const";
import { useStyles } from "./style";
import ReloadIcon from "../../assets/arrow-reload.png";
import SuccessIcon from "../../assets/succes-icon.svg";

type CaptchaProps = {
  isValidated: Function;
  captchaLenght: number;
  textButton?: string;
};

const FormDocumentValidationShema = yup.object().shape({
  txtCaptcha: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required)
    .trim(),
});

type FingerFormDataData = yup.InferType<typeof FormDocumentValidationShema>;
const CaptchaGenerator = ({
  isValidated,
  captchaLenght,
  textButton,
}: CaptchaProps) => {
  const [mainCaptcha, setMainCaptcha] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [captcha, setCaptcha] = useState<string>("captcha");
  const [texto, setTexto] = useState<string>("");
  const [hiddenCaptcha, setHiddenCaptcha] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusCaptcha, setStatusCaptcha] = useState<boolean>(false);
  const [messageStatusCaptcha, setMesageStatusCaptcha] = useState<string>("");
  const [dataList, setDataList] = useState<ICaptchaValidation>({
    captcha: "",
    responseCaptcha: "",
  });
  const canvas = useRef(null);
  const styles = useStyles();

  const { control, handleSubmit } = useForm<FingerFormDataData>({
    mode: "onChange",
    resolver: yupResolver(FormDocumentValidationShema),
    shouldUnregister: false,
  });

  const handleForm = (event: any) => {
    event.preventDefault();
    setDataList({
      ...dataList,
      responseCaptcha: captcha,
      [event.target.name]: event.target.value,
    });
  };

  const genRandomString = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < captchaLenght; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const genCaptcha = () => {
    const code = genRandomString();
    setMainCaptcha(code);
    setTexto(code);
  };

  const CreaIMG = () => {
    const canvas = document.getElementById("canva") as HTMLCanvasElement;
    const ctxCanvas = canvas.getContext("2d");
    var fontSize = "44px",
      fontFamily = "Arial",
      width = 220,
      height = 80;
    //size
    if (ctxCanvas != null) {
      ctxCanvas.canvas.width = width;
      ctxCanvas.canvas.height = height;

      //background color
      ctxCanvas.fillStyle = "WHITE"; // "whitesmoke";
      ctxCanvas.fillRect(0, 0, width, height);

      //puntos de distorsion
      ctxCanvas.setLineDash([9, 12]);
      ctxCanvas.strokeStyle = "white";
      ctxCanvas.lineDashOffset = 100;
      ctxCanvas.beginPath();

      var line;
      for (var i = 0; i < width; i++) {
        line = i * 5;
        ctxCanvas.moveTo(line, 0);
        ctxCanvas.lineTo(0, line);
      }

      ctxCanvas.stroke();
      //text format
      ctxCanvas.direction = "ltr";
      ctxCanvas.font = fontSize + " " + fontFamily;
      //text position
      var x = width / 11;
      var y = (height / 3) * 2;
      //border color text
      ctxCanvas.strokeStyle = "white";
      ctxCanvas.strokeText(texto, x, y);
      //color text
      ctxCanvas.fillStyle = "black";
      ctxCanvas.fillText(texto, x, y);
    }
  };

  const ValidCaptcha = async () => {
    var string1 = mainCaptcha;
    var string2 = document.getElementById("txtCaptcha") as HTMLInputElement;
    if (string2.value !== "") {
      if (string1 === string2.value) {
        setHiddenCaptcha(true);
        isValidated(true);
        genCaptcha();
        CreaIMG();
        setMesageStatusCaptcha("");
      } else {
        setMesageStatusCaptcha("El texto no coincide");
        genCaptcha();
        isValidated(false);
        return false;
      }
    } else {
      setMesageStatusCaptcha("Campo requerido");
    }
  };

  useEffect(() => {
    genCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    CreaIMG();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCaptcha]);

  const SuccessCaptcha = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={SuccessIcon}
          style={{
            width: "80px",
            height: "80px",
            alignSelf: "center",
            margin: "5px",
          }}
          alt="ReloadIcon"
        />
        <Typography className={styles.successAlert}>
          Captcha validado correctamente
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      <div id="dvCaptcha" hidden={hiddenCaptcha}>
        <form onChange={handleForm} onSubmit={handleSubmit((data: any) => {})}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Card>
                <input
                  type="hidden"
                  id="mainCaptcha"
                  name="mainCaptcha"
                  value={mainCaptcha}
                />
                <canvas
                  id="canva"
                  ref={canvas}
                  width={220}
                  height={100}
                  style={{ width: "100%" }}
                />
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} alignContent="center">
              <PrimaryButton
                title={
                  <img
                    src={ReloadIcon}
                    style={{ width: "25px", height: "25px" }}
                    alt="ReloadIcon"
                  />
                }
                props={{ onClick: genCaptcha, style: { maxWidth: "20px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
              <InputTinyRounded
                name="txtCaptcha"
                label="Escriba el texto"
                control={control}
                props={{
                  id: "txtCaptcha",
                }}
              />
              <Typography
                className={
                  statusCaptcha ? styles.successAlert : styles.errorAlert
                }
              >
                {messageStatusCaptcha}
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <PrimaryButton
                title={textButton ? textButton : "Vaidar Captcha"}
                props={{ onClick: ValidCaptcha, style: { height: "40px" } }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      {hiddenCaptcha && isValidated ? <SuccessCaptcha /> : ""}
    </div>
  );
};

export default CaptchaGenerator;
