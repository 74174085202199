import { valueOrDefault } from "../general/utils";

type PaletteProps = {
  logo: string;
  isMobile: boolean;
  paleta?: any;
};

export const Palette = ({ logo, isMobile, paleta }: PaletteProps) => {
  return {
    palette: {
      primary: {
        light: valueOrDefault(paleta.principalColor, "#1D71B8"),
        main: valueOrDefault(paleta.principalColor, "#1D71B8"),
        dark: valueOrDefault(paleta.principalColor, "#1D71B8"),
      },
      secondary: {
        light: valueOrDefault(paleta.secondaryColor, "#FA5A00"),
        main: valueOrDefault(paleta.secondaryColor, "#FA5A00"),
        dark: valueOrDefault(paleta.secondaryColor, "#FA5A00"),
      },
      info: {
        light: "#D5D5D5",
        main: "#00000080",
        dark: "#C1C1C1",
      },
      error: {
        light: "#FA5A00",
        main: "#D80000",
        dark: "#D80000",
      },
    },
    typography: {
      fontFamily: "Muli",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {},
        },
      },
    },
  };
};
