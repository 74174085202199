export const SECURITY_URL = "";
export const BIOMETRICS_URL = "https://qabiometriaback.gse.com.co/Biometrics/api/";
export const REQUIRED_MESSAGE = "Campo requerido";
export const API_AUTHENTICATION_ENDPOINT =
  "https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa";
export const BIOMETRICS_LOGIN = "https://qasecurity-biometria.gse.com.co/";
export const API_LICENSE = "https://qacorelicence.gse.com.co";
export const API_REPOSITORY = "https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/";
export const BIOMETRICS_ATDP_VALIDATION = "https://qabiometriaback.gse.com.co/Biometrics/api/documentAtdpValidation";
export const BASE_URL_WEBSOCKET = "https://finger.gse.com.co:8910/";
export const BASE_URL_WEBSOCKET_LOCAL = "http://127.0.0.1:8080/";
export const BASE_URL_WEBSOCKET_MOVIL = "ws://localhost:8080/";
export const REQUIRED_MESSAGE_EMAIL_ERROR_ATDP =
  "Correo electrónico incorrecto, por favor verifique la dirección de correo electrónico e intente de nuevo";
export const REQUIRED_MESSAGE_EMPTY_FIELD_ATDP =
  "Por favor diligencie el campo de correo electrónico";
export const API_SECURITY_ENDPOINT =
  "https://qacoresecurity.gse.com.co/security/api";
export const ENCRYPT_PHARAGRAPH = "gv5cfnftRUGfX8kckikwWc6db8pQDHdA";
export const FNA_POINT = "902323544";
export const API_APPLICATION_UPDATER = "https://qabiometriaback.gse.com.co/ApplicationUpdater/api";
export const API_SOCKET_DESKTOP_UPDATER = "ws://localhost:11000";
export const DEV_MODE = false;
