import { loginUser } from "@dg-bucaramanga/react-components-dg-qa";
import {
  AuthState,
  setAuth,
  setLoading,
  setTokens,
  setUpdateDesktop,
} from "../slices/AuthSlice";
import { AppThunk } from "../store";
import { AuthUser } from "../types";
import { validateVersion } from "../../services/AuthServices";

export const AuthUserLoginAC =
  (body: AuthUser): AppThunk =>
  (dispatch) => {
    let authState: AuthState = {
      error: { message: "" },
      isAuth: false,
      isLoading: true,
      currentUser: undefined,
      tokens: {
        error: "",
        result: {
          token: "",
          refreshToken: "",
        },
      },
      updateDesktop: {
        Version: "",
        InstallerName: "",
        Update: false,
      },
    };
    dispatch(setLoading(authState));
    loginUser(body)
      .then((response) => {
        if (response?.data) {
          dispatch(setAuth(true));
          dispatch(setTokens(response.data.result));
        }
      })
      .catch((err) => {
        authState.error.message = err;
      })
      .finally(() => {
        authState.isLoading = false;
        dispatch(setLoading(authState));
      });
  };

/**
 * Action Creator que lanza el servicio de verificacion de actualizacion de software y setea la respuesta
 * para enviarla al socket si existe una actualizacion
 * @returns Function
 */
export const ValidateVersionAC = (): AppThunk => (dispatch) => {
  validateVersion().then((response) => {
    if (response.statusCode === 200) {
      dispatch(
        setUpdateDesktop({
          Update: response.result.update,
          Version: response.result.version,
          InstallerName: response.result.installerName,
        })
      );
    }
  });
};
