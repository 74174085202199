import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";

const useStyles = makeStyles((_theme: Theme) => ({
  buttonSuccess: {
    width: "147px",
    height: "40px",
    fontSize: "18px",
    margin: "10px auto",
    display: "flex",
  },
}));

interface Props {
  isOpen: boolean;
  isClose: any;
  htmlProp: string;
}

const PreviewATDP = ({ isOpen, isClose, htmlProp }: Props) => {
  const classes = useStyles();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  var __html = `${htmlProp}`;
  let template = { __html: __html };
  return (
    <Dialog fullWidth={true} maxWidth={maxWidth} open={isOpen}>
      <DialogContent>
        <div dangerouslySetInnerHTML={template}></div>
      </DialogContent>
      <div className={classes.buttonSuccess}>
        <PrimaryButton title={"Aceptar"} props={{ onClick: isClose }} />
      </div>
    </Dialog>
  );
};
export default PreviewATDP;
