export interface IDashboard {
  daily_Comparison_Average: number;
  average_Time_Comparison: string;
}

export interface IBusinessUnitRecord {
  name: string;
  Number_Record: number;
}

export interface IUserRecords {
  name: string;
  Number_Record: number;
}

export interface ITypeProcessCompanyBusinessUnit {
  name_Businessunit: string;
  name_Type_process: string;
  number_Record: number;
}

export interface IRecordCompany {
  names: string;
  number_Record: number;
}

export interface ITypeProcessCompany {
  name_Company: string;
  name_TypeProcess: string;
  number_Record: number;
}

export interface IAverageCompany {
  company: string;
  name_Company: string;
  average: number;
}

export interface IPlanExpired {
  documentType: string;
  documentNumber: string;
  customerName: string;
  effectiveDate: string;
  expirationDate: string;
}

export interface ICustomerConsumption {
  total: number;
  usage: number | null;
  usagePercentage: number;
  effectiveDate: string;
  expirationDate: string;
  serviceFeatureCode?: string;
}

export interface INextBalanceToExpire {
  documentType: string;
  documentNumber: string;
  customerName: string;
  quantityTx: number;
  quantityUsed: number;
  usagePercentage: number;
  effectiveDate: string;
  expirationDate: string;
}

export interface ProgressBarProps {
  done: string;
  value?: string;
  title: string;
  onlyBars?: boolean;
  multipleColor?: boolean;
  green?: string;
  yellow?: string;
  red?: string;
  withTooltip?: boolean;
}

export interface DashboardAverage {
  daily_Comparison_Average: string;
  average_Time_Comparison_min: string;
  average_Time_Comparison_seg: string;
}

export const defaultDashboardAverage: DashboardAverage = {
  average_Time_Comparison_min: "0",
  average_Time_Comparison_seg: "0",
  daily_Comparison_Average: "0",
};

export interface DataCompaniesForChart {
  name: string;
  value: number;
  label?: string;
}

export interface DataCotejo {
  name: string;
  totalBolsa: number;
  consumo: number;
  width: number;
  fontSizeConsumo: number;
  fontSizeTotal: number;
  percentage: number;
  expirationDate: string;
}

export interface CustomerConsumption {
  DocumentType?: string;
  DocumentNumber?: string;
  ServiceName: string;
  QuantityCustomer?: number;
  EffectiveDate?: string;
  Total?: number;
  SwitchCompany?: boolean;
}

export interface SuperAdminConsumption {
  SwitchCompany: boolean;
  Role: string | undefined;
}

export interface ITypeProcessByUser {
  name_TypeProcess: string;
  number_Record: number;
}

export interface INotification {
  title: string;
  description: string;
  subtitle: string;
  status: number;
  date: Date | any;
}

export interface SelectOption {
  key: number;
  value: string | number;
  label: string;
}

export interface IDoughnutTransaction {
  id: number;
  name: string;
  value: number;
}
export interface IVerticalBarTransaction {
  fullDate: string;
  hit: number;
  noHit: number;
  novedades: number;
  name?: string;
  date?: string;
}

export interface DashboardTransactionsRequest {
  StartDate?: string;
  EndDate?: string;
  Comparative: number;
  BusinessUnitId: number;
  SwitchCompany: boolean;
}

export interface ValidateCountersNit {
  documentNumber: string;
  valid: boolean;
}
