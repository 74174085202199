import moment from "moment";
import { Column } from "../../../components/Table/types";

export const columns = (
  rol: string,
  handleConfirmClick: Function
): Column[] => {
  return rol === "BIOMETRIA RNEC"
    ? [
        {
          id: "id",
          label: "Numero de trámite",
          name: "Numero de Tramite",
          align: "center",
          haveDropDown: true,
        },
        {
          id: "code",
          label: "Código",
          name: "Codigo",
          align: "center",
          haveDropDown: true,
        },
        {
          id: "name",
          label: "Tipo de trámite",
          name: "Nombre Tramite",
          minWidth: 140,
          align: "center",
          haveDropDown: true,
        },
        {
          id: "createdon",
          label: "Fecha",
          minWidth: 140,
          align: "center",
          haveDropDown: true,
          format: (value: string) =>
            moment(value).format("DD/MM/YYYY, H:mm:ss"),
        },
      ]
    : [
        {
          id: "id",
          label: "Numero de trámite",
          name: "Numero de Tramite",
          align: "center",
          haveDropDown: true,
        },
        {
          id: "code",
          label: "Código",
          name: "Codigo",
          align: "center",
          haveDropDown: true,
        },
        {
          id: "name",
          label: "Tipo de trámite",
          name: "Nombre Tramite",
          minWidth: 140,
          align: "center",
          haveDropDown: true,
        },
        {
          id: "createdon",
          label: "Fecha",
          minWidth: 140,
          align: "center",
          haveDropDown: true,
          format: (value: string) =>
            moment(value).format("DD/MM/YYYY, H:mm:ss"),
        },
        {
          id: "state",
          label: "Estado",
          align: "center",
          haveDropDown: true,
          component: {
            handleChange: (e: any, value: any) => handleConfirmClick(e, value),
            tooltipMessage: "Tipo proceso",
            withIcon: false,
            wordLength: 60,
            handleIconClick: () => {},
          },
        },
      ];
};
