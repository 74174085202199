import { SvgIcon } from "@material-ui/core";

export const AtdpErrorMailSend = (props: any) => (
  <SvgIcon {...props} style={{width:props.width,height:props.width}} viewBox ="0 0 90 90">
    <g id="Trazado_2483" data-name="Trazado 2483" transform="translate(0 43.841) rotate(-45)" fill="#fff">
      <path d="M 60 60.5 L 2 60.5 C 1.172899961471558 60.5 0.5 59.82709884643555 0.5 59 L 0.5 2 C 0.5 1.186126351356506 1.151559233665466 0.5215606689453125 1.960449576377869 0.5005143880844116 L 51.43027114868164 8.978099822998047 L 51.47219085693359 8.98528003692627 L 51.51472091674805 8.98528003692627 C 52.34181976318359 8.98528003692627 53.01472091674805 9.658180236816406 53.01472091674805 10.48528003692627 L 53.01472091674805 10.52867984771729 L 53.02220153808594 10.57141971588135 L 61.49946594238281 59.04031753540039 C 61.47802734375 59.84885787963867 60.81362152099609 60.5 60 60.5 Z" stroke="none"/>
      <path d="M 1.929351806640625 1.002468109130859 C 1.410804748535156 1.038848876953125 1 1.472358703613281 1 2 L 1 59 C 1 59.55139923095703 1.448600769042969 60 2 60 L 60 60 C 60.52716827392578 60 60.96037292480469 59.58999633789062 60.99742889404297 59.07210540771484 L 52.51472091674805 10.57207107543945 L 52.51472091674805 10.48527908325195 C 52.51472091674805 9.933879852294922 52.06612014770508 9.485279083251953 51.51472091674805 9.485279083251953 L 51.42966079711914 9.485279083251953 L 1.929351806640625 1.002468109130859 M 2 0 L 51.51472091674805 8.485279083251953 C 52.61927795410156 8.485279083251953 53.51472091674805 9.380710601806641 53.51472091674805 10.48527908325195 L 62 59 C 62 60.10456848144531 61.10456848144531 61 60 61 L 2 61 C 0.8954315185546875 61 0 60.10456848144531 0 59 L 0 2 C 0 0.8954315185546875 0.8954315185546875 0 2 0 Z" stroke="none" fill="#3f3d56"/>
    </g>
    <path id="Trazado_2484" data-name="Trazado 2484" d="M42.454,2.642,3.989,30.295,42.454,47.2l39.1-16.9Z" transform="translate(1.134 13.596)" fill="#e6e6e6"/>
    <g id="Rectángulo_1386" data-name="Rectángulo 1386" transform="translate(19.134 17.619)" fill="#fff" stroke="#909090" strokeWidth="1">
      <rect width="49" height="51" rx="2" stroke="none"/>
      <rect x="0.5" y="0.5" width="48" height="50" rx="1.5" fill="none"/>
    </g>
    <path id="Trazado_2481" data-name="Trazado 2481" d="M398.425,387.837h-.03L381.385,395.1,358.11,405.04a.75.75,0,0,1-.575,0l-24.013-9.962-17.429-7.231-.027-.012h-.03A1.038,1.038,0,0,0,315,388.875v45.047a1.038,1.038,0,0,0,1.037,1.037h82.388a1.038,1.038,0,0,0,1.037-1.037V388.875A1.038,1.038,0,0,0,398.425,387.837Z" transform="translate(-313.866 -345.543)" fill="#fff" stroke="#3f3d56" strokeWidth="1"/>
    <line transform="translate(27.5 25) " x1="5" y1="5" x2="25" y2="25" strokeWidth="8" stroke="#FF0000" />
    <line transform="translate(27.5 25) " x1="5" y1="25" x2="25" y2="5" strokeWidth="8" stroke="#FF0000" />
    <path id="Trazado_2477" data-name="Trazado 2477" d="M373.844,640.4h-19.18a.9.9,0,1,1,0-1.792h19.18a.9.9,0,1,1,0,1.792Z" transform="translate(-346.89 -559.155)" fill="#FF0000"/>
    <path id="Trazado_2478" data-name="Trazado 2478" d="M361.545,614.4h-6.882a.9.9,0,0,1,0-1.792h6.882a.9.9,0,0,1,0,1.792Z" transform="translate(-346.89 -537.008)" fill="#FF0000"/>
  </SvgIcon>
);
