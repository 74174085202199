import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { IFingerUnit, IDataFinger } from "../../store/models/FingerPrintModel";
import CheckCirclefrom from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { useSelector } from "react-redux";
import { FingerPrintSelector } from "../../store/slices/FingerPrintSlice";
import { Slide, SlideProps } from "@material-ui/core";
import useSocket from "../../hooks/useSocket";
const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

interface Props {
  isOpen: any;
  isClose: any;
  arrayFinger?: IFingerUnit[];
  nextParticipant: any;
  dataParticipant?: IDataFinger | null;
  sizeParticipant: number;
  index: number;
  informationMessage: string;
}

interface PropsValidateData {
  arrayFingerData?: IFingerUnit[];
  dataParticipantData?: IDataFinger | null;
  responseCaptureProp?: any;
  resultMessage: string;
}

export const ValidateData = ({
  arrayFingerData,
  dataParticipantData,
  responseCaptureProp,
  resultMessage,
}: PropsValidateData) => {
  const classes = useStyles();
  const { returnIdFinger, returnValidationCheck } = useSocket();
  const formatDate = (date: string | null | undefined) => {
    if (date) {
      let newDate = new Date(date);
      let result = newDate.toLocaleString();
      return result;
    } else {
      return null;
    }
  };

  return (
    <>
      <Typography className={classes.subtitle}>{`Hits`}</Typography>
      <div className={classes.firstSection}>
        {arrayFingerData?.map((item) => {
          return (
            <div key={item.value} className={classes.resultContainer}>
              <Typography
                className={classes.text}
              >{`${item.finger} mano ${item.hand}`}</Typography>
              {returnValidationCheck(
                returnIdFinger(item.value),
                responseCaptureProp
              ) ? (
                <CheckCirclefrom className={classes.iconSuccessValidation} />
              ) : (
                <CancelIcon className={classes.iconFailValidation} />
              )}
            </div>
          );
        })}
      </div>
      <div className={classes.secondSection}>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Nut:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${responseCaptureProp?.nut}`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Fecha inicio:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${formatDate(responseCaptureProp?.initialDate)}`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Fecha fin:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${formatDate(responseCaptureProp?.finaldate)}`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Código interno:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${responseCaptureProp?.processId}`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Nombre trámite:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${responseCaptureProp?.nameTypeProcess}`}
          </Typography>
        </div>
      </div>
      <div className={classes.secondSection}>
        <Typography className={classes.subtitle}>
          {`Datos del participante`}
        </Typography>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Tipo de documento:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${dataParticipantData?.documentType}`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`No. de documento:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;
            {`${
              responseCaptureProp?.candidate
                ? responseCaptureProp?.candidate?.nuip
                : dataParticipantData?.documentNumber
            }`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Nombres:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;
            {`${
              responseCaptureProp?.candidate
                ? responseCaptureProp?.candidate?.firstName1
                : dataParticipantData?.nameuser
            }`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Apellidos:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;
            {`${
              responseCaptureProp?.candidate
                ? responseCaptureProp?.candidate?.lastName1
                : dataParticipantData?.surname
            }`}
          </Typography>
        </div>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Vigencia:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;
            {`${
              responseCaptureProp?.candidate
                ? responseCaptureProp?.candidate?.validity
                : ""
            }`}
          </Typography>
        </div>
      </div>
      <div className={classes.secondSection}>
        <Typography className={classes.subtitle}>
          {`Resultado Validacion`}
        </Typography>
        <div className={classes.pharagraph}>
          <Typography
            className={classes.secondText}
            style={{ fontWeight: "bold" }}
          >{`Respuesta registraduria:`}</Typography>
          <Typography className={classes.secondText}>
            &nbsp;{`${resultMessage}`}
          </Typography>
        </div>
      </div>
    </>
  );
};

const ModalSuccess = ({
  isOpen,
  isClose,
  arrayFinger,
  nextParticipant,
  dataParticipant,
  sizeParticipant,
  index,
  informationMessage,
}: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const classes = useStyles();
  // selector REDUX
  const { responseCapture } = useSelector(FingerPrintSelector);
  // custom hook
  const { saveInfoParticipant } = useSocket();

  const handleNextParticipant = () => {
    saveInfoParticipant(dataParticipant, arrayFinger, informationMessage);
    nextParticipant();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Typography className={classes.title}>
          {`Validación de identidad ante la Registraduría Nacional del Estado Civil`}
        </Typography>
        <ValidateData
          arrayFingerData={arrayFinger}
          dataParticipantData={dataParticipant}
          responseCaptureProp={responseCapture}
          resultMessage={informationMessage}
        />
        <div className={classes.buttonContainer}>
          <div style={{ width: "300px", height: "40px" }}>
            <PrimaryButton
              title={
                index < sizeParticipant && sizeParticipant > 1
                  ? "Siguiente participante"
                  : index === sizeParticipant && sizeParticipant > 1
                  ? "Ver Detalles"
                  : "Iniciar otro tramite"
              }
              props={{
                onClick: handleNextParticipant,
                endIcon: <ArrowForwardIos className={classes.iconButton} />,
              }}
            ></PrimaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((_theme: Theme) => ({
  title: {
    font: "normal normal bold 18px/23px Muli",
    color: "#FA5A00",
    marginBottom: "31px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 30,
  },
  subtitle: {
    font: "normal normal bold 16px/20px",
    color: "#1D71B8",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "start",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,

    [_theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
    },
    "& div:nth-child(1)": {
      width: "132px",
      height: "40px",
      [_theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    "& div:nth-child(2)": {
      width: "300px",
      height: "40px",
      [_theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
  },
  firstSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "10px",
    margin: "0 0 20px 0",
    borderBottom: "1px solid #00000080",
    flexWrap: "wrap",
    justifyContent: "space-between",

    [_theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  secondSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingBottom: "10px",
    margin: "0 0 20px 0",
    borderBottom: "1px solid #00000080",
  },
  text: {
    font: "normal normal bold 14px/18px Muli",
    color: "#575757",
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 9,
    textAlign: "center",
    height: "50%",
  },
  resultContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconFailValidation: {
    color: "#e80e07",
    fontSize: "32px",
    [_theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  iconSuccessValidation: {
    color: "#5FBE2C",
    fontSize: "32px",
    [_theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  secondText: {
    font: "normal normal 14px/18px Muli",
    color: "#575757",
    height: "25px",
  },
  pharagraph: {
    display: "flex",
  },
  iconButton: {
    fontSize: "25px !important",
  },
  thirdSection: {
    paddingBottom: "10px",
    margin: "0 0 20px 0",
    color: "#009BDD",
    display: "flex",
    alignItems: "center",
  },
}));

export default ModalSuccess;
