import React from "react";
import DataTable from "../../components/Table/DataTable";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { Fab, Grid, makeStyles, Tooltip, Checkbox } from "@material-ui/core";
import { ButtonsProps, Column } from "../../components/Table/types";
import {
  CustomTypography,
  FullLoader,
} from "@dg-bucaramanga/react-components-dg-qa";
import { NoDeviceIcon } from "../../components/Icons/NoDeviceIcon";
import { AtdpFileDownload } from "../../components/Icons/AtdpFileDownload";
import { AtdpFileSend } from "../../components/Icons/AtdpFileSend";
import { AtdpMailSend } from "../../components/Icons/AtdpMailSend";
import AlertInput from "../../components/Alert/AlertInput";
import EmailResponseTable from "./components/EmailResponseModal";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import SendEmailModal from "./components/SendEmailModal";
import { AtdpErrorMailSend } from "../../components/Icons/AtdpErrorMailSend";
import useSearchAtdp from "../../hooks/useSearchAtdp";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import moment from "moment";
import { advanceSearchHasValues } from "../../utils/general/utils";

type SearchAtdpViewProps = {
  justTable?: boolean;
  handleRowClick?: Function;
  nitCompany?: string;
  tabletOnly?: boolean;
};

/**
 * @param { string } props.nitCompany - El número de identificación tributaria de la empresa.
 * @param { boolean } props.tabletOnly - Indica si el componente debe mostrarse solo en tabla.
 * @returns { React.FC } - Un componente funcional de React.
 */

const AtdpsView: React.FC<SearchAtdpViewProps> = ({
  nitCompany,
  tabletOnly,
}) => {
  const classes = useStyles();
  // CUSTOM HOOK
  const {
    confirmSend,
    textModalSendEmail,
    handleGetBase64,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleAllActiveCheckbox,
    loadingAtdp,
    forShippingCode,
    finaMail,
    finaMailMany,
    setFinaMailMany,
    setFinaMail,
    setForShippingCode,
    modal,
    activeCheckbox,
    setModal,
    throwBase64,
    throwBase64Email,
    setIsOpenModalEmail,
    activeAllCheckbox,
    listAtdp,
    setActiveAllCheckbox,
    checkboxListChecks,
    sizeAtdp,
    option,
    setOption,
    setPage,
    handleFilter,
    handleSort,
    paginatorAtdp,
    onDownloadData,
    isOpenModalEmail,
    setUpdateEmail,
    updateEmail,
    cancelSend,
    loading,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    setFieldsAdvancedSearch,
    setShowFormAtdp,
    showFormAtdp,
    rol,
    handleOnClose,
    handleOnSearch,
    handleOnOpen,
  } = useSearchAtdp({ nitCompany: nitCompany });

  //#region columnas de la tabla

  const columns: Column[] = [
    {
      id: "checkbox",
      label: "",
      minWidth: 70,
      align: "center",
      haveDropDown: false,
      children: (
        rt: [],
        currentAtdpItem = { stateChecked: false },
        index = 0
      ) => {
        return (
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
            direction="row-reverse"
            justifyContent="center"
          >
            <Checkbox
              color="primary"
              checked={currentAtdpItem.stateChecked}
              onClick={() => {
                activeCheckbox(index, currentAtdpItem.stateChecked);
              }}
            />
          </Grid>
        );
      },
    },
    {
      id: "names",
      label: "Nombres",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "surnames",
      label: "Apellidos",
      minWidth: 130,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "document",
      label: "No. Documento",
      minWidth: 130,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "email",
      label: "Correo Electronico",
      minWidth: 130,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "nut",
      label: "Nut",
      minWidth: 130,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "date",
      label: "Fecha y Hora",
      minWidth: 110,
      align: "center",
      haveDropDown: true,
      format: (value: string) => moment(value).format("DD/MM/YYYY, H:mm:ss"),
    },
    {
      id: "download",
      label: "",
      minWidth: 40,
      align: "center",
      haveDropDown: false,
      children: (
        rt = [],
        currentAtdpItem = {
          atdpName: "",
          route: "",
          repository: "",
          stateChecked: false,
        }
      ) => {
        return (
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
            direction="row-reverse"
            justifyContent="center"
          >
            <Tooltip title={`Descargar`} aria-label={`Descargar`}>
              <Fab
                className={classes.styleFab}
                size="small"
                style={{
                  display: currentAtdpItem.stateChecked ? "none" : "table-cell",
                }}
                onClick={() => {
                  /**
                    Se valida que la información del atdp este completa
                    para permitirle activar el checkbox y poder generar el base64
                   */
                  if (
                    currentAtdpItem.atdpName !== "" &&
                    currentAtdpItem.route !== "" &&
                    Number(currentAtdpItem.repository) >= 0
                  ) {
                    let flag = {
                      Location: currentAtdpItem.route,
                      Name: currentAtdpItem.atdpName,
                      Repository: currentAtdpItem.repository,
                    };
                    throwBase64(flag, currentAtdpItem.atdpName);
                  } else {
                    setModal({
                      state: true,
                      message:
                        "¡Falta información para descargar documento ATDP!",
                      icon: <NoDeviceIcon />,
                    });
                  }
                }}
              >
                <AtdpFileDownload />
              </Fab>
            </Tooltip>
          </Grid>
        );
      },
    },
    {
      id: "AtdpId",
      label: "",
      minWidth: 40,
      align: "center",
      haveDropDown: false,
      children: (
        rt = [],
        currentAtdpItem = {
          atdpId: 0,
          atdpName: "",
          email: "",
          names: "",
          surnames: "",
          route: "",
          repository: "",
          stateChecked: false,
        }
      ) => {
        return (
          rol !== "BIOMETRIA RNEC" && (
            <Grid
              container
              item
              lg={2}
              md={2}
              sm={12}
              xs={12}
              direction="row-reverse"
              justifyContent="center"
            >
              <Tooltip title={`Enviar`} aria-label={`Enviar`}>
                <Fab
                  className={classes.styleFab}
                  size="small"
                  style={{
                    display: currentAtdpItem.stateChecked
                      ? "none"
                      : "table-cell",
                  }}
                  onClick={() => {
                    /**
                      Se valida que la información del atdp este completa
                      para permitirle activar el checkbox y poder generar el base64
                     */
                    if (
                      currentAtdpItem.atdpName !== "" &&
                      currentAtdpItem.route !== "" &&
                      Number(currentAtdpItem.repository) >= 0
                    ) {
                      //Actualiza estado con la información del atdp seleccionado activado individualmente.
                      setForShippingCode([
                        {
                          atdpId: currentAtdpItem.atdpId,
                          atdpName: currentAtdpItem.atdpName,
                          email: currentAtdpItem.email,
                          names: currentAtdpItem.names,
                          surnames: currentAtdpItem.surnames,
                          route: currentAtdpItem.route,
                          repository: currentAtdpItem.repository,
                        },
                      ]);
                      let flag = {
                        Location: currentAtdpItem.route,
                        Name: currentAtdpItem.atdpName,
                        Repository: currentAtdpItem.repository,
                      };
                      throwBase64Email(flag, currentAtdpItem);
                      setIsOpenModalEmail(true);
                    } else {
                      setModal({
                        state: true,
                        message:
                          "¡Falta información para enviar por correo el documento ATDP!",
                        icon: <AtdpErrorMailSend width="90" height="90" />,
                      });
                    }
                  }}
                >
                  <AtdpFileSend />
                </Fab>
              </Tooltip>
            </Grid>
          )
        );
      },
    },
  ];
  //#endregion

  //#region botones de la tabla
  const getButtons = () => {
    let button: ButtonsProps = {
      searchBar: {
        cellSize: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      },
      menuOrder: {
        justify: "center",
        options: [
          {
            label: "A – Z",
            columns: [
              {
                prop: "names",
              },
            ],
          },
          {
            label: "Z – A",
            columns: [
              {
                prop: "names",
                direction: -1,
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "date",
                direction: 1,
                format: (date: any) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "date",
                direction: -1,
                format: (date: any) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          xs: 12,
          sm: 12,
          md: 2,
          lg: 2,
        },
      },
      justify: "flex-start",
      exportButton: {
        cellSize: {
          xs: 12,
          sm: 12,
          md: 2,
          lg: 2,
        },
        fileName: "Listado de ATDP",
      },
    };
    return button;
  };

  /**
   * Retorna componente con mensaje cuando no encuentra o no tiene atdps
   * @param { boolean } today - fecha del dia de hoy.
   * @returns { JSX.Element } Mensaje cuando no hay atdps
   */

  const notDataFound = (today: boolean = false) => {
    return (
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
        >
          <NoDeviceIcon />
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
        >
          <CustomTypography
            type="definition"
            children={`Aún no se han creado ATDP${
              today ? " para el día de hoy." : "."
            }`}
          />{" "}
        </Grid>
      </Grid>
    );
  };
  //#endregion

  //#region botones fuera de la tabla

  const getButtonsOutside = [
    {
      id: "checkbox",
      key: "0",
      children: () => {
        return (
          <Checkbox
            color="primary"
            checked={activeAllCheckbox}
            style={{ display: listAtdp.length === 0 ? "none" : "table-cell" }}
            onClick={() => {
              // Se valida el estado del checkbox para activar o desactivar todos los atdps.
              if (!activeAllCheckbox) {
                handleAllActiveCheckbox(false);
              } else {
                handleAllActiveCheckbox(true);
                setModal({ state: false, message: "", icon: <></> });
              }
              setActiveAllCheckbox(!activeAllCheckbox);
            }}
          />
        );
      },
    },
    {
      id: "download",
      key: "1",
      children: () => {
        return (
          <Tooltip title={`Descargar`} aria-label={`Descargar`}>
            <Fab
              className={classes.styleFab}
              size="small"
              style={{
                display:
                  checkboxListChecks.length === 0 ? "none" : "table-cell",
              }}
              onClick={() => {
                // Se prepara la estructura para generar el base 64 para su respectiva descarga del atdp.
                checkboxListChecks.map(
                  // eslint-disable-next-line array-callback-return
                  (item: { route: any; atdpName: string; repository: any }) => {
                    let ban = {
                      Location: item.route,
                      Name: item.atdpName,
                      Repository: item.repository,
                    };
                    throwBase64(ban, item.atdpName);
                  }
                );
              }}
            >
              <AtdpFileDownload />
            </Fab>
          </Tooltip>
        );
      },
    },
    {
      id: "AtdpId",
      key: "2",
      children: () => {
        return (
          rol !== "BIOMETRIA RNEC" && (
            <Tooltip title={`Enviar`} aria-label={`Enviar`}>
              <Fab
                className={classes.styleFab}
                size="small"
                style={{
                  display:
                    checkboxListChecks.length === 0 ? "none" : "table-cell",
                }}
                onClick={() => {
                  handleGetBase64();
                }}
              >
                <AtdpFileSend />
              </Fab>
            </Tooltip>
          )
        );
      },
    },
  ];

  /**
   * Componente que renderiza un DataTable con los registros de los type process
   * @returns { JSX.Element } JSX.Element
   */

  const TabletComponent = (): JSX.Element => {
    return (
      <DataTable
        checkbox={getButtonsOutside}
        columns={columns}
        buttons={getButtons()}
        data={listAtdp}
        havePagination={sizeAtdp > 10 ? true : false}
        option={option}
        setOption={setOption}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={paginatorAtdp.paginator.page - 1}
        paginator={paginatorAtdp}
        rowsPerPage={paginatorAtdp.paginator.itemsPage}
        setPage={setPage}
        size={sizeAtdp}
        handleFilter={handleFilter}
        handleSort={handleSort}
        handleCellClick={(
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          value: any,
          _column: Column
        ) => handleCellClick(e, value, _column)}
        notDataFound={
          paginatorAtdp!.filter!.length < 3 &&
          !advanceSearchHasValues(fieldsAdvancedSearch, "CreationDate") &&
          listAtdp.length === 0
            ? notDataFound(true)
            : (paginatorAtdp!.filter!.length > 3 && listAtdp.length === 0) ||
              (advanceSearchHasValues(fieldsAdvancedSearch, "CreationDate") &&
                listAtdp.length === 0)
            ? undefined
            : notDataFound()
        }
        download={onDownloadData}
        setShowFormAdvance={(value: boolean) => {
          setShowFormAtdp(value);
          setFieldsAdvancedSearch(fieldsAdvanced);
        }}
        handleOnOpenAdvSearch={handleOnOpen}
        loading={loading || loadingAtdp}
      />
    );
  };
  //#endregion

  return (
    <div>
      {/** Modal de busqueda avanzada */}
      <AdvancedSearchModal
        open={showFormAtdp}
        handleOnClose={handleOnClose}
        handleOnSearch={handleOnSearch}
        fieldsAdvancedSearch={fieldsAdvancedSearch}
        fieldsAdvanced={fieldsAdvanced}
      />
      {!tabletOnly ? (
        <ViewLayout
          isNew={true}
          isEdit={false}
          headerTitle="Consulta ATDP"
          editOption={true}
        >
          <TabletComponent />
        </ViewLayout>
      ) : (
        <TabletComponent />
      )}
      <AlertInput
        isOpen={isOpenModalEmail}
        icon={<AtdpMailSend width="90" height="90" />}
        bodyText={textModalSendEmail()}
        input={
          <>
            {/* Modal que renderiza los campos de texto con los correos para actualización. */}
            <SendEmailModal
              sendEmailIndividual={forShippingCode}
              setSendEmailIndividual={setForShippingCode}
              sendEmailMultiple={updateEmail}
              setSendEmailMultiple={setUpdateEmail}
              onCancelClick={cancelSend}
              onConfirmClick={confirmSend}
              onClose={setIsOpenModalEmail}
              confirmationButtonText={"Enviar"}
              cancelButtonText={"Cancelar"}
            />
          </>
        }
        showBtnClose={false} // Si hay necesidad de habilitar la x volverlo true
        onClose={setIsOpenModalEmail}
      />
      {/* Modal que contiene icono y mensaje según el estado de la respuesta del envio del atdp. */}
      <AlertIconModal
        open={modal.state}
        handleClose={() => {
          setModal({ ...modal, state: false });
        }}
        icon={modal.icon}
        message={modal.message}
      />
      {loading ? (
        <FullLoader open={loading} viewLoader={true} />
      ) : !loading && !loadingAtdp ? (
        <>
          {/* Tabla con la respuesta del envio de los atdps enviados. */}
          <EmailResponseTable
            finaMail={loadingAtdp ? false : finaMail}
            finaMailMany={loadingAtdp ? false : finaMailMany}
            setForShippingCode={setForShippingCode}
            setStateOpenMail={setFinaMail}
            setStateOpenMailMany={setFinaMailMany}
            forShippingCode={forShippingCode}
          />
        </>
      ) : (
        <FullLoader open={loadingAtdp} viewLoader={true} />
      )}
    </div>
  );
};

export default AtdpsView;

const useStyles: any = makeStyles(() => ({
  styleFab: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));
