import { useSelector } from "react-redux";

import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";

import { PersonalizationSelector } from "../../store/slices/PersonalizationSlice";
type SecondaryButtonProps = {
  title: string;
  props?: ButtonProps;
};

export const SecondaryButton = ({ title, props }: SecondaryButtonProps) => {
  const { buttonColor } = useSelector(PersonalizationSelector);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      border: `1px solid ${
        !!buttonColor ? buttonColor : theme.palette.primary.main
      }`,
      borderRadius: "10px",
      opacity: 1,
      fontSize: "18px",
      fontWeight: 600,
      color: !!buttonColor ? buttonColor : theme.palette.primary.main,

      transition: "border, color, opacity .25s ease",

      "&:hover": {
        border: `1px solid ${
          !!buttonColor ? buttonColor : theme.palette.primary.main
        }`,
        color: !!buttonColor ? buttonColor : theme.palette.primary.main,
        opacity: ".8",
      },

      "&:disabled": {
        border: `1px solid ${
          !!buttonColor ? buttonColor : theme.palette.primary.main
        }!important`,
        boxShadow: "0px 4px 3px #0000004D",
        borderRadius: "10px",
        opacity: 0.5,
        color: `${
          !!buttonColor ? buttonColor : theme.palette.primary.main
        }!important`,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      fullWidth
      classes={{
        root: classes.root,
      }}
      {...props}
    >
      {title}
    </Button>
  );
};
