import { Grid, makeStyles, Typography } from "@material-ui/core";
import Marquee from "../Marquee";
import { LinearBar } from "./LinearBar";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  IPlanExpired,
  DataCompaniesForChart,
  DataCotejo,
} from "../../store/models/DashboardModel";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { HorizontalChartBar } from "./ChartBar";

type LinearPercentProps = {
  elements: DataCompaniesForChart[];
  withTooltip: boolean;
};
type HorizontalChartProps = {
  elements: DataCompaniesForChart[];
};

/**
 * @description Este componente toma un arreglo de datos con el tipo LinearPercentProps recorre con map para devolver elementos independientes de una barra horizontal
 * @param {LinearPercentProps} elements
 * @returns {JSX.Element} Elemento JSX que devuelve uno o varios LinearBar
 */
export const LinearPercentBar = ({ elements, withTooltip }: LinearPercentProps) => {
  const element = elements?.map((process) => {
    const biggerValue = Math.max(...elements.map((data) => data.value));
    const percent =
      process.value === biggerValue
        ? process.value > 0
          ? 90
          : 0
        : Math.round((process.value * 100) / biggerValue);

    return (
      <LinearBar
        done={percent.toString()}
        title={process.name}
        value={process.value.toLocaleString("de-DE")}
        withTooltip={withTooltip}
      />
    );
  });
  return <>{element}</>;
};

/**
 * @description Este componente toma un arreglo de datos con el tipo LinearPercentProps recorre con map para devolver elementos independientes de una barra horizontal con un label
 * @param {LinearPercentProps} elements
 * @returns {JSX.Element} Elemento JSX que devuelve uno o varios LinearBar con un label tipo Marquee
 */
export const LinearPercentLabel = ({ elements, withTooltip }: LinearPercentProps) => {
  const classes = useStyles();
  const element = elements?.map((process) => {
    const biggerValue = Math.max(...elements.map((data) => data.value));
    const percent =
      process.value === biggerValue
        ? process.value > 0
          ? 90
          : 0
        : Math.round((process.value * 100) / biggerValue);

    return (
      <>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.mg0} key={`title-${process.label}`}>
          <Marquee>{process.label}</Marquee>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.mg0}  key={`value-${process.label}`}>
          <LinearBar
            done={percent.toString()}
            title={process.name}
            value={process.value.toLocaleString("de-DE")}
            withTooltip={withTooltip}
          />
        </Grid>
      </>
    );
  });

  return (
    <Grid container spacing={1} className={classes.conteinetGrid}>
      {element}
    </Grid>
  );
};

/**
 * @description Este componente toma un arreglo de datos con el tipo IPlanExpired recorre con map para devolver elementos independientes de una lista con el nombre de la empresa y la fecha de expiracion
 * @param {IPlanExpired[]} elements - Los elementos hacen referencia a una lista de el tipo IPlanExpired
 * @returns {JSX.Element} Elemento JSX que devuelve un Grid de un ancho de 12 donde se imprime el nombre de la empresa y la fecha de expiracion
 */
export const ContentList = ({ elements }: { elements: IPlanExpired[] }): JSX.Element => {
  const classes = useStyles();
  const element = elements?.map((company) => {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography style={{ display: "flex", alignItems: "center" }}>
          <ArrowRightIcon color="secondary" />
          {`${company.customerName}`}
          {company.expirationDate != "" ? `: ${company.expirationDate}` : ""}
        </Typography>
      </Grid>
    );
  });
  return (
    <Grid container spacing={1} className={classes.containerList}>
      {element}
    </Grid>
  );
};

/**
 * @description Este componente toma un arreglo de datos con el tipo DataCotejo, lo recorre con map para devolver una dona individual con su respectivo porcentaje y nombre
 * @param {DataCotejo[]} elements - Los elementos hacen referencia a una lista de el tipo DataCotejo
 * @returns {JSX.Element} Elemento JSX que devuelve un contenedor en el que se imprime un CircularProgressBar individual con su respectiva informacion de porcentaje y nombre
 */
export const DonutProgress = ({ elements }: { elements: DataCotejo[] }) => {
  const classes = useStyles();
  const element = elements.slice(0, 5)?.map((company) => {
    return (
      <div className={classes.containerDonuts} style={{ width: company.width }}>
        <Typography className={classes.textEmpresaDonut}>
          <Marquee>{company.name}</Marquee>
        </Typography>
        <CircularProgressbarWithChildren value={company.percentage}>
          <div
            style={{
              fontSize: company.fontSizeConsumo,
              marginTop: -5,
              color: "#2196f3",
            }}
          >
            <strong>{company.consumo.toLocaleString("de-DE")}</strong>
          </div>
          <div
            style={{
              fontSize: company.fontSizeTotal,
              marginTop: -5,
            }}
          >
            de {company.totalBolsa.toLocaleString("de-DE")}
          </div>
        </CircularProgressbarWithChildren>
      </div>
    );
  });

  return (
    <Grid container style={{ minHeight: "max-content", marginTop: "10px" }}>
      {element}
    </Grid>
  );
};

/**
 * @description Este componente una lista de tipo DataCompaniesForChart[] el cual se recorre, calcula porcentaje basado en la informacion del valor y se imprime en un componente HorizontalChartBar
 * @param {DataCompaniesForChart[]} elements - Los elementos hacen referencia a una lista de el tipo DataCompaniesForChart
 * @returns {JSX.Element} Elemento JSX que devuelve un Grid en el que se imprime un HorizontalChartBar individual con su respectiva informacion de porcentaje y nombre
 */
export const HorizontalChart = ({ elements }: HorizontalChartProps) => {
  const element = elements?.map((process) => {
    const biggerValue = Math.max(...elements.map((data) => data.value));
    const percent =
      process.value === biggerValue
        ? process.value > 0
          ? 90
          : 0
        : Math.round((process.value * 100) / biggerValue);

    return (
      <HorizontalChartBar
        done={percent.toString()}
        title={process.name}
        value={process.value.toLocaleString("de-DE")}
      />
    );
  });
  return (
    <Grid container style={{ minHeight: "max-content", marginTop: "10px" }}>
      {element}
    </Grid>
  );
};

const useStyles = makeStyles((theme: any) => ({
  conteinetGrid: {
    alignItems: "center",
    textAlign: "center",
    padding: "0px",
  },
  mg0: {
    margin: 0,
  },
  containerList: {
    alignItems: "center",
    textAlign: "center",
    paddingBottom: "3px",
    maxHeight: "300px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.08)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.08)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "0.3em",
      // outline: "1px solid slategrey",
    },
  },
  textEmpresaDonut: {
    fontWeight: "bold",
    fontSize: "11px",
    color: "#575757",
    textAlign: "center",
  },
  containerDonuts: {
    margin: "0px auto",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "20px",
    justifyContent: "space-between",
    gap: "10px",
  },
}));

