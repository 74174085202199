import XLSX from "xlsx";
import { useDispatch } from "react-redux";

//ASSETS
import loadLogo from "../assets/loadLogo.png";
import errorLogo from "../assets/errorLogo.png";

//Utils
import { moduleNamesPluralMin } from "../utils/general/const";

/**
 * Custom Hook encapsula las funciones para la carga masiva, con un tipado generico.
 */
export const useMassiveLoad = <T>() => {
  //React redux
  const dispatch = useDispatch();
  /**
   * Valida el archivo seleccionado y valida si se puede subir o reemplazar o si esta mal.
   * @param { any } e - datos del campo.
   * @param { Function } alertModal - Función que ejecuta y maneja los estados del modal.
   * @param { any } validateLoadStatusAC - Función que ejecuta el servicio y guarda en redux el resultado de la validacion del archivo
   * @param { string } fieldToParse - Nombre del campo del documento excel a analizar.
   * @param { string } moduleName - Nombre del modulo en plural y minuscula
   */
  const onUpload = async (
    e: any,
    alertModal: Function,
    validateLoadStatusAC: any,
    fieldToParse: string,
    moduleName: string
  ) => {
    let arrayBuffer: any;
    let file = e.target.files[0];
    let fileReader = new FileReader();
    /** Array que contiene las extensiones de archivos permitidos para las cargas masivas */
    const extensionAccepted: string[] = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "file/xslx",
    ];
    /** Lector de documentos excel */
    fileReader.onload = async () => {
      try {
        /** Tamaño predefinido maximo para la carga */
        if (file.size / 1048576 > 2) {
          alertModal(
            "Oops!!! Ocurrio algo inesperado",
            "Recuerda que el tamaño del archivo no puede superar los 2 MB",
            errorLogo
          );
          return;
        }
        /** Validacion de extension de archivo */
        if (!extensionAccepted.includes(file.type)) {
          alertModal(
            "Oops!!! Ocurrio algo inesperado",
            "Recuerda que el tipo del archivo debe ser excel",
            errorLogo
          );
          return;
        }
        /** Inicializando buffer que manipulara la informacion del archivo */
        arrayBuffer = fileReader.result;
        let data = new Uint8Array(arrayBuffer);
        let arr = [];
        for (let i = 0; i !== data?.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        const dataXlsx: Array<T> = XLSX.utils.sheet_to_json(worksheet, {
          raw: true,
        });
        if (fieldToParse) {
          await Promise.all(
            dataXlsx?.map(
              (item: any) =>
                (item[`${fieldToParse}`] = (
                  item[`${fieldToParse}`] == null ? "" : item[`${fieldToParse}`]
                ).toString())
            )
          );
        }

        if (dataXlsx.length === 0) {
          alertModal(
            "",
            "No se encontró información en el documento cargado.",
            errorLogo
          );
        } else if (moduleName === moduleNamesPluralMin.computerEquipment) {
          const filter = dataXlsx.reduce((acc: any, data: any) => {
            acc[data.mac_imei] = ++acc[data.mac_imei] || 0;
            return acc;
          }, {});
          const dup = dataXlsx.filter((data: any) => {
            return filter[data.mac_imei] || null;
          });
          if (dup !== undefined && dup.length > 0) {
            alertModal(
              "",
              `No se pueden cargar los equipos por mac duplicado`,
              errorLogo
            );
          } else {
            alertModal(
              `Cargue masivo de ${moduleName}`,
              `Cargando información de ${dataXlsx?.length} ${moduleName}, espera un momento por favor`,
              loadLogo
            );
            await dispatch(validateLoadStatusAC(dataXlsx));
          }
        } else {
          alertModal(
            `Cargue masivo de ${moduleName}`,
            `Cargando información de ${dataXlsx?.length} ${moduleName}, espera un momento por favor`,
            loadLogo
          );
          await dispatch(validateLoadStatusAC(dataXlsx));
        }
      } catch (error: any) {
        const statusCode = error.statusCode;
        if (statusCode >= 500) {
          alertModal(
            "Oops!!! Ocurrió algo inesperado",
            "Hubo un problema en el servidor. Por favor intenta nuevamente más tarde.",
            errorLogo
          );
        } else {
          alertModal(
            "Oops!!! Ocurrió algo inesperado",
            "El archivo que estás cargando no corresponde a la plantilla, GSE no puede interpretarlo",
            errorLogo
          );
        }
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  return { onUpload };
};
