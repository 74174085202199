import {
  auditComputerEquipmentService,
  createComputerEquipment,
  getAllComputerEquipments,
  getAllEquipments,
  updateComputerEquipment,
  getMassiveComputerEquipmentTemplate,
  getComputerEquipmentsByBusinessUnit,
  validateLoadStatus,
  saveMassiveLoad,
  getBasicComputerEquipmentBusinessUnit,
  getAdvanceSearchComputerEquipmentService,
  getAdvanceSearchComputerEquipmentCsvService,
} from "../../services/ComputerEquipmentServices";
import { ComputerEquipment } from "../models/ComputerEquipmentModel";
import { ILoadComputerEquipmentUnit } from "../models/ComputerEquipmentUnit";
import { equipmentsList } from "../slices/CommonListsSlice";
import {
  setError,
  setHashCsv,
  setHashCsvModal,
  setLoading,
  setSuccess,
} from "../slices/CommonSlice";
import {
  auditComputerEquipament,
  setComputerEquipments,
  setComputerIsEdit,
  setCurrentComputerEquipment,
  setPaginatorEquipment,
  setSize,
  setFilterEquipment,
  setSortEquipment,
  setLoadingComputerEquipment,
  setComputerEquipamentMassive,
} from "../slices/ComputerEquipmentSlice";
import { AppThunk } from "../store";
import {
  AdvanceSearch,
  ComputerEquipmentBusinessUnit,
  PaginatorWithFilterAndSort,
  sortBy,
} from "../types";
/**
 * Obtiene y guarda los equipos segun el filtro de búsqueda
 * @param { PaginatorWithFilterAndSort } paginator - informacion de la paginación el filtro y el ordenamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 */
export const getAllComputerEquipmentsAC =
  (
    paginator: PaginatorWithFilterAndSort,
    company?: string,
    businessUnit?: number
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    getAllComputerEquipments(paginator, company, businessUnit)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setComputerEquipments(response.result.records));
          dispatch(setSize(response.result.count));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
/**
 * Obtiene y guarda los equipos filtrado por una sede en especifica.
 * @param { ComputerEquipmentBusinessUnit } body - datos para la busqueda por sede
 * @returns
 */
export const getComputerEquipmentsByBusinessUnitAC =
  (body: ComputerEquipmentBusinessUnit): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    getComputerEquipmentsByBusinessUnit(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setComputerEquipments(response.result.records));
          dispatch(setSize(response.result.count));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
/**
 * Guarda la paginación
 * @param { number } page - numero de pagina
 * @param { number } itemsPage - limite de datos mostrados
 * @param { boolean } recharge - validación se recarga la paginación
 */
export const getPaginatorAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorEquipment({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };
/**
 * Guarde el texto por el que se quiere filtrar
 * @param { string } filter - texto a filtrar en la busqueda
 */
export const getFilterAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterEquipment(filter));
  };
/**
 * Guarda el ordenamiento
 * @param { sortBy } sort - Tipo de ordenamiento
 */
export const getSortAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortEquipment(sort));
  };
/**
 * Obtiene y guarda todos los equipos
 */
export const getComputerEquipmentsAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getAllEquipments()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(equipmentsList(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};
/**
 * Obtiene y guarda los equipos filtrado por una sede en especifica.
 * @param { ComputerEquipmentBusinessUnit } body - datos para la busqueda por sede
 * @returns
 */
export const getEquipmentByBusinessUnitCommonAC =
  (body: ComputerEquipmentBusinessUnit): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getBasicComputerEquipmentBusinessUnit(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(equipmentsList(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * Guarda la carga masiva del equipo
 * @param { Array<ILoadComputerEquipmentUnit> } body - lista con los equipos
 */
export const saveMassiveLoadAC =
  (body: Array<ILoadComputerEquipmentUnit>, onResponse: any): AppThunk =>
  (dispatch) => {
    saveMassiveLoad(body)
      .then((response) => {
        if (response.statusCode === 200) {
          onResponse(true);
          return true;
        } else {
          onResponse(false);
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

/**
 * @function getMassiveComputerEquipmentTemplateAC
 * obtiene la plantilla para la carga masiva de Equipos
 */
export const getMassiveComputerEquipmentTemplateAC =
  (): AppThunk => (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    getMassiveComputerEquipmentTemplate()
      .then((response) => {
        if (response.statusCode === 200) {
          const mimeType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const linkSource = `${mimeType}${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName =
            response.result.nameFile + "." + response.result.extensionFile;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          dispatch(setError(response.statusMessage));
        }
      })
      .catch((err) => dispatch(setError(err)))
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };

/**
 * Obtiene la validación si se puede subir o reemplazar o si esta mal y lo guarda
 * @param { Array<ILoadComputerEquipmentUnit> } body - datos del cargue masivo
 */
export const validateLoadStatusAC =
  (body: Array<ILoadComputerEquipmentUnit>): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    return validateLoadStatus(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setComputerEquipamentMassive(response.result));
        } else {
          dispatch(
            setError(response.statusMessage || "Exception validateLoadStatusAC")
          );
        }
      })
      .catch((err) => {
        dispatch(setError(err || "Exception validateLoadStatusAC"));
        throw err;
      })
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
/**
 * Crea un equipo
 * @param { ComputerEquipment } body - información del equipo
 */
export const createComputerEquipmentAC =
  (body: ComputerEquipment): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    createComputerEquipment(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setSuccess(response.result.information));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
/**
 * Actualiza un equipo
 * @param { ComputerEquipment } body - información del equipo
 */
export const updateComputerEquipmentAC =
  (body: ComputerEquipment): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    updateComputerEquipment(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setSuccess(response.result.information));
          dispatch(setCurrentComputerEquipment(body));
          dispatch(setComputerIsEdit(false));
          dispatch(setComputerEquipments([]));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };

/**
 * Obtiene y guarda la auditoria del equipo.
 * @param { ComputerEquipment } body - datos para la auditoria
 * @param { Function } setLoadingT actualizar estado de loader según la vista.
 */
export const computerEquipmentAuditAC =
  (body: ComputerEquipment, setLoadingT: Function): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingT(true));
    auditComputerEquipmentService(body)
      .then((data) => {
        const audit = data.result;
        dispatch(auditComputerEquipament(audit));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingT(false)));
  };
/**
 * Realiza la búsqueda avanzada y actualiza los equipos
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const AdvanceSearhComputerEquipmentAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    getAdvanceSearchComputerEquipmentService(body)
      .then((data) => {
        const result = data.result;
        dispatch(setComputerEquipments(result.records));
        dispatch(setSize(result.count));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
/**
 * Realiza la búsqueda avanzada y descarga archivo csv con el resultado
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const advanceSearchEquipmentCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComputerEquipment(true));
    getAdvanceSearchComputerEquipmentCsvService(body)
      .then((data) => {
        if (data.statusCode === 200) {
          const link = `data:application/zip;base64,${data.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = data.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(data.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => dispatch(setLoadingComputerEquipment(false)));
  };
