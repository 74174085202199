import moment from "moment";
import { Column } from "../../../components/Table/types";
/**
 * Retorna las columnas que se van a mostrar en la tabla de los usuarios
 * @param { Function } handleConfirmClick - Funcion que maneja el estado
 * @returns { Array<{id: string, label: string, minWidth: number, align: string, havDropDown: boolean}>} columnas que se mostraran en la tabla
 */
const columns = (handleConfirmClick: Function) => {
  const columns: Column[] = [
    {
      id: "name",
      label: "Nombre",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "ip",
      label: "Ip",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "mac",
      label: "Mac / Imei",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "typeName",
      label: "Tipo de equipo",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "businessUnitName",
      label: "Sede",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "createdOn",
      label: "Fecha creación",
      minWidth: 70,
      align: "center",
      haveDropDown: true,
      format: (value: string) => moment(value).format("DD/MM/YYYY, H:mm:ss"),
    },
    {
      id: "state",
      label: "Estado",
      align: "center",
      haveDropDown: true,
      component: {
        handleChange: (e: any, value: any) => handleConfirmClick(e, value),
        tooltipMessage: "Tipo proceso",
        withIcon: false,
        wordLength: 60,
        handleIconClick: () => {},
      },
    },
  ];
  return columns;
};

export default columns;
