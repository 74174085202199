import { object, string, number, boolean } from "yup";
import { REQUIRED_MESSAGE } from "../../config/const";
import { regex } from "../general/utils";

export const bussinesUnitSchema = object().shape({
  code: string()
    .max(30, "El campo Código excede el tamaño permitido de 30 caracteres.")
    .required("El campo Código es obligatorio"),
  name: string()
    .max(100, "El campo Nombre excede el tamaño permitido de 100 caracteres.")
    .required(REQUIRED_MESSAGE),
  isDependent: boolean(),
  phone: string()
    .required(REQUIRED_MESSAGE)
    .trim()
    .matches(regex.CelAndPhone, "El campo Teléfono no es valido"),
  email: string()
    .required(REQUIRED_MESSAGE)
    .trim()
    .matches(regex.Email, "El Email no es válido."),
  address: string()
    .max(
      100,
      "El campo Dirección excede el tamaño permitido de 100 caracteres."
    )
    .required(REQUIRED_MESSAGE),
  department: number().required(REQUIRED_MESSAGE),
  city: number().notOneOf([0]).required(REQUIRED_MESSAGE),
  businessUnit: number().when("isDependent", {
    is: (value: boolean) => !!value,
    then: number().moreThan(0).required(REQUIRED_MESSAGE),
    otherwise: number().nullable().notRequired(),
  }),
});
