import { Grid } from "@material-ui/core";
import { NoComputerIcon } from "../../../components/Icons/NoComputerIcon";
import { CustomTypography } from "../../../components/typography/CustomTypography";

/**
 * @description Componente que muestra un mensaje definido cuando no se encuentran resultados en la tabla de comparison
 * @param { boolean } today - Parametro booleano que representa el tipo de mensaje al momento de renderizarse
 * @returns { JSX.Element } - JSX.Element
 */
export const notDataFound = (today: boolean = false): JSX.Element => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoComputerIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography type="definition" children={`Aún no hay cotejos${today ? ' para el día de hoy.': '.'}`} />
      </Grid>
    </Grid>
  );
};
