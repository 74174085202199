import {
  GetAllBrands,
  getAllBusinessUnits,
  getAllDeviceTypes,
  getAllModels,
  getListStatesAndCitiesService,
  getOperatingSystems,
  getRepositories,
  getTypeComputerEquipments,
} from "../../services/CommonListService";
import { AppThunk } from "../store";
import {
  brandList,
  modelsList,
  businessUnitList,
  typesList,
  setTypeComputer,
  setOperatingSystems,
  setRepositories,
  setStatesAndCities,
  modelsTypes,
} from "../slices/CommonListsSlice";
import { setLoading } from "../slices/CommonSlice";

/**
 * @description Lanza el servicio GetAllBrands donde se obtiene y se almacena en los hook la lista de marcas de equipos
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de marcas a el hook brands con el tipo BasicResponse[].
 */
export const getBrandsAC = (): AppThunk => (dispatch) => {
  GetAllBrands()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(brandList(response.result.records));
      }
    })
    .catch((err) => console.error(err));
};

/**
 * @description Lanza el servicio getAllModels donde se obtiene y se almacena en los hook la lista de marcas de modelos de una marca
 * @param { number } brand Parametro de tipo number que representa el ID del modelo
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de modelos a el hook models con el tipo BasicResponse[].
 */
export const getModelsAC =
  (brand: number): AppThunk =>
  (dispatch) => {
    getAllModels(brand)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(modelsList(response.result.records));
        }
      })
      .catch((err) => console.error(err));
  };

/**
 * @description Lanza el servicio getAllBusinessUnits donde se obtiene y se almacena en los hook la lista de sedes de una empresa
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de modelos a el hook businessUnits con el tipo BasicResponse[].
 */
export const getBusinessUnitAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true)) &&
    getAllBusinessUnits()
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(businessUnitList(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getAllDeviceTypes donde se obtiene y se almacena en los hook la lista de los tipos de dispositivos
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de tipos de dispositivos a el hook types con el tipo BasicResponse[].
 */
export const getDeviceTypesAC = (): AppThunk => (dispatch) => {
  getAllDeviceTypes()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(typesList(response.result.records));
      }
    })
    .catch((err) => console.error(err));
};

/**
 * @description Lanza el servicio getOperatingSystems donde se obtiene y se almacena en los hook la lista de los sistemas operativos
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista sistemas operativos a el hook operatingSystems con el tipo TypeEquipmentResponse[].
 */
export const getOperatingSystemsAC = (): AppThunk => (dispatch) => {
  getOperatingSystems()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setOperatingSystems(response.result.records));
      }
    })
    .catch((err) => console.error(err));
};

/**
 * @description Lanza el servicio getTypeComputerEquipments donde se obtiene y se almacena en los hook la lista de los tipos de equipos
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de tipos de equipos a el hook typeComputerEquipment con el tipo TypeEquipmentResponse[].
 */
export const getTypeComputerAC = (): AppThunk => (dispatch) => {
  getTypeComputerEquipments()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setTypeComputer(response.result.records));
      }
    })
    .catch((err) => console.error(err));
};

/**
 * @description Lanza el dervicio getRepositories donde se obtiene los repositorios disponibles para almacenar archivos
 * @param { string } token Parametro de tipo string que representa el token del usuario logueado
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de repositorios a el hook repositories con el tipo BasicResponse[].
 */
export const getRepositoriesAC =
  (token: string): AppThunk =>
  (dispatch) => {
    getRepositories(token)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setRepositories(response.result.records));
        }
      })
      .catch((err) => console.error(err));
  };

/**
 * @description Lanza el servicio getListStatesAndCitiesService donde se obtiene y se almacena en la lista de departamentos y ciudades
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de departamentos y ciudades a el hook statesAndCities con el tipo CitiesResponse[].
 */
export const getListStatesAndCitiesAC = (): AppThunk => (dispatch) => {
  getListStatesAndCitiesService()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setStatesAndCities(response.result.records));
      }
    })
    .catch((error) => console.error(error));
};
