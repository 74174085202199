import { SvgIcon } from "@material-ui/core";
import React from "react";

export const ArrowIcon = (props: any) => (
  <SvgIcon {...props} width="18.19" height="18.19" viewBox="0 0 18.19 18.19">
    <g transform="translate(0.502 0.502)">
      <g transform="translate(401.824 165.824) rotate(180)">
        <g transform="translate(384.637 148.637)">
          <path
            style={{
              fill: "#fff",
              strokeWidth: "1.003px",
            }}
            d="M8.593,0A8.593,8.593,0,1,1,0,8.593,8.593,8.593,0,0,1,8.593,0Z"
            transform="translate(0 0)"
          />
          <path
            style={{ fill: "none", strokeWidth: "2px" }}
            d="M385.932,3994l-3.577,3.577,3.577,3.576"
            transform="translate(-375.674 -3988.953)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
