import React from "react";
import ComputerEquipmentForm from "./ComputerEquipmentForm";
import { Column } from "../../components/Table/types";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

//COMPONENTS
import MassiveLoadComponent from "../../components/MassiveLoad/MassiveLoadComponent";
import MassiveLoadInfo from "../../components/MassiveLoad/MassiveLoadInfo";
import MassiveLoadResult from "../../components/MassiveLoad/MassiveLoadResult";

import ViewLayout from "../../components/Layouts/ViewLayout";
import DataTable from "../../components/Table/DataTable";

//COMPONENTS-MODALS
import { LoadModal } from "../../components/modal/LoadModal";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";

//PARTIALS
import GetButtonsComputerEquipment from "./data/getButtonsComputerEquipment";
import columns from "./data/columns";
import notDataFound from "./data/notDataFound";

//CUSTOM HOOK
import useComputerEquipment from "../../hooks/useComputerEquipment";

type ComputerEquipmentViewProps = {
  businessUnitId?: number;
  handleRowClick?: Function;
  hiddeMassiveButton?: boolean;
  justTable?: boolean;
  nitCompany?: string;
  tabletOnly?: boolean;
};
/**
 * Retorna la vista completa de los equipos
 * @param { ( number | undefined ) } businessUnitId - id de la sede
 * @param { ( Function | undefined ) } handleRowClick - funcion que captura la información del equipo
 * @param { ( boolean | undefined ) } hiddeMassiveButton - valida si se muestra el boton de carga masiva
 * @param { ( boolean | undefined ) } justTable - muestra solo la tabla
 * @param { ( string | undefined ) } nitCompany - NIT de la compañia
 * @param { ( boolean | undefined ) } tabletOnly
 * @returns { JSX.Element } Vista de equipos con o sin layout
 */
const ComputerEquipmentView: React.FC<ComputerEquipmentViewProps> = ({
  businessUnitId,
  handleRowClick,
  hiddeMassiveButton = false,
  justTable,
  nitCompany,
  tabletOnly,
}) => {
  // Custom hook
  const {
    advanceSearch,
    advanceSearchState,
    cancel,
    cancelText,
    computerEquipamentMassiveResponse,
    computerEquipments,
    confirmText,
    confirmate,
    fieldsAdvanced,
    handleBack,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnCloseLoadModal,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingComputerEquipment,
    messageModal,
    modal,
    onCancel,
    onContinue,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onSave,
    onUploadDataEvent,
    option,
    paginatorEquipment,
    privilege,
    rol,
    setLoad,
    setOption,
    setTab,
    showForm,
    showFormEquipment,
    size,
    tab,
    titleModal,
  } = useComputerEquipment({
    nitCompany,
    businessUnitId,
  });
  /**
   * Retorna la tabla de datos de los dispositivos
   * @returns { JSX.Element } Tabla de datos
   */
  const TabletComponent = () => {
    return (
      <DataTable
        buttons={GetButtonsComputerEquipment(
          justTable,
          privilege,
          hiddeMassiveButton,
          rol
        )}
        columns={columns(handleConfirmClick)}
        data={computerEquipments}
        download={onDownloadData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFilter={handleFilter}
        handleOnLoadButton={handleOnLoadButton}
        handleOnOpenAdvSearch={handleOnOpen}
        handleSort={handleSort}
        havePagination={size > 10 ? true : false}
        option={option}
        page={paginatorEquipment.paginator.page - 1}
        paginator={paginatorEquipment}
        rol={rol}
        rowsPerPage={paginatorEquipment.paginator.itemsPage}
        setLoad={setLoad}
        setOption={setOption}
        setPage={handleChangePage}
        setTab={setTab}
        size={size}
        handleCellClick={(
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          value: any,
          _column: Column
        ) => {
          if (rol !== "BIOMETRIA RNEC") {
            handleCellClick(e, value, _column);
          }
        }}
        notDataFound={
          (paginatorEquipment.filter && computerEquipments.length === 0) ||
          advanceSearchState
            ? undefined
            : notDataFound(rol)
        }
        createDisabled={
          privilege?.find((privil) => privil === "BIOMETRIA_EQUIPO_CREATE") ===
          undefined
            ? true
            : false
        }
        deleteDiabled={
          privilege?.find((privil) => privil === "BIOMETRIA_EQUIPO_DELETE") ===
          undefined
            ? true
            : false
        }
        modifyDiabled={
          privilege?.find((privil) => privil === "BIOMETRIA_EQUIPO_MODIFY") ===
          undefined
            ? true
            : false
        }
        readDisabled={
          privilege?.find((privil) => privil === "BIOMETRIA_EQUIPO_READ") ===
          undefined
            ? true
            : false
        }
        loading={loadingComputerEquipment}
      />
    );
  };

  return (
    <div>
      <LoadModal
        title={titleModal}
        open={modal}
        handleClose={handleOnCloseLoadModal}
        icon={<img src={imageModal} alt="hands" className={"imageInfo"} />}
        message={messageModal}
        handleConfirm={onContinue}
        handleCancel={onCancel}
        isConfirm={confirmate}
        confirmText={confirmText}
        cancelText={cancelText}
        isCancel={cancel}
      />
      {isLoad ? (
        <MassiveLoadComponent
          loadResult={
            <MassiveLoadResult
              sucessfull={computerEquipamentMassiveResponse?.exitosos}
              items={itemsList}
              replaced={computerEquipamentMassiveResponse?.reemplazados}
              error={computerEquipamentMassiveResponse?.errores}
              onDownloadErrors={onDownloadErrors}
              onContinue={onSave}
            />
          }
          loadInfo={
            <MassiveLoadInfo
              onDownload={onDownload}
              onUpload={onUploadDataEvent}
              title={"Bienvenido, así podrás hacer el cargue de equipos"}
            />
          }
          title={"Cargue masivo de equipos"}
          handleBack={handleBack}
          handleChangeTab={handleChangeTab}
          tab={tab}
        />
      ) : justTable ? (
        <>
          <AdvancedSearchModal
            open={showFormEquipment}
            handleOnSearch={handleOnSearch}
            handleOnClose={handleOnClose}
            fieldsAdvancedSearch={
              !advanceSearch.length ? fieldsAdvanced : advanceSearch
            }
            fieldsAdvanced={fieldsAdvanced}
          />
          <DataTable
            buttons={GetButtonsComputerEquipment(
              justTable,
              privilege,
              hiddeMassiveButton,
              rol
            )}
            columns={columns(handleConfirmClick)}
            data={computerEquipments}
            download={onDownloadData}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFilter={handleFilter}
            handleOnLoadButton={handleOnLoadButton}
            handleOnOpenAdvSearch={handleOnOpen}
            handleSort={handleSort}
            havePagination={size > 10 ? true : false}
            option={option}
            page={paginatorEquipment.paginator.page - 1}
            paginator={paginatorEquipment}
            rol={rol}
            rowsPerPage={paginatorEquipment.paginator.itemsPage}
            setLoad={setLoad}
            setOption={setOption}
            setPage={handleChangePage}
            setTab={setTab}
            size={size}
            handleCellClick={(
              e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
              value: any,
              _column: Column
            ) => {
              if (handleRowClick) {
                handleRowClick(e, value, _column);
              } else {
                console.error("handleRowClick must be implemented");
              }
            }}
            notDataFound={
              (paginatorEquipment.filter && computerEquipments.length === 0) ||
              advanceSearch.length > 0
                ? undefined
                : notDataFound(rol)
            }
            createDisabled={
              privilege.find(
                (privil) => privil === "BIOMETRIA_EQUIPO_CREATE"
              ) === undefined
                ? true
                : false
            }
            deleteDiabled={
              privilege.find(
                (privil) => privil === "BIOMETRIA_EQUIPO_DELETE"
              ) === undefined
                ? true
                : false
            }
            modifyDiabled={
              privilege.find(
                (privil) => privil === "BIOMETRIA_EQUIPO_MODIFY"
              ) === undefined
                ? true
                : false
            }
            readDisabled={
              privilege.find((privil) => privil === "BIOMETRIA_EQUIPO_READ") ===
              undefined
                ? true
                : false
            }
            loading={loadingComputerEquipment}
          />
        </>
      ) : showForm ? (
        <ComputerEquipmentForm company={nitCompany} />
      ) : !tabletOnly ? (
        <>
          <AdvancedSearchModal
            open={showFormEquipment}
            handleOnSearch={handleOnSearch}
            handleOnClose={handleOnClose}
            fieldsAdvancedSearch={
              !advanceSearch.length ? fieldsAdvanced : advanceSearch
            }
            fieldsAdvanced={fieldsAdvanced}
          />

          <ViewLayout
            isNew={true}
            isEdit={false}
            headerTitle="Equipos"
            editOption={false}
          >
            <TabletComponent />
          </ViewLayout>
        </>
      ) : (
        <TabletComponent />
      )}
      <HashDowloandModal />
      <FullLoader open={loadingComputerEquipment} viewLoader={true} />
    </div>
  );
};

export default React.memo(ComputerEquipmentView);
