import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type dataInfo = {
  fullDate: string;
  hit: number;
  noHit: number;
  novedades: number;
};

interface HitNoHitProps {
  data: dataInfo[];
}
/**
 * Retorna una card con la información de las transacciones
 * @param { Object } options - datos suinistrados por la gráfica (libreria)
 * @returns { JSX.Element } Card con la información.
 */
const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title}>{label}</Typography>
          <Typography className={classes.subtitle}>Transacciones: {(payload[0].value + payload[1].value + payload[2].value )}</Typography>
          <Typography
            className={classes.nameTransaction}
            style={{ color: `${payload[2].color}FF` }}
          >
            {payload[2].name}: {payload[2].value}
          </Typography>
          <Typography
            className={classes.nameTransaction}
            style={{ color: payload[1].color }}
          >
            {payload[1].name}: {payload[1].value}
          </Typography>
          <Typography style={{ color: payload[0].color, fontSize: "14px" }}>
            {payload[0].name}: {payload[0].value}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};
/**
 * Retorna grafica en forma de barras verticales con los valores hit, no hit y novedades en una sola barra
 * @param { dataInfo } data - datos con los que se llena la grafica
 * @returns { JSX.Element }
 */
const HitNoHitAndNovelties = ({ data }: HitNoHitProps) => {
  return (
    <Box height={400}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="fullDate" />
          <YAxis />
          <Tooltip content={CustomTooltip} />
          <Legend />
          <Bar
            dataKey="hit"
            name="Hit"
            stackId="a"
            fill="#5FBE2C"
            barSize={50}
          />
          <Bar
            dataKey="noHit"
            name="No Hit"
            stackId="a"
            fill="#D50915"
            barSize={50}
          />
          <Bar
            dataKey="novedades"
            name="Novedades"
            stackId="a"
            fill="#4572A7"
            barSize={50}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: 175,
  },
  title: {
    fontSize: 16,
    color: "#000000",
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 14,
    color: "#000000",
    marginBottom: 15,
  },
  nameTransaction: {
    fontSize: 14,
    marginBottom: 12,
  },
});

export default HitNoHitAndNovelties;
