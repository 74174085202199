import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/HighlightOff";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Slide, SlideProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderRadius: "10px",
    },
    icon: {
      height: 90,
      width: 222,
      margin: 10,
    },
    titleAlert: {
      color: theme.palette.secondary.dark,
      whiteSpace: "pre-line",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    cancelButton: {
      marginRight: "31px",
    },
    dialogContent: {
      marginBottom: 20,
      textAlign: "center",
      fontSize: "14px",
      color: theme.palette.secondary.main,
    },
    promoForm: {
      flexDirection: "row",
      border: "1px solid #F1F1F1",
      padding: 15,
      marginBottom: 20,
    },
    divider: {
      marginBottom: 20,
    },
  };
});

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));
interface AlertProps {
  isOpen: boolean;
  icon?: any;
  onClose?: any;
  bodyText?: any;
  input?: any;
  showBtnClose?: boolean;
}
const AlertInputComponent = ({
  isOpen,
  icon,
  onClose,
  bodyText,
  input,
  showBtnClose,
}: AlertProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        TransitionComponent={Transition}
        maxWidth="xs"
        open={isOpen}
        className="MuiDialog-root"
      >
        {!!icon && (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={6}
              md={6}
              xs={6}
              sm={6}
              justifyContent="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
          </Grid>
        )}
        <DialogTitle>
          {showBtnClose && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => onClose(false)}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography>{bodyText}</Typography>
        </DialogTitle>
        {input}
      </Dialog>
    </div>
  );
};

const AlertInput = (props: AlertProps) => {
  return <AlertInputComponent {...props} />;
};

export default AlertInput;
