import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//COMPONENTS
import { Column } from "../components/Table/types";

//UTILS
import { createExcel } from "../utils/general/utils";

//ACTIONS CREATORS
import {
  advanceSearchRnecTypeProcessAC,
  advanceSearchTypeProcessAC,
  getPaginatorTypeProcessAC,
  getFilterAC,
  getSortAC,
  getMassiveTypeProcessTemplateAC,
  validateLoadStatusAC,
  saveMassiveLoadAC,
  advanceSearchCsvAC,
  getTypeProcessViewAC,
  getTypeProcessViewRnecAC,
} from "../store/action-creators/TypeProcessActionCreators";

//SLICES
import {
  setCurrentTypeProcess,
  setLoadingTypeProcess,
  setPaginatorTypeProcess,
  setShowForm,
  setTypeProcessMassiveResponse,
  TypeProcessSelector,
} from "../store/slices/TypeProcessSlice";
import { CommonSelector, setPrivilege } from "../store/slices/CommonSlice";

//TYPES
import { StateLoad, FieldsAdvanceSearch, sortBy } from "../store/types";

//MODELS.
import {
  ILoadTypeProcess,
  ITypeProcessTemplateError,
} from "../store/models/TypeProcessUnit";
import { UpdateState } from "../store/models/UpdateState";

//SERVICES
import {
  getProcessTypesExcelService,
  updateTypeProcessServiceState,
} from "../services/TypeProcessService";

//ASSETS
import loadLogo from "../assets/loadLogo.png";
import errorLogo from "../assets/errorLogo.png";
import backLogo from "../assets/onBack.png";
import uploadLogo from "../assets/onUpload.png";
import { useMassiveLoad } from "./useMassiveLoad";
import { moduleNamesPluralMin } from "../utils/general/const";

interface TypeProcessProps {
  nitCompany?: string;
}

/**
 * @description Este Hook encapsula las funciones, useEffect y busqueda de la vista de type process
 * @param { string } param.nitCompany Parametro de tipo string que representa el numero de identificacion tributaria de la compañia
 */
const useTypeProcess = ({ nitCompany }: TypeProcessProps) => {
  //Custom Hooks
  const { onUpload } = useMassiveLoad<ILoadTypeProcess>();

  // REDUX
  const dispatch = useDispatch();
  const {
    showForm,
    typeProcessMassiveResponse,
    paginatorTypeProcess,
    sizeTypeProcess,
    typeProcesses,
    loadingTypeProcess,
  } = useSelector(TypeProcessSelector);
  const { privilege, rol } = useSelector(CommonSelector);

  // React Local States
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState(0);
  const [isLoad, setLoad] = useState(false);
  const [option, setOption] = useState(null);
  const [imageModal, setImageModal] = useState(loadLogo);
  const [messageModal, setMessageModal] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [showFormAdv, setShowFormAdv] = useState(false);
  const [confirmate, setConfirmate] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [itemsList, setItemsList] = useState<Array<StateLoad | undefined>>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  /** Objeto que representa los campos permitidos por rol para la busqueda avanzada */
  const fieldsAdvanced: FieldsAdvanceSearch[] =
    rol === "BIOMETRIA RNEC"
      ? [
          {
            name: "Name",
            title: "Nombre del tramite",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "ModificationDate",
            title: "Fecha de modificación",
            type: "date",
            value: undefined,
          },
        ]
      : [
          {
            name: "Name",
            title: "Nombre del tramite",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "ModificationDate",
            title: "Fecha de modificación",
            type: "date",
            value: undefined,
          },
          {
            name: "State",
            title: "Estado",
            type: "status",
            value: undefined,
          },
        ];
  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    useState(fieldsAdvanced);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);

  /** Obtener la informacion de los type process segun el rol */
  const fillTypeProcessList = useCallback(() => {
    if (!loadingTypeProcess) {
      if (rol === "BIOMETRIA ADMIN" || rol === "BIOMETRIA SUPERADMIN") {
        dispatch(getTypeProcessViewAC(paginatorTypeProcess, nitCompany));
      } else if (rol === "BIOMETRIA RNEC") {
        dispatch(getTypeProcessViewRnecAC(paginatorTypeProcess, nitCompany));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorTypeProcess, dispatch, nitCompany, loadingTypeProcess]);

  /**
   * Asegura que cada vez que entre al módulo muestre la tabla
   * en la primera página el filtro este vacio y el ordenado sea reciente
   */
  useEffect(() => {
    dispatch(setShowForm(false));
    return () => {
      dispatch(getFilterAC(""));
      dispatch(getSortAC("recent"));
      dispatch(getPaginatorTypeProcessAC(1, 10, true));
    };
  }, [dispatch]);

  /** Cuando se obtenga la informacion de los datos masivos correctamente despues de almacenado recorrerlos para posteriormente insetarlos en la lista de typeProcess */
  useEffect(() => {
    if (typeProcessMassiveResponse !== undefined) {
      setModal(false);
      const items = new Array<StateLoad>();
      Promise.all(
        typeProcessMassiveResponse.details?.map(
          // eslint-disable-next-line array-callback-return
          (item: { descripcion: any; result: any }) => {
            items.push({ name: item.descripcion, state: item.result });
          }
        )
      ).then(() => {
        setTab(1);
        setItemsList(items);
      });
    }
  }, [typeProcessMassiveResponse]);

  /** Se espera algún cambio en el paginador para lanzar de nuevo la peticion al servidor con la nueva pagina seleccionada. */
  useEffect(() => {
    const { paginator } = paginatorTypeProcess;
    if (paginatorTypeProcess !== undefined && paginator.recharge) {
      if (advanceSearchState) {
        let body = {
          fields: fieldsAdvancedSearch,
          paginator: paginatorTypeProcess,
          company: nitCompany,
        };

        if (rol === "BIOMETRIA RNEC") {
          dispatch(advanceSearchRnecTypeProcessAC(body));
        } else {
          dispatch(advanceSearchTypeProcessAC(body));
        }
      } else {
        fillTypeProcessList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorTypeProcess]);

  useEffect(() => {
    if (!privilege?.length) {
      dispatch(setPrivilege(""));
    }
  }, [privilege, dispatch]);

  /**
   * Muestra un modal de alerta con un mensaje y opciones de confirmación o cancelación.
   * @param { string } titleModal - titulo del modal
   * @param { string } messageModal - mensaje del modal
   * @param { any } imageModal - imagen del modal
   * @param { Object } validate - objeto con las opciones y el texto de las mismas
   */
  const alertModal = (
    titleModal: string,
    messageModal: string,
    imageModal: any,
    validate?: {
      confirmate: boolean;
      cancel: boolean;
      confirmText: string;
      cancelText: string;
    }
  ) => {
    setModal(true);
    setImageModal(imageModal);
    setTitleModal(titleModal);
    setMessageModal(messageModal);
    if (validate) {
      setConfirmate(validate?.confirmate);
      setCancel(validate?.cancel);
      setConfirmText(validate?.confirmText);
      setCancelText(validate?.cancelText);
    }
  };

  /** cambiar de pagina y lanzar el dispatch del paginador para que los useEffect realicen el cambio de pagina. */
  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
    dispatch(
      getPaginatorTypeProcessAC(
        newPage + 1,
        paginatorTypeProcess.paginator.itemsPage,
        true
      )
    );
  };

  const handleOnClose = () => {
    setShowFormAdv(false);
  };

  const handleOnCloseLoadModal = () => {
    setModal(false);
  };

  const handleOnOpen = () => {
    setShowFormAdv(true);
  };

  const handleOnLoadButton = () => {
    setLoad(true);
    setTab(0);
  };

  /** Lanzar filtro de busqueda para advance search. */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAC(""));
    let body = {
      fields: fieldsPassedAdvancedSearch,
      paginator: {
        paginator: {
          page: 1,
          itemsPage: paginatorTypeProcess.paginator.itemsPage,
          recharge: paginatorTypeProcess.paginator.recharge,
        },
        sort: paginatorTypeProcess.sort,
      },
      company: nitCompany,
    };
    /** Verificar el rol del usuario logueado para lanzar el servicio que retorna la informacion correspondiente */
    if (rol === "BIOMETRIA RNEC") {
      dispatch(advanceSearchRnecTypeProcessAC(body));
    } else {
      dispatch(advanceSearchTypeProcessAC(body));
    }
    setAdvanceSearchState(true);
    setShowFormAdv(false);
    setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    dispatch(
      getPaginatorTypeProcessAC(
        1,
        paginatorTypeProcess.paginator.itemsPage,
        true
      )
    );
  };

  /** Ordenar los resultados mediante la seleccion del sort */
  const handleSort = (sort?: sortBy) => {
    if (sort) {
      /** Validar las acciones de ordenado permitidas por el rol del usuario */
      if (sort === "active" && rol === "BIOMETRIA RNEC") {
        dispatch(getSortAC("recent"));
      } else if (sort === "inactive" && rol === "BIOMETRIA RNEC") {
        dispatch(getSortAC("old"));
      } else {
        dispatch(getSortAC(sort));
      }

      dispatch(
        getPaginatorTypeProcessAC(
          1,
          paginatorTypeProcess.paginator.itemsPage,
          true
        )
      );
    }
  };

  /** Metodo que se lanza al momento de cambiar el estado del type process y lanza nuevamente el servicio que retorna los registros */
  const handleConfirmClick = (e: any, value: any) => {
    const state: UpdateState = {
      id: value,
      state: e.target.checked,
      paginator: {
        page: paginatorTypeProcess.paginator.page,
        itemsPage: paginatorTypeProcess.paginator.itemsPage,
        recharge: true,
      },
    };
    dispatch(setLoadingTypeProcess(true));
    updateTypeProcessServiceState(state)
      .then(() => {})
      .catch((err) => {
        console.error(err || "Exception updateTypeProcessServiceState");
      })
      .finally(() => {
        dispatch(setLoadingTypeProcess(false));
        // Paginator is thrown to refresh the data in datatable
        dispatch(
          setPaginatorTypeProcess(
            state.paginator === undefined
              ? { page: 1, itemsPage: 10 }
              : state.paginator
          )
        );
      });
  };

  /** Metodo que se ejecuta al seleccionar un registro de type process en el que se captura los datos para insertarlo en el formulario, luego se permite mostrar el formulario */
  const handleClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    column: Column
  ) => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_MODIFY") ===
      undefined
        ? false
        : true
    ) {
      dispatch(setCurrentTypeProcess(value));
      dispatch(setShowForm(true));
    }
  };

  /** Metodo para lanzar un modal con la opcion de regresar a la vista anterior de la carga masiva. */
  const handleBack = () => {
    if (isLoad && typeProcessMassiveResponse !== undefined) {
      alertModal(
        "",
        "¿Estás seguro que deseas realizar esta acción?\n Si lo haces, perderás la información cargada",
        backLogo,
        { confirmate: true, cancel: true, confirmText: "SI", cancelText: "NO" }
      );
    } else {
      setLoad(false);
    }
  };

  /** Metodo que lanza un dispatch con el servicio que retorna la informacion del template de carga masiva para type process */
  const onDownload = async () => {
    dispatch(getMassiveTypeProcessTemplateAC());
  };
  /**
   * Función que ejecuta la función que valida el archivo seleccionado y valida si se puede subir o reemplazar o si esta mal
   * @param { any } e - datos del campo
   */
  const onUploadDataEvent = (e: any) =>
    onUpload(
      e,
      alertModal,
      validateLoadStatusAC,
      "descripcion",
      moduleNamesPluralMin.typeprocess
    );

  /** Metodo para lanzar despues de haber pre-cargado la informacion contenida para su respectivo almacenado */
  const onContinue = () => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      if (typeProcessMassiveResponse !== undefined) {
        let bo = typeProcessMassiveResponse.details.filter(
          (b: { result: string }) =>
            b.result === "exitoso" || b.result === "reemplazado"
        );
        dispatch(saveMassiveLoadAC(bo, onSuccess));
        alertModal(
          "Cargue masivo de tipo de trámite",
          "La informacion de tus tipos de trámite esta siendo cargada a la plataforma, recuerda revisarla",
          loadLogo,
          { confirmate: false, cancel: false, cancelText: "", confirmText: "" }
        );
      }
    }
  };

  /** Una vez almacenada la informacion se lanza este metodo con el despliegue de la alerta con la informacion de el estado del proceso de almacenado*/
  const onSuccess = (value: Boolean) => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      if (value) {
        alertModal(
          "Cargue exitosamente",
          `Tu carga masiva fue un exito`,
          uploadLogo
        );
        dispatch(setTypeProcessMassiveResponse(undefined));
        dispatch(getTypeProcessViewAC(paginatorTypeProcess, nitCompany));
        setLoad(false);
      } else {
        alertModal("Error", "Error al guardar los datos", errorLogo);
      }
    }
  };

  const onCancel = () => {
    setModal(false);
  };

  /** Al finalizar la carga masiva, devolver los parametros de carga, limipiar el modal con el mensaje y el hook que almacena la informacion de la carga masiva */
  const onFinalize = () => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_SEDE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      dispatch(setTypeProcessMassiveResponse(undefined));
      setLoad(false);
      setModal(false);
      setConfirmate(false);
      setTab(0);
    }
  };

  /** Metodo que permite descargar el archivo excel con la informacion de los type process cargados con sus respectivos estados de error */
  const onDownloadErrors = () => {
    if (typeProcessMassiveResponse !== undefined) {
      const valores = typeProcessMassiveResponse.details;
      let data: any = [];

      Object.keys(valores)?.forEach((key) => {
        const item = valores[parseInt(key, 10)];

        if (item.errors !== undefined && item.errors?.length) {
          const deviceError: ITypeProcessTemplateError = {
            codigo: item.codigo,
            descripcion: item.descripcion,
            nombreTramite: item.nombreTramite,
            error: item.errors,
          };
          data.push(deviceError);
        }
      });
      // export excel by helper method
      const fill = (number: any, len: any) =>
        "0".repeat(len - number.toString().length) + number.toString();
      const currentDate =
        new Date().getFullYear() +
        "-" +
        fill(new Date().getMonth() + 1, 2) +
        "-" +
        new Date().getDate();
      createExcel(data, "Errores_Tipos_Tramite_" + currentDate);
    }
  };

  /** Metodo que permite descargar el registro de los type process en el DataTable. */
  const onDownloadData = () => {
    if (rol === "BIOMETRIA RNEC") {
      dispatch(
        advanceSearchCsvAC({
          fields: fieldsAdvancedSearch,
          paginator: paginatorTypeProcess,
          company: nitCompany,
        })
      );
    } else {
      let data: any[] = [];
      dispatch(setLoadingTypeProcess(true));
      getProcessTypesExcelService({
        fields: fieldsAdvancedSearch,
        paginator: paginatorTypeProcess,
        company: nitCompany,
      })
        .then((response) => {
          data = response.result.records.map((item) => {
            return {
              ...item,
              fecha_Creacion: new Date(item.fecha_Creacion).toLocaleString(),
            };
          });
          createExcel(data, "Listado de Tipos de Tramite");
        })
        .catch((error) => {
          console.error(error.result.information);
        })
        .finally(() => dispatch(setLoadingTypeProcess(false)));
    }
  };

  /** Cambiar el index de la informacion */
  const handleChangeTab = (evt: any, value: any) => {
    typeProcessMassiveResponse !== undefined && setTab(value);
  };

  /** Metodo para cambiar la cantidad de registros por pagina. */
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getPaginatorTypeProcessAC(1, +event.target.value, true));
  };

  /**
   * @description Metodo para lanzar el servicio de type process con el filtro basico de busqueda
   * @param { string } query Parametro de tipo string que representa la cadena de texto que se desea filtrar
   */
  const handleFilter = (query: string) => {
    setAdvanceSearchState(false);
    setFieldsAdvancedSearch(fieldsAdvanced);
    dispatch(getFilterAC(query.trim()));
    dispatch(
      getPaginatorTypeProcessAC(
        1,
        paginatorTypeProcess.paginator.itemsPage,
        true
      )
    );
  };

  return {
    advanceSearchState,
    cancel,
    cancelText,
    confirmText,
    confirmate,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    handleBack,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleClick,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnCloseLoadModal,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingTypeProcess,
    messageModal,
    modal,
    onCancel,
    onContinue,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onFinalize,
    onUploadDataEvent,
    option,
    page,
    paginatorTypeProcess,
    privilege,
    rol,
    rowsPerPage,
    setFieldsAdvancedSearch,
    setLoad,
    setOption,
    setPage,
    setShowFormAdv,
    setTab,
    showForm,
    showFormAdv,
    sizeTypeProcess,
    tab,
    titleModal,
    typeProcessMassiveResponse,
    typeProcesses,
  };
};

export default useTypeProcess;
