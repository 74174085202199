import React from "react";

//COMPONENTES TRANSVERSALES
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

//COMPONENTES
import ViewLayout from "../../components/Layouts/ViewLayout";
import ComparisonForm from "./ComparisonForm";
import DataTable from "../../components/Table/DataTable";

//COMPONENTE-MODALS
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";

//PARTIALS
import { columns, getButtons, notDataFound } from "./data";

//TYPES
import { Column } from "../../components/Table/types";
import useComparison from "../../hooks/useComparison";
import { advanceSearchHasValues } from "../../utils/general/utils";

type ComparisonViewProps = {
  nitCompany: string;
  justTable?: boolean;
  handleRowClick?: Function;
  tabletOnly?: boolean;
};

/**
 * @description Vista principal de registros de comparison
 * @param { string } nitCompany - Parametro que representa el numero de identificacion tributaria de la compañia
 * @param { boolean } justTable - Parametro que representa la opcion de para ocultar la tabla para permitir mostrar el formulario
 * @param { Function } handleRowClick - Parametro que representa la funcion que se ejecuta en el Datatable al momento de seleccionar un registro
 * @param { boolean } tabletOnly - Parametro que representa la opcion de mostrar unicamente la tabla
 * @returns { JSX.Element }
 */
const ComparisionView: React.FC<ComparisonViewProps> = ({
  nitCompany,
  justTable,
  handleRowClick,
  tabletOnly,
}) => {
  const {
    advanceSearchState,
    comparisonExcel,
    comparisons,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleOnClose,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    loadingComparison,
    onDownloadData,
    option,
    paginatorComparison,
    setFieldsAdvancedSearch,
    setLoad,
    setOption,
    setShowFormAdv,
    showForm,
    showFormAdv,
    size,
  } = useComparison({ nitCompany });

  /**
   * @description Componente unico que retorna el Datatable donde se renderiza los resultados de los comparison
   * @returns { JSX.Element }
   */
  const TabletComponent = (): JSX.Element => {
    return (
      <DataTable
        buttons={getButtons()}
        columns={columns}
        data={comparisons}
        dataDownload={comparisonExcel}
        download={onDownloadData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFilter={handleFilter}
        handleOnOpenAdvSearch={handleOnOpen}
        handleSort={handleSort}
        havePagination={size > 10 ? true : false}
        option={option}
        page={paginatorComparison.paginator.page - 1}
        paginator={paginatorComparison}
        rowsPerPage={paginatorComparison.paginator.itemsPage}
        setLoad={setLoad}
        setOption={setOption}
        setPage={handleChangePage}
        size={size}
        handleCellClick={(
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          value: any,
          _column: Column
        ) => handleCellClick(e, value, _column)}
        notDataFound={
          paginatorComparison!.filter!.length < 3 &&
          !advanceSearchHasValues(fieldsAdvancedSearch, "CreationDate") &&
          comparisons.length === 0
            ? notDataFound(true)
            : (paginatorComparison.filter && comparisons.length === 0) ||
              (advanceSearchState && comparisons.length === 0)
            ? undefined
            : notDataFound()
        }
        setShowFormAdvance={(value: boolean) => {
          setFieldsAdvancedSearch(fieldsAdvanced);
          setShowFormAdv(value);
        }}
        loading={loadingComparison}
      />
    );
  };
  //#endregion

  return (
    <div>
      <AdvancedSearchModal
        open={showFormAdv}
        handleOnClose={handleOnClose}
        handleOnSearch={handleOnSearch}
        fieldsAdvancedSearch={fieldsAdvancedSearch}
        fieldsAdvanced={fieldsAdvanced}
      />
      {/** Renderizar Datatable o el Formulario de comparison */}
      {justTable ? (
        <DataTable
          buttons={getButtons()}
          columns={columns}
          data={comparisons}
          dataDownload={comparisonExcel}
          download={onDownloadData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFilter={handleFilter}
          handleOnOpenAdvSearch={handleOnOpen}
          handleSort={handleSort}
          havePagination={size > 10 ? true : false}
          option={option}
          page={paginatorComparison.paginator.page - 1}
          paginator={paginatorComparison}
          rowsPerPage={paginatorComparison.paginator.itemsPage}
          setLoad={setLoad}
          setOption={setOption}
          setPage={handleChangePage}
          size={size}
          handleCellClick={(e, value, column) => {
            if (handleRowClick) {
              handleRowClick(e, value, column);
            } else {
              console.error("handleRowClick must be configured");
            }
          }}
          notDataFound={
            paginatorComparison!.filter!.length < 3 &&
            !advanceSearchHasValues(fieldsAdvancedSearch, "createdOn") &&
            comparisons.length === 0
              ? notDataFound(true)
              : (paginatorComparison.filter && comparisons.length === 0) ||
                (advanceSearchState && comparisons.length === 0)
              ? undefined
              : notDataFound()
          }
          setShowFormAdvance={(value: boolean) => {
            setFieldsAdvancedSearch(fieldsAdvanced);
            setShowFormAdv(value);
          }}
          loading={loadingComparison}
        />
      ) : showForm ? (
        <ComparisonForm />
      ) : !tabletOnly ? (
        <ViewLayout
          isNew={true}
          isEdit={false}
          headerTitle="Cotejos"
          editOption={false}
        >
          <TabletComponent />
        </ViewLayout>
      ) : (
        <TabletComponent />
      )}
      <FullLoader open={loadingComparison} viewLoader={true} />
    </div>
  );
};

export default ComparisionView;
