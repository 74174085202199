/**
 * Validacion para campos de tipo nombre y apellido
 *
 * @param {string} text - texto a modificar
 * @returns {string} string con solo letras donde cada palabra comienza con la primera letra en mayusculas
 */
const onlyLetters = (text: string): string => {
  const replaceAll = text.toUpperCase();
  text = replaceAll.replace(/[^A-ZÑşŞıİçÇöÖüÜĞğÁÉÍÓÚ ]/g, "");

  return text;
};

/**
 * validacion para campos tipo celular
 *
 * @param {string} number - texto a modificar
 * @returns {string} devuelve string con solo números
 */
const numberCellphone = (number: string): string => {
  number = number.replace(/[^0-9]/g, "").substring(0, 10);
  return number;
};

/**
 * validación para campos tipo cedula
 *
 * @param {string} number - texto a modificar
 * @returns {string} devuelve string con solo números
 */
const CC = (number: string): string => {
  number = number.replace(/[^0-9]/g, "").substring(0, 10);
  return number;
};

/**
 * Validación para campos de tipo email
 *
 * @param {string} text - texto a modificar
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const email = (text: string): string => {
  const lowerCaseAll = text.toLowerCase();
  text = lowerCaseAll.replace(/[^a-zA-Z0-9.@_-]/g, "");
  return text;
};

/**
 * Validacion para los campos de la busqueda avanzada y normal
 *
 * @param {string} text - texto a modificar
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const filters = (text: string): string => {
  text = text.replace(/[^a-zA-Z0-9ñÑ/: .@_-]/g, "");
  return text;
};

/**
 * validatcion para formulario tipo de tramite
 *
 * @param {string} text - texto a modificar
 * @param {string} field - nombre del campo a modificar
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const typeProcessMask = (text: string, field: string): string => {
  switch (field) {
    case "code":
      text = text.replace(/[^a-zA-Z0-9ñÑ _-]/g, "");
      break;
    case "name":
      text = text.replace(/[^a-zA-Z0-9ñÑ @_-]/g, "");
      break;
    case "description":
      text = text.replace(/[^a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ: .,@_-]/g, "");
      break;
    default:
      text = text.replace(/[^a-zA-Z0-9ñÑ _-]/g, "");
      break;
  }
  return text;
};

/**
 * validatcion para formulario parametrizacion
 *
 * @param {string} text - texto a modificar
 * @param {string} field - nombre del campo a modificar
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const parametrizationMask = (text: string, field: string): string => {
  switch (field) {
    case "clientId":
      text = text.replace(/[^a-zA-Z0-9ñÑ _-]/g, "");
      break;
    case "urlATDP":
      text = text.replace(/[^a-zA-Z0-9ñÑ/:._-]/g, "");
      break;
    case "user":
      text = text.replace(/[^a-zA-Z0-9ñÑ ._-]/g, "");
      break;
    default:
      text = text.replace(/[^a-zA-Z0-9ñÑ _-]/g, "");
      break;
  }
  return text;
};

/**
 * ComputerequipmentForm validacion ip solo números y el caracter (.)
 *
 * @param {string} text - IP to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const validationIp = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[0-9.\r]*$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 15);
};

/**
 * ComputerequipmentForm validacion mac solo números letras y los caracteres (: o -)
 *
 * @param {string} text - MAC to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales
 */
const validationMac = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[A-Fa-f0-9-:]$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 17);
};

/**
 * ComputerequipmentForm validacion imei solo números
 *
 * @param {string} number - imei to validate
 * @returns {string} devuelve string con numeros
 */
const validationImei = (number: string) => {
  let chars = number.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[a-zA-Z0-9\r]*$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 16);
};

/**
 * BusinessUnit code validacion solo letras y numeros
 *
 * @param {string} text - code to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales (length : 30)
 */
const validationCode = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[A-Za-z0-9-_]$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 30);
};

/**
 * BusinessUnit y equipment validacion solo letras
 *
 * @param {string} text - text to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales (length : 100)
 */
const validationOnlyLetters = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[A-Za-z0-9 _-]$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 100);
};

/**
 * BusinessUnit validacion para dirección
 *
 * @param {string} text - text to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales (length : 100)
 */
const validationAddress = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[A-Za-z0-9 #-]$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 100);
};

/**
 * Devices validacion para serial
 *
 * @param {string} text - text to validate
 * @returns {string} devuelve string con letras, números y algunos caracteres especiales (length : 50)
 */
const validationSerial = (text: string) => {
  let chars = text.split("");
  let response = "";

  for (const letra of chars) {
    if (letra.match("^[A-Za-z0-9 -]$") !== null) {
      response += letra;
    }
  }
  return response.substring(0, 50);
};

export {
  onlyLetters,
  numberCellphone,
  CC,
  email,
  filters,
  validationIp,
  validationMac,
  validationImei,
  validationCode,
  validationOnlyLetters,
  validationAddress,
  validationSerial,
  typeProcessMask,
  parametrizationMask,
};
