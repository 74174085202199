import React, { useMemo } from "react";
import ViewLayout from "../components/Layouts/ViewLayout";
import { TabAdmin } from "../components/Tabs/TabAdmin";
import { BasicDataContainer } from "@dg-bucaramanga/react-components-dg-qa";
import { CompanyPersonalization } from "@dg-bucaramanga/react-components-dg-qa";
import { CompanyParametrization } from "../components/Parametrization/CompanyParametrization";
import { AuditWrapper } from "../components/Layouts/AuditWrapper";
import useCompanyBasicData from "../hooks/useCompanyBasicData";
import { CompanySelector } from "../store/slices/CompanySlice";
import { useSelector } from "react-redux";

interface CompanyViewProps {
  token: string;
  nitCompany?: String;
}

const CompanyView = ({ token }: CompanyViewProps) => {
  const {
    isEdit,
    tab,
    handleIsEdit,
    handleChangeTab,
    principalColor,
    secondaryColor,
    buttonColor,
    buttonTextColor,
  } = useCompanyBasicData();
  const { currentCompany } = useSelector(CompanySelector);
  const dataCurrentCompany = useMemo(() => currentCompany, [currentCompany]);

  return (
    <ViewLayout
      isNew={false}
      isEdit={isEdit}
      setIsEdit={handleIsEdit}
      headerTitle="Mi empresa"
      indicators={{ currentTab: tab, tabs: 3 }}
    >
      <TabAdmin
        tab={tab}
        handleChangeTab={handleChangeTab}
        content={[
          {
            label: "Datos básicos",
            tabContent: (
              <AuditWrapper>
                <BasicDataContainer
                  token={token}
                  companyId={dataCurrentCompany?.idlicense}
                  color={{
                    buttonColor: buttonColor,
                    buttonTextColor: buttonTextColor,
                  }}
                  personalizationColor={{
                    primaryColor: principalColor,
                    secondaryColor: secondaryColor,
                    buttonColor: buttonColor,
                    textButtonColor: buttonTextColor,
                  }}
                />
              </AuditWrapper>
            ),
          },
          {
            label: "Parametrización",
            tabContent: (
              <AuditWrapper>
                <CompanyParametrization />
              </AuditWrapper>
            ),
          },
          {
            label: "Personalización",
            tabContent: (
              <AuditWrapper>
                <CompanyPersonalization
                  token={token}
                  color={{
                    buttonColor: buttonColor,
                    buttonTextColor: buttonTextColor,
                  }}
                  personalizationColor={{
                    primaryColor: principalColor,
                    secondaryColor: secondaryColor,
                    buttonColor: buttonColor,
                    textButtonColor: buttonTextColor,
                  }}
                />
              </AuditWrapper>
            ),
          },
        ]}
        aria="Enterprise-tabs"
      />
    </ViewLayout>
  );
};

export default React.memo(CompanyView);
