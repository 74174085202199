import { useEffect, useState } from "react";
import { IListChecks } from "../../../store/models/CreateAtdp";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  REQUIRED_MESSAGE_EMAIL_ERROR_ATDP,
  REQUIRED_MESSAGE_EMPTY_FIELD_ATDP,
} from "../../../config/const";
import { email } from "../../../utils/general/masks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InputRounded } from "../../../components/Inputs/InputRounded";
import { regex } from "../../../utils/general/utils";
import { array, object, string } from "yup";

const useStyles = makeStyles((theme: Theme) => {
  return {
    cancelButton: {
      marginRight: "31px",
    },
    dialogContent: {
      marginBottom: 20,
      textAlign: "center",
      fontSize: "14px",
      color: theme.palette.secondary.main,
    },
    typografyName: {
      fontSize: "14px",
      marginBottom: 10,
    },
    div: {
      marginBottom: 10,
      textDecoration: "none",
    },
  };
});

const schemaSendEmailAtdp = object().shape({
  email: array().of(
    object().shape({
      email: string()
        .required(REQUIRED_MESSAGE_EMPTY_FIELD_ATDP)
        .trim()
        .matches(regex.Email, REQUIRED_MESSAGE_EMAIL_ERROR_ATDP),
    })
  ),
});
interface ISendEmailModalProps {
  sendEmailIndividual: IListChecks[];
  setSendEmailIndividual: any;
  sendEmailMultiple: IListChecks[];
  setSendEmailMultiple: Function;
  onConfirmClick?: any;
  onCancelClick?: Function;
  additionalButtons?: any;
  isLoading?: boolean;
  children?: any;
  cancelButtonText?: string;
  confirmationButtonText?: string;
  onClose?: any;
  setOpen?: any;
  customFunction?: any;
}

/**
 * Modal con la estructura de los campos de texto para ingresar correo para el envio del atdp.
 */

const SendEmailModal = ({
  sendEmailIndividual,
  setSendEmailIndividual,
  sendEmailMultiple,
  setSendEmailMultiple,
  onConfirmClick,
  onCancelClick,
  isLoading,
  cancelButtonText,
  confirmationButtonText,
  onClose,
  setOpen,
  customFunction,
}: ISendEmailModalProps) => {
  const classes = useStyles();

  const [
    initialValuesSendEmailIndividual,
    setInitialValuesSendEmailIndividual,
  ] = useState<IListChecks[]>();
  const [initialValuesSendEmailMultiple, setInitialValuesSendEmailMultiple] =
    useState<IListChecks[]>();

  // Efecto que se ejecuta cada vez que ocurre un cambio en los estados que llegan como parametro.
  useEffect(() => {
    // Se verifica parametro de email que llega por base de datos N/A y se cambia valor a vacio ("").
    const handleChangeEmail = (_array: IListChecks[]) => {
      return _array.map((item) => {
        return {
          ...item,
          email: item.email === "N/A" ? "" : item.email,
        };
      });
    };
    // Se valida si el envio es individual o multiple, se lanza la función cambio de email y se actualiza su respectivo estado.
    if (sendEmailIndividual.length === 1) {
      setInitialValuesSendEmailIndividual(
        handleChangeEmail(sendEmailIndividual)
      );
    } else {
      setInitialValuesSendEmailMultiple(handleChangeEmail(sendEmailMultiple));
    }
  }, [sendEmailIndividual, sendEmailMultiple]);

  const {
    control,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<any>({
    shouldUnregister: false,
    mode: "onChange",
    resolver: yupResolver(schemaSendEmailAtdp),
    defaultValues:
      sendEmailIndividual.length === 1
        ? initialValuesSendEmailIndividual
        : initialValuesSendEmailMultiple,
  });

  useEffect(() => {
    // Función de disparador manual de forma o validación de entrada.
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (!confirmationButtonText && !cancelButtonText) {
      setTimeout(() => {
        // Se verifica si customFunction es una función.
        if (typeof customFunction === "function") {
          // Si customFunction es una función, se llama a esa función.
          customFunction();
        } else {
          // Si customFunction no es una función, se establece el estado "open" a falso.
          setOpen(false);
        }
      }, 1500);
    }
  });

  // Region Functions

  const handleChange = (_event: any) => {
    // Valido el valor de entrada al campo.
    let emailChange = email(_event.target.value);
    let element: IListChecks;
    let objectIndividual;

    // Si los valores iniciales es el envio individual recorro el estado inicial y actualizo el email
    if (initialValuesSendEmailIndividual?.length === 1) {
      initialValuesSendEmailIndividual?.map((item, index) => {
        objectIndividual = {
          atdpId: item.atdpId,
          atdpName: item.atdpName,
          email: emailChange,
          names: item.names,
          surnames: item.surnames,
          route: item.route,
          repository: item.repository,
        };

        setValue(`email[${index}].email`, emailChange);
        return objectIndividual;
      });
      setSendEmailIndividual([objectIndividual]);
    } else {
      // Si los valores iniciales es el envio multiple recorro el estado inicial y actualizo el email en el atdp correspondiente.
      initialValuesSendEmailMultiple?.map((item, index) => {
        if (_event.target.name === `email[${index}].email`) {
          element = { ...item, email: emailChange };
          initialValuesSendEmailMultiple.splice(index, 1, element);
          setValue(`email[${index}].email`, emailChange);
        }
        return element;
      });
      setSendEmailMultiple(initialValuesSendEmailMultiple);
    }
  };

  /**
   * Obtiene el mensaje de error correspondiente al índice especificado desde un array de errores.
   *
   * @param { any } errors - El array de errores que contiene objetos con mensajes de error.
   * @param {number} index - El índice del error del cual se desea obtener el mensaje.
   * @returns {string} - El mensaje de error correspondiente al índice dado. Si no hay error en el índice especificado, devuelve una cadena vacía.
   */

  const getErrorValue = (errors: any, index: number) => {
    if (errors === undefined || errors[index] == null) {
      return "";
    }
    return errors[index].email.message;
  };

  /**
   * Formulario que renderiza la cantidad de campos de texto para ingresar el email.
   */

  const RenderInputEmail = (
    <form onChange={handleChange}>
      {initialValuesSendEmailIndividual &&
      initialValuesSendEmailIndividual.length === 1
        ? initialValuesSendEmailIndividual?.map((item, index) => {
            return (
              <InputRounded
                key={item.atdpId}
                control={control}
                label="Email"
                name={`email[${index}].email`}
                defaultValue={item.email}
                props={{
                  name: `email[${index}].email`,
                  helperText: getErrorValue(errors.email, index),
                  error: !!getErrorValue(errors.email, index) ? true : false,
                }}
              />
            );
          })
        : initialValuesSendEmailMultiple &&
          initialValuesSendEmailMultiple.map((item, index) => {
            return (
              <div className={classes.div} key={item.atdpId}>
                <Typography className={classes.typografyName}>
                  <b>{item.names + " " + item.surnames}</b>
                </Typography>
                <InputRounded
                  key={item.atdpId}
                  control={control}
                  label="Email"
                  name={`email[${index}].email`}
                  defaultValue={item.email}
                  props={{
                    name: `email[${index}].email`,
                    helperText: getErrorValue(errors.email, index),
                    error: !!getErrorValue(errors.email, index) ? true : false,
                  }}
                />
              </div>
            );
          })}
    </form>
  );

  /**
   * Renderiza boton de cancelar y confirmación.
   */

  const RenderButton = (
    <DialogContent className={classes.dialogContent}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {!!cancelButtonText && (
            <Button
              variant="contained"
              color="primary"
              onClick={onCancelClick || onClose(false)}
              className={classes.cancelButton}
            >
              {cancelButtonText || "No"}
            </Button>
          )}
          {!!confirmationButtonText && (
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmClick}
              disabled={!isValid}
            >
              {isLoading ? (
                <CircularProgress size={25} color="secondary" />
              ) : (
                confirmationButtonText || "Sí"
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </DialogContent>
  );

  return (
    <>
      <DialogTitle>{RenderInputEmail}</DialogTitle>
      <DialogTitle>{RenderButton}</DialogTitle>
    </>
  );
};

export default SendEmailModal;
