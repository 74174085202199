import { Grid } from "@material-ui/core";
import ViewLayout from "../../components/Layouts/ViewLayout";
import * as yup from "yup";
import { REQUIRED_MESSAGE } from "../../config/const";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFNAFormReport, IReportType } from "../../store/models/FNAModel";
import { useForm } from "react-hook-form";
import { SelectInputRounded } from "../../components/Inputs/SelectInputRounded";
import { DateInputRounded } from "../../components/Inputs/DateInputRounded";
import moment from "moment";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import useFNAReports from "../../hooks/useFNAReports";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { NoBusinessUnitIcon } from "../../components/Icons/NoBusinessUnitIcon";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { useEffect, useState } from "react";
export interface IReport {
  token: string;
  nitCompany?: string;
}

const schema = yup.object().shape({
  reportType: yup.number().required(REQUIRED_MESSAGE),
  initDate: yup
    .date()
    .required("La fecha de inicio es obligatoria")
    .max(new Date(), "La fecha de inicio no puede ser mayor a la fecha actual"),
  endDate: yup
    .date()
    .required("La fecha de inicio es obligatoria")
    .min(
      yup.ref("initDate"),
      "La fecha de fin no puede ser menor a la fecha de inicio"
    ),
});

const FNAReport = ({ token, nitCompany }: IReport) => {
  const {
    getFNAConsumptionReport,
    showModal,
    message,
    isLoading,
    handleCloseModal,
    reportTypeList,
    haveReportsAvailable,
    decodedToken,
  } = useFNAReports({ token, nitCompany });

  const currentForm: IFNAFormReport = {
    reportType: 1,
    initDate: new Date(),
    endDate: undefined,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFNAFormReport>({
    shouldUnregister: false,
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: currentForm,
  });

  const handleForm = () => {};
  const submitForm = (data: IFNAFormReport) => {
    if (data.reportType === 1) {
      getFNAConsumptionReport(data);
    }
  };

  return (
    <ViewLayout
      isNew={true}
      isEdit={false}
      headerTitle="Reportes"
      editOption={false}
    >
      <form onChange={handleForm} onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            {!!decodedToken && haveReportsAvailable(decodedToken) ? (
              <SelectInputRounded
                control={control}
                label={"Tipo de Reporte"}
                name={"reportType"}
                props={{
                  helperText: errors.reportType?.message,
                  error: errors.reportType ? true : false,
                }}
                options={reportTypeList?.map((item) => ({
                  key: item.value,
                  label: item.name,
                  value: item.value,
                }))}
                // onChange={}
              />
            ) : (
              <SelectInputRounded
                control={control}
                label={"Tipo de Reporte"}
                name={"reportType"}
                props={{
                  disabled: true,
                  helperText: errors.reportType?.message,
                  error: errors.reportType ? true : false,
                }}
                options={[]}
                // onChange={}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DateInputRounded
              control={control}
              label={"Fecha de inicio"}
              name={"initDate"}
              props={{
                helperText: errors.initDate?.message,
                error: errors.initDate ? true : false,
              }}
              max={moment().format("YYYY-MM-DD")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DateInputRounded
              control={control}
              label={"Fecha final"}
              name={"endDate"}
              props={{
                helperText: errors.endDate?.message,
                error: errors.endDate ? true : false,
              }}
              max={moment().format("YYYY-MM-DD")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <PrimaryButton
                title={"Descargar Reporte"}
                props={{ type: "submit", disabled: !isValid || !(!!decodedToken && haveReportsAvailable(decodedToken))}}
              />
            </div>
          </Grid>
        </Grid>
        <AlertIconModal
          open={showModal}
          handleClose={() => {
            handleCloseModal();
          }}
          icon={<NoBusinessUnitIcon width={"92px"} height={"80px"} />}
          message={message}
        />
        <FullLoader open={isLoading} viewLoader={true} />
      </form>
    </ViewLayout>
  );
};

export default FNAReport;
