import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import iconDownload from "../../assets/Iconbuttonupload.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    background: theme.palette.common.white,
    borderRadius: 3,
    border: "1px solid #A8A8A8",
    height: 37,
    padding: "0 15%",
    textTransform: "capitalize",
    boxShadow: "none",
    color: "#6D6E71 !important",
    opacity: "1",
    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: "none",
      boxShadow: "none",
    },
  },
}));

const DownloadButtonXlsx = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      {...props}
      startIcon={
        <img src={iconDownload} alt="hands" width={"14px"} height={"14px"} />
      }
    />
  );
};

export default DownloadButtonXlsx;
