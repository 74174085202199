import React from "react";
import { AuditWrapper } from "../../components/Layouts/AuditWrapper";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { TabAdmin } from "../../components/Tabs/TabAdmin";
import ComputerEquipmentForm from "../ComputerEquipment/ComputerEquipmentForm";
import ComputerEquipmentView from "../ComputerEquipment/ComputerEquipmentView";
import DeviceForm from "../Devices/DeviceForm";
import DeviceView from "../Devices/DeviceView";
import BusinessUnitBasicData from "./BusinessUnitBasicData";
import BusinessUnitParametrization from "./BusinessUnitParametrization";
import useBusinessUnitForm from "../../hooks/useBusinessUnitForm";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

const BusinessUnitForm: React.FC = () => {
  const {
    tab,
    showComputers,
    showDevices,
    isEditParametrization,
    diviceAudit,
    computerEquipamentAudit,
    handleBack,
    handleChangeTab,
    handleAudit,
    handleAuditParametrization,
    handleAuditComputer,
    handleAuditDevices,
    handleIsParametrization,
    handleComuterIsEdit,
    handleDeviceEdit,
    handleEditEB,
    handleComputerEquipment,
    handleDevices,
    handleTitle,
    handleEdit,
    setShowComputers,
    setShowDevices,
    currentBusiness,
    isEdit,
    isNew,
    businessUnitAudit,
    parametrizationAudit,
    handleSetEdit,
    loading,
    loadingBusinessForm
  } = useBusinessUnitForm();

  const HandleMenu = () => {
    if (isNew) {
      return [
        {
          label: "Datos básicos",
          tabContent: (
            <AuditWrapper isNew={isNew} editOption={false}>
              <BusinessUnitBasicData />
            </AuditWrapper>
          ),
        },
        {
          label: "Parametrización",
          tabContent: (
            <AuditWrapper isNew={isNew} editOption={true}>
              <BusinessUnitParametrization edit={!isEditParametrization} />
            </AuditWrapper>
          ),
        },
      ];
    } else {
      return [
        {
          label: "Datos básicos",
          tabContent: (
            <AuditWrapper
              isNew={isNew}
              isEdit={isEdit}
              setIsEdit={(editB: boolean) => handleSetEdit(editB)}
              handleAudit={() => handleAudit()}
              editOption={true}
              dataAudit={businessUnitAudit}
              fileName="Auditoría Sede"
            >
              <BusinessUnitBasicData />
            </AuditWrapper>
          ),
        },
        {
          label: "Parametrización",
          tabContent: (
            <AuditWrapper
              isNew={isNew}
              editOption={true}
              isEdit={isEditParametrization}
              setIsEdit={handleIsParametrization}
              handleAudit={() => handleAuditParametrization()}
              dataAudit={parametrizationAudit}
              fileName="Auditoría Parametrización"
            >
              <BusinessUnitParametrization edit={isEditParametrization} />
            </AuditWrapper>
          ),
        },
        {
          label: "Equipos",
          tabContent: (
            <AuditWrapper isNew={!isNew} editOption={false}>
              <ComputerEquipmentView
                justTable={true}
                handleRowClick={handleComputerEquipment}
                businessUnitId={currentBusiness?.id}
                tabletOnly={true}
                hiddeMassiveButton={true}
              />
            </AuditWrapper>
          ),
        },
        {
          label: "Dispositivos",
          tabContent: (
            <AuditWrapper isNew={!isNew} editOption={false}>
              <DeviceView
                justTable={true}
                handleRowClick={handleDevices}
                businessUnitId={currentBusiness?.id}
                tabletOnly={true}
                hiddeMassiveButton={true}
              />
            </AuditWrapper>
          ),
        },
      ];
    }
  };

  const BusinessContent = () => {
    if (showComputers) {
      return (
        <ComputerEquipmentForm
          justForm={true}
          customClose={() => setShowComputers(false)}
        />
      );
    }

    if (showDevices) {
      return (
        <DeviceForm
          justForm={true}
          customClose={() => {
            setShowDevices(false);
          }}
        />
      );
    }

    return (
      <TabAdmin
        tab={tab}
        handleChangeTab={handleChangeTab}
        content={HandleMenu()}
      ></TabAdmin>
    );
  };

  return (
    <div>
      <ViewLayout
        isNew={isNew}
        isEdit={handleEdit()}
        setIsEdit={() => {
          if (showComputers) {
            handleComuterIsEdit();
            return;
          }
          if (showDevices) {
            handleDeviceEdit();
            return;
          }
          handleEditEB();
        }}
        headerTitle={handleTitle()}
        handleIconClick={handleBack}
        editOption={showComputers || showDevices ? true : false}
        handleAudit={() => {
          if (showComputers) {
            handleAuditComputer();
          }
          if (showDevices) {
            handleAuditDevices();
          }
        }}
        dataAudit={showComputers ? computerEquipamentAudit : diviceAudit}
        fileName={showComputers ? "Auditoría Equipo" : "Auditoría Dispositivo"}
      >
        <BusinessContent />
      </ViewLayout>

      <FullLoader open={loading} viewLoader={true} />
      <FullLoader open={loadingBusinessForm} viewLoader={true} />

    </div>
  );
};

export default BusinessUnitForm;
