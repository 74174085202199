import React, { ChangeEvent } from "react";
import { Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { FieldsAdvanceSearchDate } from "../../store/types";
import { getDateByMonth } from "../../utils/general/formatDate";
interface IDateTextFieldProps {
  onChangeDate: Function;
  currentDate: string;
  row: FieldsAdvanceSearchDate;
  errors: string;
  typeDate?: string
  containerStyle?: React.CSSProperties
}

const DateTextField: React.FC<IDateTextFieldProps> = ({
  onChangeDate,
  currentDate,
  row,
  errors,
  typeDate = 'date',
  containerStyle
}) => {

  const classes = useStyles();

  return (
    <>
      <Grid container item lg={12} md={12} sm={12} xs={12} key={row.name}>
        <div>&nbsp;</div>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <label>{row.title}</label>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid
            item
            xs={row?.onlyOne ? 12 : 6}
            className={classes.containerDate}
            style={containerStyle}
          >
            <TextField
              fullWidth={row?.onlyOne}
              id={"startDate_" + row.name}
              label="Fecha de Inicio"
              inputProps={{
                max: typeDate === 'date' ? currentDate : getDateByMonth(),
              }}
              type={typeDate}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (row?.onlyOne) {
                  onChangeDate(row.name, event, "only");
                } else {
                  onChangeDate(row.name, event, "st");
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={!!row.value ? row.value.toString().split(":")[0] : ""}
            />
          </Grid>
          {row?.onlyOne ?? (
            <Grid item xs={6} className={classes.containerDate}>
              <TextField
                type={typeDate}
                id={"endDate_" + row.name}
                label="Fecha de Final"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: typeDate === 'date' ? currentDate : getDateByMonth(),
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChangeDate(row.name, event, "fn");
                }}
                value={
                  !!row.value ? row.value.toString().split(":")[1] || "" : ""
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {errors !== "" ? (
        <Grid item xs={12} className={classes.containerDate}>
          <Typography
            variant="body1"
            color="error"
            className={classes.messageTypography}
          >
            {errors}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: any) => ({
  messageTypography: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
  containerDate: {
    paddingRight: theme.spacing(1),
    paddingTop: 20,
    paddingLeft: theme.spacing(1),
  },
}));

export default DateTextField;
