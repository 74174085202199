import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComparisonLogResponse } from "../models/AuditLogModel";
import {
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
  SortOptionLog,
} from "../types";
import { RootState } from "./rootReducer";

/**
 * @type auditlog state definition
 *  */

type AuditLogState = {
  auditLogComparison: ComparisonLogResponse[];
  paginatorAuditLog: PaginatorWithFilterAndSort;
  size: number;
  loadingAuditLog: boolean;
};

/**
 * @property state initialization
 */

const initialState: AuditLogState = {
  auditLogComparison: [],
  paginatorAuditLog: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    sort: "recent",
    filter: "",
    sortOptionLog: "comparison",
  },
  size: 0,
  loadingAuditLog: false,
};

/**
 * @function AuditLogSlice redux state slice
 *
 * control over auditlog state
 */

const AuditLogSlice = createSlice({
  name: "AuditLog",
  initialState,
  reducers: {
    setAuditLogComparison: (
      state,
      { payload }: PayloadAction<ComparisonLogResponse[]>
    ) => {
      state.auditLogComparison = payload;
    },
    setPaginatorAuditLog: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) payload.page = 1;
      state.paginatorAuditLog.paginator = payload;
    },
    setOptionLog: (state, { payload }: PayloadAction<SortOptionLog>) => {
      state.paginatorAuditLog.sortOptionLog = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setFilterAuditLog: (state, { payload }: PayloadAction<string>) => {
      state.paginatorAuditLog.filter = payload;
    },
    setSortAuditLog: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorAuditLog.sort = payload;
    },
    setLoadingAuditLog: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingAuditLog = payload;
    },
  },
});

export const {
  setAuditLogComparison,
  setPaginatorAuditLog,
  setOptionLog,
  setFilterAuditLog,
  setSize,
  setSortAuditLog,
  setLoadingAuditLog,
} = AuditLogSlice.actions;

export const AuditLogSelector = (state: RootState) => state.auditLog;

export default AuditLogSlice.reducer;
