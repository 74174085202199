import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { IValidateVersion } from "../models/IApplicationUpdater";

export interface AuthError {
  message: string;
}
export interface AuthState {
  isAuth: boolean;
  currentUser?: CurrentUser;
  isLoading: boolean;
  error: AuthError;
  tokens: token;
  updateDesktop?: IValidateVersion;
}

export type token = {
  result: {
    token: string;
    refreshToken: string;
  };
  error: any;
};
export interface CurrentUser {
  id: string;
  display_name: string;
  email: string;
  photo_url: string;
}
export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  error: { message: "An Error occurred" },
  tokens: {
    error: "",
    result: {
      token: "",
      refreshToken: "",
    },
  },
  updateDesktop: {
    Version: "",
    InstallerName: "",
    Update: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<AuthState>) => {
      state.isLoading = payload.isLoading;
    },
    setAuthSuccess: (state, { payload }: PayloadAction<AuthState>) => {
      state.currentUser = payload.currentUser;
      state.isAuth = true;
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.currentUser = undefined;
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthState>) => {
      state.error = payload.error;
      state.isAuth = false;
    },
    setTokens: (state, { payload }: PayloadAction<token>) => {
      state.tokens = payload;
      sessionStorage.setItem("jwtToken", payload.result.token);
      sessionStorage.setItem("refreshToken", payload.result.refreshToken);
    },
    setAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload;
    },
    setUpdateDesktop: (state, { payload }: PayloadAction<IValidateVersion>) => {
      state.updateDesktop = payload;
    },
  },
});

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setAuthFailed,
  setTokens,
  setAuth,
  setUpdateDesktop,
} = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
