import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import {
  advanceSearchComparisonAC,
  getAllComparisonAC,
  getFilterAC,
  getPaginatorAC,
  getSortAC,
} from "../store/action-creators/ComparisonCreators";

//SLICES
import { CommonSelector, setLoading } from "../store/slices/CommonSlice";
import {
  ComparisonSelector,
  setCurrentComparison,
  setShowForm,
} from "../store/slices/ComparisonSlice";

//MODELS
import { ComparisonResponse } from "../store/models/ComparisonModel";

//TYPES
import { Column } from "../components/Table/types";
import { FieldsAdvanceSearch, sortBy } from "../store/types";

//HRLPERS
import {
  advanceSearchHasValues,
  downloadDataFromBase64ToZip,
} from "../utils/general/utils";
import { getComparisonExcelService } from "../services/ComparisonService";
import { formatDateIso } from "../utils/general/formatDate";

interface ComparisonProps {
  nitCompany?: string;
}

/**
 * Este Hook encapsula las funciones, useEffect y validaciones de la vista de comparison
 * @param { string } nitCompany - Parametro de tipo string que representa el numero de identificacion tributaria de la compañia
 */
const useComparison = ({ nitCompany }: ComparisonProps) => {
  const {
    comparisonExcel,
    comparisons,
    loadingComparison,
    paginatorComparison,
    showForm,
    size,
  } = useSelector(ComparisonSelector);
  const { loading } = useSelector(CommonSelector);
  const dispatch = useDispatch();
  const [option, setOption] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoad, setLoad] = useState(null);
  const [showFormAdv, setShowFormAdv] = useState(false);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);

  /** Array de objetos que representan los inputs para el filtro de busqueda avanzada */
  const fieldsAdvanced: FieldsAdvanceSearch[] = [
    {
      name: "fullName",
      title: "Nombres y Apellidos",
      type: "text",
      value: "",
    },
    {
      name: "sede",
      title: "Sede",
      type: "text",
      value: "",
    },
    {
      name: "nut",
      title: "NUT",
      type: "text",
      value: "",
    },
    {
      name: "documentNumber",
      title: "Número de documento",
      type: "text",
      value: "",
    },

    {
      name: "typeStation",
      title: "Tipo de estación",
      type: "selectTypeWorkstation",
      value: "",
    },

    {
      name: "latitude",
      title: "Latitud",
      type: "text",
      value: "",
    },

    {
      name: "longitude",
      title: "Longitud",
      type: "text",
      value: "",
    },
    {
      name: "CreationDate",
      title: "Fecha de creación",
      type: "date",
      value: undefined,
    },
  ];
  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    useState<FieldsAdvanceSearch[]>(fieldsAdvanced);

  const nameDate = "CreationDate";

  /**
   * Asegura que cada vez que entre al módulo muestre la tabla
   * en la primera página el filtro este vacio y el ordenamieto sea reciente
   */
  useEffect(() => {
    dispatch(setShowForm(false));
    return () => {
      dispatch(getFilterAC(""));
      dispatch(getSortAC("recent"));
      dispatch(getPaginatorAC(1, 10, true));
    };
  }, [dispatch]);

  /** Se espera algun cambio en el hook paginatorComparison para lanzar la busqueda y se verifica si existe el filtro avanzado para ejecutarlo o lanzar la consulta inicial */
  useEffect(() => {
    const { paginator, filter } = paginatorComparison;
    if (paginator !== undefined) {
      if (advanceSearchState) {
        dispatch(
          advanceSearchComparisonAC({
            /**
             * Verfieldsifica que no hayan busquedas (normal y avanzada)
             */
            fields: !advanceSearchHasValues(fieldsAdvancedSearch, nameDate)
              ? fieldsAdvancedSearch.map((field: FieldsAdvanceSearch) => {
                  if (field.name === nameDate) {
                    field = {
                      ...field,
                      value: `${formatDateIso()}:${formatDateIso()}`,
                    };
                    return field;
                  }
                  return field;
                })
              : fieldsAdvancedSearch,
            paginator: paginatorComparison,
            company: nitCompany,
          })
        );
      } else {
        dispatch(
          getAllComparisonAC(
            {
              ...paginatorComparison,
              filter: filter!.length < 3 ? formatDateIso() : filter,
            },
            nitCompany
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorComparison]);

  //#region  handling events
  /** Metodo que se ejecuta al seleccionar un registro en el Datatable de Comparison y lanzar el dispatch para mostrar el formulario con la informacion del comparison*/
  const handleCellClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    column: Column
  ) => {
    const currentComparison: ComparisonResponse = {
      id: value.id,
      nut: value.nut,
      initialDate: value.initialDate,
      finalDate: value.finalDate,
      names: value.names,
      surnames: value.surnames,
      detail: value.detail,
      documentNumber: value.documentNumber,
      phone: value.phone,
      email: value.email,
      documentType: value.documentType,
      process: value.process,
      idprocess: value.idprocess,
      fullName: value.fullName,
      result: value.result === null ? [] : value.result,
      scoreFinger1: value.result !== null && value?.result?.[0]?.score,
      scoreFinger2: value.result !== null && value?.result?.[1]?.score,
      typeStation: value.typeStation,
      latitude: value.latitude,
      longitude: value.longitude,
      manualCaptureJustification: value.manualCaptureJustification,
    };
    dispatch(setShowForm(true));
    dispatch(setCurrentComparison(currentComparison));
  };

  /** Metodo para cambiar numero de pagina */
  const handleChangePage = (_event: any, newPage: any) => {
    dispatch(
      getPaginatorAC(newPage + 1, paginatorComparison.paginator.itemsPage, true)
    );
  };

  /** Metodo para cambiar cantidad de registros por pagina y reiniciar el numero de pagina */
  const handleChangeRowsPerPage = (event: any) => {
    dispatch(getPaginatorAC(1, +event.target.value, true));
  };

  /** Metodo para lanzar filtro basico */
  const handleFilter = (query: string) => {
    setAdvanceSearchState(false);
    setFieldsAdvancedSearch(fieldsAdvanced);
    dispatch(getFilterAC(query.trim()));
    dispatch(getPaginatorAC(1, paginatorComparison.paginator.itemsPage, true));
  };

  const handleOnClose = () => {
    setShowFormAdv(false);
  };

  const handleOnOpen = () => {
    setShowFormAdv(true);
  };

  /** Metodo para lanzar filtro avanzado y reiniciar el numero de pagina */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAC(""));
    setAdvanceSearchState(true);
    setShowFormAdv(false);
    setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    dispatch(getPaginatorAC(1, paginatorComparison.paginator.itemsPage, true));
  };

  /** Ordenar los resultados mediante la seleccion del sort */
  const handleSort = (sort?: sortBy) => {
    if (sort === "active" || sort === "a-z") {
      dispatch(getSortAC("recent"));
    } else if (sort === "inactive" || sort === "z-a") {
      dispatch(getSortAC("old"));
    }
    if (paginatorComparison.paginator.page !== 1) {
      dispatch(
        getPaginatorAC(1, paginatorComparison.paginator.itemsPage, true)
      );
    }
  };

  //#region  table aditional data
  /** Metodo para preparar la descarga de los registros*/
  const onDownloadData = () => {
    const { filter } = paginatorComparison;

    dispatch(setLoading(true));

    getComparisonExcelService({
      /**
       * Verfieldsifica que no hayan busquedas (normal y avanzada)
       */
      fields:
        filter!.length < 3 &&
        !advanceSearchHasValues(fieldsAdvancedSearch, nameDate)
          ? fieldsAdvancedSearch.map((field: FieldsAdvanceSearch) => {
              if (field.name === nameDate) {
                field = {
                  ...field,
                  value: `${formatDateIso()}:${formatDateIso()}`,
                };
                return field;
              }
              return field;
            })
          : fieldsAdvancedSearch,
      paginator: paginatorComparison,
      company: nitCompany,
    })
      .then((resp) => {
        const { downloadRoute, nameFile } = resp.result;

        downloadDataFromBase64ToZip(downloadRoute, nameFile);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  //#endregion

  return {
    advanceSearchState,
    comparisonExcel,
    comparisons,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleOnClose,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    loadingComparison,
    onDownloadData,
    option,
    paginatorComparison,
    setFieldsAdvancedSearch,
    setLoad,
    setOption,
    setShowFormAdv,
    showForm,
    showFormAdv,
    size,
  };
};

export default useComparison;
