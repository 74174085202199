import React from "react";

import { Route, useHistory } from "react-router-dom";
import Home from "../views/Home";
import { useSelector } from "react-redux";
import { BIOMETRICS_LOGIN } from "../config/const";
import { setAuth } from "../store/slices/AuthSlice";
import { CommonSelector } from "../store/slices/CommonSlice";
// import useAuth from "../hooks/useAuth";

interface AuthObj {
  name: string;
  authoredRoutes: string[];
}

const authorizationsByRol: AuthObj[] = [
  {
    name: "BIOMETRIA RNEC",
    authoredRoutes: [
      "/",
      "/searchatdp",
      "/auditlog",
      "/companylist",
      "/businessUnit",
      "/computers",
      "/devices",
      "/procedure",
      "/users",
      "/notFound",
    ],
  },
  {
    name: "BIOMETRIA ADMIN",
    authoredRoutes: [
      "/",
      "/dashboard",
      "/adminuser",
      "/searchatdp",
      "/company",
      "/businessUnit",
      "/computers",
      "/devices",
      "/procedure",
      "/comparison",
      "/notFound",
      "/fnaReport",
    ],
  },
  {
    name: "BIOMETRIA CAPTURADOR",
    authoredRoutes: [
      "/",
      "/dashboard",
      "/fingerprint",
      "/searchatdp",
      "/comparison",
      "/notFound",
    ],
  },
  {
    name: "BIOMETRIA SUPERADMIN",
    authoredRoutes: [
      "/",
      "/dashboard",
      "/adminuser",
      "/searchatdp",
      "/company",
      "/businessUnit",
      "/computers",
      "/devices",
      "/procedure",
      "/comparison",
      "/notFound",
      "/fnaReport",
    ],
  },
  {
    name: "BIOMETRIA CAPTURADOR MOVIL",
    authoredRoutes: ["/fingerprint"],
  },
  {
    name: "BIOMETRIA CAPTURA MOVIL",
    authoredRoutes: ["/fingerprint"],
  },
];

const PrivateRoute: React.FC<{
  Component: React.FC<any>;
  path: string;
  exact: boolean;
  menuHide?: boolean;
}> = React.memo(({ menuHide, Component, exact, path }) => {
  // private state
  const dataCompany = sessionStorage.getItem("nitCompany") || "";
  // store
  const { rol } = useSelector(CommonSelector);

  const history = useHistory();

  const isAuthoredToNav = (findedRoutes: AuthObj) => {
    return findedRoutes.authoredRoutes.includes(path) ? true : false;
  };
  // React HOOKS
  React.useEffect((): any => {
    const encryptedToken = window.location.pathname.substring(1);
    if (encryptedToken.length < 50) {
      const token = sessionStorage.getItem("jwtToken");
      if (token) {
        setAuth(true);
      } else {
        window.location.replace(BIOMETRICS_LOGIN);
      }
    }
  }, []);

  // Custom functions

  React.useEffect(() => {
    const findedRoutes =
      authorizationsByRol.find((e) => e.name === rol) || null;
    if (findedRoutes !== null) {
      if (path === "/" && rol === "BIOMETRIA RNEC") {
        if (!!dataCompany) {
          isAuthoredToNav(findedRoutes) && history.push("/businessunit");
        } else {
          isAuthoredToNav(findedRoutes) && history.push("/companylist");
        }
      } else if (path !== "/") {
        isAuthoredToNav(findedRoutes) || history.push("/notfound");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rol, dataCompany]);

  return (
    <>
      {
        <Route
          path={path}
          exact={exact}
          render={(props) => (
            <>
              <Home menuHide={menuHide}>
                <Component {...props} />
              </Home>
            </>
          )}
        />
      }
    </>
  );
});

export default PrivateRoute;
