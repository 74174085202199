import { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Badge, Box, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Notifications } from "@material-ui/icons";
import {
  DashboardSelector,
  setNotificationDashboard,
} from "../../store/slices/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Marquee from "../Marquee";
import moment from "moment";
import { CustomerConsumption } from "../../store/models/DashboardModel";
import {
  getCustomerPlanExpiredAC,
  getNextBalanceToExpireAC,
  getNextPlanExpirationsAC,
} from "../../store/action-creators/DashboardActionCreators";
import {
  addNotificationsAC,
  getUserNotificationsAC,
  readNotificationAC,
} from "../../store/action-creators/NotificationActionCreators";
import { INotification } from "../../store/models/NotificationModel";
import { NotificationSelector } from "../../store/slices/NotificationsSlice";
import CloseIcon from '@material-ui/icons/Close';

const colorsState = [
  {
    id: 1,
    color: "#6a6a6a",
    borderBottom: "1.5px #00800073 solid",
    background: "#00800008",
  },
  {
    id: 2,
    color: "#6a6a6a",
    borderBottom: "1.5px #ffa500bf solid",
    background: "#ffa50014",
  },
  {
    id: 3,
    color: "#6a6a6a",
    borderBottom: "1.5px #ff00005c solid",
    background: "#ff00000a",
  },
];
const DropDownNotification = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [listNotification, setListNotification] = useState<INotification[]>([]);
  const [current_page, setCurrent_page] = useState(1);
  const [obj_per_page, setObj_per_page] = useState(10);

  const dispatch = useDispatch();
  const { nextBalanceToExpire, customerPlanExpired, nextPlanExpirations } =
    useSelector(DashboardSelector);
  const { notifications } = useSelector(NotificationSelector);

  useEffect(() => {
    if (notifications) {
      setListNotification(notifications);
      change(current_page);
    }
  }, [notifications]);

  function totNumPages() {
    return Math.ceil(notifications.length / obj_per_page);
  }

  function prevPage() {
    if (current_page > 1) {
      setCurrent_page(current_page - 1);
      change(current_page - 1);
    }
  }
  function nextPage() {
    if (current_page < totNumPages()) {
      setCurrent_page(current_page + 1);
      change(current_page + 1);
    }
  }

  function itemsPerPage(number: number) {
    setObj_per_page(number);
    change(current_page, number);
  }

  const calculatePercent = (total: number, actual: number) =>
    Number.parseFloat((total - actual).toString())
      .toFixed(1)
      .toString();

  function change(page: number, per_page: number = obj_per_page) {
    if (page < 1) page = 1;
    if (page > totNumPages()) page = totNumPages();
    const newListFiltered = [];
    if (notifications) {
      for (var i = (page - 1) * per_page; i < page * per_page; i++) {
        if (notifications[i] !== undefined) {
          newListFiltered.push(notifications[i]);
        }
      }
      setListNotification(newListFiltered);
    }
  }
  useEffect(() => {
    const response: CustomerConsumption = {
      DocumentType: "nit",
      DocumentNumber: "900087497",
      ServiceName: "Biometria",
      QuantityCustomer: 5,
    };
    dispatch(getNextBalanceToExpireAC(response));
    dispatch(getCustomerPlanExpiredAC(response));
    dispatch(getNextPlanExpirationsAC(response));
    dispatch(getUserNotificationsAC());
  }, [dispatch]);

  useEffect(() => {
    const nextBalance: INotification[] = [];
    nextBalanceToExpire?.map((company) =>
      nextBalance.push({
        notificationTitle: `${company.customerName} próximo a vencer cupos`,
        notificationDescription: `disponible menos del ${calculatePercent(100, company.usagePercentage)}%`,
        notificationStatus: 1,
        notificationReceptionDate: new Date(),
        senderNotification: "Licencement",
        userReadNotification: 0,
        nitCompany: company.documentNumber,
        typeNotifications: 1,
      })
    );
    dispatch(addNotificationsAC(nextBalance));
    change(current_page);
  }, [nextBalanceToExpire]);

  useEffect(() => {
    const planExpired: INotification[] = [];
    customerPlanExpired?.map((company) =>
      planExpired.push({
        notificationTitle: `${company.customerName}`,
        notificationDescription: `Empresa con contrato vencido`,
        notificationStatus: 1,
        notificationReceptionDate: new Date(),
        senderNotification: "Licencement",
        userReadNotification: 0,
        nitCompany: company.documentNumber,
        typeNotifications: 2,
      })
    );
    dispatch(addNotificationsAC(planExpired));
    change(current_page);
  }, [customerPlanExpired]);

  useEffect(() => {
    const nexExpiration: INotification[] = [];
    nextPlanExpirations?.map((company) =>
      nexExpiration.push({
        notificationTitle: `${company.customerName} próxima a vencer contrato`,
        notificationDescription: `vence: ${moment
          .duration(moment(new Date(company.expirationDate)).diff(moment()))
          .humanize(true)}`,
        notificationStatus: 1,
        notificationReceptionDate: new Date(),
        senderNotification: "Licencement",
        userReadNotification: 0,
        nitCompany: company.documentNumber,
        typeNotifications: 3,
      })
    );
    dispatch(addNotificationsAC(nexExpiration));
    change(current_page);
  }, [nextPlanExpirations]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (notifications.length > 0) {
      change(current_page);
    }
  }, [notifications]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFirst = (string: string) => {
    let palabras = string.split(/\s+/);
    let primerasLetras = palabras.map((palabra) => palabra[0]);
    return primerasLetras.join("").substr(0, 2);
  };

  const IndividualCardNotification = ({ data }: { data: INotification }) => {
    return (
      <Box
        onClick={() =>
          data.id
            ? dispatch(
                readNotificationAC({ id: data.id, notificationStatus: 2 })
              )
            : null
        }
        style={{ width: "100%" }}
      >
        <Grid container>
          <Grid item xs={2}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <p>{getFirst(data.notificationTitle)}</p>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box style={{ width: "100%", padding: 0, whiteSpace: "normal" }}>
              <Typography variant="subtitle1" style={{ fontSize: 12 }}>
                <Marquee style={{ fontSize: 12 }}>
                  {data.notificationTitle}
                </Marquee>
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                style={{
                  fontSize: 10,
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {data.notificationDescription}
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: 10 }}>
                {moment
                  .duration(
                    moment(new Date(data.notificationReceptionDate)).diff(
                      moment()
                    )
                  )
                  .humanize(true)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              style={{
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 8,
              }}
            >
              <IconButton aria-label="close" onClick={() => null}>
                <CloseIcon style={{ fontSize: "9pt" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const ListControl = () => {
    return (
      <Grid container style={{ overflowY: "auto", paddingRight: 0 }}>
        <Grid item xs={6}>
          <Box
            style={{
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="caption"
              style={{ fontSize: 10, justifyContent: "center" }}
            >{`Pagina ${current_page}`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ paddingRight: 10 }}>
            <Grid container>
              <Grid item xs={4}>
                <IconButton
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={prevPage}
                  disabled={current_page <= 1 ? true : false}
                >
                  <ArrowBackIosIcon style={{ fontSize: 12 }} />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Box
                  style={{ alignContent: "center", justifyContent: "center" }}
                >
                  <select
                    style={{
                      width: "25px",
                      height: "20px",
                      marginTop: "8px",
                      borderRadius: "4px",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                    value={obj_per_page}
                    onChange={(e) => itemsPerPage(parseInt(e.target.value))}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={nextPage}
                  disabled={current_page >= totNumPages() ? true : false}
                >
                  <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <IconButton
        aria-controls="drop-dow-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge
          color="secondary"
          badgeContent={
            notifications && notifications.length > 9
              ? "9+"
              : notifications
              ? notifications.length
              : 0
          }
        >
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        id="drop-dow-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          position: "absolute",
          marginTop: "45px",
          padding: 0,
          overflowY: "auto",
          paddingRight: 0,
        }}
        disableScrollLock={true}
      >
        <ListControl />
        {listNotification.length > 0 ? (
          listNotification?.map((not) => (
            <MenuItem
              onClick={handleClose}
              style={{
                width: "250px",
                padding: 2,
                marginBottom: 3,
                borderRadius: 6,
                ...colorsState.find((c) => c.id === not.typeNotifications),
              }}
            >
              <IndividualCardNotification data={not} />
            </MenuItem>
          ))
        ) : (
          <Typography variant="subtitle1" style={{ fontSize: 12 }}>
            No se encontraron resultados de alertas de paquetes
          </Typography>
        )}
      </Menu>
    </div>
  );
};

export default DropDownNotification;
