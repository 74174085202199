import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, ICompany } from "../models/Company";
import { CompanyState, Paginator, sortBy, SortOptionLog } from "../types";
import { RootState } from "./rootReducer";
import { ICustomerConsumption } from "../models/DashboardModel";

const initialState: CompanyState = {
  success: "",
  error: "",
  isLoading: false,
  showForm: false,
  companies: [],
  currentCompany: undefined,
  paginatorCompany: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    sort: "recent",
    sortOptionLog: undefined,
  },
  sizeCompany: 0,
  isInerit: true,
  loading: false,
  license: []
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSuccess: (state, success: PayloadAction<string>) => {
      state.success = success.payload;
    },
    setError: (state, error: PayloadAction<string>) => {
      state.error = error.payload;
    },
    setLoading: (state, loading: PayloadAction<boolean>) => {
      state.loading = loading.payload;
    },
    setCurrentCompany: (state, currentCompany: PayloadAction<Company>) => {
      state.currentCompany = currentCompany.payload;
    },
    setCompanies: (state, companies: PayloadAction<ICompany[]>) => {
      state.companies = companies.payload;
    },
    setShowForm: (state, showForm: PayloadAction<boolean>) => {
      state.showForm = showForm.payload;
    },
    setPaginatorCompany: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorCompany.paginator = payload;
    },
    setSizeCompany: (state, { payload }: PayloadAction<number>) => {
      state.sizeCompany = payload;
    },
    setFilterCompany: (state, { payload }: PayloadAction<string>) => {
      state.paginatorCompany.filter = payload;
    },
    setSortCompany: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorCompany.sort = payload;
    },
    setSortLogCompany: (state, { payload }: PayloadAction<SortOptionLog>) => {
      state.paginatorCompany.sortOptionLog = payload;
    },
    setCustomerLicense: (state, { payload }: PayloadAction<ICustomerConsumption[]>) => {
      state.license = payload;
    },
  },
});

export const {
  setCurrentCompany,
  setError,
  setLoading,
  setSuccess,
  setShowForm,
  setCompanies,
  setPaginatorCompany,
  setSizeCompany,
  setFilterCompany,
  setSortCompany,
  setSortLogCompany,
  setCustomerLicense
} = companySlice.actions;

export const CompanySelector = (state: RootState) => state.company;

export default companySlice.reducer;
