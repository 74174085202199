import axios, { AxiosResponse } from "axios";
import { defaultHeaders, getCurrentJWT } from "../config/config";
import { BIOMETRICS_URL, API_LICENSE } from "../config/const";

import {
  BasicResponse,
  CitiesResponse,
  ListBasicResponse,
  StatesAndCitiesResponse,
  TypeEquipmentResponse,
} from "../store/models/ListBasicResponse";
import { StandardResponse } from "../type";

const GET_BRANDS = "Brand/Brand";
const GET_MODELS = "Device/models";
const GET_BUSINESS_UNIT = "BusinessUnit/basic";
const GET_COMPANY_LIST = "Company/getListCompany";
const GET_DEVICE_TYPE = "DeviceType/DeviceType";
const GET_DEVICE_TYPE_MODEL = "DeviceType/DeviceTypeModel";
const GET_OPERATING_SYSTEMS = "OperatingSystem/OperatingSystem";
const GET_TYPE_COMPUTER = "TypeComputerEquipment";
const REPOSITORIES_URL = "Repository/repositories";
const STATE_CITIES_URL = "parameter/getListStatesAndCities";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

/**
 * @description Servicio de biometria que obtiene las marcas de los dispositivos existentes en la base de datos
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */
export const GetAllBrands = async (): Promise<
  StandardResponse<ListBasicResponse<BasicResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(`${BIOMETRICS_URL}${GET_BRANDS}`, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene los modelos existentes de una marca seleccionada
 * @param { number } brand Parametro de tipo number que representa el id de la marca
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */
export const getAllModels = async (
  brand: number
): Promise<StandardResponse<ListBasicResponse<BasicResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(`${BIOMETRICS_URL}${GET_MODELS}`, {
      headers: {
        brand,
        ...getCurrentJWT(),
      },
    });
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene todas las sedes existentes de una empresa
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */
export const getAllBusinessUnits = async (): Promise<
  StandardResponse<ListBasicResponse<BasicResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(
      `${BIOMETRICS_URL}${GET_BUSINESS_UNIT}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna todas las compañias creadas y activas
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */
export const getListCompany = async (): Promise<
  StandardResponse<ListBasicResponse<BasicResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(
      `${BIOMETRICS_URL}${GET_COMPANY_LIST}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que consulta todos los tipos de dispositivos
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */
export const getAllDeviceTypes = async (): Promise<
  StandardResponse<ListBasicResponse<BasicResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(
      `${BIOMETRICS_URL}${GET_DEVICE_TYPE}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene todos los sistemas operativos existentes en la base de datos
 * @returns { ListBasicResponse<TypeEquipmentResponse> } TypeEquipmentResponse
 */
export const getOperatingSystems = async (): Promise<
  StandardResponse<ListBasicResponse<TypeEquipmentResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<TypeEquipmentResponse>>
    > = await instance.get(
      `${BIOMETRICS_URL}${GET_OPERATING_SYSTEMS}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna los tipos de equipos existentes en la base de datos
 * @returns { ListBasicResponse<TypeEquipmentResponse> } TypeEquipmentResponse
 */
export const getTypeComputerEquipments = async (): Promise<
  StandardResponse<ListBasicResponse<TypeEquipmentResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<TypeEquipmentResponse>>
    > = await instance.get(
      `${BIOMETRICS_URL}${GET_TYPE_COMPUTER}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna todos los departamentos y ciudades existentes en la base de datos
 * @returns { ListBasicResponse<StatesAndCitiesResponse<CitiesResponse>> } CitiesResponse
 */
export const getListStatesAndCitiesService = async (): Promise<
  StandardResponse<ListBasicResponse<StatesAndCitiesResponse<CitiesResponse>>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<
        ListBasicResponse<StatesAndCitiesResponse<CitiesResponse>>
      >
    > = await instance.get(
      `${API_LICENSE}/license/api/${STATE_CITIES_URL}`,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description get information about possible repositories to save information
 * @param {string} token
 * @returns { ListBasicResponse<BasicResponse> } BasicResponse
 */

export const getRepositories = async (
  token: string
): Promise<StandardResponse<ListBasicResponse<BasicResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await axios.get(`${BIOMETRICS_URL}${REPOSITORIES_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description get device type by id model device
 * @param { string } device
 * @returns { StandardResponse<BasicResponse> } BasicResponse
 */
export const getDeviceTypesByModel = async (
  device: number
): Promise<StandardResponse<BasicResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<BasicResponse>> =
      await instance.post(
        `${BIOMETRICS_URL}${GET_DEVICE_TYPE_MODEL}`,
        { device },
        defaultHeaders()
      );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
