import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import React, { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import IconExpedition from "../../assets/iconexpeditionplace.svg";
import "../Atdp/toolbar.css";

const initialState = {
  mouseX: null,
  mouseY: null,
};

type UserInfoAtdp = {
  name: string;
  surnames: string;
  documentNumber: string;
  expeditionPlace?: string;
  qrCode?: string;
};

type EditableSectionProps = {
  user?: UserInfoAtdp;
  isBody?: boolean;
  insertVariables: Function;
  refProp: any;
  handleClose: Function;
  sizeProp: any;
  id: any;
};

const EditableSection: React.FC<EditableSectionProps> = React.forwardRef(
  ({ user, insertVariables, handleClose, refProp, sizeProp, id }) => {
    const [state, setState] = React.useState<{
      mouseX: null | number;
      mouseY: null | number;
    }>(initialState);

    const [anchorMenu, setCloseMenu] = React.useState(false);

    const internalRef = useRef<any>(null);
    let Size = Quill.import("attributors/style/size");
    Size.whitelist = ["12px", "16px", "24px", "32px"];
    Quill.register(Size, true);
    var icons = Quill.import("ui/icons");

    const Font = Quill.import("formats/font");
    Font.whitelist = [
      "arial",
      "courier-new",
      "georgia",
      "helvetica",
      "lucida",
      "Verdana",
      "Ubuntu",
      "Raleway",
      "Roboto",
      "Montserrat",
      "Inconsolata",
    ];
    Quill.register(Font, true);

    icons["color"] = "";
    const modules = {
      toolbar: {
        container: `#toolbar-${id}`,
        handlers: {
          insertStar: "insertStar",
        },
        syntax: true,
      },
      clipboard: {
        matchVisual: false,
      },
    };

    const formats = [
      "header",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "align",
      "link",
      "image",
      "color",
      "font",
    ];

    //#region
    React.useEffect(() => {
      if (internalRef) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // const editor = pruebaRef.current.getEditor()
        // editor.insertText(0, 'hell', 'bold', true)
        // ref = internalRef;
      }
    }, [internalRef]);
    //#endregion

    //#region  event handler region

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setCloseMenu(true);
    };

    const handleCloseMenu = (event: React.MouseEvent<HTMLDivElement>) => {
      setCloseMenu(false);
    };

    //#endregion
    return (
      <div
        className="text-editor"
        onContextMenu={handleClick}
        style={{ cursor: "context-menu" }}
      >
        <div className="grid-container">
          <CustomToolBarM id={id} />
          <div className="tools-container">
            <hr className="custom-hr"></hr>
            <span>Insertar</span>
            <Tooltip title={"Nombre"} placement="top">
              <div
                className="icon-names ql-icon"
                style={{ cursor: "pointer" }}
                onClick={() => insertVariables(user?.name)}
              ></div>
            </Tooltip>
            <Tooltip title={"Apellidos"} placement="top">
              <div
                className="icon-surnames ql-icon"
                style={{ cursor: "pointer" }}
                onClick={() => insertVariables(user?.surnames)}
              ></div>
            </Tooltip>
            <Tooltip title={"No. cédula"} placement="top">
              <div
                className="icon-address ql-icon"
                style={{ cursor: "pointer" }}
                onClick={() => insertVariables(user?.documentNumber)}
              ></div>
            </Tooltip>
          </div>
        </div>
        <div>
          <ReactQuill
            theme="snow"
            ref={refProp}
            style={{ height: sizeProp }}
            modules={modules}
            formats={formats}
          ></ReactQuill>
        </div>
        <Menu
          id="simple-menu"
          keepMounted
          open={Boolean(anchorMenu)}
          onClose={handleCloseMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem
            onClick={(evt) => {
              handleClose(evt, user?.name);
              return setCloseMenu(false);
            }}
            value={user?.name}
          >
            <div
              className="icon-names ql-icon"
              style={{ width: 20, height: 20, margin: "0px 11px 0px 0px" }}
            />
            nombres
          </MenuItem>
          <MenuItem
            onClick={(evt) => {
              handleClose(evt, user?.surnames);
              return setCloseMenu(false);
            }}
            value={user?.surnames}
          >
            <div
              className="icon-surnames ql-icon"
              style={{ width: 20, height: 20, margin: "0px 11px 0px 0px" }}
            />
            Apellidos
          </MenuItem>
          <MenuItem
            onClick={(evt) => {
              handleClose(evt, user?.documentNumber);
              return setCloseMenu(false);
            }}
            value={user?.documentNumber}
          >
            <div
              className="icon-address ql-icon"
              style={{ width: 20, height: 20, margin: "0px 11px 0px 0px" }}
            />
            No. de cédula
          </MenuItem>
        </Menu>
      </div>
    );
  }
);
export default EditableSection;

const CustomToolbar = (id: any) => (
  <div id={`toolbar-${id.id}`} className="ql-toolbar ql-snow">
    <div className="size-section">
      <span style={{ fontSize: 14 }}>Tamaño</span>
      <span className="ql-formats">
        <select className="ql-size ql-picker">
          <option value="12px">Pequeña</option>
          <option value="16px">Mediana</option>
          <option value="24px">Grande</option>
          <option value="32px">Muy grande</option>
        </select>
      </span>
    </div>
    <select className="ql-font">
      <option value="arial" selected>
        Arial
      </option>
      <option value="georgia">Georgia</option>
      <option value="lucida">Lucida</option>
      <option value="Verdana">Verdana</option>
      <option value="Ubuntu">Ubuntu</option>
      <option value="Montserrat">Montserrat</option>
      <option value="Inconsolata">Inconsolata</option>
    </select>
    <div className="style-section">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
    </div>
    <div className="align-section">
      <button className="ql-align" value=""></button>
      <button className="ql-align" value="center"></button>
      <button className="ql-align" value="right"></button>
    </div>
    <div>
      {" "}
      <button className="custom" value="right">
        <span className="ql-formats">
          <select className="ql-color ql-icon" />
        </span>
      </button>
    </div>
    <div className="list-section">
      <button className="ql-list" value="bullet"></button>
      <button className="ql-list" value="ordered"></button>
    </div>
  </div>
);

const CustomToolBarM = React.memo(CustomToolbar);
