import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { IConsumptionFNAReport, IFNAReports } from "../models/FNAModel";

const initialState: IFNAReports = {
  consumptionFNA: null,
  isLoading: false,
  showModal: false,
  message: "",
};

const FNAReportSlice = createSlice({
  name: "FNAReports",
  initialState,
  reducers: {
    setFNAConsumptionReport: (
      state,
      { payload }: PayloadAction<IConsumptionFNAReport | null>
    ) => {
      state.consumptionFNA = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setShowModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showModal = payload;
    },
    setMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
  },
});

export const {
  setFNAConsumptionReport,
  setIsLoading,
  setShowModal,
  setMessage,
} = FNAReportSlice.actions;

export const FNAReportsSelector = (state: RootState) => state.fnareports;

export default FNAReportSlice.reducer;
