import axios, { AxiosResponse } from "axios";
import { BIOMETRICS_URL, API_LICENSE } from "../config/const";
import {
  IAverageCompany,
  IBusinessUnitRecord,
  IUserRecords,
  ICustomerConsumption,
  IPlanExpired,
  IDashboard,
  INextBalanceToExpire,
  IRecordCompany,
  ITypeProcessCompany,
  ITypeProcessCompanyBusinessUnit,
  ITypeProcessByUser,
  IDoughnutTransaction,
  IVerticalBarTransaction,
  DashboardTransactionsRequest,
  ValidateCountersNit,
} from "../store/models/DashboardModel";
import {
  ListBasicResponse,
  ResultEntityResponse,
  StandardResponse,
} from "../type";
import { defaultHeaders } from "../config/config";

const GET_DAILY_COMPARISON_AVERAGE = "Dashboard/DailycomparisonAverage";
const GET_RECORD_COMPANY_BUSINESSUNIT =
  "Dashboard/RecordsByCompanyAndBusinessunit";
const GET_RECORD_USER = "Dashboard/RecordsUser";
const GET_RECORD_TYPEPROCESS_COMPANY_BUSINESSUNIT =
  "Dashboard/RecordsTypeProcessCompanyAndBusinessunit";
const GET_RECORD_COMPANY = "Dashboard/RecordbyCompany";
const GET_RECORD_COMPANY_MONTH = "Dashboard/RecordCompanyByMonth";
const GET_NUMBER_RECORD_TYPEPROCESS =
  "Dashboard/NumberRecordByTypeProcessCompany";
const GET_AVERAGE_COMPANY = "Dashboard/AverageCompany";
const GET_AVERAGE_COMPANY_MONTH = "Dashboard/AverageCompanyMonthly";
const POST_CUSTOMER_PLAN_EXPIRED = "license/api/License/GetCustomerPlanExpired";
const POST_CUSTUMER_CONSUMPTION = "validation/CustomerConsumption";
const POST_CUSTUMER_LICENSE = "license/api/License/GetCustomerConsumption";
const POST_NEXT_BALANCE_TOEXPIRE = "license/api/License/GetNextBalanceToExpire";
const POST_NEXT_PLAN_EXPIRED = "license/api/License/GetNextPlanExpirations";
const GET_RECORD_BUSINESSUNIT = "Dashboard/AverageByBusinessUnit";
const GET_RECORD_TYPEPROCESS_USER = "Dashboard/RecordsTypeProcessByUser";
const GET_TRANSACTION_DOGHNUT = "Dashboard/DashboardHitsDonut";
const GET_TRANSACTION_VERTICAL_BAR = "Dashboard/DashboardHits";
const GET_VALIDATED_COUNTERS = "validation/VerifyCounters";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
});

const instancelicense = axios.create({
  baseURL: API_LICENSE,
});

export const getDailycomparisonAverage = async (): Promise<
  StandardResponse<ResultEntityResponse<IDashboard>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ResultEntityResponse<IDashboard>>
    > = await instance.get(GET_DAILY_COMPARISON_AVERAGE, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw DataTransfer;
    }
  } catch (error) {
    throw error;
  }
};

export const getRecordsByCompanyAndBusinessunit = async (): Promise<
  StandardResponse<ListBasicResponse<IBusinessUnitRecord>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IBusinessUnitRecord>>
    > = await instance.get(GET_RECORD_COMPANY_BUSINESSUNIT, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getUserRecord = async (): Promise<
  StandardResponse<ListBasicResponse<IUserRecords>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IUserRecords>>
    > = await instance.get(GET_RECORD_USER, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getRecordsTypeProcessCompanyAndBusinessunit = async (): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessCompanyBusinessUnit>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ITypeProcessCompanyBusinessUnit>>
    > = await instance.get(
      GET_RECORD_TYPEPROCESS_COMPANY_BUSINESSUNIT,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getRecordbyCompany = async (): Promise<
  StandardResponse<ListBasicResponse<IRecordCompany>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IRecordCompany>>
    > = await instance.get(GET_RECORD_COMPANY, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getRecordCompanyByMonth = async (): Promise<
  StandardResponse<ListBasicResponse<IRecordCompany>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IRecordCompany>>
    > = await instance.get(GET_RECORD_COMPANY_MONTH, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getNumberRecordByTypeProcessCompany = async (): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessCompany>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ITypeProcessCompany>>
    > = await instance.get(GET_NUMBER_RECORD_TYPEPROCESS, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getAverageCompany = async (): Promise<
  StandardResponse<ListBasicResponse<IAverageCompany>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IAverageCompany>>
    > = await instance.get(GET_AVERAGE_COMPANY, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getAverageCompanyMonthly = async (): Promise<
  StandardResponse<ListBasicResponse<IAverageCompany>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IAverageCompany>>
    > = await instance.get(GET_AVERAGE_COMPANY_MONTH, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getCustomerPlanExpired = async (
  body: any
): Promise<StandardResponse<ListBasicResponse<IPlanExpired>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IPlanExpired>>
    > = await instancelicense.post(
      POST_CUSTOMER_PLAN_EXPIRED,
      body,
      defaultHeaders()
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getCustomerConsumption = async (
  body: any
): Promise<StandardResponse<ICustomerConsumption>> => {
  try {
    const response: AxiosResponse<StandardResponse<ICustomerConsumption>> =
      await instance.post(POST_CUSTUMER_CONSUMPTION, body, defaultHeaders());
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getNextBalanceToExpire = async (
  body: any
): Promise<StandardResponse<ListBasicResponse<INextBalanceToExpire>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<INextBalanceToExpire>>
    > = await instancelicense.post(
      POST_NEXT_BALANCE_TOEXPIRE,
      body,
      defaultHeaders()
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getNextPlanExpirations = async (
  body: any
): Promise<StandardResponse<ListBasicResponse<IPlanExpired>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IPlanExpired>>
    > = await instancelicense.post(
      POST_NEXT_PLAN_EXPIRED,
      body,
      defaultHeaders()
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getAverageByBusinessunit = async (): Promise<
  StandardResponse<ListBasicResponse<IBusinessUnitRecord>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IBusinessUnitRecord>>
    > = await instance.get(GET_RECORD_BUSINESSUNIT, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getRecordsTypeProcessByUser = async (): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessByUser>>
> => {
  try {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${sessionStorage.getItem("jwtToken")}`;
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ITypeProcessByUser>>
    > = await instance.get(GET_RECORD_TYPEPROCESS_USER, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getDoughnutTransaction = async (
  dasboardTransactions: DashboardTransactionsRequest
): Promise<StandardResponse<ListBasicResponse<IDoughnutTransaction>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IDoughnutTransaction>>
    > = await instance.post(
      GET_TRANSACTION_DOGHNUT,
      dasboardTransactions,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getVerticalBarTransaction = async (
  dasboardTransactions: DashboardTransactionsRequest
): Promise<StandardResponse<ListBasicResponse<IVerticalBarTransaction>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IVerticalBarTransaction>>
    > = await instance.post(
      GET_TRANSACTION_VERTICAL_BAR,
      dasboardTransactions,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getCustomerLicense = async (
  body: any
): Promise<StandardResponse<ListBasicResponse<ICustomerConsumption>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ICustomerConsumption>>
    > = await instancelicense.post(
      POST_CUSTUMER_LICENSE,
      body,
      defaultHeaders()
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

/**
 * Servicio que consulta al backend de biometria que responde los contratos almacenados en la tabla counters
 * @param body.documentNumber Numero de documento de la compañia de la cual se desea conocer los contratos
 * @param body.valid Estado de los contratos que se desea buscar, true para los contratos activos y false para los inactivos
 * @returns StandardResponse<ListBasicResponse<ValidateCountersNit[]>>
 */
export const getValidatedCounters = async (
  body: any
): Promise<StandardResponse<ListBasicResponse<ValidateCountersNit>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ValidateCountersNit>>
    > = await instance.post(GET_VALIDATED_COUNTERS, body, defaultHeaders());
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
