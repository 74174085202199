import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InputRounded } from "../../components/Inputs/InputRounded";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { useDispatch, useSelector } from "react-redux";
import { CommonSelector } from "../../store/slices/CommonSlice";
import {
  ComparisonSelector,
  setShowForm,
} from "../../store/slices/ComparisonSlice";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import {
  comparisonAuditAC,
} from "../../store/action-creators/ComparisonCreators";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { getNameFinger } from "../../utils/general/utils";
import { IAudit } from "../../store/types";
import { ComparisonResponse } from "../../store/models/ComparisonModel";
import moment from "moment";

type ComparisonFormProperties = {
  justForm?: boolean;
  customClose?: Function;
};

/**
 * @description Vista que contiene un layout con el formulario de la informacion del cotejo seleccionado, permite mostrar el formulario solo o con el layout que contiene opciones de auditoria.
 * @param { ComparisonFormProperties } ComparisonFormProperties - Parametro de tipo ComparisonFormProperties que contiene las configuraciones del formulario { justForm: boolean, customClose: Function }
 * @returns { JSX.Element } JSX.Element
 */
const ComparisonForm: React.FC<ComparisonFormProperties> = ({
  justForm,
}: ComparisonFormProperties): JSX.Element => {
  const { comparison, comparisonAudit } = useSelector(ComparisonSelector);
  const [dataAudit, setDataAudit] = useState<IAudit>();
  const { loading } = useSelector(CommonSelector);
  const dispatch = useDispatch();

  /** Control de formulario mediante useForm */
  const { control } = useForm<ComparisonResponse>({
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: comparison
      ? {
          ...comparison,
          initialDate: moment(comparison.initialDate).format(
            "YYYY-MM-DD H:mm:ss"
          ),
          finalDate: moment(comparison.finalDate).format("YYYY-MM-DD H:mm:ss"),
          manualCaptureJustification:
            comparison.manualCaptureJustification !== null
              ? comparison.manualCaptureJustification
              : "N/A",
        }
      : {},
  });

  useEffect(() => {
    if (comparisonAudit) {
      setDataAudit(comparisonAudit);
    }
  }, [comparisonAudit]);

  /**
   * @description Componente donde se muestra los resultados de los hists y el valor de el score por cada dedo capturado
   * @returns { JSX.Element } JSX.Element
   */
  const FingerComponent = () => {
    return (
      <Paper className={classes.paperHits}>
        <Typography className={classes.typography}>Hits</Typography>
        <Grid container direction="row" className={classes.alignGrid}>
          <Grid item xs={6}>
            {comparison?.result?.[0] && (
              <Grid container direction="column" className={classes.alignGrid}>
                <Typography>
                  {getNameFinger(comparison?.result?.[0]?.finger)}
                </Typography>
                {comparison?.result?.[0]?.result ? (
                  <CheckCircle
                    style={{ paddingTop: "10px" }}
                    fontSize="large"
                    htmlColor="#5FBE2C"
                  />
                ) : (
                  <Cancel
                    style={{ paddingTop: "10px" }}
                    fontSize="large"
                    htmlColor="#D50915"
                  />
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            {comparison?.result?.[1] && (
              <Grid container direction="column" className={classes.alignGrid}>
                <Typography>
                  {getNameFinger(comparison?.result?.[1]?.finger)}
                </Typography>
                {comparison?.result?.[1]?.result ? (
                  <CheckCircle
                    style={{ paddingTop: "10px" }}
                    fontSize="large"
                    htmlColor="#5FBE2C"
                  />
                ) : (
                  <Cancel
                    style={{ paddingTop: "10px" }}
                    fontSize="large"
                    htmlColor="#D50915"
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Typography className={classes.typography}>Score</Typography>

        <Grid container direction="row" className={classes.alignGrid}>
          <Grid item xs={6} className={classes.alignGrid}>
            <Typography>{comparison?.scoreFinger1}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.alignGrid}>
            <Typography>{comparison?.scoreFinger2}</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const handleBack = () => {
    dispatch(setShowForm(false));
  };

  const handleAudit = () => {
    if (comparison) {
      dispatch(comparisonAuditAC(comparison));
    }
  };

  const classes = useStyles();

  /**
   * @description Formulario donde se integran los inputs para mostrar la informacion de los resultados de la captura de huellas de un candidato
   * @returns { JSX.Element } JSX.Element
   */
  const SimpleForm = (): JSX.Element => (
    <form>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FingerComponent />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="names"
                control={control}
                label="Nombres"
                props={{
                  name: "names",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="surnames"
                control={control}
                label="Apellidos"
                props={{
                  name: "surnames",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="documentType"
                control={control}
                label="Tipo de documento"
                props={{
                  name: "documentType",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="documentNumber"
                control={control}
                label="Número de documento"
                props={{
                  name: "documentNumber",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="nut"
                control={control}
                label="NUT"
                props={{
                  name: "nut",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="idprocess"
                control={control}
                label="Código de trámite"
                props={{
                  name: "idprocess",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="process"
                control={control}
                label="Nombre trámite"
                props={{
                  name: "process",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="phone"
                control={control}
                label="Celular"
                props={{
                  name: "phone",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="email"
                control={control}
                label="Correo electrónico"
                props={{
                  name: "email",
                  disabled: false,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="typeStation"
                control={control}
                label="Tipo de estación"
                props={{
                  name: "typeStation",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="latitude"
                control={control}
                label="Latitud"
                props={{
                  name: "latitude",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="longitude"
                control={control}
                label="Longitud"
                props={{
                  name: "longitude",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="initialDate"
                control={control}
                label="Fecha inicio"
                props={{
                  name: "initialDate",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="finalDate"
                control={control}
                label="Fecha finalización"
                props={{
                  name: "finalDate",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="detail"
                control={control}
                label="Detalle"
                props={{
                  name: "detail",
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="manualCaptureJustification"
                control={control}
                label="Justificacion captura manual"
                props={{
                  name: "detail",
                  disabled: false,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FullLoader open={loading} viewLoader={true} />
    </form>
  );

  return (
    <div>
      {justForm ? (
        /** Se muestra unicamente el formulario sin el layout */
        <SimpleForm />
      ) : (
        <ViewLayout
          isNew={!comparison ? true : false}
          isEdit={false}
          headerTitle="Cotejos"
          handleIconClick={handleBack}
          editOption={false}
          auditOption={true}
          handleAudit={() => handleAudit()}
          dataAudit={dataAudit}
          fileName="Auditoría Cotejo"
        >
          <SimpleForm />
        </ViewLayout>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paperHits: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    minHeigth: 0,
    maxHeight: "145px",
    boxShadow: "0px 3px 3px #0000001A",
    border: "1px solid #D5D5D5",
    borderRadius: "10px",
    opacity: 1,
    paddingTop: 5,
    paddingBottom: 5,
  },
  typography: {
    marginLeft: "11px",
    font: "normal normal normal 15px/17px Muli",
    color: "#009BDD",
  },
  alignGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default ComparisonForm;
