import { useDispatch, useSelector } from "react-redux";
import {
  FNAReportsSelector,
  setFNAConsumptionReport,
  setMessage,
  setShowModal,
} from "../store/slices/FNAReportSlice";
import { IFNAFormReport, IReportHook, IReportType } from "../store/models/FNAModel";
import { getFNAConsumptionReportAC } from "../store/action-creators/FNAReportsActionCreators";
import { useEffect, useState } from "react";
import { decodeToken, downloadDataFromBase64ToZip } from "../utils/general/utils";
import { DataToken } from "../store/types";
import { FNA_POINT } from "../config/const";

const typeReportsLists = [{
  name: "Reporte de consumo",
  value: 1
}];

const useFNAReports = ({ token, nitCompany }: IReportHook) => {
  // REDUX state
  const dispatch = useDispatch();
  const [reportTypeList, setReportTypeList] = useState<IReportType[]>(typeReportsLists);
  const [decodedToken, setDecodedToken] = useState<DataToken | undefined>(undefined);
  const { consumptionFNA, showModal, message, isLoading } =
    useSelector(FNAReportsSelector);

  useEffect(() => {
    if (consumptionFNA !== null) {
      downloadDataFromBase64ToZip(consumptionFNA.file, consumptionFNA.fileName);
      dispatch(setFNAConsumptionReport(null));
    }
  }, [consumptionFNA]);

  const getFNAConsumptionReport = (body: IFNAFormReport) => {
    dispatch(getFNAConsumptionReportAC(body));
  };

  const handleCloseModal = () => {
    dispatch(setShowModal(false))
    dispatch(setMessage(""))
  }

  useEffect(() => {
    if (token) {
      const decodeNewToken: any = decodeToken(token);
      setDecodedToken(decodeNewToken);
    }
  },[token]);

  function haveReportsAvailable (_decodedToken:DataToken | undefined){
    if ( decodedToken?.customer_value === FNA_POINT && (decodedToken.BIOMETRIA_ROLE === "BIOMETRIA ADMIN" || decodedToken.BIOMETRIA_ROLE === "BIOMETRIA SUPERADMIN") ) return true;
    return false;
  }

  return {
    consumptionFNA,
    showModal,
    message,
    isLoading,
    getFNAConsumptionReport,
    handleCloseModal,
    reportTypeList,
    setReportTypeList,
    decodedToken,
    haveReportsAvailable
  };
};

export default useFNAReports;
