import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { FieldsAdvanceSearch } from "../store/types";
import { getVerticalBarTransactionAC } from "../store/action-creators/DashboardActionCreators";
import {
  resetVerticalBarTransaction,
} from "../store/slices/DashboardSlice";
import {
  formatDateIso,
  getDateByMonth,
  getMonthFormattedValue,
} from "../utils/general/formatDate";

/**
 * funciones y estados para controlar el componente DateTextField
 * @param {string} selectDate - estado tipo string para saber que tipo de fecha se renderiza day or month
 * @returns (setDate, date, executeSearch, messageErrors, onChangeDate)
 */

export const useDateTextField = ({ switchCompany, role }: any) => {
  // REDUX
  const dispatch = useDispatch();

  // sTATES
  const [messageErrors, setMessageErrors] = useState<string>("");
  const [selectDate, setSelectDate] = useState<string>("");

  const [date, setDate] = useState<FieldsAdvanceSearch>({
    name: "ModificationDate",
    title: "",
    type: "date",
    value: "",
  });

  /**
   * Actualiza el estado de la fecha
   * @param { string } fieldPass - Nombre de estado (name)
   * @param { string | Date | boolean | number | undefined } valuePass - Valor que se le setea al estado y componente
   * @param { string } type - tipo o posicion de la fecha
   */
  const setValueField = (
    fieldPass: string,
    valuePass: string | Date | boolean | number | undefined,
    type?: string
  ) => {
    setDate((prevState) => {
      let valueState: string[] = !!prevState?.value
        ? prevState.value.toString().split(":")
        : ["", ""];
      const start = valueState[0];
      const finish = valueState[1];

      if (!!type) {
        if (type === "only") {
          return {
            ...prevState,
            value: `${valuePass || ""}`,
          };
        }

        if ((start.trim() === "" || finish.trim() === "") && valuePass === "") {
          return {
            ...prevState,
            value: "",
          };
        }

        if (type === "st") {
          return {
            ...prevState,
            value: `${valuePass || ""}:${finish}`,
          };
        }
        if (type === "fn") {
          return {
            ...prevState,
            value: `${start}:${valuePass || ""}`,
          };
        }
      }

      return {
        ...prevState,
        value: valuePass,
      };
    });
  };

  /**
   * funcion que maneja el componente para actualizar el estado de la fecha
   * @param field - Nombre de estado (name)
   * @param { ChangeEvent<HTMLInputElement> } event - datos que provee el componente
   * @param type - tipo o posicion de la fecha
   */

  const onChangeDate = (
    field: any,
    event: ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    const { value } = event.target;
    if (date !== null) {
      setValueField(field, value, type);
    }
  };

  /**
   * Comprueba las restricciones para el componente DateTextField
   * @param { string } start - fecha inicial
   * @param { string } end - fecha final
   * @returns { string }
   */

  const validationDates = useCallback(
    (start: string, end: string) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      let errors = "";

      if (!start && end) {
        errors +=
          "La fecha inicial está vacía. Debes ingresar una fecha inicial.";
      } else if (start && !end) {
        errors += "La fecha final está vacía. Debes ingresar una fecha final.";
      } else if (startDate > endDate) {
        errors += "La fecha inicial es mayor que la fecha final.";
      } else {
        const milisecondsPerDay = 1000 * 60 * 60 * 24;
        const daysDifference = Math.abs(
          (endDate.getTime() - startDate.getTime()) / milisecondsPerDay
        );

        if (selectDate === "day" && daysDifference > 7) {
          errors += "El rango de fechas es mayor a 7 días.";
        }
      }

      return errors;
    },
    [selectDate]
  );

  const destructureDate = (date: FieldsAdvanceSearch) => {
    let dateArray = !!date.value
      ? date.value !== ""
        ? date.value.toString().split(":")
        : null
      : null;
    let startDate = !!dateArray ? dateArray[0] : "";
    let endDate = !!dateArray ? dateArray[1] : "";

    return {
      startDate,
      endDate,
    };
  };

  /**
   * Comprueba que no haya errores y ejecuta el dispatch
   * @param { string } start - fecha inicial
   * @param { string } end - fecha final
   */
  const executeSearch = useCallback(
    (startDate: string, endDate: string, SwitchCompany?: boolean) => {
      const errorsModifiedDate = validationDates(startDate, endDate);

      if (errorsModifiedDate === "") {
        setMessageErrors("");
      } else {
        setMessageErrors(errorsModifiedDate);
      }

      if (errorsModifiedDate === "") {
        dispatch(
          getVerticalBarTransactionAC(
            {
              BusinessUnitId: 0,
              Comparative: selectDate === "day" ? 0 : 1,
              StartDate: startDate,
              EndDate: endDate,
              SwitchCompany: role === "BIOMETRIA ADMIN" ? true : switchCompany,
            },
            selectDate
          )
        );
      }
    },
    [validationDates, dispatch, selectDate]
  );

  // react hooks
  useEffect(() => {
    setDate({
      name: "ModificationDate",
      title: "",
      type: "date",
      value: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  useEffect(() => {
    const { startDate, endDate } = destructureDate(date);

    if (startDate && endDate) {
      executeSearch(startDate, endDate, switchCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, switchCompany]);

  useEffect(() => {
    const { startDate, endDate } = destructureDate(date);

    if (startDate && endDate) {
      if (selectDate === "month") {
        dispatch(resetVerticalBarTransaction());
        setDate((prevState) => ({
          ...prevState,
          value: `${getDateByMonth({ getLastMonth: true, _date: startDate })}:${getMonthFormattedValue(
            endDate
          )}`,
        }));
      } else if (selectDate === "day") {
        dispatch(resetVerticalBarTransaction());
        setDate((prevState) => ({
          ...prevState,
          value: `${formatDateIso({ getYesterday: true })}:${formatDateIso()}`,
        }));
      }
    } else {
      const value =
        selectDate === "month"
          ? `${getDateByMonth({ getLastMonth: true })}:${getDateByMonth()}`
          : `${formatDateIso({ getYesterday: true })}:${formatDateIso()}`;
      dispatch(resetVerticalBarTransaction());
      setDate((prevState) => ({
        ...prevState,
        value: value,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  return {
    setDate,
    // executeSearch,
    onChangeDate,
    setSelectDate,
    date,
    messageErrors,
    selectDate,
  };
};
