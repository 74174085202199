import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import DataTable from "../../components/Table/DataTable";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { Column } from "../../components/Table/types";
import { CommonSelector } from "../../store/slices/CommonSlice";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";
import notDataFoundUsers from "./data/notDataFoundUsers";
import columnsUsers from "./data/columnsUsers";
import getButtonsUsers from "./data/getButtonsUsers";
import useUsers from "../../hooks/useUsers";

type UserViewProps = {
  nitCompany?: string;
  tabletOnly?: boolean;
};
/**
 * Retorna el componente completo para ser mostrado
 * @param { string } nitCompany - NIT de la compañia
 * @param { boolean } tabletOnly - validacion mostrar unicamente la tabla de datos
 * @returns { JSX.Element } - vista de usuarios
 */
const UsersView: React.FC<UserViewProps> = ({ nitCompany, tabletOnly }) => {
  // Redux
  const { loading, privilege, rol } = useSelector(CommonSelector);
  // Custom Hook
  const {
    advSearchState,
    handleChangeRowsPerPage,
    handleClickPaginator,
    handleClickUser,
    onDownloadData,
    handleSetState,
    handleOnSearch,
    fieldsAdvanced,
    setShowFormAdv,
    handleOnClose,
    paginatorUser,
    handleFilter,
    showFormAdv,
    actualPage,
    handleSort,
    users,
    size,
    advanceSearch,
  } = useUsers({ nitCompany });
  // Estado Local
  const [option, setOption] = useState(null);

  /**
   * Retorna la tabla de los usuarios
   * @returns { JSX.Element } - Tabla de datos
   */
  const TabletComponent = () => {
    return (
      <DataTable
        columns={columnsUsers(handleSetState)}
        buttons={getButtonsUsers()}
        data={users}
        havePagination={size > 10 ? true : false}
        option={option}
        setOption={setOption}
        page={actualPage - 1}
        paginator={paginatorUser}
        setPage={handleClickPaginator}
        size={size}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginatorUser.paginator.itemsPage}
        handleChangePage={handleClickPaginator}
        handleCellClick={(
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          value: any,
          _column: Column
        ) => handleClickUser(e, value, _column)}
        notDataFound={
          (paginatorUser.filter && users.length === 0) || advSearchState
            ? undefined
            : notDataFoundUsers()
        }
        handleFilter={handleFilter}
        handleSort={handleSort}
        handleOnOpenAdvSearch={() => {
          setShowFormAdv(true);
        }}
        readDisabled={
          privilege.find((privil) => privil === "BIOMETRIA_USER_READ") ===
          undefined
            ? true
            : false
        }
        setShowFormAdvance={() => {
          setShowFormAdv(true);
        }}
        rol={rol}
        download={onDownloadData}
        loading={loading}
      />
    );
  };

  return (
    <div>
      <AdvancedSearchModal
        open={rol === "BIOMETRIA RNEC" ? showFormAdv : false}
        handleOnSearch={handleOnSearch}
        handleOnClose={handleOnClose}
        fieldsAdvancedSearch={
          !advanceSearch.length ? fieldsAdvanced : advanceSearch
        }
        fieldsAdvanced={fieldsAdvanced}
      />
      {!tabletOnly ? (
        <ViewLayout
          headerTitle="Usuarios"
          editOption={true}
          isEdit={false}
          isNew={true}
        >
          <TabletComponent />
        </ViewLayout>
      ) : (
        <TabletComponent />
      )}
      <HashDowloandModal />
      <FullLoader open={loading} viewLoader={true} />
    </div>
  );
};

export default UsersView;
