import { Theme } from "@material-ui/core";
import {
  getCompanyById,
  getCompanyByUser,
  getPersonalization,
  advanceSearch,
  getAllLogComparisonCsvService,
  getAllLogConsultCsvService,
  getAllLogUnsatisfactoryAnswerCsvService,
} from "../../services/CompanyService";
import {
  setCurrentCompany,
  setError,
  setLoading,
  setFilterCompany,
  setPaginatorCompany,
  setSizeCompany,
  setSortCompany,
  setCompanies,
  setSortLogCompany,
} from "../slices/CompanySlice";
import {
  setBackground,
  setBackgroundColor,
  setButtonColor,
  setButtonTextColor,
  setLogo,
  setPrimaryColor,
  setSecondaryColor,
} from "../slices/PersonalizationSlice";
import { AppThunk } from "../store";
import {
  PaginatorWithFilterAndSort,
  sortBy,
  AdvanceSearch,
  SortOptionLog,
  GeneralLog,
} from "../types";
import { setPersonalization } from "../../utils/general/personalization";
import { setHashCsv, setHashCsvModal } from "../slices/CommonSlice";

/**
 * @description Lanza el servicio advanceSearch donde se envia los elementos de advance search y se retorna una lista de empresas filtrada.
 * @param { AdvanceSearch } body - este parametro representa los elementos del tipo AdvanceSearch
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas a el hook companies con el tipo ICompany[].
 */
export const advanceSearchAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    advanceSearch(body)
      .then((data) => {
        const { count } = data.result;
        dispatch(setCompanies(data.result.records));
        dispatch(setSizeCompany(count));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information));
      })
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getCompanyByUser que donde se envia el id del usuario y se retorna una lista de empresas, tambien permite enviar el paginador para la paginacion del lado del servidor.
 * @param { string } userId - este parametro representa el id del usuario logueado
 * @param { PaginatorWithFilterAndSort } paginator - este parametro representa los elementos del paginador de tipo PaginatorWithFilterAndSort
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas a el hook companies con el tipo ICompany[].
 */
export const getCompanyByUserAC =
  (userId: string, paginator: PaginatorWithFilterAndSort): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getCompanyByUser(userId, paginator)
      .then((response: any) => {
        if (response.statusCode === 200) {
          dispatch(setCompanies(response.result.records));
          dispatch(setSizeCompany(response.result.count));
        } else {
          dispatch(setError("tenemos problemas"));
        }
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information));
      })
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getCompanyById que donde se retorna la informacion de la empresa empresa mediante el id que se almacena en el token
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la informacion de la empresa en el hook currentCompany con el tipo Company.
 */
export const getCompanyByIdAC = (): AppThunk => (dispatch) => {
  getCompanyById()
    .then((response: any) => {
      if (response.status === 200) {
        const { data } = response;
        dispatch(setCurrentCompany(data));
      } else {
        dispatch(setError("tenemos problemas"));
      }
    })
    .catch((error) => {
      const { data } = error;
      const { information } = data.result;
      dispatch(setError(information));
    })
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Este metodo lanza directamente a el hook paginatorCompany los valores para la paginacion del Table
 * @param { number } page - Parametro que representa el numero de pagina con el tipo de dato number
 * @param { number } itemsPage - Parametro que la cantidad de elementos por pagina con el tipo de dato number
 * @param { boolean } recharge - Parametro que representa si se necesita recargar la busqueda con el tipo de dato boolean
 */
export const getPaginatorCompanyAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorCompany({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

/**
 * @description Este metodo lanza directamente el sub-indice filter de el hook de paginatorCompany el texto de busqueda para el filtro
 * @param { string } filter
 */
export const getFilterCompanyAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterCompany(filter));
  };

/**
 * @description Este metodo lanza directamente el sub-indice sort de el hook de paginatorCompany con el tipo sortBy
 * @param { sortBy } sort - Parametro de tipo sortBy ( "recent" | "old" | "active" | "inactive" | "a-z" | "z-a" )
 */
export const getSortCompanyAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortCompany(sort));
  };

/**
 * @description Este metodo lanza directamente el sub-indice sortOptionLog de el hook de paginatorCompany con el tipo SortOptionLog
 * @param { SortOptionLog } sortLog - Parametro de tipo SortOptionLog ( "comparison" | "consult" | "unsatisfactoryAnswer" )
 */
export const getSortOptionAllLogAC =
  (sortLog: SortOptionLog): AppThunk =>
  (dispatch) => {
    dispatch(setSortLogCompany(sortLog));
  };

/**
 * @description Lanza el servicio getPersonalization que donde se retorna la personalizacion de la empresa a la que el usuario está logueado
 * @param { string } token - cadena de string que representa el token al que el usuario está logueado
 * @param { Function } setTheme - Funcion que se devuelve al momento de obtener la informacion de personalizacion el cual inserta en el hook la personalizacion correspondiente
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la personalizacion de la empresa en el hook personalization con el tipo PersonalizationResponseDto.
 */
export const getPersonalizationAC =
  (
    token: string,
    setTheme: React.Dispatch<React.SetStateAction<Theme>>
  ): AppThunk =>
  (dispatch) => {
    getPersonalization(token)
      .then((response) => {
        setPersonalization(response, setTheme);
        let personalization = response.data.result.entityDto;
        if (!!personalization) {
          dispatch(setPrimaryColor(personalization?.principalColor));
          dispatch(setSecondaryColor(personalization?.secondaryColor));
          dispatch(setBackgroundColor(personalization?.backgroundColor));
          dispatch(setLogo(personalization?.logo));
          dispatch(setBackground(personalization?.backgroundImage));
          dispatch(setButtonColor(personalization?.buttonColor));
          dispatch(setButtonTextColor(personalization?.buttonTextColor));
        }
      })
      .catch((err) => {
        console.error(err);
        setPersonalization(null, setTheme);
      })
      .finally(() => dispatch(setLoading(false)));
  };

/**
  * @description Lanza el servicio getAllLogComparisonCsvService que devuelve una coleccion de registros de log de comparison y los prepara en un documento encriptado en base64 que contiene la informacion que se convierte en un archivo (CSV)
  * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
  * @returns { Function } se realiza la solicitud a la API, prepara el documento csv y almacena el hash con los registros posteriormente inicializa un modal para descarga
  */
export const getAllLogComparisonCsvAC =
  (body: GeneralLog): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getAllLogComparisonCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
  * @description Lanza el servicio getAllLogConsultCsvService que devuelve una coleccion de registros de log de comparison y los prepara en un documento encriptado en base64 que contiene la informacion que se convierte en un archivo (CSV)
  * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
  * @returns { Function } se realiza la solicitud a la API, prepara el documento csv y almacena el hash con los registros posteriormente inicializa un modal para descarga
  */
export const getAllLogConsultCsvAC =
  (body: GeneralLog): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getAllLogConsultCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
  * @description Lanza el servicio getAllLogUnsatisfactoryAnswerCsvService que devuelve una coleccion de respuesta no satisfactoria
  * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
  * @returns { Function } se realiza la solicitud a la API, prepara el documento csv con los registros de respuesta no satisfactoria
  */
export const getAllLogUnsatisfactoryAnswerCsvAC =
  (body: GeneralLog): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getAllLogUnsatisfactoryAnswerCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };
