import { ButtonsProps } from "../../../components/Table/types";

/**
 * @description Metodo que retorna los botones de acciones para el DataTable de Audit Logs
 * @returns { ButtonsProps } ButtonsProps
 */
const getButtonsAuditLog = () => {
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: 5,
        md: 5,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "center",
      options: [
        {
          label: "Más reciente",
          columns: [
            {
              prop: "createdOn",
              direction: 1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
        {
          label: "Más antiguo",
          columns: [
            {
              prop: "createdOn",
              direction: -1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
      ],
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    selectLog: {
      justify: "center",
      options: [
        {
          label: "Log de peticiones",
          columns: [
            {
              prop: "state",
            },
          ],
        },
        {
          label: "Log de consultas",
          columns: [
            {
              prop: "state",
              direction: -1,
            },
          ],
        },
        {
          label: "Log de respuesta no satisfactoria",
          columns: [
            {
              prop: "createdOn",
              direction: 1,
            },
          ],
        },
      ],
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
  };
  return button;
};

export default getButtonsAuditLog;
