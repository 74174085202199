import {
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IAudit } from "../../store/types";
import { AuditMoldal } from "../modal/AuditModal";
import { EditButton } from "@dg-bucaramanga/react-components-dg-qa";
import { DRAWER_WIDTH } from "../../utils/general/const";
import {
  auditBusinessUnits,
  auditParametrization,
} from "../../store/slices/BusinessUnitSlice";

interface AuditWrapperProps {
  children: JSX.Element;
  setIsEdit?: Function;
  isEdit?: boolean;
  isNew?: boolean;
  dataAudit?: IAudit;
  handleAudit?: Function;
  editOption?: boolean | undefined;
  fileName?: string;
}

const options = ["Auditoría"];

export const AuditWrapper = ({
  children,
  setIsEdit,
  isEdit,
  isNew,
  dataAudit,
  handleAudit,
  editOption,
  fileName,
}: AuditWrapperProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const drawerWidth = !isMobile ? DRAWER_WIDTH : 0;
  const padding = !isMobile ? "0px 40px 40px 40px" : 0;
  const classes = useStyles({
    drawerWidth: drawerWidth,
    padding: padding,
    isMobile,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAudit, setOpenAudit] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAudit = () => {
    setOpenAudit(false);
    setAnchorEl(null);
    dispatch(auditBusinessUnits(defaulAudit));
    dispatch(auditParametrization(defaulAudit));
  };

  useEffect(() => {
    if (dataAudit?.creadoPor) {
      setOpenAudit(true);
    }
  }, [dataAudit?.creadoPor]);

  const defaulAudit = {
    creadoPor: "",
    fechaCreacion: "",
    modificadoPor: "",
    fechaModificado: "",
    sede: "",
    propietario: "",
  };
  return (
    <div className={classes.wrapper}>
      <div>{children}</div>
      <Grid container>
        <Grid
          container
          item
          lg={6}
          sm={8}
          md={12}
          xs={12}
          alignItems="baseline"
        >
          {!isNew && editOption ? (
            <Grid
              container
              item
              lg={6}
              sm={4}
              md={12}
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
            >
              <div className={classes.prueba}>
                <EditButton
                  isEditing={isEdit ? isEdit : false}
                  onClick={() => {
                    if (setIsEdit) setIsEdit(!isEdit);
                  }}
                />
                <IconButton
                  aria-label="audit"
                  aria-controls="simple-menu"
                  color="secondary"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
              <Menu
                id="simple-menu"
                MenuListProps={{
                  "aria-labelledby": "simple-menu",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "15ch",
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {handleAudit
                  ? options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Auditoría"}
                        onClick={() => {
                          setAnchorEl(null);
                          handleAudit();
                        }}
                        style={{ paddingTop: "0px", background: "white" }}
                      >
                        {option}
                      </MenuItem>
                    ))
                  : null}
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <AuditMoldal
        open={openAudit}
        handleClose={() => handleCloseAudit()}
        dataAudit={dataAudit}
        fileName={fileName ?? "Auditoría"}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "99%  1%",
    width: "100%",
  },
  icon: {
    marginRight: 4,
  },
  externalCard: (props: any) => ({
    marginTop: props.isMobile ? 50 : 100,
    marginLeft: props.drawerWidth,
    padding: props.padding,
    borderRadius: props.isMobile ? 0 : "10px",
    justifyContent: "center",
    opacity: 1,
    width: props.isMobile ? "100%" : `calc(90% - ${props.drawerWidth}px)`,
    height: props.isMobile ? "93vh" : "",
    minWidth: "40% !important",
    boxShadow: "0px 5px 5px #00000026",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingBottom: props.isMobile ? 0 : "100px",
  }),
  contentCard: () => ({
    margin: "10px 25px",
    width: "98%",
    minWidth: "93% !important",
  }),
  contentPage: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
  },
  prueba: {
    display: "inherit",
    verticalAlign: "inherit",
    alignItems: "baseline",
  },
}));
