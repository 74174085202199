import { EditButton } from "@dg-bucaramanga/react-components-dg-qa";
import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DRAWER_WIDTH } from "../../utils/general/const";
import { ArrowIcon } from "../Icons/ArrowIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CircularTab } from "../Tabs/CircularTab";
import { PageBackground } from "./PageBackground";
import { AuditMoldal } from "../modal/AuditModal";
import { auditTypeProcess } from "../../store/slices/TypeProcessSlice";
import { auditDivice } from "../../store/slices/DeviceSlice";
import { auditComparison } from "../../store/slices/ComparisonSlice";
import { useDispatch } from "react-redux";
import { IAudit } from "../../store/types";
import { auditComputerEquipament } from "../../store/slices/ComputerEquipmentSlice";

type ViewLayoutProps = {
  headerTitle: string;
  children: JSX.Element;
  isEdit?: boolean;
  setIsEdit?: Function;
  isNew: boolean;
  handleIconClick?: Function | undefined;
  indicators?: {
    tabs: number;
    currentTab: number;
  };
  editOption?: boolean;
  auditOption?: boolean;
  dataAudit?: IAudit;
  handleAudit?: Function | undefined;
  fileName?: string;
  isCentered?: boolean;
  actionChildren?: JSX.Element;
};
const options = ["Auditoría"];
//const ITEM_HEIGHT = 7;

const ViewLayout = ({
  headerTitle,
  children,
  indicators,
  handleIconClick,
  isEdit,
  setIsEdit,
  isNew,
  editOption,
  auditOption,
  dataAudit,
  handleAudit,
  fileName,
  isCentered,
  actionChildren
}: ViewLayoutProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = !isMobile ? (isCentered ? 0 : DRAWER_WIDTH) : 0;
  const padding = !isMobile ? "0px 40px 40px 40px" : 0;
  const classes = useStyles({
    drawerWidth: drawerWidth,
    padding: padding,
    isMobile,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAudit, setOpenAudit] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaulAudit = {
    creadoPor: "",
    fechaCreacion: "",
    modificadoPor: "",
    fechaModificado: "",
    sede: "",
    propietario: "",
  };

  const handleCloseAudit = () => {
    setOpenAudit(false);
    setAnchorEl(null);
    dispatch(auditTypeProcess(defaulAudit));
    dispatch(auditDivice(defaulAudit));
    dispatch(auditComputerEquipament(defaulAudit));
    dispatch(auditComparison(defaulAudit));
  };

  useEffect(() => {
    if (dataAudit?.creadoPor) {
      setOpenAudit(true);
    }
  }, [dataAudit?.creadoPor]);

  const AuditButtons = () => {
    return (
      <Menu
        id="simple-menu"
        MenuListProps={{
          "aria-labelledby": "simple-menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "15ch",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {handleAudit
          ? options.map((option) => (
              <MenuItem
                key={option}
                selected={option === "Auditoría"}
                onClick={() => {
                  setAnchorEl(null);
                  handleAudit();
                }}
                style={{ paddingTop: "0px", background: "white" }}
              >
                {option}
              </MenuItem>
            ))
          : null}
      </Menu>
    );
  };

  return (
    <div className={classes.contentPage}>
      <PageBackground />
      <div
        className={classes.externalCard}
        style={isCentered ? { marginLeft: 0 } : {}}
      >
        <Grid container>
          <Grid container item lg={6} sm={8} md={8} xs={8} alignItems="center">
            {handleIconClick ? (
              <Grid item lg={1} sm={1} md={2} xs={1}>
                <Tooltip
                  id="button-report"
                  title="Atrás"
                  style={{ float: "left", margin: "26px 2px 17px 17px" }}
                >
                  <IconButton
                    area-label="Report"
                    onClick={() => handleIconClick()}
                  >
                    <ArrowIcon className={classes.previousArrow} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid item lg={11} sm={11} md={10} xs={11}>
              <div className={classes.title}>{headerTitle}</div>
            </Grid>
          </Grid>
          {!isNew && editOption ? (
            <Grid
              container
              item
              lg={6}
              sm={4}
              md={4}
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <EditButton
                isEditing={isEdit ? isEdit : false}
                onClick={() => {
                  if (setIsEdit) setIsEdit(!isEdit);
                }}
              />
              <IconButton
                aria-label="audit"
                aria-controls="simple-menu"
                color="secondary"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <AuditButtons />
            </Grid>
          ) : null}
          { actionChildren !== undefined ? (
            <Grid
              container
              item
              lg={6}
              sm={4}
              md={4}
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              {actionChildren}
            </Grid>
          ) : null}
          {auditOption ? (
            <Grid
              container
              item
              lg={6}
              sm={4}
              md={4}
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                aria-label="audit"
                aria-controls="simple-menu"
                color="secondary"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <AuditButtons />
            </Grid>
          ) : null}
        </Grid>

        <Grid container justifyContent="center">
          <Grid container item lg={12} md={12} sm={12} justifyContent="center">
            <div className={classes.contentCard}>{children}</div>
          </Grid>

          {indicators && !isMobile ? (
            <div className={classes.indicators}>
              <CircularTab
                tabs={indicators ? indicators.tabs : 0}
                currentTab={indicators ? indicators.currentTab : 0}
              />
            </div>
          ) : null}
        </Grid>
      </div>
      <AuditMoldal
        open={openAudit}
        handleClose={() => handleCloseAudit()}
        dataAudit={dataAudit}
        fileName={fileName ?? "Auditoría"}
      />
    </div>
  );
};

export default ViewLayout;

const useStyles = makeStyles((theme: any) => {
  return {
    title: (props: any) => ({
      fontSize: props.isMobile ? "22px" : "28px",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      padding: "10px 0px 10px 25px",
      marginTop: 10,
      // width: "97%",
    }),
    externalCard: (props: any) => ({
      marginTop: props.isMobile ? 50 : 100,
      marginLeft: props.drawerWidth,
      padding: props.padding,
      borderRadius: props.isMobile ? 0 : "10px",
      justifyContent: "center",
      opacity: 1,
      width: props.isMobile ? "100%" : `calc(90% - ${props.drawerWidth}px)`,
      height: props.isMobile ? "auto" : "",
      minHeight: props.isMobile ? "93vh" : '',
      minWidth: "40% !important",
      boxShadow: "0px 5px 5px #00000026",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      paddingBottom: props.isMobile ? 0 : "100px",
    }),
    contentCard: () => ({
      margin: "10px 25px",
      width: "98%",
      minWidth: "93% !important",
    }),
    indicators: (props: any) => ({
      width: "100%",
      borderRadius: "10px",
      height: "10px",
      position: "absolute",
      justifyContent: "center",
      bottom: 20,
      left: props.drawerWidth / 2,
      marginTop: "100px",
    }),
    contentPage: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      alignItems: "center",
      flexDirection: "column",
    },
    previousArrow: {
      transform: "rotate(180deg)",
      stroke: theme.palette.secondary.main,
    },
  };
});
