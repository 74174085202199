import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../../../components/Card/Cards";
import {
  getDailycomparisonAverageAC,
  getRecordTypeProcessByUserAC,
} from "../../../store/action-creators/DashboardActionCreators";
import { DashboardSelector } from "../../../store/slices/DashboardSlice";
import {
  DashboardAverage,
  DataCompaniesForChart,
  defaultDashboardAverage,
} from "../../../store/models/DashboardModel";
import { RoundedCard } from "../../../components/Card/RoundedCard";
import { LinearPercentBar } from "../../../components/ProgressBar";

/**
 * @description This component show all Grabber role dashboard items, only it works when user is "BIOMETRIA CAPTURADOR" rol
 * @returns {JSX.Element} JSX element with the dashboard elements for grabber user
 */
function Grabber() {
  // REDUX dispatch
  const dispatch = useDispatch();
  // REDUX selectors
  const { dasboardCapturador, recordTypeProcessByUser } =
    useSelector(DashboardSelector);
  // REDUX state
  const [dailyAverage, setDailyAverage] = useState<DashboardAverage>(
    defaultDashboardAverage
  );
  const [dataUserRecords, setDataUserRecords] = useState<
    DataCompaniesForChart[]
  >([]);
  // REACT HOOKS
  useEffect(() => {
    dispatch(getDailycomparisonAverageAC());
    dispatch(getRecordTypeProcessByUserAC());
  }, [dispatch]);

  // listen for changes of "dasboardCapturador" to organize the information needed to set it to the graphs
  useEffect(() => {
    const data: DashboardAverage = {
      daily_Comparison_Average:
        dasboardCapturador.daily_Comparison_Average.toString(),
      average_Time_Comparison_min: parseInt(
        dasboardCapturador.average_Time_Comparison.split(":")[1]
      ).toString(),
      average_Time_Comparison_seg:
        dasboardCapturador.average_Time_Comparison.split(":")[2],
    };
    setDailyAverage(data);
  }, [dasboardCapturador]);

  // listen for changes of "recordTypeProcessByUser" to organize the information needed to set it to the graphs
  useEffect(() => {
    const data: DataCompaniesForChart[] = recordTypeProcessByUser?.map(
      (user: any) => ({
        name: user.name_TypeProcess,
        value: user.number_Record,
      })
    );
    setDataUserRecords(data);
  }, [recordTypeProcessByUser]);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Cards
          title="Promedio cotejos diarios"
          text={dailyAverage.daily_Comparison_Average}
          subText="/ día hoy"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Cards
          title="Tiempo promedio por cotejo"
          text={dailyAverage.average_Time_Comparison_min}
          subText="min"
          text1={dailyAverage.average_Time_Comparison_seg}
          subText1="seg"
          subTitle="cotejo / dia hoy"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Top 5 tramites mas usados"
          text=""
          subTitle="cotejo / dia hoy"
        >
          <LinearPercentBar elements={dataUserRecords} withTooltip={false}/>
        </RoundedCard>
      </Grid>
    </>
  );
}

export default Grabber;
