/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useRef } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { NoBusinessUnitIcon } from "../../components/Icons/NoBusinessUnitIcon"; 

type SelectFileButtonProps = {
  label: string;
  haveIcon?: true;
  handleSaveImage: Function;
  setSrc?: string | null;
  disabled?: boolean;
  accept?:string;
};



export const SelectFileButton = ({
  label,
  haveIcon,
  handleSaveImage,
  setSrc,
  disabled,
  accept = ".*",
}: SelectFileButtonProps) => {
  const classes = useStyles();


  const [image, setImage] = React.useState<any | null>(null);
  const [isHovered, setHovered] = React.useState<boolean | false>(false);
  const inputImage = useRef<HTMLInputElement | null>(null);
  const extensionsAccepted = ["image/png","image/jpeg","image/jpg"];
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  React.useEffect(() => {
    setImage(null)
    setHovered(false)
  }, []);

  React.useEffect(() => {
    if(setSrc !== null){
      setImage(setSrc)
      handleSaveImage(setSrc)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSrc]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ( e.target.files && e.target.files[0] ) {
      if( !extensionsAccepted.includes( e.target.files[0].type ) ){
        setImage(setSrc)
        setAlertMessage("Archivo no soportado, intenta otro.");
        return;
      }
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        setImage(reader.result)
        handleSaveImage(reader.result)
      }
    }
  }


  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleDelete = () => {
    setImage(null)
    handleSaveImage(null)
  }

  const handleEdit = () => {
    setImage(null)
    if (null !== inputImage.current) {
      inputImage.current.click()
    }
  }

  return (
    <div>
      <AlertIconModal open={alertMessage ? true : false} handleClose={() => setAlertMessage("")} icon={<NoBusinessUnitIcon width={"92px"} height={"80px"} />} message={alertMessage} />
       <input type="file" ref={inputImage} hidden onChange={onImageChange} accept={accept} />
      {image == null ?
        <Button
          className={classes.root}
          fullWidth
          variant="contained"
          component="label"
          disabled={disabled}
        >
          <Grid container alignItems="center">
            {haveIcon ? (
              <span className={`material-icons ${classes.icon}`}>insert_photo</span>
            ) : null}
            {label}
          </Grid>
          <input type="file" hidden onChange={onImageChange} accept={accept}/>
        </Button> :
        <div>
          <div className={classes.menuEdit} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isHovered === true ?
              <div className={classes.iconHoverContainer}>
                <EditIcon style={{ color: '#009BDD', marginRight: '10px', cursor: 'pointer' }} onClick={handleEdit} />
                <DeleteIcon style={{ color: '#009BDD', cursor: 'pointer' }} onClick={handleDelete} />
              </div> : null
            }
            <div className={classes.imageContainer}>
              <img src={image} alt='preview image' style={{ maxWidth: '156px', maxHeight: '156px' }} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "left",
    font: "normal normal 600 10px Muli",
    letterSpacing: "0px",
    opacity: 1,
    color: "#B2B2B2",
    width: 96,
    background: "#EFEFEF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 1px #00000033",
    border: "1px solid #D5D5D5",
    borderRadius: "3px",
    textTransform: "initial",
  },
  icon: {
    color: theme.palette.primary.light,
    marginRight: 6,
  },
  imageContainer: {
    maxWidth: '170px',
    maxHeight: '120px',
    display: 'flex',

    '& :hover': {
      border: '1px #DDDDDD solid',
      borderRadious: '20px'
    }
  },
  iconHoverContainer: {
    display: 'flex',
    position: 'absolute',
    top: '1px',
    right: '1px',
    maxWidth: '50px',
    maxHeight: '18px',
    backgroundColor: '#FFFFFF',
    border: 'none !important',
    boxShadow: '0px 5px 10px #00000033',
    padding: '5px 20px',
    borderRadious: '10px',
  },

  menuEdit: {
    position: 'relative',
    width: '170px',
  }


}));
