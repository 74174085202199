import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonSelector,
  setHashCsv,
  setHashCsvModal,
} from "../../store/slices/CommonSlice";

/**
 * @description Componente que renderiza el modal para descarga de el hash
 * @returns { JSX.Element } JSX.Element
 */
export const HashDowloandModal = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const { hashCsvModal, hashCsv } = useSelector(CommonSelector);

  const closeModal = () => {
    dispatch(setHashCsvModal(false));
    dispatch(setHashCsv(""));
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={hashCsvModal}
      aria-labelledby="custom-dialog"
      hideBackdrop={true}
    >
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1}>
          <Close
            color="primary"
            onClick={() => closeModal()}
            style={{
              cursor: "pointer",
              paddingTop: "10px",
              height: "20px",
              width: "50px",
            }}
          />
        </Grid>
      </Grid>
      <DialogContent>
        <Typography
          component="h2"
          color="textSecondary"
          className={classes.messageTypography}
        >
          {`Hash: ${hashCsv}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.root,
              disabled: classes.disabledButton,
            }}
            onClick={() => closeModal()}
          >
            {" "}
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 1,
    background: "#1D71B8 0% 0% no-repeat padding-box",
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  disabledButton: {
    background: "#1D71B8 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 0.5,
    color: `${theme.palette.common.white} !important`,
  },
  messageTypography: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
}));
