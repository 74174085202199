import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import NewUserIcon from "../../../components/Icons/NewUserIcon";
import { ButtonsProps } from "../../../components/Table/types";
import {
  setComputerIsEdit,
  setCurrentComputerEquipment,
  setShowForm,
} from "../../../store/slices/ComputerEquipmentSlice";
/**
 * Retorna los botones y el buscador los cuales se usan junto a la tabla de datos
 * @param { ( boolean | undefined ) } justTable - valida si esta solo en modo solo tabla de datos
 * @param { string[] } privilege - privilegios de usuario
 * @param { ( boolean | undefined ) } HideMassiveButon - valida si muestra el botón de carga masiva
 * @param { string } rol - rol del usuario
 * @returns { JSX.Element } botones y buscador 
 */
const GetButtonsComputerEquipment = (
  justTable: boolean | undefined,
  privilege: string[],
  HideMassiveButon?: boolean | undefined,
  rol?: string
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sizebuttons = rol === "BIOMETRIA RNEC" ? 7 : 4;
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: sizebuttons,
        md: sizebuttons,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "center",
      options: [
        {
          label: "Activo",
          columns: [
            {
              prop: "state",
            },
          ],
        },
        {
          label: "Inactivo",
          columns: [
            {
              prop: "state",
              direction: -1,
            },
          ],
        },
        {
          label: "Más reciente",
          columns: [
            {
              prop: "createdOn",
              direction: 1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
        {
          label: "Más antiguo",
          columns: [
            {
              prop: "createdOn",
              direction: -1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
      ],
      cellSize: {
        lg: 2,
        md: 2,
        sm: 12,
        xs: 12,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
      fileName: "Listado de Equipos",
    },
    chargeButton: {},
    children: () => {
      return justTable ? (
        <></>
      ) : (
        <Grid
          container
          item
          lg={2}
          md={2}
          sm={12}
          xs={12}
          direction="row-reverse"
          justifyContent="center"
        >
          <Tooltip title={`Crear Equipo`} aria-label={`Nuevo Equipo`}>
            <Fab
              className={classes.styleFab}
              size="small"
              onClick={() => {
                dispatch(setCurrentComputerEquipment());
                dispatch(setShowForm(true));
                dispatch(setComputerIsEdit(true));
              }}
              disabled={
                privilege.find(
                  (privil) => privil === "BIOMETRIA_EQUIPO_CREATE"
                ) === undefined
                  ? true
                  : false
              }
            >
              <NewUserIcon />
            </Fab>
          </Tooltip>
        </Grid>
      );
    },
  };

  if (!HideMassiveButon) {
    button.chargeButton = {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    };
  }

  return button;
};

const useStyles = makeStyles(() => ({
  styleFab: {
    boxShadow: "none",
  },
}));

export default GetButtonsComputerEquipment;
