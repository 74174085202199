import { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import BtnCreateATDP from "../../components/button/BtnCreateATDP";
import { CustomTypography } from "../../components/typography/CustomTypography";
import FullLoader from "../../components/Loader/FullLoader.component";
import { EditButton } from "@dg-bucaramanga/react-components-dg-qa";
import CreateAtdpForm from "../Atdp/CreateAtdpForm";
import ConfirmATDP from "../Atdp/ConfirmATDP";
import CancelATDP from "../Atdp/CancelATDP";
import PreviewATDP from "../Atdp/PreviewATDP";
import ErrorATDP from "../Atdp/ErrorATDP";
import useCompanyParametrization from "../../hooks/useCompanyParametrization";
import { parametrizationMask } from "../../utils/general/masks";

const initialValues = {
  clientId: "",
  generateATDP: false,
  repository: "",
  urlATDP: "",
  userStamp: "",
  passwordStamp: "",
  signATDP: false,
  signTyC: false,
  userSign: "",
  passwordSign: "",
  automaticEmail: false,
};

export const CompanyParametrization = () => {
  const {
    handleSuccess,
    handleOnChangeForm,
    modalATDP,
    handleOpenModal,
    handleCloseModal,
    html,
    confirmATDP,
    errotAtdp,
    cancelATDP,
    previewATDP,
    generateATDP,
    signATDP,
    handleConfirmAtdp,
    handleCancelAtdp,
    handlePreviewAtdp,
    handleErrorAtdp,
    handleSetIdEdit,
    isEdit,
    loading,
    isNew,
    currentATDP,
    handleFormSubmit,
    handleOnErrorSubmit,
    parametersEdit,
    setValue,
    handleSubmit,
    errors,
    control,
    watch,
    statusAtdp
  } = useCompanyParametrization();

  useEffect(() => {
      if (parametersEdit) {
        Object.entries(parametersEdit).forEach((key: any, value: any) => {
          switch (key[0]) {
            case "clientId":
              setValue(key[0], parametrizationMask(key[1], "clientId"), {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
            case "urlATDP":
              setValue(key[0], parametrizationMask(key[1], "urlATDP"), {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
            case "userStamp":
              setValue(key[0], parametrizationMask(key[1], "user"), {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
            case "passwordStamp":
              setValue(key[0], key[1], {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
            case "userSign":
              setValue(key[0], parametrizationMask(key[1], "user"), {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
            case "passwordSign":
              setValue(key[0], key[1], {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;

            default:
              setValue(key[0], key[1], {
                shouldValidate: true,
                shouldDirty: true,
              });
              break;
          }

          // setValue(key[0], parametrizationMask(key[1], "password"), {
          //   shouldValidate: true,
          //   shouldDirty: true,
          // });
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, parametersEdit]);

  return (
    isNew !== undefined ?
    <form
      onChange={handleOnChangeForm}
      onSubmit={handleSubmit(handleFormSubmit, handleOnErrorSubmit)}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          justifyContent="space-between"
        >
          <Grid container item lg={2} md={8} sm={12} xs={12}>
            <InputRounded
              name="clientId"
              label="Cliente Id"
              props={{
                name: "clientId",
                helperText: errors.clientId?.message,
                error: errors.clientId ? true : false,
                disabled: !isEdit,
                InputLabelProps: { shrink: true }
              }}
              control={control}
              max={"8"}
            />
          </Grid>
          <Grid
            container
            item
            lg={2}
            md={2}
            sm={3}
            xs={3}
            style={{ marginTop: 10, marginRight: 10 }}
            justifyContent="flex-end"
          >
            <EditButton
              isEditing={isEdit}
              onClick={() => {
                handleSetIdEdit(!isEdit);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <Grid
            container
            item
            lg={3}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item lg={5} md={8} sm={4} xs={4}>
              <CustomTypography type="definition">
                {"Generar ATDP"}
              </CustomTypography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6}>
              {" "}
              <ControlledSwitch
                error={errors.generateATDP ? true : false}
                helperText={errors.generateATDP?.message}
                control={control}
                name="generateATDP"
                isDisabled={!isEdit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid item lg={2} md={8} sm={4} xs={4}>
            {currentATDP ? (
              <BtnCreateATDP
                onClick={handleOpenModal}
                label="Editar ATDP"
                disabled={generateATDP || !isEdit || !watch("generateATDP")}
              />
            ) : (
              <BtnCreateATDP
                onClick={handleOpenModal}
                label="Crear ATDP"
                disabled={generateATDP || !isEdit || !watch("generateATDP")}
              />
            )}
          </Grid>
          <Grid item lg={6} md={8} sm={4} xs={4}>
            <InputRounded
              label="URL ATDP"
              control={control}
              name="urlATDP"
              // defaultValue={currentParameters?.urlATDP}
              props={{
                name: "urlATDP",
                helperText: errors.urlATDP?.message,
                error: errors.urlATDP ? true : false,
                disabled: !isEdit || !watch("generateATDP"),
                InputLabelProps: { shrink: true }
              }}
              max={"150"}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          {/*  user print section     */}
          <CustomTypography type="subtitle">
            {"Usuario de estampa"}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="flex-start"
          spacing={1}
        >
          {/*subsection estampa */}
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label="Usuario"
              name="userStamp"
              control={control}
              defaultValue={initialValues.userStamp}
              props={{
                name: "userStamp",
                helperText: errors.userStamp?.message,
                error: errors.userStamp ? true : false,
                disabled: !isEdit || !watch("generateATDP"),
                InputLabelProps: { shrink: true },
              }}
              max={"150"}
            />{" "}
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            {" "}
            <InputRounded
              label="Contraseña"
              name="passwordStamp"
              control={control}
              // defaultValue={initialValues.passwordStamp}
              props={{
                name: "passwordStamp",
                helperText: errors.passwordStamp?.message,
                error: errors.passwordStamp ? true : false,
                disabled: !isEdit || !watch("generateATDP"),
                type: "password",
                InputLabelProps: { shrink: true },
              }}
              max={"200"}
            />{" "}
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="flex-start"
        >
          <Grid
            container
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            {" "}
            <Grid item lg={3} md={8} sm={5} xs={4}>
              <CustomTypography type="definition">
                {"Firmar ATDP"}
              </CustomTypography>
            </Grid>
            <Grid item lg={6} md={4} sm={6} xs={6}>
              {" "}
              <ControlledSwitch
                error={errors.signATDP ? true : false}
                helperText={errors.signATDP?.message}
                // value={currentParameters?.signATDP}
                control={control}
                name="signATDP"
                isDisabled={!isEdit || !watch("generateATDP")}
                checked={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item lg={5} md={10} sm={6} xs={8} style={{ display: "none" }}>
              <CustomTypography type="definition">
                {"Firmar Términos y condiciones"}
              </CustomTypography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4} style={{ display: "none" }}>
              {" "}
              <ControlledSwitch
                error={errors.signTyC ? true : false}
                helperText={errors.signTyC?.message}
                // defaultValue={initialValues.signTyC}
                control={control}
                name="signTyC"
                isDisabled={!isEdit || !watch("generateATDP")}
                checked={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          alignItems="center"
        >
          <Grid item lg={2} md={4} sm={12} xs={12}>
            <CustomTypography type="definition">
              {"Firma desatendida"}
            </CustomTypography>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label="Usuario"
              name="userSign"
              control={control}
              // defaultValue={initialValues.userSign}
              props={{
                name: "userSign",
                helperText: errors.userSign?.message,
                error: errors.userSign ? true : false,
                disabled:
                  !isEdit ||
                  signATDP ||
                  !watch("generateATDP") ||
                  !watch("signATDP"),
                InputLabelProps: { shrink: true },
              }}
              max={"50"}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label="Contraseña"
              name="passwordSign"
              control={control}
              // defaultValue={initialValues.passwordSign}
              props={{
                name: "passwordSign",
                helperText: errors.passwordSign?.message,
                error: errors.passwordSign ? true : false,
                disabled:
                  !isEdit ||
                  signATDP ||
                  !watch("generateATDP") ||
                  !watch("signATDP"),
                type: "password",
                InputLabelProps: { shrink: true },
              }}
              max={"150"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: "none" }}
        >
          <CustomTypography type="subtitle">
            {"Envío ATDP por correo electrónico al participante"}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction="row"
          spacing={1}
        >
          <Grid item lg={1} md={2} sm={6} xs={4} style={{ display: "none" }}>
            {" "}
            <Typography>{"Automático"}</Typography>
          </Grid>
          <Grid item lg={1} md={6} sm={6} xs={4} style={{ display: "none" }}>
            <ControlledSwitch
              error={errors.automaticEmail ? true : false}
              helperText={errors.automaticEmail?.message}
              // defaultValue={initialValues.automaticEmail}
              control={control}
              name="automaticEmail"
              isDisabled={!isEdit}
              checked={true}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item lg={2} md={3} sm={6} xs={12}>
            <PrimaryButton
              title={isNew === true ? "Guardar" : "Actualizar"}
              props={{
                disabled: !isEdit ,
                type: "submit",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <FullLoader open={loading} />
      <CreateAtdpForm
        isOpen={modalATDP}
        isClose={() => handleCancelAtdp(true)}
        handleSuccessProp={handleSuccess}
      />
      <ConfirmATDP
        isOpen={!!statusAtdp}
        isClose={() => handleConfirmAtdp(false)}
        messageStatusAtdp={statusAtdp}
      />
      <ErrorATDP isOpen={errotAtdp} isClose={() => handleErrorAtdp(false)} />
      <CancelATDP
        isOpen={cancelATDP}
        isNo={() => handleCancelAtdp(false)}
        isYes={() => {
          handleCancelAtdp(false);
          handleCloseModal();
        }}
      />
      <PreviewATDP
        htmlProp={html}
        isOpen={previewATDP}
        isClose={() => handlePreviewAtdp(false)}
      />
    </form>
    :
    <FullLoader open={true} />
  );
};

export default CompanyParametrization;
