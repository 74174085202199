import { Grid, makeStyles } from "@material-ui/core";
import error from "../../assets/error.png";
import sucessfull from "../../assets/sucessfull.png";
import refresh from "../../assets/refresh.png";
import "../ProgressBar/ProgressBar.css";
import { StateLoad } from "../../store/types";

type ItemsListProps = {
  list?: Array<StateLoad | undefined>;
};

export const ItemsList = (props: ItemsListProps) => {
  const classes = useStyles();

  const type = (name: string | undefined) => {
    if (name === "exitoso")
      return <img src={sucessfull} alt="hands" className={"imageInfo"} />;
    else if (name === "erroneo")
      return <img src={error} alt="hands" className={"imageInfo"} />;
    else return <img src={refresh} alt="hands" className={"imageInfo"} />;
  };
  return (
    <Grid container spacing={2} direction="column">
      {props.list?.map((item) => (
        <Grid item key={item?.name}>
          <Grid item className={classes.list}>
            <Grid container item direction="row" spacing={1}>
              <Grid style={{ paddingTop: "12px" }} item>
                {type(item?.state)}
              </Grid>
              <Grid style={{ paddingTop: "13px" }} item>
                {item?.name}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme: any) => ({
  list: {
    border: "1px solid #D5D5D5",
    height: "40px",
    color: "#575756",
    paddingLeft: "18px",
    fontSize: theme.typography.pxToRem(16),
    borderRadius: "6px",
  },
}));
