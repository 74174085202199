import { Grid } from "@material-ui/core";

//COMPONENTS
import { NoTypeProcessIcon } from "../../../components/Icons/NoTypeProcessIcon";
import { CustomTypography } from "../../../components/typography/CustomTypography";

export const notDataFound = (rol?: string) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoTypeProcessIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography
          type="definition"
          children={
            rol === "BIOMETRIA RNEC"
              ? "Aún no se ha creado un tipo de trámite"
              : "Aún no has creado un tipo de trámite"
          }
        />{" "}
      </Grid>
    </Grid>
  );
};
