import { encryptionIv, encryptionKey } from "../../config/config";
import * as aesjs from "aes-js";
import jwt_decode from "jwt-decode";
import { DataToken, FieldsAdvanceSearch } from "../../store/types";
import XLSX from "xlsx";
import { formatDateIso } from "./formatDate";
import {
  BASE_URL_WEBSOCKET,
  BASE_URL_WEBSOCKET_LOCAL,
  BASE_URL_WEBSOCKET_MOVIL,
} from "../../config/const";
import { Roles } from "../types/const";

/**
 * Generate random id of length passed as parameter
 * @param {number} length - length id
 * @returns {string} return id generated
 */
export const makeId = (length: number) => {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

/**
 * Decode querystring from passed parameters
 * @param {string} queryString token string passed
 * @returns {string} tokens decoded
 */
export function decodeTokenData(queryString: string) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || "", "base64").toString("hex")
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = cleanParamsObj ? JSON.parse(cleanParamsObj) : undefined;
    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      const tokens = {
        token: paramsObj.token,
        refreshToken: paramsObj.refreshToken,
        encryptedToken: queryString,
      };
      return tokens;
    } else {
      return undefined;
    }
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

/**
 * Return name of finger by id
 * @param {number} fingerID finger ID
 * @returns {string} Finger name-description
 */
export function getNameFinger(fingerID: any) {
  switch (fingerID) {
    case 1:
      return "Pulgar mano derecha";
    case 2:
      return "Índice mano derecha";
    case 3:
      return "Medio mano derecha";
    case 4:
      return "Anular mano derecha";
    case 5:
      return "Meñique mano derecha";
    case 6:
      return "Pulgar mano izquierda";
    case 7:
      return "Índice mano izquierda";
    case 8:
      return "Medio mano izquierda";
    case 9:
      return "Anular mano izquierda";
    case 10:
      return "Meñique mano izquierda";
    default:
      break;
  }
}

/**
 * limpia el querystring
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar: string) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\000-\031\200-\377]+/g, "");
    return paramsChar;
  } catch (err) {
    console.error("Error limpiando parametros:", err);
  }
}

/**
 * Capitalize string passed
 * @function
 * @param {string} str String to capitalize
 * @return {string} Capitalized String
 */
export const Capitalize = (str: string) => {
  var data = str.split(" ");
  var response = "";
  for (let i = 0; i < data.length; i++) {
    response +=
      data[i].charAt(0).toUpperCase() + data[i].slice(1).toLowerCase() + " ";
  }
  return response.substring(0, response.length - 1);
};

/**
 * Decode and return token
 * @function
 * @param {string} token Token to decode
 * @return {string} Token decoded
 */
export const decodeToken = (token: string) => {
  if (token !== "") {
    const data = jwt_decode<DataToken>(token);
    return data;
  }
};

/**
 * Validate if value passed is empty or unset and return the value o default value
 * @function
 * @param {any} value Value to validate
 * @param {any} defaultValue Default value if value is empty or unset
 * @return {any} Value returned
 */
export const valueOrDefault = (value: any, defaultValue: any) => {
  let returnData = value;
  if (
    typeof value === undefined ||
    value === null ||
    value === "" ||
    value === "null"
  ) {
    returnData = defaultValue;
  }
  return returnData;
};

/**
 * Default personalization
 */
export const defaultPersonalization = {
  logo: {
    blob: null,
    name: null,
    path: null,
    extension: null,
  },
  principalColor: "#1D71B8",
  secondaryColor: "#FA5A00",
  buttonColor: "#1D71B8",
  buttonTextColor: "#FFFFFF",
  backgroundColor: "",
  backgroundImage: {
    blob: null,
    name: null,
    path: null,
    extension: null,
  },
};

/**
 * Set current company on sessionStorage
 * @function
 * @param {any} value nitCompany
 */
export const selectCurrentCompany = (value: any) => {
  let tokensessionStorage = sessionStorage.getItem("jwtToken") || "";
  var dataToken = decodeToken(tokensessionStorage ?? "");
  if (dataToken != null) {
    sessionStorage.setItem("nitCompany", value);
  }
  window.location.href = "../businessunit";
};

/**
 * Create excel file
 * @function
 * @param {any} data Data to create excel file
 * @param {any} nameFile Name file
 * @param {any} rol Rol User
 * @param {any} CSV is CSV file ?
 */
export const createExcel = (
  data: any[],
  nameFile: string,
  rol?: string,
  CSV?: boolean
) => {
  if (!data.length) data.push({});
  const workSheet = XLSX.utils.json_to_sheet(data, {
    dateNF: "mm/dd/aaaa;@",
    cellDates: true,
    // Opcion para eliminar cabezera de excel en archivo csv
    skipHeader: rol === "BIOMETRIA RNEC" ? true : false,
  });
  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, "Hoja1");
  //Buffer
  XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

  if (CSV) {
    XLSX.utils.sheet_to_csv(workBook, { strip: true });
    XLSX.writeFile(workBook, `${nameFile}.csv`);
  } else if (rol === "BIOMETRIA RNEC" && nameFile === "Listado de Atdp") {
    XLSX.utils.sheet_to_csv(workBook, { strip: true });
    XLSX.writeFile(workBook, `Listado de Atdp.csv`);
  } else {
    //Download
    XLSX.writeFile(workBook, `${nameFile}.xlsx`);
  }
};

/**
 * Download data from base64 to ZIP
 * @function
 * @param {any} downloadRoute Route to download
 * @param {any} nameFile Name file
 */
export const downloadDataFromBase64ToZip = (
  downloadRoute: string,
  nameFile: string
) => {
  const link = `data:application/zip;base64,${downloadRoute}`;
  const downloadLink = document.createElement("a");
  const fileName = nameFile + ".zip";
  downloadLink.href = link;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * Regex patterns to match
 */
export const regex = {
  WithoutCharactersSpecial: /^[a-zA-ZñşŞıİçÇöÖüÜĞğáéíóúÁÉÍÓÚ ]+$/,
  Cellphone:
    /((30[0-5])|(31[0-9])|(32[0-4])|(333)|(35[0-1]))[\d]{3}[\d\w\W\b]{4,5}$/,
  CelAndPhone:
    /^((((30[0-5])|(31[0-9])|(32[0-4])|(333)|(35[0-1]))[\d]{3}[\d\w\W\b]{4,5})|^((60[0-2])|(60[4-8])?)[\d\b]{7})$/,
  Ip: /^10\.(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2})\b(\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b)|(^172\.1[6-9]\.(\b(?:(?:25[0-5]|2?[0-4][0-9]|[01]?[0-9][0-9]?)\.)\b)(\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))|(^172\.2[0-9]\.(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\b)(\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))|(^172\.3[0-1]\.(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\b)(\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))|(^192\.168\.(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)\b)(\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))/,
  Mac: /^(([a-fA-F0-9\w\W]{2,}-){5}[a-fA-F0-9\w\W]{2,}|([a-fA-F0-9\w\W]{2,}:){5,}[a-fA-F0-9\w\W]{2,}|([0-9A-Fa-f\w\W]{4,}\.){2,}[0-9A-Fa-f\w\W]{4,})?$/,
  Email: /^[\w\W]+@[\w\W]+\.[\w\W]{2,}$/,
};

/**
 * Verifica si hay datos en la busqueda avanzada exceptuando la fecha de creacion con el dia de hoy
 */
export const advanceSearchHasValues = (
  fieldsAdvancedSearch: FieldsAdvanceSearch[],
  nameDate: string
) =>
  fieldsAdvancedSearch.some((field) => {
    if (field.name === nameDate) {
      if (
        !field.value ||
        (!!field.value &&
          field.value === `${formatDateIso()}:${formatDateIso()}`)
      ) {
        return false;
      }
    }
    return field.value !== "";
  });

/**
 * Return code description from code number
 * @function
 * @param {number} code code to return description
 * @returns {string} String code description
 */
export const licenseCode = (
  code: number,
  argument: "plural" | "singular" = "singular"
): string => {
  switch (code) {
    case 200:
      return "Resultado éxitoso";
    case 422:
      return argument === "singular"
        ? "Cliente no cuenta con bolsa, o no está activa"
        : "No se encontraron empresas";
    case 500:
      return "No se encontraron datos";
    case 401:
      return "No autorizado o token vencido";
    case 415:
      return "Ocurrió un error en la solicitud";
    default:
      return "Error desconocido";
  }
};

// obtiene geolocalizacion guardadad de la sessionStorage
export const getLocationUserStore = () => {
  const position = {
    latitude: sessionStorage.getItem("pos_latitud") || "",
    longitude: sessionStorage.getItem("pos_longitud") || "",
  };

  return position;
};

// obtiene encriptacion guardadada de la sessionStorage
export const getEncryptMode = () => {
  return sessionStorage.getItem("aes-encrypt");
};

// metodo para obtener la url del web socket respectivo al rol del usuario logueado
export const getUrlWebSocketByRole = () => {
  const token = sessionStorage.getItem("jwtToken");
  if (token) {
    const decodedToken = decodeToken(token);

    if (decodedToken?.BIOMETRIA_ROLE === Roles.BIOMETRIA_CAPTURA_MOVIL) {
      return {
        role: "movil",
        url: BASE_URL_WEBSOCKET_MOVIL,
        urlLocal: "",
      };
    } else {
      return {
        role: "desktop",
        url: BASE_URL_WEBSOCKET,
        urlLocal: BASE_URL_WEBSOCKET_LOCAL,
      };
    }
  }
  return {
    role: "desktop",
    url: "",
    urlLocal: "",
  };
};

export const addPopStateListener = (listener: () => void) => {
  window.history.pushState(null, "", window.location.pathname);
  window.addEventListener("popstate", listener);
};

export const removePopStateListener = (listener: () => void) => {
  window.removeEventListener("popstate", listener);
};

export const popStateListener = () => {
  window.history.go(1);
  console.log("Navegación hacia atrás interceptada");
};

/**generic function debounce called function with delay
 * @function Function to be debounced
 * @param delay delay in milliseconds
 */
export function debounceded(func: () => void, delay: number) {
  var timer = 0;
  return function debouncedFn() {
    if (Date.now() - timer > delay) {
      func();
    }
    timer = Date.now();
  };
}
