import { addNotifications, readNotification, getUserNotifications } from "../../services/NotificationService";
import { INotification, IReadNotification } from "../models/NotificationModel";
import { setError } from "../slices/CommonSlice";
import { setLoadingNotification, setNotifications } from "../slices/NotificationsSlice";
import { AppThunk } from "../store";

/**
 * @description Lanza el servicio getUserNotifications que retorna las notificaciones no leidas del usuario logueado
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista notificaciones al hook notifications con el tipo INotification[].
 */
export const getUserNotificationsAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingNotification(true)) &&
  getUserNotifications()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setNotifications(response.result.records));
      }
    })
    .catch((err) => null)
    .finally(() => dispatch(setLoadingNotification(false)));
};

/**
 * @description Lanza el servicio addNotifications que almacena las notificaciones en la base de datos, valida la existencia de las notificaciones y los estados.
 * @param { INotification[] } notifications Parametro de tipo INotification[] que representa el array de objetos de notificaciones.
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con el metodo que consulta las notificaciones del usuario.
 */
export const addNotificationsAC = (notifications: INotification[]): AppThunk => (dispatch) => {
  dispatch(setLoadingNotification(true)) &&
  addNotifications(notifications)
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(getUserNotificationsAC());
      }
    })
    .catch((err) => null)
    .finally(() => dispatch(setLoadingNotification(false)));
};

/**
 * @description Lanza el servicio readNotification que cambia el estado de una notificacion como leido
 * @param { IReadNotification } body Parametro de tipo IReadNotification que representa el id de la notificacion y el estado nuevo
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con el metodo que consulta las notificaciones del usuario.
 */
export const readNotificationAC = (body: IReadNotification): AppThunk => (dispatch) => {
  dispatch(setLoadingNotification(true)) &&
  readNotification(body)
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(getUserNotificationsAC());
      }
    })
    .catch((err) => null)
    .finally(() => dispatch(setLoadingNotification(false)));
};