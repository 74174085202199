export const GET_TYPE_PROCESS_LIST = "GET_TYPE_PROCESS_LIST";
export const CREATE_TYPE_PROCESS = "CREATE_TYPE_PROCESS";
export const ON_ERROR = "ON_ERROR";
export const ON_SUCCESS = "ON_SUCCESS";


export enum ECompanyActionTypes {
    CREATE_PARAMETRIZATION = 'CREATE_PARAMETRIZATION',
  }
  
  export interface ICompanyBaseAction {
    type: ECompanyActionTypes
  }
  

  
  export interface CreateParametersConfig {
    companyId: string
    parameters: {}
  }
  
  export interface SettingsResponseDto {
    parameters: Map<string, string>
    information: string
    informationCode: string
  }
  
  export interface PersonalizationResponseDto {
    entityDto: ObjectEntityDto
    information: string
    informationCode: string
  }

type ObjectEntityDto = {
  backgroundColor: string
  backgroundImage: {blob: string, name: string, path: null, extension: null}
  logo: {blob: string, name: string, path: null, extension: null}
  principalColor: string
  secondaryColor: string
  buttonColor: string
  buttonTextColor: string
}
 
export type TabContent = {
  label: string
  tabContent: JSX.Element
  title?: string
}

export type TabComponentsProps = {
  content: Array<TabContent>
  tab: number
  handleChangeTab: (event: React.ChangeEvent<{}>, value: any) => void
  aria: string
}

export type TabPanelProps = {
  children: React.ReactNode
  value: number
  index: number
  other?: any
}

export type AccordionTabs = {
  content: Array<TabContent>
  aria: string
  title: string
}

export interface StandardResponse<T> {
  transactionId: string
  timestamp: Date
  statusCode: number
  statusMessage: string
  result: T
  statusDetails: StatusDetails[]
}

interface StatusDetails {
  code: string
  Severity: string
  Description: string
}

export interface IBasicData {
  id: string
  name: string
  code?: string
}

