import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAverageCompany,
  IBusinessUnitRecord,
  IUserRecords,
  ICustomerConsumption,
  IPlanExpired,
  IDashboard,
  INextBalanceToExpire,
  IRecordCompany,
  ITypeProcessCompany,
  ITypeProcessCompanyBusinessUnit,
  ITypeProcessByUser,
  INotification,
  IDoughnutTransaction,
  IVerticalBarTransaction,
  ValidateCountersNit,
} from "../models/DashboardModel";
import { RootState } from "./rootReducer";

type DashboardState = {
  dasboardCapturador: IDashboard;
  businessUnitRecords: IBusinessUnitRecord[];
  userRecords: IUserRecords[];
  typeProcessCompanyBusinessUnits: ITypeProcessCompanyBusinessUnit[];
  recordCompanies: IRecordCompany[];
  typeProcessCompanies: ITypeProcessCompany[];
  averageCompanies: IAverageCompany[];
  customerPlanExpired: IPlanExpired[];
  customerConsumption: ICustomerConsumption | null;
  nextBalanceToExpire: INextBalanceToExpire[];
  nextPlanExpirations: IPlanExpired[];
  businessUnitAverage: IBusinessUnitRecord[];
  recordTypeProcessByUser: ITypeProcessByUser[];
  recordCompaniesMonthly: IRecordCompany[];
  averageCompaniesMonthly: IAverageCompany[];
  notificationDashboard: INotification[];
  customerPlanExpiredMessage: string | null;
  customerConsumptionMessage: string | null;
  nextBalanceToExpireMessage: string | null;
  nextPlanExpirationsMessage: string | null;
  doughnutTransactions: IDoughnutTransaction[];
  verticalBarTransactions: IVerticalBarTransaction[];
  switchCompany: boolean;
  validatedCounters: ValidateCountersNit[];
};

const initialState: DashboardState = {
  dasboardCapturador: {
    average_Time_Comparison: "00:00:00",
    daily_Comparison_Average: 0,
  },
  businessUnitRecords: [],
  userRecords: [],
  typeProcessCompanyBusinessUnits: [],
  recordCompanies: [],
  recordCompaniesMonthly: [],
  typeProcessCompanies: [],
  averageCompanies: [],
  averageCompaniesMonthly: [],
  customerPlanExpired: [],
  customerConsumption: null,
  nextBalanceToExpire: [],
  nextPlanExpirations: [],
  businessUnitAverage: [],
  recordTypeProcessByUser: [],
  notificationDashboard: [],
  customerPlanExpiredMessage: null,
  customerConsumptionMessage: null,
  nextBalanceToExpireMessage: null,
  nextPlanExpirationsMessage: null,
  doughnutTransactions: [],
  verticalBarTransactions: [],
  switchCompany: false,
  validatedCounters: [],
};

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    setDasboardCapturador: (state, { payload }: PayloadAction<IDashboard>) => {
      state.dasboardCapturador = payload;
    },
    setBusinessUnitRecords: (
      state,
      { payload }: PayloadAction<IBusinessUnitRecord[]>
    ) => {
      state.businessUnitRecords = payload;
    },
    setUserRecords: (state, { payload }: PayloadAction<IUserRecords[]>) => {
      state.userRecords = payload;
    },
    setTypeProcessCompanyBusinessUnits: (
      state,
      { payload }: PayloadAction<ITypeProcessCompanyBusinessUnit[]>
    ) => {
      state.typeProcessCompanyBusinessUnits = payload;
    },
    setRecordCompanies: (
      state,
      { payload }: PayloadAction<IRecordCompany[]>
    ) => {
      state.recordCompanies = payload;
    },
    setRecordCompaniesMonthly: (
      state,
      { payload }: PayloadAction<IRecordCompany[]>
    ) => {
      state.recordCompaniesMonthly = payload;
    },
    setTypeProcessCompanies: (
      state,
      { payload }: PayloadAction<ITypeProcessCompany[]>
    ) => {
      state.typeProcessCompanies = payload;
    },
    setAverageCompanies: (
      state,
      { payload }: PayloadAction<IAverageCompany[]>
    ) => {
      state.averageCompanies = payload;
    },
    setAverageCompaniesMonthly: (
      state,
      { payload }: PayloadAction<IAverageCompany[]>
    ) => {
      state.averageCompaniesMonthly = payload;
    },
    setCustomerPlanExpired: (
      state,
      { payload }: PayloadAction<IPlanExpired[]>
    ) => {
      state.customerPlanExpired = payload;
    },
    setCustomerConsumption: (
      state,
      { payload }: PayloadAction<ICustomerConsumption>
    ) => {
      state.customerConsumption = payload;
    },
    setNextBalanceToExpire: (
      state,
      { payload }: PayloadAction<INextBalanceToExpire[]>
    ) => {
      state.nextBalanceToExpire = payload;
    },
    setNextPlanExpirations: (
      state,
      { payload }: PayloadAction<IPlanExpired[]>
    ) => {
      state.nextPlanExpirations = payload;
    },
    setBusinessUnitAverage: (
      state,
      { payload }: PayloadAction<IBusinessUnitRecord[]>
    ) => {
      state.businessUnitAverage = payload;
    },
    setRecordTypeProcessByUser: (
      state,
      { payload }: PayloadAction<ITypeProcessByUser[]>
    ) => {
      state.recordTypeProcessByUser = payload;
    },
    setNotificationDashboard: (
      state,
      { payload }: PayloadAction<INotification>
    ) => {
      const currentNotification: INotification[] = state.notificationDashboard;
      if (
        !state.notificationDashboard.find(
          (item) => item.title === payload.title
        )
      ) {
        currentNotification.push(payload);
      }
      state.notificationDashboard = currentNotification;
    },
    setCustomerPlanExpiredMessage: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.customerPlanExpiredMessage = payload;
    },
    setCustomerConsumptionMessage: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.customerConsumptionMessage = payload;
    },
    setNextBalanceToExpireMessage: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.nextBalanceToExpireMessage = payload;
    },
    setNextPlanExpirationsMessage: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.nextPlanExpirationsMessage = payload;
    },

    setDoughnutTransaction: (
      state,
      { payload }: PayloadAction<IDoughnutTransaction[]>
    ) => {
      state.doughnutTransactions = payload;
    },
    setVerticalBarTransaction: (
      state,
      { payload }: PayloadAction<IVerticalBarTransaction[]>
    ) => {
      state.verticalBarTransactions = payload;
    },
    setSwitchCompany: (state, { payload }: PayloadAction<boolean>) => {
      state.switchCompany = payload;
    },
    resetVerticalBarTransaction: (state) => {
      state.verticalBarTransactions = [];
    },
    setValidatedCounters: (
      state,
      { payload }: PayloadAction<ValidateCountersNit[]>
    ) => {
      state.validatedCounters = payload;
    },
  },
});

export const {
  setDasboardCapturador: setDashboardCapturador,
  setBusinessUnitRecords,
  setUserRecords,
  setTypeProcessCompanyBusinessUnits,
  setRecordCompanies,
  setTypeProcessCompanies,
  setAverageCompanies,
  setCustomerPlanExpired,
  setCustomerConsumption,
  setNextBalanceToExpire,
  setNextPlanExpirations,
  setBusinessUnitAverage,
  setRecordTypeProcessByUser,
  setRecordCompaniesMonthly,
  setAverageCompaniesMonthly,
  setNotificationDashboard,
  setCustomerPlanExpiredMessage,
  setCustomerConsumptionMessage,
  setNextBalanceToExpireMessage,
  setNextPlanExpirationsMessage,
  setDoughnutTransaction,
  setVerticalBarTransaction,
  setSwitchCompany,
  resetVerticalBarTransaction,
  setValidatedCounters,
} = dashboardSlice.actions;

export const DashboardSelector = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
