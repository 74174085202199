import { useMediaQuery, useTheme } from "@material-ui/core";
import { TabComponentsProps } from "../../utils/types/types";
import { AccordionMobile } from "./AccordionMobile";
import TabComponent from "./TabComponent";

export const TabAdmin = (props: TabComponentsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div style={{ justifyContent: "center" }}>
      {isMobile ? <AccordionMobile {...props} /> : <TabComponent {...props} />}
    </div>
  );
};
