import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import CancelIcon from "../../assets/cancel.svg";
import { PrimaryButton } from "../button/PrimaryButton";

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",

    "& p": {
      margin: "0px",
    },
  },

  text: {
    font: "normal normal bold 16px/24px Muli",
    marginBottom: "17px",
    textAlign: "center",
  },
  iconContainer: {
    width: "83px",
    height: "80px",
    marginBottom: "17px",
  },
  buttonCancel: {
    width: "74px",
    height: "40px",
    fontSize: "18px",
    marginBottom: "37px",
  },

  buttonContainer: {
    display: "flex",
  },
}));

interface Props {
  isOpen: boolean;
  isNo: any;
  isYes: any;
}

const CancelATDP = ({ isOpen, isNo, isYes }: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const classes = useStyles();
  return (
    <Dialog fullWidth={true} maxWidth={maxWidth} open={isOpen}>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            <img
              src={CancelIcon}
              alt="success Icon"
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className={classes.text}>
            <p>¿Desea cancelar?</p>
            <p>se eliminarán todos los cambios</p>
          </div>
          <div className={classes.buttonContainer}>
            <div
              style={{ marginRight: "10px" }}
              className={classes.buttonCancel}
            >
              <PrimaryButton title={"Si"} props={{ onClick: isYes }} />
            </div>
            <div className={classes.buttonCancel}>
              <PrimaryButton title={"No"} props={{ onClick: isNo }} />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CancelATDP;
