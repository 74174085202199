import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  User,
  IAudit,
  IUserExcel,
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
  FieldsAdvanceSearch,
} from "../types";
import { RootState } from "./rootReducer";

type UserState = {
  currentUser: User | undefined;
  users: User[];
  userAudit: IAudit | undefined;
  userExcel: IUserExcel[];
  paginatorUser: PaginatorWithFilterAndSort;
  size: number;
  advanceSearch: FieldsAdvanceSearch[];
  showForm: boolean;
};

const initialState: UserState = {
  currentUser: undefined,
  users: [],
  userAudit: undefined,
  userExcel: [],
  paginatorUser: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    sort: "recent",
  },
  size: 0,
  advanceSearch: [],
  showForm: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<User | undefined>) => {
      state.currentUser = payload;
    },
    setUser: (state, { payload }: PayloadAction<User[]>) => {
      state.users = payload;
    },
    setUsersExcel: (state, userExcel: PayloadAction<IUserExcel[]>) => {
      state.userExcel = userExcel.payload;
    },
    setPaginatorUser: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorUser.paginator = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setFilterUser: (state, { payload }: PayloadAction<string>) => {
      state.paginatorUser.filter = payload;
    },
    setAdvanceSearch: (
      state,
      { payload }: PayloadAction<FieldsAdvanceSearch[]>
    ) => {
      state.advanceSearch = payload;
    },
    setSortUser: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorUser.sort = payload;
    },
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setUpdateStateUserById: (state, { payload }: PayloadAction<number>) => {
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.id === payload) {
            return { ...item, state: !item.state };
          } else {
            return item;
          }
        }),
      };
    },
  },
});

export const {
  setCurrentUser,
  setFilterUser,
  setPaginatorUser,
  setShowForm,
  setSize,
  setSortUser,
  setUpdateStateUserById,
  setUser,
  setUsersExcel,
  setAdvanceSearch,
} = userSlice.actions;

export const UserSelector = (state: RootState) => state.user;

export default userSlice.reducer;
