import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import refreshIcon from "../../assets/refreshIcon.png";
import sucessfullIcon from "../../assets/sucessfullIcon.png";
import iconDownload from "../../assets/Icondownloadblue.png";
import errorIcon from "../../assets/errorIcon.png";
import filtroIcon from "../../assets/Filtro.png";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { SecondaryButton } from "../../components/button/SecondaryButton";
import InputSearch from "../../components/Inputs/InputSearch";
import { ItemsList } from "../../components/List/ItemsList";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { StateLoad } from "../../store/types";

type MassiveLoadResultProps = {
  sucessfull?: number;
  replaced?: number;
  error?: number;
  onDownloadErrors: any;
  items?: Array<StateLoad | undefined>;
  onContinue: any;
};
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    background: "#FFFFFF !important",
  },
})((props: any) => (
  <Menu
    open={props.open}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MassiveLoadResult = (props: MassiveLoadResultProps) => {
  const [search, setSearch] = useState<string | undefined>("");
  const searchRef = useRef<{ value: string }>(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [itemsList, setItemsList] = useState<Array<StateLoad | undefined>>();
  const [red, setRed] = useState("");
  const [yellow, setYellow] = useState("");
  const [green, setGreen] = useState("");

  const options = [
    {
      label: "Reemplazados",
    },
    {
      label: "Errores",
    },
    {
      label: "Limpiar filtro",
    },
  ];

  React.useEffect(() => {
    var total = props.sucessfull! + props.replaced! + props.error!;
    if (props.sucessfull)
      setGreen(((props.sucessfull * 100) / total).toString());
    if (props.replaced) setYellow(((props.replaced * 100) / total).toString());
    if (props.error) setRed(((props.error * 100) / total).toString());
    setItemsList(props.items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (_event: any, index: any) => {
    setSelectedIndex(index);
    if (index === 0)
      setItemsList(props.items?.filter((n) => n?.state === "reemplazado"));
    else if (index === 1)
      setItemsList(props.items?.filter((n) => n?.state === "erroneo"));
    else if (index === 2) setItemsList(props.items);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const filterList = () => {
    if (searchRef.current?.value!?.length < 1) {
      setItemsList(props.items);
    } else {
      let name: string = searchRef.current?.value!;
      setItemsList(
        props.items?.filter((n) =>
          n?.name.toLowerCase().includes(name.toLowerCase())
        )
      );
    }
  };

  return (
    <Grid container>
      <Grid
        container
        style={{ paddingTop: "17px" }}
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={sucessfullIcon} alt="hands" className={"imageInfo"} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Exitosos: {props.sucessfull}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={refreshIcon} alt="hands" className={"imageInfo"} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Reemplazados: {props.replaced}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <img src={errorIcon} alt="hands" className={"imageInfo"} />
            </Grid>
            <Grid item>
              <Typography className={classes.messageTypography}>
                Errores: {props.error}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: "17px" }} direction="row">
        <ProgressBar
          done={"100"}
          multipleColor={true}
          green={green}
          yellow={yellow}
          red={red}
          title=""
        />
      </Grid>
      <Grid container style={{ paddingTop: "34px" }} direction="row">
        <Grid item xs={6}>
          <Typography className={classes.message}>
            Detalle del cargue
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={9} lg={11} md={11} sm={11}>
              <InputSearch
                name="filtro"
                value={search}
                inputRef={searchRef}
                disabled={false}
                onChange={() => {
                  setSearch(
                    searchRef.current ? searchRef.current.value : undefined
                  );
                  filterList();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "#C6C6C6" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={1} style={{ display: "flex" }}>
              <Button
                style={{ padding: "10px  10px", minWidth: "fit-content" }}
                onClick={handleClick}
              >
                <img src={filtroIcon} alt="filter-icon" />
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {options.map((option: any, index: any) => (
                  <StyledMenuItem
                    key={option.label}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.label}
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: "20px" }}>
        <div className={classes.itemList}>
          <ItemsList list={itemsList}></ItemsList>
        </div>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ paddingTop: "24px" }}
        justifyContent="space-between"
      >
        <Grid item>
          <SecondaryButton
            title={"Descargar informe de errores"}
            props={{
              onClick: props.onDownloadErrors,
              startIcon: (
                <img src={iconDownload} alt="hands" className={"imageInfo"} />
              ),
              style: { fontWeight: "bold", textTransform: "initial" },
            }}
          ></SecondaryButton>
        </Grid>
        <Grid item>
          <PrimaryButton
            title={"Continuar"}
            props={{
              onClick: props.onContinue,
              style: { width: "200px", textTransform: "capitalize" },
              disabled:
                (props.sucessfull === undefined ? 0 : props.sucessfull) +
                  (props.replaced === undefined ? 0 : props.replaced) >
                0
                  ? false
                  : true,
            }}
          ></PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MassiveLoadResult;

const useStyles = makeStyles((theme: any) => ({
  messageTypography: {
    marginTop: 4,
    color: "#6D6E71",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  itemList: {
    overflowY: "scroll",
    overflowX: "hidden",
    width: "110%",
    maxHeight: "300px",
  },
  message: {
    marginTop: 27,
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
  },
}));
