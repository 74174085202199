import React, { memo } from "react";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import ViewLayout from "../../components/Layouts/ViewLayout";
import MassiveLoadComponent from "../../components/MassiveLoad/MassiveLoadComponent";
import MassiveLoadInfo from "../../components/MassiveLoad/MassiveLoadInfo";
import MassiveLoadResult from "../../components/MassiveLoad/MassiveLoadResult";
import { LoadModal } from "../../components/modal/LoadModal";
import DataTable from "../../components/Table/DataTable";
import { Column } from "../../components/Table/types";
import DeviceForm from "./DeviceForm";
import GetButtons from "./data/getButtons";
import notDataFound from "./data/NotDataFound";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";
import useDevice from "../../hooks/useDevice";
import moment from "moment";

type DeviceViewProps = {
  justTable?: boolean;
  handleRowClick?: Function;
  businessUnitId?: number;
  tabletOnly?: boolean;
  equipment?: number | undefined;
  hiddeMassiveButton?: boolean | undefined;
  nitCompany?: string;
};

/**
 * Retorna tabla de datos con la información de los dispositivos relacionados con la sede
 * @param { boolean } justTable - Validacion para mostrar solo la tabla de datos
 * @param { Function } handleRowClick - Función
 * @param { number } businessUnitId - Número de ID de la sede asociada
 * @param { boolean } tabletOnly
 * @param { number | undefined } equipment - Número de ID del equipo asociado
 * @param { boolean | undefined } hiddeMassiveButton - Validación para ocultar el botón cargue masivo
 * @param { string } nitCompany - NIT de la compañia
 * @returns { JSX.Element } Vista dispostivos
 */

const DeviceView: React.FC<DeviceViewProps> = ({
  justTable,
  handleRowClick,
  businessUnitId,
  tabletOnly,
  equipment,
  hiddeMassiveButton = false,
  nitCompany,
}) => {
  // Custom Hook
  const {
    advanceSearch,
    advanceSearchState,
    cancel,
    cancelText,
    confirmText,
    confirmate,
    deviceMassiveResponse,
    devices,
    fieldsAdvanced,
    handleBack,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingDevice,
    messageModal,
    modal,
    onCancel,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onFinalize,
    onSaveMassive,
    onUploadDataEvent,
    option,
    paginatorDevice,
    privilege,
    rol,
    setAdvanceSearchDevice,
    setLoad,
    setOption,
    setShowFormDevice,
    setTab,
    showForm,
    showFormDevice,
    size,
    tab,
    titleModal,
  } = useDevice({
    businessUnitId,
    equipment,
    nitCompany,
  });
  /**
   * Objeto con las columnas de las tablas
   */
  const columns: Column[] = [
    {
      id: "brandName",
      label: "Marca",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "modelName",
      label: "Modelo",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "typeName",
      label: "Tipo",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "serial",
      label: "Serial",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "businessUnitName",
      label: "Sede",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "createdOn",
      label: "Fecha creación",
      minWidth: 70,
      align: "center",
      haveDropDown: true,
      format: (value: string) => moment(value).format("DD/MM/YYYY, H:mm:ss"),
    },
    {
      id: "state",
      label: "Estado",
      align: "center",
      haveDropDown: true,

      component: {
        handleChange: (e: any, value: any) => handleConfirmClick(e, value),
        tooltipMessage: "Tipo proceso",
        withIcon: false,
        wordLength: 60,
        handleIconClick: () => {},
      },
    },
  ];

  const TabletComponent = () => {
    return (
      <DataTable
        columns={columns}
        buttons={GetButtons(justTable, privilege, hiddeMassiveButton, rol)}
        data={devices}
        havePagination={size > 10 ? true : false}
        option={option}
        setShowFormAdvance={(value: boolean) => {
          setShowFormDevice(value);
          setAdvanceSearchDevice(fieldsAdvanced);
        }}
        setLoad={setLoad}
        setTab={setTab}
        setOption={setOption}
        page={paginatorDevice.paginator.page - 1}
        paginator={paginatorDevice}
        setPage={handleChangePage}
        size={size}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginatorDevice.paginator.itemsPage}
        handleChangePage={handleChangePage}
        handleCellClick={(
          e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
          value: any,
          _column: Column
        ) => {
          if (rol !== "BIOMETRIA RNEC") {
            handleCellClick(e, value, _column);
          }
        }}
        notDataFound={
          (paginatorDevice.filter && devices.length === 0) || advanceSearchState
            ? undefined
            : notDataFound(rol)
        }
        handleFilter={handleFilter}
        handleSort={handleSort}
        createDisabled={
          privilege?.find(
            (privil) => privil === "BIOMETRIA_DISPOSITIVO_CREATE"
          ) === undefined
            ? true
            : false
        }
        deleteDiabled={
          privilege?.find(
            (privil) => privil === "BIOMETRIA_DISPOSITIVO_DELETE"
          ) === undefined
            ? true
            : false
        }
        modifyDiabled={
          privilege?.find(
            (privil) => privil === "BIOMETRIA_DISPOSITIVO_MODIFY"
          ) === undefined
            ? true
            : false
        }
        readDisabled={
          privilege?.find(
            (privil) => privil === "BIOMETRIA_DISPOSITIVO_READ"
          ) === undefined
            ? true
            : false
        }
        rol={rol}
        download={onDownloadData}
        handleOnOpenAdvSearch={handleOnOpen}
        handleOnLoadButton={handleOnLoadButton}
        loading={loadingDevice}
      />
    );
  };
  //#endregion

  return (
    <div>
      <LoadModal
        title={titleModal}
        open={modal}
        handleClose={onCancel}
        icon={<img src={imageModal} alt="hands" className={"imageInfo"} />}
        message={messageModal}
        handleConfirm={onFinalize}
        handleCancel={onCancel}
        isConfirm={confirmate}
        confirmText={confirmText}
        cancelText={cancelText}
        isCancel={cancel}
      />
      {isLoad ? (
        <MassiveLoadComponent
          loadResult={
            <MassiveLoadResult
              sucessfull={deviceMassiveResponse?.sucess}
              items={itemsList}
              replaced={deviceMassiveResponse?.replace}
              error={deviceMassiveResponse?.wrong}
              onDownloadErrors={onDownloadErrors}
              onContinue={onSaveMassive}
            />
          }
          loadInfo={
            <MassiveLoadInfo
              onDownload={onDownload}
              onUpload={onUploadDataEvent}
              title={"Bienvenido, así podrás hacer el cargue de dispositivos"}
            />
          }
          title={"Cargue masivo de dispositivos"}
          handleBack={handleBack}
          handleChangeTab={handleChangeTab}
          tab={tab}
        />
      ) : justTable ? (
        <>
          <AdvancedSearchModal
            open={showFormDevice}
            handleOnClose={handleOnClose}
            handleOnSearch={handleOnSearch}
            fieldsAdvancedSearch={
              !advanceSearch.length ? fieldsAdvanced : advanceSearch
            }
            fieldsAdvanced={fieldsAdvanced}
          />
          <DataTable
            columns={columns}
            buttons={GetButtons(justTable, privilege, hiddeMassiveButton, rol)}
            data={devices}
            havePagination={size > 10 ? true : false}
            option={option}
            setOption={setOption}
            setLoad={setLoad}
            setTab={setTab}
            page={paginatorDevice.paginator.page - 1}
            paginator={paginatorDevice}
            setPage={handleChangePage}
            size={size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFilter={handleFilter}
            handleSort={handleSort}
            rowsPerPage={paginatorDevice.paginator.itemsPage}
            handleCellClick={(
              e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
              value: any,
              _column: Column
            ) => {
              if (handleRowClick) {
                handleRowClick(e, value, _column);
              } else {
                console.error("handleRowClick must be implemented");
              }
            }}
            notDataFound={
              (paginatorDevice.filter && devices.length === 0) ||
              advanceSearch.length > 0
                ? undefined
                : notDataFound(rol)
            }
            createDisabled={
              privilege?.find(
                (privil) => privil === "BIOMETRIA_DISPOSITIVO_CREATE"
              ) === undefined
                ? true
                : false
            }
            deleteDiabled={
              privilege?.find(
                (privil) => privil === "BIOMETRIA_DISPOSITIVO_DELETE"
              ) === undefined
                ? true
                : false
            }
            modifyDiabled={
              privilege?.find(
                (privil) => privil === "BIOMETRIA_DISPOSITIVO_MODIFY"
              ) === undefined
                ? true
                : false
            }
            readDisabled={
              privilege?.find(
                (privil) => privil === "BIOMETRIA_DISPOSITIVO_READ"
              ) === undefined
                ? true
                : false
            }
            rol={rol}
            download={onDownloadData}
            handleOnOpenAdvSearch={handleOnOpen}
            loading={loadingDevice}
          />
        </>
      ) : showForm ? (
        <DeviceForm />
      ) : !tabletOnly ? (
        <>
          <AdvancedSearchModal
            open={showFormDevice}
            handleOnClose={handleOnClose}
            handleOnSearch={handleOnSearch}
            fieldsAdvancedSearch={
              !advanceSearch.length ? fieldsAdvanced : advanceSearch
            }
            fieldsAdvanced={fieldsAdvanced}
          />

          <ViewLayout
            isNew={true}
            isEdit={false}
            headerTitle="Dispositivos"
            editOption={true}
          >
            <TabletComponent />
          </ViewLayout>
        </>
      ) : (
        <TabletComponent />
      )}
      <HashDowloandModal />
      <FullLoader open={loadingDevice} viewLoader={true} />
    </div>
  );
};

export default memo(DeviceView);
