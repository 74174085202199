import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompanySelector } from "../store/slices/CompanySlice";
import { CommonSelector } from "../store/slices/CommonSlice";
import {
  advanceSearchAC,
  getAllLogComparisonCsvAC,
  getAllLogConsultCsvAC,
  getAllLogUnsatisfactoryAnswerCsvAC,
  getCompanyByUserAC,
  getFilterCompanyAC,
  getPaginatorCompanyAC,
  getSortCompanyAC,
  getSortOptionAllLogAC,
} from "../store/action-creators/CompanyActionCreators";
import {
  sortBy,
  FieldsAdvanceSearch,
  SortOptionLog,
  GeneralLog,
} from "../store/types";
import { formatDateIso } from "../utils/general/formatDate";

interface CompanyProps {
  nitCompany?: string;
}

const fieldsAdvanced: FieldsAdvanceSearch[] = [
  {
    name: "name",
    title: "Razon social",
    type: "text",
    value: "",
  },
  {
    name: "documentType",
    title: "Tipo de documento",
    type: "selectDocumentType",
    value: "",
  },
  {
    name: "documentNumber",
    title: "Número de documento",
    type: "text",
    value: "",
  },
  {
    name: "email",
    title: "Email",
    type: "text",
    value: "",
  },
  {
    name: "CreationDate",
    title: "Fecha de creación",
    type: "date",
    value: undefined,
  },
];
const fieldsLog: FieldsAdvanceSearch[] = [
  {
    name: "CreationDate",
    title: "Fecha de creación logs",
    type: "date",
    value: formatDateIso(),
    onlyOne: true,
  },
];

/**
 * Este Hook encapsula las funciones, useEffect y busqueda de la vista de compañias
 * @param { string } nitCompany - representa el Numero de Identificacion Tributaria de la compañia
 * @returns companies, currentCompany, fieldsAdvanced, fieldsAdvancedSearch, fieldsLog, handleChangePage, handleChangeRowsPerPage, handleFilter, handleOnClose, handleOnOpen, handleOnSearch, handleSort, handleSortOptionLog, isLoad, isLog, loadingCompany, option, page, paginatorCompany, privilege, rol, rowsPerPage, setLoad, setOption, setPage, setTab, showFormAdv, sizeCompany, tab
 */
const useCompany = ({ nitCompany }: CompanyProps) => {
  // redux
  const dispatch = useDispatch();

  const {
    paginatorCompany,
    sizeCompany,
    currentCompany,
    companies,
    loading: loadingCompany,
  } = useSelector(CompanySelector);
  const { privilege, rol } = useSelector(CommonSelector);
  // LOCAL STATES REACT
  const [tab, setTab] = React.useState(0);
  const [isLoad, setLoad] = React.useState(null);
  const [option, setOption] = React.useState(null);
  const [showFormAdv, setShowFormAdv] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [advanceSearchState, setAdvanceSearchState] = React.useState(false);
  const [isLog, setIsLog] = React.useState(false);

  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    React.useState(fieldsAdvanced);

  /** Se implementa useCallback para escuchar los cambios de, paginador, dispatch, nitCompany y loader para que no se cree un bucle de re-renderizado
   *  se verifica si existe actualmente un filtro en el hook de company para enviarlo con el filtro o enviarlo vacío para que se desplace a la primera pagina
   */
  const fillCompanyList = React.useCallback(() => {
    const initialPaginator = { ...paginatorCompany.paginator, page: 1 };
    if (
      paginatorCompany.filter !== undefined &&
      paginatorCompany.filter.length >= 3
    ) {
      dispatch(
        getCompanyByUserAC(sessionStorage.getItem("jwtToken") ?? "", {
          ...paginatorCompany,
          paginator: initialPaginator,
        })
      );
    } else {
      dispatch(
        getCompanyByUserAC(
          sessionStorage.getItem("jwtToken") ?? "",
          paginatorCompany
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorCompany, dispatch, nitCompany, loadingCompany]);

  // react hooks

  React.useEffect(() => {
    if (!isLog) {
      setFieldsAdvancedSearch(fieldsAdvanced);
    } else {
      setFieldsAdvancedSearch(fieldsLog);
    }
  }, [isLog]);

  React.useEffect(() => {
    return () => {
      dispatch(getFilterCompanyAC(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Se espera algún cambio en el paginador para lanzar de nuevo la peticion al servidor con la nueva pagina seleccionada
  React.useEffect(() => {
    const { paginator } = paginatorCompany;
    if (paginatorCompany !== undefined && paginator.recharge) {
      if (rol === "BIOMETRIA RNEC") {
        if (advanceSearchState) {
          dispatch(
            advanceSearchAC({
              fields: fieldsAdvancedSearch,
              paginator: paginatorCompany,
              company: nitCompany,
            })
          );
        } else {
          fillCompanyList();
        }
      } else {
        fillCompanyList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorCompany.paginator]);

  /** cambiar de pagina y lanzar el dispatch del paginador para que los useEffect realicen el cambio de pagina. */
  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
    dispatch(
      getPaginatorCompanyAC(
        newPage + 1,
        paginatorCompany.paginator.itemsPage,
        true
      )
    );
  };

  /** cambiar la cantidad de items por pagina y lanzar el dispatch del paginador para que el useEffect re-lance el filtro con los cambios. */
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getPaginatorCompanyAC(1, +event.target.value, true));
  };

  /** lanzar el filtro normal al servicio getFilterCompanyAC y setear el paginador en la primera pagina y mantener la cantidad de items por pagina*/
  const handleFilter = (query: string) => {
    setIsLog(false);
    setAdvanceSearchState(false);
    dispatch(getFilterCompanyAC(query.trim()));
    dispatch(
      getPaginatorCompanyAC(1, paginatorCompany.paginator.itemsPage, true)
    );
    setPage(0);
  };
  /** Ordenar los resultados mediante la seleccion del sort */
  const handleSort = (sort?: sortBy) => {
    if (sort) {
      dispatch(getSortCompanyAC(sort));
      dispatch(
        getCompanyByUserAC(sessionStorage.getItem("jwtToken") ?? "", {
          ...paginatorCompany,
          sort: sort,
        })
      );
    }
  };

  const handleSortOptionLog = (sortLog?: SortOptionLog) => {
    if (sortLog) {
      dispatch(getSortOptionAllLogAC(sortLog));

      setIsLog(true);
      setShowFormAdv(true);
    }
  };
  /** Lanzar filtro de busqueda para advance search. */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterCompanyAC(""));

    let { sortOptionLog } = paginatorCompany;

    if (isLog) {
      let paramsLog: GeneralLog = {
        date: new Date(fieldsPassedAdvancedSearch[0]?.value as string),
      };
      if (sortOptionLog === "comparison")
        dispatch(getAllLogComparisonCsvAC(paramsLog));

      if (sortOptionLog === "consult")
        dispatch(getAllLogConsultCsvAC(paramsLog));

      if (sortOptionLog === "unsatisfactoryAnswer")
        dispatch(getAllLogUnsatisfactoryAnswerCsvAC(paramsLog));

      setShowFormAdv(false);
    } else {
      let paramsAdvance = {
        fields: fieldsPassedAdvancedSearch,
        paginator: paginatorCompany,
        company: nitCompany,
      };
      dispatch(advanceSearchAC(paramsAdvance));
      setShowFormAdv(false);
      setAdvanceSearchState(true);
      setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    }
  };

  const handleOnClose = () => {
    setShowFormAdv(false);
  };

  const handleOnOpen = () => {
    setIsLog(false);
    setShowFormAdv(true);
  };

  return {
    companies,
    currentCompany,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    fieldsLog,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleOnClose,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    handleSortOptionLog,
    isLoad,
    isLog,
    loadingCompany,
    option,
    page,
    paginatorCompany,
    privilege,
    rol,
    rowsPerPage,
    setLoad,
    setOption,
    setPage,
    setTab,
    showFormAdv,
    sizeCompany,
    tab,
  };
};

export default useCompany;
