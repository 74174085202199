import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  FormHelperText,
  Radio,
  RadioGroup,
  DialogTitle,
} from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import { Slide, SlideProps } from "@material-ui/core";
import { IFingerprintJustification } from "../../store/models/FingerPrintModel";

const useStyles = makeStyles((_theme: Theme) => ({
  formControl: {
    margin: _theme.spacing(3),
  },
  button: {
    margin: _theme.spacing(1, 1, 0, 0),
  },
  text: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Muli",
    letterSpacing: "0px",
    color: "#575757",
    whiteSpace: "pre-line",
    marginTop: 30,
  },
  understand: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "30px",
    color: "#009BDD",
    cursor: "pointer",
  },
  containerCheckbox: {
    marginBottom: 20,
  },
  check: {
    "&.Mui-checked": {
      color: "#1D71B8",
    },
  },
}));

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  isClose: any;
  handleSubmit: any;
  fingerprintJustification: Array<IFingerprintJustification> | null;
  valueSaved: string | null;
}

const ManualFingerprintDialog = ({
  isOpen,
  isClose,
  handleSubmit,
  fingerprintJustification,
  valueSaved,
}: Props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const [value, setValue] = React.useState(valueSaved);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("Elija una opción");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  const handleSubmitLocal = (
    _event: React.FormEvent<HTMLFormElement>,
    _value: string | null
  ) => {
    _event.preventDefault();
    if (handleSubmit(Number(_value))) isClose(true);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setHelperText(" ");
    setError(false);
  };

  useEffect(() => {
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    setValue(valueSaved);
  }, [valueSaved]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Cambiar a Captura Manual
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.text}>
          {`Debes seleccionar dos (2) dedos de las manos para poder hacer la captura de huellas.`}
        </Typography>
        <form onSubmit={(_e) => handleSubmitLocal(_e, value)}>
          <FormControl
            component="fieldset"
            error={error}
            className={classes.formControl}
          >
            <FormLabel component="legend">
              ¿Porque elegirá las huellas de manera manual?
            </FormLabel>
            <RadioGroup
              style={{
                maxHeight: "50vh",
                display: "Grid",
                overflowY: "auto",
                margin: "1em 0",
              }}
              aria-label="justification"
              name="justification"
              value={value}
              onChange={handleRadioChange}
            >
              {!!fingerprintJustification &&
                !!fingerprintJustification.length &&
                fingerprintJustification.map((value, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={value.idManualCaptureJustification.toString()}
                      control={<Radio />}
                      label={value.nameManualCaptureJustification}
                      labelPlacement="end"
                    />
                  );
                })}
            </RadioGroup>
            <FormHelperText style={{ textAlign: "center" }}>
              {helperText}
            </FormHelperText>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Continuar
            </Button>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ManualFingerprintDialog;
