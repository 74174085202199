import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { API_AUTHENTICATION_ENDPOINT, BIOMETRICS_URL } from "../config/const";
import { ListBasicResponse } from "../store/models/ListBasicResponse";
import { UpdateState, UpdateStateSecurity } from "../store/models/UpdateState";
import {
  User,
  IUserExcel,
  PaginatorWithFilterAndSort,
  AdvanceSearch,
} from "../store/types";
import {
  ResponseTemplate,
  ResultResponse,
  StandardResponse,
  UserDto,
} from "../type";
/**
 * Endpoints
 */
const USER_ALL = "User/getAllUsers";
const USER_STATE = "User/State";
const USER_EXCEL = "User/GetAllUsersXlsx";
const ADVANCE_SEARCH = "User/AdvanceSearch";
const ADVANCE_SEARCH_CSV = "User/AdvanceSearchCsv";
const UPDATE_STATE_SECURITY = "security/api/user/changeStatusRNec";
/**
 * Instancia de axios para Biometria
 */
const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
/**
 * Instancia de axios para seguridad
 */
const securityInstance = axios.create({
  baseURL: API_AUTHENTICATION_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  },
});
/**
 * Retorna los usuarios 
 * @param { PaginatorWithFilterAndSort } body body - informacion de la paginación el filtro y el orednamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 * @returns { Promise<StandardResponse<ListBasicResponse<User>>> } promesa con la lista de los usuarios
 */
export const getAllUsers = async (
  body: PaginatorWithFilterAndSort,
  company?: string,
  businessUnit?: string
): Promise<StandardResponse<ListBasicResponse<User>>> => {
  try {
    const response: AxiosResponse<StandardResponse<ListBasicResponse<User>>> =
      await instance.post(USER_ALL, { ...body, company }, defaultHeaders());

    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Actualiza en seguridad el estado del usuario
 * @param { UpdateStateSecurity } body - información del usuario y su estado 
 * @returns { Promise<StandardResponse<ResultResponse>> } promeso con el resultado obtenido
 */
export const updateUserStateSecurity = async (
  body: UpdateStateSecurity
): Promise<StandardResponse<ResultResponse>> => {
  try {
    let conf: AxiosRequestConfig = {};
    conf.validateStatus = (status: number) => {
      return (status >= 200 && status < 300) || status === 404;
    };
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await securityInstance.post(UPDATE_STATE_SECURITY, body, conf);
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Actualiza en biometris el estado del usuario
 * @param { UpdateStateSecurity } body - información del usuario y su estado 
 * @returns { Promise<StandardResponse<ResultResponse>> } promeso con el resultado obtenido
 */
export const updateUserState = async (
  body: UpdateState
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(USER_STATE, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los usuarios dependiendo la sede o el equipo para armar el archivo excel
 * @param { DeviceBusinessUnit } body - datos
 * @returns { Promise<StandardResponse<ListBasicResponse<IUserExcel>>> } promesa con la lista de los usuarios que se van a descargar en un archivo excel
 */
export const getAllUsersExcelService = async (): Promise<
  StandardResponse<ListBasicResponse<IUserExcel>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IUserExcel>>
    > = await instance.get(USER_EXCEL, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los usuarios dependiendo la búsqueda avanzada
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ListBasicResponse<UserDto>>> } - promesa con la lista de los usuarios
 */
export const advanceSearch = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<UserDto>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<UserDto>>
    > = await instance.post(ADVANCE_SEARCH, body, defaultHeaders());

    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna el template para descargar el archivo CSV dependiendo la búsqueda
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ResponseTemplate>> } promesa con el template del archivo que se piensa descargar
 */
export const advanceSearchCsv = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
