import { useState } from "react";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import { LaunchIcon } from "../Icons/LaunchIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    borderRadius: 3,
    border: "1px solid #A8A8A8",
    height: 37,
    padding: "0 15%",
    color: "#6D6E71 !important",
    boxShadow: "none",
    textTransform: "capitalize",
    opacity: "1",

    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: "none",
      boxShadow: "none",
    },
  },
}));

const DownloadButtonXlsx = (props: ButtonProps) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  return (
    <Button
      className={classes.root}
      {...props}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      startIcon={
        <LaunchIcon
          width={"15px"}
          height={"15px"}
          active={active}
          className={{
            background: "#f1f1f1",
            "&:hover": {
              background: "#ffffff",
            },
          }}
        />
      }
    />
  );
};

export default DownloadButtonXlsx;
