import { useState, useEffect } from "react";
import {
  GetATDPAC,
  GetParametersATDPAC,
} from "../store/action-creators/CreateAtdpActionCreators";
import { PersonalizationSelector } from "../store/slices/PersonalizationSlice";
import { useDispatch, useSelector } from "react-redux";

/**
 * Este Hook encapsula las funciones, useEffect y busqueda de el formulario de informacion de empresa
 * @returns buttonColor, buttonTextColor, principalColor, secondaryColor, tab, isEdit, handleChangeTab, handleIsEdit
 */
const useCompanyBasicData = () => {
  const dispatch = useDispatch();

  const { buttonColor, buttonTextColor, principalColor, secondaryColor } =
    useSelector(PersonalizationSelector);

  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  //#region handle events
  const handleIsEdit = (isEdit: boolean) => {
    setIsEdit(isEdit);
  };

  const handleChangeTab = (event: any, newTab: any) => {
    newTab === 1 &&
      dispatch(
        GetATDPAC({ CompanyId: "c6937b97-8a44-454e-84c4-71a9a6d13450" })
      );
    setTab(newTab);
  };

  /** Se inicializa los parametros de la compañia al iniciar la vista de informacion de la empresa para reducir tiempo de carga al pasar a la parametrizacion de la compalia */
  useEffect(() => {
    dispatch(
      GetParametersATDPAC({ CompanyId: "c6937b97-8a44-454e-84c4-71a9a6d13450" })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    buttonColor,
    buttonTextColor,
    principalColor,
    secondaryColor,
    tab,
    isEdit,
    handleChangeTab,
    handleIsEdit,
  };
};

export default useCompanyBasicData;
