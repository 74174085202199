import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { TextFieldProps } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { Controller } from "react-hook-form";

type InputTinyRoundedProperties = {
  label: string;
  props?: TextFieldProps;
  name: string;
  defaultValue?: any;
  control: any;
  rules?: any;
  max?: string;
  min?: string;
  onChanged?: Function | undefined;
};

const InputTinyRoundedComponent = ({
  label,
  props,
  name,
  defaultValue,
  control,
  rules,
  max,
  min,
  onChanged,
}: InputTinyRoundedProperties) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          className={classes.inputCustom}
          label={label}
          variant="filled"

          InputProps={{
            // disableUnderline: true,
            classes: { disabled: classes.textFieldDisabled },
          }}
          inputProps={
            {
            maxLength: max,
            minLength: min,
          }}
          {...props}
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputCustom: {
    "& input": {
      height: "2px",
      background: "white",
      boxShadow: "0px 3px 3px #0000001A",
      border: "1px solid #D5D5D5",
      borderRadius: "10px",
    },

    "& div": {
      background: "none !important",
    },

    "& label": {
      fontSize: "15px",
      font: "normal normal normal 16px/17px Muli",
    },
    
    "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
      borderBottom: 'none',
    },
  },
  textFieldDisabled: {
    opacity: "1 !important",
    
  },
}));

export const InputTinyRounded = (props: InputTinyRoundedProperties) => {
  return <InputTinyRoundedComponent {...props} />;
};
