import axios, { AxiosResponse } from "axios";
import { BIOMETRICS_URL } from "../config/const";
import {
  ComputerEquipment,
  ComputerEquipmentResponse,
} from "../store/models/ComputerEquipmentModel";
import {
  BasicResponse,
  ListBasicResponse,
} from "../store/models/ListBasicResponse";

import { UpdateState } from "../store/models/UpdateState";
import { ResultResponse, StandardResponse, ResponseTemplate } from "../type";
import {
  AdvanceSearch,
  ComputerEquipmentBusinessUnit,
  IAudit,
  ICoputerEquipmentExcel,
  PaginatorWithFilterAndSort,
} from "../store/types";
import {
  ComputerEquipmentUnitMassiveResponse,
  ILoadComputerEquipmentUnit,
} from "../store/models/ComputerEquipmentUnit";
import { defaultHeaders } from "../config/config";
// Endpoints
const GET_COMPUTER_EQUIPMENTS = "ComputerEquipment/ComputerEquipment";
const GET_COMPUTER_EQUIPMENT = "ComputerEquipment/BasicComputerEquipment";
const CREATE_COMPUTER_EQUIPMENT = "ComputerEquipment";
const COMPUTER_EQUIPMENT_STATE = "ComputerEquipment/State";
const COMPUTER_EQUIPMENT_AUDIT = "ComputerEquipment/Audit";
const COMPUTER_EQUIPMENT_EXCEL = "ComputerEquipment/GetComputerEquipmentsExcel";

const COMPUTER_EQUIPMENT_UNIT_VALIDATE_LOAD =
  "ComputerEquipment/ComputerEquipmentMassive";
const COMPUTER_EQUIPMENT_LOAD = "ComputerEquipment/save";
const COMPUTER_EQUIPMENT_TEMPLATE =
  "/ComputerEquipment/ComputerEquipmentMassiveTemplate";
const COMPUTER_EQUIPMENTS_BUSSINES_UNIT =
  "ComputerEquipment/ComputerEquipmentBusinessUnit";
const COMPUTER_EQUIPMENT_EXCEL_BUSSINES_UNIT =
  "ComputerEquipment/GetComputerEquipmentsExcelByBusinessUnit";
const GET_COMPUTER_EQUIPMENT_BUSINESS_UNIT =
  "ComputerEquipment/BasicComputerEquipmentBusinessUnit";
const COMPUTER_EQUIPMENT_ADVANCE_SEARCH =
  "ComputerEquipment/AdvanceSearchComputerEquipment";
const COMPUTER_EQUIPMENT_ADVANCE_SEARCH_CSV =
  "ComputerEquipment/ComputerEquipmentFileCsv";
// Instancia de axios para Biometría
const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
  },
});
/**
 * Retorna los equipos dependiendo el filtro de búsqueda
 * @param { PaginatorWithFilterAndSort } body - informacion de la paginación el filtro y el orednamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 * @param { ( number | undefined ) } equipment - id del equipo
 * @returns { Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> } promesa con la lista de los equipos
 */
export const getAllComputerEquipments = async (
  body: PaginatorWithFilterAndSort,
  company?: string,
  businessUnit?: number
): Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>
    > = await instance.post(
      GET_COMPUTER_EQUIPMENTS,
      {
        ...body,
        company,
        businessUnit,
      },
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los equipos dependiendo la sede
 * @param { ComputerEquipmentBusinessUnit } body - datos de búsqueda por sede
 * @returns { Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> } promesa con la lista de los equipos que se van a descargar en un archivo excel
 */
export const getComputerEquipmentsByBusinessUnit = async (
  body: ComputerEquipmentBusinessUnit
): Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>
    > = await instance.post(
      COMPUTER_EQUIPMENTS_BUSSINES_UNIT,
      body,
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna lista basica de todos los equipos
 * @returns { Promise<StandardResponse<ListBasicResponse<BasicResponse>>> }
 */
export const getAllEquipments = async (): Promise<
  StandardResponse<ListBasicResponse<BasicResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.get(GET_COMPUTER_EQUIPMENT, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna lista basica de los equipos
 * @param { ComputerEquipmentBusinessUnit } body - datos de búsqueda por sede
 * @returns { Promise<StandardResponse<ListBasicResponse<BasicResponse>>> }
 */
export const getBasicComputerEquipmentBusinessUnit = async (
  body: ComputerEquipmentBusinessUnit
): Promise<StandardResponse<ListBasicResponse<BasicResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<BasicResponse>>
    > = await instance.post(
      GET_COMPUTER_EQUIPMENT_BUSINESS_UNIT,
      body,
      defaultHeaders()
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Actualiza un equipo.
 * @param { ComputerEquipment } body - información del equipo.
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const createComputerEquipment = async (
  body: ComputerEquipment
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(CREATE_COMPUTER_EQUIPMENT, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};
/**
 * Actualiza un equipo
 * @param { ComputerEquipment } body - información del equipo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const updateComputerEquipment = async (
  body: ComputerEquipment
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.put(CREATE_COMPUTER_EQUIPMENT, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};
/**
 * Actualiza el estado del equipo
 * @param { UpdateState } body - datos del equipo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const updateComputerEquipmentState = async (
  body: UpdateState
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(COMPUTER_EQUIPMENT_STATE, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};
/**
 * Obtiene y retorna la auditoria del equipo
 * @param { Device } body - datos del equipo
 * @returns { Promise<StandardResponse<IAudit>> } promesa con la auditoria del equipo
 */
export const auditComputerEquipmentService = async (
  body: ComputerEquipment
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(
      COMPUTER_EQUIPMENT_AUDIT,
      body,
      defaultHeaders()
    );
    if (response.status === 200) {
      const result = response.data;
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los equipos para armar el archivo excel
 * @param { AdvanceSearch } body - datos de búsqueda 
 * @returns { Promise<StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>> } promesa con la lista de los equipos que se van a descargar en un archivo excel
 */
export const getComputerEquipmentsExcelService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>
    > = await instance.post(COMPUTER_EQUIPMENT_EXCEL, body, defaultHeaders());
    if (response.status === 200) {
      const result = response.data;
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los equipos dependiendo la sede para armar el archivo excel
 * @param { ComputerEquipmentBusinessUnit } body - datos de búsqueda 
 * @returns { Promise<StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>> } promesa con la lista de los equipos que se van a descargar en un archivo excel
 */
export const getComputerEquipmentsExcelByBusinessUnitService = async (
  body: ComputerEquipmentBusinessUnit
): Promise<StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>> => {
  try {
    var response: AxiosResponse<
      StandardResponse<ListBasicResponse<ICoputerEquipmentExcel>>
    > = await instance.post(
      COMPUTER_EQUIPMENT_EXCEL_BUSSINES_UNIT,
      body,
      defaultHeaders()
    );
    if (response.status === 200) {
      const result = response.data;
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna la validacion si se puede subir o se reemplaza o si esta mal
 * @param { Array<ILoadComputerEquipmentUnit> } body - informacion de los equipos del cargue masivo
 * @returns { Promise<StandardResponse<ComputerEquipmentUnitMassiveResponse>> } promesa con la respuesta de la validacion de la carga masiva
 */
export const validateLoadStatus = async (
  body: Array<ILoadComputerEquipmentUnit>
): Promise<StandardResponse<ComputerEquipmentUnitMassiveResponse>> => {
  try {
    var response: AxiosResponse<
      StandardResponse<ComputerEquipmentUnitMassiveResponse>
    > = await instance.post(
      COMPUTER_EQUIPMENT_UNIT_VALIDATE_LOAD,
      body,
      defaultHeaders()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;;
  }
};
/**
 * Crea los equipos del cargue masivo
 * @param { Array<ILoadComputerEquipmentUnit> } body - información de los equipos del cargue masivo 
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const saveMassiveLoad = async (
  body: Array<ILoadComputerEquipmentUnit>
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(COMPUTER_EQUIPMENT_LOAD, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna el template para descargar la plantilla de la carga masiva
 * @returns { StandardResponse<ResponseTemplate> } promesa con el template del archivo que se piensa descargar
 */
export const getMassiveComputerEquipmentTemplate = async (): Promise<
  StandardResponse<ResponseTemplate>
> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.get(COMPUTER_EQUIPMENT_TEMPLATE, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los equipos dependiendo la búsqueda
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> } - promesa con la lista de los equipos
 */
export const getAdvanceSearchComputerEquipmentService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComputerEquipmentResponse>>
    > = await instance.post(
      COMPUTER_EQUIPMENT_ADVANCE_SEARCH,
      body,
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna el template para descargar el archivo CSV dependiendo la búsqueda
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ResponseTemplate>> } promesa con el template del archivo que se piensa descargar
 */
export const getAdvanceSearchComputerEquipmentCsvService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(
        COMPUTER_EQUIPMENT_ADVANCE_SEARCH_CSV,
        body,
        defaultHeaders()
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};
