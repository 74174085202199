import { FullLoader, isEmpty } from "@dg-bucaramanga/react-components-dg-qa";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { BigSwitch } from "../../components/Inputs/BigSwitch/BigSwitch";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import { CustomTypography } from "../../components/typography/CustomTypography";
import { IParametrization } from "../../store/models/BusinessUnit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { CompanyIcon } from "../../components/Icons/CompanyIcon";
import { MESSAGES } from "../../utils/types/const";
import useBusinessUnitParametrization from "../../hooks/useBusinessUnitParametrization";

const ParametrizationSchema = yup.object().shape({
  clientId: yup.string().required(MESSAGES.max),
  generateATDP: yup.bool(),
  repository: yup.string(),
  templatePathATDP: yup.string(),
  urlATDP: yup.string(),
  userStamp: yup
    .string()
    .when("generateATDP", {
      is: (value: boolean) => !!value,
      then: yup.string().required(MESSAGES.required).nullable(),
      otherwise: yup.string().notRequired(),
    })
    .nullable(),
  passwordStamp: yup
    .string()
    .max(200, `${MESSAGES.max} 200`)
    .when("generateATDP", {
      is: (value: boolean) => !!value,
      then: yup.string().required(MESSAGES.required).nullable(),
      otherwise: yup.string().notRequired().nullable(),
    })
    .trim(),
  signATDP: yup.bool(),
  signTyC: yup.bool(),
  userSign: yup.string().when("signATDP", {
    is: (value: boolean) => !!value,
    then: yup.string().required(MESSAGES.required).nullable(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  passwordSign: yup
    .string()
    .max(150, `${MESSAGES.max} 150`)
    .when("signATDP", {
      is: (value: boolean) => !!value,
      then: yup.string().required(MESSAGES.max).nullable(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  automaticEmail: yup.bool(),
});

const initialValues: IParametrization = {
  automaticEmail: false,
  clientId: "",
  generateATDP: false,
  passwordSign: "",
  passwordStamp: "",
  repository: "",
  signATDP: false,
  signTyC: false,
  templatePathATDP: "",
  urlATDP: "",
  userSign: "",
  userStamp: "",
};

const BusinessUnitParametrization = ({ edit }: { edit: boolean }) => {
  const {
    message,
    params,
    dataBol,
    formSubmit,
    isInerit,
    loadParametrization,
    isNew,
    loadingBusiness,
    handleCloseModal,
    handleIsInherit,
    isEditParametrization,
    isSuccess,
    showModal
  } = useBusinessUnitParametrization({ edit });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IParametrization>({
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: params
      ? {
          ...params,
          automaticEmail: dataBol?.automaticEmail,
          signTyC: dataBol.signTyC,
          signATDP: dataBol.signATDP,
          generateATDP: dataBol.generateATDP,
        }
      : initialValues,
    resolver: yupResolver(ParametrizationSchema),
  });

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTypography
            type="subtitle"
            children="Configuración de la sede"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <BigSwitch
            checked={isInerit}
            setChecked={(checked: boolean) => {
              loadParametrization(checked);
              handleIsInherit(checked);
            }}
            disabled={!isEditParametrization}
          />
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          justifyContent="space-between"
        >
          <Grid container item lg={4} md={8} sm={12} xs={12}>
            <InputRounded
              name="clientId"
              label="Cliente Id"
              props={{
                name: "clientId",
                helperText: errors.clientId?.message,
                error: errors.clientId ? true : false,
                disabled: !isEditParametrization,
              }}
              control={control}
              max={"8"}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          <Grid
            container
            item
            lg={3}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            {/* subsection */}
            <Grid item lg={5} md={8} sm={4} xs={4}>
              <CustomTypography type="definition">
                {"Generar ATDP"}
              </CustomTypography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6}>
              {" "}
              <ControlledSwitch
                error={errors.generateATDP ? true : false}
                helperText={errors.generateATDP?.message}
                control={control}
                name="generateATDP"
                isDisabled={!isEditParametrization || isInerit}
              />
            </Grid>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label="URL ATDP"
              control={control}
              name="urlATDP"
              props={{
                name: "urlATDP",
                helperText: errors.urlATDP?.message,
                error: errors.urlATDP ? true : false,
                disabled:
                  !isEditParametrization || !watch("generateATDP") || isInerit,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
          {/*  user print section     */}
          <CustomTypography type="subtitle">
            {"Usuario de estampa"}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="flex-start"
          spacing={1}
        >
          {/*subsection estampa */}
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputRounded
              label="Usuario"
              name="userStamp"
              control={control}
              props={{
                name: "userStamp",
                helperText: errors.userStamp?.message,
                error: errors.userStamp ? true : false,
                disabled:
                  !isEditParametrization || !watch("generateATDP") || isInerit,
              }}
            />{" "}
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            {" "}
            <InputRounded
              label="Contraseña"
              name="passwordStamp"
              control={control}
              props={{
                name: "passwordStamp",
                helperText: errors.passwordStamp?.message,
                error: errors.passwordStamp ? true : false,
                disabled:
                  !isEditParametrization || !watch("generateATDP") || isInerit,
                type: "password",
              }}
              max={"200"}
            />{" "}
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="flex-start"
        >
          <Grid
            container
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            {" "}
            <Grid item lg={4} md={8} sm={5} xs={4}>
              <CustomTypography type="definition">
                {"Firmar ATDP"}
              </CustomTypography>
            </Grid>
            <Grid item lg={6} md={4} sm={6} xs={6}>
              {" "}
              <ControlledSwitch
                error={errors.signATDP ? true : false}
                helperText={errors.signATDP?.message}
                control={control}
                name="signATDP"
                isDisabled={!isEditParametrization || isInerit}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            style={{ display: "none" }}
          >
            <Grid item lg={6} md={10} sm={6} xs={8}>
              <CustomTypography type="definition">
                {"Firmar Términos y condiciones"}
              </CustomTypography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4} style={{ display: "none" }}>
              {" "}
              <ControlledSwitch
                error={errors.signTyC ? true : false}
                helperText={errors.signTyC?.message}
                control={control}
                name="signTyC"
                isDisabled={!isEditParametrization || isInerit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={1}
          alignItems="center"
        >
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <CustomTypography type="definition">
              {"Firma desatendida"}
            </CustomTypography>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label="Usuario"
              name="userSign"
              control={control}
              props={{
                name: "userSign",
                helperText: errors.userSign?.message,
                error: errors.userSign ? true : false,
                disabled:
                  !isEditParametrization ||
                  (!watch("signATDP") && !watch("signTyC")) ||
                  isInerit,
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <InputRounded
              label="Contraseña"
              name="passwordSign"
              control={control}
              props={{
                name: "passwordSign",
                helperText: errors.passwordSign?.message,
                error: errors.passwordSign ? true : false,
                disabled:
                  !isEditParametrization ||
                  (!watch("signATDP") && !watch("signTyC")) ||
                  isInerit,
                type: "password",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: "none" }}
        >
          <CustomTypography type="subtitle">
            {"Envío ATDP por correo electrónico al participante"}
          </CustomTypography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction="row"
          spacing={1}
          style={{ display: "none" }}
        >
          <div style={{ paddingRight: 20 }}>
            <Grid item lg={1} md={2} sm={6} xs={4}>
              {" "}
              <Typography>{"Automático"}</Typography>
            </Grid>
          </div>
          <Grid item lg={1} md={6} sm={6} xs={4}>
            <ControlledSwitch
              error={errors.automaticEmail ? true : false}
              helperText={errors.automaticEmail?.message}
              control={control}
              name="automaticEmail"
              isDisabled={!isEditParametrization || isInerit}
              checked={false}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item lg={2} md={3} sm={6} xs={12}>
            <PrimaryButton
              title={isNew ? "Guardar" : "Actualizar"}
              props={{
                disabled: !isEditParametrization,
                type: "submit",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <AlertIconModal
        open={showModal}
        handleClose={() => handleCloseModal()}
        icon={<CompanyIcon width={"92px"} height={"80px"} />}
        message={isSuccess}
      />
      <FullLoader open={loadingBusiness} viewLoader={true} />
    </form>
  );
};

export default BusinessUnitParametrization;
