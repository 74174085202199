import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Theme } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((_theme: Theme) => ({
  box: {
    width: "10px",
    height: "10px",
  },
}));

interface Props {
  isOpen: boolean;
}

const CheckParticipant = ({ isOpen }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      {isOpen ? (
        <CheckIcon style={{ fontSize: "18px", color: "#53AD0C" }} />
      ) : null}
    </Box>
  );
};

export default CheckParticipant;
