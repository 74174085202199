import { makeStyles, Theme } from "@material-ui/core";

export const PageBackground = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={`${classes.root} 'Page-background'`} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100%",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundImage: "",
    backgroundSize: "",
    zIndex: -999999,
  },
}));
