import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
} from "@material-ui/core";

import React from "react";
import { IBusinessUnit } from "../../store/models/BusinessUnit";
import { FieldsAdvanceSearch } from "../../store/types";
import {
  CitiesResponse,
  StatesAndCitiesResponse,
} from "../../store/models/ListBasicResponse";
import useAdvanceSearchModal from "../../hooks/useAdvanceSearchModal";
import { Clear } from "@material-ui/icons";

interface IAdvancedSearchMoldalProps {
  open?: boolean;
  currentBusiness?: IBusinessUnit;
  fieldsAdvancedSearch?: Array<FieldsAdvanceSearch>;
  parentComponent?: string;
  statesAndCities?: StatesAndCitiesResponse<CitiesResponse>[];
  handleOnClose?: Function;
  handleOnSearch?: any;
  fieldsAdvanced?: Array<FieldsAdvanceSearch>;
  isExport?: any;
}

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "#D80000 !important",
  },
}));

/**
 * AdvancedSearchModal component.
 * Renders a modal dialog for advanced search functionality.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the modal is open or not.
 * @param {Array} props.fieldsAdvancedSearch - An array of objects representing the fields for advanced search.
 * @param {React.Component} props.parentComponent - The parent component.
 * @param {Array} props.statesAndCities - An array of states and cities.
 * @param {Function} props.handleOnClose - The callback function to handle modal close event.
 * @param {Function} props.handleOnSearch - The callback function to handle search event.
 * @param {Object} props.fieldsAdvanced - The advanced search fields.
 * @param {boolean} props.isExport - Determines whether the search is for export or not.
 * @returns {React.Component} The AdvancedSearchModal component.
 */
export const AdvancedSearchModal: React.FC<IAdvancedSearchMoldalProps> = ({
  open,
  fieldsAdvancedSearch,
  parentComponent,
  statesAndCities,
  handleOnClose,
  handleOnSearch,
  fieldsAdvanced,
  isExport = false,
}) => {
  const helperTextStyles = useHelperTextStyles();
  const {
    onChangeGenericInput,
    onChangeText,
    onChangeBranchCity,
    onChangeStatus,
    onClose,
    onClearForm,
    executeSearch,
    getErrors,
    brands,
    modelSelector,
    listCities,
    fieldsAdvancedSearchLocal,
    classes,
    CategoryListSelector,
    TypeStationListSelector,
    documentTypes,
    equipmentTypes,
    TypesDeviceSelector,
  } = useAdvanceSearchModal({
    fieldsAdvancedSearch,
    handleOnClose,
    handleOnSearch,
    fieldsAdvanced,
    isExport,
    statesAndCities,
  });

  /**
   * Se renderiza el dialogo modal
   */
  return (
    <Dialog
      fullScreen={false}
      open={open || false}
      aria-labelledby="custom-dialog"
      hideBackdrop={true}
      classes={
        parentComponent == null
          ? {
              paper: classes.dialog,
            }
          : {
              paper: classes.dialogCompanySelector,
            }
      }
      maxWidth="sm"
      onClose={() => {
        onClose();
      }}
    >
      <Clear
        id="clear-icon"
        onClick={() => {
          onClose();
        }}
        color="primary"
        className={classes.iconContainer}
      />
      {/** Se renderiza el cuerpo del modal */}
      <DialogContent>
        {fieldsAdvancedSearchLocal /** Se recorre cada elemento del array de objetos para los inputs de la busqueda avanzada. */
          ? fieldsAdvancedSearchLocal.map((row: FieldsAdvanceSearch) =>
              row.type === "text" /** Input de tipo text */ ? (
                <Grid container item xs={12} key={row.name}>
                  <TextField
                    fullWidth={true}
                    id={row.name}
                    label={row.title}
                    name={row.name}
                    onChange={(event) => {
                      onChangeText(row.name, event.target.value);
                    }}
                    value={row.value}
                    InputLabelProps={{
                      error:
                        row.helperText === undefined || row.helperText === ""
                          ? false
                          : true,
                    }}
                    helperText={row.helperText}
                    FormHelperTextProps={{
                      classes: {
                        root: helperTextStyles.root,
                      },
                    }}
                  />
                </Grid>
              ) : row.type === "date" /** Mensajes de Input de tipo fecha */ ? (
                getErrors(row)
              ) : row.type === "status" /** Input de tipo radio select */ ? (
                <FormGroup key={row.name}>
                  <Grid container spacing={2}>
                    <div>&nbsp;</div>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <label>{row.title}</label>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name={row.name}
                          value={row.value}
                          onChange={(event, checked) => {
                            onChangeStatus(row.name, checked);
                            event.preventDefault();
                          }}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Activo"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Inactivo"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              ) : row.type ===
                "selectDpo" /** Input tipo select para la lista de departamentos. */ ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Departamento"}</InputLabel>
                    <Select
                      name={row.name}
                      onChange={onChangeBranchCity}
                      value={row.value}
                    >
                      {statesAndCities
                        ? statesAndCities.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.stateName}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type ===
                "selectMpo" /** Input de tipo select para la lista de ciudades */ ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Ciudad"}</InputLabel>
                    <Select
                      label={"Ciudad"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {listCities
                        ? listCities.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.cityName}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectCategory" ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Categoria"}</InputLabel>
                    <Select
                      label={"Categoria"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {CategoryListSelector
                        ? CategoryListSelector.map((item) => (
                            <MenuItem key={item.id} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectTypeEquipment" ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Tipo Equipo"}</InputLabel>
                    <Select
                      label={"Tipo Equipo"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {equipmentTypes
                        ? equipmentTypes.map((item) => (
                            <MenuItem key={item.id} value={item.code}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectTypeWorkstation" ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Tipo de estacion"}</InputLabel>
                    <Select
                      label={"Tipo de estacion"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {TypeStationListSelector
                        ? TypeStationListSelector.map((item) => (
                            <MenuItem key={item.id} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectDocumentType" ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Tipo Documento"}</InputLabel>
                    <Select
                      label={"Tipo Documento"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {documentTypes
                        ? documentTypes.map((item) => (
                            <MenuItem key={item.id} value={item.code}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectDeviceType" ? (
                <Grid key={row.name}>
                  <FormControl fullWidth>
                    <InputLabel key={row.name}>{"Tipo Dispositivo"}</InputLabel>
                    <Select
                      label={"Tipo Dispositivo"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {TypesDeviceSelector
                        ? TypesDeviceSelector.map((item) => (
                            <MenuItem key={item.id} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : row.type === "selectBranchName" ? (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel key={row.name}>{"Marca"}</InputLabel>
                      <Select
                        label={"Marca"}
                        name={row.name}
                        onChange={onChangeGenericInput}
                        value={row.value}
                      >
                        {brands
                          ? brands.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          : []}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : row.type === "SelectModelDevice" ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel key="SelectModelDevice">{"Modelo"}</InputLabel>
                    <Select
                      label={"Modelo"}
                      name={row.name}
                      onChange={onChangeGenericInput}
                      value={row.value}
                    >
                      {modelSelector
                        ? modelSelector.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ""
              )
            )
          : ""}
      </DialogContent>
      {/** Botones de acciones para el formulario*/}
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Grid container item xs={6} md={6}>
            <Button
              variant="contained"
              color="secondary"
              classes={{
                root: classes.root,
                disabled: classes.disabledButton,
              }}
              onClick={() => onClearForm()}
              className={classes.classesButton}
            >
              Limpiar
            </Button>
          </Grid>
          <Grid container item xs={6} md={6}>
            <Button
              variant="contained"
              color="primary"
              classes={{
                root: classes.root,
                disabled: classes.disabledButton,
              }}
              onClick={() => executeSearch()}
              className={classes.classesButton}
            >
              {isExport ? "Exportar" : "Buscar"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
