import { Box, Grid, Switch, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { DataToken } from "../../store/types";
import { decodeToken } from "../../utils/general/utils";
import { useSelector } from "react-redux";
import { CompanySelector } from "../../store/slices/CompanySlice";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { CommonSelector } from "../../store/slices/CommonSlice";
import { defaultDashboardByRole } from "./defaultDashboardByRole";
import { MultiDashboard } from "./components/MultiDashboard";
import useDashboard from "../../hooks/useDashboard";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: "40px",
    alingnItems: "center",
  },
  textEmpresaDonut: {
    fontWeight: "bold",
    fontSize: "11px",
    color: "#575757",
    textAlign: "center",
  },
}));
interface DashboardViewProps {
  token: string;
  nitCompany?: String;
}

/**
 * @description This view detect user role, then print respective dashboards graphic
 * @param {string} token Login Token
 * @returns {JSX.Element} JSX element that represents the main layout where the dashboard elements are displayed
 */

const DashboardView = ({ token }: DashboardViewProps) => {
  const classes = useStyles();
  // REACT state
  const [dataToken, setDataToken] = useState<DataToken>();
  const [dashboardType, setDashboardType] = useState<number | undefined>(0);
  // REDUX selectors
  const { currentCompany } = useSelector(CompanySelector);
  const { loading } = useSelector(CommonSelector);
  const { switchCompany, handleChangeSwitchCompany } = useDashboard();
  // REACT HOOKS
  useEffect(() => {
    setDataToken(decodeToken(token));
  }, [token]);



  useEffect(()=> {
    if (dataToken) {
        setTimeout(() => {
          if (switchCompany){
            setDashboardType(defaultDashboardByRole.find((x) => x.role === dataToken.BIOMETRIA_ROLE)?.alter);
          } else {
            setDashboardType(defaultDashboardByRole.find((x) => x.role === dataToken.BIOMETRIA_ROLE)?.default);
          }
        }, 3000);
    }
  }, [dataToken]);


  const handleSwitchChangeDashboardByRole = () => {
    if (dataToken) {
      handleChangeSwitchCompany(!switchCompany);
      if (!switchCompany ){
        setDashboardType(defaultDashboardByRole.find((x) => x.role === dataToken.BIOMETRIA_ROLE)?.alter);
      } else {
        setDashboardType(defaultDashboardByRole.find((x) => x.role === dataToken.BIOMETRIA_ROLE)?.default);
      }
    }
  }

  const ActionComponentSwitch= () : JSX.Element => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box><Typography variant="body2">General</Typography></Box>
        <Switch
            checked={switchCompany}
            onChange={ () => setTimeout(() => {handleSwitchChangeDashboardByRole()}, 500)}
            name="checkedB"
            color="primary"
          />
          <Box><Typography variant="body2">Empresa</Typography></Box>
      </Box>
    )
  }

  return (
    <>
      {/** we expect it to load the company information in the hook */}
      {currentCompany && (
        <div className={classes.root}>
          <ViewLayout
            isNew={false}
            isEdit={false}
            headerTitle="Dashboard"
            editOption={false}
            actionChildren={dataToken?.BIOMETRIA_ROLE === "BIOMETRIA SUPERADMIN" ? <ActionComponentSwitch /> : undefined}
          >
            <Grid container spacing={3}>
              <MultiDashboard currentCompany={currentCompany} dataToken={dataToken} dashboardType={dashboardType} SwitchCompany={switchCompany} Role={dataToken?.BIOMETRIA_ROLE}/>
            </Grid>
          </ViewLayout>
          <FullLoader open={loading} viewLoader={true} />
        </div>
      )}
    </>
  );
};


export default DashboardView;
