import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MODELS
import {
  BasicResponse,
  TypeEquipmentResponse,
} from "../store/models/ListBasicResponse";

//SLICES
import { CommonListSelector } from "../store/slices/CommonListsSlice";
import {
  ComputerEquipmentSelector,
  setCurrentComputerEquipment,
} from "../store/slices/ComputerEquipmentSlice";
import {
  setShowForm,
  DeviceSelector,
  setAdvanceSearch as setAdvanceSearchDevice,
} from "../store/slices/DeviceSlice";
import {
  getOperatingSystemsAC,
  getTypeComputerAC,
} from "../store/action-creators/CommonListActionCreators";
import { IAudit } from "../store/types";
/**
 * Retorna funciones y estados que se utilizan en el formulario de equipos
 * @param { boolean } watchFixedStation - valida si es una estación fija
 * @param { any } setValue - 
 * @returns { ReactElement } funciones y estados
 */
const useComputerEquipmentForm = (
  watchFixedStation: boolean,
  setValue: any
) => {
  // REDUX
  const dispatch = useDispatch();
  const { typeComputerEquipment, operatingSystems } =
    useSelector(CommonListSelector);
  const { computerEquipment, computerEquipamentAudit } = useSelector(
    ComputerEquipmentSelector
  );
  const { advanceSearch: advanceSearchDevices } = useSelector(DeviceSelector);
  // Estados local 
  const [typeDevices, setTypeDevices] = useState<TypeEquipmentResponse[]>([]);
  const [typeOperatingSystems, setOperatingSystems] = useState<BasicResponse[]>(
    []
  );
  const [isFocusNombre, setFocusNombre] = useState(true);
  const [isFocusOs, setFocusOs] = useState(false);
  const [isFocusIp, setFocusIp] = useState(false);
  const [isFocusMac, setFocusMac] = useState(false);
  const [isFocusImei, setFocusImei] = useState(false);
  const [dataAudit, setDataAudit] = useState<IAudit>();
  /**
   * Efecto para eliminar los datos del estado global de la busqueda avanzada de dispositivos
   */
  useEffect(() => {
    if (advanceSearchDevices.length > 0) dispatch(setAdvanceSearchDevice([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const movilStation: TypeEquipmentResponse[] = [];
    const fixedStation: TypeEquipmentResponse[] = [];

    // eslint-disable-next-line array-callback-return
    typeComputerEquipment.map((item) => {
      if (item.isMobile === true) {
        fixedStation.push(item);
      } else {
        movilStation.push(item);
      }
    });

    if (typeComputerEquipment.length > 0 && computerEquipment === undefined) {
      const movilStation: TypeEquipmentResponse[] = [];
      const fixedStation: TypeEquipmentResponse[] = [];

      // eslint-disable-next-line array-callback-return
      typeComputerEquipment.find((item) => {
        if (item.isMobile === true) {
          fixedStation.push(item);
        } else {
          movilStation.push(item);
        }
      });

      setValue("equipment", 0);
      if (watchFixedStation) {
        setValue("mac", "");
        setTypeDevices(fixedStation);
      } else {
        setValue("imei", "");
        setTypeDevices(movilStation);
      }
    }

    if (typeComputerEquipment.length > 0 && computerEquipment !== undefined) {
      if (typeDevices.length === 0) {
        if (watchFixedStation) {
          setValue("mac", "");
          setTypeDevices(fixedStation);
        } else {
          setValue("imei", "");
          setTypeDevices(movilStation);
        }
      } else {
        if (watchFixedStation) {
          setTypeDevices(fixedStation);
        } else {
          setTypeDevices(movilStation);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFixedStation, typeComputerEquipment]);

  useEffect(() => {
    const sistemsPc: TypeEquipmentResponse[] = [];
    const sistemsMobile: TypeEquipmentResponse[] = [];

    // eslint-disable-next-line array-callback-return
    operatingSystems.map((item) => {
      if (item.isMobile) {
        sistemsMobile.push(item);
      } else {
        sistemsPc.push(item);
      }
    });

    if (watchFixedStation) {
      setOperatingSystems(sistemsMobile);
    } else {
      setOperatingSystems(sistemsPc);
    }
  }, [watchFixedStation, operatingSystems]);

  useEffect(() => {
    setFocusNombre(true);
    setFocusIp(false);
    setFocusMac(false);
    setFocusImei(false);
  }, []);

  useEffect(() => {
    if (!operatingSystems.length) dispatch(getOperatingSystemsAC());
    if (!typeComputerEquipment.length) dispatch(getTypeComputerAC());

    setFocusNombre(true);
    setFocusIp(false);
    setFocusMac(false);
    setFocusImei(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatingSystems.length]);

  useEffect(() => {
    if (computerEquipamentAudit) {
      setDataAudit(computerEquipamentAudit);
    }
  }, [computerEquipamentAudit]);
  /**
   * Borra el equipo y cierra el formulario al ponerlo en false en Redux
   */
  const handleBack = () => {
    dispatch(setShowForm(false));
    dispatch(setCurrentComputerEquipment(undefined));
  };

  return {
    dataAudit,
    handleBack,
    isFocusImei,
    isFocusIp,
    isFocusMac,
    isFocusNombre,
    isFocusOs,
    setFocusImei,
    setFocusIp,
    setFocusMac,
    setFocusNombre,
    setFocusOs,
    typeDevices,
    typeOperatingSystems,
  };
};

export default useComputerEquipmentForm;
