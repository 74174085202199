import { findFinalOfWord } from "@dg-bucaramanga/react-components-dg-qa";
import {
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  Cancel,
  CheckCircle,
  Clear,
} from "@material-ui/icons";
import Search from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import { makeStyles } from "@material-ui/styles";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { NoTypeProcessIcon } from "../../components/Icons/NoTypeProcessIcon";
import { sortBy, SortOptionLog } from "../../store/types";
import { getNameFinger, selectCurrentCompany } from "../../utils/general/utils";
import IconEspecialButton from "../button/DownloadButtonXlsx";
import ButtonLoad from "../button/LoadButton";
import MenuOrder from "../Menu/MenuOrder";
import SwitchForm from "../Inputs/SwitchForm";
import InputSearch from "../Inputs/InputSearch";
import TrashIcon from "../Icons/TrashIcon";
import TablePaginationActions from "./TablePagination";
import { Column, ComponentColumn, Config, DataTableProps } from "./types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuAuditLog from "../Menu/MenuAuditLog";
import { filters } from "../../utils/general/masks";
interface orderColum {
  name: string;
  naturalOrder: boolean | undefined;
}
interface IMessageSearchError {
  status: boolean;
  message: string | null
}

const DataTable: React.FC<DataTableProps> = ({
  checkbox,
  columns,
  data,
  paramToDisable,
  havePagination,
  globalDisabled,
  createDisabled,
  deleteDiabled,
  modifyDiabled,
  readDisabled,
  validateToDisable,
  buttons,
  option,
  setLoad,
  setShowFormAdvance,
  setTab,
  setOption,
  handleCellClick,
  notDataFound,
  page,
  setPage,
  size,
  rowsPerPage,
  rol,
  download,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilter,
  handleSort,
  handleOnLoadButton,
  handleOnOpenAdvSearch,
  handleSortLog,
  loading,
  paginator,
}) => {
  //Filtro para paginación
  let filtered = [];

  // manejo de estado
  const [selectedColumn, setSelectedColumn] = useState<any | null>(null);
  const [isDropdownUp, setDropdownUp] = useState(false);
  const [search, setSearch] = useState<string | undefined>("");
  const [searchFilter, setSearchFilter] = useState<string>(
    paginator?.filter || ""
  );
  const [currentData, setCurrentData] = useState<any>([]);
  const [orderedColumns, setOrderedColumns] = useState<any[]>([]);
  const [sort, setSort] = useState<sortBy | undefined>();
  const [sortOptionLog, setSortOptionLog] = useState<
    SortOptionLog | undefined
  >();
  const [searchMessageError, setSearchMessageError] = useState<IMessageSearchError>({ status: false, message: ""});

  const classes = useStyles(searchMessageError);
  let component;
  let config: Config = {
    cellSize: { lg: 2, md: 2, sm: 2, xs: 2 },
    justify: "center",
    spacing: 2,
  };

  const onChangeText = (filter: string) => {
    const accentuationList = ['á','é','í','ó','ú'];
    if (filter !== undefined) {
      accentuationList.includes(filter[filter.length-1])
        ? setSearchMessageError({ status: true, message: 'No se permiten tildes en el campo de busqueda'})
        : setSearchMessageError({ status: false, message: ''});
      setSearchFilter(filters(filter));
    }
  };

  const [currentFilter, setCurrentFilter] = useState({
    searchBar: true,
    option: false,
    dropdown: false,
  });

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  useEffect(() => {
    if (handleSort !== undefined && sort !== undefined) handleSort(sort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    if (handleSortLog !== undefined && sortOptionLog !== undefined)
      handleSortLog(sortOptionLog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOptionLog]);

  // ---------------------
  // Function Section
  // ---------------------

  /** Función para validar el tamaño de la busqueda
   * */

  const handleSearchFilter = () => {
    if (handleFilter) {
      if (searchFilter.length >= 3 || !searchFilter.length) {
        handleFilter(searchFilter);
      } else {
        setSearchMessageError({ status: true, message: "Para realizar la búsqueda, debe contener al menos 3 caracteres."});
      }
    }
  };

  // metodos de renderización de la tabla
  const getDropDownState = (id: any) => {
    const column = orderedColumns.find(
      (item: any) => item.name === selectedColumn
    );
    const naturalOrder = !!column ? column.naturalOrder : false;
    if (selectedColumn === id && !naturalOrder) {
      return (
        <ArrowDropUp
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else if (selectedColumn === id && naturalOrder) {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    }
  };

  const FingerComponent = (column: Column) => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        className={classes.alignSwitch}
      >
        <Grid item>{column?.result?.[0]?.finger}</Grid>
        <Grid item style={{ marginLeft: 3 }}>
          <Tooltip title={`${getNameFinger(column?.result?.[0]?.finger)}`}>
            {column?.result?.[0]?.result ? (
              <CheckCircle fontSize="small" htmlColor="#5FBE2C" />
            ) : (
              <Cancel fontSize="small" htmlColor="#D50915" />
            )}
          </Tooltip>
        </Grid>
        <Divider
          style={{ marginTop: 1.5, marginLeft: 10, height: 30, width: 2 }}
          orientation="vertical"
          flexItem
        />
        <Grid item style={{ marginLeft: 10 }}>
          {column?.result?.[1]?.finger}
        </Grid>
        <Grid item style={{ marginLeft: 3 }}>
          <Tooltip title={`${getNameFinger(column?.result?.[1]?.finger)}`}>
            {column?.result?.[1]?.result ? (
              <CheckCircle fontSize="small" htmlColor="#5FBE2C" />
            ) : (
              <Cancel fontSize="small" htmlColor="#D50915" />
            )}
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const SwitchComponent = (
    value: any,
    row: any,
    column: Column,
    component: ComponentColumn
  ) => {
    const {
      handleChange,
      haveIcon,
      tooltipMessage,
      handleIconClick,
      withIcon,
      format,
    } = component;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        spacing={2}
        justifyContent={haveIcon ? "flex-end" : "center"}
        onMouseOver={() => {
          //if (Boolean(row[paramToDisable]) === false) {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = "visible";
            trashButton.style.cursor = "pointer";
          }
          //}
        }}
        onMouseLeave={() => {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = "hidden";
          }
        }}
      >
        <Tooltip
          title={`Activar/Inactivar ${tooltipMessage}`}
          aria-label={`Activar/Inactivar ${column.name}`}
          className={classes.alignSwitch}
        >
          <SwitchForm
            checked={format ? format(value) : value}
            name={tooltipMessage}
            disabled={
              globalDisabled
                ? globalDisabled
                : readDisabled
                ? !readDisabled
                : (row.idState || row.Idstate) === 2 && rol !== "BIOMETRIA RNEC"
                ? true
                : false
            }
            onChange={(evt: React.MouseEventHandler<HTMLInputElement>) => {
              handleChange(evt, row.id, column.id, row);
              setCurrentFilter({
                searchBar: false,
                option: false,
                dropdown: false,
              });
            }}
          />
        </Tooltip>
        {withIcon ? (
          <Tooltip
            title="Eliminar"
            aria-label="Eliminar"
            style={{ marginTop: 4, marginLeft: 2 }}
            // className={classes.alignSwitch}
          >
            <TrashIcon
              id={row.id}
              style={{
                visibility: "hidden",
                marginLeft: 4,
                justifyContent: "center",
              }}
              color="primary"
              width={"20px"}
              active={true}
              props={{
                onClick: (evt: any) => {
                  if (typeof handleIconClick === "function")
                    handleIconClick(evt, row.id);
                },
              }}
            />
          </Tooltip>
        ) : null}
      </Grid>
    );
  };

  const typographyComponent = (value: any, row: any, column: Column) => {
    const { component } = column;
    const format = column?.format;

    const wordLength = component?.wordLength;

    if (wordLength === undefined) column.key = undefined;
    const result = !!column.key
      ? findFinalOfWord(value, wordLength ? wordLength : 60, column.key)
      : value;
    value = !!value ? value : "";

    return (
      <>
        {value.length > (wordLength ? wordLength : 0) ? (
          <Tooltip title={value}>
            <Typography
              className={classes.labels}
              onClick={(evt) => {
                if (typeof handleCellClick === "function")
                  handleCellClick(evt, row, column);
              }}
              align={column.align}
              style={column.customClass ? column.customClass : undefined}
            >
              {format ? format(result) : result}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            className={classes.labels}
            onClick={(evt) => {
              if (typeof handleCellClick === "function")
                handleCellClick(evt, row, column);
            }}
            align={column.align}
            style={!!column.customClass ? column.customClass : undefined}
          >
            {format ? format(result) : result}
          </Typography>
        )}
      </>
    );
  };

  const getRowData = () => {
    if (currentFilter.searchBar) {
      if (handleFilter !== undefined) {
        filtered = currentData;
      } else {
        filtered = filteredData;
      }
    } else if (currentFilter.option) {
      filtered = orderByOption ? orderByOption : [];
    } else if (currentFilter.dropdown) {
      filtered = orderedColumn;
    } else {
      filtered = currentData;
    }
    if (filtered.length === 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell align={"center"}>
            <NoTypeProcessIcon />
            <br />
            {
              "No existe información correspondiente a los criterios de búsqueda"
            }
          </TableCell>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
        </TableRow>
      );
    } else {
      const filterCounter = havePagination ? filtered : filtered;
      return filterCounter.map((row: any, key: any) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={key}
            className={
              paramToDisable !== undefined &&
              Boolean(row[paramToDisable]) === validateToDisable
                ? "disabled_row"
                : globalDisabled
                ? "disabled_row"
                : "MuiTypography-root"
            }
          >
            {columns.map((column: Column) => {
              column.result = row[column.id];
              const value = row[column.id];
              return (
                <TableCell
                  classes={{
                    root:
                      row.idState === 2 && rol !== "BIOMETRIA RNEC"
                        ? classes.disabled
                        : classes.root,
                  }}
                  key={column.id}
                  align={column.align}
                  size={"medium"}
                >
                  {stateSection(column, row, value, key)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
  };

  const stateSection = (
    column: Column,
    row: any,
    value: any,
    index: number
  ) => {
    if (!!column.children) {
      return column.children(value, row, index);
    }

    if (column.id === "viewIcon") {
      return (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              selectCurrentCompany(
                row["businessName"] + "," + row["documentNumber"]
              );
            }}
          >
            <VisibilityIcon color="secondary" />
          </div>
        </>
      );
    }
    if (!!column.component) {
      if (column.isVisible) {
        if (column.isVisible(row)) {
          return SwitchComponent(value, row, column, column.component);
        } else {
          return null;
        }
      } else {
        if (column.id === "result") {
          return FingerComponent(column);
        } else return SwitchComponent(value, row, column, column.component);
      }
    }

    return typographyComponent(value, row, column);
  };

  const RecordNotFound = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={
            columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
          }
        />
        <TableCell>
          {notDataFound ? (
            notDataFound
          ) : (
            <TableCell align={"center"}>
              <NoTypeProcessIcon />
              <br />
              {
                "No existe información correspondiente a los criterios de búsqueda"
              }
            </TableCell>
          )}
        </TableCell>
        <TableCell
          colSpan={
            columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
          }
        />
      </TableRow>
    );
  };

  //metodo para renderizar checkbox

  const renderButton = (checkbox: any[]) => {
    const buttons: JSX.Element[] = [];
    checkbox?.map((item) => buttons.push(item.children()));
    return (
      <Grid container direction="row" alignItems="flex-start">
        {buttons.map((item, index) => {
          return <Grid key={index}>{item}</Grid>;
        })}
      </Grid>
    );
  };

  // metodos de control de paginación

  const orderedColumn = useMemo(() => {
    const haveBeenOrdered = orderedColumns?.find(
      (item: any) => item.name === selectedColumn
    );
    var dataCopy = data.slice();
    if (haveBeenOrdered !== undefined) {
      let orderedData = [];
      if (haveBeenOrdered.naturalOrder) {
        orderedData = dataCopy.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
            ? 1
            : -1
        );
      } else {
        orderedData = dataCopy.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] < b[selectedColumn]
            ? 1
            : -1
        );
      }
      return orderedData;
    } else {
      if (orderedColumns.length) {
        return dataCopy.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
            ? 1
            : -1
        );
      } else {
        return dataCopy;
      }
    }
  }, [data, orderedColumns, selectedColumn]);

  const orderByDirection = (a: any, b: any, sortBy: any) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop] < b[sortBy[i].prop]
          ? -1
          : a[sortBy[i].prop] > b[sortBy[i].prop]
          ? 1
          : 0);
      i++;
    }
    return result;
  };

  const orderByOption = useMemo(() => {
    const options = buttons.menuOrder?.options ?? [];
    const arrayForSort = currentData ? [...currentData] : [];
    if (options.length) {
      if (option !== null && option !== undefined) {
        for (const iter of options) {
          if (iter.label === options[option].label) {
            if (iter.columns.length === 1) {
              const { prop, direction } = iter.columns[0];
              if (direction === -1) {
                return arrayForSort.sort((a: any, b: any) =>
                  a[prop] === b[prop] ? 0 : a[prop] > b[prop] ? 1 : -1
                );
              } else {
                return arrayForSort.sort((a: any, b: any) =>
                  a[prop] === b[prop] ? 0 : a[prop] < b[prop] ? 1 : -1
                );
              }
            } else if (iter.columns.length > 1) {
              return arrayForSort.sort((a: any, b: any) =>
                orderByDirection(a, b, iter.columns)
              );
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, option]);

  const filteredData = useMemo(() => {
    if (handleFilter === undefined) {
      return currentData.filter((item: any) => {
        for (const key in item) {
          if (
            item[key] !== undefined &&
            item[key] !== null &&
            typeof item[key] === "string"
          ) {
            const filter =
              typeof item[key] === "string"
                ? item[key].toLowerCase()
                : item[key];
            if (filter.includes(search?.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, search]);

  if (!!buttons.children) {
    if (!!buttons.children().component) {
      component = buttons.children().component;
    } else {
      component = buttons.children;
    }
    if (!!buttons.children().config) {
      config = buttons.children().config;
    }
  }

  const showAdvanceSearch = () => {
    handleOnOpenAdvSearch && handleOnOpenAdvSearch();
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={2}
        justifyContent={buttons.justify ? buttons.justify : "flex-start"}
      >
        {buttons.selectLog?.cellSize &&
          buttons.selectLog?.title == undefined && (
            <Grid
              item
              container
              lg={buttons.selectLog?.cellSize.lg}
              md={buttons.selectLog?.cellSize.md}
              sm={buttons.selectLog?.cellSize.sm}
            >
              <MenuAuditLog
                title={buttons.selectLog?.title}
                icon={buttons.selectLog?.icon}
                size={"small"}
                disabled={false}
                options={
                  !!buttons.selectLog
                    ? buttons.selectLog.options.map((item) => item.label)
                    : []
                }
                selectItem={(opt: any) => {
                  if (handleSortLog !== undefined) {
                    switch (opt) {
                      case 0:
                        setSortOptionLog("comparison");
                        break;
                      case 1:
                        setSortOptionLog("consult");
                        break;
                      case 2:
                        setSortOptionLog("unsatisfactoryAnswer");
                        break;
                      default:
                        setSortOptionLog("comparison");
                        break;
                    }
                  } else {
                    setPage(0);
                    setSearch("");
                    setCurrentFilter({
                      searchBar: false,
                      option: true,
                      dropdown: false,
                    });
                  }
                }}
              />
            </Grid>
          )}
        {buttons.searchBar ? (
          <Grid
            container
            item
            lg={buttons.searchBar.cellSize.lg}
            md={buttons.searchBar.cellSize.md}
            sm={buttons.searchBar.cellSize.sm}
          >
            <InputSearch
              name="CustomersFilter"
              value={searchFilter}
              disabled={globalDisabled}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (handleFilter !== undefined) {
                  onChangeText(e.target.value);
                } else {
                  setSearch(e.target.value);
                  setPage(0);
                  setCurrentFilter({
                    searchBar: true,
                    option: false,
                    dropdown: false,
                  });
                }
              }}
              onKeyPress={(e: { charCode: number }) => {
                if (e.charCode === 13) {
                  handleSearchFilter();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TuneIcon
                      id="advanced-search-icon"
                      onClick={showAdvanceSearch}
                      className={classes.advancedSearchIcon}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchFilter.length >= 3 && (
                      <Clear
                        id="clear-icon"
                        onClick={() => {
                          if (!!handleFilter) {
                            setSearchFilter("");
                            handleFilter("");
                          }
                        }}
                        color="secondary"
                        className={classes.colorClearAdornment}
                      />
                    )}
                    <Search
                      id="search-icon"
                      onClick={() => {
                        handleSearchFilter();
                      }}
                      className={classes.colorSearchAdornment}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              margin="dense"
              fullWidth
              error={searchMessageError.status}
              helperText={searchMessageError.message}
            />
          </Grid>
        ) : null}
        {buttons.menuOrder ? (
          <Grid
            item
            container
            lg={buttons.menuOrder.cellSize.lg}
            md={buttons.menuOrder.cellSize.md}
            sm={buttons.menuOrder.cellSize.sm}
          >
            <MenuOrder
              size="small"
              disabled={globalDisabled ? globalDisabled : false}
              options={
                !!buttons.menuOrder
                  ? buttons.menuOrder.options.map((item) => item.label)
                  : []
              }
              selectItem={(opt: any) => {
                if (handleSort !== undefined) {
                  switch (opt) {
                    case 0:
                      setSort("active");
                      break;
                    case 1:
                      setSort("inactive");
                      break;
                    case 3:
                      setSort("old");
                      break;
                    default:
                      setSort("recent");
                      break;
                  }
                } else {
                  !!setOption && setOption(opt);
                  setPage(0);
                  setSearch("");
                  setCurrentFilter({
                    searchBar: false,
                    option: true,
                    dropdown: false,
                  });
                }
              }}
            />
          </Grid>
        ) : null}

        {buttons.selectLog?.title == "Log consolidado" && (
          <Grid
            item
            container
            lg={buttons.selectLog?.cellSize.lg}
            md={buttons.selectLog?.cellSize.md}
            sm={buttons.selectLog?.cellSize.sm}
          >
            <MenuAuditLog
              title={buttons.selectLog?.title}
              icon={buttons.selectLog?.icon}
              size={"small"}
              disabled={false}
              options={
                !!buttons.selectLog
                  ? buttons.selectLog.options.map((item) => item.label)
                  : []
              }
              selectItem={(opt: any) => {
                if (handleSortLog !== undefined) {
                  switch (opt) {
                    case 0:
                      setSortOptionLog("comparison");
                      break;
                    case 1:
                      setSortOptionLog("consult");
                      break;
                    case 2:
                      setSortOptionLog("unsatisfactoryAnswer");
                      break;
                    default:
                      setSortOptionLog("comparison");
                      break;
                  }
                } else {
                  setPage(0);
                  setSearch("");
                  setCurrentFilter({
                    searchBar: false,
                    option: true,
                    dropdown: false,
                  });
                }
              }}
            />
          </Grid>
        )}

        {buttons.exportButton ? (
          <Grid
            item
            container
            lg={buttons.exportButton.cellSize.lg}
            md={buttons.exportButton.cellSize.md}
            sm={buttons.exportButton.cellSize.sm}
          >
            <IconEspecialButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              size={"small"}
              onClick={() => download()}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: 1,
                }}
              >
                Exportar
              </p>
            </IconEspecialButton>
          </Grid>
        ) : null}

        {buttons.chargeButton?.cellSize ? (
          rol !== "BIOMETRIA RNEC" ? (
            <Grid
              item
              container
              lg={buttons.chargeButton.cellSize.lg}
              md={buttons.chargeButton.cellSize.md}
              sm={buttons.chargeButton.cellSize.sm}
            >
              <ButtonLoad
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                // size={"small"}
                onClick={() => !!handleOnLoadButton && handleOnLoadButton()}
                disabled={createDisabled}
              >
                <p
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    fontSize: "0.8125rem",
                  }}
                >
                  Carga masiva
                </p>
              </ButtonLoad>
            </Grid>
          ) : (
            <></>
          )
        ) : null}

        {!!component ? (
          rol !== "BIOMETRIA RNEC" ? (
            <Grid
              container
              item
              lg={config.cellSize.lg}
              md={config.cellSize.lg}
              sm={config.cellSize.lg}
              direction="row"
              alignItems="center"
              justifyContent={config.justify ? config.justify : "flex-end"}
            >
              {component()}
            </Grid>
          ) : (
            <></>
          )
        ) : null}
      </Grid>
      {checkbox !== undefined ? renderButton(checkbox) : null}
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="custom table"
          size="small"
          id="customTableScroll"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCell}
                  onClick={(evt) => {
                    if (column.haveDropDown) {
                      setSelectedColumn(column.id);
                      const haveBeenOrdered: orderColum | undefined =
                        orderedColumns?.find((item: orderColum) => {
                          if (item.name === column.id) {
                            return item;
                          }
                          return undefined;
                        });
                      if (haveBeenOrdered !== undefined) {
                        setOrderedColumns([
                          ...orderedColumns.map((item: orderColum) =>
                            item === haveBeenOrdered
                              ? {
                                  name: haveBeenOrdered.name,
                                  naturalOrder: !haveBeenOrdered.naturalOrder,
                                }
                              : item
                          ),
                        ]);
                      } else {
                        setOrderedColumns([
                          ...orderedColumns,
                          { name: column.id, naturalOrder: true },
                        ]);
                      }
                      setCurrentFilter({
                        searchBar: false,
                        option: false,
                        dropdown: true,
                      });
                    }
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography className={classes.columnStyle}>
                      {column.label}
                    </Typography>
                    <Typography className={classes.columnStyle}>
                      {column.haveDropDown ? getDropDownState(column.id) : null}
                    </Typography>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? null
              : currentData.length > 0
              ? getRowData()
              : RecordNotFound()}
          </TableBody>
        </Table>
      </TableContainer>
      {havePagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          align="justify"
          style={{ display: "flex", justifyContent: "space-evenly" }}
          count={size}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            () => (
              <div></div>
            ) /* ({ from, to, count }) => { return `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`;} */
          }
          labelRowsPerPage="Registros por página"
          nextIconButtonText="Siguiente"
          backIconButtonText="Anterior"
          ActionsComponent={(props) => <TablePaginationActions {...props} />}
        />
      ) : null}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px 0 0 4px`,
      },
    },
    padding: "8px",
    "& .MuiTableCell-root": {
      padding: "6px",
    },
  },
  disabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px 0 0 4px`,
      },
    },
    padding: "8px",
    "& .MuiTableCell-root": {
      padding: "6px",
    },
    "& .MuiTypography-root": {
      color: "rgb(128 128 128 / 56%)",
    },
  },
  container: (searchMessageError) => ({
    width: "100%",
    marginTop: searchMessageError ? "10px" : "0px",
  }),
  card: {
    borderRadius: "10px",
    background: theme.palette.primary.main,
  },
  paper: {
    width: "100% !important",
    padding: "10px 10px",
    background: theme.palette.common.white,
  },
  table: {
    background: theme.palette.common.white,
    padding: "10px 10px 10px 10px",
    WebkitBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    MozBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    boxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
  },
  columnStyle: {
    fontSize: "14px",
    font: "normal normal 600 14px/16px Muli",
  },
  tableCell: {
    height: "26px",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.light, // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  alignSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    color: "blue",
  },
  hover: {
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  labels: {
    font: "normal normal normal 14px/19px Muli",
    letterSpacing: "0px",
    color: "#575756",
    cursor: "pointer",
  },
  menuOrder: {
    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: "none",
      boxShadow: "none",
    },
  },
  row: {
    background: "#F6F6F6 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #00000033",
    opacity: 1,
    height: "45px",
  },
  colorSearchAdornment: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  cursorPointerStyle: {
    cursor: "pointer",
  },
  colorClearAdornment: {
    color: theme.palette.text.secondary,
    opacity: 0.5,
    cursor: "pointer",
  },
  advancedSearchIcon: {
    color: theme.palette.text.secondary,
    opacity: 1,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
}));

export default DataTable;
