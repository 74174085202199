import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { valueOrDefault } from "../../utils/general/utils";

export interface PersonalizationState {
  backgroundColor: string;
  backgroundImage: { blob: string; name: string; path: null; extension: null };
  logo: { blob: string; name: string; path: null; extension: null };
  principalColor: string;
  secondaryColor: string;
  buttonColor: string;
  buttonTextColor: string;
}

const initialState: PersonalizationState = {
  principalColor: "#1D71B8",
  secondaryColor: "#FA5A00",
  backgroundColor: "FFFFFF",
  buttonColor: "#1D71B8",
  buttonTextColor: "#FFFFFF",
  logo: { blob: "", name: "", path: null, extension: null },
  backgroundImage: { blob: "", name: "", path: null, extension: null },
};

const personalizationSlice = createSlice({
  name: "Personalization",
  initialState,
  reducers: {
    setPrimaryColor: (state, principalColor: PayloadAction<string>) => {
      state.principalColor = valueOrDefault(
        principalColor.payload,
        initialState.principalColor
      );
    },
    setSecondaryColor: (state, secondaryColor: PayloadAction<string>) => {
      state.secondaryColor = valueOrDefault(
        secondaryColor.payload,
        initialState.secondaryColor
      );
    },
    setBackgroundColor: (state, backgroundColor: PayloadAction<string>) => {
      state.backgroundColor = valueOrDefault(
        backgroundColor.payload,
        initialState.backgroundColor
      );
    },
    setButtonColor: (state, buttonColor: PayloadAction<string>) => {
      state.buttonColor = valueOrDefault(
        buttonColor.payload,
        initialState.buttonColor
      );
    },
    setButtonTextColor: (state, buttonTextColor: PayloadAction<string>) => {
      state.buttonTextColor = valueOrDefault(
        buttonTextColor.payload,
        initialState.buttonTextColor
      );
    },
    setLogo: (
      state,
      logo: PayloadAction<{
        blob: string;
        name: string;
        path: any;
        extension: any;
      }>
    ) => {
      state.logo = valueOrDefault(logo.payload, initialState.logo);
    },
    setBackground: (
      state,
      background: PayloadAction<{
        blob: string;
        name: string;
        path: any;
        extension: any;
      }>
    ) => {
      state.backgroundImage = valueOrDefault(
        background.payload,
        initialState.backgroundImage
      );
    },
  },
});

export const {
  setPrimaryColor,
  setSecondaryColor,
  setBackgroundColor,
  setButtonColor,
  setButtonTextColor,
  setLogo,
  setBackground,
} = personalizationSlice.actions;

export const PersonalizationSelector = (state: RootState) =>
  state.personalization;

export default personalizationSlice.reducer;
