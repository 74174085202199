import {
  getAverageCompany,
  getCustomerConsumption,
  getCustomerPlanExpired,
  getDailycomparisonAverage,
  getNextBalanceToExpire,
  getNextPlanExpirations,
  getNumberRecordByTypeProcessCompany,
  getRecordbyCompany,
  getRecordCompanyByMonth,
  getRecordsByCompanyAndBusinessunit,
  getRecordsTypeProcessCompanyAndBusinessunit,
  getUserRecord,
  getAverageByBusinessunit,
  getRecordsTypeProcessByUser,
  getAverageCompanyMonthly,
  getDoughnutTransaction,
  getVerticalBarTransaction,
  getCustomerLicense,
  getValidatedCounters,
} from "../../services/DashboardService";
import { licenseCode } from "../../utils/general/utils";
import { formatDateIso } from "../../utils/general/formatDate";
import {
  CustomerConsumption,
  DashboardTransactionsRequest,
  IVerticalBarTransaction,
} from "../models/DashboardModel";
import { setLoading } from "../slices/CommonSlice";
import {
  setAverageCompanies,
  setAverageCompaniesMonthly,
  setBusinessUnitAverage,
  setBusinessUnitRecords,
  setCustomerConsumption,
  setCustomerConsumptionMessage,
  setCustomerPlanExpired,
  setCustomerPlanExpiredMessage,
  setDashboardCapturador,
  setDoughnutTransaction,
  setNextBalanceToExpire,
  setNextBalanceToExpireMessage,
  setNextPlanExpirations,
  setNextPlanExpirationsMessage,
  setRecordCompanies,
  setRecordCompaniesMonthly,
  setRecordTypeProcessByUser,
  setTypeProcessCompanies,
  setTypeProcessCompanyBusinessUnits,
  setUserRecords,
  setValidatedCounters,
  setVerticalBarTransaction,
} from "../slices/DashboardSlice";
import { AppThunk } from "../store";
import { setCustomerLicense } from "../slices/CompanySlice";

export const getAverageCompanyAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getAverageCompany()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setAverageCompanies(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getAverageCompany que retorna una lista de empresas con un promedio de cotejos consumidos por mes.
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas a el hook averageCompaniesMonthly con el tipo IAverageCompany[].
 */
export const getAverageCompanyMonthlyAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getAverageCompanyMonthly()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setAverageCompaniesMonthly(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getDailycomparisonAverage que retorna la informacion del dia actual respecto al tiempo (min y seg) promedio en que se realizan los cotejos
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con informacion tipo IDashboard que contiene el promedio de cotejos, minutos y segundos.
 */
export const getDailycomparisonAverageAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getDailycomparisonAverage()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setDashboardCapturador(response.result.entityDto));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getNumberRecordByTypeProcessCompany que retorna una lista de empresas con la informacion de el tipo de tramite mas usado en el dia actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas y la informacion del tipo de tramite a el hook typeProcessCompanies con el tipo ITypeProcessCompany[].
 */
export const getNumberRecordByTypeProcessCompanyAC =
  (): AppThunk => (dispatch) => {
    dispatch(setLoading(true));
    getNumberRecordByTypeProcessCompany()
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setTypeProcessCompanies(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getRecordbyCompany que retorna una lista de empresas con la informacion de un calculo promedio de cotejos consumidos por empresa al dia
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas y el promedio de cotejos por dia a el hook recordCompanies con el tipo IRecordCompany[].
 */
export const getRecordbyCompanyAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getRecordbyCompany()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setRecordCompanies(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getRecordCompanyByMonth que retorna una lista de empresas con la informacion de un calculo promedio de cotejos consumidos por empresa en mes actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas y el promedio de cotejos en el mes actual a el hook recordCompaniesMonthly con el tipo IRecordCompany[].
 */
export const getRecordCompanyByMonthAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getRecordCompanyByMonth()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setRecordCompaniesMonthly(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getRecordsByCompanyAndBusinessunit que retorna una lista de sedes con mayor cantidad de cotejos en el dia actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de sedes con el total de cotejos en el dia a el hook businessUnitRecords con el tipo IBusinessUnitRecord[].
 */
export const getRecordsByCompanyAndBusinessunitAC =
  (): AppThunk => (dispatch) => {
    dispatch(setLoading(true));
    getRecordsByCompanyAndBusinessunit()
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setBusinessUnitRecords(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getUserRecord que retorna una lista de usuarios con mayor número de cotejos en el dia actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de usuarios con el total de cotejos en el dia a el hook userRecords con el tipo IUserRecords[].
 */
export const getUserRecordsAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getUserRecord()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setUserRecords(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getRecordsTypeProcessCompanyAndBusinessunit que retorna una lista de tipos de tramites mas usados por sede en el dia actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de tipos de tramites con la cantidad de uso por sede en el dia a el hook typeProcessCompanyBusinessUnits con el tipo ITypeProcessCompanyBusinessUnit[].
 */
export const getRecordsTypeProcessCompanyAndBusinessunitAC =
  (): AppThunk => (dispatch) => {
    dispatch(setLoading(true));
    getRecordsTypeProcessCompanyAndBusinessunit()
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setTypeProcessCompanyBusinessUnits(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getCustomerPlanExpired que se envia hacia licenciamiento donde nos retorna una lista de empresas con cupos de cotejos vencidos
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas con cupos de cotejos vencidos a el hook customerPlanExpired con el tipo IPlanExpired[].
 * @param {CustomerConsumption} body - body es de tipo CustomerConsumption { ServiceName: string, QuantityCustomer: number | undefined }
 */
export const getCustomerPlanExpiredAC =
  (body: CustomerConsumption): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getCustomerPlanExpired(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setCustomerPlanExpired(response.result.records));
        } else {
          /** Función que toma el código de error devuelto y toma un mensaje personalizado desde un diccionario proporcionado por licenciamiento*/
          dispatch(
            setCustomerPlanExpiredMessage(
              licenseCode(response.statusCode, "plural")
            )
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getCustomerConsumption que se envia hacia licenciamiento donde nos retorna la informacion actual del consumo de cotejos, el limite de la bolsa y la fecha de expiración de la empresa
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la informacion del consumo y fecha de vencimiento de la bolsa a el hook customerConsumption con el tipo ICustomerConsumption.
 * @param {CustomerConsumption} body - body es de tipo CustomerConsumption { ServiceName: string, QuantityCustomer: number | undefined }
 */
export const getCustomerConsumptionAC =
  (body: CustomerConsumption): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getCustomerConsumption(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setCustomerConsumption(response.result));
        } else {
          /** Función que toma el código de error devuelto y toma un mensaje personalizado desde un diccionario proporcionado por licenciamiento*/
          dispatch(
            setCustomerConsumptionMessage(licenseCode(response.statusCode))
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getNextBalanceToExpire que se envia hacia licenciamiento donde nos retorna la informacion actual del consumo de cotejos, el limite de la bolsa y la fecha de expiración de la empresa
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la informacion del consumo y fecha de vencimiento de la bolsa a el hook customerConsumption con el tipo ICustomerConsumption.
 * @param {CustomerConsumption} body - body es de tipo CustomerConsumption { ServiceName: string, QuantityCustomer: number | undefined }
 */
export const getNextBalanceToExpireAC =
  (body: CustomerConsumption): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getNextBalanceToExpire(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setNextBalanceToExpire(response.result.records));
        } else {
          /** Función que toma el código de error devuelto y toma un mensaje personalizado desde un diccionario proporcionado por licenciamiento */
          dispatch(
            setNextBalanceToExpireMessage(
              licenseCode(response.statusCode, "plural")
            )
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getNextPlanExpirations que se envia hacia licenciamiento donde nos retorna una lista de empresas proximas a vencer contrato.
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista de empresas con proxima fecha a vencer contrato a el hook nextPlanExpirations con el tipo IPlanExpired[].
 * @param {CustomerConsumption} body - body es de tipo CustomerConsumption { ServiceName: string, QuantityCustomer: number | undefined }
 */
export const getNextPlanExpirationsAC =
  (body: CustomerConsumption): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getNextPlanExpirations(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setNextPlanExpirations(response.result.records));
        } else {
          /** Función que toma el código de error devuelto y toma un mensaje personalizado desde un diccionario proporcionado por licenciamiento */
          dispatch(
            setNextPlanExpirationsMessage(
              licenseCode(response.statusCode, "plural")
            )
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getAverageByBusinessunit que retorna una lista sedes con el promedio de cotejos consumidos durante el mes
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista sedes con el calculo promedio de cotejos consumidos a el hook businessUnitAverage con el tipo IBusinessUnitRecord[].
 */
export const getAverageByBusinessunitAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getAverageByBusinessunit()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setBusinessUnitAverage(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

/**
 * @description Lanza el servicio getRecordsTypeProcessByUser que retorna una podio de 5 tipos de tramite mas usados en el dia actual
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la lista tipos de tramites mas usados a el hook recordTypeProcessByUser con el tipo ITypeProcessByUser[].
 */
export const getRecordTypeProcessByUserAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getRecordsTypeProcessByUser()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setRecordTypeProcessByUser(response.result.records));
      }
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoading(false)));
};

export const getDoughnutTransactionAC =
  (dashboardTransactions: DashboardTransactionsRequest): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getDoughnutTransaction(dashboardTransactions)
      .then((response) =>
        dispatch(setDoughnutTransaction(response.result.records))
      )
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

export const getVerticalBarTransactionAC =
  (
    dashboardTransactions: DashboardTransactionsRequest,
    selectDate: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getVerticalBarTransaction(dashboardTransactions)
      .then((response) => {
        let IVerticalTransaccion: IVerticalBarTransaction[] = [];
        let startDate: Date | string;
        let endDate: Date | string;
        if (selectDate === "day") {
          startDate = new Date(dashboardTransactions.StartDate!);
          endDate = new Date(dashboardTransactions.EndDate!);
          startDate.setMinutes(
            startDate.getMinutes() + startDate.getTimezoneOffset()
          );
          endDate.setMinutes(
            endDate.getMinutes() + endDate.getTimezoneOffset()
          );
          if (response.result.records === null) {
            for (
              let i = new Date(startDate);
              i <= endDate;
              i.setDate(i.getDate() + 1)
            ) {
              IVerticalTransaccion = [
                ...IVerticalTransaccion,
                {
                  fullDate: formatDateIso({ date: i }),
                  hit: 0,
                  noHit: 0,
                  novedades: 0,
                },
              ];
            }
          } else {
            for (
              let i = new Date(startDate);
              i <= endDate;
              i.setDate(i.getDate() + 1)
            ) {
              let isTransaction: any = response.result.records.find(
                (transaction) =>
                  transaction.fullDate === formatDateIso({ date: i })
              );
              if (!!isTransaction) {
                IVerticalTransaccion = [
                  ...IVerticalTransaccion,
                  {
                    fullDate: isTransaction.fullDate,
                    hit: isTransaction.hit,
                    noHit: isTransaction.noHit,
                    novedades: isTransaction.novedades,
                  },
                ];
              } else {
                IVerticalTransaccion = [
                  ...IVerticalTransaccion,
                  {
                    fullDate: formatDateIso({ date: i }),
                    hit: 0,
                    noHit: 0,
                    novedades: 0,
                  },
                ];
              }
            }
          }
        } else {
          startDate = dashboardTransactions.StartDate!;
          endDate = dashboardTransactions.EndDate!;
          if (response.result.records === null) {
            if (startDate === endDate) {
              IVerticalTransaccion = [
                ...IVerticalTransaccion,
                {
                  fullDate: startDate,
                  hit: 0,
                  noHit: 0,
                  novedades: 0,
                },
              ];
            } else {
              for (let i = 0; i < 2; i++) {
                IVerticalTransaccion = [
                  ...IVerticalTransaccion,
                  {
                    fullDate: i === 0 ? startDate : endDate,
                    hit: 0,
                    noHit: 0,
                    novedades: 0,
                  },
                ];
              }
            }
          } else if (startDate === endDate) {
            IVerticalTransaccion = response.result.records.map(
              (transaction) => {
                return {
                  fullDate: String(startDate),
                  hit: transaction.hit,
                  noHit: transaction.noHit,
                  novedades: transaction.novedades,
                };
              }
            );
          } else {
            for (let i = 0; i < 2; i++) {
              let isTransaction: any = response.result.records.find(
                (transaction) =>
                  transaction.fullDate === (i === 0 ? startDate : endDate)
              );
              if (!!isTransaction) {
                IVerticalTransaccion = [
                  ...IVerticalTransaccion,
                  {
                    fullDate: isTransaction.fullDate,
                    hit: isTransaction.hit,
                    noHit: isTransaction.noHit,
                    novedades: isTransaction.novedades,
                  },
                ];
              } else {
                IVerticalTransaccion = [
                  ...IVerticalTransaccion,
                  {
                    fullDate: i === 0 ? startDate : endDate,
                    hit: 0,
                    noHit: 0,
                    novedades: 0,
                  },
                ];
              }
            }
          }
        }
        dispatch(setVerticalBarTransaction(IVerticalTransaccion));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * @description Lanza el servicio getCustomerLicense que se envia hacia licenciamiento donde nos retorna una la informacion de la bolsa del contrato actual con licenciamiento
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con la informacion de la bolsa a el hook license con el tipo  ICustomerConsumption[].
 * @param {CustomerConsumption} body - body es de tipo CustomerConsumption { ServiceName: string, DocumentNumber: number | undefined }
 */
export const getCustomerLicenseAC =
  (body: CustomerConsumption): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getCustomerLicense(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setCustomerLicense(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };
/**
 * @description Lanza el servicio getValidatedCounters que se envia hacia el backend de biometria donde se retorna una lista de contratos ya sea activos o inactivos
 * @param body.documentNumber Numero de documento de la compañia de la cual se desea conocer los contratos
 * @param body.valid Estado de los contratos que se desea buscar, true para los contratos activos y false para los inactivos
 * @returns {Function} se realiza una solicitud a la API de biometria y se dispara el dispatch con la informacion de los contratos
 */
export const getValidatedCountersAC =
  (body: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getValidatedCounters(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setValidatedCounters(response.result.records));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };
