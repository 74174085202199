import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeId } from "../../utils/general/utils";
import { TabComponentsProps, TabContent } from "../../utils/types/types";

export const AccordionMobile = ({ content }: TabComponentsProps) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {content.map(({ tabContent, label, title }: TabContent) => {
        const key = makeId(7);
        return (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              className={classes.summary}
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              }
              aria-controls={label}
              id={key}
              key={key}
            >
              <Typography className={classes.heading}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>{tabContent}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      justifyContent: "center",
    },
    accordion: {
      marginBottom: theme.spacing(2),
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #009BDD",
      borderRadius: "10px",
      borderColor: theme.palette.primary.light,
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    summary: {
      opacity: 1,
      font: "normal normal bold 16px/17px Muli",
      color: theme.palette.secondary.main,
    },
  })
);
