import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { debounceded } from "../utils/general/utils";

type ILocation = {
  latitude: number;
  longitude: number;
};

/**
 * Hook que devuelve la geolocalizacion (LAT, LNG)
 * @param {Object} [options] Objeto opciones de la geolocalizacion {enableHighAccuracy:boolean, maximumAge, timeout}
 * @returns [position | undefined]
 */
const useLocation = (options?: PositionOptions): [ILocation | undefined] => {
  const [position, setPosition] = useState<ILocation>();
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  function success(position: {
    coords: { latitude: any; longitude: any; accuracy: any };
  }) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setPosition({ latitude, longitude });
  }

  function error(err: any) {
    enqueueSnackbar(
      `La ubicación se encuentra bloqueado. Para habilitarlo en su navegador vaya a configuracion/privacidad/ubicacion otorgue el permiso \n Codigo: ${err.message}`,
      {
        variant: "error",
      }
    );
    setPosition(undefined);
  }

  const activateGeolocalization = debounceded(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        success,
        error,
        options ?? {
          enableHighAccuracy: false,
          maximumAge: 20000,
          timeout: 10000,
        }
      );
    } else {
      enqueueSnackbar(`Su navegador no soporta la geolocalizacion.`, {
        variant: "error",
      });
    }
  }, 5000);

  useEffect(() => {
    activateGeolocalization();
  }, []);

  return [position];
};

export default useLocation;
