import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { TextFieldProps } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { Controller } from "react-hook-form";


type InputRoundedProperties = {
  label: string;
  props?: TextFieldProps;
  name: string;
  defaultValue?: any;
  control: any;
  rules?: any;
  max?: string;
  min?: string;
  autocomplete?: string;
  onChanged?: Function | undefined;
};

const DateInputRoundedComponent = ({
  label,
  props,
  name,
  defaultValue,
  control,
  rules,
  max,
  min,
  autocomplete = "off",
  onChanged,
}: InputRoundedProperties) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <TextField
          type="date"
          {...field}
          fullWidth
          className={classes.inputCustom}
          label={label}
          variant="filled"
          InputProps={{
            classes: {
              disabled: classes.textFieldDisabled,
            },
            autoComplete: autocomplete,
          }}
          inputProps={{
            max,
            min
          }}
          {...props}
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputCustom: {
      background: "white",
      boxShadow: "0px 3px 3px #0000001A",
      border: "1px solid #D5D5D5",
      borderRadius: "10px",
      underline: "none",

    "& div": {
      background: "none !important",
      textDecoration: "none",
    },

    "& label": {
      fontSize: "15px",
      font: "normal normal normal 16px/17px Muli",
      transform: "translate(12px, 10px) scale(0.75)"
    },

    "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
      borderBottom: 'none',
    },
  },

  textFieldDisabled: {
    opacity: "1 !important",
  },
}));

export const DateInputRounded = (props: InputRoundedProperties) => {
  return <DateInputRoundedComponent {...props} />;
};
