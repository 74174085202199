import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import { Slide, SlideProps } from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { ArrowForwardIos } from "@material-ui/icons";
import useSocket from "../../hooks/useSocket";
import { IDataFinger, IFingerUnit } from "../../store/models/FingerPrintModel";

const useStyles = makeStyles((_theme: Theme) => ({
  title: {
    fontWeight: 900,
    color: "#FA5A00",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  informationResult: {
    fontWeight: 600,
    color: "#FA5A00",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 50,
    marginBottom: 50,
  },
  buttonContainer: {
    width: "100%",
  },
}));

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  onClose: any;
  informationMessage: any;
  returnToCapture: any;
  returnToForm: any;
  isUserFNA: boolean;
  index: number;
  sizeParticipant: number;
  nextParticipant: any;
  arrayFinger?: IFingerUnit[];
  dataParticipant?: IDataFinger | null;
}

const ModalError = ({
  isOpen,
  onClose,
  informationMessage,
  returnToCapture,
  returnToForm,
  isUserFNA,
  index,
  sizeParticipant,
  nextParticipant,
  arrayFinger,
  dataParticipant,
}: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xs");

  const classes = useStyles();

  const { saveInfoParticipant } = useSocket();

  const handleNextParticipant = () => {
    saveInfoParticipant(dataParticipant, arrayFinger, informationMessage);
    nextParticipant();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <DialogContent>
        <Typography className={classes.title}>
          {`VALIDACION NO SATISFACTORIA`}
        </Typography>
        <Typography className={classes.informationResult}>
          {`${informationMessage}`}
        </Typography>
        <div className={classes.buttonContainer}>
          <div style={{ display: "flex", gap: "10px" }}>
            <PrimaryButton
              title={isUserFNA ? "Aceptar" : "Volver a intentar"}
              props={{
                style: { fontSize: "14px" },
                onClick: isUserFNA ? returnToForm : returnToCapture,
              }}
            ></PrimaryButton>

            {index === sizeParticipant && !isUserFNA && (
              <PrimaryButton
                title={"Terminar"}
                props={{
                  onClick: handleNextParticipant,
                  style: { fontSize: "14px" },
                }}
              ></PrimaryButton>
            )}

            {index < sizeParticipant && sizeParticipant > 1 && (
              <PrimaryButton
                title={"Siguiente participante"}
                props={{
                  onClick: handleNextParticipant,
                  style: { fontSize: "14px" },

                  endIcon: (
                    <ArrowForwardIos style={{ fontSize: "25px !important" }} />
                  ),
                }}
              ></PrimaryButton>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalError;
