import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetParameters,
  IAtdpFile,
  IListAtdps,
  IListChecks,
  IListBase64,
  IMailParamAtdps,
  IDocumentInfoAtdps,
} from "../models/CreateAtdp";
import { RootState } from "./rootReducer";
import {
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
  StandardResponse,
} from "../types";
import { TypeAtdpEmail } from "../../type";

/**
 * @type business unit state definition
 *  */
type AtdpState = {
  createATDP: StandardResponse<IAtdpFile> | undefined;
  currentATDP: string | undefined;
  currentHtmlATDP: string | undefined;
  currentParameters: IGetParameters | undefined;
  parametersEdit: IGetParameters | undefined;
  parameters: number | undefined;
  listAtdp: IListAtdps[];
  checkboxListChecks: IListChecks[];
  dowloandPdfBase64Atdp: IListBase64[];
  sendEmailBase64Atdp: IMailParamAtdps[];
  mailAtdp: TypeAtdpEmail[];
  paginatorAtdp: PaginatorWithFilterAndSort;
  sizeAtdp: number;
  loadingAtdp: boolean;
  objectCurrentAtdp: IDocumentInfoAtdps | undefined;
  isEdit: boolean;
  isNew: boolean;
  statusAtdp: boolean | string
};

/**
 * @property state initialization
 */
const initialState: AtdpState = {
  createATDP: undefined,
  currentATDP: undefined,
  currentHtmlATDP: undefined,
  currentParameters: undefined,
  parameters: undefined,
  listAtdp: [],
  checkboxListChecks: [],
  dowloandPdfBase64Atdp: [],
  sendEmailBase64Atdp: [],
  mailAtdp: [],
  paginatorAtdp: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    filter: "",
    sort: "recent",
  },
  sizeAtdp: 0,
  loadingAtdp: false,
  objectCurrentAtdp: undefined,
  isEdit: false,
  isNew: false,
  parametersEdit: undefined,
  statusAtdp: false
};

/**
 * @function AtdpSlice redux state slice
 *
 * control over Atdp state
 */

const AtdpSlice = createSlice({
  name: "atdp",
  initialState,
  reducers: {
    setCreateAtdp: (
      state,
      { payload }: PayloadAction<StandardResponse<IAtdpFile> | undefined>
    ) => {
      state.createATDP = payload;
    },
    setAtdp: (state, { payload }: PayloadAction<string | undefined>) => {
      state.currentATDP = payload;
    },

    setCurrentHtml: (state, { payload }: PayloadAction<string | undefined>) => {
      state.currentHtmlATDP = payload;
    },

    setCurrentParameters: (
      state,
      { payload }: PayloadAction<IGetParameters | undefined>
    ) => {
      state.currentParameters = payload;
    },

    setParameters: (state, { payload }: PayloadAction<number | undefined>) => {
      state.parameters = payload;
    },

    setListAtdp: (state, { payload }: PayloadAction<IListAtdps[]>) => {
      state.listAtdp = payload;
    },

    setCheckboxListChecks: (
      state,
      { payload }: PayloadAction<IListChecks[]>
    ) => {
      state.checkboxListChecks = payload;
    },

    setDowloandPdfBase64Atdp: (
      state,
      { payload }: PayloadAction<IListBase64[]>
    ) => {
      state.dowloandPdfBase64Atdp = payload;
    },

    setSendEmailBase64Atdp: (
      state,
      { payload }: PayloadAction<IMailParamAtdps[]>
    ) => {
      if (payload.length === 0) {
        state.sendEmailBase64Atdp = [];
      } else {
        state.sendEmailBase64Atdp = [...state.sendEmailBase64Atdp, ...payload];
      }
    },

    setMailAtdp: (state, { payload }: PayloadAction<TypeAtdpEmail[]>) => {
      if (payload.length === 0) {
        state.mailAtdp = [];
      } else {
        state.mailAtdp = [...state.mailAtdp, ...payload];
      }
    },

    setPaginatorAtdp: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorAtdp.paginator = payload;
    },

    setFilterAtdp: (state, { payload }: PayloadAction<string>) => {
      state.paginatorAtdp.filter = payload;
    },

    setSortAtdp: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorAtdp.sort = payload;
    },

    setSizeAtdp: (state, { payload }: PayloadAction<number>) => {
      state.sizeAtdp = payload;
    },

    setLoadingAtdp: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingAtdp = payload;
    },

    setInfoAtdp: (
      state,
      { payload }: PayloadAction<IDocumentInfoAtdps | undefined>
    ) => {
      state.objectCurrentAtdp = payload;
    },

    setIsEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdit = payload;
    },

    setIsNew: (state, { payload }: PayloadAction<boolean>) => {
      state.isNew = payload;
    },

    setParametersEdit: (
      state,
      { payload }: PayloadAction<IGetParameters | undefined>
    ) => {
      state.parametersEdit = payload;
    },

    setStatusAtdp: (state, { payload }: PayloadAction<boolean | string>) => {
      state.statusAtdp = payload;
    },
  },
});

export const {
  setCreateAtdp,
  setAtdp,
  setCurrentHtml,
  setCurrentParameters,
  setParameters,
  setListAtdp,
  setDowloandPdfBase64Atdp,
  setSendEmailBase64Atdp,
  setMailAtdp,
  setPaginatorAtdp,
  setFilterAtdp,
  setSortAtdp,
  setSizeAtdp,
  setCheckboxListChecks,
  setLoadingAtdp,
  setInfoAtdp,
  setIsEdit,
  setIsNew,
  setParametersEdit,
  setStatusAtdp
} = AtdpSlice.actions;

export const AtdpSelector = (state: RootState) => state.atdp;

export default AtdpSlice.reducer;
