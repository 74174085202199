import moment from "moment";

type IformatDate = {
  date?: Date;
  getYesterday?: boolean;
};
type IDateByMonth = {
  getLastMonth?: boolean;
  _date?: any,
};

/**
 * Format date to standard 'YYYY/MM/DD'
 * @function
 * @param {any} date - date to format
 */
export default function formatDate(date: any) {
  const formatted_date = moment(date, "YYYY-MM-DD")
    .utc()
    .format("DD/MMMM/YYYY");
  return formatted_date !== "Fecha inválida" ? formatted_date : "";
}
/**
 * Formatea una fecha en formato YYYY-MM-DD o la fecha del día anterior en el mismo formato.
 * @param { Object } options - Opciones para el formateo de fecha.
 * @param { Date } options.date - Fecha a formatear. Si no se proporciona, se utiliza la fecha actual.
 * @param { boolean } options.getYesterday - Indica si se desea obtener la fecha del día anterior. (Default: false)
 * @returns { string } La fecha formateada en el formato YYYY-MM-DD.
 */
export const formatDateIso = ({
  date = new Date(),
  getYesterday = false,
}: IformatDate = {}) => {
  let day = getYesterday ? date.getDate() - 1 : date.getDate();
  let month = day === 0 ? date.getMonth() : date.getMonth() + 1;
  let year = date.getFullYear();
  let dateReturn;

  if (day === 0) {
    let previousMonth = new Date(year, month, day);
    day = previousMonth.getDate();
    month = previousMonth.getMonth() + 1;
    year = previousMonth.getFullYear();
    dateReturn = year + "-" + formato(month) + "-" + formato(day);
  } else {
    dateReturn = year + "-" + formato(month) + "-" + formato(day);
  }
  return dateReturn;
};
/**
 * Formatea una fecha en formato YYYY-MM o la fecha del mes anterior en el mismo formato.
 * @param { Object } options - Opciones para el formateo de fecha.
 * @param { boolean } options.getLastMonth - Indica si se desea obtener la fecha del mes anterior. (Default: false)
 * @returns { string } La fecha formateada en el formato YYYY-MM.
 */
export const getDateByMonth = ({ getLastMonth = false, _date = new Date() }: IDateByMonth = {}) => {
  let date: Date = new Date(_date);
  let month = getLastMonth ? date.getMonth() : date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + formato(month);
};

export const getMonthFormattedValue = (_date : any) => {
  let date: Date = new Date(_date);
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return year + "-" + formato(month);
};

const formato = (numero: number) => {
  return numero < 10 ? "0" + numero : numero;
};
