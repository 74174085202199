import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { decodeToken } from "../../utils/general/utils";

type CommonState = {
  error: string;
  loading: boolean;
  success: string;
  privilege: string[];
  rol: string;
  hashCsvModal: boolean;
  hashCsv: string;
};

const initialState: CommonState = {
  error: "",
  loading: false,
  success: "",
  privilege: [],
  rol: "",
  hashCsvModal: false,
  hashCsv: "",
};

const CommonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {
    setSuccess: (state, success: PayloadAction<string>) => {
      state.success = success.payload;
    },
    setError: (state, error: PayloadAction<string>) => {
      state.error = error.payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPrivilege: (state, { payload }: PayloadAction<string>) => {
      const tokenDecode = decodeToken(sessionStorage.getItem("jwtToken") ?? "");
      state.privilege =
        tokenDecode === undefined ? [] : tokenDecode.BIOMETRIA_PRIVILEGE;
    },
    setRol: (state, { payload }: PayloadAction<string>) => {
      const tokenDecode = decodeToken(sessionStorage.getItem("jwtToken") ?? "");
      state.rol = tokenDecode === undefined ? "" : tokenDecode?.BIOMETRIA_ROLE;
    },
    setHashCsvModal: (state, { payload }: PayloadAction<boolean>) => {
      state.hashCsvModal = payload;
    },
    setHashCsv: (state, { payload }: PayloadAction<string>) => {
      state.hashCsv = payload;
    },
  },
});

export const {
  setError,
  setLoading,
  setSuccess,
  setPrivilege,
  setRol,
  setHashCsvModal,
  setHashCsv,
} = CommonSlice.actions;

export const CommonSelector = (state: RootState) => state.common;

export default CommonSlice.reducer;
