import { useEffect, useState } from "react";
import { Column } from "../components/Table/types";
import { Device } from "../store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  businessUnitAuditAC,
  businessUnitParametrizationAuditAC,
} from "../store/action-creators/BusinessUnitActionCreators";
import {
  getBrandsAC,
  getBusinessUnitAC,
  getDeviceTypesAC,
  getModelsAC,
} from "../store/action-creators/CommonListActionCreators";
import {
  computerEquipmentAuditAC,
  getEquipmentByBusinessUnitCommonAC,
} from "../store/action-creators/ComputerEquipmentActionCreator";
import { deviceAuditAC } from "../store/action-creators/DeviceActionCreators";
import { ComputerEquipment } from "../store/models/ComputerEquipmentModel";

import {
  BusinessUnitSelector,
  setCurrentBusinessUnit,
  setEdit,
  setIsEditParametrization,
  setIsNew,
  setLoadingBusiness,
  setParametrization,
  setShowForm,
} from "../store/slices/BusinessUnitSlice";
import {
  CommonListSelector,
  setRepositories,
} from "../store/slices/CommonListsSlice";
import {
  ComputerEquipmentSelector,
  setShowForm as computerShowForm,
  setComputerIsEdit,
  setCurrentComputerEquipment,
} from "../store/slices/ComputerEquipmentSlice";
import {
  DeviceSelector,
  setCurrentDevice,
  setIsDeviceEdit,
  setAdvanceSearch as setAdvanceSearchDevice,
} from "../store/slices/DeviceSlice";
import { CommonSelector } from "../store/slices/CommonSlice";

const useBusinessUnitForm = () => {
  // REDUX state
  const dispatch = useDispatch();
  const {
    currentBusiness,
    isEdit,
    isNew,
    businessUnitAudit,
    parametrizationAudit,
    isEditParametrization,
    loadingBusinessForm
  } = useSelector(BusinessUnitSelector);
  const {
    isEdit: computerEdit,
    computerEquipamentAudit,
    computerEquipment,
  } = useSelector(ComputerEquipmentSelector);
  const {
    isDeviceEdit,
    diviceAudit,
    currentDevice,
    advanceSearch: advanceSearchDevices,
  } = useSelector(DeviceSelector);
  const { loading } = useSelector(CommonSelector);
  const { businessUnits } = useSelector(CommonListSelector);

  // Local state
  const [tab, setTab] = useState(0);
  const [showComputers, setShowComputers] = useState(false);
  const [showDevices, setShowDevices] = useState(false);
  const [isEditB, setIsEditB] = useState<boolean>(isEdit);

  /**
   * Change the state of the local useState
   * @function
   * @param {boolean} edit data to save
   */
  const handleIsEditB = (edit: boolean) => setIsEditB(edit);

  /**
   * save state on REDUX to handle parametrization
   * @function
   * @param {boolean} editParametrization data to format
   */
  const handleIsParametrization = (editParametrization: boolean) => {
    dispatch(setIsEditParametrization(editParametrization));
  };

  /**
   * Change the state of the local useState
   * @function
   * @param {boolean} showC data to save
   */
  const handleShowComputers = (showC: boolean) => setShowComputers(showC);

  /**
   * Change the state of the local useState
   * @function
   * @param {boolean} showC data to save
   */
  const handleShowDevices = (showC: boolean) => setShowDevices(showC);

  /**
   * Switch to false state on REDUX to handle visualization of computers form
   * @function
   */
  const handleDispachShowComputers = () => dispatch(computerShowForm(false));

  /**
   * Switch the state of the local useState
   * @function
   */
  const handleComuterIsEdit = () => dispatch(setComputerIsEdit(!computerEdit));

  /**
   * Switch the state of the local useState
   * @function
   */
  const handleDeviceEdit = () => dispatch(setIsDeviceEdit(!isDeviceEdit));

  /**
   * Switch the state of the local useState
   * @function
   */
  const handleEditEB = () => dispatch(setEdit(!isEdit));

  /**
   * save state on REDUX to handle parametrization
   * @function
   * @param {boolean} stateSE data to save
   */
  const handleSetEdit = (stateSE: boolean) => dispatch(setEdit(stateSE));

  /**
   * handle programatic return to the previous page
   * @function
   */
  const handleBack = () => {
    if (showDevices) {
      setShowDevices(false);
      return;
    }
    if (showComputers) {
      setShowComputers(false);
      return;
    }

    setShowDevices(false);
    setShowComputers(false);
    dispatch(setShowForm(false));
    dispatch(setCurrentBusinessUnit(undefined));
    dispatch(setRepositories([]));
    dispatch(setParametrization(undefined));
    dispatch(setEdit(true));
    dispatch(setIsNew(true));
  };

  /**
   * Handle change tab
   * @function
   * @param {any} value data to save
   */
  const handleChangeTab = (_event: any, value: any) => {
    if (!currentBusiness && value === 1) {
    } else {
      setTab(value);
    }
  };

  /**
   * Set current business unit on REDUX (businessUnitAuditAC)
   * @function
   */
  const handleAudit = () => {
    if (currentBusiness) {
      dispatch(businessUnitAuditAC(currentBusiness));
    }
  };

  /**
   * Set current business unit on REDUX (businessUnitParametrizationAuditAC)
   * @function
   */
  const handleAuditParametrization = () => {
    if (currentBusiness) {
      dispatch(businessUnitParametrizationAuditAC(currentBusiness));
    }
  };

  /**
   * Set computer equipment on REDUX (computerEquipment)
   * @function
   */
  const handleAuditComputer = () => {
    if (computerEquipment) {
      dispatch(computerEquipmentAuditAC(computerEquipment, setLoadingBusiness));
    }
  };

  /**
   * Set current device on REDUX (currentDevice)
   * @function
   */
  const handleAuditDevices = () => {
    if (currentDevice) {
      dispatch(deviceAuditAC(currentDevice, setLoadingBusiness));
    }
  };

  //#endregion
  //#region

  /**
   * Set current computer equipment on REDUX (setCurrentComputerEquipment)
   * @function
   * @param {any} value data to set new computer equipment object
   */
  const handleComputerEquipment = (e: any, value: any, column: Column) => {
    const currentComputer: ComputerEquipment = {
      id: value.id,
      businessUnit: value.businessUnit,
      state: value.state,
      equipment: value.type,
      ip: value.ip,
      mac: value.mac,
      name: value.name,
      fixedStation: value.fixedStation,
      os: value.os,
      haveProcess: value.haveProcess
    };
    dispatch(setCurrentComputerEquipment(currentComputer));
    setShowComputers(true);
  };

  /**
   * Set current device on REDUX (setCurrentDevice)
   * @function
   * @param {any} value data to set new computer equipment object
   */
  const handleDevices = (e: any, value: any, column: Column) => {
    const currentDev: Device = {
      id: value.id,
      brand: value.brand,
      businessUnit: value.businessUnit,
      equipment: value.equipment,
      model: value.model,
      serial: value.serial,
      state: value.state,
      type: value.type,
      haveProcess: value.haveProcess
    };
    if (!showDevices) {
      dispatch(setCurrentDevice(currentDev));
      dispatch(getBrandsAC());
      dispatch(getModelsAC(value.brand));
      dispatch(getDeviceTypesAC());
      if (businessUnits.length <= 0) dispatch(getBusinessUnitAC());
      dispatch(
        getEquipmentByBusinessUnitCommonAC({
          BusinessUnitId: value.businessUnit,
          Paginator: { itemsPage: 0, page: 0 },
          filter: "",
          sort: "recent",
        })
      );
    }
    setShowDevices(true);
  };

  /**
   * return title of current page
   * @function
   * @returns {string} title of current page
   */
  const handleTitle = (): string => {
    if (isNew) return "Crear sede";
    if (!isNew) {
      if (showComputers) return "Equipos";
      if (showDevices) return "Dispositivos";
      return "Editar sede";
    }
    return "";
  };

  /**
   * Return true if computer or device is editing
   * @function
   * @param {any} value data to set new computer equipment object
   */
  const handleEdit = () => {
    if (showComputers) return computerEdit;
    if (showDevices) return isDeviceEdit;
    return isEdit;
  };

  /**
   * Efecto para eliminar los datos del estado global de la busqueda avanzada de dispositivos
   */
  useEffect(() => {
    if (advanceSearchDevices.length > 0) dispatch(setAdvanceSearchDevice([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleBack,
    handleChangeTab,
    handleAudit,
    handleAuditParametrization,
    handleAuditComputer,
    handleAuditDevices,
    currentBusiness,
    isEdit,
    isNew,
    businessUnitAudit,
    parametrizationAudit,
    computerEdit,
    computerEquipamentAudit,
    computerEquipment,
    isDeviceEdit,
    diviceAudit,
    currentDevice,
    isEditB,
    isEditParametrization,
    handleIsEditB,
    handleIsParametrization,
    tab,
    showComputers,
    showDevices,
    setShowComputers,
    setShowDevices,
    handleShowComputers,
    handleShowDevices,
    handleComputerEquipment,
    handleDevices,
    handleTitle,
    handleEdit,
    handleDispachShowComputers,
    handleComuterIsEdit,
    handleDeviceEdit,
    handleEditEB,
    handleSetEdit,
    loading,
    loadingBusinessForm
  };
};

export default useBusinessUnitForm;
