import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    jumbotronCard: {
      background: "#e5e5e5",
      width: "60%",
      borderRadius: "10px",
      alignSelf: "right",
      position: "relative",
      left: "25%",
      padding: "20px",
      paddingLeft: "34px",
      paddingBottom: "60px",
    },
    contentContainer: {
      alignItems: "right",
    },
    descriptionContent: {
      fontSize: "15px",
      color: "#6e6c6c",
      lineHeight: "25px",
    },
    containerCaptcha: {
      padding: "6px",
      alignContent: "center",
    },
    captchaCard: {
      width: "90%",
    },
    captchaAction: {
      padding:"6px"
    },
    btnReload: {
      background: '#FFFFFF',
      boxShadow: '0px 3px 3px #0000001A',
      border: '1px solid #D5D5D5',
      borderRadius: '10px',
      padding: '4px',
      '& span': {
        fontSize: '12px',
        font: 'normal normal normal Muli',
  
      },
    },
    massageErrorAlert: {
      fontSize: "14px",
    }
}));