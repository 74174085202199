import {
  updateATDP,
  getATDP,
  SetParametersATDP,
  UpdateParametersATDP,
  GetParametersATDP,
  GetListATDP,
  GetdocumentATDP,
  getATDPInfo,
} from "../../services/CreateATDP";
import {
  ICreateATDP,
  IGetATDP,
  ISetParameters,
  IAtdpFile,
  IDocumentAtdps,
  IListAtdps,
  IListChecks,
  IListBase64,
  IMailParamAtdps,
  IGetATDPInfo,
} from "../models/CreateAtdp";
import {
  setAtdp,
  setCreateAtdp,
  setCurrentParameters,
  setParameters,
  setListAtdp,
  setDowloandPdfBase64Atdp,
  setPaginatorAtdp,
  setFilterAtdp,
  setSortAtdp,
  setSizeAtdp,
  setSendEmailBase64Atdp,
  setInfoAtdp,
  setLoadingAtdp,
  setStatusAtdp,
} from "../slices/AtdpSlice";
import { setError, setLoading } from "../slices/CommonSlice";
import { AppThunk } from "../store";
import { PaginatorWithFilterAndSort, sortBy, StandardResponse } from "../types";

/**
 * Actualizar un elemento ATDP y realizar acciones basadas en el resultado.
 * @param { ICreateATDP } body - Datos para la creación del ATDP.
 */

export const UpdateATDPAC =
  (body: ICreateATDP): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      updateATDP(body)
        .then((response: StandardResponse<IAtdpFile>) => {
          if (response.statusCode === 200) {
            dispatch(setCreateAtdp(response));
            dispatch(setAtdp(response.result.file || undefined));
            dispatch(
              setStatusAtdp("¡La plantilla del ATDP Se ha creado exitosamente!")
            );
          } else {
            dispatch(setError(response.statusMessage));
            dispatch(setStatusAtdp("Error al crear la plantilla ATDP"));
          }
        })
        .catch((err) =>
          dispatch(setStatusAtdp("Error al crear la plantilla ATDP"))
        )
        .finally(() => dispatch(setLoading(false)));
  };

/**
 * Obtiene el atdp guardado.
 * @param { IGetATDP } body - Identificador de la compañia para realizar la busqueda.
 */

export const GetATDPAC =
  (body: IGetATDP): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      getATDP(body)
        .then((response: any) => {
          if (response.statusCode === 200) {
            if (response.result.file) {
              dispatch(setAtdp(response.result.file));
            } else {
              dispatch(setAtdp(undefined));
            }
          } else if (response.statusCode === 201) {
            dispatch(setAtdp(undefined));
          } else {
            dispatch(setError("tenemos problemas"));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoading(false)));
  };

/**
 * Crea la parametrización del atdp de la compañia.
 * @param { ISetParameters } body - información para la creación del atdp.
 * @param { any } enqueueSnackbar - función para mostrar informacion de la creación en una notificación.
 */

export const SetParametersATDPAC =
  (body: ISetParameters, enqueueSnackbar: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    SetParametersATDP(body)
      .then((response: any) => {
        if (response.statusCode === 200) {
          dispatch(setCurrentParameters(response.result));
          dispatch(setParameters(response.statusCode));
          enqueueSnackbar("Información guardada exitosamente", {
            variant: "success",
          });
        } else {
          dispatch(setError(response.result.information));
          enqueueSnackbar(`${response.result.information}`, {
            variant: "error",
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * Actualiza la parametrización del atdp de la compañia.
 * @param { ISetParameters } body - información para actualización del atdp.
 * @param { any } enqueueSnackbar - función para mostrar informacion de la actualización en una notificación.
 */

export const UpdateParametersATDPAC =
  (body: ISetParameters, enqueueSnackbar: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    UpdateParametersATDP(body)
      .then((response: any) => {
        if (response.statusCode === 200) {
          dispatch(setCurrentParameters(response.result));
          dispatch(setParameters(response.statusCode));
          enqueueSnackbar("Información actualizada exitosamente", {
            variant: "success",
          });
        } else {
          dispatch(setError(response.result.information));
          enqueueSnackbar(`${response.result.information}`, {
            variant: "error",
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * Retorna parametrización del atdp si existe configuración de la compañia.
 * @param { IGetATDP } body - identificador de la compañia para realizar la consulta.
 */

export const GetParametersATDPAC =
  (body: IGetATDP): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      GetParametersATDP(body)
        .then((response: any) => {
          if (response.statusCode === 200 || response.statusCode === 201) {
            dispatch(setCurrentParameters(response.result));
          } else {
            dispatch(setError(response.result));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoading(false)));
  };

/**
 * Actualiza estado en redux de la paginación.
 * @param { number } page - número de página.
 * @param { number } itemsPage - limite de datos mostrados.
 * @param { boolean } recharge - validación se recarga la paginación.
 */

export const GetPaginatorATDPAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorAtdp({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

/**
 * Actualiza el estado en redux del filtro de busqueda.
 * @param { string } filter - texto a filtrar en la busqueda
 */
export const GetFilterATDPAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterAtdp(filter));
  };

/**
 * Actualiza el estado en redux del tipo de ordenamiento seleccionado.
 * @param { sortBy } sort - tipo de ordenamiento.
 */

export const GetSortATDPAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortAtdp(sort));
  };

/**
 * Obtiene y actualiza la lista de atdps.
 * @param { PaginatorWithFilterAndSort } paginator - parametro de tipo PaginatorWithFilterAndSort que representa los elementos de paginacion, ordenado o filtro
 * @param { IListChecks[] } checksList - array con la lista de atdps chequeados.
 */
export const GetListATDPAC =
  (
    paginator: PaginatorWithFilterAndSort,
    checksList: IListChecks[]
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      GetListATDP(paginator)
        .then((response) => {
          if (response.count > 0 || response.informationCode === "A10") {
            let listBD: IListAtdps[] = [...response.records];
            let listChecks: any[] = [...checksList];

            // Recorre cada elemento en la lista `listBD` y modifica los elementos que coinciden en la lista `listChecks`.
            // eslint-disable-next-line array-callback-return
            listBD.map((item, index) => {
              // eslint-disable-next-line array-callback-return
              listChecks.map((itm) => {
                if (item.atdpId === itm.atdpId) {
                  // Crea un nuevo elemento con propiedades actualizadas.
                  let element = {
                    ...itm,
                    document: item.document,
                    nut: item.nut,
                    date: item.date,
                    stateChecked: !itm.stateChecked,
                  };
                  // Reemplaza el elemento en `listBD` con el elemento actualizado.
                  listBD.splice(index, 1, element);
                }
              });
            });
            // Se actualiza la lista y la cantidad.
            dispatch(setListAtdp(listBD));
            dispatch(setSizeAtdp(response.count));
          } else {
            dispatch(
              setError(response.information || "Exception GetListATDPAC")
            );
          }
        })
        .catch((err) => dispatch(setError(err || "Exception GetListATDPAC")))
        .finally(() => dispatch(setLoading(false)));
  };
/**
 * Obtiene el base 64 del atdp para su respectiva descarga en formato pdf.
 * @param { IDocumentAtdps } body - información de ubicación del atdp
 * @param {string } atdpName - nombre del atdp.
 * @param { boolean } isPublic - parametro no se usa.
 */

export const GetBase64PDFATDPAC =
  (
    body: IDocumentAtdps,
    atdpName: string,
    isPublic: Boolean = false
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      GetdocumentATDP(body)
        .then((response: any) => {
          let listBase64: IListBase64[] = [];
          if (response.codigoRespuesta === "A10") {
            listBase64.push({
              base64: response.archivoBase64,
              atdpName: atdpName,
            });
            dispatch(setDowloandPdfBase64Atdp(listBase64));
          }
        })
        .catch((err: any) =>
          dispatch(
            setDowloandPdfBase64Atdp([
              {
                base64: "",
                atdpName: "",
              },
            ])
          )
        )
        .finally(() => dispatch(setLoading(false)));
  };

/**
 * Obtiene el base 64 del atdp para su envio por correo.
 * @param { IDocumentAtdps } flag - información de ubicación del atdp
 * @param { IListChecks } item - información del cliente al cual se le va hacer envio del atdp por correo.
 */

export const GetBase64EmailATDPAC =
  (flag: IDocumentAtdps, item: IListChecks): AppThunk =>
  (dispatch) => {
    let base64: IMailParamAtdps = {
      IdAtdp: item.atdpId,
      base64: "",
      email: item.email,
      names: item.names,
      surnames: item.surnames,
      messageStatus: "",
      codeStatus: "",
    };
    dispatch(setLoadingAtdp(true)) &&
      GetdocumentATDP(flag)
        .then((response: any) => {
          if (response.codigoRespuesta === "A10") {
            base64 = {
              IdAtdp: item.atdpId,
              base64: response.archivoBase64,
              email: item.email,
              names: item.names,
              surnames: item.surnames,
              messageStatus: response.descripcionRespuesta,
              codeStatus: response.codigoRespuesta,
            };
            dispatch(setSendEmailBase64Atdp([base64]));
          } else {
            dispatch(setError(response.descripcionRespuesta));
          }
        })
        .catch((err: any) => dispatch(setSendEmailBase64Atdp([base64])));
  };

/**
 * Consulta el detalle del atdp y la devuelve en una lista.
 * @param { IGetATDPInfo } body - codigo qr.
 * @param { Boolean } isPublic - parametro no se usa.
 */

export const GetATDPInfoAC =
  (body: IGetATDPInfo, isPublic: Boolean = false): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      getATDPInfo(body)
        .then((response: any) => {
          if (response.count > 0) {
            if (response.records.length > 0) {
              dispatch(setInfoAtdp(response.records[0]));
            } else {
              dispatch(setInfoAtdp(undefined));
            }
          } else if (response.count === 0) {
            dispatch(setInfoAtdp(undefined));
          } else {
            dispatch(setError("tenemos problemas"));
          }
        })
        .catch((err: any) => console.error(err))
        .finally(() => dispatch(setLoading(false)));
  };
