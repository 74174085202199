import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ViewLayout from "../../components/Layouts/ViewLayout";
import FormDocumentValidation from "./FormDocumentValidation";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style";
import { GetATDPInfoAC } from "../../store/action-creators/CreateAtdpActionCreators";
import { AtdpSelector, setInfoAtdp } from "../../store/slices/AtdpSlice";

interface DocumentValidationProps {
  qrcode: string;
  nitCompany?: String;
}

const DocumentValidation = ({ qrcode }: DocumentValidationProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { objectCurrentAtdp } = useSelector(AtdpSelector);
  const [status, setStatus] = React.useState(false);
  const [messageStatus, setMessageStatus] = React.useState("");

  React.useEffect(() => {
    dispatch(setInfoAtdp(undefined));
    dispatch(GetATDPInfoAC({ qrcode: qrcode }, true));
    setMessageStatus("Verificando ATDP");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setStatus(objectCurrentAtdp !== undefined ? true : false);
    setMessageStatus(
      objectCurrentAtdp !== undefined
        ? "ATDP Obtenido correctamente"
        : "Error al encontrar éste ATDP"
    );
  }, [objectCurrentAtdp]);

  return (
    <div>
      <ViewLayout
        isNew={false}
        isEdit={false}
        headerTitle="Validador de documentos"
        editOption={false}
        isCentered={true}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            xl={7}
            className={styles.contentContainer}
          >
            <div className={styles.jumbotronCard}>
              <Typography className={styles.descriptionContent}>
                {" "}
                El validador documental permite verificar la autenticidad de la
                Autorización de Tratamiento de Datos Personales firmado
                electrónicamente. Para realizar la comprobación introduzca los
                datos necesarios en el cuadro de texto según se muestre.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={5} xl={5}>
            <FormDocumentValidation
              status={status}
              messageStatus={messageStatus}
            />
          </Grid>
        </Grid>
      </ViewLayout>
    </div>
  );
};

export default DocumentValidation;
