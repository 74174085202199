import { ButtonsProps } from "../../../components/Table/types";

/**
 * @description Componente que renderiza los botones necesarios para el Datatable para las funciones de ordenado o exportacion de archivo
 * @returns { ButtonsProps } ButtonsProps
 */
export const getButtons = (): ButtonsProps => {
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "center",
      options: [
        {
          label: "Más reciente",
          columns: [
            {
              prop: "CreatedOn",
              direction: 1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
        {
          label: "Más antiguo",
          columns: [
            {
              prop: "CreatedOn",
              direction: -1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
      ],
      cellSize: {
        lg: 2,
        md: 2,
        sm: 12,
        xs: 12,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
      fileName: "Listado de Cotejos",
    },
  };

  return button;
};
