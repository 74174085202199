import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ReactComponent as OrderIcon } from "../../assets/bars.svg";

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    borderRadius: 3,
    border: "1px solid #A8A8A8",
    height: 37,
    padding: "0 15%",
    boxShadow: "none",
    color: "#6D6E71 !important",
    opacity: "1",
    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      boxShadow: "none",
      border: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(Button);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    background: "#FFFFFF !important",
  },
})((props: any) => (
  <Menu
    open={props.open}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

type MenuOrderProps = {
  size: "small" | "medium" | "large";
  disabled: boolean;
  options: any;
  selectItem: Function;
};
const MenuOrder = ({ options, selectItem, disabled, size }: MenuOrderProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [active, setActive] = useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (_event: any, index: any) => {
    selectItem(index);
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  return (
    <>
      <StyledButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        size={size}
        disabled={disabled}
        onClick={handleClick}
        onMouseOver={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        startIcon={<OrderIcon height={13} />}
      >
        <p
          style={{
            margin: 0,
            lineHeight: 1,
          }}
        >
          Ordenar
        </p>
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option: any, index: any) => (
          <StyledMenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default MenuOrder;
