import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { TabAdmin } from "../../components/Tabs/TabAdmin";

import {
  BusinessUnitSelector,
  setCurrentBusinessUnit,
  setEdit,
  setIsNew,
  setParametrization,
  setShowForm,
} from "../../store/slices/BusinessUnitSlice";
import {
  setRepositories,
  setStatesAndCities,
} from "../../store/slices/CommonListsSlice";

type MassiveLoadProps = {
  title: string;
  handleBack: any;
  loadInfo: ReactElement;
  loadResult: ReactElement;
  handleChangeTab: any;
  tab: number;
};

const MassiveLoadComponent = (props: MassiveLoadProps) => {
  const [showComputers, setShowComputers] = React.useState(false);
  const [showDevices, setShowDevices] = React.useState(false);
  const { isNew } = useSelector(BusinessUnitSelector);

  const dispatch = useDispatch();

  const handleBack = () => {
    if (showDevices) {
      setShowDevices(false);
      return;
    }
    if (showComputers) {
      setShowComputers(false);
      return;
    }
    setShowDevices(false);
    setShowComputers(false);
    dispatch(setShowForm(false));
    props.handleBack();
    dispatch(setCurrentBusinessUnit(undefined));
    dispatch(setRepositories([]));
    dispatch(setParametrization(undefined));
    dispatch(setEdit(true));
    dispatch(setIsNew(true));
  };

  const HandleMenu = () => {
    return [
      {
        label: "Cargue de información",
        tabContent: props.loadInfo,
      },
      {
        label: "Resultado del cargue",
        tabContent: props.loadResult,
      },
    ];
  };

  const BusinessContent = () => {
    return (
      <TabAdmin
        tab={props.tab}
        handleChangeTab={props.handleChangeTab}
        content={HandleMenu()}
      ></TabAdmin>
    );
  };

  return (
    <ViewLayout
      isNew={isNew}
      headerTitle={props.title}
      handleIconClick={handleBack}
    >
      <BusinessContent />
    </ViewLayout>
  );
};

export default MassiveLoadComponent;
