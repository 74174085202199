import { Grid, Tooltip } from "@material-ui/core";
import { useState } from "react";
import useProgressBar from "../../hooks/useProgressBar";
import { ProgressBarProps } from "../../store/models/DashboardModel";
import Marquee from "../Marquee";
import "../ProgressBar/ProgressBar.css";

export const LinearBar = (props: ProgressBarProps) => {
  const [style] = useProgressBar(props);
  const [watchText, setWatchText] = useState<boolean>(false);
  setTimeout(() => {
    setWatchText(true);
  }, 1500);
  return props.onlyBars ? (
    <div className="progress-done" style={style}></div>
  ) : (
    <Tooltip title={`${props.title} (${props.value ? props.value : props.done})`} placement="left">
      <div className="progress">
        <div className="progress-done" style={style}>
          {watchText && (
              <Grid container key={`grid-${props.title}`}>
                <Grid item xs={10} key={`title-${props.title}`}>
                  <Marquee style={{ fontSize: "11px", paddingLeft: 3 }} withTooltip={props.withTooltip}>
                    {props.title}
                  </Marquee>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "end" }} key={`value-${props.title}`}>
                    <Marquee
                      style={{
                        marginRight: "4px",
                        fontSize: "10px",
                        textAlign: "end",
                        lineHeight: 3,
                        alignSelf: "end",
                      }}
                      withTooltip={props.withTooltip}
                    >
                      {props.value ? props.value.toLocaleString() : props.done}
                    </Marquee>
                </Grid>
              </Grid>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
