import React, { useEffect, useState, useMemo } from "react";
import {
  UpdateATDPAC,
  SetParametersATDPAC,
  UpdateParametersATDPAC,
  GetATDPAC,
} from "../store/action-creators/CreateAtdpActionCreators";
import { CommonSelector } from "../store/slices/CommonSlice";
import {
  AtdpSelector,
  setIsEdit,
  setIsNew,
  setParametersEdit,
  setStatusAtdp
} from "../store/slices/AtdpSlice";
import { useDispatch, useSelector } from "react-redux";
import { templateATDP } from "../components/Atdp/templateATDP";
import { ICreateATDP } from "../store/models/CreateAtdp";
import { MESSAGES } from "../utils/types/const";
import { useSnackbar } from "notistack";
import { CompanySelector } from "../store/slices/CompanySlice";
import { ENCRYPT_PHARAGRAPH } from "../config/const";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { parametrizationMask } from "../utils/general/masks";

interface IParametrization {
  clientId: string;
  generateATDP: boolean;
  repository: number;
  urlATDP: string;
  userStamp: string;
  passwordStamp: string;
  signATDP: boolean;
  signTyC: boolean;
  userSign: string;
  passwordSign: string;
  automaticEmail: boolean;
}
/**
 * Este Hook encapsula las funciones, useEffect y validaciones de el formulario de parametrizacion de compañia
 */
const useCompanyParametrization = () => {
  // dependencies
  var aesEcb = require("aes-ecb");
  // hook
  const dispatch = useDispatch();
  const { currentATDP, currentParameters, isNew, isEdit, parametersEdit, statusAtdp } =
    useSelector(AtdpSelector); 
  const { loading } = useSelector(CommonSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompany } = useSelector(CompanySelector);

  // states
  const [html, setHtml] = useState<any | null>(null);
  const [modalATDP, setModalATDP] = useState(false);
  const [errotAtdp, setErrotAtdp] = useState(false);
  const [confirmATDP, setConfirmATDP] = useState(false);
  const [cancelATDP, setCancelATDP] = React.useState(false);
  const [previewATDP, setPreviewATDP] = React.useState(false);
  const [generateATDP, setGenerateATDP] = React.useState(false);
  const [signATDP, setSignATDP] = React.useState(false);
  const [tab, setTab] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [companyParameters, setCompanyParameters] = React.useState<
    IParametrization | undefined | {}
  >();

  const ParametrizationSchema = yup.object().shape({
    clientId: yup.string().max(100, `${MESSAGES.max} 100`).trim().nullable(),
    generateATDP: yup.bool(),
    repository: yup.string().max(100, `${MESSAGES.max} 100`).trim().nullable(),
    urlATDP: yup
      .string()
      .max(100, `${MESSAGES.max} 100`)
      .when("generateATDP", {
        is: (value: boolean) => !!value,
        then: yup.string().required(MESSAGES.required).nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })
      .trim(),
    userStamp: yup
      .string()
      .max(100, `${MESSAGES.max} 100`)
      .when("generateATDP", {
        is: (value: boolean) => !!value,
        then: yup.string().required(MESSAGES.required).nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })
      .trim(),
    passwordStamp: yup
      .string()
      .max(200, `${MESSAGES.max} 200`)
      .when("generateATDP", {
        is: (value: boolean) => !!value,
        then: yup.string().required(MESSAGES.required).nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })
      .trim(),
    signATDP: yup.bool(),
    signTyC: yup.bool(),
    userSign: yup
      .string()
      .max(100, `${MESSAGES.max} 100`)
      .when("signATDP", {
        is: (value: boolean) => !!value,
        then: yup.string().required(MESSAGES.required).nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })
      .trim(),
    passwordSign: yup
      .string()
      .max(150, `${MESSAGES.max} 150`)
      .when("signATDP", {
        is: (value: boolean) => !!value,
        then: yup.string().required(MESSAGES.required).nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })
      .trim(),
    automaticEmail: yup.bool(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IParametrization>({
    mode: "onChange",
    resolver: yupResolver(ParametrizationSchema),
    defaultValues: useMemo(() => currentParameters, [currentParameters]) ,
    shouldUnregister: false,
  });

  /** Se espera la carga de currentParameters para resetear el formulario con la informacion de los parametros de la empresa*/
  useEffect(() => {
    if (currentParameters !== undefined) {
      reset(currentParameters,{ keepTouched: true, keepDirty: true });
    }
  }, [currentParameters]);

  /** metodos para ejecutar estados que controlan los formularios, tabs, alertas */
  const handleOpenModal = () => {
    setModalATDP(true);
  };
  const handleCloseModal = () => {
    setModalATDP(false);
  };
  const handleErrorAtdp = (error: any) => {
    setErrotAtdp(error);
  };
  const handleConfirmAtdp = (confirm: any) => {
    dispatch(setStatusAtdp(confirm));
  };
  const handleCancelAtdp = (cancel: any) => {
    setCancelATDP(cancel);
  };
  const handlePreviewAtdp = (preview: any) => {
    setPreviewATDP(preview);
  };
  const handleGenerateAtdp = (generate: any) => {
    setGenerateATDP(generate);
  };
  const handleSignAtdp = (signAtdp: any) => {
    setSignATDP(signAtdp);
  };
  const handleSetIdEdit = (edit: boolean) => {
    if (edit) dispatch(setParametersEdit(currentParameters));
    dispatch(setIsEdit(edit));
  };
  const handleSetHtml = (setHtml: any) => {
    setHtml(setHtml);
  };

  /** Metodo para guardar el contenido html de la plantilla HTML para el ATDP  */
  const handleSuccess = (header?: any, footer?: any, image?: any) => {
    try {
      let imageConditional = image ? image : "";
      let headerConditional = header ? header : "";
      let footerConditional = footer ? footer : "";

      let htmlForSend = templateATDP(
        headerConditional,
        footerConditional,
        imageConditional
      );
      /** convertir el string recuperado en base64 y limpiar mediante chartCode los elementos UTF8 no admitidos */
      setHtml(htmlForSend);
      let base64 = btoa(
        htmlForSend.replace(/[\u00A0-\u2666-\uFEFF]/g, function (c) {
          return "&#" + c.charCodeAt(0) + ";";
        })
      );

      const createATDP: ICreateATDP = { Base64ATDP: base64 };
      dispatch(UpdateATDPAC(createATDP));
      setModalATDP(false);
      if (createATDP) {
        dispatch(setIsEdit(true));
      }
    } catch (e) {
      setErrotAtdp(true);
    }
  };
  /** Metodo para ejecutar cuando un input de el formulario se modifica y hay que almacenarlo en el elemento de hook correspondiente */
  const handleOnChangeForm = (e: any) => {
    switch (e.target.name) {
      case "generateATDP":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            generateATDP: e.target.checked,
          })
        );
        break;
      case "signATDP":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            signATDP: e.target.checked,
          })
        );
        break;
      case "urlATDP":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            urlATDP: parametrizationMask(e.target.value, "urlATDP"),
          })
        );
        break;
      case "clientId":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            clientId: parametrizationMask(e.target.value, "clientId"),
          })
        );
        break;
      case "userStamp":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            userStamp: parametrizationMask(e.target.value, "user"),
          })
        );
        break;
      case "passwordStamp":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            passwordStamp: e.target.value,
          })
        );
        break;
      case "userSign":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            userSign: parametrizationMask(e.target.value, "user"),
          })
        );
        break;
      case "passwordSign":
        dispatch(
          setParametersEdit({
            ...parametersEdit,
            passwordSign: e.target.value,
          })
        );
        break;
      default:
        break;
    }
  };
  /** Enviar formulario de parametrizacion*/
  const handleFormSubmit = (data: any) => {
    /** Validar y Encriptar contraseñas */
    const comparePasswordSign =
      currentParameters?.passwordSignOriginal?.substring(0, 150);
    const comparePasswordStamp =
      currentParameters?.passwordStampOriginal?.substring(0, 200);

    if (comparePasswordSign === data.passwordSign) {
      data.passwordSign = data?.passwordSignOriginal;
      delete data.passwordSignOriginal;
    } else {
      delete data.passwordSignOriginal;
      const passwordSignEncripted = !!data.passwordSign
        ? aesEcb.encrypt(ENCRYPT_PHARAGRAPH, data.passwordSign || "")
        : "";
      data.passwordSign = passwordSignEncripted;
    }
    if (comparePasswordStamp === data.passwordStamp) {
      data.passwordStamp = data?.passwordStampOriginal;
      delete data.passwordStampOriginal;
    } else {
      delete data.passwordStampOriginal;
      const passwordStampEncripted = !!data.passwordStamp
        ? aesEcb.encrypt(ENCRYPT_PHARAGRAPH, data.passwordStamp || "")
        : "";
      data.passwordStamp = passwordStampEncripted;
    }
    /** Validar si la parametrizacion es nueva (Guardar) o es antigua (Actualizar) */
    if (!!isNew) { 
      dispatch(SetParametersATDPAC(data, enqueueSnackbar));
    } else {
      dispatch(UpdateParametersATDPAC(data, enqueueSnackbar));
    }
    handleSetIdEdit(false);
  };

  /** Mensaje de error cuando las reglas de yup no estén correctas al llenar el formulario */
  const handleOnErrorSubmit = (errors: any, e: any) => {
    enqueueSnackbar("Error en el formulario", {
      variant: "error",
    });
  };

  const handleChangeTab = (event: any, newTab: any) => {
    newTab === 1 &&
      dispatch(
        GetATDPAC({ CompanyId: "c6937b97-8a44-454e-84c4-71a9a6d13450" })
      );
    setTab(newTab);
  };

  /** Verificar cuando la parametrizacion es nueva o ya existe para determinar el tipo de envio de parametros (guardar o actualizar) */
  React.useEffect(() => {
    if (!!currentParameters) {
      dispatch(setIsNew(false));
    } else {
      dispatch(setIsNew(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParameters]);

  React.useEffect(() => {
    setHtml(html);
  }, [html]);

  return {
    html,
    handleSetHtml,
    modalATDP,
    handleSuccess,
    handleOnChangeForm,
    companyParameters,
    handleOpenModal,
    handleCloseModal,
    handleErrorAtdp,
    errotAtdp,
    handleConfirmAtdp,
    handleCancelAtdp,
    handlePreviewAtdp,
    handleGenerateAtdp,
    handleSignAtdp,
    confirmATDP,
    cancelATDP,
    previewATDP,
    generateATDP,
    signATDP,
    handleSetIdEdit,
    isNew,
    isEdit,
    currentATDP,
    currentParameters,
    loading,
    handleFormSubmit,
    handleOnErrorSubmit,
    currentCompany,
    handleChangeTab,
    tab,
    parametersEdit,
    setValue,
    handleSubmit,
    errors,
    control,
    watch,
    statusAtdp
  };
};

export default useCompanyParametrization;
