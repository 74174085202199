import { Grid, Typography } from "@material-ui/core";
import useProgressBar from "../../hooks/useProgressBar";
import { ProgressBarProps } from "../../store/models/DashboardModel";
import Marquee from "../Marquee";
import "../ProgressBar/ProgressBar.css";

export const HorizontalChartBar = (props: ProgressBarProps) => {
  const [style] = useProgressBar(props);

  return (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <Marquee>{props.title}</Marquee>
      </Grid>
      <Grid item xs={4}>
        <div className="chart-bar">
          <div className="chart-bar-done" style={style}></div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography
          style={{
            margin: "0px auto",
            fontSize: "13px",
            fontWeight: "bold",
            paddingRight: "5%",
            color: "#fa5a00",
          }}
        >
          {props.value ? props.value : props.done}
        </Typography>
      </Grid>
    </Grid>
  );
};
