import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import {
  Theme,
  Dialog,
  DialogContent,
  Accordion,
  AccordionSummary,
  DialogProps,
  AccordionDetails,
} from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { SecondaryButton } from "../button/SecondaryButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { FingerPrintSelector } from "../../store/slices/FingerPrintSlice";
import { ValidateData } from "./ModalSuccess";
import "../Fingerprint/FingerPrintStyle.css";
import { Slide, SlideProps } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) => ({
  title: {
    font: "normal normal bold 18px/23px Muli",
    color: "#FA5A00",
    marginBottom: "31px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 30,
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,
    marginTop: 20,
  },
  validateParticipantsContainer: {
    width: "100%",
  },
  toggleContainer: {
    background: "#FAFAFA",
    border: "1px solid #DDDDDD",
    borderRadius: "10px !important",
    marginBottom: "20px",
  },
  text: {
    font: "normal normal bold 14px/18px Muli",
    color: "#009BDD",
  },
}));

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  isClose: any;
  finalize: any;
}

const ValidateParticipants = ({ isOpen, isClose, finalize }: Props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const { multipleParticipantInfo } = useSelector(FingerPrintSelector);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Typography className={classes.title}>
          {`Validación de identidad ante la Registraduría Nacional del Estado Civil`}
        </Typography>
        {!!multipleParticipantInfo.length &&
          multipleParticipantInfo.map((item) => {
            return (
              <div key={item.infoPerson.surname}>
                <Accordion className={classes.toggleContainer}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#FA5A00" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.text}>
                      {item.infoPerson.nameuser}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ marginBottom: 20 }}>
                    <div className={classes.validateParticipantsContainer}>
                      <ValidateData
                        arrayFingerData={item.infoFingers}
                        responseCaptureProp={item.infoRenec}
                        dataParticipantData={item.infoPerson}
                        resultMessage={item.resultMessage}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        <div className={classes.buttonContainer}>
          <div style={{ display: "flex", width: "100%", gap: "10px" }}>
            <SecondaryButton
              title={"Reintentar"}
              props={{ onClick: isClose }}
            ></SecondaryButton>
            <PrimaryButton
              title={"Iniciar otro trámite"}
              props={{ onClick: finalize }}
            ></PrimaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ValidateParticipants;
