import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ComputerEquipment,
  ComputerEquipmentResponse,
} from "../models/ComputerEquipmentModel";
import {
  FieldsAdvanceSearch,
  IAudit,
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
} from "../types";
import { RootState } from "./rootReducer";
import { ComputerEquipmentUnitMassiveResponse } from "../models/ComputerEquipmentUnit";

type ComputerEquipmentState = {
  showForm: boolean;
  computerEquipment: ComputerEquipment | undefined;
  computerEquipments: ComputerEquipmentResponse[];
  isEdit: boolean;
  computerEquipamentAudit: IAudit | undefined;
  computerEquipamentMassiveResponse:
    | ComputerEquipmentUnitMassiveResponse
    | undefined;
  paginatorEquipment: PaginatorWithFilterAndSort;
  size: number;
  advanceSearch: FieldsAdvanceSearch[];
  loadingComputerEquipment: boolean;
};

const initialState: ComputerEquipmentState = {
  showForm: false,
  computerEquipment: undefined,
  computerEquipments: [],
  isEdit: false,
  computerEquipamentAudit: undefined,
  computerEquipamentMassiveResponse: undefined,

  paginatorEquipment: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    filter: '',
    sort: "recent",
  },
  size: 0,
  advanceSearch: [],
  loadingComputerEquipment: false,
};

const computerEquipmentSlice = createSlice({
  name: "Computer-Equipment",
  initialState,
  reducers: {
    setCurrentComputerEquipment: (
      state,
      { payload }: PayloadAction<ComputerEquipment | undefined>
    ) => {
      state.computerEquipment = payload;
      if (payload) {
        state.isEdit = false;
      } else {
        state.isEdit = true;
      }
    },
    setComputerEquipments: (
      state,
      { payload }: PayloadAction<ComputerEquipmentResponse[]>
    ) => {
      state.computerEquipments = payload;
    },
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setComputerIsEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdit = payload;
    },
    auditComputerEquipament: (
      state,
      { payload }: PayloadAction<IAudit | undefined>
    ) => {
      state.computerEquipamentAudit = payload;
    },
    setComputerEquipamentMassive: (
      state,
      {
        payload,
      }: PayloadAction<ComputerEquipmentUnitMassiveResponse | undefined>
    ) => {
      state.computerEquipamentMassiveResponse = payload;
    },
    setPaginatorEquipment: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorEquipment.paginator = payload;
    },
    setFilterEquipment: (state, { payload }: PayloadAction<string>) => {
      state.paginatorEquipment.filter = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSortEquipment: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorEquipment.sort = payload;
    },
    setAdvanceSearch: (
      state,
      { payload }: PayloadAction<FieldsAdvanceSearch[]>
    ) => {
      state.advanceSearch = payload;
    },
    setLoadingComputerEquipment: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loadingComputerEquipment = payload;
    },
  },
});

export const {
  setComputerEquipments,
  setShowForm,
  setCurrentComputerEquipment,
  setComputerIsEdit,
  auditComputerEquipament,
  setComputerEquipamentMassive,
  setPaginatorEquipment,
  setFilterEquipment,
  setSortEquipment,
  setSize,
  setAdvanceSearch,
  setLoadingComputerEquipment,
} = computerEquipmentSlice.actions;

export const ComputerEquipmentSelector = (state: RootState) =>
  state.computerEquipment;

export default computerEquipmentSlice.reducer;
