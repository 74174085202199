import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL, API_REPOSITORY } from "../config/const";
import {
  ICreateATDP,
  IGetATDP,
  ISetParameters,
  IMailAtdps,
  IAtdpFile,
  IDocumentAtdps,
  IGetATDPInfo,
  IDownloadDocument,
} from "../store/models/CreateAtdp";
import { AdvanceSearch, PaginatorWithFilterAndSort } from "../store/types";
import {
  ResultResponse,
  StandardResponse,
  AtdpResponse,
  TypeAtdpDto,
  IAtdpExcel,
  PaginatorWithFilterAndSortAtdp,
  Base64Response,
  TypeAtdpEmail,
} from "../type";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json; charset=utf8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  maxContentLength: 4000000,
  maxBodyLength: 4000000,
});

const instanceRepository = axios.create({
  baseURL: API_REPOSITORY,
  headers: {
    "Content-Type": "application/json; charset=utf8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const CREATE_ATDP = "/AtdpManager/createAtdp";
const GET_ATDP = "/AtdpManager/getAtdp";
const SET_PARAMETERS = "/AtdpManager/CreateParametersAtdp";
const UPDATE_PARAMETERS = "/AtdpManager/UpdateParametersAtdp";
const GET_PARAMETERS = "/AtdpManager/getParameters";
const GET_ATDP_LIST = "/AtdpManager/listatdp";
// const GET_ATDP_DOCU = "/AtdpManager/pdfatdp";
const GET_ATDP_LIST_EXCEL = "/AtdpManager/getExcelAtdp";
const SET_ATDP_MAIL = "/AtdpManager/Mailatdp";
const DELETE_ATDP_DOCUMENT = "/documentmanager/api/DocumentManager/eliminar";
const GET_ATDP_INFO = "/AtdpManager/adtpInfo";
const ADVANCE_SEARCH_ATDP = "/AtdpManager/AndvanceSearch";
const DOWNLOAD_DOCUMENT_ATDP = "/AtdpManager/downloadDocument";

/**
 * Actualiza y retorna información de la ubicación del atdp.
 * @param { ICreateATDP } body - Base 64 y el identificador de la compañia.
 * @returns { Promise<StandardResponse<IAtdpFile>> } - Promesa con la respuesta de la actualización del atdp.
 */

export const updateATDP = async (
  body: ICreateATDP
): Promise<StandardResponse<IAtdpFile>> => {
  try {
    const response: AxiosResponse<StandardResponse<IAtdpFile>> =
      await instance.post(CREATE_ATDP, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Obtiene el atdp guardado.
 * @param { IGetATDP } body - Identificador de la compañia para realizar la busqueda.
 * @returns { Promise<StandardResponse<ResultResponse>> } - Respuesta estandar con la plantilla de la ubicación del atdp.
 */

export const getATDP = async (
  body: IGetATDP
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(GET_ATDP, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Crea la parametrización del atdp de la compañia.
 * @param { ISetParameters } body - información para creación de la parametrizació del atdp.
 * @returns { Promise<StandardResponse<ResultResponse>> } - respuesta estandar con la información de la creación de la parametrización.
 */

export const SetParametersATDP = async (
  body: ISetParameters
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await instance.post(
      SET_PARAMETERS,
      body,
      defaultHeaders()
    );
    if (response.status === 200 || response.status === 201) {
      let { data } = response;
      data.result.passwordSignOriginal = data.result.passwordSign;
      data.result.passwordStampOriginal = data.result.passwordStamp;

      if (data.result.passwordSign.length >= 150) {
        data.result.passwordSign = data.result.passwordSign.substring(0, 150);
      }
      if (data.result.passwordStamp.length >= 200) {
        data.result.passwordStamp = data.result.passwordStamp.substring(0, 200);
      }
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Actualiza la parametrización del atdp de la compañia.
 * @param { ISetParameters } body - información para actualización del atdp.
 * @returns { Promise<StandardResponse<ResultResponse>> } - respuesta con la información de la actualización de la parametrización del atdp.
 */
export const UpdateParametersATDP = async (
  body: ISetParameters
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await instance.post(
      UPDATE_PARAMETERS,
      body,
      defaultHeaders()
    );
    if (response.status === 200 || response.status === 201) {
      let { data } = response;
      data.result.passwordSignOriginal = data.result.passwordSign;
      data.result.passwordStampOriginal = data.result.passwordStamp;
      if (data.result.passwordSign.length >= 150) {
        data.result.passwordSign = data.result.passwordSign.substring(0, 150);
      }
      if (data.result.passwordStamp.length >= 200) {
        data.result.passwordStamp = data.result.passwordStamp.substring(0, 200);
      }
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Retorna parametrización del atdp si existe configuración de la compañia.
 * @param { IGetATDP } body - identificador de la compañia para realizar la consulta.
 * @returns { Promise<StandardResponse<ResultResponse>> } - respuesta estandar con los parametros de configuración.
 */

export const GetParametersATDP = async (
  body: IGetATDP
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await instance.post(
      GET_PARAMETERS,
      body,
      defaultHeaders()
    );
    if (response.status === 200 || response.status === 201) {
      let { data } = response;

      data.result.passwordSignOriginal = data.result.passwordSign;
      data.result.passwordStampOriginal = data.result.passwordStamp;
      if (data.result.passwordSign.length >= 150) {
        data.result.passwordSign = data.result.passwordSign.substring(0, 150);
      }
      if (data.result.passwordStamp.length >= 200) {
        data.result.passwordStamp = data.result.passwordStamp.substring(0, 200);
      }
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Consulta todos los atpds de la empresa.
 * @param { PaginatorWithFilterAndSort } body - información de la paginación el filtro y el ordenamiento.
 * @returns { Promise<AtdpResponse<TypeAtdpDto>> } - respuesta estandar que contiene una lista de atdps.
 */

export const GetListATDP = async (
  body: PaginatorWithFilterAndSortAtdp
): Promise<AtdpResponse<TypeAtdpDto>> => {
  try {
    const response: AxiosResponse<AtdpResponse<TypeAtdpDto>> =
      await instance.post(GET_ATDP_LIST, body, defaultHeaders());
    return response.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Obtiene informacion del atdp en formato de base 64.
 * @param { IDocumentAtdps } body - parametros para generar el base 64.
 * @returns { Promise<Base64Response<TypeAtdpDto>> } - Base 64 con la información del atdp.
 */

export const GetdocumentATDP = async (
  body: IDocumentAtdps
): Promise<Base64Response<TypeAtdpDto>> => {
  try {
    let response: AxiosResponse<Base64Response<TypeAtdpDto>> = await axios.get(
      API_REPOSITORY + "documentmanager/api/DocumentManager",
      {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Name: body.Name,
          Repository: body.Repository,
          Location: body.Location,
          ...defaultHeaders(),
        },
      }
    );

    if (response.status >= 500) {
      const bodyDownloadDocument: IDownloadDocument = {
        name: body.Name,
        route: body.Location,
      };

      response = await instance.post(
        DOWNLOAD_DOCUMENT_ATDP,
        bodyDownloadDocument,
        defaultHeaders()
      );
      return response.data;
    } else {
      const { data } = response;
      return data;
    }
  } catch (err) {
    const bodyDownloadDocument: IDownloadDocument = {
      name: body.Name,
      route: body.Location,
    };

    let response: AxiosResponse<Base64Response<TypeAtdpDto>> =
      await instance.post(
        DOWNLOAD_DOCUMENT_ATDP,
        bodyDownloadDocument,
        defaultHeaders()
      );

    return response.data;
  }
};

/**
 * Envia el documento atdp por correo al cliente
 * @param { IMailAtdps } body - lista de clientes con la información para realizar el envio del correo.
 * @returns { Promise<AtdpResponse<TypeAtdpEmail>> } - respuesta con una lista de la información de respuesta del envio del correo.
 */

export const SetMailATDPService = async (
  body: IMailAtdps
): Promise<AtdpResponse<TypeAtdpEmail>> => {
  try {
    const response: AxiosResponse<AtdpResponse<TypeAtdpEmail>> =
      await instance.post(SET_ATDP_MAIL, body, defaultHeaders());

    if (response.status === 200 || response.status === 201) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Pendiente de seguimiento para elimina por no uso.
 */

export const DeleteDocumentATDP = async (
  body: IDocumentAtdps[]
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instanceRepository.post(
        DELETE_ATDP_DOCUMENT,
        body,
        defaultHeaders()
      );
    if (response.status === 200 || response.status === 201) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Retorna los atdps para construir el archivo excel.
 * @param { PaginatorWithFilterAndSort } body - parametros de busqueda para realizar la consulta.
 * @returns { Promise<AtdpResponse<IAtdpExcel>> }  lista de los atdps que se van a descargar en un archivo excel.
 */

export const getAtdpExcelService = async (
  body: PaginatorWithFilterAndSort
): Promise<AtdpResponse<IAtdpExcel>> => {
  try {
    const response: AxiosResponse<AtdpResponse<IAtdpExcel>> =
      await instance.post(GET_ATDP_LIST_EXCEL, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Consulta el detalle del atdp y la devuelve en una lista.
 * @param { IGetATDPInfo } body - codigo qr
 * @returns { Promise<StandardResponse<ResultResponse>> } -  respuesta estandar que contiene una lista con los detalles del atdp.
 */
export const getATDPInfo = async (
  body: IGetATDPInfo
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(GET_ATDP_INFO, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Retorna los atpds con respecto al filtro general o al filtro de busqueda avanzada.
 * @param { AdvanceSearch } body - parametros para realizar la busqueda.
 * @returns { Promise<StandardResponse<ResultResponse>> } - lista de atdps.
 */

export const getAdvanceSearchAtdp = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(ADVANCE_SEARCH_ATDP, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
