import React from "react";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import NewUserIcon from "../../components/Icons/NewUserIcon";
import { NoBusinessUnitIcon } from "../../components/Icons/NoBusinessUnitIcon";
import ViewLayout from "../../components/Layouts/ViewLayout";
import MassiveLoadComponent from "../../components/MassiveLoad/MassiveLoadComponent";
import MassiveLoadInfo from "../../components/MassiveLoad/MassiveLoadInfo";
import MassiveLoadResult from "../../components/MassiveLoad/MassiveLoadResult";
import { LoadModal } from "../../components/modal/LoadModal";
import DataTable from "../../components/Table/DataTable";
import { ButtonsProps, Column } from "../../components/Table/types";
import { CustomTypography } from "../../components/typography/CustomTypography";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import { setEdit, setIsNew } from "../../store/slices/BusinessUnitSlice";
import BusinessUnitForm from "./BusinessUnitForm";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";
import useBussinessUnit from "../../hooks/useBussinessUnit";
import moment from "moment";

// rc

const getButtons = (
  dispatch: any,
  classes: any,
  privilege: Array<any>,
  handleShowForm: any,
  rol: string
) => {
  const sizebuttons = rol === "BIOMETRIA RNEC" ? 7 : 4;
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: sizebuttons,
        md: sizebuttons,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "space-evenly",
      options: [
        {
          label: "Activo",
          columns: [
            {
              prop: "state",
            },
          ],
        },
        {
          label: "Inactivo",
          columns: [
            {
              prop: "state",
              direction: -1,
            },
          ],
        },
        {
          label: "Más reciente",
          columns: [
            {
              prop: "createdOn",
              direction: 1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
        {
          label: "Más antiguo",
          columns: [
            {
              prop: "createdOn",
              direction: -1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
      ],
      cellSize: {
        lg: 2,
        md: 2,
        sm: 12,
        xs: 12,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
      fileName: "Listado de Sedes",
    },
    chargeButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    children: () => {
      return (
        <Grid
          container
          item
          lg={2}
          md={2}
          sm={12}
          xs={12}
          direction="row-reverse"
          justifyContent="center"
        >
          <Tooltip title={`Crear Sede`} aria-label={`Nueva sede`}>
            <Fab
              className={classes.styleFab}
              size="small"
              onClick={() => {
                handleShowForm(true);
                dispatch(setEdit(true));
                dispatch(setIsNew(true));
              }}
              disabled={
                privilege.find(
                  (privil: string) => privil === "BIOMETRIA_SEDE_CREATE"
                ) === undefined
                  ? true
                  : false
              }
            >
              <NewUserIcon />
            </Fab>
          </Tooltip>
        </Grid>
      );
    },
  };

  return button;
};

const notDataFound = (rol?: string) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoBusinessUnitIcon width="90px" height="90px" />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography
          type="definition"
          children={
            rol === "BIOMETRIA RNEC"
              ? "Aún no se ha creado una sede"
              : "Aún no has creado una sede"
          }
        />{" "}
      </Grid>
    </Grid>
  );
};
//#endregion

type BussinessUnitViewProps = {
  nitCompany?: string;
  tabletOnly?: boolean;
};

// VIEW
const BusinessUnitView: React.FC<BussinessUnitViewProps> = React.memo(
  ({ nitCompany, tabletOnly }) => {
    // CUSTOM HOOK
    const {
      advanceSearchState,
      businessMassiveResponse,
      businessUnits,
      cancelText,
      cancel,
      confirmText,
      confirmate,
      currentBusiness,
      fieldsAdvanced,
      imageModal,
      isLoad,
      itemsList,
      loadingBusiness,
      modal,
      messageModal,
      onDownload,
      onSave,
      onUploadDataEvent,
      onCancel,
      onContinue,
      onDownloadErrors,
      onDownloadData,
      option,
      handleChangePage,
      handleChangeTab,
      handleBack,
      handleConfirmClick,
      handleChangeRowsPerPage,
      handleFilter,
      handleSort,
      handleCellClick,
      handleOnClose,
      handleOnSearch,
      handleOnCloseLoadModal,
      handleOnLoadButton,
      handleOnOpen,
      handleShowForm,
      page,
      paginatorBussiness,
      privilege,
      rol,
      rowsPerPage,
      setPage,
      showFormAdv,
      showForm,
      statesAndCities,
      sizeBussiness,
      tab,
      titleModal,
      fieldsAdvancedSearch,
    } = useBussinessUnit({ nitCompany });
    // REDUX DISPATCH
    const dispatch = useDispatch();

    // STYLES
    const classes = useStyles();

    //#region  table configuration
    const columns: Column[] = [
      {
        id: "code",
        label: "Código",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "name",
        label: "Nombre",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "email",
        label: "Email",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "category",
        label: "Categoria",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "createdOn",
        label: "Fecha creación",
        minWidth: 50,
        align: "center",
        haveDropDown: true,
        format: (value: string) => moment(value).format("DD/MM/YYYY, H:mm:ss"),
      },
      {
        id: "state",
        label: "Estado",
        align: "center",
        haveDropDown: true,
        component: {
          handleChange: (e: any, value: any) => handleConfirmClick(e, value),
          tooltipMessage: "Tipo proceso",
          withIcon: false,
          wordLength: 60,
          handleIconClick: () => {},
        },
      },
    ];

    const TabletComponent = () => {
      return (
        <DataTable
          columns={columns}
          buttons={getButtons(
            dispatch,
            classes,
            privilege,
            handleShowForm,
            rol
          )}
          data={businessUnits}
          havePagination={sizeBussiness > 10 ? true : false}
          option={option}
          page={page}
          setPage={setPage}
          paginator={paginatorBussiness}
          size={sizeBussiness}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFilter={handleFilter}
          handleSort={handleSort}
          handleOnLoadButton={handleOnLoadButton}
          rowsPerPage={rowsPerPage}
          handleCellClick={(e, value, column) =>
            handleCellClick(e, value, column)
          }
          notDataFound={
            (paginatorBussiness.filter && businessUnits.length === 0) ||
            advanceSearchState
              ? undefined
              : notDataFound(rol)
          }
          createDisabled={
            privilege?.find((privil) => privil === "BIOMETRIA_SEDE_CREATE") ===
            undefined
              ? true
              : false
          }
          deleteDiabled={
            privilege?.find(
              (privil) => privil === "BIOMETRIA_EQUIPO_DELETE"
            ) === undefined
              ? true
              : false
          }
          modifyDiabled={
            privilege?.find(
              (privil) => privil === "BIOMETRIA_EQUIPO_MODIFY"
            ) === undefined
              ? true
              : false
          }
          readDisabled={
            privilege?.find((privil) => privil === "BIOMETRIA_EQUIPO_READ") ===
            undefined
              ? true
              : false
          }
          handleOnOpenAdvSearch={handleOnOpen}
          rol={rol}
          download={onDownloadData}
          loading={loadingBusiness}
        />
      );
    };

    //#endregion

    return (
      <div>
        {isLoad ? (
          <MassiveLoadComponent
            loadResult={
              <MassiveLoadResult
                sucessfull={businessMassiveResponse?.exitosos}
                items={itemsList}
                replaced={businessMassiveResponse?.reemplazados}
                error={businessMassiveResponse?.errores}
                onDownloadErrors={onDownloadErrors}
                onContinue={onSave}
              />
            }
            loadInfo={
              <MassiveLoadInfo
                onDownload={onDownload}
                onUpload={onUploadDataEvent}
                title={"Bienvenido, así podrás hacer el cargue de sedes"}
              />
            }
            title={"Cargue masivo de sedes"}
            handleBack={handleBack}
            handleChangeTab={handleChangeTab}
            tab={tab}
          />
        ) : showForm ? (
          <>
            <BusinessUnitForm />
          </>
        ) : !tabletOnly ? (
          <ViewLayout
            isNew={true}
            isEdit={false}
            headerTitle="Sedes"
            editOption={false}
          >
            <TabletComponent />
          </ViewLayout>
        ) : (
          <TabletComponent />
        )}
        <HashDowloandModal />
        <AdvancedSearchModal
          open={showFormAdv}
          handleOnClose={handleOnClose}
          handleOnSearch={handleOnSearch}
          currentBusiness={currentBusiness}
          statesAndCities={statesAndCities}
          fieldsAdvancedSearch={fieldsAdvancedSearch}
          fieldsAdvanced={fieldsAdvanced}
        />
        <LoadModal
          title={titleModal}
          open={modal}
          handleClose={handleOnCloseLoadModal}
          icon={<img src={imageModal} alt="hands" className={"imageInfo"} />}
          message={messageModal}
          handleConfirm={onContinue}
          handleCancel={onCancel}
          isConfirm={confirmate}
          confirmText={confirmText}
          cancelText={cancelText}
          isCancel={cancel}
        />
        <FullLoader open={loadingBusiness} viewLoader={true} />
      </div>
    );
  }
);

export { BusinessUnitView };

const useStyles = makeStyles(() => ({
  styleFab: {
    boxShadow: "none",
  },
}));
