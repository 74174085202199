import React, { useState } from "react";
import {
  CustomTypography,
  LeftMenu,
  PageBackground,
  SuiteBar,
  UserMenu,
} from "@dg-bucaramanga/react-components-dg-qa";
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { HelpOutline, DoneAllRounded } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { BIOMETRICS_LOGIN } from "../config/const";
import { authSelector, setTokens } from "../store/slices/AuthSlice";
import { CommonSelector } from "../store/slices/CommonSlice";
import {
  PersonalizationSelector,
  setBackground,
} from "../store/slices/PersonalizationSlice";
import { DRAWER_WIDTH } from "../utils/general/const";
import { Capitalize, decodeToken } from "../utils/general/utils";
import "moment/locale/es";
import useEventListener from "../hooks/useEventListener";
import DropDownNotification from "../components/Notifications/DropDownNotification";
import jwt_decode from "jwt-decode";

interface HomeProps {
  menuHide?: boolean;
}

const Home: React.FC<HomeProps> = React.memo(({ menuHide, children }) => {
  // global

  const dataSesion = decodeToken(sessionStorage.getItem("jwtToken") ?? "");

  const userInfo = {
    user: {
      enterprise: "true",
      email: "",
      name: "",
      role: [""],
      profilePicture: "",
      closeSession: () => {
        sessionStorage.clear();
        window.location.replace(BIOMETRICS_LOGIN);
      },
    },
  };

  // selectons react
  const { logo, principalColor, buttonTextColor, secondaryColor, buttonColor } =
    useSelector(PersonalizationSelector);
  const { tokens } = useSelector(authSelector);
  const { rol } = useSelector(CommonSelector);
  var urllogoGSE = "/assets/images/gseLogo.png";
  // states
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentToken, setCurrentToken] = useState(tokens);
  const [currentMenuName, setCurrentMenuName] = useState("");

  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setCurrentCustomerId } = useEventListener();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tabletAndDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();

  React.useEffect(() => {
    if (currentToken.result.token) {
      const decodedToken: any = jwt_decode(currentToken.result.token || "");
      setCurrentCustomerId(decodedToken.customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);

  React.useEffect(() => {
    // misma funcion en App.js
    let nitCompany = sessionStorage.getItem("nitCompany") || "";
    setCurrentMenuName(nitCompany);
  }, []);

  React.useEffect(() => {
    dispatch(setTokens(currentToken));
  }, [dispatch, currentToken]);

  React.useEffect(() => {
    dispatch(setBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImgHeader = (urlImg: string) => {
    return (
      <>
        <img
          src={urlImg}
          id="companyLogo"
          alt="logo"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 192,
            maxHeight: 45,
          }}
        />
      </>
    );
  };

  return (
    <div id="visorContainer">
      {currentToken.result.token ? (
        <div>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  container
                  alignItems="center"
                  item
                  lg={8}
                  md={6}
                  sm={5}
                  xs={5}
                >
                  {!!logo.name ? (
                    <>
                      {getImgHeader(urllogoGSE)}
                      <img
                        src={"data:image;base64," + logo.blob}
                        id="companyLogo"
                        alt="logo"
                        style={{
                          position: "relative",
                          marginLeft: "2em",
                          maxWidth: "90px",
                          maxHeight: "30px",
                        }}
                      />
                    </>
                  ) : (
                    <>{getImgHeader(urllogoGSE)}</>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  lg={4}
                  md={6}
                  sm={7}
                  xs={7}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item lg={1} md={1} sm={2} xs={2}>
                    <IconButton>
                      <HelpOutline />
                    </IconButton>
                  </Grid>
                  {rol === "BIOMETRIA SUPERADMIN" && (
                    <Grid item lg={1} md={2} sm={2} xs={2}>
                      <DropDownNotification />
                    </Grid>
                  )}
                  <Grid item lg={1} md={1} sm={2} xs={3}>
                    {currentToken.result.token ? (
                      <SuiteBar
                        token={currentToken.result.token}
                        appName="biometria"
                        scrollWidth={30}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                  {tabletAndDesktop ? (
                    <div
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        maxWidth: 250,
                        margin: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <CustomTypography type="definition">
                        {Capitalize(
                          dataSesion
                            ? dataSesion.given_name +
                                " " +
                                dataSesion.family_name
                            : ""
                        )}
                      </CustomTypography>
                    </div>
                  ) : null}

                  <Grid item lg={1} md={2} sm={2} xs={2}>
                    {currentToken.result.token ? (
                      <UserMenu
                        tokens={currentToken}
                        setTokens={setCurrentToken}
                        appName="biometria"
                        userInfo={userInfo}
                        functions={[]}
                        viewLoader={true}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {rol !== "BIOMETRIA CAPTURADOR INTEGRACION" ? (
            !menuHide ? (
              <>
                <nav className={classes.drawer}>
                  <Hidden lgUp>
                    <LeftMenu
                      appName="biometria"
                      open={true}
                      drawerProps={{
                        classes: {
                          paper: classes.drawer,
                        },
                        variant: "temporary",
                        anchor: theme.direction === "rtl" ? "right" : "left",
                        open: mobileOpen,
                        onClose: handleDrawerToggle,
                        ModalProps: {
                          keepMounted: true, // Mejora el rendimiento de apertura en dispositivos móviles.
                        },
                      }}
                      token={currentToken.result.token}
                      functions={[]}
                      color={{
                        primaryColor: principalColor,
                        secondaryColor: secondaryColor,
                        textButtonColor: buttonTextColor,
                        buttonColor: buttonColor,
                      }}
                    />
                  </Hidden>
                  <Hidden mdDown>
                    <LeftMenu
                      appName="biometria"
                      open={true}
                      drawerProps={{
                        variant: "permanent",
                        classes: {
                          paper: classes.drawer,
                        },
                        open: true,
                      }}
                      token={currentToken.result.token}
                      functions={[]}
                      color={{
                        primaryColor: principalColor,
                        secondaryColor: secondaryColor,
                        textButtonColor: buttonTextColor,
                        buttonColor: buttonColor,
                      }}
                    />
                  </Hidden>
                </nav>
                {children}
              </>
            ) : (
              <>{children}</>
            )
          ) : (
            <Box className={classes.container}>
              <Grid
                className={classes.content}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid className={classes.header} item>
                  <DoneAllRounded />
                </Grid>
                <Grid className={classes.body} item>
                  <Typography>
                    La asignación de su contraseña fue exitosa
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      ) : (
        <PageBackground />
      )}
    </div>
  );
});

export default Home;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
  },
  menuButton: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  container: {
    position: "absolute",
    padding: "20px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "40%",
    height: "40%",
    borderRadius: "20px",
    backgroundColor: theme.palette.common.white,
  },
  content: {
    height: "100%",
  },
  header: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.main,
      fontSize: "10rem",
    },
  },
  body: {
    "& .MuiTypography-root": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      textAlign: "center",
    },
  },
}));
