import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentUser,
  UserSelector,
  setPaginatorUser,
  setAdvanceSearch,
} from "../store/slices/UserSlice";
import { FieldsAdvanceSearch, sortBy, User } from "../store/types";
import {
  getUsersAC,
  getFilterAC,
  getPaginatorAC,
  getSortAC,
  updateUserStateAC,
  advanceSearchCsvAC,
  advanceSearchAC,
  updateStateUserByIdAC,
} from "../store/action-creators/UserActionCreators";

import {
  CommonSelector,
  setPrivilege,
  setRol,
} from "../store/slices/CommonSlice";
import { Column } from "../components/Table/types";
import { UpdateState } from "../store/models/UpdateState";
import { updateUserStateSecurity } from "../services/UsersService";
import { useSnackbar } from "notistack";

type UsersProps = {
  nitCompany?: string;
  tabletOnly?: boolean;
};
/**
 * Campos que se utilizan en el buscador avanzado
 */
const fieldsAdvanced: FieldsAdvanceSearch[] = [
  {
    name: "UserName",
    title: "Nombre de usuario",
    type: "text",
    value: "",
  },
  {
    name: "Name",
    title: "Nombres",
    type: "text",
    value: "",
  },
  {
    name: "Surname",
    title: "Apellidos",
    type: "text",
    value: "",
  },
  {
    name: "DocumentNumber",
    title: "Número de documento",
    type: "text",
    value: "",
  },
  {
    name: "Email",
    title: "Email",
    type: "text",
    value: "",
  },
  {
    name: "CreationDate",
    title: "Fecha de creación",
    type: "date",
    value: undefined,
  },
  {
    name: "State",
    title: "Estado",
    type: "status",
    value: undefined,
  },
];
/**
 * Retorna todas las funciones y estados que utiliza el módulo
 * @param { string } nitCompany - Nit de la empresa
 */
const useUsers = ({ nitCompany }: UsersProps) => {
  // Redux
  const dispatch = useDispatch();
  const { users, paginatorUser, size, advanceSearch } =
    useSelector(UserSelector);
  const { loading, privilege, rol } = useSelector(CommonSelector);
  // Custom Hook
  const { enqueueSnackbar } = useSnackbar();
  // Estados locales
  const [showFormAdv, setShowFormAdv] = useState(false);
  const [advSearchState, setAdvSearchState] = useState(false);
  const [actualPage, setActualPage] = useState(1);

  const { paginator, filter, sort } = paginatorUser;

  /**
   * Obtiene los usuarios con la búsqueda normal
   */
  const getAllUsers = () => {
    fieldsAdvanced.forEach((item) => {
      if (typeof item.value != "undefined" && item.value)
        setAdvSearchState(true);
    });
    if (advSearchState) {
      dispatch(
        advanceSearchAC({
          fields: fieldsAdvanced,
          paginator: {
            ...paginatorUser,
            paginator: { ...paginator, page: actualPage },
          },
          company: nitCompany,
        })
      );
    } else {
      dispatch(
        getUsersAC(
          { paginator: { ...paginator, page: actualPage }, sort, filter },
          nitCompany
        )
      );
    }
  };

  /**
   * Asegura que cada vez que entre al módulo el filtro este vacio,
   * en la primera página y el ordenamiento sea reciente
   */
  useEffect(() => {
    dispatch(getPaginatorAC(1, 10, true));
    dispatch(setAdvanceSearch(fieldsAdvanced));
    return () => {
      dispatch(getFilterAC(""));
      dispatch(getSortAC("recent"));
      dispatch(getPaginatorAC(1, 10, true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!rol.length) {
      dispatch(setRol(""));
    }
  }, [rol, dispatch]);

  useEffect(() => {
    if (!privilege.length) {
      dispatch(setPrivilege(""));
    }
  }, [privilege, dispatch]);

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPage, filter, sort, paginator]);
  /**
   * Maneja el click cuando cambia de página
   * @param { any } _event
   * @param { any } newPage - página actual
   */
  const handleClickPaginator = (_event: any, newPage: any) => {
    dispatch(
      setPaginatorUser({
        page: newPage + 1,
        itemsPage: paginator.itemsPage,
        recharge: true,
      })
    );
    setActualPage(newPage + 1);
  };
  /**
   * Maneja la cantidad de datos mostrados en la tabla
   * @param { any } event - datos del componente
   */
  const handleChangeRowsPerPage = (event: any) => {
    dispatch(
      getPaginatorAC(paginatorUser.paginator.page, +event.target.value, true)
    );
  };
  /**
   * Obtiene la información del usuario mediante la información de la tabla
   * @param { React.MouseEvent<HTMLSpanElement, MouseEvent> } e - datos enviados por la tabla
   * @param { any } value - información del usuario
   * @param { Column } _column - información de la columna
   */
  const handleClickUser = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    _column: Column
  ) => {
    const currentUs: User = {
      documentNumber: value.documentNumber,
      firstName: value.firstName,
      userName: value.userName,
      lastName: value.lastName,
      email: value.email,
      state: value.state,
      id: value.id,
    };
    dispatch(setCurrentUser(currentUs));
  };
  /**
   * Maneja la busqueda normal y actualiza la tabla de dispositivos del modulo
   * @param { string } query - Texto a buscar
   */
  const handleFilter = (query: string) => {
    setAdvSearchState(false);
    dispatch(setAdvanceSearch(fieldsAdvanced));
    setActualPage(1);
    if (filter !== query) {
      dispatch(getFilterAC(query.trim()));
    }
    if (filter === "" && query === "") {
      dispatch(getFilterAC(query + " "));
    }
  };
  /**
   * MAneja la busqueda avanzada y actualiza la tabla de dispositivos del módulo
   * @param { FieldsAdvanceSearch[] } fieldsPassedAdvancedSearch - campos y valores de la busqueda avanzada
   */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAC(""));
    dispatch(
      advanceSearchAC({
        fields: fieldsPassedAdvancedSearch,
        paginator: {
          paginator: {
            ...paginatorUser.paginator,
            page:
              paginatorUser.paginator.page > 1
                ? 1
                : paginatorUser.paginator.page,
          },
          sort: paginatorUser.sort,
        },
        company: nitCompany,
      })
    );
    setAdvSearchState(true);
    setShowFormAdv(false);
    dispatch(setAdvanceSearch(fieldsPassedAdvancedSearch));
  };
  /**
   * Cierra el formulario para creación o edición de un dispositivo
   */
  const handleOnClose = () => {
    setShowFormAdv(false);
  };
  /**
   * Activa o desactiva el estado del usuario en los proyecto de Seguridad y Biometria
   * @param { any } e - datos enviados por la columna
   * @param { any } value - valor actual del estado del usuario
   */
  const handleSetState = (e: any, value: any) => {
    const userState = users.find((u) => u.id === value);
    const rnecUserStatus = {
      true: "ACTIVO",
      false: "BLOQUEADO_RNEC",
    };
    if (userState) {
      updateUserStateSecurity({
        userName: userState.userName,
        userState: e.target.checked
          ? rnecUserStatus.true
          : rnecUserStatus.false,
      }).then((response) => {
        if (response.statusCode === 200 || response.statusCode === 201) {
          const state: UpdateState = {
            id: value,
            state: !e.target.checked,
            paginator: {
              page: actualPage,
              itemsPage: paginatorUser.paginator.itemsPage,
              recharge: true,
            },
          };
          dispatch(updateUserStateAC(state));
          dispatch(updateStateUserByIdAC(value));
        } else {
          enqueueSnackbar(response.result.information, {
            variant: "error",
          });
        }
      });
    } else {
      enqueueSnackbar("Hay un error con éste usuario", {
        variant: "error",
      });
    }
  };
  /**
   * Maneja el tipo de ordenamiento y actualiza la tabla segun este
   * @param { sortBy } sort - tipo de ordenamiento
   */
  const handleSort = (sort?: sortBy) => {
    if (sort) {
      dispatch(getSortAC(sort));
      dispatch(
        getPaginatorAC(actualPage, paginatorUser.paginator.itemsPage, true)
      );
    }
  };
  /**
   * Descarga archivo CSV según el criterio de busqueda actual
   */
  const onDownloadData = () => {
    dispatch(
      advanceSearchCsvAC({
        fields: advanceSearch,
        paginator: {
          ...paginatorUser,
          paginator: { ...paginator, page: actualPage },
        },
        company: nitCompany,
      })
    );
  };

  return {
    actualPage,
    advSearchState,
    fieldsAdvanced,
    handleChangeRowsPerPage,
    handleClickPaginator,
    handleClickUser,
    handleFilter,
    handleOnClose,
    handleOnSearch,
    handleSetState,
    handleSort,
    loading,
    onDownloadData,
    paginatorUser,
    privilege,
    rol,
    setShowFormAdv,
    showFormAdv,
    advanceSearch,
    size,
    users,
  };
};

export default useUsers;
