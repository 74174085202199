import { Grid } from "@material-ui/core";
import { NoComputerIcon } from "../../../components/Icons/NoComputerIcon";
import { CustomTypography } from "../../../components/typography/CustomTypography";
/**
 * Retorna componente con mensaje cuando no encuentra o no tiene dispositivos
 * @returns { JSX.Element } - Mensaje cuando no hay dispositivos
 */
const notDataFound = (rol?: string) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoComputerIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography
          type="definition"
          children={
            rol === "BIOMETRIA RNEC"
              ? "Aún no se ha creado un equipo"
              : "Aún no has creado un equipo"
          }
        />{" "}
      </Grid>
    </Grid>
  );
};

export default notDataFound;
