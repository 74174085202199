import React from "react";
import { useForm } from "react-hook-form";
import { Theme } from "@material-ui/core";
import { Grid, Typography, Container, makeStyles } from "@material-ui/core";
import { InputRounded } from "../Inputs/InputRounded";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MESSAGES } from "../../utils/types/const";
import { useSnackbar } from "notistack";
import { IDataFingerFNA } from "../../store/models/FingerPrintModel";
import { IDataATDPFNAINT } from "../../store/models/CreateAtdp";

const useStyles = makeStyles((_theme: Theme) => ({
  formParticipants: {
    boxShadow: "0px 3px 6px #00000033",
    border: "1px solid #009BDD",
    padding: "20px",
    borderRadius: "10px",
    margin: 0,
    marginBottom: "20px",
  },

  inputs: {
    marginBottom: "10px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
  },
  titleParticipant: {
    font: "normal normal bold 18px/23px Muli",
    color: "#009BDD",
    marginBottom: 20,
  },
}));

interface FingerFormDataProps {
  handleValidateProp: Function;
  isOpen: boolean;
  dataParticipant?: IDataATDPFNAINT;
}

interface IFingerPrintForm {
  documentType: string;
  documentNumber: string;
}

const FingerPrintSchema = yup.object().shape({
  documentType: yup
    .string()
    .max(100, `${MESSAGES.max} 100 caracteres`)
    .required(MESSAGES.required)
    .trim(),
  documentNumber: yup
    .string()
    .min(6, `${MESSAGES.min} 6 digitos`)
    .required(MESSAGES.required),
});

const FingerFormData_FNA_INT = ({
  handleValidateProp,
  isOpen,
  dataParticipant,
}: FingerFormDataProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  //#region  states
  const {
    control,
    formState: { errors },
  } = useForm<IFingerPrintForm>({
    mode: "onChange",
    resolver: yupResolver(FingerPrintSchema),
    shouldUnregister: false,
  });

  const [dataFormFNA, setDataFormFNA] = React.useState<IDataFingerFNA>({
    id: 0,
    documentType: "",
    documentNumber: "",
  });

  React.useEffect(() => {
    validate(1);
  }, [dataFormFNA]);

  React.useEffect(() => {
    if (dataParticipant) {
      setDataFormFNA({
        id: 1,
        documentType: dataParticipant?.documentType,
        documentNumber: dataParticipant?.documentNumber,
      });
    }
  }, [dataParticipant]);

  const validate = (id: any) => {
    const response = FingerPrintSchema.isValidSync(dataFormFNA);

    handleValidateProp({
      idParticipant: id,
      stateForm: response,
    });
  };

  return (
    <>
      {isOpen ? (
        <form>
          <Container className={classes.formParticipants}>
            <Typography className={classes.titleParticipant}>
              Candidato
            </Typography>
            <Grid container item spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    name="documentType"
                    label="Tipo de documento"
                    props={{
                      name: "documentType",
                      helperText: errors.documentType?.message,
                      error: errors.documentType ? true : false,
                      disabled: true,
                      autoComplete: "on",
                      value: dataFormFNA.documentType,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    name="documentNumber"
                    label="Número de documento"
                    props={{
                      name: "documentNumber",
                      helperText: errors.documentNumber?.message,
                      error: errors.documentNumber ? true : false,
                      disabled: true,
                      autoComplete: "on",
                      value: dataFormFNA.documentNumber,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </form>
      ) : (
        <></>
      )}
    </>
  );
};
export default FingerFormData_FNA_INT;
