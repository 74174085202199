import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { PrimaryButton } from "../button/PrimaryButton";

interface AlertIconModalProps {
  open: boolean;
  handleClose: Function;
  icon: JSX.Element;
  message: string;
}

/**
 * @description Componente que renderiza el modal para mostrar las alertas de biometria
 * @param { boolean } param.open Parametro de tipo boolean que representa el estado de apertura del modal
 * @param { Function } param.handleClose Parametro de tipo Function que representa que retorna al componente padre la accion de estado de apertura del modal
 * @param { JSX.Element } param.icon Parametro de tipo JSX.Element que representa que representa el icono de estado de la alerta
 * @param { string } param.message Parametro de tipo string que representa el mensaje de la alerta
 * @returns { React.FC<AlertIconModalProps> } AlertIconModalProps
 */
export const AlertIconModal: React.FC<AlertIconModalProps> = ({
  open,
  handleClose,
  icon,
  message,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="custom-dialog"
    >
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1} className={classes.cursorProps}>
          <Close color="primary" onClick={() => handleClose()} />
        </Grid>
      </Grid>

      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="center"
            className={classes.icon}
          >
            {icon}
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="center"
            className={classes.message}
          >
            <Typography
              component="h1"
              color="textPrimary"
              className={classes.messageTypography}
            >
              {message}
            </Typography>
          </Grid>
          <Grid className={classes.buttonSuccess}>
            <PrimaryButton
              title={"Aceptar"}
              props={{ onClick: () => handleClose() }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => ({
  icon: {
    height: 80,
    width: 92,
    margin: 10,
  },
  dialogTitle: {
    height: 12,
  },
  message: {
    marginBottom: 15,
  },
  messageTypography: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: 0,
    padding: "8px 25px",
  },
  buttonSuccess: {
    width: "147px",
    height: "40px",
    margin: 10,
    fontSize: "18px",
  },
  cursorProps: {
    cursor: "pointer",
    height: "15px",
    padding: "15px 0",
  },
}));
