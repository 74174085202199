import axios, { AxiosResponse } from "axios";
import { BIOMETRICS_URL } from "../config/const";
import {
  BusinessUnitMassiveResponse,
  IBusinessUnit,
  ICreateBusinessUnit,
  ICreateBusinessUnitParametrization,
  ILoadBusinessUnit,
  IParametrization,
  UpdateInherit,
} from "../store/models/BusinessUnit";
import { ListBasicResponse } from "../store/models/ListBasicResponse";
import { UpdateState } from "../store/models/UpdateState";
import {
  IAudit,
  IBusinessUnitExcel,
  PaginatorWithFilterAndSort,
  AdvanceSearch,
} from "../store/types";
import { defaultHeaders, getCurrentJWT } from "../config/config";
import {
  ResponseTemplate,
  ResultEntityResponse,
  ResultResponse,
  StandardResponse,
} from "../type";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const ADVANCE_SEARCH = "BusinessUnit/AdvanceSearch";
const ADVANCE_SEARCH_CSV = "BusinessUnit/AdvanceSearchCsv";
const BUSINESS_UNITS = "/BusinessUnit/list";
const BUSINESS_TEMPLATE = "/BusinessUnit/BusinessMassiveTemplate";
const BUSINESS_UNITS_CREATE = "/BusinessUnit/create";
const BUSINESS_UNITS_PARAMETRIZATION = "/Parametrization";
const BUSINESS_UNITS_INHERIT = "/BusinessUnit/inheritSetting";
const BUSINESS_UNIT_STATE = "BusinessUnit/State";
const BUSINESS_UNIT_AUDIT = "BusinessUnit/AuditBusinessUnit";
const BUSINESS_UNIT_UPDATE = "BusinessUnit/UpdateBusinessUnit";
const BUSINESS_UNIT_VALIDATE_LOAD = "BusinessUnit/BusinessMassive";
const BUSINESS_UNIT_LOAD = "BusinessUnit/save";
const BUSINESS_UNIT_PARAMETRIZATION_AUDIT = "Parametrization/AuditSetting";
const BUSINESS_UNIT_EXEL = "BusinessUnit/getAllBusinessUnitsExcel";

export const getAllBusinessUnits = async (
  body: PaginatorWithFilterAndSort,
  company?: string
): Promise<StandardResponse<ListBasicResponse<IBusinessUnit>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IBusinessUnit>>
    > = await instance.post(
      BUSINESS_UNITS,
      { ...body, company },
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMassiveBusinessTemplate = async (): Promise<
  StandardResponse<ResponseTemplate>
> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.get(BUSINESS_TEMPLATE, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBusinessUnitState = async (
  body: UpdateState
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(BUSINESS_UNIT_STATE, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

export const createBusinessUnit = async (
  body: ICreateBusinessUnit
): Promise<StandardResponse<ResultEntityResponse<IBusinessUnit>>> => {
  try {
    var response: AxiosResponse<
      StandardResponse<ResultEntityResponse<IBusinessUnit>>
    > = await instance.post(BUSINESS_UNITS_CREATE, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveMassiveLoad = async (
  body: Array<ILoadBusinessUnit>
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(BUSINESS_UNIT_LOAD, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validateLoadStatus = async (
  body: Array<ILoadBusinessUnit>
): Promise<StandardResponse<BusinessUnitMassiveResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<BusinessUnitMassiveResponse>> =
      await instance.post(BUSINESS_UNIT_VALIDATE_LOAD, body, defaultHeaders());
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const createBusinessUnitParametrization = async (
  body: ICreateBusinessUnitParametrization
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(
        BUSINESS_UNITS_PARAMETRIZATION,
        body,
        defaultHeaders()
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBusinessUnitParametrization = async (
  body: ICreateBusinessUnitParametrization
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.put(
        BUSINESS_UNITS_PARAMETRIZATION,
        body,
        defaultHeaders()
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBusinessUnitInherit = async (
  body: UpdateInherit
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(BUSINESS_UNITS_INHERIT, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBusinessUnitParametrization = async (
  companyId: string,
  businessId?: number,
  currentBusinessId?: number,
  enableSwitch?: number
): Promise<StandardResponse<ResultEntityResponse<IParametrization>>> => {
  try {
    let headers = {};
    if (businessId) {
      headers = {
        companyId,
        businessId,
        currentBusinessId,
        enableSwitch,
        ...getCurrentJWT(),
      };
    } else {
      headers = {
        companyId,
        currentBusinessId,
        enableSwitch,
        ...getCurrentJWT(),
      };
    }

    const response: AxiosResponse<StandardResponse<any>> = await instance.get(
      BUSINESS_UNITS_PARAMETRIZATION,
      {
        headers: headers,
      }
    );

    if (response.status === 200 || response.status === 201) {
      let { data } = response;

      data.result.parameters.passwordSignOriginal =
        data.result.parameters.passwordSign;
      data.result.parameters.passwordStampOriginal =
        data.result.parameters.passwordStamp;

      if (
        !!data.result.parameters.passwordSign &&
        data.result.parameters.passwordSign.length >= 150
      ) {
        data.result.parameters.passwordSign =
          data.result.parameters.passwordSign.substring(0, 150);
      }
      if (
        !!data.result.parameters.passwordStamp &&
        data.result.parameters.passwordStamp.length >= 200
      ) {
        data.result.parameters.passwordStamp =
          data.result.parameters.passwordStamp.substring(0, 200);
      }

      return data;
    } else {
      throw Error(`[requestClient] Request failed with reason -  `);
    }
  } catch (error) {
    throw error;
  }
};

export const getBusinessUnitAuditService = async (
  body: IBusinessUnit
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(
      BUSINESS_UNIT_AUDIT,
      body,
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    return error;
  }
};

export const updateBusinessUnitService = async (
  body: IBusinessUnit
): Promise<StandardResponse<ResultEntityResponse<IBusinessUnit>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ResultEntityResponse<IBusinessUnit>>
    > = await instance.put(BUSINESS_UNIT_UPDATE, body, defaultHeaders());
    return response.data;
  } catch (error: any) {
    return error;
  }
};

export const getParametrizationAuditService = async (
  body: IBusinessUnit
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(
      BUSINESS_UNIT_PARAMETRIZATION_AUDIT,
      body,
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    return error;
  }
};

export const getAllBusinessUnitsExcelService = async (
  advanceSearch?: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<IBusinessUnitExcel>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IBusinessUnitExcel>>
    > = await instance.post(
      BUSINESS_UNIT_EXEL,
      advanceSearch,
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const advanceSearch = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<IBusinessUnit>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IBusinessUnit>>
    > = await instance.post(ADVANCE_SEARCH, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const advanceSearchCsv = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
