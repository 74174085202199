import {
  createBusinessUnit,
  createBusinessUnitParametrization,
  getAllBusinessUnits,
  getBusinessUnitAuditService,
  getBusinessUnitParametrization,
  getMassiveBusinessTemplate,
  getParametrizationAuditService,
  saveMassiveLoad,
  updateBusinessUnitInherit,
  updateBusinessUnitParametrization,
  updateBusinessUnitService,
  validateLoadStatus,
  advanceSearch,
  advanceSearchCsv,
} from "../../services/BusinessUnitService";
import {
  IBusinessUnit,
  ICreateBusinessUnit,
  ICreateBusinessUnitParametrization,
  ILoadBusinessUnit,
  UpdateInherit,
} from "../models/BusinessUnit";
import {
  auditBusinessUnits,
  auditParametrization,
  setBusinessMassive,
  setBusinessUnits,
  setCurrentBusinessUnit,
  setFilterBussiness,
  setPaginatorBussiness,
  setParametrization,
  setSizeBussiness,
  setSortBussiness,
  setLoadingBusiness,
  setIsSuccess,
  setShowModal,
  setLoadingBusinessForm,
} from "../slices/BusinessUnitSlice";
import { setBusinessUnitEdited } from "../slices/CommonListsSlice";
import { setError, setHashCsv, setHashCsvModal } from "../slices/CommonSlice";
import { AppThunk } from "../store";
import { PaginatorWithFilterAndSort, sortBy, AdvanceSearch } from "../types";

/**
 * @function getBusinessUnitsAC
 * get business units and save them to storage
 */
export const getBusinessUnitsAC =
  (
    paginator: PaginatorWithFilterAndSort,
    company?: string,
    loader?: boolean
  ): AppThunk =>
  (dispatch) => {
    if (loader !== false) dispatch(setLoadingBusiness(true));
    getAllBusinessUnits(paginator, company)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setBusinessUnits(response.result.records));
          dispatch(setSizeBussiness(response.result.count));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => dispatch(setError(err)))
      .finally(() => dispatch(setLoadingBusiness(false)));
  };

export const getPaginatorBussinessAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorBussiness({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

export const getFilterBussinessAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterBussiness(filter));
  };

export const getSortBussinessAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortBussiness(sort));
  };

/**
 * @function getMassiveBusinessTemplateAC
 * gets the template for bulk business loading
 */
export const getMassiveBusinessTemplateAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingBusiness(true)) &&
    getMassiveBusinessTemplate()
      .then((response) => {
        if (response.statusCode === 200) {
          const mimeType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const linkSource = `${mimeType}${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName =
            response.result.nameFile + "." + response.result.extensionFile;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          dispatch(setError(response.statusMessage));
        }
      })
      .catch((err) => dispatch(setError(err)))
      .finally(() => dispatch(setLoadingBusiness(false)));
};

/**
 *
 * @param body
 * save a new business unit
 */
export const createBusinessUnitAC =
  (
    body: ICreateBusinessUnit,
    paginator: PaginatorWithFilterAndSort
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusinessForm(true)) &&
      createBusinessUnit(body)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setIsSuccess(response.result.information));
            dispatch(
              setCurrentBusinessUnit({
                ...response.result.entityDto,
                city: body.city,
                department: body.department,
              })
            );
            dispatch(
              setParametrization(response.result.entityDto.parametrization)
            );
          } else {
            dispatch(setIsSuccess(response.result.information));
            dispatch(setShowModal(true));
            dispatch(setError(response.result.information));
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => dispatch(setLoadingBusinessForm(false)));
  };
/**
 *
 * @param body
 * save load massive business unit
 */
export const saveMassiveLoadAC =
  (body: Array<ILoadBusinessUnit>, onResponse: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      saveMassiveLoad(body)
        .then((response) => {
          if (response.statusCode === 200) {
            onResponse(true);
            return true;
          } else {
            onResponse(false);
            return false;
          }
        })
        .catch((err) => {
          console.error(err);
          return false;
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };
/**
 *
 * @param body
 * validate load massive business unit
 */
export const validateLoadStatusAC =
  (body: Array<ILoadBusinessUnit>): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true));
    return validateLoadStatus(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setBusinessMassive(response.result));
        } else {
          dispatch(setError(response.statusMessage));
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => dispatch(setLoadingBusiness(false)));
  };
/**
 *
 * @param body
 * save parametrizatrion by business unit
 */
export const createBusinessUnitParametrizationAC =
  (body: ICreateBusinessUnitParametrization): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusinessForm(true)) &&
      createBusinessUnitParametrization(body)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setParametrization({ ...body.parameters }));
          }
          dispatch(setIsSuccess(response.result.information));
          dispatch(setLoadingBusinessForm(false));
          dispatch(setShowModal(true));
        })
        .catch((err) => console.error(err))
        .finally(() => null);
  };

/**
 *
 * @param body
 * update parametrization by business unit
 */
export const updateBusinessUnitParametrizationAC =
  (body: ICreateBusinessUnitParametrization): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusinessForm(true)) &&
      updateBusinessUnitParametrization(body)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setParametrization({ ...body.parameters }));
          }
          dispatch(setIsSuccess(response.result.information));
          dispatch(setLoadingBusinessForm(false));
          dispatch(setShowModal(true));
        })
        .catch((err) => console.error(err))
        .finally(() => null);
  };

/**
 *
 * @param companyId
 * @param businessId
 * @param currentBusinessId
 * get parametrization by business unit
 */
export const getParametrizationAC =
  (
    companyId: string,
    businessId?: number,
    currentBusinessId?: number,
    enableSwitch?: number
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      getBusinessUnitParametrization(
        companyId,
        businessId,
        currentBusinessId,
        enableSwitch
      )
        .then((response: any) => {
          if (response.statusCode === 200) {
            dispatch(setParametrization(response.result.parameters));
          } else {
            dispatch(setError(response.result.information));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoadingBusiness(false)));
  };

/**
 *
 * @param body
 * update business unit inherit state
 */
export const updateBusinessUnitInheritAC =
  (body: UpdateInherit): AppThunk =>
  (dispatch) => {
    updateBusinessUnitInherit(body)
      .then((response) => {
        // dispatch(setSuccess(response.result.information));
        // dispatch(setIsSuccess(response.result.information));
        // dispatch(setShowModal(true));
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };

export const businessUnitAuditAC =
  (body: IBusinessUnit): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      getBusinessUnitAuditService(body)
        .then((data) => {
          const audit = data.result;
          dispatch(auditBusinessUnits(audit));
        })
        .catch((err) => {
          dispatch(setError("Ha ocurrido un error"));
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };

export const businessUnitAudateAC =
  (body: IBusinessUnit, editId: number): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      updateBusinessUnitService(body)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setIsSuccess(response.result.information));
            dispatch(setShowModal(true));
            dispatch(
              setCurrentBusinessUnit({
                ...response.result.entityDto,
                city: body.city,
                department: body.department,
              })
            );
            dispatch(setBusinessUnitEdited(editId));
          } else {
            dispatch(setIsSuccess(response.result.information));
            dispatch(setShowModal(true));
          }
        })
        .catch((error) => {
          dispatch(setError("Ha ocurrido un error al actualizar la sede"));
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };

export const businessUnitParametrizationAuditAC =
  (body: IBusinessUnit): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      getParametrizationAuditService(body)
        .then((data) => {
          const audit = data.result;
          dispatch(auditParametrization(audit));
        })
        .catch((error) => {
          dispatch(setError("Ha ocurrido un error al consultar la auditoría"));
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };

export const advanceSearchAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      advanceSearch(body)
        .then((data) => {
          const { records, count } = data.result;
          dispatch(setBusinessUnits(records));
          dispatch(setSizeBussiness(count));
        })
        .catch((error) => {
          const { data } = error;
          const { information } = data.result;
          dispatch(setError(information));
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };

export const advanceSearchCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingBusiness(true)) &&
      advanceSearchCsv(body)
        .then((response) => {
          if (response.statusCode === 200) {
            const mimeType = "data:application/zip;base64,";
            const linkSource = `${mimeType}${response.result.downloadRoute}`;
            const downloadLink = document.createElement("a");
            const fileName = response.result.nameFile + ".zip";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            dispatch(setHashCsv(response.result.hash));
            dispatch(setHashCsvModal(true));
          }
        })

        .catch((error) => {
          const { data } = error;
          const { information } = data.result;
          dispatch(setError(information));
        })
        .finally(() => dispatch(setLoadingBusiness(false)));
  };
