import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import chargeBusiness from '../../assets/chargeBusinessInfo.png';
import iconDownload from '../../assets/Icondownload.png';
import iconUpload from '../../assets/Iconupload.png';
import { DRAWER_WIDTH } from "../../utils/general/const";
import { useRef } from "react";
import { PrimaryButton } from "../../components/button/PrimaryButton";

type MassiveLoadInfoProps = {
    title: string,
    onDownload: (any),
    onUpload: (any),
}

const MassiveLoadInfo = (props:MassiveLoadInfoProps) => {
    const theme = useTheme();
    const fileUpload = useRef(document.createElement("input"));
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const drawerWidth = !isMobile ? DRAWER_WIDTH : 0;
    const padding = !isMobile ? "0px 40px 40px 40px" : 0;
    const classes = useStyles({
        drawerWidth: drawerWidth,
        padding: padding,
        isMobile,
    });

    const upload = () => {
        fileUpload.current.click();
    }

    return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                    <div className={classes.title}>{props.title}</div>
                    <br></br>
                    <img src={chargeBusiness} alt="hands" style={{width:"100%"}} />
                </Grid>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                    <Grid container spacing={2} justifyContent="space-evenly" direction="row">
                        <Grid item>
                            <PrimaryButton title={'Descargar plantilla'} props={{ onClick: props.onDownload, startIcon: <img src={iconDownload} alt="hands" className={'imageInfo'} />, style: { textTransform: "capitalize" } }}></PrimaryButton>
                        </Grid>
                        <Grid item>
                            <PrimaryButton title={'Cargar plantilla masiva'} props={{ onClick: upload, startIcon: <img src={iconUpload} alt="hands" className={'imageInfo'} />, style: { textTransform: "capitalize" } }}></PrimaryButton>
                            <input
                                type="file"
                                accept=".xlsx"
                                ref={fileUpload}
                                onChange={(e) => props.onUpload(e)}
                                hidden
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>        
    );
};

export default MassiveLoadInfo;

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1
    },
    title: (props: any) => ({
        fontSize: props.isMobile ? "22px" : "24px",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        padding: "10px 0px 10px 0px",
        marginTop: 10,
    }),
}));