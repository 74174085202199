import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../models/NotificationModel";
import { RootState } from "./rootReducer";

export interface NotificationsState {
  notifications: INotification[] | [],
  loadingNotification: boolean,
}

const initialState: NotificationsState = {
  notifications: [],
  loadingNotification: false,
}

const notificationsSlice = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    setNotifications: (state, { payload }: PayloadAction<INotification[]>) => {
      state.notifications = payload;
    },
    setLoadingNotification:(state, { payload }: PayloadAction<boolean>) => {
      state.loadingNotification = payload;
    },
  }
});

export const { setNotifications, setLoadingNotification } = notificationsSlice.actions;

export const NotificationSelector = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;