import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { ResponseTemplate, TypeProcessExcel } from "../../type";
import { IAudit, Paginator, sortBy, TypeProcessState } from "../types";
import { RootState } from "./rootReducer";
import { TypeProcessMassiveResponse } from "../models/TypeProcessUnit";
import {
  ITypeProcessViewCapturadorResponse,
  ITypeProcessViewResponse,
  ITypeProcessViewRnecResponse,
} from "../models/TypeProcessView";

const initialState: TypeProcessState = {
  isLoading: false,
  error: "",
  showForm: false,
  currentTypeProcess: undefined,
  typeProcesses: [],
  typeProcessAudit: undefined,
  typeProcessMassiveResponse: undefined,
  paginatorTypeProcess: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    sort: "recent",
  },
  sizeTypeProcess: 0,
  loadingTypeProcess: false,
};

const typeProcessSlice = createSlice({
  name: "type-process",
  initialState,
  reducers: {
    setCurrentTypeProcess: (
      state,
      { payload }: PayloadAction<ITypeProcessViewResponse | undefined>
    ) => {
      state.currentTypeProcess = payload;
    },
    setShowForm: (state, showForm: PayloadAction<boolean>) => {
      state.showForm = showForm.payload;
    },
    setSuccess: (state, success: PayloadAction<string>) => {
      state.success = success.payload;
    },
    setError: (state, haveError: PayloadAction<string>) => {
      state.error = haveError.payload;
    },
    setLoading: (state, isLoading: PayloadAction<boolean>) => {
      state.isLoading = isLoading.payload;
    },
    TypeProcesses: (
      state,
      typeProcesses: PayloadAction<
        | ITypeProcessViewResponse[]
        | ITypeProcessViewCapturadorResponse[]
        | ITypeProcessViewRnecResponse[]
      >
    ) => {
      state.typeProcesses = typeProcesses.payload;
    },
    createTypeProcess: (
      state,
      typeProcess: PayloadAction<ITypeProcessViewResponse>
    ) => {
      state.typeProcesses = state.typeProcesses.concat(typeProcess.payload);
    },
    auditTypeProcess: (state, typeProcessAudit: PayloadAction<IAudit>) => {
      state.typeProcessAudit = typeProcessAudit.payload;
    },
    setTypeProcessMassiveResponse: (
      state,
      { payload }: PayloadAction<TypeProcessMassiveResponse | undefined>
    ) => {
      state.typeProcessMassiveResponse = payload;
    },
    setPaginatorTypeProcess: (state, { payload }: PayloadAction<Paginator>) => {
      state.paginatorTypeProcess.paginator = payload;
    },
    setSizeTypeProcess: (state, { payload }: PayloadAction<number>) => {
      state.sizeTypeProcess = payload;
    },
    setFilterTypeProcess: (state, { payload }: PayloadAction<string>) => {
      state.paginatorTypeProcess.filter = payload;
    },
    setLoadingTypeProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingTypeProcess = payload;
    },
    setsortTypeProcess: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorTypeProcess.sort = payload;
    },
  },
});

export const {
  TypeProcesses,
  createTypeProcess,
  setLoading,
  setError,
  setSuccess,
  setCurrentTypeProcess,
  setShowForm,
  auditTypeProcess,
  setTypeProcessMassiveResponse,
  setPaginatorTypeProcess,
  setSizeTypeProcess,
  setsortTypeProcess,
  setFilterTypeProcess,
  setLoadingTypeProcess,
} = typeProcessSlice.actions;

export const TypeProcessSelector = (state: RootState) => state.typeProcess;

export default typeProcessSlice.reducer;
