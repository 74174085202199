export const NoTypeProcessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <defs>
        <style>{`.a,.c{fill:#fff;}.a{stroke:#b2b2b2;}.b{fill:#b2b2b2;}.d{fill:#f2f2f2;}.e{fill:#e6e6e6;}.f,.g{fill:#fa5a1d;}.f{stroke:#fff;stroke-width:3px;}.h,.j{stroke:none;}.i{fill:none;}.j{fill:#fff;}`}</style>
      </defs>
      <g transform="translate(-744.999 -382)">
        <g transform="translate(745.354 382)">
          <g className="a" transform="translate(-0.354)">
            <circle className="h" cx="40" cy="40" r="40" />
            <circle className="i" cx="40" cy="40" r="39.5" />
          </g>
          <g transform="translate(10.641 16.994)">
            <path
              className="b"
              d="M237.961,99.176l-1.841.475-22.683,5.85-1.841.475a3.486,3.486,0,0,0-2.5,4.241l7.984,30.958a3.486,3.486,0,0,0,4.241,2.5h0l26.356-6.8h0a3.486,3.486,0,0,0,2.5-4.241L242.2,101.678A3.486,3.486,0,0,0,237.961,99.176Z"
              transform="translate(-208.978 -99.06)"
            />
            <path
              className="c"
              d="M251.063,113l-2.181.562-22,5.674-2.181.562a2.482,2.482,0,0,0-1.782,3.02l7.984,30.958a2.482,2.482,0,0,0,3.02,1.782h0l26.356-6.8h0a2.482,2.482,0,0,0,1.782-3.02l-7.984-30.958A2.482,2.482,0,0,0,251.063,113Z"
              transform="translate(-221.829 -111.912)"
            />
            <path
              className="d"
              d="M409.957,237.928l-13.333,3.438a.58.58,0,1,1-.29-1.123l13.333-3.438a.58.58,0,0,1,.29,1.123Z"
              transform="translate(-382.37 -226.817)"
            />
            <path
              className="d"
              d="M419.027,255.864,403.379,259.9a.58.58,0,0,1-.29-1.123l15.649-4.036a.58.58,0,1,1,.29,1.123Z"
              transform="translate(-388.635 -243.455)"
            />
            <path
              className="d"
              d="M440.226,355.3l-13.333,3.438a.58.58,0,0,1-.29-1.123l13.333-3.438a.58.58,0,0,1,.29,1.123Z"
              transform="translate(-410.448 -335.698)"
            />
            <path
              className="d"
              d="M449.3,373.238l-15.649,4.035a.58.58,0,1,1-.29-1.123l15.649-4.036a.58.58,0,0,1,.29,1.123Z"
              transform="translate(-416.714 -352.336)"
            />
            <path
              className="d"
              d="M470.494,472.675l-13.333,3.438a.58.58,0,1,1-.29-1.123l13.333-3.438a.58.58,0,1,1,.29,1.123Z"
              transform="translate(-438.526 -444.579)"
            />
            <path
              className="d"
              d="M479.564,490.611l-15.649,4.035a.58.58,0,0,1-.29-1.123l15.649-4.036a.58.58,0,1,1,.29,1.123Z"
              transform="translate(-444.792 -461.217)"
            />
            <path
              className="e"
              d="M295.623,287.944l-4.718,1.217a.278.278,0,0,1-.339-.2l-1.083-4.2a.278.278,0,0,1,.2-.339l4.718-1.217a.278.278,0,0,1,.339.2l1.083,4.2A.278.278,0,0,1,295.623,287.944Z"
              transform="translate(-283.645 -269.868)"
            />
            <path
              className="e"
              d="M325.892,405.318l-4.718,1.217a.278.278,0,0,1-.339-.2l-1.083-4.2a.278.278,0,0,1,.2-.339l4.718-1.217a.278.278,0,0,1,.339.2l1.083,4.2A.278.278,0,0,1,325.892,405.318Z"
              transform="translate(-311.723 -378.75)"
            />
            <path
              className="e"
              d="M356.16,522.691l-4.718,1.217a.278.278,0,0,1-.339-.2l-1.083-4.2a.278.278,0,0,1,.2-.339l4.718-1.217a.278.278,0,0,1,.339.2l1.083,4.2A.278.278,0,0,1,356.16,522.691Z"
              transform="translate(-339.801 -487.631)"
            />
            <path
              className="b"
              d="M469.9,209.052H442.675a3.486,3.486,0,0,0-3.482,3.482V244.5a3.486,3.486,0,0,0,3.482,3.482H469.9a3.486,3.486,0,0,0,3.482-3.482V212.534A3.486,3.486,0,0,0,469.9,209.052Z"
              transform="translate(-422.531 -201.088)"
            />
            <path
              className="c"
              d="M482.755,222.906H455.527a2.482,2.482,0,0,0-2.48,2.48v31.971a2.482,2.482,0,0,0,2.48,2.48h27.228a2.482,2.482,0,0,0,2.48-2.48V225.385A2.482,2.482,0,0,0,482.755,222.906Z"
              transform="translate(-435.383 -213.94)"
            />
            <path
              className="e"
              d="M623.034,450.847H609.265a.58.58,0,0,1,0-1.16h13.769a.58.58,0,1,1,0,1.16Z"
              transform="translate(-579.759 -424.313)"
            />
            <path
              className="e"
              d="M625.426,477.895H609.265a.58.58,0,0,1,0-1.16h16.161a.58.58,0,0,1,0,1.16Z"
              transform="translate(-579.759 -449.404)"
            />
            <path
              className="e"
              d="M623.034,572.06H609.265a.58.58,0,1,1,0-1.16h13.769a.58.58,0,0,1,0,1.16Z"
              transform="translate(-579.759 -536.756)"
            />
            <path
              className="e"
              d="M625.425,599.108H609.265a.58.58,0,1,1,0-1.16h16.161a.58.58,0,0,1,0,1.16Z"
              transform="translate(-579.759 -561.847)"
            />
            <path
              className="e"
              d="M508.032,442.3H503.16a.278.278,0,0,1-.278-.278v-4.337a.278.278,0,0,1,.278-.278h4.873a.278.278,0,0,1,.278.278v4.337A.278.278,0,0,1,508.032,442.3Z"
              transform="translate(-481.611 -412.923)"
            />
            <path
              className="e"
              d="M508.032,563.516H503.16a.278.278,0,0,1-.278-.278V558.9a.278.278,0,0,1,.278-.278h4.873a.278.278,0,0,1,.278.278v4.337A.278.278,0,0,1,508.032,563.516Z"
              transform="translate(-481.611 -525.366)"
            />
            <path
              className="e"
              d="M669.627,315.912H659.476a.58.58,0,1,1,0-1.16h10.151a.58.58,0,0,1,0,1.16Z"
              transform="translate(-626.338 -299.141)"
            />
            <path
              className="e"
              d="M672.019,342.96H659.476a.58.58,0,1,1,0-1.16h12.543a.58.58,0,1,1,0,1.16Z"
              transform="translate(-626.338 -324.232)"
            />
            <path
              className="e"
              d="M512.307,289.834h-9.359a.278.278,0,0,1-.278-.278v-7.3a.278.278,0,0,1,.278-.278h9.359a.278.278,0,0,1,.278.278v7.3A.278.278,0,0,1,512.307,289.834Z"
              transform="translate(-481.415 -268.734)"
            />
          </g>
          <g transform="translate(49.656 22.311)">
            <g className="f" transform="translate(0 29.002)">
              <ellipse
                className="h"
                cx="5.366"
                cy="5.366"
                rx="5.366"
                ry="5.366"
              />
              <ellipse
                className="i"
                cx="5.366"
                cy="5.366"
                rx="6.866"
                ry="6.866"
              />
            </g>
            <g className="g" transform="translate(-372.186 -195.944)">
              <path
                className="h"
                d="M 382.13525390625 224.0639953613281 L 382.1344299316406 224.0639953613281 L 382.1336059570312 224.0639953613281 C 380.4476928710938 224.0621490478516 378.8435363769531 223.2511596679688 377.8424682617188 221.8945922851562 C 376.8414306640625 220.5379028320312 376.5396728515625 218.7658843994141 377.0352783203125 217.1544036865234 L 383.2005920410156 197.1017303466797 C 383.9230651855469 194.7517242431641 385.8730163574219 193.01025390625 388.2895202636719 192.5569000244141 C 388.6900634765625 192.4817504882812 389.0994262695312 192.4436492919922 389.5062561035156 192.4436492919922 C 391.5351867675781 192.4436492919922 393.4208374023438 193.3565368652344 394.6797485351562 194.9482269287109 C 396.2048950195312 196.8765411376953 396.532470703125 199.4703216552734 395.53466796875 201.7173767089844 L 387.0205078125 220.8908538818359 C 386.1619262695312 222.8190155029297 384.2445983886719 224.0639953613281 382.13525390625 224.0639953613281 Z"
              />
              <path
                className="j"
                d="M 382.13525390625 222.5639953613281 C 383.6547241210938 222.5639953613281 385.0316162109375 221.6698913574219 385.6495971679688 220.2820892333984 L 394.1637573242188 201.1086120605469 C 394.9322509765625 199.3779907226562 394.6779174804688 197.3639373779297 393.5032348632812 195.8787384033203 C 392.3285217285156 194.3935241699219 390.4270935058594 193.6820373535156 388.5661010742188 194.0311737060547 C 386.7049560546875 194.3803405761719 385.1907958984375 195.7325744628906 384.6343383789062 197.5425415039062 L 378.468994140625 217.5953369140625 C 378.1110229492188 218.7593078613281 378.326416015625 220.0240631103516 379.0494384765625 221.0039367675781 C 379.7725219726562 221.9838256835938 380.91748046875 222.5626678466797 382.13525390625 222.5639953613281 M 382.13525390625 225.5639953613281 L 382.1319885253906 225.5639953613281 C 379.9725036621094 225.5616302490234 377.9177551269531 224.5228424072266 376.6355285644531 222.7852325439453 C 375.3532409667969 221.0474090576172 374.9667358398438 218.7776336669922 375.6015319824219 216.7134704589844 L 381.7668151855469 196.6609039306641 C 382.6535949707031 193.7765350341797 385.0469665527344 191.6390533447266 388.012939453125 191.0826110839844 C 388.5044250488281 190.9904022216797 389.0068664550781 190.9436492919922 389.5062561035156 190.9436492919922 C 390.7138671875 190.9436492919922 391.9278564453125 191.2206573486328 393.0170288085938 191.7447357177734 C 394.1169738769531 192.2740173339844 395.0987548828125 193.0599975585938 395.8562316894531 194.0177001953125 C 397.7282104492188 196.384521484375 398.1302795410156 199.5681304931641 396.9055786132812 202.3261413574219 L 388.3914184570312 221.4996185302734 C 387.29150390625 223.9697418212891 384.8363037109375 225.5639953613281 382.13525390625 225.5639953613281 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
