import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NoDeviceIcon } from "../components/Icons/NoDeviceIcon";
import { Column } from "../components/Table/types";
import {
  getAtdpExcelService,
  SetMailATDPService,
} from "../services/CreateATDP";
import {
  GetBase64EmailATDPAC,
  GetBase64PDFATDPAC,
  GetFilterATDPAC,
  GetListATDPAC,
  GetPaginatorATDPAC,
  GetSortATDPAC,
} from "../store/action-creators/CreateAtdpActionCreators";
import {
  IDocumentAtdps,
  IListAtdps,
  IListChecks,
  IMailParamAtdps,
  IModalAlert,
} from "../store/models/CreateAtdp";
import {
  AtdpSelector,
  setCheckboxListChecks,
  setDowloandPdfBase64Atdp,
  setListAtdp,
  setLoadingAtdp,
  setMailAtdp,
  setSendEmailBase64Atdp,
} from "../store/slices/AtdpSlice";
import { CommonSelector, setLoading } from "../store/slices/CommonSlice";
import { FieldsAdvanceSearch, sortBy } from "../store/types";
import { advanceSearchHasValues, createExcel } from "../utils/general/utils";
import { formatDateIso } from "../utils/general/formatDate";

interface SearchAtdpProps {
  nitCompany: string | undefined;
}
const useSearchAtdp = ({ nitCompany }: SearchAtdpProps) => {
  const dispatch = useDispatch();
  const {
    listAtdp,
    mailAtdp,
    paginatorAtdp,
    sizeAtdp,
    checkboxListChecks,
    dowloandPdfBase64Atdp,
    sendEmailBase64Atdp,
    loadingAtdp,
  } = useSelector(AtdpSelector);
  const { loading, privilege, rol } = useSelector(CommonSelector);

  //Regions states
  const [option, setOption] = useState(null);
  const [forShippingCode, setForShippingCode] = useState<IListChecks[]>([]);
  const [updateEmail, setUpdateEmail] =
    useState<IListChecks[]>(checkboxListChecks);
  const [finaMail, setFinaMail] = useState(false);
  const [finaMailMany, setFinaMailMany] = useState(false);
  const [modal, setModal] = useState<IModalAlert>({
    state: false,
    message: "",
    icon: <></>,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeAllCheckbox, setActiveAllCheckbox] = useState(false);
  const [stateParametersATDP, setStateParametersATDP] = useState<
    IMailParamAtdps[]
  >([]);
  const [isOpenModalEmail, setIsOpenModalEmail] = useState(false);
  let newarray: IMailParamAtdps[] = [...stateParametersATDP];

  const [showFormAtdp, setShowFormAtdp] = useState(false);

  //Nombres, Apellidos, No Documento, Correo Electrónico, Nut, Fecha y hora
  const fieldsAdvanced: FieldsAdvanceSearch[] = [
    {
      name: "Nombres",
      title: "Nombres",
      type: "text",
      value: "",
    },
    {
      name: "Apellidos",
      title: "Apellidos",
      type: "text",
      value: "",
    },
    {
      name: "NoDocumento",
      title: "No Documento",
      type: "text",
      value: "",
    },
    {
      name: "CorreoElectrónico",
      title: "Correo Electrónico",
      type: "text",
      value: "",
    },
    {
      name: "Nut",
      title: "Nut",
      type: "text",
      value: "",
    },
    {
      name: "CreationDate",
      title: "Fecha de creación",
      type: "date",
      value: undefined,
    },
  ];
  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    useState(fieldsAdvanced);

  const nameDate = "CreationDate";

  //#region  handling Effects
  /**
   * Asegura que cada vez que entre al módulo el filtro este vacio,
   * en la primera página y el ordenamiento sea reciente
   */
  useEffect(() => {
    return () => {
      dispatch(GetFilterATDPAC(""));
      dispatch(GetSortATDPAC("recent"));
      dispatch(GetPaginatorATDPAC(1, 10, true));
      dispatch(setDowloandPdfBase64Atdp([]));
      dispatch(setCheckboxListChecks([]));
      dispatch(GetPaginatorATDPAC(1, paginatorAtdp.paginator.itemsPage, true));
    };
  }, [dispatch]);

  /**
   * Este efecto se activa cuando cambia el valor de mailAtdp y verifica su longitud para actualizar el estado finaMail.
   * @param {boolean} mailAtdp - El valor de mailAtdp que se está observando.
   */

  useEffect(() => {
    if ((mailAtdp.length ? mailAtdp.length : 0) > 0) {
      setFinaMail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!mailAtdp]);

  /**
   * Este efecto se activa cuando cambia el valor de mailAtdp.
   */
  useEffect(() => {
    // verifica si el tamaño enviado al servicio de envio de correos es igual al tamaño de stateParametersATDP
    if (mailAtdp.length === stateParametersATDP.length) {
      // se desactiva el loading
      dispatch(setLoadingAtdp(false));
      // actualiza estado para apertura del modal.
      updateEmail.length > 0 ? setFinaMailMany(true) : setFinaMail(true);
      // limpieza de estados
      setUpdateEmail([]);
      setStateParametersATDP([]);
      dispatch(setSendEmailBase64Atdp([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailAtdp.length]);

  /**
   * Este efecto se activa cuando hay un cambio en el estado del paginador de atdp.
   */

  useEffect(() => {
    fillAllAtdp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorAtdp]);

  /**
   * obtiene la lista de atdps dependiendo los filtros de busqueda.
   * @function
   */

  const fillAllAtdp = () => {
    const { paginator, filter, sort } = paginatorAtdp;

    dispatch(
      GetListATDPAC(
        {
          ...paginatorAtdp,
          paginator: paginator,
          filter: filter,
          company: nitCompany,
          sort: sort,
          /**
           * Verifica que no hayan busquedas (normal y avanzada)
           */
          fieldsAdvanceSearch:
            filter!.length < 3 &&
            !advanceSearchHasValues(fieldsAdvancedSearch, nameDate)
              ? fieldsAdvancedSearch.map((field: FieldsAdvanceSearch) => {
                  if (field.name === nameDate) {
                    field = {
                      ...field,
                      value: `${formatDateIso()}:${formatDateIso()}`,
                    };
                    return field;
                  }
                  return field;
                })
              : fieldsAdvancedSearch,
        },
        checkboxListChecks
      )
    );
  };
  /**
   * Esta función procesa arreglos de datos de correos electrónicos y actualiza un nuevo arreglo con elementos modificados.
   * Recorre los arreglos de entrada y aplica ciertas condiciones para actualizar el contenido del correo electrónico.
   * Después de actualizar el arreglo, establece el estado y el estado de carga.
   * @function
   */

  const fillSendingMail = () => {
    // eslint-disable-next-line array-callback-return
    sendEmailBase64Atdp.map((itemState, indexState) => {
      if (!!updateEmail && updateEmail.length > 0) {
        // eslint-disable-next-line array-callback-return
        updateEmail.map((itemUpdate) => {
          if (itemState.IdAtdp === itemUpdate.atdpId) {
            let element = { ...itemState, email: itemUpdate.email };
            newarray.splice(indexState, 1, element);
          }
        });
      } else if (!!forShippingCode && forShippingCode.length === 1) {
        // eslint-disable-next-line array-callback-return
        forShippingCode.map((itemShipping) => {
          let element: IMailParamAtdps = {
            IdAtdp: itemShipping.atdpId,
            email: itemShipping.email,
            base64: itemState.base64,
            names: itemShipping.names,
            surnames: itemShipping.surnames,
            messageStatus: itemState.messageStatus,
            codeStatus: itemState.codeStatus,
          };
          newarray.splice(indexState, 1, element);
        });
      }
    });
    setStateParametersATDP(newarray);
    loadingAtdp && dispatch(setLoadingAtdp(false));
  };

  /**
   *  Efecto que verifica si el envio fue individual o multiple y ejecuta la función fillSendingMail.
   */

  useEffect(() => {
    if (
      !!checkboxListChecks &&
      checkboxListChecks.length > 0 &&
      checkboxListChecks.length === sendEmailBase64Atdp.length
    ) {
      fillSendingMail();
    } else if (
      !!checkboxListChecks &&
      checkboxListChecks.length === 0 &&
      sendEmailBase64Atdp.length
    ) {
      fillSendingMail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEmail, forShippingCode, sendEmailBase64Atdp]);

  /**
   * Efecto que se ejecuta cuando hay un cambio en la lista dowloandPdfBase64Atdp
   * Realizando la descarga del atdp en pdf.
   */

  useEffect(() => {
    if (!!dowloandPdfBase64Atdp && !!dowloandPdfBase64Atdp.length) {
      // eslint-disable-next-line array-callback-return
      dowloandPdfBase64Atdp.map((item: { base64: any; atdpName: any }) => {
        if (item.base64 !== "") {
          const link = `data:application/pdf;base64,${item.base64}`;
          const downloadLink = document.createElement("a");
          const fileName = item.atdpName;
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
      dispatch(setDowloandPdfBase64Atdp([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dowloandPdfBase64Atdp]);

  //#region  handling events
  const handleCellClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    column: Column
  ) => {
    //pendiente si se requiere
  };

  /**
   * Actualiza la lista de atps de la pagina siguiente.
   * @param { any } _event - datos del componente.
   * @param { any } newPage - página actual.
   * @function
   */

  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
    dispatch(
      GetPaginatorATDPAC(newPage + 1, paginatorAtdp.paginator.itemsPage, true)
    );
    if (activeAllCheckbox) {
      setActiveAllCheckbox(false);
      dispatch(setCheckboxListChecks([]));
    }
  };

  /**
   * Maneja la cantidad de datos mostrados en la tabla.
   * @param { any } event - datos del componente.
   * @function
   */

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(GetPaginatorATDPAC(1, +event.target.value, true));
  };

  /**
   * Maneja la busqueda normal y actualiza la tabla de dispositivos del modulo.
   * @param { string } query - texto a buscar.
   * @function
   */

  const handleFilter = (query: string) => {
    setFieldsAdvancedSearch(fieldsAdvanced);
    dispatch(GetFilterATDPAC(query.trim()));
    dispatch(GetPaginatorATDPAC(1, paginatorAtdp.paginator.itemsPage, true));
  };

  /**
   * Cambia el tipo de ordenamiento.
   * @param { sortBy } sort - tipo de ordenamiento seleccionado.
   * @function
   */

  const handleSort = (sort?: sortBy) => {
    if (sort === "active") {
      dispatch(GetSortATDPAC("a-z"));
    } else if (sort === "inactive") {
      dispatch(GetSortATDPAC("z-a"));
    } else if (sort === "recent") {
      dispatch(GetSortATDPAC("recent"));
    } else {
      dispatch(GetSortATDPAC("old"));
    }
    dispatch(GetPaginatorATDPAC(1, paginatorAtdp.paginator.itemsPage, true));
  };

  /**
   * Actualiza el estado en redux del atdp seleccionado o deseleccionado.
   * @param { number } id - identificador del atdp.
   * @param { boolean } state - estado del atdp.
   * @function
   */

  const activeCheckbox = (id: number, state: boolean) => {
    let listBD: IListAtdps[] = [...listAtdp];
    let newarray: IListChecks[] = [...checkboxListChecks];
    let element = listAtdp[id];

    if (
      element.atdpName !== "" &&
      element.route !== "" &&
      Number(element.repository) >= 0
    ) {
      if (!state) {
        newarray.push({
          atdpId: element.atdpId,
          atdpName: element.atdpName,
          names: element.names,
          surnames: element.surnames,
          email: element.email,
          repository: element.repository,
          route: element.route,
        });
      } else {
        removeItemFromArr(newarray, element.atdpId);
      }
      // eslint-disable-next-line array-callback-return
      listBD.map((item) => {
        if (item.atdpId === element.atdpId) {
          element = { ...item, stateChecked: !state };
        }
      });
    } else {
      setModal({
        state: true,
        message: "¡Falta información para consultar documento ATDP!",
        icon: <NoDeviceIcon />,
      });
    }
    listBD.splice(id, 1, element);
    dispatch(setListAtdp(listBD));
    dispatch(setCheckboxListChecks(newarray));
    setUpdateEmail([...newarray]);
  };

  /**
   * Elimina un elemento específico de la lista de atdps.
   * @param {Array} lista - La lista de elementos de la cual se eliminará el elemento.
   * @param {number} elemento - El elemento que se eliminará de la lista.
   * @function
   */

  const removeItemFromArr = (lista: any[], elemento: number) => {
    var item = lista.indexOf(elemento);
    lista.splice(item, 1);
  };

  /**
   * Actualiza el estado en redux de todos los atdps de la pantalla.
   * Verificando si cumple con los criterios de consulta del atdp.
   * @param { number } id - identificador del atdp.
   * @param { boolean } state - estado del atdp.
   * @function
   */

  const handleAllActiveCheckbox = (state: boolean) => {
    let listBD: IListAtdps[] = [...listAtdp];
    let newArray: IListChecks[] = [];
    let flag = 0;

    listBD.forEach((item, index) => {
      if (
        item.atdpName !== "" &&
        item.route !== "" &&
        Number(item.repository) >= 0
      ) {
        if (!state) {
          newArray.push({
            atdpId: item.atdpId,
            atdpName: item.atdpName,
            names: item.names,
            surnames: item.surnames,
            email: item.email,
            repository: item.repository,
            route: item.route,
          });
        } else {
          newArray = [];
          setActiveAllCheckbox(false);
        }
        let element = { ...item, stateChecked: !state };
        listBD.splice(index, 1, element);
      } else {
        flag++;
      }
    });
    if (flag > 0) {
      setModal({
        state: true,
        message: `¡Se encontró ${flag} registro(s) sin información para consultar documento(s) ATDP!`,
        icon: <NoDeviceIcon />,
      });
    }
    dispatch(setListAtdp(listBD));
    dispatch(setCheckboxListChecks(newArray));
    setUpdateEmail([...newArray]);
  };

  /**
   * Maneja la obtención de datos en formato base64 y envío por correo electrónico.
   * Itera sobre una lista de elementos de casillas de verificación y realiza las siguientes acciones:
   * Abre un modal de correo electrónico.
   * Llama a la función de despacho 'GetBase64EmailATDPAC' para obtener datos en formato base64 y enviarlos por correo.
   * @function
   */

  const handleGetBase64 = () => {
    checkboxListChecks.forEach((item) => {
      const flag = {
        Location: item.route,
        Name: item.atdpName,
        Repository: item.repository,
      };
      setIsOpenModalEmail(true);
      dispatch(GetBase64EmailATDPAC(flag, item));
    });
  };

  /**
   * Verifica el mensaje principal del modal si es para envio de correo individual o masivo.
   * @returns { string } - el mensaje correspondiente a la validación.
   * @function
   */
  const textModalSendEmail = () => {
    let message = "";
    if (forShippingCode.length === 1) {
      message = `La Autorización de Tratamiento de Datos Personales de ${
        forShippingCode[0].names + " " + forShippingCode[0].surnames
      } será enviada a`;
    } else if (checkboxListChecks.length > 0) {
      message =
        "La Autorización de Tratamiento de Datos Personales será enviada a los siguientes correos:";
    }
    return message;
  };

  /**
   * Lanza una acción para obtener un PDF en formato base64 mediante parámetros específicos.
   * @param {IDocumentAtdps} par1 - objeto que representa los parámetros del documento.
   * @param {string} par2 - cadena que especifica algún tipo de información relacionada.
   * @function
   */

  const throwBase64 = (par1: IDocumentAtdps, par2: string) => {
    dispatch(GetBase64PDFATDPAC(par1, par2));
  };

  /**
   * Lanza una acción para obtener un archivo en formato base64 para enviar por correo electrónico.
   * @param {IDocumentAtdps} par1 - Objeto que representa los parámetros del documento.
   * @param {any} par2 - información del cliente.
   * @function
   */

  const throwBase64Email = (par1: IDocumentAtdps, par2: any) => {
    dispatch(GetBase64EmailATDPAC(par1, par2));
  };

  /**
   * Limpia los estados al abandonar el proceso de envio de correos.
   * @function
   */

  const cancelSend = () => {
    setIsOpenModalEmail(false);
    setForShippingCode([]);
    setUpdateEmail([]);
    setStateParametersATDP([]);
    let listBD: IListAtdps[] = [...listAtdp];
    // eslint-disable-next-line array-callback-return
    listBD.map((item, index) => {
      let element = { ...item, stateChecked: false };
      listBD.splice(index, 1, element);
    });
    dispatch(setListAtdp(listBD));
    dispatch(setSendEmailBase64Atdp([]));
    dispatch(setCheckboxListChecks([]));
  };

  /**
   * Recibe la lista de atdps para enviar al servicio y realizar el envio por correo.
   * @param { IMailParamAtdps[] } list - lista de atpds listos para ser enviado por correo.
   * @function
   */

  const listToSendAndSetAnswer = (list: IMailParamAtdps[]) => {
    SetMailATDPService({
      DataClient: list,
    })
      .then((response) => {
        if (response.informationCode === "A10") {
          dispatch(setMailAtdp(response.records));
        } else {
          dispatch(setMailAtdp(response.records));
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(setMailAtdp([]));
        dispatch(setLoadingAtdp(false));
      });
  };

  /**
   * Desestructura un array de parámetros de correo y los envía por partes al servicio.
   * @function
   * @param {IMailParamAtdps[]} listAtdp - Un array de parámetros de correo.
   * @param {number} amount - La cantidad de elementos a procesar en cada fragmento.
   */

  const destructuringListToSend = (
    listAtdp: IMailParamAtdps[],
    amount: number
  ) => {
    let acumulator: number = 0;
    while (acumulator < listAtdp.length) {
      const listToSend = listAtdp.splice(acumulator, amount);
      listToSendAndSetAnswer(listToSend);
    }
  };

  /**
   * Funcion que confirma el envio de correos, enviando de a 10 atdps por llamado.
   * @function
   */

  const confirmSend = () => {
    dispatch(setLoadingAtdp(true));
    const list = stateParametersATDP.slice();
    destructuringListToSend(list, 10);
    setIsOpenModalEmail(false);
    setActiveAllCheckbox(false);
  };
  /**
   * Función que asigna un array vacio como estado inicial de modal
   * @function
   */

  const setInitialInputModalState = () => {
    dispatch(setMailAtdp([]));
  };
  /**
   *
   * Descarga archivo excel con condiciones de busqueda u de elementos seleccionados.
   * Si no hay elementos seleccionados descarga toda la lista de atdps correspondiente a la compañia.
   * @function
   */

  const onDownloadData = () => {
    const { filter } = paginatorAtdp;
    let listChecks: any[] = [];
    let dataExcel: any[] = [];

    checkboxListChecks.forEach((item, index) => {
      listChecks.splice(index, 1, { idAtdp: item.atdpId });
    });

    dispatch(setLoading(true));
    getAtdpExcelService({
      ...paginatorAtdp,
      checkListId: listChecks,
      /**
       * Verifica que no hayan busquedas (normal y avanzada)
       */
      fieldsAdvanceSearch:
        filter!.length < 3 &&
        !advanceSearchHasValues(fieldsAdvancedSearch, nameDate)
          ? fieldsAdvancedSearch.map((field: FieldsAdvanceSearch) => {
              if (field.name === nameDate) {
                field = {
                  ...field,
                  value: `${formatDateIso()}:${formatDateIso()}`,
                };
                return field;
              }
              return field;
            })
          : fieldsAdvancedSearch,
      company: nitCompany,
    })
      .then((response) => {
        if (response.informationCode === "A10") {
          dataExcel = response.records.map((item) => {
            return {
              ...item,
            };
          });
          createExcel(dataExcel, "Listado de Atdp", rol);
        }
      })
      .catch((err: string) => {
        console.error(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  /**
   * Maneja el evento cuando es necesario cerrar el formulario de Atdp (Búsqueda Avanzada).
   * @function
   */

  const handleOnClose = () => {
    setShowFormAtdp(false);
  };

  /**
   * Maneja el evento de búsqueda con campos de búsqueda avanzada.
   * @function
   * @param {FieldsAdvanceSearch[]} fieldsPassedAdvancedSearch - Un arreglo de campos de búsqueda avanzada.
   */

  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(GetFilterATDPAC(""));
    setShowFormAtdp(false);
    setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    dispatch(GetPaginatorATDPAC(1, paginatorAtdp.paginator.itemsPage, true));
  };

  /**
   * Maneja el evento cuando es necesario abrir el formulario de Atdp (Búsqueda Avanzada).
   * @function
   */

  const handleOnOpen = () => {
    setShowFormAtdp(true);
  };

  return {
    confirmSend,
    textModalSendEmail,
    handleGetBase64,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleAllActiveCheckbox,
    loadingAtdp,
    forShippingCode,
    finaMail,
    finaMailMany,
    setFinaMailMany,
    setFinaMail,
    setForShippingCode,
    modal,
    activeCheckbox,
    setModal,
    throwBase64,
    throwBase64Email,
    setIsOpenModalEmail,
    activeAllCheckbox,
    listAtdp,
    setActiveAllCheckbox,
    checkboxListChecks,
    sizeAtdp,
    option,
    setOption,
    page,
    rowsPerPage,
    setPage,
    handleFilter,
    handleSort,
    paginatorAtdp,
    onDownloadData,
    isOpenModalEmail,
    setUpdateEmail,
    updateEmail,
    cancelSend,
    setInitialInputModalState,
    loading,
    fieldsAdvanced,
    fieldsAdvancedSearch,
    setFieldsAdvancedSearch,
    showFormAtdp,
    setShowFormAtdp,
    privilege,
    rol,
    handleOnClose,
    handleOnSearch,
    handleOnOpen,
  };
};

export default useSearchAtdp;
