import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Cards } from "../../../components/Card/Cards";
import { RoundedCard } from "../../../components/Card/RoundedCard";
import DougnutHits from "../../../components/Charts/Doughnuts/HitNoHitNovelties";
import VerticalBarHits from "../../../components/Charts/VerticalBar/HitNoHitNovelties";
import { SelectInputRounded } from "../../../components/Inputs/SelectInputRounded";
import DateTextField from "../../../components/Inputs/DateTextField";
import {
  DonutProgress,
  HorizontalChart,
  LinearPercentBar,
  LinearPercentLabel,
} from "../../../components/ProgressBar";

import { useDateTextField } from "../../../hooks/useDateTextField";

import {
  getCustomerConsumptionAC,
  getDailycomparisonAverageAC,
  getRecordsByCompanyAndBusinessunitAC,
  getUserRecordsAC,
  getAverageByBusinessunitAC,
  getDoughnutTransactionAC,
  getRecordsTypeProcessCompanyAndBusinessunitAC,
  getCustomerLicenseAC,
} from "../../../store/action-creators/DashboardActionCreators";
import {
  DashboardAverage,
  DataCompaniesForChart,
  DataCotejo,
  ICustomerConsumption,
  SelectOption,
  defaultDashboardAverage,
} from "../../../store/models/DashboardModel";
import { DashboardSelector } from "../../../store/slices/DashboardSlice";
import formatDate, {
  formatDateIso,
} from "../../../utils/general/formatDate";
import { MESSAGES } from "../../../utils/types/const";
import { CompanySelector } from "../../../store/slices/CompanySlice";
import { CommonSelector } from "../../../store/slices/CommonSlice";
import useDashboard from "../../../hooks/useDashboard";

const defaultValuesDataCotejo: DataCotejo = {
  name: "",
  totalBolsa: 0,
  consumo: 0,
  width: 130,
  fontSizeConsumo: 30,
  fontSizeTotal: 15,
  percentage: 0,
  expirationDate: "",
};

interface IAdminView {
  DocumentType?: string;
  DocumentNumber?: string;
  ServiceName: string;
  QuantityCustomer?: number;
  EffectiveDate?: string;
  Total?: number
  Role?: string | undefined
}

/**
 * @description This component show all Admin dashboard items, only it works when user is "BIOMETRIA ADMIN" role
 * @param { string } DocumentType
 * @param { string } ServiceName - Service name should be 'Biometria' because this information would send to license api
 * @param { string } DocumentNumber - Document number is the document number identification business
 * @returns { JSX.Element } Element that represents the dashboard cards for the Admin user
 */
const Admin: React.FC<IAdminView> = ({
  DocumentType,
  ServiceName,
  DocumentNumber,
  Role,
}) => {
  // dispatch REDUX
  const dispatch = useDispatch();
  // REDUX selectors
  const {
    customerConsumption,
    businessUnitRecords,
    userRecords,
    dasboardCapturador,
    typeProcessCompanyBusinessUnits,
    businessUnitAverage,
    customerConsumptionMessage,
    doughnutTransactions,
    verticalBarTransactions,
  } = useSelector(DashboardSelector);

  const { loading } = useSelector(CommonSelector);
  const { license } = useSelector(CompanySelector);
  const { switchCompany } = useDashboard();

  // React states
  const [dataCotejo, setDataCotejo] = useState<DataCotejo>(
    defaultValuesDataCotejo
  );
  const [dataBusinessUnits, setDataBusinessUnits] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dataUserRecords, setDataUserRecords] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dailyAverage, setDailyAverage] = useState<DashboardAverage>(
    defaultDashboardAverage
  );
  const [dataTypeBusinessUnit, setDataTypeBusinessUnit] = useState<
    DataCompaniesForChart[]
  >([]);
  const [averageBusinessUnit, setAverageBusinessUnit] = useState<
    DataCompaniesForChart[]
  >([]);
  const [refreshChart, setRefreshChart] = useState<boolean>(true);

  //Custom Hooks
  const { date, messageErrors, onChangeDate, selectDate, setSelectDate } =
    useDateTextField({ switchCompany, role: Role });

  const [consumptionData, setConsumptionData] = useState<ICustomerConsumption>();


  const getActualLicense = (licenses:ICustomerConsumption[]) => {
    // filtrar las bolsas correspondientes de consumo de cotejos y que ésta esté en uso
    let  isValid = licenses.filter(object => {
      return (
        object.serviceFeatureCode === "BIOMETRIA_COTEJO"
        && object.usagePercentage < 100
        && object.usagePercentage >= 0
        && moment(object.expirationDate).diff(moment(), "minute") >= 0
      );
    });
    // ordenar de menor a mayor las fechas de las bolsas (DateTime)
    isValid.sort(function(a,b){ return Date.parse(a.expirationDate) - Date.parse(b.expirationDate) });
    // Comprobar si hay mas registros de licencias con porcentaje de uso mayor a 0
    if(isValid.length > 1) {
      // filtramos solo por los que tengan porcentaje de uso mayor a cero
      const moreValid = isValid.filter(mv => { return (mv.usage != null && mv.usagePercentage > 0)});
      if (moreValid.length > 1) {
        // aqui trataremos el registro para solo devolver el que tenga mayor uso
        isValid = moreValid.sort(function(a,b){ return b.usagePercentage - a.usagePercentage });
      }
    }
    // retornar el primer registro si existen mas bolsas válidas, se ordena la bolsa mas aproximada a ser la activa para poderla retornar
    return isValid[0];
  }

  const DashboardPrintSchema = yup.object().shape({
    dateType: yup
      .string()
      .max(100, `${MESSAGES.max} 100 caracteres`)
      .required(MESSAGES.required)
      .trim(),
  });

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(DashboardPrintSchema),
    shouldUnregister: false,
  });

  /** Function to get count of days between two dates */
  const calculateDays = ({ expirationDate }: { expirationDate: string }) => {
    return (
      moment(expirationDate, "YYYY-MM-DD HH:mm:ss").diff(
        moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
        "days"
      )
    );
  };

  const calculatePercent = (total: number, actual: number) =>
    Number.parseFloat((total - actual).toString())
      .toFixed(2)
      .toString();

  const optionDate: Array<SelectOption> = [
    {
      key: 0,
      value: "day",
      label: "Días",
    },
    {
      key: 1,
      value: "month",
      label: "Meses",
    },
  ];

  //REACT HOOKS

  /** Launch services to the license and biometrics api services through the action creator  */
  useEffect(() => {
    /** Se lanza action que consume el servicio de licenciamiento para obtener la informacion de la bolsa */
    dispatch(getCustomerLicenseAC({ DocumentType, ServiceName, DocumentNumber }));
    dispatch(getRecordsByCompanyAndBusinessunitAC()); //RecordsByCompanyAndBusinessunit
    dispatch(getUserRecordsAC()); //RecordsUser
    dispatch(getDailycomparisonAverageAC()); //DailycomparisonAverage
    dispatch(getAverageByBusinessunitAC()); //AverageByBusinessUnit
    dispatch(getRecordsTypeProcessCompanyAndBusinessunitAC());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DocumentNumber]);


  useEffect(() => {
    if (refreshChart) {
      dispatch(
        getDoughnutTransactionAC({
          BusinessUnitId: 0,
          Comparative: 1,
          StartDate: formatDateIso(),
          SwitchCompany: switchCompany
        })
      );
      setRefreshChart(false);
    }
  }, [refreshChart, dispatch, switchCompany]);

    /** Se espera que cargue la informacion de la bolsa proveniente de licenciamiento, se filtra la informacion necesaria que es la bolsa de consumo de cotejos
   * luego se setea en el estado consumptionData la informacion y luego se lanza el servicio hacia biometria que calcula el total del consumo de cotejos
   */
    useEffect(() => {
      if (license){
        const consumption = getActualLicense(license);
        if (consumption){
          setConsumptionData(consumption);
          dispatch(
            getCustomerConsumptionAC({
              DocumentType,
              ServiceName,
              DocumentNumber,
              EffectiveDate: consumption.effectiveDate,
              Total: consumption.total,
              SwitchCompany: switchCompany,
            })
          );
        }
      }
    }, [ license, switchCompany ]);
  
    /** Listen hook information about dashboard, then set state to graph */
    useEffect(() => {
      const data: DataCompaniesForChart[] = typeProcessCompanyBusinessUnits?.map(
        (business: any) => ({
          name: business.name_Type_Process,
          value: business.number_Record,
          label: business.name_Businessunit,
        })
      );
      setDataTypeBusinessUnit(data);
    }, [typeProcessCompanyBusinessUnits]);
  
    /** Setear la informacion en el estado que muestra el donut de el consumo de cotejos para el usuario admin */
    useEffect(() => {
      if (customerConsumption != null) {
        const cotejoEmpresa: DataCotejo = {
          name: "",
          totalBolsa: customerConsumption.total,
          consumo: customerConsumption.usage ? customerConsumption.usage : 0,
          width: 130,
          fontSizeConsumo: 22,
          fontSizeTotal: 14,
          percentage: customerConsumption.usagePercentage,
          expirationDate: consumptionData?.expirationDate ? consumptionData.expirationDate : "",
        };
        setDataCotejo(cotejoEmpresa);
      }
    }, [customerConsumption]);
  
    /** Setear la informacion de promedio de tiempo por cotejos */
    useEffect(() => {
      const data: DashboardAverage = {
        daily_Comparison_Average:
          dasboardCapturador.daily_Comparison_Average.toString(),
        average_Time_Comparison_min: parseInt(
          dasboardCapturador.average_Time_Comparison.split(":")[1]
        ).toString(),
        average_Time_Comparison_seg:
          dasboardCapturador.average_Time_Comparison.split(":")[2],
      };
      setDailyAverage(data);
    }, [dasboardCapturador]);
  
    useEffect(() => {
      const data: DataCompaniesForChart[] = businessUnitRecords?.map(
        (business: any) => ({
          name: business.name,
          value: business.number_Record,
        })
      );
      setDataBusinessUnits(data);
    }, [businessUnitRecords]);
  
    useEffect(() => {
      const data: DataCompaniesForChart[] = businessUnitAverage?.map(
        (business: any) => ({
          name: business.name,
          value: business.number_Record,
        })
      );
      setAverageBusinessUnit(data);
    }, [businessUnitAverage]);
  
    useEffect(() => {
      const data: DataCompaniesForChart[] = userRecords?.map((user: any) => ({
        name: user.name,
        value: user.number_Record,
      }));
      setDataUserRecords(data);
    }, [userRecords]);
    /** End Listen hook information about dashboard, then set state to graph */

  
  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Cotejos Consumidos"
          text={customerConsumptionMessage != null ? customerConsumptionMessage : ""}
          pieTextTitle="Fecha finalización:"
          pieText={formatDate(dataCotejo.expirationDate)}
          orangeAlertIcon={
            dataCotejo.percentage > 90 && dataCotejo.percentage <= 99.99
          }
          redAlertIcon={dataCotejo.percentage >= 100}
          textOrangeAlertIcon={
            calculateDays({ expirationDate: dataCotejo.expirationDate }) >= 0 &&
            calculateDays({ expirationDate: dataCotejo.expirationDate }) <= 90
          }
          textRedAlertIcon={
            calculateDays({ expirationDate: dataCotejo.expirationDate }) < 0 ||
            dataCotejo.expirationDate === ""
          }
          messageAlertIcon={{
            orangeText: `menos del ${calculatePercent(
              100,
              dataCotejo.percentage
            )}% de cotejos disponible`,
            redText: "Cotejos consumidos",
            pieOrangeText: `Aproximadamente ${calculateDays({
              expirationDate: dataCotejo.expirationDate,
            })} dia(s) para vencer`,
            pieRedText: customerConsumptionMessage != null ? customerConsumptionMessage : "Cotejos Vencidos",
          }}
        >
          <DonutProgress elements={[dataCotejo]} />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Promedio de cotejos consumidos por sede durante el mes"
          text=""
          subTitle="Cotejos / día"
        >
          <HorizontalChart elements={averageBusinessUnit} />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <Cards
          title="Tiempo promedio por cotejo"
          subTitle="Cotejos / día hoy"
          text={dailyAverage.average_Time_Comparison_min}
          subText="min"
          text1={dailyAverage.average_Time_Comparison_seg}
          subText1="seg"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Sedes con mayor cantidad de cotejos"
          text=""
          subTitle="Cotejos / día hoy"
        >
          <LinearPercentBar elements={dataBusinessUnits} withTooltip={false}/>
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Usuarios con mayor número de cotejos"
          text=""
          subTitle="Cotejos / día hoy"
        >
          <LinearPercentBar elements={dataUserRecords} withTooltip={false}/>
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Trámite más usual por sede"
          text=""
          subTitle="Cantidad / día hoy"
        >
          <LinearPercentLabel elements={dataTypeBusinessUnit} withTooltip={false}/>
        </RoundedCard>
        </Grid>

      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <RoundedCard
            title="Cotejos Hit, No hit y novedades"
            text=""
            subTitle="Cotejos / día hoy"
          >
            <DougnutHits
              data={doughnutTransactions}
              colors={["#5FBE2C", "#D50915", "#4572A7"]}
              refreshChart={refreshChart}
              setRefreshChart={setRefreshChart}
            />
          </RoundedCard>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={10}>
          <RoundedCard
            title="Consulta de Cotejos Hit, No hit y novedades por mes o día"
            text=""
          >
            <SelectInputRounded
              label="Selecciona tipo de fecha que deseas consultar"
              control={control}
              props={{
                helperText: errors.documentType?.message,
                error: errors.documentType ? true : false,
                disabled: false,
                autoComplete: "off",
              }}
              onChange={(e: any) => setSelectDate(e)}
              name="dateType"
              options={optionDate}
            />
            {!!selectDate && !loading ? (
              <>
              <p>{Role}</p>
                <DateTextField
                  onChangeDate={onChangeDate}
                  currentDate={formatDateIso()}
                  row={date}
                  errors={messageErrors}
                  typeDate={selectDate !== "day" ? "month" : "date"}
                />
                <VerticalBarHits data={verticalBarTransactions} />
              </>
            ) : (
              <></>
            )}
          </RoundedCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin;
