import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import HandleNumberParticipantsComponent from "../../../components/button/IncreaseButton";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import FingerFormData from "../../../components/Fingerprint/FingerFormData";
import { SelectInputRounded } from "../../../components/Inputs/SelectInputRounded";
import { MESSAGES } from "../../../utils/types/const";
import { SelectOption } from "../../../store/models/DashboardModel";
import FingerATDP from "../../../components/Atdp/FingerATDP";
import { IDataATDP } from "../../../store/models/CreateAtdp";
import { SecondaryButton } from "../../../components/button/SecondaryButton";

const useStyles = makeStyles((_theme: Theme) => ({
  header: {
    padding: "0 !important",
    margin: "20px 0",
    display: "flex",
    flexDirection: "column",
    [_theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  containerText: {
    alignItems: "center",
    justifyContent: "flex-start",
    [_theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },

  increaseContainer: {
    display: "flex",
    justifyContent: "right",
    height: "30px",
  },

  formParticipants: {
    boxShadow: "0px 3px 6px #00000033",
    border: "1px solid #009BDD",
    padding: "20px",
    borderRadius: "10px",
    margin: 0,
    marginBottom: "20px",
  },

  buttonNextContainer: {
    display: "flex",

    justifyContent: "right",
    [_theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [_theme.breakpoints.up("sm")]: {
      maxWidth: "137px",
    },
  },
  titleParticipant: {
    font: "normal normal bold 18px/23px Muli",
    color: "#009BDD",
    marginBottom: 20,
  },
}));

interface FingerprintFormProps {
  handleValidate: Function;
  formSubmit: Function;
  handleDataSaveForm: Function;
  handleProcessType: Function;
  handleNumberParticipants: Function;
  processTypeState: SelectOption[];
  numberParticipantsLocal: number;
  submitState: any;
  primaryParticipant: IDataATDP;
  hideModalATDP: Function;
  isOpenModalATPD: boolean;
  isValidDevice: boolean;
  connnectDevice: Function;
  handleChangeTab: Function;
  handleStatesToFalse: Function;
}

const FingerPrintSchema = yup.object().shape({
  procedure: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
});

type FingerPrintFormData = yup.InferType<typeof FingerPrintSchema>;

const FingerprintForm = ({
  handleValidate,
  formSubmit,
  handleDataSaveForm,
  handleProcessType,
  handleNumberParticipants,
  processTypeState,
  numberParticipantsLocal,
  submitState,
  primaryParticipant,
  hideModalATDP,
  isOpenModalATPD,
  isValidDevice,
  handleChangeTab,
  handleStatesToFalse,
  connnectDevice,
}: FingerprintFormProps) => {
  // library hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FingerPrintFormData>({
    mode: "onSubmit",
    resolver: yupResolver(FingerPrintSchema),
    shouldUnregister: false,
  });

  const classes = useStyles();

  function returnForLoop(numberParticipants: number) {
    let renderChildren = [];
    if (numberParticipants > 0) {
      for (let i = 0; i < numberParticipants; i++) {
        renderChildren.push(
          <FingerFormData
            index={i + 1}
            isOpen={true}
            handleValidateProp={handleValidate}
            handleSubmitProp={formSubmit}
            handleDataProp={handleDataSaveForm}
          />
        );
      }
    }
    return renderChildren;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((data: any) => {
          formSubmit(data);
        })}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={"column"}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Container className={classes.header}>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={3}
              justifyContent="space-between"
            >
              <Grid item lg={8} md={8} sm={7} xs={12}>
                <SelectInputRounded
                  control={control}
                  props={{
                    helperText: errors.procedure?.message,
                    error: errors.procedure ? true : false,
                    disabled: false,
                  }}
                  onChange={(e: any) => {
                    handleProcessType(e);
                  }}
                  name="procedure"
                  label={"Seleccione el trámite"}
                  options={processTypeState}
                />
              </Grid>
              <Grid
                container
                item
                lg={3}
                md={3}
                sm={3}
                xs={8}
                className={classes.containerText}
              >
                <Typography>Cantidad de participantes</Typography>
              </Grid>
              <Grid
                container
                item
                lg={1}
                md={1}
                sm={2}
                xs={4}
                alignItems="center"
                justifyContent="flex-end"
              >
                <div className={classes.increaseContainer}>
                  <HandleNumberParticipantsComponent
                    min={1}
                    max={10}
                    handleValue={handleNumberParticipants}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>

          <Container style={{ padding: 0 }}>
            {typeof numberParticipantsLocal === "number" &&
              returnForLoop(numberParticipantsLocal)}
          </Container>
          <Container
            style={{
              display: "flex",
              justifyContent: "end",
              margin: 0,
              padding: 0,
            }}
          >
            <div className={classes.buttonNextContainer}>
              {!isValidDevice ? (
                <SecondaryButton
                  title={"Conectar Dispositivo"}
                  props={{
                    style: {
                      display: "flex",
                      minWidth: "max-content",
                      backgroundColor: "#fa5a00",
                      color: "#fff",
                    },
                    type: "button",
                    onClick: () => connnectDevice(),
                  }}
                />
              ) : (
                <PrimaryButton
                  title={"Siguiente"}
                  props={{
                    style: { display: "flex" },
                    endIcon: <ArrowForwardIos style={{ fontSize: "25px" }} />,
                    type: "submit",
                    disabled: !!submitState
                      ? false
                      : !submitState
                      ? true
                      : false,
                  }}
                />
              )}
            </div>
          </Container>
        </Grid>
      </form>
      <FingerATDP
        data={primaryParticipant}
        onClick={() => {
          hideModalATDP();
          handleChangeTab(1);
        }}
        isOpen={isOpenModalATPD}
        isClose={handleStatesToFalse}
      />
    </>
  );
};
export { FingerprintForm };
