import React, { useState } from "react";
import ViewLayout from "../../components/Layouts/ViewLayout";
import FullLoader from "../../components/Loader/FullLoader.component";
import { useSelector } from "react-redux";
import { TypeProcessSelector } from "../../store/slices/TypeProcessSlice";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { FingerprintCaptureMain } from "./FingerPrintCapture";
import { FingerprintFormMain } from "./FingerPrintForm/index";
import useManagerRole from "../../hooks/useManagerRole";

interface FingerprintViewProps {
  token: string;
  nitCompany?: string;
}

const FingerprintView = ({ token, nitCompany }: FingerprintViewProps) => {
  const { isLoading } = useSelector(TypeProcessSelector);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const {
    roleLogged,
    companyID,
    tab,
    isEdit,
    haveGeolocatization,
    setIsEdit,
    handleChangeTab,
  } = useManagerRole({ token, nitCompany });

  return (
    <>
      {haveGeolocatization !== null && (
        <>
          <ViewLayout
            isNew={false}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            headerTitle="Captura de huellas"
            indicators={{ currentTab: tab, tabs: 2 }}
          >
            {tab === 0 ? (
              <FingerprintFormMain
                roleLogged={roleLogged}
                token={""}
                handleChangeTab={handleChangeTab}
                haveGeolocatization={haveGeolocatization}
              />
            ) : tab === 1 ? (
              <FingerprintCaptureMain
                roleLogged={roleLogged}
                isMobile={matches}
                handleChangeTab={handleChangeTab}
                companyId={companyID.CompanyId}
              />
            ) : (
              <></>
            )}
          </ViewLayout>
          <FullLoader open={isLoading} />
        </>
      )}
    </>
  );
};

export default FingerprintView;
