import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import { StandardResponse } from "../type";
import { IDataATDPFNAINT } from "../store/models/CreateAtdp";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const CANDIDATE_INTEGRATOR = "Integracion/Candidate ";

/**
 * @description Servicio trae informacion de candidato biometria integrador
 * @returns { StandardResponse<IDataATDPFNAINT> } Informacion de candidato
 */
export const getDataParticipantINT = async (
  enqueueSnackbar: any,
  stopLoading: any
): Promise<StandardResponse<IDataATDPFNAINT>> => {
  try {
    const response: AxiosResponse<StandardResponse<IDataATDPFNAINT>> =
      await instance.get(CANDIDATE_INTEGRATOR, defaultHeaders());
    stopLoading();
    enqueueSnackbar("Candidato agregado", {
      variant: "success",
    });
    return response.data;
  } catch (error) {
    enqueueSnackbar("No se encuentra un candidato disponible", {
      variant: "error",
    });
    stopLoading();
    throw error;
  }
};
