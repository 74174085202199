import { useDispatch } from "react-redux";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";

//COMPONENTS
import NewUserIcon from "../../../components/Icons/NewUserIcon";
import { ButtonsProps } from "../../../components/Table/types";

//SLICES
import { setShowForm } from "../../../store/slices/TypeProcessSlice";

export const GetButtons = (rol: string, privilege: string[]) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const sizebuttons = rol === "BIOMETRIA RNEC" ? 7 : 4;
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: sizebuttons,
        md: sizebuttons,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "center",
      options:
        rol === "BIOMETRIA RNEC"
          ? [
              {
                label: "Más reciente",
                columns: [
                  {
                    prop: "createdon",
                    direction: 1,
                    format: (date: any) => {
                      return new Date(date).getTime();
                    },
                  },
                ],
              },
              {
                label: "Más antiguo",
                columns: [
                  {
                    prop: "createdon",
                    direction: -1,
                    format: (date: any) => {
                      return new Date(date).getTime();
                    },
                  },
                ],
              },
            ]
          : [
              {
                label: "Activo",
                columns: [
                  {
                    prop: "state",
                  },
                ],
              },
              {
                label: "Inactivo",
                columns: [
                  {
                    prop: "state",
                    direction: -1,
                  },
                ],
              },
              {
                label: "Más reciente",
                columns: [
                  {
                    prop: "createdon",
                    direction: 1,
                    format: (date: any) => {
                      return new Date(date).getTime();
                    },
                  },
                ],
              },
              {
                label: "Más antiguo",
                columns: [
                  {
                    prop: "createdon",
                    direction: -1,
                    format: (date: any) => {
                      return new Date(date).getTime();
                    },
                  },
                ],
              },
            ],
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
      fileName: "Listado de Tipo de trámite",
    },
    chargeButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    children: () => {
      return (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          direction="row-reverse"
          justifyContent="center"
        >
          <Tooltip title={`Crear trámite`} aria-label={`Nuevo tramite`}>
            <Fab
              className={classes.styleFab}
              size="small"
              onClick={() => {
                dispatch(setShowForm(true));
                // setUser({
                //   id: 0,
                // });
              }}
              disabled={
                privilege?.find(
                  (privil: string) => privil === "BIOMETRIA_TRAMITE_CREATE"
                ) === undefined
                  ? true
                  : false
              }
            >
              <NewUserIcon />
            </Fab>
          </Tooltip>
        </Grid>
      );
    },
  };

  return button;
};
const useStyles = makeStyles({
  styleFab: {
    boxShadow: "none",
  },
});
