import {
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AtdpErrorMailSend } from "../../../components/Icons/AtdpErrorMailSend";
import { makeStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { AtdpSelector } from "../../../store/slices/AtdpSlice";
import { AtdpMailSend } from "../../../components/Icons/AtdpMailSend";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { AlertIconModal } from "../../../components/modal/AlertIconModal";

interface AlertEmailModalProps {
  open: boolean;
  handleClose: Function;
}

/**
 * Modal con la información a cerca del estado del envio del atdp por correo.
 * @param { boolean } open - estado del modal.
 * @param { Function } handleClose - función que se ejecuta al cerrar el modal.
 * @returns { React.FC } Vista de modal de respuesta del envio de atdp por correo.
 */

export const AlertEmailModal: React.FC<AlertEmailModalProps> = ({
  open,
  handleClose,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const { mailAtdp } = useSelector(AtdpSelector);
  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => handleClose()}>
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1} className={classes.cursorProps}>
          <Close color="primary" onClick={() => handleClose()} />
        </Grid>
      </Grid>
      {mailAtdp.length > 0 ? (
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
            >
              <Table aria-label="simple table" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.tableCell} align="center">
                      Email
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Estado
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Observación
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mailAtdp.map((item, index) => (
                    <TableRow className={classes.row} key={index}>
                      <TableCell align="center" component="th" scope="row">
                        {item.email}
                      </TableCell>
                      <TableCell align="center">
                        {item.sucessfull ? (
                          <AtdpMailSend width="30" height="30" />
                        ) : (
                          <AtdpErrorMailSend width="30" height="30" />
                        )}
                      </TableCell>
                      <TableCell align="center">{item.messageStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid className={classes.buttonSuccess}>
              <PrimaryButton
                title={"Aceptar"}
                props={{ onClick: () => handleClose() }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <>
          <AlertIconModal
            open={open}
            handleClose={() => {
              handleClose();
            }}
            icon={<AtdpErrorMailSend width="90" height="90" />}
            message={"El envío no fue exitoso, por favor intente de nuevo"}
          />
        </>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "555px",
    background: theme.palette.common.white,
    padding: "10px 10px 10px 10px",
    WebkitBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    MozBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    boxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
  },
  tableCell: {
    height: "26px",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.light, // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  row: {
    background: "#F6F6F6 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #00000033",
    opacity: 1,
    height: "45px",
  },
  buttonSuccess: {
    width: "147px",
    height: "40px",
    margin: 10,
    fontSize: "18px",
  },
  cursorProps: {
    cursor: "pointer",
    height: "15px",
    margin: "15px 0 15px 0",
  },
}));
