import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { DEV_MODE, ENCRYPT_PHARAGRAPH } from "../config/const";
import { IGeneralWebsocketResponse } from "../store/models/FingerPrintModel";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { useDispatch } from "react-redux";
import { setLoadingFingerPrint } from "../store/slices/FingerPrintSlice";
import { getUrlWebSocketByRole } from "../utils/general/utils";
import { useCallback, useState } from "react";

var stompClient: any = null;
let responseConnect: string = "";
let responseSocketTemp: IGeneralWebsocketResponse = {
  dateTime: "",
  httpStatus: 0,
  message: "",
  responsePrintFinger: {
    deviceSerial: "",
    imageBase64: "",
    ip: "",
    mac: "",
    prinfingerTemplate: "",
  },
  responseCode: "",
};

const useFingerWebSocket = (fingerDevice?: any) => {
  // dependencies
  var aesEcb = require("aes-ecb");
  // Constants
  var urlSocket = getUrlWebSocketByRole();
  const needToEncryptData = sessionStorage.getItem("aes-encrypt");
  // REDUX
  const dispatch = useDispatch();

  /**
   * Encrypt data
   * @function
   * @param {any} data data to submit
   */
  const encryptDataToSend = (data: any) => {
    const dataParsed = JSON.stringify(data);
    let encryptedData = aesEcb.encrypt(ENCRYPT_PHARAGRAPH, dataParsed || "");
    return encryptedData;
  };
  /**
   * Decrypt data
   * @function
   * @param {any} data data to submit
   */
  const decryptDataReceibed = (data: any) => {
    const dataDecrypted = aesEcb.decrypt(ENCRYPT_PHARAGRAPH, data);

    return JSON.parse(dataDecrypted);
  };

  const setResponseConnect = (re: any) => {
    if (re) {
      responseConnect = re;
    }
  };

  const getReturnResponseConnect = () => {
    return responseConnect;
  };

  const returnResponseSend = () => {
    return responseSocketTemp;
  };

  function isEmptyObject(obj: any) {
    try {
      const objParsed = JSON.parse(obj);
      return objParsed;
    } catch (error) {}
  }
  // open new conection to desktop web socketWS and try to connect in local if fail at first time
  const openSocketConnection = (
    urlSocket: string,
    onResponse: any,
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject
    ) => SnackbarKey,
    _customFunction: any
  ) => {
    let socket = new SockJS(urlSocket + "printfinger", null, {});
    stompClient = Stomp.over(socket);
    stompClient.debug = null;
    stompClient.connect(
      {},
      (frame: any) => {
        setResponseConnect(frame.command);
        suscribe(_customFunction);
        onResponse(true);
      },
      (error: any) => {
        setResponseConnect(error);
        enqueueSnackbar("Error de comunicación con biometria desktop.", {
          variant: "error",
        });
        onResponse(false);
        dispatch(setLoadingFingerPrint(false));
      }
    );
  };
  /* Connect to topic using web sockets */
  const connectToFingerWebSocket = (
    onResponse: any,
    _customFunction: any,
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject
    ) => SnackbarKey
  ) => {
    if (urlSocket?.role === "movil") {
      let socket = new WebSocket(`${urlSocket.url}printfinger`);

      socket.onopen = () => {
        const deviceinfo = fingerDevice.result;
        socket.send(
          JSON.stringify({
            device: `${deviceinfo.device}`,
            encrypt: `${deviceinfo.encrypt}`,
            image: false,
            companyId: `${deviceinfo.companyId}`,
            deviceId: `${deviceinfo.deviceId}`,
            deviceSerial: `${deviceinfo.serial}`,
            dev: DEV_MODE,
          })
        );
      };

      socket.onmessage = (_response) => {
        if (isEmptyObject(_response.data)) {
          setResponseConnect("CONNECTED");
          onResponse(true);
          suscribe(_customFunction, _response.data);
          socket.close();
        }
      };
    } else {
      openSocketConnection(
        needToEncryptData === "1" ? urlSocket?.urlLocal : urlSocket?.url,
        onResponse,
        enqueueSnackbar,
        _customFunction
      );
    }
  };

  /* Disconnect */
  function disconnect() {
    if (!!stompClient) {
      stompClient.disconnect();
    }
  }

  const suscribe = (_customFunction: any, data?: any) => {
    if (!!stompClient) {
      stompClient.subscribe("/topic/printfingerData", (message: any) => {
        callbackSuscribe(message, _customFunction, needToEncryptData === "1");
      });
    } else {
      callbackSuscribe({ body: data }, _customFunction);
    }
  };

  const callbackSuscribe = (
    message: any,
    _customFunction: any,
    isEncrypted?: Boolean
  ) => {
    if (message) {
      let messageParsed = JSON.parse(message.body);

      let responsePrintFingerData = isEncrypted
        ? decryptDataReceibed(messageParsed.responsePrintFingerEncrypted)
        : messageParsed.responsePrintFinger;

      responseSocketTemp = {
        dateTime: messageParsed.dateTime,
        httpStatus: messageParsed.httpStatus,
        message: messageParsed.message,
        responsePrintFinger: responsePrintFingerData,
        responseCode: messageParsed.responseCode,
      };

      _customFunction(responseSocketTemp);
    }
  };

  /* Send place */
  const sendSocket = useCallback(
    (deviceInfo: any) => {
      if (!!stompClient) {
        let objtoSend = {
          device: `${deviceInfo.device}`,
          encrypt: `${deviceInfo.encrypt}`,
          image: false,
          companyId: `${deviceInfo.companyId}`,
          deviceId: `${deviceInfo.deviceId}`,
          deviceSerial: `${deviceInfo.serial}`,
          dev: DEV_MODE,
        };

        stompClient.send(
          "/app/printfinger",
          {},
          needToEncryptData === "1"
            ? encryptDataToSend(objtoSend)
            : JSON.stringify(objtoSend)
        );
      }
    },
    [needToEncryptData]
  );

  function sendRequestSocket(deviceInfo: any) {
    sendSocket(deviceInfo);
  }

  return {
    sendRequestSocket,
    disconnect,
    connectToFingerWebSocket,
    returnResponseConnect: getReturnResponseConnect,
    returnResponseSend,
  };
};

export default useFingerWebSocket;
