import { useEffect, useState } from "react";
import { FNA_POINT } from "../config/const";
import { decodeToken } from "../utils/general/utils";
import { IGetATDP } from "../store/models/CreateAtdp";
import { useDispatch } from "react-redux";
import useLocation from "./useLocation";
import {
  GetATDPAC,
  GetParametersATDPAC,
} from "../store/action-creators/CreateAtdpActionCreators";

interface IUseManagerRole {
  token: string;
  nitCompany: string | undefined;
}

const useManagerRole = ({ token, nitCompany }: IUseManagerRole) => {
  // redux dispatch
  const dispatch = useDispatch();
  //   local state
  const [tab, setTab] = useState(0);
  const [isEdit, setIsEdit] = useState();
  const [haveGeolocatization, setHaveGeolocatization] = useState<
    boolean | null
  >(null);

  var options = { enableHighAccuracy: false, timeout: 5000, maximumAge: 5000 };

  // geolocalization
  const [position] = useLocation(options);

  const [roleLogged, setRoleLogged] = useState<"FNA" | "INT" | "NRM">("NRM");

  const companyID: IGetATDP = {
    CompanyId: !!nitCompany
      ? nitCompany
      : "c6937b97-8a44-454e-84c4-71a9a6d13450",
  };

  //   Custom functions
  const handleChangeTab = (newTab: any) => {
    setTab(newTab);
  };

  useEffect(() => {
    const tokenDecode = decodeToken(token);

    if (tokenDecode?.customer_value === FNA_POINT) {
      setRoleLogged("FNA");
    } else if (tokenDecode?.BIOMETRIA_ROLE === "BIOMETRIA INTEGRACION") {
      setRoleLogged("INT");
    }
  }, [token]);
  // react hooks

  useEffect(() => {
    if (!!position && (!!position.latitude || !!position.longitude)) {
      sessionStorage.setItem(
        "pos_latitud",
        position?.latitude.toString() || ""
      );
      sessionStorage.setItem(
        "pos_longitud",
        position?.longitude.toString() || ""
      );
      setHaveGeolocatization(true);
    } else {
      setHaveGeolocatization(false);
    }
  }, [position && position.latitude, position && position.longitude]);

  useEffect(() => {
    dispatch(GetATDPAC(companyID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(GetParametersATDPAC(companyID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tab === 0 && sessionStorage.setItem("aes-encrypt", "0");
  }, [tab]);

  return {
    roleLogged,
    companyID,
    tab,
    isEdit,
    haveGeolocatization,
    setIsEdit,
    handleChangeTab,
  };
};

export default useManagerRole;
