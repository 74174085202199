import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./AuthSlice";
import CommonListSlice from "./CommonListsSlice";
import CommonSlice from "./CommonSlice";
import CompanySlice from "./CompanySlice";
import DeviceSlice from "./DeviceSlice";
import TypeProcessSlice from "./TypeProcessSlice";
import ComputerEquipment from "./ComputerEquipmentSlice";
import Comparison from "./ComparisonSlice";
import BusinessUnitSlice from "./BusinessUnitSlice";
import AtdpSlice from "./AtdpSlice";
import FingerPrintSlice from "./FingerPrintSlice";
import DashboardSlice from "./DashboardSlice";
import PersonalizationSlice from "./PersonalizationSlice";
import UserSlice from "./UserSlice";
import AuditLogSlice from "./AuditLogSlice";
import NotificationsSlice from "./NotificationsSlice";
import FNAReportSlice from "./FNAReportSlice";
 
const rootReducer = combineReducers({
  auth: authSlice,
  typeProcess: TypeProcessSlice,
  company: CompanySlice,
  commonList: CommonListSlice,
  device: DeviceSlice,
  common: CommonSlice,
  computerEquipment: ComputerEquipment,
  comparison: Comparison,
  business: BusinessUnitSlice,
  atdp: AtdpSlice,
  fingerprint: FingerPrintSlice,
  dashboard: DashboardSlice,
  personalization: PersonalizationSlice,
  user: UserSlice,
  auditLog: AuditLogSlice,
  notifications: NotificationsSlice,
  fnareports: FNAReportSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
