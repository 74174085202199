import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import { DeviceMassiveResponse, ILoadDevice } from "../store/models/DeviceUnit";
import { ListBasicResponse } from "../store/models/ListBasicResponse";
import { UpdateState } from "../store/models/UpdateState";
import {
  AdvanceSearch,
  Device,
  DeviceBusinessUnit,
  IAudit,
  IEquiptmentDeviceExcel,
  PaginatorWithFilterAndSort,
} from "../store/types";
import { ResponseTemplate, ResultResponse, StandardResponse } from "../type";

// Endpoints
const DEVICE_ALL = "Device/all";
const DEVICE_URL = "Device/Device";
const DEVICE_STATE = "Device/State";
const DEVICE_AUDIT = "Device/Audit";
const DEVICE_EXCEL = "Device/GetAllDeviceXlsx";
const DEVICE_TEMPLATE = "Device/DeviceMassiveTemplate";
const DEVICE_VALIDATE = "Device/DeviceMassive";
const DEVICE_MASSIVE_SAVE = "Device/DeviceMassiveSave";
const DEVICE_BUSINESS_UNIT = "Device/DeviceBusinessUnit";
const DEVICE_EXCEL_BUSINESS_UNIT = "Device/GetDeviceByBusinessUnitXlsx";
const DEVICE_ADVANCE_SEARCH = "Device/AdvanceSearchDevice";
const DEVICE_ADVANCE_SEARCH_CSV = "Device/DeviceFileCsv";

// Config Axios
const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

/**
 * Crea un dispositivo
 * @param { Device } body - información del dispositivo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const createDevice = async (
  body: Device
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(DEVICE_URL, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Actualiza un dispositivo
 * @param { Device } body - información del dispositivo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const updateDevice = async (
  body: Device
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.put(DEVICE_URL, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los dispostivos
 * @param { PaginatorWithFilterAndSort } body - informacion de la paginación el filtro y el orednamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 * @param { ( number | undefined ) } equipment - id del equipo
 * @returns { Promise<StandardResponse<ListBasicResponse<Device>>> } promesa con la lista de los dispositivos
 */
export const getAllDevices = async (
  body: PaginatorWithFilterAndSort,
  company?: string,
  businessUnit?: string,
  equipment?: number | undefined
): Promise<StandardResponse<ListBasicResponse<Device>>> => {
  try {
    const response: AxiosResponse<StandardResponse<ListBasicResponse<Device>>> =
      await instance.post(
        DEVICE_ALL,
        {
          ...body,
          company,
          businessUnit,
          equipment,
        },
        defaultHeaders()
      );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los dispostivos dependiendo la sede o el equipo para armar el archivo excel
 * @param { DeviceBusinessUnit } body - datos
 * @returns { Promise<StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>> } promesa con la lista de los dispositivos que se van a descargar en un archivo excel
 */
export const getDevicesExcelByBusinessUnit = async (
  body: DeviceBusinessUnit
): Promise<StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>
    > = await instance.post(DEVICE_EXCEL_BUSINESS_UNIT, body, defaultHeaders());
    if (response.status === 200) {
      const result = response.data;
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los dispostivos dependiendo la sede o el equipo
 * @param { DeviceBusinessUnit } body - datos
 * @returns { Promise<StandardResponse<ListBasicResponse<Device>>> } promesa con la lista de los dispositivos
 */
export const getDevicesByBusinessUnit = async (
  body: DeviceBusinessUnit
): Promise<StandardResponse<ListBasicResponse<Device>>> => {
  try {
    const response: AxiosResponse<StandardResponse<ListBasicResponse<Device>>> =
      await instance.post(DEVICE_BUSINESS_UNIT, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Actualiza el estado del dispositivo
 * @param { UpdateState } body - datos del dispositivo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const updateDeviceState = async (
  body: UpdateState
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(DEVICE_STATE, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Obtiene y retorna la auditoria del dispositivo
 * @param { Device } body - datos del dispositivo
 * @returns { Promise<StandardResponse<IAudit>> } promesa con la auditoria del dispositivo
 */
export const deviceAuditService = async (
  body: Device
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(DEVICE_AUDIT, body, defaultHeaders());
    const result = response.data;
    return result;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los dispostivos para armar el archivo excel
 * @param { AdvanceSearch } body - informacion para realizar la busqueda
 * @returns { Promise<StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>> } promesa con la lista de los dispositivos que se van a descargar en un archivo excel
 */
export const getAllDevicesExcelService = async (
  body: AdvanceSearch,
  equipment?: number | undefined
): Promise<StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IEquiptmentDeviceExcel>>
    > = await instance.post(
      DEVICE_EXCEL,
      { ...body, equipment },
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna el template para descargar la plantilla de la carga masiva
 * @returns { StandardResponse<ResponseTemplate> } promesa con el template del archivo que se piensa descargar
 */
export const getMassiveDeviceTemplate = async (): Promise<
  StandardResponse<ResponseTemplate>
> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.get(DEVICE_TEMPLATE, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna la validacion si se puede subir o se reemplaza o si esta mal
 * @param { Array<ILoadDevice> } body - informacion del cargue masivo
 * @returns { Promise<StandardResponse<DeviceMassiveResponse>> } promesa con la respuesta de la validacion de la carga masiva
 */
export const validateLoadStatus = async (
  body: Array<ILoadDevice>
): Promise<StandardResponse<DeviceMassiveResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<DeviceMassiveResponse>> =
      await instance.post(DEVICE_VALIDATE, body, defaultHeaders());
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
/**
 * Guarda los datos del cargue masivo y retorna si fue satisfactoria o no
 * @param { Array<ILoadDevice> } body - informacion del cargue masivo
 * @returns { Promise<StandardResponse<ResultResponse>> } promesa con el resultado de la operación
 */
export const createMassiveDevice = async (
  body: Array<ILoadDevice>
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(DEVICE_MASSIVE_SAVE, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna los dispositivos dependiendo la búsqueda
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ListBasicResponse<Device>>> } - promesa con la lista de los dispositivos
 */
export const getAdvanceSearchDeviceService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<Device>>> => {
  try {
    const response: AxiosResponse<StandardResponse<ListBasicResponse<Device>>> =
      await instance.post(DEVICE_ADVANCE_SEARCH, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Retorna el template para descargar el archivo CSV dependiendo la búsqueda
 * @param { AdvanceSearch } body - informacion de la búsqueda
 * @returns { Promise<StandardResponse<ResponseTemplate>> } promesa con el template del archivo que se piensa descargar
 */
export const getAdvanceSearchDeviceCsvService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(DEVICE_ADVANCE_SEARCH_CSV, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
