import { Grid } from "@material-ui/core";
import { NoDeviceIcon } from "../../../components/Icons/NoDeviceIcon";
import { CustomTypography } from "@dg-bucaramanga/react-components-dg-qa";

/**
 * Retorna componente con mensaje cuando no encuentra o no tiene dispositivos
 * @param { string } rol - Rol del usuario
 * @returns { JSX.Element } Mensaje cuando no hay dispositivos
 */

const notDataFound = (rol?: string) => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoDeviceIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography
          type="definition"
          children={
            rol === "BIOMETRIA RNEC"
              ? "Aún no se ha creado un dispositivo"
              : "Aún no has creado un dispositivo"
          }
        />{" "}
      </Grid>
    </Grid>
  );
};

export default notDataFound;
