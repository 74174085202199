import { makeStyles, Theme } from "@material-ui/core";
import { makeId } from "../../utils/general/utils";
type CircularTabProps = {
  tabs: number;
  currentTab: number;
};

export const CircularTab = ({ currentTab, tabs }: CircularTabProps) => {
  const classes = useStyles();
  const tabsArray = new Array(tabs).fill(0);
  return (
    <div className={classes.root}>
      {tabsArray.map((tab, index) => (
        <div
          key={makeId(3)}
          className={
            index === currentTab
              ? classes.circularContainerSelected
              : classes.circularContainer
          }
        ></div>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  circularContainerSelected: {
    height: "10px",
    backgroundColor: theme.palette.secondary.main,
    width: "10px",
    margin: "2px",
    boxShadow: "0px 2px 3px #0000004D",
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    opacity: 1,
  },
  circularContainer: {
    height: "10px",
    backgroundColor: theme.palette.common.white,
    width: "10px",
    margin: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
    opacity: 1,
  },
}));
