import React, { useState, useEffect, memo } from "react";
import {
  CustomTypography,
  FullLoader,
} from "@dg-bucaramanga/react-components-dg-qa";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import { SelectInputRounded } from "../../components/Inputs/SelectInputRounded";
import ViewLayout from "../../components/Layouts/ViewLayout";
import * as yup from "yup";
import {
  getAllModels,
  getDeviceTypesByModel,
} from "../../services/CommonListService";
import { CommonListSelector } from "../../store/slices/CommonListsSlice";
import { Device, IAudit } from "../../store/types";
import { REQUIRED_MESSAGE } from "../../config/const";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createDeviceAC,
  updateDeviceAC,
  deviceAuditAC,
  getDevicesAC,
  getDevicesByBusinessUnitAC,
} from "../../store/action-creators/DeviceActionCreators";
import { getBasicComputerEquipmentBusinessUnit } from "../../services/ComputerEquipmentServices";
import {
  CommonSelector,
  setError,
  setSuccess,
} from "../../store/slices/CommonSlice";
import {
  DeviceSelector,
  setCurrentDevice,
  setDevices,
  setIsDeviceEdit,
  setLoadingDevice,
  setShowForm,
} from "../../store/slices/DeviceSlice";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { DeviceIcon } from "../../components/Icons/DeviceIcon";
import ErrorIcon from "../../assets/Imagen_Error.svg";
import { validationSerial } from "../../utils/general/masks";
import { BusinessUnitSelector } from "../../store/slices/BusinessUnitSlice";

const schema = yup.object().shape({
  brand: yup.number().required(REQUIRED_MESSAGE),
  model: yup.number().notOneOf([0]).required(REQUIRED_MESSAGE),
  type: yup.number().required(REQUIRED_MESSAGE),
  serial: yup
    .string()
    .max(50, "El serial debe tener como máximo 50 caracteres.")
    .required(REQUIRED_MESSAGE),
  businessUnit: yup.number().required(REQUIRED_MESSAGE),
  equipment: yup.number().notOneOf([0]).required(REQUIRED_MESSAGE),
});

type DeviceFormProps = {
  justForm?: boolean;
  customClose?: Function;
};

/**
 * Retorna formulario para crear o editar los dispositivos de las sedes
 * @param { boolean } justForm - Booleano que valida si muestra solo el formulario o necesita un layout
 * @param { Function } customClose - Función para cerrar el formulario cuando este se encuentra dentro de otro componente
 * @returns { JSX.Element } formulario de dispositivos
 */

const DeviceForm: React.FC<DeviceFormProps> = ({ justForm, customClose }) => {
  // Redux
  const dispatch = useDispatch();
  const {
    currentDevice,
    isDeviceEdit: isEdit,
    diviceAudit,
    paginatorDevice,
  } = useSelector(DeviceSelector);
  const {
    brands,
    businessUnits,
    equipments,
    models,
    types,
    computerEquipmentEdit,
  } = useSelector(CommonListSelector);
  const { currentBusiness } = useSelector(BusinessUnitSelector);

  // form RHF >7
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm<Device>({
    shouldUnregister: false,
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: currentDevice
      ? currentDevice
      : {
          brand: 0,
          businessUnit: 0,
          equipment: 0,
          state: true,
        },
  });
  // local states
  const [message, setMessage] = useState("");
  const [isFocusSerial, setIsFocusSerial] = useState<boolean>(false);
  const [dataAudit, setDataAudit] = useState<IAudit>();
  const [dataEquipment, setDataEquipment] = useState<any[]>([]);
  const [modelSelector, setModelSelector] = useState<any[]>([]);
  const { loading, error, success, rol } = useSelector(CommonSelector);

  /**
   * Borra el dispositivo actual guardado en Redux y setea en falso el estado showForm el cual sirve para mostrar/ocultar el formulario
   */
  const handleBack = () => {
    dispatch(setShowForm(false));
    dispatch(setCurrentDevice(undefined));
  };

  /**
   * Busca y realiza una auditoría del dispositivo actual seleccionado.
   */
  const handleAudit = () => {
    if (currentDevice) {
      dispatch(deviceAuditAC(currentDevice, setLoadingDevice));
    }
  };
  /**
   * llama servicio modelos por id de sede
   * @param { any } value id de la sede
   */
  const getEquipmentByBusinessService = (value: any) => {
    getBasicComputerEquipmentBusinessUnit({
      BusinessUnitId: value,
      Paginator: { itemsPage: 0, page: 0 },
      filter: "",
      sort: "recent",
    })
      .then((response) => {
        if (response.statusCode === 200) {
          setDataEquipment(response.result.records);
        }
      })
      .catch((err) => console.error(err));
  };
  /**s
   * llama servicio modelos por id de marca
   * @param { any } value id de la marcsedea
   */
  const getModelsByBranchService = (value: any) => {
    getAllModels(value)
      .then((response) => {
        if (response.statusCode === 200) {
          setModelSelector(response.result.records);
        }
      })
      .catch((err) => console.error(err));
  };
  /**
   * llama servicio tipos de dispositivos por id del modelo
   * @param { any } value id del modelo
   */
  const getDeviceTypeByModelService = (value: any) => {
    getDeviceTypesByModel(value)
      .then((response) => {
        if (response.statusCode === 200) {
          setValue("type", response.result.id);
        }
      })
      .catch((err) => console.error(err));
  };
  /**
   * Busca modelos por id de marca
   * @param { any } field id de la marca
   */
  const handleOnChangeBrandName = (field: any) => {
    getModelsByBranchService(field);
    if (field !== currentDevice?.brand) {
      setValue("model", 0);
      setValue("type", 0);
    }
  };
  /**
   * Busca equipos por id de sede
   * @param { any } field id de la sede
   */
  const handleEquipmentByBusinessUnit = (field: any) => {
    if (field !== currentDevice?.equipment) {
      setValue("equipment", 0);
    }
    getEquipmentByBusinessService(field);
  };

  /**
   * Busca de tipos de despositivos por modelo
   * @param { any } field id de el modelo
   */
  const handleOnChangeModel = (field: any) => {
    getDeviceTypeByModelService(field);
  };
  /**
   * Función para enviar y procesar los datos del formulario relacionados con dispositivos.
   * @param { any } data - Los datos del formulario que se enviarán.
   */
  const submitForm = (data: any) => {
    if (currentDevice) {
      const device: Device = {
        ...data,
        id: currentDevice.id,
      };
      if (device.serial.trim().length === 0) {
        setMessage("El campo Serial no puede estar vacio.");
      } else if (device.model > 0) {
        dispatch(updateDeviceAC(device));
      } else {
        setMessage("El campo Modelo no esta seleccionado.");
      }
    } else {
      if (data.serial.trim().length === 0) {
        setMessage("El campo Serial no puede estar vacio.");
      } else {
        dispatch(createDeviceAC(data));
        // dispatch(setCurrentDevice(data));
      }
    }
  };
  /**
   * Funcion para manejar el enfoque del campo MAC/IMEI
   * @param { React.FocusEvent<HTMLInputElement> } event - los datos del formulario que se enviara
   */
  const focusHandlerMac = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocusSerial(true);
  };
  /**
   * Funcion que valida los caracteres del campo serial
   * @param { any } event - los datos del formulario que se enviara
   */
  const handleForm = (event: any) => {
    switch (event.target.name) {
      case "serial":
        setValue("serial", validationSerial(event.target.value));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setModelSelector(models);
    setDataEquipment(equipments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, equipments]);

  useEffect(() => {
    if (error.length > 0) {
      setMessage(error);
    } else if (success.length > 0) {
      setMessage(success);
      reset();
    } else {
      setMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success]);

  useEffect(() => {
    if (diviceAudit) {
      setDataAudit(diviceAudit);
    }
  }, [diviceAudit]);

  const DeviceForm = () => {
    return (
      <div>
        <form onChange={handleForm} onSubmit={handleSubmit(submitForm)}>
          <Grid container spacing={2}>
            <Grid container item lg={6} md={8} sm={12} xs={12} spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInputRounded
                  control={control}
                  label={"Marca"}
                  name={"brand"}
                  props={{
                    helperText: errors.brand?.message,
                    error: errors.brand ? true : false,
                    disabled: currentDevice?.haveProcess? true : !isEdit,
                  }}
                  options={brands?.map((item) => ({
                    key: item.id,
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={handleOnChangeBrandName}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInputRounded
                  control={control}
                  label={"Modelo"}
                  name={"model"}
                  props={{
                    helperText: errors.model?.message,
                    error: errors.model ? true : false,
                    disabled: currentDevice?.haveProcess? true : !isEdit,
                  }}
                  options={modelSelector?.map((item) => ({
                    key: item.id,
                    label: item.name,
                    value: item.id,
                    disabled: !isEdit,
                  }))}
                  onChange={handleOnChangeModel}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInputRounded
                  control={control}
                  label={"Tipo"}
                  name={"type"}
                  props={{
                    helperText: errors.type?.message,
                    error: errors.type ? true : false,
                    disabled: true,
                  }}
                  options={types?.map((item) => ({
                    key: item.id,
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputRounded
                  control={control}
                  label="Serial"
                  name="serial"
                  props={{
                    autoFocus: isFocusSerial,
                    onFocus: focusHandlerMac,
                    helperText: errors.serial?.message,
                    error: errors.serial ? true : false,
                    disabled: currentDevice?.haveProcess? true : !isEdit,
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInputRounded
                  control={control}
                  label={"Sede"}
                  name={"businessUnit"}
                  props={{
                    name: "businessUnit",
                    helperText: errors.businessUnit?.message,
                    error: errors.businessUnit ? true : false,
                    disabled: !isEdit,
                  }}
                  onChange={handleEquipmentByBusinessUnit}
                  options={businessUnits?.map((item) => ({
                    key: item.id,
                    label: item.name,
                    value: `${item.id}`,
                  }))}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInputRounded
                  control={control}
                  label={"Equipo"}
                  name={"equipment"}
                  props={{
                    helperText: errors.equipment?.message,
                    error: errors.equipment ? true : false,
                    disabled: !isEdit,
                  }}
                  options={dataEquipment?.map((item: any) => ({
                    key: item.id,
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <div style={{ paddingRight: 10 }}>
                  <Grid item lg={1} md={2} sm={2} xs={2}>
                    <CustomTypography type="definition">
                      Activo
                    </CustomTypography>
                  </Grid>
                </div>
                <Grid>
                  <ControlledSwitch
                    control={control}
                    name="state"
                    isDisabled={
                      rol !== "BIOMETRIA RNEC" && currentDevice?.idState === 2
                        ? true
                        : !isEdit
                    }
                    checked={true}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={8}
                sm={12}
                xs={12}
                justifyContent="flex-end"
              >
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <PrimaryButton
                    title={currentDevice ? "Actualizar" : "Guardar"}
                    props={{ type: "submit", disabled: !isValid || !isEdit }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} sm={4} md={1} xs={1}></Grid>
          </Grid>
          <AlertIconModal
            open={message ? true : false}
            handleClose={() => {
              if (customClose) {
                const { paginator, filter, sort } = paginatorDevice;
                customClose();
                setMessage("");
                dispatch(setError(""));
                dispatch(setSuccess(""));
                /* Importante!
                  Esta seccion corrige un problema grande y confuso, como la vista es compartida por sedes y por equipos necesitamos saber
                  cuando usar el currentBusiness o el computerEquipment y no enredarse con el currentDevice que solo se deberia lanzar cuando
                  se edita el dispositivo desde la misma vista, excepto cuando estamos en sedes, en este caso debemos permanecer la vida del estado de currentBusiness
                  asi podemos editar y ver la misma informacion sobre la sede que se esta trabajando, lo mismo pasa cuando editamos desde equipo, debemos ignorar el currenBusiness
                  para solo usar el computerEquipment, es por eso que en la vista de equipos no vamos a usar currentBusiness
                  en el objeto que se envia en getDevicesByBusinessUnitAC la sede solo se debe habilitar cuando currentBusiness este activo o cuando estemos dentro de
                  la vista de device, anteriormente se lanzaba por error el id de la sede del dispositivo al que se estaba editando en la vista de equipos, es por eso
                  que se le reforzó el condicional para que si o si, estando el computer equipment lleno (dentro de la vista de equipos) no se lance la sede del dispositivo.
                  Dentro de la misma funcion, la key que se envia como Equipment se debe lanzar solo en la vista de equipos, es por eso que se reforzó el condicional para que
                  cuando se esté en la vista de sedes no se envie el id del equipo del dispositivo que se está editando, porque solo va a buscar el equipo relacionado este
                  asi que solo se debe lanzar el id de la sede.
                  por otro lado cuando se está editando el dispositivo desde la misma vista de dispositivo ignoramos todo esto porque será redireccionado a la vista principal
                  con todos los dispositivos y se limpiará el objeto.
                */
                dispatch(
                  getDevicesByBusinessUnitAC({
                    BusinessUnit:
                      currentBusiness !== undefined
                        ? currentBusiness.id !== undefined
                          ? currentBusiness.id
                          : 0
                        : currentDevice !== undefined &&
                          currentBusiness === undefined &&
                          computerEquipmentEdit === 0
                        ? currentDevice?.businessUnit
                        : 0,
                    Paginator: paginator,
                    filter: filter,
                    sort: sort,
                    Equipment:
                      computerEquipmentEdit > 0
                        ? computerEquipmentEdit
                        : currentDevice !== undefined &&
                          currentBusiness === undefined
                        ? currentDevice?.equipment
                        : 0,
                  })
                );
                dispatch(setShowForm(false));
                dispatch(setIsDeviceEdit(false));
              } else {
                if (success) {
                  setMessage("");
                  dispatch(setShowForm(false));
                  dispatch(setSuccess(""));
                  dispatch(setCurrentDevice(undefined));
                  dispatch(setDevices([]));
                  dispatch(getDevicesAC(paginatorDevice));
                } else {
                  setMessage("");
                  dispatch(setError(""));
                }
              }
            }}
            icon={
              success ? (
                <DeviceIcon width={"92px"} height={"80px"} />
              ) : (
                <div>
                  <img
                    src={ErrorIcon}
                    style={{ width: "92px", height: "80px" }}
                    alt="ErrorIcon"
                  />
                </div>
              )
            }
            message={message}
          />
        </form>
      </div>
    );
  };

  return (
    <div>
      <div>
        {justForm ? (
          <DeviceForm />
        ) : (
          <ViewLayout
            isNew={currentDevice ? false : true}
            isEdit={isEdit}
            setIsEdit={() => dispatch(setIsDeviceEdit(!isEdit))}
            headerTitle={`${!currentDevice ? "Crear" : "Editar"} dispositivo`}
            handleIconClick={handleBack}
            editOption={true}
            handleAudit={() => handleAudit()}
            dataAudit={dataAudit}
            fileName="Auditoría Dispositivo"
          >
            <DeviceForm />
          </ViewLayout>
        )}
      </div>
      <FullLoader open={loading} viewLoader={true} />
    </div>
  );
};

export default memo(DeviceForm);
