import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import { StandardResponse } from "../type";
import { IDataATDPFNAINT } from "../store/models/CreateAtdp";
import {
  IConsumptionFNAReport,
  IFNAFormReport,
} from "../store/models/FNAModel";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const CANDIDATE_FNA = "ConsultFNA/CandidateFNA ";
const CONSUMPTION_REPORT = "ConsultFNA/ConsumptionFNAReport";
/**
 * @description Servicio trae informacion de candidato FNA
 * @returns { StandardResponse<IDataATDPFNAINT> } Informacion de candidato
 */
export const getDataParticipantFNA = async (
  enqueueSnackbar: any,
  stopLoading: any
): Promise<StandardResponse<IDataATDPFNAINT>> => {
  try {
    const response: AxiosResponse<StandardResponse<IDataATDPFNAINT>> =
      await instance.get(CANDIDATE_FNA, defaultHeaders());
    stopLoading();
    enqueueSnackbar("Candidato agregado", {
      variant: "success",
    });
    return response.data;
  } catch (error) {
    enqueueSnackbar("No se encuentra una solicitud vigente", {
      variant: "error",
    });
    stopLoading();
    throw error;
  }
};

export const getConsumptionFNAReport = async (
  body: IFNAFormReport
): Promise<StandardResponse<IConsumptionFNAReport>> => {
  try {
    const response: AxiosResponse<StandardResponse<IConsumptionFNAReport>> =
      await instance.post(CONSUMPTION_REPORT, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
