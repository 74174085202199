import axios, { AxiosResponse } from "axios";
import { GeneralLog, StandardResponse } from "../store/types";
import { BIOMETRICS_URL } from "../config/const";
import {
  CreateParametersConfig,
  IBasicData,
  PersonalizationResponseDto,
  SettingsResponseDto,
} from "../store/actionTypes";
import { ICompany } from "../store/models/Company";
import { ListBasicResponse } from "../store/models/ListBasicResponse";
import { PaginatorWithFilterAndSort, AdvanceSearch } from "../store/types";
import { ResponseTemplate } from "../type";

const ADVANCE_SEARCH = "/Company/AdvanceSearch";
const COMPANIES_URL = "/Company/getCompaniesByUser";
const PERSONALIZATION_URL = "/Company/getPersonalization";
const PARAMETRIZATION_URL = "/Parametrization/Setting";
const REPOSITORIES_URL = "/Repository/repositories";
const CURRENT_COMPANY = "/Company/currentcompany";

const AUDITLOG_ALL_COMPARISON = "RNEC/Comparison";
const AUDITLOG_ALL_CONSULT = "RNEC/Consult";
const AUDITLOG_ALL_UNSATISFACTORYANSWER = "RNEC/UnsatisfactoryAnswer";
/**
 * Function that returns the value of the token in the updated SessionStorage
 */

function instance() {
  return axios.create({
    baseURL: BIOMETRICS_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
    },
  });
}

/**
 * @description Servicio de biometria para busqueda avanzada en el modulo de compañias
 * @param { AdvanceSearch } body - parametro que representa a un objeto del tipo AdvanceSearch
 * @returns { AxiosResponse<StandardResponse<ListBasicResponse<ICompany>>> } AxiosResponse<StandardResponse<ListBasicResponse<ICompany>>>
 */
export const advanceSearch = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ICompany>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ICompany>>
    > = await instance().post(ADVANCE_SEARCH, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria para obtener las compañias a la que está relacionado el usuario logueado
 * @param { string } userId - Parametro que representa el id del usuario
 * @param { PaginatorWithFilterAndSort } paginator - Parametro que representa la busqueda, ordenado o paginador
 * @returns { AxiosResponse<StandardResponse<ListBasicResponse<ICompany>>> } AxiosResponse<StandardResponse<ListBasicResponse<ICompany>>>
 */
export const getCompanyByUser = async (
  userId: string,
  paginator: PaginatorWithFilterAndSort
): Promise<StandardResponse<ListBasicResponse<ICompany>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ICompany>>
    > = await instance().post(COMPANIES_URL, paginator);

    return response.data;
  } catch (error) {
    console.error(error + userId);
    throw error;
  }
};

/**
 * @description Servicio de biometria obtener la compañia mediante el id almacenado en el token
 * @returns { AxiosResponse<any> } AxiosResponse<any>
 */
export const getCompanyById = async () => {
  try {
    const response = await instance().get(CURRENT_COMPANY);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Servicio de biometria que nos devuelve la informacion si existe algun repositorio donde se puede almacenar el documento ATDP
 * @param {string} token - Cadena de string que representa el token del usuario logueado
 * @returns {StandardResponse<IBasicData[]>} StandardResponse<IBasicData[]>
 */
export const getRepositories = async (token: string): Promise<any> => {
  try {
    const response: StandardResponse<IBasicData[]> = await instance().get(
      REPOSITORIES_URL,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @description Servicio de biometria que se envia para almacenar por primera vez la parametrizacion de la compalia
 * @param { CreateParametersConfig } body - Parametro de tipo CreateParametersConfig que contiene la parametrizacion y el ID de la compañia
 * @param { string } token - Parametro que representa una cadena de string con el token del usuario logueado
 * @returns { StandardResponse<string> } string
 */
export const createParametrization = async (
  body: CreateParametersConfig,
  token: string
) => {
  try {
    const response: StandardResponse<string> = await instance().post(
      PARAMETRIZATION_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @description Servicio de biometria que retorna la parametrizacion de la compañia
 * @param { String } token - Parametro que representa una cadena de string con el token del usuario logueado
 * @param { string } companyId - Parametro que representa el id de la compañia
 * @returns { StandardResponse<any> } any
 */
export const getParametrization = async (
  token: String,
  companyId: string
): Promise<AxiosResponse<StandardResponse<SettingsResponseDto>>> => {
  try {
    const response = await instance().get(PARAMETRIZATION_URL, {
      headers: { Authorization: `Bearer ${token}`, companyId: companyId },
    });

    return response;
  } catch (error: any) {
    return error;
  }
};

/**
 * @description Servicio de biometria que se envia para actualizar la informacion de la parametrizacion de la compañia
 * @param { CreateParametersConfig } body - Parametro de tipo CreateParametersConfig que contiene la parametrizacion y el ID de la compañia
 * @param { string } token - Parametro que representa una cadena de string con el token del usuario logueado
 * @returns { StandardResponse<string> } string
 */
export const updateParametrization = async (
  body: CreateParametersConfig,
  token: string
) => {
  try {
    const response: StandardResponse<string> = await instance().put(
      PARAMETRIZATION_URL,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @description Servicio de biometria que retorna la personalizacion de estilos de la compañia
 * @param { String } token - Parametro que representa una cadena de string con el token del usuario logueado
 * @returns { AxiosResponse<any> } any
 */
export const getPersonalization = async (
  token: string
): Promise<AxiosResponse<StandardResponse<PersonalizationResponseDto>>> => {
  try {
    const response = await instance().get(PERSONALIZATION_URL, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (error: any) {
    return error;
  }
};

/**
 * @description Servicio de biometria que retorna el log de todos los comparison realizados por la compañia con la estructura ResponseTemplate
 * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
 * @returns { AxiosResponse<StandardResponse<ResponseTemplate>> } ResponseTemplate
 */
export const getAllLogComparisonCsvService = async (
  body: GeneralLog
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance().post(AUDITLOG_ALL_COMPARISON, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna los logs de consultas con la estructura ResponseTemplate
 * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
 * @returns { AxiosResponse<StandardResponse<ResponseTemplate>> } ResponseTemplate
 */
export const getAllLogConsultCsvService = async (
  body: GeneralLog
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance().post(AUDITLOG_ALL_CONSULT, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna todos los logs de respuesta no satisfactoria con la estructura ResponseTemplate
 * @param { GeneralLog } body - Parametro de tipo GeneralLog que contiene el item date de tipo Date
 * @returns { AxiosResponse<StandardResponse<ResponseTemplate>> } ResponseTemplate
 */
export const getAllLogUnsatisfactoryAnswerCsvService = async (
  body: GeneralLog
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance().post(AUDITLOG_ALL_UNSATISFACTORYANSWER, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
