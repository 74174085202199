import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

// ACTIONSCREATORS
import {
  getBrandsAC,
  getBusinessUnitAC,
  getDeviceTypesAC,
  getModelsAC,
} from "../../store/action-creators/CommonListActionCreators";
import {
  computerEquipmentAuditAC,
  createComputerEquipmentAC,
  getAllComputerEquipmentsAC,
  getComputerEquipmentsAC,
  getComputerEquipmentsByBusinessUnitAC,
  getEquipmentByBusinessUnitCommonAC,
  updateComputerEquipmentAC,
} from "../../store/action-creators/ComputerEquipmentActionCreator";
import { deviceAuditAC } from "../../store/action-creators/DeviceActionCreators";

// ASSETS
import ErrorIcon from "../../assets/Imagen_Error.svg";

// COMPONENTS
import DeviceView from "../Devices/DeviceView";
import DeviceForm from "../Devices/DeviceForm";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { AuditWrapper } from "../../components/Layouts/AuditWrapper";
import { CustomTypography } from "../../components/typography/CustomTypography";
import { Column } from "../../components/Table/types";
import { ComputerIcon } from "../../components/Icons/ComputerIcon";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { SelectInputRounded } from "../../components/Inputs/SelectInputRounded";
import { TabAdmin } from "../../components/Tabs/TabAdmin";

// CONSTS
import { REQUIRED_MESSAGE } from "../../config/const";

// CUSTOM HOOK
import useComputerEquipmentForm from "../../hooks/useComputerEquipmentForm";

//MODELS
import { ComputerEquipment } from "../../store/models/ComputerEquipmentModel";

// SLICES
import { CommonListSelector } from "../../store/slices/CommonListsSlice";
import {
  CommonSelector,
  setError as setErrorCommon,
  setSuccess,
} from "../../store/slices/CommonSlice";
import {
  ComputerEquipmentSelector,
  setComputerIsEdit,
  setCurrentComputerEquipment,
  setLoadingComputerEquipment,
  setShowForm,
} from "../../store/slices/ComputerEquipmentSlice";
import {
  DeviceSelector,
  setIsDeviceEdit,
} from "../../store/slices/DeviceSlice";
import { setCurrentDevice } from "../../store/slices/DeviceSlice";
import { BusinessUnitSelector } from "../../store/slices/BusinessUnitSlice";

// TYPES
import { Device } from "../../store/types";
import {
  validationImei,
  validationIp,
  validationMac,
  validationOnlyLetters,
} from "../../utils/general/masks";
import { regex } from "../../utils/general/utils";

type ComputerEquipmentFormProperties = {
  justForm?: boolean;
  customClose?: Function;
  newEquipment?: boolean | undefined;
  company?: string;
};
/**
 * Renderiza el formulario con o sin layout dependiendo de los paramaetros enviados.
 * @param { Object } options - opciones
 * @param { Object } options.justForm - validación para mostrar solo el formulario
 * @param { Object } options.customClose - función que cierra el formalario
 * @returns { JSX.Element } formulario con o sin layout
 */
const ComputerEquipmentForm: React.FC<ComputerEquipmentFormProperties> = ({
  justForm,
  customClose,
}) => {
  // Redux
  const dispatch = useDispatch();
  const {
    computerEquipment,
    isEdit,
    computerEquipamentAudit,
    paginatorEquipment,
  } = useSelector(ComputerEquipmentSelector);
  const { businessUnits } = useSelector(CommonListSelector);
  const { currentDevice, isDeviceEdit, diviceAudit } =
    useSelector(DeviceSelector);
  const { loading, error, success, rol } = useSelector(CommonSelector);
  const { currentBusiness } = useSelector(BusinessUnitSelector);
  // Estados locales
  const [tab, setTab] = useState(0);
  const [message, setMessage] = useState("");
  const [showDevices, setShowDevices] = useState(false);
  /**
   * Esquema de validaciones del formulario
   */
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(50, "El campo Nombre excede el tamaño permitido de 50 caracteres.")
      .required(REQUIRED_MESSAGE),
    os: yup.number().required(REQUIRED_MESSAGE),
    ip: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .matches(regex.Ip, "No es una IP válida"),
    fixedStation: yup.boolean(),
    mac: yup.string().when("fixedStation", {
      is: false,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim()
        .matches(regex.Mac, "No es una dirección MAC válida"),
    }),
    imei: yup.string().when("fixedStation", {
      is: true,
      then: yup
        .string()
        .min(16, "El campo IMEI debe tener mínimo 16 caracteres.") 
        .required(REQUIRED_MESSAGE),
    }),
    equipment: yup.number().notOneOf([0]).required(REQUIRED_MESSAGE),
    businessUnit: yup.number().required(REQUIRED_MESSAGE),
    state: yup.boolean(),
  });
  // Custom hooks
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<ComputerEquipment>({
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: computerEquipment
      ? { ...computerEquipment, imei: computerEquipment.mac }
      : { state: true, fixedStation: false },
    resolver: yupResolver(schema),
  });
  const watchFixedStation = watch("fixedStation");

  const {
    typeDevices,
    typeOperatingSystems,
    isFocusNombre,
    setFocusNombre,
    isFocusOs,
    setFocusOs,
    isFocusIp,
    setFocusIp,
    isFocusMac,
    setFocusMac,
    isFocusImei,
    setFocusImei,
  } = useComputerEquipmentForm(watchFixedStation, setValue);
  //#region

  useEffect(() => {
    if (error.length > 0) {
      setMessage(error);
    } else if (success.length > 0) {
      setMessage(success);
      reset();
    } else {
      setMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success]);

  useEffect(() => {
    if (justForm !== true) dispatch(getBusinessUnitAC());
  }, [justForm, dispatch]);
  //#endregion
  /**
   * Borra el equipo y cierra el formulario al ponerlo en false en Redux
   */
  const handleBack = () => {
    dispatch(setShowForm(false));
    dispatch(setCurrentComputerEquipment(undefined));
  };
  /**
   * Maneja el envio del formulario, ademas valida los campos Nombre, IP y MAC/IMEI según corresponda
   * @param { ComputerEquipment } data - información ´del equipo
   */
  const handleSubmitForm = (data: ComputerEquipment) => {
    const isFixedStation = getValues("fixedStation");

    const textOptionFixedStation = isFixedStation ? " MAC " : " IMEI ";

    if (!computerEquipment) {
      if (data.name.trim().length === 0 || data.ip?.trimEnd().length === 0) {
        setMessage(
          `Los campos Nombre, IP y ${textOptionFixedStation} no puede estar vacio.`
        );
      } else {
        dispatch(
          createComputerEquipmentAC({
            ...data,
            mac: !isFixedStation ? data.mac : data.imei || "",
          })
        );
      }
    } else {
      if (data.name.trim().length === 0 || data.ip?.trimEnd().length === 0)
        setMessage(
          `Los campos Nombre, IP y ${textOptionFixedStation}  no puede estar vacio.`
        );
      else {
        dispatch(
          updateComputerEquipmentAC({
            ...data,
            mac: !isFixedStation ? data.mac : data.imei || "",
          })
        );
      }
    }
  };
  /**
   * Maneja el evento focus de todos los campos.
   * Activa Nombre y desactiva los demas
   * @param { React.FocusEvent<HTMLInputElement> } event - datos enviados por el campo
   */
  const focusHandlerName = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocusNombre(true);
    setFocusIp(false);
    setFocusMac(false);
    setFocusImei(false);
  };
  /**
   * Maneja el evento focus de todos los campos.
   * Activa sistema operativo y desactiva los demas
   * @param { React.FocusEvent<HTMLInputElement> } event - datos enviados por el campo
   */
  const focusHandlerOs = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocusNombre(false);
    setFocusOs(true);
    setFocusIp(false);
    setFocusMac(false);
    setFocusImei(false);
  };
  /**
   * Maneja el evento focus de todos los campos.
   * Activa IP y desactiva los demas
   * @param { React.FocusEvent<HTMLInputElement> } event - datos enviados por el campo
   */
  const focusHandlerIp = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocusNombre(false);
    setFocusOs(false);
    setFocusIp(true);
    setFocusMac(false);
    setFocusImei(false);
  };
  /**
   * Maneja el evento focus de todos los campos.
   * Activa MAC y desactiva los demas
   * @param { React.FocusEvent<HTMLInputElement> } event - datos enviados por el campo
   */
  const focusHandlerMac = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocusNombre(false);
    setFocusOs(false);
    setFocusIp(false);
    setFocusMac(true);
    setFocusImei(false);
  };
  /**
   * Maneja el evento focus de todos los campos.
   * Activa IMEI y desactiva los demas
   * @param { React.FocusEvent<HTMLInputElement> } event - datos enviados por el campo
   */
  const focusHandlerImei = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocusNombre(false);
    setFocusOs(false);
    setFocusIp(false);
    setFocusMac(false);
    setFocusImei(true);
  };
  /**
   * Obtiene la auditoria del equipo seleccionado
   */
  const handleAudit = () => {
    if (computerEquipment) {
      dispatch(
        computerEquipmentAuditAC(computerEquipment, setLoadingComputerEquipment)
      );
    }
  };
  /**
   * Renderiza el campo imei o mac dependiendo la selección del usuario
   * @returns { JSX.Element } campo imei o mac
   */
  const renderInput = () => {
    if (!watchFixedStation) {
      return (
        <>
          <Grid item lg={7} md={8} sm={12} xs={12}>
            <InputRounded
              name="mac"
              control={control}
              label="MAC"
              props={{
                name: "mac",
                helperText: errors.mac?.message,
                error: errors.mac ? true : false,
                disabled: computerEquipment?.haveProcess ? true : !isEdit,
                onFocus: focusHandlerMac,
                autoFocus: isFocusMac,
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item lg={7} md={8} sm={12} xs={12}>
            <InputRounded
              name="imei"
              control={control}
              label="IMEI"
              props={{
                name: "imei",
                helperText: errors.imei?.message,
                error: errors.imei ? true : false,
                disabled: computerEquipment?.haveProcess ? true : !isEdit,
                onFocus: focusHandlerImei,
                autoFocus: isFocusImei,
              }}
            />
          </Grid>
        </>
      );
    }
  };
  /**
   * Valida que los datos ingresados al campo
   * @param { any } event - datos suministrados por el campo (input).
   */
  const handleForm = (event: any) => {
    switch (event.target.name) {
      case "name":
        setValue("name", validationOnlyLetters(event.target.value));
        break;
      case "ip":
        setValue("ip", validationIp(event.target.value));
        break;
      case "mac":
        setValue("mac", validationMac(event.target.value));
        break;
      case "imei":
        setValue("imei", validationImei(event.target.value));
        break;
      default:
        break;
    }
  };
  /**
   * Retorna el formaulario sin layout
   * @returns { JSX.Element } formulario de edición o creación de equipos
   */
  const SimpleForm = () => (
    <form onChange={handleForm} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={2}>
        <Grid item lg={7} md={8} sm={12} xs={12}>
          <InputRounded
            control={control}
            label="Nombre*"
            name="name"
            props={{
              name: "name",
              helperText: errors.name?.message,
              error: errors.name ? true : false,
              disabled: !isEdit,
              autoFocus: isFocusNombre,
              /* onBlur: blurHandlerName,   */
              onFocus: focusHandlerName,
            }}
          />
        </Grid>

        <Grid item lg={7} md={8} sm={12} xs={12}>
          <InputRounded
            control={control}
            label="IP"
            name="ip"
            props={{
              name: "ip",
              helperText: errors.ip?.message,
              error: errors.ip ? true : false,
              disabled: !isEdit,
              autoFocus: isFocusIp,
              onFocus: focusHandlerIp,
            }}
          />
        </Grid>
        <Grid container item lg={7} md={8} sm={12} xs={12} spacing={1}>
          <Grid item lg={2} md={4} sm={3} xs={3}>
            <CustomTypography
              type={watch("fixedStation") ? "disabled" : "definition"}
              children="Estación fija"
            />
          </Grid>
          <Grid item lg={1} md={2} sm={3} xs={3}>
            <ControlledSwitch
              control={control}
              name="fixedStation"
              isDisabled={!isEdit}
              checked={false}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={3} xs={3}>
            <CustomTypography
              type={watch("fixedStation") ? "definition" : "disabled"}
              children="Estación móvil"
            />
          </Grid>
        </Grid>

        {renderInput()}

        <Grid item lg={7} md={8} sm={12} xs={12}>
          <SelectInputRounded
            control={control}
            label={"Sistema operativo*"}
            name={"os"}
            props={{
              helperText: errors.os?.message,
              error: errors.os ? true : false,
              disabled: !isEdit,
              autoFocus: isFocusOs,
              onFocus: focusHandlerOs,
            }}
            options={typeOperatingSystems?.map((item) => ({
              key: item.id,
              label: item.name,
              value: `${item.id}`,
            }))}
          />
        </Grid>

        <Grid item lg={7} md={8} sm={12} xs={12}></Grid>
        <Grid item lg={7} md={8} sm={12} xs={12}>
          {!!typeDevices.length && (
            <SelectInputRounded
              control={control}
              label={"Tipo de equipo*"}
              name={"equipment"}
              props={{
                helperText: errors.equipment?.message,
                error: errors.equipment ? true : false,
                disabled: !isEdit,
              }}
              options={typeDevices?.map((item) => ({
                key: item.id,
                label: item.name,
                value: item.id,
              }))}
            />
          )}
        </Grid>
        <Grid item lg={7} md={8} sm={12} xs={12}>
          <SelectInputRounded
            control={control}
            label={"Sede*"}
            name={"businessUnit"}
            props={{
              helperText: errors.businessUnit?.message,
              error: errors.businessUnit ? true : false,
              disabled: !isEdit,
            }}
            options={businessUnits?.map((item) => ({
              key: item.id,
              label: item.name,
              value: `${item.id}`,
            }))}
          />
          {!businessUnits ? (
            <CustomTypography type="helperText">
              No hay Sedes creadas
            </CustomTypography>
          ) : (
            ""
          )}
        </Grid>
        <Grid container item lg={7} md={8} sm={12} xs={12}>
          <div style={{ paddingRight: 10 }}>
            <Grid item lg={1} md={2} sm={2} xs={2}>
              <CustomTypography type="definition">Activo</CustomTypography>
            </Grid>
          </div>
          <Grid item lg={1} md={2} sm={2} xs={2}>
            <ControlledSwitch
              control={control}
              name="state"
              isDisabled={
                rol !== "BIOMETRIA RNEC" && computerEquipment?.idState === 2
                  ? true
                  : !isEdit
              }
              checked={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={7}
          md={8}
          sm={12}
          xs={12}
          justifyContent="flex-end"
        >
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <PrimaryButton
              title={computerEquipment ? "Actualizar" : "Guardar"}
              props={{ type: "submit", disabled: !isEdit || !isValid }}
            />
          </Grid>
        </Grid>
      </Grid>
      <AlertIconModal
        open={message ? true : false}
        handleClose={() => {
          if (customClose) {
            const { paginator, filter, sort } = paginatorEquipment;
            customClose();
            setMessage("");
            dispatch(setErrorCommon(""));
            dispatch(setSuccess(""));
            /**Importante!
             *a la funcion aqui debajo se reforzó el condicional para que cuando se esté
            editando la sede y se acceda a la lista de equipos se permaneza la informacion
            de la sede al momento de regresar de la edicion de un equipo, anteriormente
            se enviaba la sede del equipo despues de actualizarse, generando un error en el listado
            se visualizaban los equipos de la sede de la que el equipo ha sido relacionado.
            el condicional posee un numero cero el cual nunca se va a acceder pero se debe poner porque éste campo
            no recibe datos number | undefined, solo number, pero no se va a llegar al caso de enviarse un id 0
            ya que se estará editando el equipo dentro de equipos o equipo dentro de sedes
             */
            dispatch(
              getComputerEquipmentsByBusinessUnitAC({
                BusinessUnitId:
                  currentBusiness !== undefined
                    ? currentBusiness.id !== undefined
                      ? currentBusiness.id
                      : 0
                    : computerEquipment !== undefined
                    ? computerEquipment.businessUnit
                    : 0,
                Paginator: paginator,
                filter: filter,
                sort: sort,
              })
            );
            dispatch(setShowForm(false));
            dispatch(setComputerIsEdit(false));
          } else {
            if (success) {
              dispatch(setShowForm(false));
              dispatch(setSuccess(""));
              dispatch(setCurrentComputerEquipment(undefined));
              dispatch(getAllComputerEquipmentsAC(paginatorEquipment));
              dispatch(getComputerEquipmentsAC());
            } else {
              dispatch(setErrorCommon(""));
            }
            setMessage("");
          }
        }}
        icon={
          success ? (
            <ComputerIcon width={"92px"} height={"80px"} />
          ) : (
            <div>
              <img
                src={ErrorIcon}
                style={{ width: "92px", height: "80px" }}
                alt="ErrorIcon"
              />
            </div>
          )
        }
        message={message}
      />
    </form>
  );
  /**
   * Maneja el cambio de las tabs
   * @param evt
   * @param { any } value - valor de la tab seleccionada
   */
  const handleChangeTab = (evt: any, value: any) => {
    setTab(value);
  };

  // DEVICES

  const handleComputerIsEdit = () => dispatch(setComputerIsEdit(!isEdit));

  const handleDeviceEdit = () => dispatch(setIsDeviceEdit(!isDeviceEdit));

  const handleDevices = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    _column: Column
  ) => {
    const currentDev: Device = {
      id: value.id,
      brand: value.brand,
      businessUnit: value.businessUnit,
      equipment: value.equipment,
      model: value.model,
      serial: value.serial,
      state: value.state,
      type: value.type,
      idState: value.idState,
      haveProcess: value.haveProcess
    };

    if (!showDevices) {
      dispatch(setCurrentDevice(currentDev));
      dispatch(getBrandsAC());
      dispatch(getModelsAC(value.brand));
      dispatch(getDeviceTypesAC());
      if (businessUnits.length <= 0) dispatch(getBusinessUnitAC());
      dispatch(
        getEquipmentByBusinessUnitCommonAC({
          BusinessUnitId: value.businessUnit,
          Paginator: { itemsPage: 0, page: 0 },
          filter: "",
          sort: "recent",
        })
      );
    }
    setShowDevices(true);
  };
  /**
   * Borra el dispositivo en Redux y cierra el formulario de dispositivos.
   */
  const handleBackDevice = () => {
    if (showDevices) {
      return setShowDevices(false);
    }
    setShowDevices(false);
    dispatch(setShowForm(false));
    dispatch(setIsDeviceEdit(true));
  };
  /**
   * Busca y realiza una auditoría del dispositivo actual seleccionado.
   */
  const handleAuditDevices = () => {
    if (currentDevice) {
      dispatch(deviceAuditAC(currentDevice, setLoadingComputerEquipment));
    }
  };
  /**
   * Retorna una lista con las tabs que se van a renderizar
   * @returns { Array<{ label: string, tabContent: JSX.Element }> } Un arreglo de objetos donde cada objeto representa una pestaña y contenido.
   */
  const HandleMenu = () => {
    return [
      {
        label: "Datos básicos",
        tabContent: (
          <AuditWrapper
            isNew={!computerEquipment ? true : false}
            isEdit={isEdit}
            setIsEdit={() => handleComputerIsEdit()}
            editOption={true}
            handleAudit={() => handleAudit()}
            fileName="Auditoría Equipo"
          >
            <SimpleForm />
          </AuditWrapper>
        ),
      },
      {
        label: "Dispositivos",
        tabContent: (
          <AuditWrapper
            isNew={!computerEquipment ? true : false}
            editOption={false}
          >
            <DeviceView
              tabletOnly={true}
              justTable={true}
              handleRowClick={handleDevices}
              equipment={computerEquipment?.id}
              hiddeMassiveButton={true}
            />
          </AuditWrapper>
        ),
      },
    ];
  };
  /**
   * Valida si el usuario va a editar un dispositivo por lo cual
   * renderiza el formulario de dispositivos de lo contrario renderiza las tab del equipo.
   * @returns { JSX.Element } formulario de dispositivos o las tab de equipos
   */
  const EquipmentFormContent = () => {
    if (showDevices) {
      return (
        <DeviceForm
          justForm={true}
          customClose={() => {
            setShowDevices(false);
          }}
        />
      );
    }

    return (
      <TabAdmin
        tab={tab}
        handleChangeTab={handleChangeTab}
        content={HandleMenu()}
      ></TabAdmin>
    );
  };

  return (
    <div>
      {justForm ? (
        <>
          <SimpleForm />
        </>
      ) : (
        <ViewLayout
          isNew={!computerEquipment ? true : false}
          isEdit={showDevices ? isDeviceEdit : isEdit}
          setIsEdit={() =>
            showDevices ? handleDeviceEdit() : handleComputerIsEdit()
          }
          headerTitle={showDevices ? "Dispositivos" : "Equipos"}
          handleIconClick={() =>
            showDevices ? handleBackDevice() : handleBack()
          }
          editOption={showDevices ? true : false}
          handleAudit={() =>
            showDevices ? handleAuditDevices() : handleAudit()
          }
          dataAudit={showDevices ? diviceAudit : computerEquipamentAudit}
          fileName={showDevices ? "Auditoria Dispositivos" : "Auditoría Equipo"}
        >
          <>
            {computerEquipment?.id ? <EquipmentFormContent /> : <SimpleForm />}
          </>
        </ViewLayout>
      )}
      <FullLoader open={loading} viewLoader={true} />
    </div>
  );
};

export default ComputerEquipmentForm;
