import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BasicResponse,
  CitiesResponse,
  StatesAndCitiesResponse,
  TypeEquipmentResponse,
} from "../models/ListBasicResponse";
import { CommonList } from "../types";
import { RootState } from "./rootReducer";

const initialState: CommonList = {
  brands: [],
  businessUnits: [],
  equipments: [],
  models: [],
  types: [],
  operatingSystems: [],
  typeComputerEquipment: [],
  cities: [],
  departments: [],
  repositories: [],
  statesAndCities: [],
  modelsType: [],
  businessUnitsEdited: 0,
  computerEquipmentEdit: 0,
  documentTypes: [],
  equipmentTypes: [],
};

const CommonListSlice = createSlice({
  name: "common-list",
  initialState,
  reducers: {
    brandList: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.brands = payload;
    },
    businessUnitList: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.businessUnits = payload;
    },
    equipmentsList: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.equipments = payload;
    },
    modelsList: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.models = payload;
    },
    modelsTypes: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.modelsType = payload;
    },
    typesList: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.types = payload;
    },
    setOperatingSystems: (
      state,
      { payload }: PayloadAction<TypeEquipmentResponse[]>
    ) => {
      state.operatingSystems = payload;
    },
    setTypeComputer: (
      state,
      { payload }: PayloadAction<TypeEquipmentResponse[]>
    ) => {
      state.typeComputerEquipment = payload;
    },
    setRepositories: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.repositories = payload;
    },
    setStatesAndCities: (
      state,
      { payload }: PayloadAction<StatesAndCitiesResponse<CitiesResponse>[]>
    ) => {
      state.statesAndCities = payload;
    },
    setBusinessUnitEdited: (state, { payload }: PayloadAction<number>) => {
      state.businessUnitsEdited = payload;
    },
    setComputerEquipmentEdit: (state, { payload }: PayloadAction<number>) => {
      state.computerEquipmentEdit = payload;
    },
    setDocumentTypes: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.documentTypes = payload;
    },
    setEquipmentsType: (state, { payload }: PayloadAction<BasicResponse[]>) => {
      state.equipmentTypes = payload;
    },
  },
});

export const {
  brandList,
  businessUnitList,
  equipmentsList,
  modelsList,
  typesList,
  modelsTypes,
  setOperatingSystems,
  setTypeComputer,
  setRepositories,
  setStatesAndCities,
  setBusinessUnitEdited,
  setComputerEquipmentEdit,
  setDocumentTypes,
  setEquipmentsType,
} = CommonListSlice.actions;

export const CommonListSelector = (state: RootState) => state.commonList;

export default CommonListSlice.reducer;
