import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BusinessUnitSelector,
  setBusinessMassive,
  setCurrentBusinessUnit,
  setEdit,
  setIsEditParametrization,
  setIsNew,
  setLoadingBusiness,
  setPaginatorBussiness,
  setShowForm,
} from "../store/slices/BusinessUnitSlice";
import { CommonSelector, setPrivilege } from "../store/slices/CommonSlice";
import { CommonListSelector } from "../store/slices/CommonListsSlice";
import {
  getBusinessUnitAC,
  getListStatesAndCitiesAC,
} from "../store/action-creators/CommonListActionCreators";
import {
  advanceSearchAC,
  advanceSearchCsvAC,
  getBusinessUnitsAC,
  getFilterBussinessAC,
  getMassiveBusinessTemplateAC,
  getPaginatorBussinessAC,
  getParametrizationAC,
  getSortBussinessAC,
  saveMassiveLoadAC,
  validateLoadStatusAC,
} from "../store/action-creators/BusinessUnitActionCreators";
import {
  StateLoad,
  FieldsAdvanceSearch,
  sortBy,
  Paginator,
} from "../store/types";
import loadLogo from "../assets/loadLogo.png";
import errorLogo from "../assets/errorLogo.png";
import backLogo from "../assets/onBack.png";
import uploadLogo from "../assets/onUpload.png";
import {
  IBusinessUnitError,
  ILoadBusinessUnit,
} from "../store/models/BusinessUnit";
import { Column } from "../components/Table/types";
import { UpdateState } from "../store/models/UpdateState";
import { setAdvanceSearch as setAdvanceSearchDevice } from "../store/slices/DeviceSlice";
import { setAdvanceSearch as setAdvanceSearchEquipment } from "../store/slices/ComputerEquipmentSlice";
import {
  getAllBusinessUnitsExcelService,
  updateBusinessUnitState,
} from "../services/BusinessUnitService";
import { createExcel } from "../utils/general/utils";
import { getPaginatorAC as getPaginatorACDevice } from "../store/action-creators/DeviceActionCreators";
import { getPaginatorAC as getPaginatorACEquipment } from "../store/action-creators/ComputerEquipmentActionCreator";
import { useMassiveLoad } from "./useMassiveLoad";
import { moduleNamesPluralMin } from "../utils/general/const";

interface BussinessUnitProps {
  nitCompany?: string;
}

/**
 * handle bussiness unit view, services and implementing components and render
 */
const useBussinessUnit = ({ nitCompany }: BussinessUnitProps) => {
  //Custom Hooks
  const { onUpload } = useMassiveLoad<ILoadBusinessUnit>();
  // redux
  const dispatch = useDispatch();

  const { statesAndCities, businessUnitsEdited } =
    useSelector(CommonListSelector);
  const {
    showForm,
    businessMassiveResponse,
    paginatorBussiness,
    sizeBussiness,
    currentBusiness,
    businessUnits,
    loadingBusiness,
  } = useSelector(BusinessUnitSelector);
  const { privilege, rol } = useSelector(CommonSelector);
  // LOCAL STATES REACT¿
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState(0);
  const [isLoad, setLoad] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [option, setOption] = useState(null);
  const [imageModal, setImageModal] = useState(loadLogo);
  const [messageModal, setMessageModal] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [showFormAdv, setShowFormAdv] = useState(false);
  const [confirmate, setConfirmate] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [itemsList, setItemsList] = useState<Array<StateLoad | undefined>>();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fieldsAdvanced: FieldsAdvanceSearch[] =
    rol === "BIOMETRIA RNEC"
      ? [
          {
            name: "codesede",
            title: "Código ",
            type: "text",
            value: "",
          },
          {
            name: "codedepto",
            title: "Departamento",
            type: "selectDpo",
            value: "",
          },
          {
            name: "codempio",
            title: "Municipio ",
            type: "selectMpo",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: "",
          },
          {
            name: "ModificationDate",
            title: "Fecha de modificación",
            type: "date",
            value: "",
          },
          {
            name: "statesede",
            title: "Estado ",
            type: "status",
            value: "",
          },
        ]
      : [
          {
            name: "codesede",
            title: "Código",
            type: "text",
            value: "",
          },
          {
            name: "namesede",
            title: "Nombre ",
            type: "text",
            value: "",
          },
          {
            name: "email",
            title: "Email",
            type: "text",
            value: "",
          },

          {
            name: "category",
            title: "Categoria",
            type: "selectCategory",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: "",
          },
          {
            name: "statesede",
            title: "Estado ",
            type: "status",
            value: "",
          },
        ];

  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    useState(fieldsAdvanced);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);

  const { paginator, filter } = paginatorBussiness;

  // FUNCTIONS
  const fillBussinessList = useCallback(() => {
    if (filter !== undefined && filter.length < 3 && paginator.page === 1) {
      dispatch(
        getBusinessUnitsAC(
          {
            ...paginatorBussiness,
            filter: "",
          },
          nitCompany
        )
      );
    } else {
      dispatch(
        getBusinessUnitsAC(
          {
            ...paginatorBussiness,
          },
          nitCompany
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorBussiness, dispatch, nitCompany, loadingBusiness]);

  /**
   * Muestra un modal de alerta con un mensaje y opciones de confirmación o cancelación.
   * @param { string } titleModal - titulo del modal
   * @param { string } messageModal - mensaje del modal
   * @param { any } imageModal - imagen del modal
   * @param { Object } validate - objeto con las opciones y el texto de las mismas
   */
  const alertModal = (
    titleModal: string,
    messageModal: string,
    imageModal: any,
    validate?: {
      confirmate: boolean;
      cancel: boolean;
      confirmText: string;
      cancelText: string;
    }
  ) => {
    setModal(true);
    setImageModal(imageModal);
    setTitleModal(titleModal);
    setMessageModal(messageModal);
    if (validate) {
      setConfirmate(validate?.confirmate);
      setCancel(validate?.cancel);
      setConfirmText(validate?.confirmText);
      setCancelText(validate?.cancelText);
    }
  };

  const onSucess = (value: Boolean) => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_SEDE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      if (value) {
        alertModal(
          "Cargue exitosamente",
          `Tu carga masiva fue un exito`,
          uploadLogo
        );
        dispatch(setBusinessMassive(undefined));
        dispatch(getBusinessUnitsAC(paginatorBussiness, nitCompany));
        setLoad(false);
      } else {
        alertModal("Error", "Error al guardar los datos", errorLogo);
      }
    }
  };

  const onSave = () => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_SEDE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      if (businessMassiveResponse !== undefined) {
        let bo = businessMassiveResponse.details.filter(
          (b) => b.estado === "exitoso" || b.estado === "reemplazado"
        );
        dispatch(saveMassiveLoadAC(bo, onSucess));
        alertModal(
          "Cargue masivo de sedes",
          "La informacion de tus sedes esta siendo cargada a la plataforma, recuerda revisarla",
          loadLogo,
          { confirmate: false, cancel: false, cancelText: "", confirmText: "" }
        );
      }
    }
  };
  /**
   * Función que ejecuta la función que valida el archivo seleccionado y valida si se puede subir o reemplazar o si esta mal
   * @param { any } e - datos del campo
   */
  const onUploadDataEvent = (e: any) =>
    onUpload(
      e,
      alertModal,
      validateLoadStatusAC,
      "telefono",
      moduleNamesPluralMin.businessUnit
    );

  const handleChangeTab = (evt: any, value: any) => {
    businessMassiveResponse !== undefined && setTab(value);
  };

  const handleBack = () => {
    if (isLoad && businessMassiveResponse !== undefined) {
      alertModal(
        "",
        "¿Estás seguro que deseas realizar esta acción?\n Si lo haces, perderás la información cargada",
        backLogo,
        { confirmate: true, cancel: true, confirmText: "SI", cancelText: "NO" }
      );
    } else {
      setLoad(false);
    }
  };

  const onCancel = () => {
    setModal(false);
    setConfirmate(false);
  };

  const onContinue = () => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_SEDE_CREATE") ===
      undefined
        ? false
        : true
    ) {
      dispatch(setBusinessMassive(undefined));
      setLoad(false);
      setModal(false);
      setConfirmate(false);
      setTab(0);
    }
  };

  const onDownload = async () => {
    //if(privilege?.find(privil=>privil==='BIOMETRIA_EQUIPO_READ')===undefined?true:false){
    dispatch(getMassiveBusinessTemplateAC());
    //}
  };

  const onDownloadErrors = () => {
    if (businessMassiveResponse !== undefined) {
      /*  const valores = {...currentData}; */

      const valores = businessMassiveResponse.details;
      let data: any = [];

      Object.keys(valores)?.forEach((key) => {
        const item = valores[parseInt(key, 10)];

        if (item.errores !== undefined && item.errores?.length) {
          const businessError: IBusinessUnitError = {
            codigo: item.codigo,
            dependiente: item.dependiente,
            sede: item.sede ?? "",
            nombre: item.nombre,
            telefono: item.telefono,
            email: item.email,
            direccion: item.direccion,
            departamento: item.departamento,
            ciudad: item.ciudad,
            error: item.errores ?? "",
          };
          data.push(businessError);
        }
      });
      // export excel by helper method
      const fill = (number: any, len: any) =>
        "0".repeat(len - number.toString().length) + number.toString();
      const currentDate =
        new Date().getFullYear() +
        "-" +
        fill(new Date().getMonth() + 1, 2) +
        "-" +
        new Date().getDate();
      createExcel(data, "Errores_Sedes_" + currentDate);
    }
  };

  // REACT HOOKS
  /* this callback was implemented to correct multiple launches of the common list service*/
  const listBusinessUnitCommon = useCallback(() => {
    if (rol !== "BIOMETRIA RNEC") {
      dispatch(getBusinessUnitAC());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Asegura que cada vez que entre al módulo muestre la tabla
   * en la primera página el filtro este vacio y el ordenamieto sea reciente
   */
  useEffect(() => {
    dispatch(setShowForm(false));
    return () => {
      dispatch(getFilterBussinessAC(""));
      dispatch(getSortBussinessAC("recent"));
      dispatch(getPaginatorBussinessAC(1, 10, true));
    };
  }, [dispatch]);

  useEffect(() => {
    !statesAndCities.length && dispatch(getListStatesAndCitiesAC());
    listBusinessUnitCommon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!privilege?.length) {
      dispatch(setPrivilege(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege?.length]);

  useEffect(() => {
    if (businessMassiveResponse !== undefined) {
      setModal(false);
      const items = new Array<StateLoad>();
      Promise.all(
        // eslint-disable-next-line array-callback-return
        businessMassiveResponse.details?.map((item) => {
          items.push({ name: item.nombre, state: item.estado });
        })
      ).then(() => {
        setTab(1);
        setItemsList(items);
      });
    }
  }, [businessMassiveResponse]);

  useEffect(() => {
    if (paginatorBussiness !== undefined && paginator.recharge) {
      if (advanceSearchState) {
        dispatch(
          advanceSearchAC({
            fields: fieldsAdvancedSearch,
            paginator: paginatorBussiness,
            company: nitCompany,
          })
        );
      } else {
        fillBussinessList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorBussiness]);

  useEffect(() => {
    if (businessUnitsEdited > 0) {
      listBusinessUnitCommon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnitsEdited]);

  const handleChangePage = (_event: any, newPage: any) => {
    // if (paginatorBussiness.paginator.page >= newPage) {
    setPage(newPage);
    dispatch(getPaginatorBussinessAC(newPage + 1, paginator.itemsPage, true));
    // }
  };

  const handleConfirmClick = (e: any, value: any) => {
    const state: UpdateState = {
      id: value,
      state: e.target.checked,
      paginator: {
        page: paginator.page,
        itemsPage: paginator.itemsPage,
        recharge: true,
      },
    };
    dispatch(setLoadingBusiness(true));
    updateBusinessUnitState(state)
      .then(() => {})
      .catch((err) => console.error(err || "Exception updateBusinessUnitState"))
      .finally(() => {
        dispatch(setLoadingBusiness(false));
        // Paginator is thrown to refresh the data in datatable
        dispatch(
          setPaginatorBussiness(
            state.paginator === undefined
              ? { page: 1, itemsPage: 10 }
              : state.paginator
          )
        );
      });
  };

  const onDownloadData = () => {
    if (rol === "BIOMETRIA RNEC") {
      dispatch(
        advanceSearchCsvAC({
          fields: fieldsAdvancedSearch,
          paginator: paginatorBussiness,
          company: nitCompany,
        })
      );
    } else {
      dispatch(setLoadingBusiness(true)) &&
        getAllBusinessUnitsExcelService({
          fields: fieldsAdvancedSearch,
          paginator: paginatorBussiness,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              // Se crea una copia del objeto original sin el campo 'id'.

              const businessUnitsWithoutId = response.result.records.map(
                (unit) => {
                  const { id, ...newUnit } = unit;
                  return newUnit;
                }
              );

              createExcel(businessUnitsWithoutId, "Listado de Sedes");
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => dispatch(setLoadingBusiness(false)));
    }
  };

  const handleCellClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    _column: Column
  ) => {
    if (
      privilege?.find((privil) => privil === "BIOMETRIA_SEDE_MODIFY") ===
      undefined
        ? false
        : true
    ) {
      dispatch(setCurrentBusinessUnit(value));
      dispatch(setShowForm(true));
      dispatch(setIsNew(false));
      dispatch(setEdit(false));
      // Reinicia busqueda en la tab Equipos
      dispatch(setAdvanceSearchEquipment([]));
      dispatch(getPaginatorACEquipment(1, 10, true));
      // Reinicia busqueda en la tab dispositivos
      dispatch(setAdvanceSearchDevice([]));
      dispatch(getPaginatorACDevice(1, 10, true));
      dispatch(getParametrizationAC("", 0, value.id, 0));
      dispatch(setIsEditParametrization(false));
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getPaginatorBussinessAC(1, +event.target.value, true));
  };

  const handleFilter = (query: string) => {
    setAdvanceSearchState(false);
    setFieldsAdvancedSearch(fieldsAdvanced);
    dispatch(getFilterBussinessAC(query.trim()));
    dispatch(getPaginatorBussinessAC(1, paginator.itemsPage, true));
    setPage(0);
  };

  const handleSort = (sort?: sortBy) => {
    if (sort) {
      const paramsPaginator: Paginator = {
        page: 1,
        itemsPage: paginator.itemsPage,
        recharge: true,
      };
      dispatch(getSortBussinessAC(sort));
      dispatch(setPaginatorBussiness(paramsPaginator));
    }
  };

  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    const paramsPaginator: Paginator = { page: 1, itemsPage: 10 };
    dispatch(getFilterBussinessAC(""));
    dispatch(setPaginatorBussiness(paramsPaginator));
    dispatch(
      advanceSearchAC({
        fields: fieldsPassedAdvancedSearch,
        paginator: {
          ...paginatorBussiness,
          paginator: paramsPaginator,
        },
        company: nitCompany,
      })
    );
    setShowFormAdv(false);
    setAdvanceSearchState(true);
    setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    setPage(0);
  };

  const handleOnClose = () => {
    setShowFormAdv(false);
  };

  const handleOnOpen = () => {
    setShowFormAdv(true);
  };

  const handleOnCloseLoadModal = () => {
    setModal(false);
  };

  const handleOnLoadButton = () => {
    setLoad(true);
    setTab(0);
  };

  const handleShowForm = (value: boolean) => {
    dispatch(setShowForm(value));
  };

  //#endregion

  return {
    handleChangePage,
    statesAndCities,
    showForm,
    businessMassiveResponse,
    paginatorBussiness,
    sizeBussiness,
    currentBusiness,
    businessUnits,
    privilege,
    rol,
    loadingBusiness,
    page,
    setPage,
    fieldsAdvanced,
    onSave,
    onUploadDataEvent,
    handleChangeTab,
    handleBack,
    onCancel,
    onContinue,
    showFormAdv,
    handleConfirmClick,
    titleModal,
    cancel,
    modal,
    imageModal,
    confirmText,
    confirmate,
    tab,
    onDownload,
    option,
    handleChangeRowsPerPage,
    handleFilter,
    handleSort,
    rowsPerPage,
    handleCellClick,
    messageModal,
    cancelText,
    isLoad,
    itemsList,
    onDownloadErrors,
    onDownloadData,
    handleOnClose,
    handleOnCloseLoadModal,
    handleOnSearch,
    handleOnLoadButton,
    handleOnOpen,
    handleShowForm,
    advanceSearchState,
    fieldsAdvancedSearch,
  };
};

export default useBussinessUnit;
