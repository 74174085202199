import {
  advanceSearchTypeProcessCsv,
  advanceSearchTypeProcess,
  advanceSearchRnecTypeProcess,
  createTypeProcessService,
  getMassiveTypeProcessTemplate,
  getTypeProcessView,
  getTypeProcessViewCapturador,
  getTypeProcessViewRnec,
  saveMassiveLoad,
  typeProcessAuditService,
  updateTypeProcessService,
  validateLoadStatus,
} from "../../services/TypeProcessService";
import { CREATE_TYPE_PROCESS } from "../actionTypes";
import { ILoadTypeProcess } from "../models/TypeProcessUnit";
import { setHashCsv, setHashCsvModal } from "../slices/CommonSlice";
import { setLoadingFingerPrint } from "../slices/FingerPrintSlice";
import {
  auditTypeProcess,
  setError,
  setFilterTypeProcess,
  setLoadingTypeProcess,
  setPaginatorTypeProcess,
  setSizeTypeProcess,
  setsortTypeProcess,
  setSuccess,
  setTypeProcessMassiveResponse,
  TypeProcesses,
} from "../slices/TypeProcessSlice";
import { AppThunk } from "../store";
import {
  AdvanceSearch,
  ITypeProcess,
  PaginatorWithFilterAndSort,
  sortBy,
  TypeProcessAction,
} from "../types";

/**
 * @description Lanza el servicio createTypeProcessService que permite almacenar la informacion del type process
 * @param { ITypeProcess } typeProcess Parametro de Tipo ITypeProcess que contiene la informacion necesaria para almacenar un type process
 * @returns se realiza la solicitud a la API y dispara el dispatch con la informacion de el estado de el proceso al hook success
 */
export const createTypeProcessAC =
  (typeProcess: ITypeProcess): AppThunk =>
  (dispatch) => {
    const action: TypeProcessAction = {
      type: CREATE_TYPE_PROCESS,
      typeProcess,
    };
    dispatch(setLoadingTypeProcess(true));
    createTypeProcessService(action.typeProcess)
      .then((response) => {
        const { data } = response;
        const { information } = data.result;
        dispatch(setSuccess(information));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Este metodo lanza directamente el sub-indice paginator de el hook de paginatorTypeProcess los valores para la paginacion del Table
 * @param { number } page Parametro de tipo number que representa el numero de pagina
 * @param { number } itemsPage Parametro de tipo number que representa la cantidad de elementos por pagina
 * @param { boolean } recharge Parametro de tipo boolean que representa si el registro debe recargarse
 */
export const getPaginatorTypeProcessAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorTypeProcess({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

/**
 * @description Este metodo lanza directamente el sub-indice filter de el hook de paginatorTypeProcess el texto de busqueda para el filtro
 * @param { string } filter
 */
export const getFilterAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterTypeProcess(filter));
  };

/**
 * @description Este metodo lanza directamente el sub-indice sort de el hook de paginatorTypeProcess con el tipo sortBy
 * @param { sortBy } sort - Parametro de tipo sortBy ( "recent" | "old" | "active" | "inactive" | "a-z" | "z-a" )
 */
export const getSortAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setsortTypeProcess(sort));
  };

/**
 * @description Lanza el servicio getTypeProcessView que retorna los registros de type process organizados por la paginacion del lado del servidor o filtros de busqueda
 * @param { PaginatorWithFilterAndSort } paginator Parametro de tipo PaginatorWithFilterAndSort que representa los elementos de paginacion, ordenado o filtro
 * @param { string } company Parametro de tipo string que representa el id de la compañia
 * @returns { ListBasicResponse<ITypeProcessViewResponse> } ITypeProcessViewResponse[]
 */
export const getTypeProcessViewAC =
  (paginator: PaginatorWithFilterAndSort, company?: string): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    getTypeProcessView(paginator, company)
      .then((data) => {
        const { records, count } = data.result;
        dispatch(TypeProcesses(records));
        dispatch(setSizeTypeProcess(count));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio getTypeProcessView que retorna los registros de type process especificamente para el rol RNEC organizados por la paginacion del lado del servidor o filtros de busqueda
 * @param { PaginatorWithFilterAndSort } paginator Parametro de tipo PaginatorWithFilterAndSort que representa los elementos de paginacion, ordenado o filtro
 * @param { string } company Parametro de tipo string que representa el id de la compañia
 * @returns { ListBasicResponse<ITypeProcessViewRnecResponse> } ITypeProcessViewRnecResponse[]
 */
export const getTypeProcessViewRnecAC =
  (paginator: PaginatorWithFilterAndSort, company?: string): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    getTypeProcessViewRnec(paginator, company)
      .then((data) => {
        const { records, count } = data.result;
        dispatch(TypeProcesses(records));
        dispatch(setSizeTypeProcess(count));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio getTypeProcessView que retorna los registros de type process especificamente para el rol CAPTURADOR organizados por la paginacion del lado del servidor o filtros de busqueda
 * @param { PaginatorWithFilterAndSort } paginator Parametro de tipo PaginatorWithFilterAndSort que representa los elementos de paginacion, ordenado o filtro
 * @param { string } company Parametro de tipo string que representa el id de la compañia
 * @returns { ListBasicResponse<ITypeProcessViewCapturadorResponse> } ITypeProcessViewCapturadorResponse[]
 */
export const getTypeProcessViewCapturadorAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingFingerPrint(true));
  getTypeProcessViewCapturador()
    .then((data) => {
      const { records } = data.result;
      dispatch(TypeProcesses(records));
    })
    .catch((err) => console.error(err))
    .finally(() => dispatch(setLoadingFingerPrint(false)));
};

/**
 * @description Lanza el servicio updateTypeProcessService que permite actualizar la informacion de un type process
 * @param { ITypeProcess } typeProcess Parametro de Tipo ITypeProcess que contiene la informacion necesaria para actualizar un type process
 * @returns se realiza la solicitud a la API y dispara el dispatch con la informacion de el estado de el proceso al hook success
 */
export const updateTypeProcessAC =
  (typeProcess: ITypeProcess): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    updateTypeProcessService(typeProcess)
      .then((response) => {
        const { data } = response;
        const { information } = data.result;
        dispatch(setSuccess(information));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio getMassiveTypeProcessTemplate que retorna la informacion necesaria para realizar una carga masiva de type process
 * @returns se realiza la solicitud a la API y prepara la plantilla de carga masiva y al terminar la descarga automaticamente
 */
export const getMassiveTypeProcessTemplateAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingTypeProcess(true));
  getMassiveTypeProcessTemplate()
    .then((response) => {
      if (response.statusCode === 200) {
        const mimeType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const linkSource = `${mimeType}${response.result.downloadRoute}`;
        const downloadLink = document.createElement("a");
        const fileName =
          response.result.nameFile + "." + response.result.extensionFile;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        dispatch(setError(response.statusMessage));
      }
    })
    .catch((err) => dispatch(setError(err)))
    .finally(() => dispatch(setLoadingTypeProcess(false)));
};

/**
 * @description Lanza el servicio typeProcessAuditService que retorna la informacion para descargar la auditoria de un type process
 * @param { ITypeProcess } typeProcess Parametro de tipo ITypeProcess que representa la informacion de un type process
 * @returns se realiza la solicitud a la API y dispara el dispatch con la informacion de la auditoria del type process solicitado al hook typeProcessAudit
 */
export const typeProcessAuditAC =
  (typeProcess: ITypeProcess): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    typeProcessAuditService(typeProcess)
      .then((data) => {
        const audit = data.result;
        dispatch(auditTypeProcess(audit));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio saveMassiveLoad que almacena toda la informacion recopilada de la carga masiva de type process
 * @param { Array<ILoadTypeProcess> } body Parametro de tipo Array<ILoadTypeProcess> que contiene el array de objetos con la informacion de cada type process
 * @param  { any } onResponse Parametro de tipo any que puede funcionar como tipo Funcion que retorna al metodo padre el estado del proceso
 * @returns se lanza la funcion onResponse hacia el metodo padre con el estado del proceso
 */
export const saveMassiveLoadAC =
  (body: Array<ILoadTypeProcess>, onResponse: any): AppThunk =>
  (dispatch) => {
    saveMassiveLoad(body)
      .then((response) => {
        if (response.statusCode === 200) {
          onResponse(true);
          return true;
        } else {
          onResponse(false);
          return false;
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio advanceSearchTypeProcess donde se envia los elementos de advance search y se retorna una lista de type process.
 * @param { AdvanceSearch } body - este parametro representa los elementos del tipo AdvanceSearch
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de type process filtrada a el hook typeProcesses con el tipo ITypeProcessViewResponse[].
 */
export const advanceSearchTypeProcessAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    advanceSearchTypeProcess(body)
      .then((data) => {
        const { records, count } = data.result;
        dispatch(TypeProcesses(records));
        dispatch(setSizeTypeProcess(count));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio advanceSearchTypeProcess donde se envia los elementos de advance search y se retorna una lista de type process para el rol RNEC.
 * @param { AdvanceSearch } body - este parametro representa los elementos del tipo AdvanceSearch
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de type process filtrada a el hook typeProcesses con el tipo ITypeProcessViewRnecResponse[].
 */
export const advanceSearchRnecTypeProcessAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    advanceSearchRnecTypeProcess(body)
      .then((data) => {
        const { records, count } = data.result;
        dispatch(TypeProcesses(records));
        dispatch(setSizeTypeProcess(count));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio advanceSearchTypeProcess donde se envia los elementos de advance search y se retorna la informacion lista para descargar el CSV.
 * @param { AdvanceSearch } body - este parametro representa los elementos del tipo AdvanceSearch
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de type process filtrada a el hook typeProcesses con el tipo ResponseTemplate.
 */
export const advanceSearchCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    advanceSearchTypeProcessCsv(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const mimeType = "data:application/zip;base64,";
          const linkSource = `${mimeType}${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };

/**
 * @description Lanza el servicio validateLoadStatus al que se envia el array de objetos de los type process de la carga masiva para validar la informacion para almacenar
 * @param { Array<ILoadTypeProcess> } body Parametro de tipo Array<ILoadTypeProcess> que contiene el array de objetos de los type process para la carga masiva
 * @returns se realiza la solicitud a la API y dispara el dispatch con la lista de type process con el estado de los campos a el hook typeProcessMassiveResponse con el tipo TypeProcessMassiveResponse.
 */
export const validateLoadStatusAC =
  (body: Array<ILoadTypeProcess>): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingTypeProcess(true));
    return validateLoadStatus(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setTypeProcessMassiveResponse(response.result));
        } else {
          dispatch(setError(response.statusMessage));
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      })
      .finally(() => dispatch(setLoadingTypeProcess(false)));
  };
