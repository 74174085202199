import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import {
  IGeneralWebsocketResponse,
  responseSocket,
  IRNEC,
  IDocumentType,
  IProcessType,
  IBusinessUnitFingerInfo,
  IMultipleParticipantInfo,
  IFingerprintJustification,
} from "../../store/models/FingerPrintModel";
import { StandardResponse } from "../types";
/**
 * @type business unit state definition
 *  */
type CaptureFingerState = {
  responseCapture: IRNEC | null;
  responseSocket: Array<responseSocket>;
  documentType: Array<IDocumentType> | null;
  processType: Array<IProcessType> | null;
  businessUnitFinger: IBusinessUnitFingerInfo | null;
  multipleParticipantInfo: Array<IMultipleParticipantInfo>;
  selectProcess: {
    idProcessType: number;
    descriptionProcess: string | undefined;
  } | null;
  fingerDevice: StandardResponse<String> | undefined;
  loadingFingerPrint: boolean;
  fingerprintJustification: Array<IFingerprintJustification> | null;
};

/**
 * @property state initialization
 */
const initialState: CaptureFingerState = {
  responseCapture: null,
  responseSocket: [],
  documentType: null,
  processType: null,
  businessUnitFinger: null,
  selectProcess: null,
  multipleParticipantInfo: [],
  fingerDevice: undefined,
  fingerprintJustification: [],
  loadingFingerPrint: false,
};

/**
 * @function FingerPrintSlice redux state slice
 *
 * controls fingerprint capture states
 */

const FingerPrintSlice = createSlice({
  name: "fingerprint",
  initialState,
  reducers: {
    setFingerCapture: (state, { payload }: PayloadAction<IRNEC | null>) => {
      state.responseCapture = payload;
    },
    setResponseSocket: (
      state,
      { payload }: PayloadAction<Array<responseSocket>>
    ) => {
      state.responseSocket = payload;
    },
    setDocumentType: (
      state,
      { payload }: PayloadAction<Array<IDocumentType> | null>
    ) => {
      state.documentType = payload;
    },
    setProcessType: (
      state,
      { payload }: PayloadAction<Array<IProcessType> | null>
    ) => {
      state.processType = payload;
    },
    setBusinessUnitFinger: (
      state,
      { payload }: PayloadAction<IBusinessUnitFingerInfo | null>
    ) => {
      state.businessUnitFinger = payload;
    },
    setSelectProcess: (
      state,
      {
        payload,
      }: PayloadAction<{
        idProcessType: number;
        descriptionProcess: string | undefined;
      } | null>
    ) => {
      state.selectProcess = payload;
    },
    setMultipleParticipantInfo: (
      state,
      { payload }: PayloadAction<Array<IMultipleParticipantInfo>>
    ) => {
      state.multipleParticipantInfo = payload;
    },
    setFingerDevice: (
      state,
      { payload }: PayloadAction<StandardResponse<String> | undefined>
    ) => {
      state.fingerDevice = payload;
    },
    setFingerprintJustification: (
      state,
      { payload }: PayloadAction<Array<IFingerprintJustification>>
    ) => {
      state.fingerprintJustification = payload;
    },
    resetState: () => {
      return initialState;
    },
    setLoadingFingerPrint: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingFingerPrint = payload;
    },
  },
});

export const {
  setFingerCapture,
  setResponseSocket,
  setDocumentType,
  setProcessType,
  setBusinessUnitFinger,
  setSelectProcess,
  setMultipleParticipantInfo,
  setFingerDevice,
  setLoadingFingerPrint,
  resetState,
  setFingerprintJustification,
} = FingerPrintSlice.actions;

export const FingerPrintSelector = (state: RootState) => state.fingerprint;

export default FingerPrintSlice.reducer;
