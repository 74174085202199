import "./BigSwitch.css";

type BigSwitchProps = {
  checked: boolean;
  setChecked: Function;
  disabled?: boolean;
};

export const BigSwitch = ({
  checked,
  setChecked,
  disabled = false,
}: BigSwitchProps) => {
  return (
    <div className="toggle-button-cover">
      <div className="button-cover">
        <div className="button b2" id="button-10">
          <input
            disabled={disabled}
            type="checkbox"
            checked={!checked}
            className="checkbox"
            onChange={(e: any) => {
              setChecked(!e.target.checked);
            }}
          />
          <div className="knobs">
            <span>Heredado</span>
          </div>
          <div className="layer"></div>
        </div>
      </div>
    </div>
  );
};
