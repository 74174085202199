import {
  makeStyles,
  Button,
  ButtonGroup,
  Box,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { useState} from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((_theme: Theme) => ({
  label: {
    textAlign: "center",
    padding: "2px",
  },
  button: {
    border: "none",
    color: "#009BDD",
    width: "30px",
    
    "&.Mui-disabled": {
      border: "none !important"
    }
  },
  box: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 3px 3px #0000001A",
    border: "1px solid #D5D5D5",
    borderRadius: "10px",
    maxWidth: "78px",
  },
  buttonsContainer: {
    minWidth: "15px",
    "& .MuiButtonGroup-grouped": {
      minWidth: "15px",
    },
  },
}));

interface Props {
  handleValue: Function;
  max: number;
  min: number;
}

const HandleNumberParticipantsComponent = ({ handleValue, max, min }: Props) => {
  const classes = useStyles();

  const [count, setCount] = useState(1);
  
    // custom functions
  const handleChange = (countParam : number) => {
    setCount(countParam)
    handleValue(countParam)
  }
  
  return (
    <Box className={classes.box}>
      <ButtonGroup className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          aria-label="reduce"
          disabled={count > min ? false : true}
          onClick={() => {
            handleChange(Math.max(count - 1, 0));
          }}
        >
          <RemoveIcon fontSize="small" />
        </Button>

        <Typography className={classes.label} >
          {count}
        </Typography>
        <Button
          className={classes.button}
          aria-label="increase"
          disabled={count < max ? false : true}
          onClick={() => {
            handleChange(count + 1);
          }}
        >
          <AddIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default HandleNumberParticipantsComponent;
