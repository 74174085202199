import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { PersonalizationSelector } from "../../store/slices/PersonalizationSlice";
type PrimaryButtonProps = {
  title: string | any;
  props?: ButtonProps;
};

export const PrimaryButton = ({ title, props }: PrimaryButtonProps) => {
  const { buttonColor, buttonTextColor } = useSelector(PersonalizationSelector);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      boxShadow: "0px 4px 3px #0000004D",
      borderRadius: "10px",
      opacity: 1,
      background: !!buttonColor
        ? buttonColor
        : theme.palette.primary.main + " 0% 0% no-repeat padding-box",
      fontSize: "18px",
      fontWeight: 600,
      color: !!buttonTextColor ? buttonTextColor : theme.palette.common.white,

      transition: "background, color, opacity .25s ease",

      "&:hover": {
        background: !!buttonColor
          ? buttonColor
          : theme.palette.primary.main + " 0% 0% no-repeat padding-box",
        color: !!buttonTextColor ? buttonTextColor : theme.palette.common.white,
        opacity: ".8",
      },

      "&:disabled": {
        background: !!buttonColor
          ? buttonColor
          : theme.palette.primary.main +
            " 0% 0% no-repeat padding-box !important",
        boxShadow: "0px 4px 3px #0000004D",
        borderRadius: "10px",
        opacity: 0.5,
        color: `${
          !!buttonTextColor ? buttonTextColor : theme.palette.common.white
        } !important`,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Button
      variant="contained"
      fullWidth
      classes={{
        root: classes.root,
      }}
      {...props}
    >
      {title}
    </Button>
  );
};
