import { ButtonsProps } from "../../../components/Table/types";
/**
 * Retorna los botones y el buscador los cuales se usan junto a la tabla de datos
 * @returns { Object } Botones y buscador 
 */
const getButtonsUsers = () => {
  let button: ButtonsProps = {
    searchBar: {
      cellSize: {
        lg: 7,
        md: 4,
        sm: 12,
        xs: 12,
      },
    },
    menuOrder: {
      justify: "center",
      options: [
        {
          label: "Activo",
          columns: [
            {
              prop: "state",
            },
          ],
        },
        {
          label: "Inactivo",
          columns: [
            {
              prop: "state",
              direction: -1,
            },
          ],
        },
        {
          label: "Más reciente",
          columns: [
            {
              prop: "createdOn",
              direction: 1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
        {
          label: "Más antiguo",
          columns: [
            {
              prop: "createdOn",
              direction: -1,
              format: (date: any) => {
                return new Date(date).getTime();
              },
            },
          ],
        },
      ],
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
    },
    justify: "flex-start",
    exportButton: {
      cellSize: {
        xs: 12,
        sm: 12,
        md: 2,
        lg: 2,
      },
      fileName: "Listado de Usuarios",
    },
  };
  return button;

}

export default getButtonsUsers