import { getConsumptionFNAReport } from "../../services/FnaService";
import { IFNAFormReport } from "../models/FNAModel";
import {
  setIsLoading,
  setFNAConsumptionReport,
  setShowModal,
  setMessage,
} from "../slices/FNAReportSlice";
import { AppThunk } from "../store";

export const getFNAConsumptionReportAC = (data: IFNAFormReport): AppThunk => (dispatch) => {
  dispatch(setIsLoading(true)) &&
    getConsumptionFNAReport(data)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setFNAConsumptionReport(response.result));
        } else {
          dispatch(setShowModal(true))
          dispatch(setMessage(response.statusMessage))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setIsLoading(false)));
};
