import React from "react";
import { Theme } from "@material-ui/core";
import {
  Grid,
  Typography,
  Container,
  makeStyles,
  Box,
  FormControlLabel,
  Checkbox,
  withStyles,
  createStyles,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowForward from "@material-ui/icons/ArrowForward";
import "../../../components/Fingerprint/FingerPrintStyle.css";
import FingerPrintHook from "../../../hooks/useFingerPrintCapture";
import ManualFingerprintDialog from "../../../components/Fingerprint/ManualFingerprintDialog";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { SecondaryButton } from "../../../components/button/SecondaryButton";
import CaptureDialog from "../../../components/Fingerprint/CaptureDialog";
import ModalError from "../../../components/Fingerprint/ModalError";
import ModalSuccess from "../../../components/Fingerprint/ModalSuccess";
import CheckParticipant from "../../../components/Fingerprint/CheckParticipant";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import FingerATDP from "../../../components/Atdp/FingerATDP";
import HandsLeft from "../../../assets/hand_left.png";
import HandsRight from "../../../assets/hand_right.png";
import FullLoader from "../../../components/Loader/FullLoader.component";
import ValidateParticipants from "../../../components/Fingerprint/ValidateParticipants";

// interfaces
interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

interface IFingerprintCaptureProps {
  companyId: string;
  changeTab: Function;
  roleLogged: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// customized components

const CustomizedTabs = withStyles({
  indicator: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    marginTop: "1em",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 200,
      width: "100%",
      backgroundColor: "#f77730",
    },
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const CustomizedTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#f77730",
      fontWeight: "bold",
      fontSize: "1.1em",
      marginRight: "1em",
      width: "50%",
      maxWidth: "none",
      "&:focus": {
        opacity: 1,
      },
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FingerprintCaptureMobile = ({
  companyId,
  changeTab,
  roleLogged,
}: IFingerprintCaptureProps) => {
  // custom hook
  const {
    dataParticipantState,
    isOpenModalValidateResult,
    dataATDPState,
    fingerATDP,
    validateParticipantsState,
    setMultipleParticipantInfo,
    isOpenModalErrorValidation,
    responseCapture,
    loadingFingerPrint,
    defaultCheck,
    fingerAlert,
    captureDialog,
    isramdomAvailable,
    fingerPrimary,
    fingerId,
    handPrimary,
    tabstate,
    indexState,
    checkParticipantList,
    keyState,
    fingers,
    fingerprintJustification,
    manualJustification,
    handleChangeTab,
    handleMultipleDialogs,
    handleCaptureDialog,
    handleChangeTabPrincipal,
    getsessionStorage,
    isButtonDisabled,
    setFingerAlert,
    clearTimer,
    resetCapture,
    setCaptureDialog,
    handleNextParticipant,
    setIsOpenModalValidateResult,
    setFingerATDP,
    setValidateParticipantsState,
    setIsOpenModalErrorValidation,
    dispatch,
    changeToManualFingerCapture,
    handleCheckbox,
    generateRandom,
    handleJustification,
  } = FingerPrintHook({ changeTab, companyId });
  // timeOut
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={"column"}
        lg={12}
        xs={12}
        sm={12}
      >
        <Container>
          <Box className={classes.participantsContainer}>
            {getsessionStorage().map((item) => (
              <div
                key={item.id}
                style={{ display: "flex", width: "100%", position: "relative" }}
              >
                {indexState === item.id ? (
                  <div className={classes.arrowContainer}>
                    <ArrowForward style={{ fontSize: "18px" }} />
                  </div>
                ) : (
                  <></>
                )}
                <Typography
                  className={
                    indexState === item.id
                      ? classes.selectParticipantText
                      : classes.participantText
                  }
                >
                  {item.nameuser}&nbsp;{item.surname}
                </Typography>
                <div className={classes.checkContainer}>
                  <CheckParticipant
                    isOpen={
                      item.id === 1
                        ? checkParticipantList.oneForm
                        : item.id === 2
                        ? checkParticipantList.twoForm
                        : item.id === 3
                        ? checkParticipantList.threeForm
                        : false
                    }
                  />
                </div>
              </div>
            ))}
          </Box>
        </Container>
      </Grid>
      <CustomizedTabs
        value={tabstate}
        onChange={handleChangeTabPrincipal}
        aria-label="Tabs Captura Huella"
      >
        <CustomizedTab label="Mano derecha" />
        <CustomizedTab label="Mano izquierda" />
      </CustomizedTabs>
      <TabPanel value={tabstate} index={0}>
        <Container style={{ margin: 0, padding: 0 }}>
          <div className={"handsContainer"} style={{ position: "relative" }}>
            <Box className={"fingerContainer"}>
              <img src={HandsRight} alt="hands" className={"imageHands"} />
              <div className={classes.customCheckbox}>
                <FormControlLabel
                  className={"checkboxHands pinky-right-mobile"}
                  value="meñiqued"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.meñiqued.disabled}
                      checked={defaultCheck.meñiqued.checked}
                    />
                  }
                  label="meñique"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "derecha", "Meñique")}
                />
                <FormControlLabel
                  className={"checkboxHands ringFinger-right-mobile"}
                  value="anulard"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.anulard.disabled}
                      checked={defaultCheck.anulard.checked}
                    />
                  }
                  label="Anular"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "derecha", "Anular")}
                />
                <FormControlLabel
                  className={"checkboxHands middleFinger-right-mobile"}
                  value="corazond"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.corazond.disabled}
                      checked={defaultCheck.corazond.checked}
                    />
                  }
                  label="Corazón"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "derecha", "Corazón")}
                />
                <FormControlLabel
                  className={"checkboxHands indexFinger-right-mobile"}
                  value="indiced"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.indiced.disabled}
                      checked={defaultCheck.indiced.checked}
                    />
                  }
                  label="Índice"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "derecha", "Índice")}
                />
                <FormControlLabel
                  className={"checkboxHands thumb-right-mobile"}
                  value="pulgard"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.pulgard.disabled}
                      checked={defaultCheck.pulgard.checked}
                    />
                  }
                  label="Pulgar"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "derecha", "Pulgar")}
                />
              </div>
            </Box>
          </div>
        </Container>
      </TabPanel>
      <TabPanel value={tabstate} index={1}>
        <Container style={{ margin: 0, padding: 0 }}>
          <div className={"handsContainer"} style={{ position: "relative" }}>
            <Box className={"fingerContainer"}>
              <img src={HandsLeft} alt="hands" className={"imageHands"} />
              <div className={classes.customCheckbox}>
                <FormControlLabel
                  className={"checkboxHands pinky-left-mobile"}
                  value="meñiquei"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.meñiquei.disabled}
                      checked={defaultCheck.meñiquei.checked}
                    />
                  }
                  label="meñique"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "izquierda", "Meñique")}
                />
                <FormControlLabel
                  className={"checkboxHands ringFinger-left-mobile"}
                  value="anulari"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.anulari.disabled}
                      checked={defaultCheck.anulari.checked}
                    />
                  }
                  label="Anular"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "izquierda", "Anular")}
                />
                <FormControlLabel
                  className={"checkboxHands middleFinger-left-mobile"}
                  value="corazoni"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.corazoni.disabled}
                      checked={defaultCheck.corazoni.checked}
                    />
                  }
                  label="Corazón"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "izquierda", "Corazón")}
                />
                <FormControlLabel
                  className={"checkboxHands indexFinger-left-mobile"}
                  value="indicei"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.indicei.disabled}
                      checked={defaultCheck.indicei.checked}
                    />
                  }
                  label="Índice"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "izquierda", "Índice")}
                />
                <FormControlLabel
                  className={"checkboxHands thumb-left-mobile"}
                  value="pulgari"
                  control={
                    <Checkbox
                      color="default"
                      className={"checkboxCustom"}
                      disabled={defaultCheck.pulgari.disabled}
                      checked={defaultCheck.pulgari.checked}
                    />
                  }
                  label="Pulgar"
                  labelPlacement="bottom"
                  onChange={(e) => handleCheckbox(e, "izquierda", "Pulgar")}
                />
              </div>
            </Box>
          </div>
        </Container>
      </TabPanel>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={"column"}
        lg={12}
      >
        <Container>
          <Grid item sm={12} style={{ marginBottom: "1em" }}>
            <PrimaryButton
              title={"Capturar huellas"}
              props={{
                onClick: handleCaptureDialog,
                disabled: isButtonDisabled(),
                endIcon: <ArrowForwardIos />,
              }}
            ></PrimaryButton>
          </Grid>
          <Grid item sm={12} style={{ marginBottom: "1em" }}>
            {isramdomAvailable ? (
              <SecondaryButton
                title={"Aleatorio"}
                props={{
                  onClick: generateRandom,
                  // disabled: isreadyFormat,
                }}
              ></SecondaryButton>
            ) : (
              <SecondaryButton
                title={"Cambiar a Manual"}
                props={{
                  onClick: changeToManualFingerCapture,
                }}
              ></SecondaryButton>
            )}
          </Grid>

          <div className={classes.buttonsContainer}>
            <Grid item sm={12} style={{ marginBottom: "1em" }}>
              <PrimaryButton
                title={"Anterior"}
                props={{
                  onClick: handleChangeTab,
                  startIcon: <ArrowBackIos style={{ color: "#009BDD" }} />,
                }}
              ></PrimaryButton>
            </Grid>
          </div>
        </Container>
      </Grid>
      <ManualFingerprintDialog
        isOpen={fingerAlert}
        isClose={() => setFingerAlert(false)}
        fingerprintJustification={fingerprintJustification}
        handleSubmit={handleJustification}
        valueSaved={
          !!manualJustification ? manualJustification.toString() : null
        }
      />
      {/* Modal captura de huellas */}
      <CaptureDialog
        keyProp={keyState}
        isOpen={captureDialog}
        finger={fingerPrimary ? fingerPrimary : null}
        hand={handPrimary ? handPrimary : null}
        arrayFinger={fingers}
        clearTimer={clearTimer}
        handleAcceptButton={handleMultipleDialogs}
        isClose={() => {
          resetCapture();
          clearTimer();
          return setCaptureDialog(false);
        }}
        value={fingerId}
      />
      {/* Modal Result fingerprint validation */}
      <ModalSuccess
        dataParticipant={dataParticipantState}
        arrayFinger={fingers}
        isOpen={isOpenModalValidateResult}
        nextParticipant={handleNextParticipant}
        sizeParticipant={getsessionStorage().length}
        index={indexState}
        isClose={() => {
          resetCapture();
          return setIsOpenModalValidateResult(false);
        }}
        informationMessage={responseCapture && responseCapture.information}
      />
      {/* politica tratamiento datos personales */}
      <FingerATDP
        data={dataATDPState}
        onClick={() => setFingerATDP(false)}
        isOpen={fingerATDP}
        isClose={() => {
          resetCapture();
          return setFingerATDP(false);
        }}
      />
      {/* modal show resume for multiple participants after validation */}
      <ValidateParticipants
        isOpen={validateParticipantsState}
        finalize={() => {
          dispatch(setMultipleParticipantInfo([]));
          return handleChangeTab();
        }}
        isClose={() => {
          resetCapture();
          return setValidateParticipantsState(false);
        }}
      />
      {/* modal de resultado si la validacion de huellas no es satisfactoria  */}
      <ModalError
        isOpen={isOpenModalErrorValidation}
        onClose={() => {
          setIsOpenModalErrorValidation(false);
        }}
        informationMessage={responseCapture && responseCapture.information}
        returnToCapture={() => {
          resetCapture();
          return setIsOpenModalErrorValidation(false);
        }}
        returnToForm={() => {
          handleChangeTab();
          return setIsOpenModalErrorValidation(false);
        }}
        mustReturnToForm={
          responseCapture && responseCapture.informationCode?.trim() === "RNEC0"
            ? true
            : false
        }
        isUserFNA={roleLogged}
        sizeParticipant={getsessionStorage().length}
        nextParticipant={handleNextParticipant}
        index={indexState}
        dataParticipant={dataParticipantState}
        arrayFinger={fingers}
      />

      <FullLoader open={loadingFingerPrint} />
    </>
  );
};

// styles customizations
const useStyles = makeStyles((_theme: Theme) => ({
  participantsContainer: {
    width: "auto",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    border: "1px solid #DDDDDD",
    borderRadius: "10px",
    padding: "17px 25px",
  },
  participantText: {
    font: "normal normal normal 14px/18px Muli",
    color: "#B2B2B2",
  },
  selectParticipantText: {
    font: "font: normal normal bold 14px/18px Muli",
    color: "#009BDD",
    fontWeight: "bold",
  },
  arrowContainer: {
    width: "10%",
    fontSize: "10px",
    color: "#009BDD",
    position: "absolute",
    left: "-20px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [_theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
    },

    [_theme.breakpoints.down("md")]: {
      gap: "1rem",
    },
    "& div:nth-child(1)": {
      [_theme.breakpoints.up("sm")]: {
        width: "50%",
        height: "40px",
      },
    },
    "& div:nth-child(2)": {
      [_theme.breakpoints.up("sm")]: {
        width: "50%",
        height: "40px",
      },
    },
  },
  customCheckbox: {
    background: "none",
    maxWidth: "360px",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    position: "relative",
    "& .MuiCheckbox-root": {
      color: "#ffffff !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px !important",
    },
  },
  checkContainer: {
    width: "10%",
    color: "#009BDD",
    marginLeft: "10px",
  },
  tabs: {
    borderRight: `1px solid #f77730`,
  },
}));

export default FingerprintCaptureMobile;
