import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}
const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  onPageChange,
  page,
  rowsPerPage,
  children,
}) => {
  const theme = useTheme();
  const backButtonClickRef = useRef<HTMLButtonElement>(null);
  const nextButtonClickRef = useRef<HTMLButtonElement>(null);
  const [numberPage, setNumberPage] = useState<number[]>([]);
  const [localValue, setLocalValue] = useState<number>(page); // we want to keep value locally
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));
  const classes = useStyles({ md, lg, xl });

  let nroCargar: number = 1;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(
      event,
      localValue - (localValue === 1 || nroCargar === 0 ? 1 : nroCargar)
    );
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, localValue + nroCargar);
  };

  useEffect(() => {
    let listRows: number[] = [];
    for (let index = 0; index < Math.ceil(count / rowsPerPage); index++) {
      var item = index + 1;
      listRows.push(item);
    }
    setNumberPage(listRows);
  }, [count, rowsPerPage]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    let nro: number = event.target.value;
    let val: number =
      nro - (localValue + 1) < -1
        ? (nro - (localValue + 1)) * -1
        : nro - (localValue + 1);
    nroCargar = val < 0 ? 0 : val;
    setLocalValue(nro - 1);
    if (nro > localValue) {
      nextButtonClickRef.current?.click();
    } else {
      backButtonClickRef.current?.click();
    }
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={localValue === 0}
        aria-label="previous page"
        ref={backButtonClickRef}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft color={localValue === 0 ? "inherit" : "primary"} />
        )}
      </IconButton>
      <div>
        <div className={classes.indicatorBox}>
          <Select
            style={{ fontSize: "12px" }}
            displayEmpty
            value={localValue + 1}
            onChange={handleChange}
          >
            <MenuItem disabled value={localValue + 1}>
              Escoge
            </MenuItem>
            {numberPage.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {option ?? option}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <span>{` de ${Math.ceil(count / rowsPerPage)}`}</span>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={localValue >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        ref={nextButtonClickRef}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight
            color={
              localValue === 0 || localValue > Math.ceil(count / rowsPerPage)
                ? "primary"
                : "inherit"
            }
          />
        )}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ md, lg, xl }: any) => {
      let size = 0;
      if (md) {
        size = 400;
      } else if (lg) {
        size = 450;
      } else if (xl) {
        size = 950;
      }

      return {
        flexShrink: 0,
        marginLeft: size,
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "center",
      };
    },
    indicatorBox: {
      display: "grid",
      justifyContent: "center",
      alignItems: "center",
      gridTemplateColumns: "repeat(2, 0.1fr)",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 2px 6px #00000029",
      borderRadius: "3px",
      opacity: 1,
      maxHeight: 39,
    },
  })
);
