import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

type ProgressBarProps = {
  done: string;
  value?: string;
  title: string;
  onlyBars?: boolean;
  multipleColor?: boolean;
  green?: string;
  yellow?: string;
  red?: string;
};

const useProgressBar = (props: ProgressBarProps) => {
  const [style, setStyle] = useState({});

  const useStyles = makeStyles(() => ({
    linearGradientGreen: {
      backgroundImage: "linear-gradient(#62B916,#62B916)",
    },
    linearGradientYellow: {
      backgroundImage: "linear-gradient(#E99C00,#E99C00)",
    },
    linearGradientRed: {
      backgroundImage: "linear-gradient(#D91637,#D91637)",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${props.done}%`,
      };
      setStyle(newStyle);
    }, 200);
  }, [props.done]);

  return [style, classes];
};

export default useProgressBar;
