import React, { ChangeEvent } from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      margin: 1,
      backgroundColor: theme.palette.grey[400],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: `1px solid ${theme.palette.info.light}`,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.info.light}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.info.dark,
    },
    checked: {},
  })
)(Switch);

type SwitchFormProps = {
  checked: boolean;
  onChange: Function;
  name: string;
  disabled: boolean;
};
const SwitchForm = React.forwardRef<HTMLButtonElement, SwitchFormProps>(
  (props, ref) => {
    const { checked, onChange, name, disabled } = props;
    return (
      <div>
        <AntSwitch
          checked={checked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          name={name}
          ref={ref}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default SwitchForm;
