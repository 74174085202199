import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, GetApp } from "@material-ui/icons";
import React from "react";
import { IAudit } from "../../store/types";
import XLSX from "xlsx";

interface IAuditMoldalProps {
  open: boolean;
  handleClose: Function;
  dataAudit?: IAudit | undefined;
  fileName: string;
}

/**
 * @description Componente que representa el modal para la informacion de auditoria
 * @param { boolean } param.open Parametro de tipo boolean que representa el estado de apertura del modal de auditoria
 * @param { Function } param.handleClose Parametro de tipo Function que representa la funcion que se envia al componente padre para el cierre del modal
 * @param { IAudit | undefined } param.dataAudit Parametro de tipo IAudit | undefined que representa la informacion de auditoria
 * @param { string } param.fileName Parametro de tipo string que representa la accion de descargar el archivo con la auditoria
 * @returns { React.FC<IAuditMoldalProps> } IAuditMoldalProps
 */
export const AuditMoldal: React.FC<IAuditMoldalProps> = ({
  open,
  handleClose,
  dataAudit,
  fileName,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const downloadExcel = () => {
    const dataArray = [dataAudit];
    const workSheet = XLSX.utils.json_to_sheet(dataArray);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "Hoja1");
    //Buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, fileName + ".xlsx");
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="custom-dialog"
      maxWidth="sm"
    >
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1}>
          <Close
            color="primary"
            onClick={() => handleClose()}
            style={{
              cursor: "pointer",
              paddingTop: "10px",
              height: "20px",
              width: "50px",
            }}
          />
        </Grid>
      </Grid>
      <DialogTitle className={classes.dialogTitle}>Auditoría</DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={2} md={2} xs={3} sm={3}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Creado por:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.creadoPor}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={3} md={3} xs={3} sm={3}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Fecha creación:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.fechaCreacion}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            <Divider className={classes.hr} />
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={3} md={3} xs={3} sm={3}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Modificado por:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.modificadoPor}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={3} md={3} xs={4} sm={4}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Fecha modificación:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.fechaModificado}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={4} md={4} xs={5} sm={5}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Unidad de negocio (sede):
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.sede}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent="flex-start"
          >
            <Grid item lg={3} md={3} xs={3} sm={3}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                Propietario:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} xs={7} sm={7}>
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.datosTypography}
              >
                {dataAudit?.propietario}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            <Divider className={classes.hr} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item lg={3} md={3} xs={4} sm={4}></Grid>
        <Grid
          container
          item
          lg={6}
          md={6}
          xs={8}
          sm={8}
          justifyContent="center"
        >
          <Button
            variant="contained"
            fullWidth
            color="primary"
            classes={{
              root: classes.root,
              disabled: classes.disabledButton,
            }}
            onClick={() => downloadExcel()}
            startIcon={<GetApp width={"24px"} height={"24px"} />}
          >
            Descargar soporte
          </Button>
        </Grid>
        <Grid container item lg={3} md={3} xs={4} sm={4}></Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 1,
    background: "#1D71B8 0% 0% no-repeat padding-box",
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  disabledButton: {
    background: "#1D71B8 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 0.5,
    color: `${theme.palette.common.white} !important`,
  },
  dialogTitle: {
    height: 12,
    color: theme.palette.secondary.dark,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 40,
  },
  messageTypography: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
  hr: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.info.light,
    paddingTop: "1px",
  },
  datosTypography: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
}));
