import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONCREATORS
import {
  advanceSearchDeviceAC,
  advanceSearchDeviceCsvAC,
  getDevicesAC,
  getDevicesByBusinessUnitAC,
  getFilterAC,
  getMassiveDeviceTemplateAC,
  getPaginatorAC,
  getSortAC,
  saveMassiveLoadAC,
  validateLoadStatusAC,
} from "../store/action-creators/DeviceActionCreators";
import {
  getBrandsAC,
  getBusinessUnitAC,
  getDeviceTypesAC,
  getModelsAC,
} from "../store/action-creators/CommonListActionCreators";
import { getEquipmentByBusinessUnitCommonAC } from "../store/action-creators/ComputerEquipmentActionCreator";

// ASSETS
import loadLogo from "../assets/loadLogo.png";
import errorLogo from "../assets/errorLogo.png";
import backLogo from "../assets/onBack.png";
import uploadLogo from "../assets/onUpload.png";

// MODELS
import { UpdateState } from "../store/models/UpdateState";

// SERVICES
import {
  getAllDevicesExcelService,
  updateDeviceState,
} from "../services/DeviceService";

//SLICES
import {
  DeviceSelector,
  setAdvanceSearch,
  setCurrentDevice,
  setLoadingDevice,
  setPaginatorDevice,
  setShowForm,
} from "../store/slices/DeviceSlice";
import {
  CommonSelector,
  setLoading,
  setPrivilege,
} from "../store/slices/CommonSlice";

// TYPES
import { Column } from "../components/Table/types";
import { Device, FieldsAdvanceSearch, sortBy, StateLoad } from "../store/types";

// UTILS
import { createExcel } from "../utils/general/utils";
import { CommonListSelector } from "../store/slices/CommonListsSlice";
import { useMassiveLoad } from "./useMassiveLoad";
import { moduleNamesPluralMin } from "../utils/general/const";
import { ILoadDevice } from "../store/models/DeviceUnit";

type UseDeviceProps = {
  businessUnitId?: number;
  equipment?: number | undefined;
  nitCompany?: string;
};
/**
 * Retorna funciones y estados que se manejan en la vista
 * @param { number } businessUnitId - id de la sede
 * @param { ( number | undefined ) } equipment - id del equipo
 * @param { string } nitCompany - NIT de la compañia
 */
const useDevice = ({
  businessUnitId,
  equipment,
  nitCompany,
}: UseDeviceProps) => {
  
  //Custom Hooks
  const { onUpload } = useMassiveLoad<ILoadDevice>();
  // Redux
  const dispatch = useDispatch();
  const {
    devices,
    showForm,
    deviceMassiveResponse,
    paginatorDevice,
    size,
    advanceSearch,
    loadingDevice,
  } = useSelector(DeviceSelector);
  const { privilege, rol } = useSelector(CommonSelector);
  const { brands } = useSelector(CommonListSelector);
  // Estados locales
  const [isLoad, setLoad] = useState(false);
  const [option, setOption] = useState(null);
  const [tab, setTab] = useState(0);
  const [itemsList, setItemsList] = useState<Array<StateLoad | undefined>>();
  const [imageModal, setImageModal] = useState(loadLogo);
  const [messageModal, setMessageModal] = useState("");
  const [confirmate, setConfirmate] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [modal, setModal] = useState(false);
  const [showFormDevice, setShowFormDevice] = useState(false);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);

  const fieldsAdvanced: FieldsAdvanceSearch[] =
    rol === "BIOMETRIA RNEC"
      ? [
          {
            name: "MarkDevice",
            title: "Marca",
            type: "selectBranchName",
            value: "",
          },
          {
            name: "ModelDevice",
            title: "Modelo",
            type: "SelectModelDevice",
            value: "",
          },
          {
            name: "ClassDevice",
            title: "Tipo",
            type: "selectDeviceType",
            value: "",
          },
          {
            name: "CodeSede",
            title: "Código de Sede",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "ModificationDate",
            title: "Fecha de modificación",
            type: "date",
            value: undefined,
          },
          {
            name: "State",
            title: "Estado",
            type: "status",
            value: "",
          },
        ]
      : [
          {
            name: "MarkDevice",
            title: "Marca",
            type: "selectBranchName",
            value: "",
          },
          {
            name: "ModelDevice",
            title: "Modelo",
            type: "SelectModelDevice",
            value: "",
          },
          {
            name: "ClassDevice",
            title: "Tipo",
            type: "selectDeviceType",
            value: "",
          },
          {
            name: "SerialDevice",
            title: "Serial",
            type: "text",
            value: "",
          },
          {
            name: "NameSede",
            title: "Sede",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "State",
            title: "Estado",
            type: "status",
            value: "",
          },
        ];
  const setAdvanceSearchDevice = (fields: FieldsAdvanceSearch[]) =>
    dispatch(setAdvanceSearch(fields));
  const { paginator, filter, sort } = paginatorDevice;

  /**
   * Funcion para obtener los dispositivos con la busqueda normal
   * Elimina una petición demas
   */
  const fillDeviceList = useCallback(() => {
    if (!loadingDevice) {
      if (filter !== undefined && filter.length < 3) {
        dispatch(
          getDevicesAC(
            { ...paginatorDevice, filter: "" },
            nitCompany,
            undefined,
            equipment
          )
        );
      } else {
        dispatch(
          getDevicesAC(paginatorDevice, nitCompany, undefined, equipment)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorDevice, dispatch, nitCompany, loadingDevice]);

  //#region effects
  /**
   * Asegura que cada vez que entre al módulo muestre la tabla
   * en la primera página, el filtro este vacio, la busqueda avanzada también
   * y el ordenamieto sea reciente
   */
  useEffect(() => {
    if (equipment === undefined && businessUnitId === undefined) {
      dispatch(getPaginatorAC(1, 10, true));
      setAdvanceSearchDevice(fieldsAdvanced);
    }
    dispatch(setShowForm(false));
    return () => {
      dispatch(getFilterAC(""));
      dispatch(getSortAC("recent"));
      if (equipment === undefined && businessUnitId === undefined)
        dispatch(getPaginatorAC(1, 10, true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, equipment, businessUnitId]);

  useEffect(() => {
    if (!privilege?.length) {
      dispatch(setPrivilege(""));
    }
  }, [privilege, dispatch]);

  useEffect(() => {
    if (brands.length === 0) dispatch(getBrandsAC());
  }, [brands, dispatch]);

  useEffect(() => {
    if (paginatorDevice !== undefined && paginator.recharge) {
      if (businessUnitId !== undefined || equipment !== undefined) {
        if (advanceSearchState) {
          dispatch(
            advanceSearchDeviceAC({
              businessUnitId,
              equipment,
              fields: advanceSearch,
              paginator: paginatorDevice,
              company: nitCompany,
            })
          );
        } else if (!loadingDevice) {
          dispatch(
            getDevicesByBusinessUnitAC({
              BusinessUnit: businessUnitId,
              Paginator: paginator,
              filter: filter,
              sort: sort,
              Equipment: equipment,
            })
          );
        }
        dispatch(getPaginatorAC(paginator.page, paginator.itemsPage, false));
      } else {
        if (advanceSearchState) {
          dispatch(
            advanceSearchDeviceAC({
              fields: advanceSearch,
              paginator: paginatorDevice,
              company: nitCompany,
            })
          );
        } else {
          fillDeviceList();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorDevice]);

  useEffect(() => {
    if (deviceMassiveResponse !== undefined) {
      setModal(false);
      const items = new Array<StateLoad>();
      Promise.all(
        // eslint-disable-next-line array-callback-return
        deviceMassiveResponse.details?.map((item) => {
          items.push({ name: item.modelo, state: item.result });
        })
      ).then(() => {
        setTab(1);
        setItemsList(items);
      });
    }
  }, [deviceMassiveResponse]);

  //#endregion

  //#region handling events

  /**
   * Muestra un modal de alerta con un mensaje y opciones de confirmación o cancelación.
   * @param { string } titleModal - titulo del modal
   * @param { string } messageModal - mensaje del modal
   * @param { any } imageModal - imagen del modal
   * @param { Object } validate - objeto con las opciones y el texto de las mismas
   */
  const alertModal = (
    titleModal: string,
    messageModal: string,
    imageModal: any,
    validate?: {
      confirmate: boolean;
      cancel: boolean;
      confirmText: string;
      cancelText: string;
    }
  ) => {
    setModal(true);
    setImageModal(imageModal);
    setTitleModal(titleModal);
    setMessageModal(messageModal);
    if (validate) {
      setConfirmate(validate?.confirmate);
      setCancel(validate?.cancel);
      setConfirmText(validate?.confirmText);
      setCancelText(validate?.cancelText);
    }
  };
  /**
   * Cancela la accion del modal y cierra el modal
   */
  const onCancel = () => {
    setModal(false);
    setConfirmate(false);
  };
  /**
   * Actualiza el estado del dispositivo
   * @param e - datos enviados por la fila de la tabla
   * @param value - id del dispositivo
   */
  const handleConfirmClick = (e: any, value: any) => {
    const state: UpdateState = {
      id: value,
      state: e.target.checked,
      paginator: {
        page: paginatorDevice.paginator.page,
        itemsPage: paginatorDevice.paginator.itemsPage,
        recharge: true,
      },
    };

    dispatch(setLoading(true));
    updateDeviceState(state)
      .then((response) => {})
      .catch((err) => {
        console.error(err || "Exception updateDeviceState");
      })
      .finally(() => {
        dispatch(setLoading(false));
        // Paginator is thrown to refresh the data in datatable
        dispatch(
          setPaginatorDevice(
            state.paginator === undefined
              ? { page: 1, itemsPage: 10 }
              : state.paginator
          )
        );
      });
  };
  /**
   * Obtiene toda la informacion del dispositivo
   * la guarda en redux
   * @param { React.MouseEvent<HTMLSpanElement, MouseEvent> } e - datos del formulario que se enviaran
   * @param { any } value - información del dispositivo
   * @param _column - datos acerca de la columna
   */
  const handleCellClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    _column: Column
  ) => {
    const currentDev: Device = {
      id: value.id,
      brand: value.brand,
      businessUnit: value.businessUnit,
      equipment: value.equipment,
      model: value.model,
      serial: value.serial,
      state: value.state,
      type: value.type,
      idState: value.idState,
      haveProcess: value.haveProcess,
    };
    dispatch(setCurrentDevice(currentDev));
    dispatch(getBrandsAC());
    dispatch(getModelsAC(value.brand));
    dispatch(getDeviceTypesAC());
    dispatch(getBusinessUnitAC());
    dispatch(
      getEquipmentByBusinessUnitCommonAC({
        BusinessUnitId: value.businessUnit,
        Paginator: { itemsPage: 0, page: 0 },
        filter: "",
        sort: "recent",
      })
    );
    dispatch(setShowForm(true));
  };
  /**
   * Retrocede a la tabla de datos y cancela el cargue masivo
   */
  const handleBack = () => {
    if (isLoad && deviceMassiveResponse !== undefined) {
      alertModal(
        "",
        "¿Estás seguro que deseas realizar esta acción?\n Si lo haces, perderás la información cargada",
        backLogo,
        { confirmate: true, cancel: true, confirmText: "SI", cancelText: "NO" }
      );
    } else {
      setLoad(false);
    }
  };
  /**
   * Descarga la pantilla excel para el cargue masivo
   */
  const onDownload = async () => {
    dispatch(getMassiveDeviceTemplateAC());
  };
  /**
   * Función que ejecuta la función que valida el archivo seleccionado y valida si se puede subir o reemplazar o si esta mal
   * @param { any } e - datos del campo
   */
  const onUploadDataEvent = (e: any) =>
    onUpload(
      e,
      alertModal,
      validateLoadStatusAC,
      "serials",
      moduleNamesPluralMin.device
    );
  /**
   * Guarda los datos masivos suministrados en el excel
   */
  const onSaveMassive = () => {
    setLoading(true);
    if (deviceMassiveResponse !== undefined) {
      let bo = deviceMassiveResponse.details.filter(
        (b) => b.result === "exitoso" || b.result === "reemplazado"
      );
      dispatch(saveMassiveLoadAC(bo, onSucess));
      alertModal(
        "Cargue masivo de dispositivos",
        "La informacion de tus dispositivos esta siendo cargada a la plataforma, recuerda revisarla",
        loadLogo,
        { confirmate: false, cancel: false, cancelText: "", confirmText: "" }
      );
    }
  };
  /**
   * Muestra el alert modal y actualiza los dispositivos
   * @param { boolean } value - validación para saber si fue exitoso el cargue masivo
   */
  const onSucess = (value: Boolean) => {
    if (value) {
      alertModal(
        "Cargue exitosamente",
        `Tu carga masiva fue un exito`,
        uploadLogo
      );
      dispatch(getDevicesAC(paginatorDevice));
      setLoad(false);
    } else {
      alertModal("Error", "Error al guardar los datos", errorLogo);
    }
  };
  /**
   * Confirma la acción del modal, cierra el modal y actualiza los dispositivos
   */
  const onFinalize = () => {
    setModal(false);
    setLoad(false);
    dispatch(
      getDevicesAC({ paginator: { page: 1, itemsPage: 10 }, sort: "recent" })
    );
  };
  /**
   * Descarga los errores generados por el cargue masivo
   */
  const onDownloadErrors = () => {
    if (deviceMassiveResponse !== undefined) {
      const valores = deviceMassiveResponse.details;
      let data: any = [];

      Object.keys(valores)?.forEach((key) => {
        const item = valores[parseInt(key, 10)];

        if (item.errors !== undefined && item.errors?.length) {
          const deviceError: any = {
            marca: item.marca,
            modelo: item.modelo,
            serial: item.serial,
            sede: item.sede,
            equipo: item.equipo,
            error: item.errors,
          };
          data.push(deviceError);
        }
      });
      // export excel by helper method
      const fill = (number: any, len: any) =>
        "0".repeat(len - number.toString().length) + number.toString();
      const currentDate =
        new Date().getFullYear() +
        "-" +
        fill(new Date().getMonth() + 1, 2) +
        "-" +
        new Date().getDate();
      createExcel(data, "Errores_Dispositivos_" + currentDate);
    }
  };
  /**
   * Maneja el cambio de tabs
   * @param { any } evt - datos del componente tab
   * @param { any } value - posicion de la tab
   */
  const handleChangeTab = (evt: any, value: any) => {
    deviceMassiveResponse !== undefined && setTab(value);
  };
  /**
   * Actualiza los dispositivos con la siguiente pagina
   * @param { any } _event - datos del componente
   * @param { any } newPage - página actual
   */
  const handleChangePage = (_event: any, newPage: any) => {
    dispatch(
      getPaginatorAC(newPage + 1, paginatorDevice.paginator.itemsPage, true)
    );
  };
  /**
   * Maneja la cantidad de datos mostrados en la tabla
   * @param { any } event - datos del componente
   */
  const handleChangeRowsPerPage = (event: any) => {
    dispatch(getPaginatorAC(1, +event.target.value, true));
  };
  /**
   * Maneja la busqueda normal y actualiza la tabla de dispositivos del modulo
   * @param { string } query - Texto a buscar
   */
  const handleFilter = (query: string) => {
    setAdvanceSearchState(false);
    setAdvanceSearchDevice(fieldsAdvanced);
    dispatch(getFilterAC(query.trim()));
    dispatch(getPaginatorAC(1, paginatorDevice.paginator.itemsPage, true));
  };
  /**
   * Maneja el tipo de ordenamiento y actualiza la tabla segun este
   * @param { sortBy } sort - tipo de ordenamiento
   */
  const handleSort = (sort?: sortBy) => {
    if (sort) {
      dispatch(getSortAC(sort));
      dispatch(getPaginatorAC(1, paginatorDevice.paginator.itemsPage, true));
    }
  };
  /**
   * Descarga archivo Xlxs o excel segun el rol y el criterio de busqueda actual
   */
  const onDownloadData = () => {
    if (rol === "BIOMETRIA RNEC") {
      dispatch(
        advanceSearchDeviceCsvAC({
          fields: advanceSearch,
          paginator: paginatorDevice,
          company: nitCompany,
        })
      );
    } else {
      let params = !!businessUnitId
        ? {
            businessUnitId,
            fields: advanceSearch,
            paginator: paginatorDevice,
          }
        : !!equipment
        ? {
            equipment,
            fields: advanceSearch,
            paginator: paginatorDevice,
          }
        : {
            fields: advanceSearch,
            paginator: paginatorDevice,
          };

      dispatch(setLoadingDevice(true));
      getAllDevicesExcelService(params, equipment)
        .then((response) => {
          if (response.statusCode === 200) {
            createExcel(response.result.records, "Listado de Dispositivos");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => dispatch(setLoadingDevice(false)));
    }
  };
  /**
   * MAneja la busqueda avanzada y actualiza la tabla de dispositivos del módulo
   * @param { FieldsAdvanceSearch[] } fieldsPassedAdvancedSearch - campos y valores de la busqueda avanzada
   */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAC(""));

    let params =
      rol === "BIOMETRIA RNEC"
        ? {
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorDevice,
              paginator: {
                page: 1,
                itemsPage: paginatorDevice.paginator.itemsPage,
                recharge: paginatorDevice.paginator.recharge,
              },
              sort: paginatorDevice.sort,
            },
            company: nitCompany,
          }
        : !!businessUnitId
        ? {
            businessUnitId,
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorDevice,
              paginator: {
                page: 1,
                itemsPage: paginatorDevice.paginator.itemsPage,
              },
            },
          }
        : !!equipment
        ? {
            equipment,
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorDevice,
              paginator: {
                page: 1,
                itemsPage: paginatorDevice.paginator.itemsPage,
              },
            },
          }
        : {
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorDevice,
              paginator: {
                page: 1,
                itemsPage: paginatorDevice.paginator.itemsPage,
              },
            },
          };

    dispatch(advanceSearchDeviceAC(params));
    setAdvanceSearchState(true);
    setShowFormDevice(false);
    setAdvanceSearchDevice(fieldsPassedAdvancedSearch);
    dispatch(getPaginatorAC(1, paginatorDevice.paginator.itemsPage, true));
  };
  /**
   * Cierra el formulario para creación o edición de un dispositivo
   */
  const handleOnClose = () => {
    setShowFormDevice(false);
  };
  /**
   * Abre el formulario para creación o edición de un dispositivo
   */
  const handleOnOpen = () => {
    setShowFormDevice(true);
  };
  /**
   * Carga el boton de carga masiva
   */
  const handleOnLoadButton = () => {
    setLoad(true);
    setTab(0);
  };

  //#endregion

  return {
    advanceSearch,
    advanceSearchState,
    cancel,
    cancelText,
    confirmText,
    confirmate,
    deviceMassiveResponse,
    devices,
    fieldsAdvanced,
    handleBack,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingDevice,
    messageModal,
    modal,
    onCancel,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onFinalize,
    onSaveMassive,
    onUploadDataEvent,
    option,
    paginatorDevice,
    privilege,
    rol,
    setAdvanceSearchDevice,
    setLoad,
    setOption,
    setShowFormDevice,
    setTab,
    showForm,
    showFormDevice,
    size,
    tab,
    titleModal,
  };
};

export default useDevice;
