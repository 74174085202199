import React from 'react'
import { makeStyles} from '@material-ui/core'
import { Theme, Typography, Box } from '@material-ui/core'

const useStyles = makeStyles((_theme: Theme) => ({

    root: {
      background: '#FFFFFF',
      width: '100%',
      height: '100%',
      opacity: '.5',
      cursor: 'pointer'
    },

    text: {
        padding: '34px 40px'
    }
  }))


  interface Props {
    active: any;
    label: string;
  }

const ActiveATDP = ({active, label}: Props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles()
    return (
        <Box className={classes.root} onClick={active}>
            <Typography className={classes.text}>{label}</Typography>
        </Box>
    )
}

export default ActiveATDP;
