import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchTextField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        height: "40px",
        boxShadow: "0px 3px 2px #00000026",
        border: `1px solid ${theme.palette.info.light}`,
      },
    },
    "& p": {
      position: "absolute",
      top: "35px",
      left: "10px",
    },
  },
}));
const InputSearch = (props: any) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.searchTextField}
      {...props}
      variant="outlined"
      margin="dense"
    />
  );
};

export default InputSearch;
