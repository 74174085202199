import { Column } from "../../../components/Table/types";
/**
 * Retorna las columnas que se van a mostrar en la tabla de los usuarios
 * @param { Function } handleConfirmClick - Funcion que maneja el estado
 * @returns 
 */
const columns = (handleConfirmClick: Function) => {
  const columns: Column[] = [
    {
      id: "userName",
      label: "Nombre de Usuario",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "firstName",
      label: "Nombres",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "lastName",
      label: "Apellidos",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "documentNumber",
      label: "Documento",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "state",
      label: "Estado",
      align: "center",
      haveDropDown: true,
      component: {
        handleChange: (e: any, value: any) => handleConfirmClick(e, value),
        tooltipMessage: "Usuarios",
        withIcon: false,
        wordLength: 60,
        handleIconClick: () => {},
      },
    },
  ];
  return columns;
};

export default columns;
