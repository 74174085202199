import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import {
  ILoadTypeProcess,
  TypeProcessMassiveResponse,
} from "../store/models/TypeProcessUnit";
import { UpdateState } from "../store/models/UpdateState";
import {
  AdvanceSearch,
  IAudit,
  ITypeProcess,
  PaginatorWithFilter,
} from "../store/types";
import {
  ListBasicResponse,
  ResponseTemplate,
  ResultResponse,
  StandardResponse,
  TypeProcessExcel,
} from "../type";
import {
  ITypeProcessViewResponse,
  ITypeProcessViewCapturadorResponse,
  ITypeProcessViewRnecResponse,
} from "../store/models/TypeProcessView";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const TYPE_PROCESS_VIEW_READ = "TypeProcess/TypeProcessViewRead";
const TYPE_PROCESS_VIEW_RNEC_READ = "TypeProcess/TypeProcessViewRnecRead";
const TYPE_PROCESS_VIEW_CAPTURADOR_READ =
  "TypeProcess/TypeProcessViewCapturadorRead";
const TYPE_PROCESS_STATE = "TypeProcess/State";
const TYPE_PROCESS_AUDIT = "TypeProcess/Audit";
const TYPE_PROCESS_EXCEL = "TypeProcess/GetProcessTypesExcel";
const TYPE_PROCESS_VALIDATE = "TypeProcess/TypeProcessMassive";
const TYPE_PROCESS_MASSIVE_SAVE = "TypeProcess/TypeProcessMassiveSave";
const TYPE_PROCESS = "TypeProcess/TypeProcess";
const ADVANCE_SEARCH = "TypeProcess/AdvanceSearch";
const ADVANCE_SEARCH_RNEC = "TypeProcess/AdvanceSearchRnec";
const ADVANCE_SEARCH_CSV = "TypeProcess/AdvanceSearchCsv";
const TYPE_PROCESS_TEMPLATE = "TypeProcess/TypeProcessMassiveTemplate";

/**
 * @description Servicio de biometria para obtener la informacion de auditoria de un type process
 * @param { ITypeProcess } body - parametro que representa a un objeto del tipo ITypeProcess
 * @returns { StandardResponse<IAudit> } IAudit
 */
export const typeProcessAuditService = async (
  body: ITypeProcess
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_AUDIT,
      body,
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria para obtener todos los type process al cual se le implementa el paginador del lado del servidor y una busqueda basica.
 * @param { PaginatorWithFilter } body parametro de tipo PaginatorWithFilter representa al paginador, filtro y ordenado de los type process
 * @param { string } company Parametro de tipo string que representa el ID de la compañia
 * @returns { StandardResponse<ListBasicResponse<ITypeProcessViewResponse>> } ITypeProcessViewResponse
 */
export const getTypeProcessView = async (
  body: PaginatorWithFilter,
  company?: string
): Promise<StandardResponse<ListBasicResponse<ITypeProcessViewResponse>>> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_VIEW_READ,
      {
        ...body,
        company,
      },
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria para obtener los type process con la informacion para el rol RNEC al cual se le implementa el paginador del lado del servidor y una busqueda basica.
 * @param { PaginatorWithFilter } body parametro de tipo PaginatorWithFilter representa al paginador, filtro y ordenado de los type process
 * @param { string } company Parametro de tipo string que representa el ID de la compañia
 * @returns { StandardResponse<ListBasicResponse<ITypeProcessViewRnecResponse>> } ITypeProcessViewRnecResponse[]
 */
export const getTypeProcessViewRnec = async (
  body: PaginatorWithFilter,
  company?: string
): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessViewRnecResponse>>
> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_VIEW_RNEC_READ,
      {
        ...body,
        company,
      },
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria para obtener los type process con la informacion para el rol CAPTURADOR
 * @returns { StandardResponse<ListBasicResponse<ITypeProcessViewCapturadorResponse>> } ITypeProcessViewCapturadorResponse[]
 */
export const getTypeProcessViewCapturador = async (): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessViewCapturadorResponse>>
> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_VIEW_CAPTURADOR_READ,
      {},
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description servicio de biometria para almacenar la informacion de un type process
 * @param { ITypeProcess } body parametro de tipo ITypeProcess que contiene el objeto con al informacion de un type process
 * @returns { AxiosResponse<StandardResponse<ResultResponse>> } ResultResponse
 */
export const createTypeProcessService = async (
  body: ITypeProcess
): Promise<AxiosResponse<StandardResponse<ResultResponse>>> => {
  try {
    const response = await instance.post(TYPE_PROCESS, body, defaultHeaders());
    return response;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description servicio de biometria para actualizar la informacion de un type process
 * @param { ITypeProcess } body parametro de tipo ITypeProcess que contiene el objeto con al informacion de un type process
 * @returns { AxiosResponse<StandardResponse<ResultResponse>> } ResultResponse
 */
export const updateTypeProcessService = async (
  body: ITypeProcess
): Promise<AxiosResponse<StandardResponse<ResultResponse>>> => {
  try {
    const response = await instance.put(TYPE_PROCESS, body, defaultHeaders());
    return response;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria para cambiar el estado de un type process
 * @param { UpdateState } body parametro de tipo UpdateState que contiene el identificador del type process y el nuevo estado
 * @returns { AxiosResponse<StandardResponse<ResultResponse>> } ResultResponse
 */
export const updateTypeProcessServiceState = async (
  body: UpdateState
): Promise<AxiosResponse<StandardResponse<ResultResponse>>> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_STATE,
      body,
      defaultHeaders()
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria que retorna la informacion de el template de carga masiva de type process
 * @returns { StandardResponse<ResponseTemplate> } ResponseTemplate
 */
export const getMassiveTypeProcessTemplate = async (): Promise<
  StandardResponse<ResponseTemplate>
> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.get(TYPE_PROCESS_TEMPLATE, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * @description Servicio de biometria que retorna los registros de type process preparados para descargarlos en un archivo excel, se permite la busqueda avanzada para los distintos roles
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene el objeto con la configuracion para la busqueda avanzada
 * @returns { StandardResponse<ListBasicResponse<TypeProcessExcel>> } TypeProcessExcel
 */
export const getProcessTypesExcelService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<TypeProcessExcel>>> => {
  try {
    const response = await instance.post(
      TYPE_PROCESS_EXCEL,
      body,
      defaultHeaders()
    );
    const result = response.data;
    return result;
  } catch (error: any) {
    throw error;
  }
};
/**
 * @description Servicio de biometria que verifica los campos de los registros ingresados en el archivo para carga masiva
 * @param  { Array<ILoadTypeProcess> } body Parametro de tipo Array<ILoadTypeProcess> que contiene el array de objetos de type process para la carga masiva
 * @returns { StandardResponse<TypeProcessMassiveResponse> } TypeProcessMassiveResponse
 */
export const validateLoadStatus = async (
  body: Array<ILoadTypeProcess>
): Promise<StandardResponse<TypeProcessMassiveResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<TypeProcessMassiveResponse>> =
      await instance.post(TYPE_PROCESS_VALIDATE, body, defaultHeaders());
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

/**
 * @description Servicio de biometria que retorna los registros de type process mediante la busqueda avanzada
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los elementos para la paginacion y busqueda avanzada
 * @returns { ListBasicResponse<ITypeProcessViewResponse> } ITypeProcessViewResponse
 */
export const advanceSearchTypeProcess = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ITypeProcessViewResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ITypeProcessViewResponse>>
    > = await instance.post(ADVANCE_SEARCH, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna los registros de type process para el rol RNEC mediante la busqueda avanzada
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los elementos para la paginacion y busqueda avanzada
 * @returns { ListBasicResponse<ITypeProcessViewRnecResponse> } ITypeProcessViewRnecResponse
 */
export const advanceSearchRnecTypeProcess = async (
  body: AdvanceSearch
): Promise<
  StandardResponse<ListBasicResponse<ITypeProcessViewRnecResponse>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ITypeProcessViewRnecResponse>>
    > = await instance.post(ADVANCE_SEARCH_RNEC, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna los registros de type process preparados para la descarga de el archivo CSV.
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los elementos para la paginacion y busqueda avanzada
 * @returns { StandardResponse<ResponseTemplate> } ResponseTemplate
 */
export const advanceSearchTypeProcessCsv = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * @description Servicio de biometria que almacena la informacion de la carga masiva
 * @param { Array<ILoadTypeProcess> } body Parametro de tipo Array<ILoadTypeProcess> que contiene el array de objetos de type process para la carga masiva
 * @returns { StandardResponse<ResultResponse> } ResultResponse
 */
export const saveMassiveLoad = async (
  body: Array<ILoadTypeProcess>
): Promise<StandardResponse<ResultResponse>> => {
  try {
    var response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(TYPE_PROCESS_MASSIVE_SAVE, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
