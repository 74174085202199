import { Card, CardContent, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-circular-progressbar/dist/styles.css";
import orangeAlert from "../../assets/orangeAlert.svg";
import redAlert from "../../assets/redAlert.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: "#F6F6F6", //'rgba(73.155.234.1)'
    boxShadow: "0px 2px 3px #00000033",
    borderRadius: "10px",
    opacity: 1,
    height: "100%",
  },
  cardContent: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    textAlign: "center",
  },
  divVal: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "6px",
    paddingTop: "0px",
    marginTop: "15px",
    fontWeight: "bold",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  text: {
    fontSize: 50,
    color: "#FA5A00",
    marginRight: "5px",
    fontWeight: "bold",
  },
  subtext: {
    fontSize: 16,
    color: "#FA5A00",
    margin: "17px 10px 0 0",
  },
  title: {
    fontWeight: "normal",
    fontSize: "15px",
    color: "#1D71B8",
    padding: "0px",
    marginBottom: "5px",
  },
  pieText: {
    fontWeight: "normal",
    display: "inline",
    textTransform: "lowercase",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "13px",
    color: "#575757",
    textAlign: "end",
  },
}));

export type CardProps = {
  title: string;
  text: string;
  subText?: string;
  text1?: string;
  subText1?: string;
  progressBar?: Function;
  pieTextTitle?: string;
  pieText?: string;
  subTitle?: string;
  children?: Function;
  onlyBars?: Function;
  contentList?: Function;
  orangeAlertIcon?: boolean;
  redAlertIcon?: boolean;
};

/**
 *
 * @param props - Las propiedades representan al tipo CardProps
 * @returns {JSX.Element} - Retorna una tarjeta tipo Card con un contenedor grid donde se organizan los títulos, subtítulos, alertas y progress bar
 */
export function Cards(props: CardProps): JSX.Element {
  const classes = useStyles();
  const titleSize = props.orangeAlertIcon || props.redAlertIcon ? 11 : 12;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid
            item
            xs={titleSize}
            sm={titleSize}
            md={titleSize}
            lg={titleSize}
            xl={titleSize}
          >
            <Typography className={classes.title}>{props.title}</Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {props.orangeAlertIcon ? (
              <img
                src={orangeAlert}
                alt="orangeAlert Icon"
                width={"30px"}
                height={"26px"}
              />
            ) : (
              ""
            )}
            {props.redAlertIcon ? (
              <img
                src={redAlert}
                alt="redAlert Icon"
                width={"30px"}
                height={"26px"}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {props.subTitle && (
          <Typography className={classes.subTitle}>{props.subTitle}</Typography>
        )}

        {props.contentList ? <>{props.contentList()}</> : ""}
        {props.progressBar ? <>{props.progressBar()}</> : ""}

        {props.children ? <>{props.children()}</> : ""}
        {props.onlyBars ? <>{props.onlyBars()}</> : ""}

        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.text && props.subText && (
            <div className={classes.textContainer}>
              <Typography className={classes.text}>{props.text}</Typography>
              <Typography className={classes.subtext}>
                {props.subText}
              </Typography>
            </div>
          )}
          {props.text1 && props.subText1 && (
            <div className={classes.textContainer}>
              <Typography className={classes.text}>{props.text1}</Typography>
              <Typography className={classes.subtext}>
                {props.subText1}
              </Typography>
            </div>
          )}
        </div>

        <div className={classes.divVal}>
          <Typography style={{ fontWeight: "bold" }}>
            {props.pieTextTitle}
          </Typography>
          <Typography className={classes.pieText}>{props.pieText}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}
