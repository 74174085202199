import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComparisonResponse } from "../models/ComparisonModel";
import { IAudit, IComparisonExcel, Paginator, PaginatorWithFilterAndSort, sortBy } from "../types";
import { RootState } from "./rootReducer";

type ComparisonState = {
  showForm: boolean;
  comparison: ComparisonResponse | undefined;
  comparisons: ComparisonResponse[];
  comparisonAudit: IAudit | undefined;
  comparisonExcel: IComparisonExcel[],
  loadingComparison: boolean,
  size:number;
  paginatorComparison: PaginatorWithFilterAndSort;
};

const initialState: ComparisonState = {
  showForm: false,
  comparison: undefined,
  comparisons: [],
  comparisonAudit: undefined,
  comparisonExcel: [],
  loadingComparison: false,
  size:0,
  paginatorComparison:{
    paginator : { page: 1, itemsPage: 10,recharge: true },
    filter: "",
    sort: "recent",
  },
};

const comparisonSlice = createSlice({
  name: "Comparison",
  initialState,
  reducers: {
    auditComparison: (state , { payload }: PayloadAction<IAudit | undefined>) => {
      state.comparisonAudit = payload;
    },
    setComparison: (
      state,
      { payload }: PayloadAction<ComparisonResponse[]>
    ) => {
      state.comparisons = payload;
    },
    setComparisonExcel: (state, {payload}: PayloadAction<IComparisonExcel[]>) => {
      state.comparisonExcel = payload;
    },
    setCurrentComparison: (
      state,
      { payload }: PayloadAction<ComparisonResponse | undefined>
    ) => {
      state.comparison = payload;
    },
    setFilterComparison: (state, { payload }: PayloadAction<string>) => {
      state.paginatorComparison.filter = payload;
    },
    setLoadingComparison: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingComparison = payload;
    },
    setPaginatorComparison:(state , {payload}: PayloadAction<Paginator> ) => {
      if(isNaN(payload.page)){
        payload.page=1;
      }
      state.paginatorComparison.paginator = payload;
    },
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setSize:(state , {payload}: PayloadAction<number>) => {
      state.size = payload;
    },
    setSortComparison: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorComparison.sort = payload;
    },
  },
});

export const {
  auditComparison,
  setComparison,
  setComparisonExcel,
  setCurrentComparison,
  setFilterComparison,
  setLoadingComparison,
  setPaginatorComparison,
  setShowForm,
  setSize,
  setSortComparison,
} = comparisonSlice.actions;

export const ComparisonSelector = (state: RootState) =>
  state.comparison;

export default comparisonSlice.reducer;
