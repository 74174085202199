import { Tooltip, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import "./style.css";

type Props = {
  title?: string;
  style?: React.CSSProperties;
  marqueeStyle?: React.CSSProperties;
  withTooltip?: boolean;
};

const Marquee: React.FC<Props> = ({ title, children, style, marqueeStyle, withTooltip = true }) => {

  const [parentHeight, setParentHeight] = useState(0);
  const [parentwidth, setParentWidth] = useState(0);
  const [childrentHeight, setChildrentHeight] = useState(0);
  const [childrentwidth, setChildrentWidth] = useState(0);

  const parentRef = useCallback(
    (node) => {
      if (node !== null) {
        setParentHeight(node.getBoundingClientRect().height);
        setParentWidth(node.getBoundingClientRect().width);
      }
    },
    [parentwidth, childrentwidth]
  );

  const childRef = useCallback((node) => {
    if (node !== null) {
      setChildrentHeight(node.getBoundingClientRect().inlineSize);
      setChildrentWidth(node.getBoundingClientRect().width);
    }
  }, []);

  setTimeout(() => {}, 100);

  const contentMarquee = () => {
    return (
      <Typography
        style={
          style
            ? style
            : {
                fontSize: "16px",
                padding: 0,
              }
        }
        className={`${childrentwidth > parentwidth ? "slide" : "hold"}`}
        ref={childRef}
      >
        {children}
      </Typography>
    );
  };

  return (
    <div
      className="marquee"
      ref={parentRef}
      style={marqueeStyle ? marqueeStyle : {}}
    >
      {
        withTooltip 
          ? <Tooltip title={`${children}`} placement="top">{contentMarquee()}</Tooltip>
          : contentMarquee()
      }
    </div>
  );
};

export default Marquee;
