import {
  advanceSearch,
  auditComparisonService,
  getAllComparison,
} from "../../services/ComparisonService";
import { Comparison } from "../models/ComparisonModel";
import { setError } from "../slices/CommonSlice";
import {
  auditComparison,
  setComparison,
  setFilterComparison,
  setLoadingComparison,
  setPaginatorComparison,
  setSize,
  setSortComparison,
} from "../slices/ComparisonSlice";
import { AppThunk } from "../store";
import { AdvanceSearch, PaginatorWithFilterAndSort, sortBy } from "../types";

/**
 * @description - Lanza el servicio getAllComparison donde se envia los parametros de paginacion, id de compañia e id de sede para obtener los comparison realizados de la sede
 * @param { PaginatorWithFilterAndSort } paginator - parametro de tipo PaginatorWithFilterAndSort donde se configura el paginador, el ordenado y la organizacion de la informacion
 * @param { string } company - parametro de tipo string que representa el identificador de la compañia
 * @param { string } businessUnit - parametro de tipo string que representa el identificador de la sede
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista de comparison a el hook comparisons con el tipo ComparisonResponse[].
 */
export const getAllComparisonAC =
  (
    paginator: PaginatorWithFilterAndSort,
    company?: string,
    businessUnit?: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComparison(true));
    getAllComparison(paginator, company, businessUnit)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setComparison(!!response.result.count ? response.result.records : []));
          dispatch(setSize(response.result.count));
        } else {
          dispatch(setError(response.result.information));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => dispatch(setLoadingComparison(false)));
  };

/**
 * @description - Lanza el servicio auditComparisonService donde se envia los datos de un comparison para generar una auditoria de este
 * @param { Comparison } body - Parametro de tipo Comparison que contienen los campos requeridos de un comparison
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista auditoria de comparison a el hook comparisonAudit con el tipo IAudit.
 */
export const comparisonAuditAC =
  (body: Comparison): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComparison(true));
    auditComparisonService(body)
      .then((data) => {
        const audit = data.result;
        dispatch(auditComparison(audit));
      })
      .catch((error) => {})
      .finally(() => dispatch(setLoadingComparison(false)));
  };

/**
 * @description - Lanza el servicio advanceSearch donde se envia la informacion de busqueda avanzada y se retorna los comparison filtrados
 * @param { Comparison } body - Parametro de tipo Comparison que contienen los campos requeridos de un comparison
 * @returns { Function } se realiza la solicitud a la API y dispara el dispatch con la lista auditoria de comparison a el hook comparisonAudit con el tipo IAudit.
 */
export const advanceSearchComparisonAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingComparison(true));
    advanceSearch(body)
      .then((data) => {
        const { records } = data.result;
        dispatch(setComparison(records));
        dispatch(setSize(data.result.count));
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information));
      })
      .finally(() => dispatch(setLoadingComparison(false)));
  };

/**
 * @description Metodo que lanza directamente el dispatch al sub-indice paginator de el hook paginatorComparison con el numero de pagina, items por pagina y si se requiere recargar la informacion
 * @param { number } page Parametro de tipo number que representa el numero de pagina
 * @param { number } itemsPage Parametro de tipo number que representa la cantidad de elementos por pagina
 * @param { boolean } recharge Parametro de tipo boolean que representa la opcion de recargar la informacion
 */
export const getPaginatorAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorComparison({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

  /**
   * @description Metodo que lanza directamente el dispatch al sub-indice filter de el hook paginatorComparison con la cadena de texto que se enviará al filtro basico de comparison
   * @param { string } filter Parametro de tipo string que representa la cadena de texto que se usará en la consulta de filtro basico
   */
export const getFilterAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterComparison(filter));
  };

/**
 * @description Metodo que lanza directamente el dispatch al sub-indice sort de paginatorComparison con la opcion de el tipo SortBy para ordenar los registros de comparison
 * @param { sortBy } sort Parametro de tipo sortBy que contiene un enumerador con opciones ( "recent" | "old" | "active" | "inactive" | "a-z" | "z-a" ) para ordenar los registros mostrados en comparison
 */
export const getSortAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortComparison(sort));
  };
