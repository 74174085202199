import React from "react";
import { Grid } from "@material-ui/core";
import FindInPageIcon from "@material-ui/icons/FindInPage";

import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";

//COMPONENTS
import { NoBusinessUnitIcon } from "../../components/Icons/NoBusinessUnitIcon";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";
import DataTable from "../../components/Table/DataTable";
import { ButtonsProps, Column } from "../../components/Table/types";
import { CustomTypography } from "../../components/typography/CustomTypography";
import { selectCurrentCompany } from "../../utils/general/utils";

//CUSTOMS HOOKS
import useCompany from "../../hooks/useCompany";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";

type CompanyViewProps = {
  token: string;
  companyIdSelected?: string;
  companyNameSelected?: String;
  setCompanyIdSelected?: any;
  setCompanyNameSelected?: any;
  nitCompany?: string;
  justTable?: boolean;
};
const CompanyViewList: React.FC<CompanyViewProps> = React.memo(
  ({ nitCompany, justTable }) => {
    // CUSTOM HOOK
    const {
      companies,
      fieldsAdvanced,
      fieldsAdvancedSearch,
      fieldsLog,
      handleChangePage,
      handleChangeRowsPerPage,
      handleFilter,
      handleOnClose,
      handleOnOpen,
      handleOnSearch,
      handleSort,
      handleSortOptionLog,
      isLog,
      loadingCompany,
      paginatorCompany,
      rol,
      rowsPerPage,
      setPage,
      showFormAdv,
      sizeCompany,
    } = useCompany({ nitCompany });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tab, setTab] = React.useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoad, setLoad] = React.useState(null);
    const [option, setOption] = React.useState(null);

    //#region  table configuration
    const columns: Column[] = [
      {
        id: "businessName",
        label: "Nombre / Razón social",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "documentTypeDesc",
        label: "Tipo Documento",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "documentNumber",
        label: "Número documento",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "email",
        label: "Email",
        minWidth: 140,
        align: "center",
        haveDropDown: true,
      },
      {
        id: "createdon",
        label: "Fecha creación",
        minWidth: 50,
        align: "center",
        haveDropDown: true,
        format: (value: string) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        id: "viewIcon",
        label: "Action",
        align: "center",
        haveDropDown: false,
      },
    ];

    const getButtons = () => {
      let button: ButtonsProps = {
        searchBar: {
          cellSize: {
            lg: 5,
            md: 5,
            sm: 12,
            xs: 12,
          },
        },
        menuOrder: {
          justify: "space-evenly",
          options: [
            {
              label: "Activo",
              columns: [
                {
                  prop: "state",
                },
              ],
            },
            {
              label: "Inactivo",
              columns: [
                {
                  prop: "state",
                  direction: -1,
                },
              ],
            },
            {
              label: "Más reciente",
              columns: [
                {
                  prop: "createdOn",
                  direction: 1,
                  format: (date: any) => {
                    return new Date(date).getTime();
                  },
                },
              ],
            },
            {
              label: "Más antiguo",
              columns: [
                {
                  prop: "createdOn",
                  direction: -1,
                  format: (date: any) => {
                    return new Date(date).getTime();
                  },
                },
              ],
            },
          ],
          cellSize: {
            lg: 2,
            md: 2,
            sm: 12,
            xs: 12,
          },
        },
        selectLog: {
          title: "Log consolidado",
          icon: <FindInPageIcon />,
          justify: "center",
          options: [
            {
              label: "Log de peticiones",
              columns: [
                {
                  prop: "state",
                },
              ],
            },
            {
              label: "Log de consultas",
              columns: [
                {
                  prop: "state",
                  direction: -1,
                },
              ],
            },
            {
              label: "Log de respuesta no satisfactoria",
              columns: [
                {
                  prop: "createdOn",
                  direction: 1,
                },
              ],
            },
          ],
          cellSize: {
            lg: 2,
            md: 2,
            sm: 12,
            xs: 12,
          },
        },

        justify: "flex-start",

        advancedSearchButton: {
          cellSize: {
            xs: 12,
            sm: 12,
            md: 2,
            lg: 2,
          },
        },
      };

      return button;
    };

    const notDataFound = () => {
      return (
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
          >
            <NoBusinessUnitIcon width="90px" height="90px" />
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
          >
            <CustomTypography
              type="definition"
              children={"Aún no se han asignado empresas"}
            />{" "}
          </Grid>
        </Grid>
      );
    };

    //#endregion
    /**
     * Componente que renderiza un DataTable con los registros de company
     * @returns { JSX.Element } JSX.Element
     */
    const TabletComponent = (): JSX.Element => {
      return (
        <DataTable
          columns={columns}
          buttons={getButtons()}
          data={companies}
          havePagination={sizeCompany > 10 ? true : false}
          option={option}
          setOption={setOption}
          setLoad={setLoad}
          rol={rol}
          setTab={setTab}
          page={paginatorCompany.paginator.page - 1}
          setPage={setPage}
          paginator={paginatorCompany}
          size={sizeCompany}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFilter={handleFilter}
          handleSort={handleSort}
          handleSortLog={handleSortOptionLog}
          rowsPerPage={rowsPerPage}
          handleOnOpenAdvSearch={handleOnOpen}
          notDataFound={
            paginatorCompany.filter && companies.length === 0
              ? undefined
              : notDataFound()
          }
          handleCellClick={(_e, value) => {
            selectCurrentCompany(
              value.businessName + "," + value.documentNumber
            );
          }}
          loading={loadingCompany}
        />
      );
    };
    return (
      <div>
        <HashDowloandModal />
        <AdvancedSearchModal
          open={rol !== "BIOMETRIA RNEC" ? false : showFormAdv}
          handleOnClose={handleOnClose}
          handleOnSearch={handleOnSearch}
          fieldsAdvancedSearch={fieldsAdvancedSearch}
          fieldsAdvanced={!isLog ? fieldsAdvanced : fieldsLog}
          parentComponent={!isLog ? "CompanySelector" : undefined}
          isExport={isLog}
        />
        {!justTable ? (
          <ViewLayout
            isNew={true}
            isEdit={false}
            headerTitle="Búsqueda de empresa"
            editOption={false}
            isCentered={true}
          >
            <TabletComponent />
          </ViewLayout>
        ) : (
          <TabletComponent />
        )}
        <FullLoader open={loadingCompany} viewLoader={true} />
      </div>
    );
  }
);

export default CompanyViewList;
