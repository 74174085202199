import { makeStyles, Button } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0px 3px 3px #0000001A",
    border: "1px solid #D5D5D5",
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    padding: "4px",

    "& span": {
      fontSize: "12px",
      font: "normal normal normal Muli",
    },
  },

  buttonDisabled: {
    background: "#FFFFFF",
    boxShadow: "0px 3px 3px #0000001A",
    border: "1px solid #D5D5D5",
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    padding: "4px",

    "& span": {
      fontSize: "12px",
      font: "normal normal normal Muli",
    },
    opacity: "0.5 !important",
  },
}));

interface Props {
  label: string;
  disabled: boolean;
  onClick: any;
}

const BtnCreateATDP = ({ label, disabled, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Button
      className={disabled === false ? classes.root : classes.buttonDisabled}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
    >
      <InsertDriveFileIcon
        style={{ fontSize: "23px", marginRight: "10px", color: "#009BDD" }}
      ></InsertDriveFileIcon>
      <div style={{ margin: "5px", textTransform: "none" }}>{label}</div>
    </Button>
  );
};

export default BtnCreateATDP;
