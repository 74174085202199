import {
  getAllAuditLogComparison,
  getAllAuditLogConsult,
  getAllAuditLogUnsatisfactoryAnswer,
  getAuditLogComparisonCsvService,
  getAuditLogConsultCsvService,
  getAuditLogUnsatisfactoryAnswerCsvService,
} from "../../services/AuditLogService";
import { ComparisonLogResponse } from "../models/AuditLogModel";
import {
  setAuditLogComparison,
  setFilterAuditLog,
  setLoadingAuditLog,
  setOptionLog,
  setPaginatorAuditLog,
  setSize,
  setSortAuditLog,
} from "../slices/AuditLogSlice";
import { setHashCsv, setHashCsvModal } from "../slices/CommonSlice";
import { AppThunk } from "../store";
import { AdvanceSearch, sortBy, SortOptionLog } from "../types";

/**
 * @description Lanza el servicio getAverageCompany que retorna todas las auditorias de los comparison.
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con todos los comparison a el hook auditLogComparison con el tipo ComparisonLogResponse[].
 */
export const getAllAuditLogComparisonAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAllAuditLogComparison(body)
      .then((response) => {
        const responseAudit: ComparisonLogResponse[] =
          response.result.records.map((item) => {
            return {
              comparisonId: item.comparisonId,
              nut: item.nut,
              operationDate: item.operationDate,
            };
          });
        if (response.statusCode === 200) {
          dispatch(setAuditLogComparison(responseAudit));
          dispatch(setSize(response.result.count));
        } else {
          console.error(response.result.information);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };

/**
 * @description Lanza el servicio getAllAuditLogConsult que retorna la auditoria de consultas
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con todos los logs de consulta a el hook auditLogComparison con el tipo ComparisonLogResponse[].
 */
export const getAllAuditLogConsultAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAllAuditLogConsult(body)
      .then((response) => {
        const responseAudit: ComparisonLogResponse[] =
          response.result.records.map((item) => {
            return {
              comparisonId: item.comparisonId,
              nut: item.nut,
              operationDate: item.operationDate,
            };
          });
        if (response.statusCode === 200) {
          dispatch(setAuditLogComparison(responseAudit));
          dispatch(setSize(response.result.count));
        } else {
          console.error(response.result.information);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };

/**
 * @description Lanza el servicio getAllAuditLogUnsatisfactoryAnswer que retorna los logs de las respuestas no satisfactorias
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API y dispara el dispatch con todos los logs de respuestas no satisfactorias a el hook auditLogComparison con el tipo ComparisonLogResponse[].
 */
export const getAllAuditLogUnsatisfactoryAnswerAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAllAuditLogUnsatisfactoryAnswer(body)
      .then((response) => {
        const responseAudit: ComparisonLogResponse[] =
          response.result.records.map((item) => {
            return {
              comparisonId: item.comparisonId,
              nut: item.nut,
              operationDate: item.operationDate,
            };
          });
        if (response.statusCode === 200) {
          dispatch(setAuditLogComparison(responseAudit));
          dispatch(setSize(response.result.count));
        } else {
          console.error(response.result.information);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };

/**
 * @description Este metodo lanza directamente al sub-indice paginator del hook paginatorAuditLog los valores para la paginacion de la informacion
 * @param { number } page - Parametro que representa el numero de pagina con el tipo de dato number
 * @param { number } itemsPage - Parametro que la cantidad de elementos por pagina con el tipo de dato number
 * @param { boolean } recharge - Parametro que representa si se necesita recargar la busqueda con el tipo de dato boolean
 */
export const getPaginatorAuditLogAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorAuditLog({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };

/**
 * @description Este metodo lanza directamente el sub-indice filter de el hook de paginatorAuditLog el texto de busqueda para el filtro
 * @param { string } filter
 */
export const getFilterAuditLogAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterAuditLog(filter));
  };

/**
 * @description Este metodo lanza directamente el sub-indice sort de el hook de paginatorAuditLog con el tipo sortBy
 * @param { sortBy } sort - Parametro de tipo sortBy ( "recent" | "old" | "active" | "inactive" | "a-z" | "z-a" )
 */
export const getSortAuditLogAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortAuditLog(sort));
  };

/**
 * @description Este metodo lanza directamente el sub-indice sortOptionLog de el hook de paginatorAuditLog con el tipo SortOptionLog
 * @param { SortOptionLog } sortLog - Parametro de tipo SortOptionLog ( "comparison" | "consult" | "unsatisfactoryAnswer" )
 */
export const getSortOptionAuditLogAC =
  (sort: SortOptionLog): AppThunk =>
  (dispatch) => {
    dispatch(setOptionLog(sort));
  };

/**
 * @description Lanza el servicio getAuditLogComparisonCsvService que retorna los registros de log de comparison y prepara la data para descargar un archivo csv
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API, prepara el documento csv y almacena el hash con los registros posteriormente inicializa un modal para descarga.
 */
export const getAuditLogComparisonCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAuditLogComparisonCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };

/**
 * @description Lanza el servicio getAuditLogConsultCsvService que retorna los registros de log de consultas y prepara la data para descargar un archivo csv
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API, prepara el documento csv y almacena el hash con los registros posteriormente inicializa un modal para descarga.
 */
export const getAuditLogConsultCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAuditLogConsultCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };

/**
 * @description Lanza el servicio getAuditLogUnsatisfactoryAnswerCsvService que retorna los registros de log de respuestas no satisfactorias y prepara la data para descargar un archivo csv
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns {Function} se realiza la solicitud a la API, prepara el documento csv y almacena el hash con los registros posteriormente inicializa un modal para descarga.
 */
export const getAuditLogUnsatisfactoryAnswerCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAuditLog(true));
    getAuditLogUnsatisfactoryAnswerCsvService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const link = `data:application/zip;base64,${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = link;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((err: any) => console.error(err))
      .finally(() => dispatch(setLoadingAuditLog(false)));
  };
