import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  LinearPercentLabel,
  LinearPercentBar,
  ContentList,
  DonutProgress,
} from "../../../components/ProgressBar";
import DougnutHits from "../../../components/Charts/Doughnuts/HitNoHitNovelties";
import VerticalBarHits from "../../../components/Charts/VerticalBar/HitNoHitNovelties";
import { SelectInputRounded } from "../../../components/Inputs/SelectInputRounded";
import { RoundedCard } from "../../../components/Card/RoundedCard";
import DateTextField from "../../../components/Inputs/DateTextField";

import { useDateTextField } from "../../../hooks/useDateTextField";

import {
  getAverageCompanyMonthlyAC,
  getCustomerPlanExpiredAC,
  getDoughnutTransactionAC,
  getNextBalanceToExpireAC,
  getNextPlanExpirationsAC,
  getNumberRecordByTypeProcessCompanyAC,
  getRecordCompanyByMonthAC,
  getRecordbyCompanyAC,
  getValidatedCountersAC,
} from "../../../store/action-creators/DashboardActionCreators";
import { DashboardSelector } from "../../../store/slices/DashboardSlice";
import {
  CustomerConsumption,
  DataCompaniesForChart,
  DataCotejo,
  IPlanExpired,
  SelectOption,
  SuperAdminConsumption,
  ValidateCountersNit,
} from "../../../store/models/DashboardModel";

import { MESSAGES } from "../../../utils/types/const";
import { formatDateIso } from "../../../utils/general/formatDate";
import { CommonSelector } from "../../../store/slices/CommonSlice";
import useDashboard from "../../../hooks/useDashboard";

/**
 * @description This component show all Super Admin role dashboard items, only it works when user is "BIOMETRIA SUPERADMIN" rol
 * @returns {JSX.Element} JSX element with the dashboard elements for Super Admin user
 */
function SuperAdmin({ SwitchCompany, Role }: SuperAdminConsumption) {
  const { loading } = useSelector(CommonSelector);
  // REDUX
  const dispatch = useDispatch();
  const {
    nextBalanceToExpire,
    recordCompanies,
    typeProcessCompanies,
    customerPlanExpired,
    nextPlanExpirations,
    recordCompaniesMonthly,
    averageCompaniesMonthly,
    customerPlanExpiredMessage,
    nextBalanceToExpireMessage,
    nextPlanExpirationsMessage,
    doughnutTransactions,
    verticalBarTransactions,
    validatedCounters,
  } = useSelector(DashboardSelector);

  const { switchCompany } = useDashboard();

  // UseStates
  const [dataRecordCompanies, setDataRecordCompanies] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dataRecordCompaniesMonthly, setDataRecordCompaniesMonthly] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dataTypeProcessCompanies, setDataTypeProcessCompanies] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dataPlanExpiration, setDataPlanExpiration] = useState<IPlanExpired[]>(
    []
  );
  const [dataAverageCompaniesMonthly, setAverageCompaniesMonthly] = useState<
    DataCompaniesForChart[]
  >([]);
  const [dataCompaniesFinishingCotejo, setDataCompaniesFinishingCotejo] =
    useState<DataCotejo[]>([]);
  const [dataPlanExpired, setDataPlanExpired] = useState<IPlanExpired[]>([]);

  const [refreshChart, setRefreshChart] = useState<boolean>(true);

  //Custom Hooks
  const { date, messageErrors, onChangeDate, selectDate, setSelectDate } =
    useDateTextField({ switchCompany, role: Role });

  const DashboardPrintSchema = yup.object().shape({
    dateType: yup
      .string()
      .max(100, `${MESSAGES.max} 100 caracteres`)
      .required(MESSAGES.required)
      .trim(),
  });

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(DashboardPrintSchema),
    shouldUnregister: false,
  });

  const optionDate: Array<SelectOption> = [
    {
      key: 0,
      value: "day",
      label: "Días",
    },
    {
      key: 1,
      value: "month",
      label: "Meses",
    },
  ];

  /*Metodo que se usa para remover de la lista de empresas de contratos vencidos las que actualmente poseen un contrato nuevo vigente */
  const removeLiencesEnablesFromExpiration = (
    dataPlanExpired: IPlanExpired[],
    validatedCounters: ValidateCountersNit[]
  ) => {
    var filtered: IPlanExpired[] = [...dataPlanExpired];
    if (dataPlanExpired !== undefined && validatedCounters !== undefined) {
      if (validatedCounters.length > 0 && dataPlanExpired.length > 0) {
        validatedCounters.forEach((counter) => {
          filtered = filtered.filter(
            (license) => license.documentNumber !== counter.documentNumber
          );
        });
      }
      return filtered;
    } else {
      return false;
    }
  };

  // UseEffects
  useEffect(() => {
    const response: CustomerConsumption = {
      ServiceName: "Biometria",
      QuantityCustomer: 5,
    };
    dispatch(getRecordbyCompanyAC());
    dispatch(getNumberRecordByTypeProcessCompanyAC());
    dispatch(getNextBalanceToExpireAC(response));
    dispatch(getCustomerPlanExpiredAC(response));
    dispatch(getNextPlanExpirationsAC(response));
    dispatch(getRecordCompanyByMonthAC());
    dispatch(getAverageCompanyMonthlyAC());
    dispatch(
      getValidatedCountersAC({
        documentNumber: "",
        valids: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setDataPlanExpiration(nextPlanExpirations);
  }, [nextPlanExpirations]);

  useEffect(() => {
    setDataPlanExpired(customerPlanExpired);
  }, [customerPlanExpired]);

  useEffect(() => {
    /** Recorrer empresas y crear podio de promedio de cotejos consumidos por empresa por mes*/
    const data: DataCompaniesForChart[] = averageCompaniesMonthly?.map(
      (company) => ({
        name: company.name_Company,
        value: company.average,
      })
    );
    setAverageCompaniesMonthly(data);
  }, [averageCompaniesMonthly]);

  useEffect(() => {
    /** Recorrer empresas y crear podio de cotejos consumidos por empresa al día*/
    const data: DataCompaniesForChart[] = recordCompanies?.map((company) => ({
      name: company.names,
      value: company.number_Record,
    }));
    setDataRecordCompanies(data);
  }, [recordCompanies]);

  useEffect(() => {
    /** Recorrer empresas y crear podio empresas con mayores usos de cotejos al mes*/
    const data: DataCompaniesForChart[] = recordCompaniesMonthly?.map(
      (company) => ({
        name: company.names,
        value: company.number_Record,
      })
    );
    setDataRecordCompaniesMonthly(data);
  }, [recordCompaniesMonthly]);

  useEffect(() => {
    /** Recorrer empresas y crear podio de tipos de tramites mas usados*/
    const data: DataCompaniesForChart[] = typeProcessCompanies?.map(
      (company) => ({
        name: company.name_TypeProcess,
        value: company.number_Record,
        label: company.name_Company,
      })
    );
    setDataTypeProcessCompanies(data);
  }, [typeProcessCompanies]);

  useEffect(() => {
    /** Recorrer lista de empresas y almacenar las próximas empresas a expirar*/
    const data: DataCotejo[] = nextBalanceToExpire?.map((company) => ({
      name: company.customerName,
      consumo: company.quantityUsed,
      percentage: company.usagePercentage,
      totalBolsa: company.quantityTx,
      fontSizeConsumo: 16,
      fontSizeTotal: 12,
      width: 130,
      expirationDate: "",
    }));
    setDataCompaniesFinishingCotejo(data);
  }, [nextBalanceToExpire]);

  useEffect(() => {
    if (refreshChart) {
      dispatch(
        getDoughnutTransactionAC({
          BusinessUnitId: 0,
          Comparative: 1,
          StartDate: formatDateIso(),
          SwitchCompany: switchCompany,
        })
      );
      setRefreshChart(false);
    }
  }, [refreshChart, dispatch, switchCompany]);

  useEffect(() => {
    if (validatedCounters !== null || customerPlanExpired !== null) {
      const removes = removeLiencesEnablesFromExpiration(
        customerPlanExpired,
        validatedCounters
      );
      if (removes) {
        setDataPlanExpired(removes);
      }
    }
  }, [validatedCounters, customerPlanExpired]);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Empresas con mayor cantidad de cotejos al mes"
          text=""
          subTitle="Cotejos / mes actual"
        >
          <LinearPercentBar
            elements={dataRecordCompaniesMonthly}
            withTooltip={false}
          />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Promedio de cotejos consumidos por empresa al día"
          text=""
          subTitle="Cotejos / dia hoy"
        >
          <LinearPercentBar
            elements={dataRecordCompanies}
            withTooltip={false}
          />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Trámite más usual por empresa"
          text=""
          subTitle="Cantidad / día hoy"
        >
          <LinearPercentLabel
            elements={dataTypeProcessCompanies}
            withTooltip={false}
          />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
        <RoundedCard
          title="Empresas próximas a vencer contrato"
          text={
            nextPlanExpirationsMessage != null ? nextPlanExpirationsMessage : ""
          }
        >
          <ContentList elements={dataPlanExpiration} />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <RoundedCard
          title="Promedio de consumo de cotejos mensuales por empresa"
          text=""
          subTitle="Cotejos / mes"
        >
          <LinearPercentBar
            elements={dataAverageCompaniesMonthly}
            withTooltip={false}
          />
        </RoundedCard>
      </Grid>

      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <RoundedCard
          title="Empresas próximas a terminar cupo de cotejos"
          text={
            nextBalanceToExpireMessage != null ? nextBalanceToExpireMessage : ""
          }
          orangeAlertIcon={true}
        >
          <DonutProgress elements={dataCompaniesFinishingCotejo} />
        </RoundedCard>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <RoundedCard
          title="Empresas con contratos vencidos"
          text={
            customerPlanExpiredMessage != null ? customerPlanExpiredMessage : ""
          }
          redAlertIcon={true}
        >
          {dataPlanExpired.length > 0 && (
            <ContentList elements={dataPlanExpired} />
          )}
        </RoundedCard>
      </Grid>

      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <RoundedCard
            title="Cotejos Hit, No hit y novedades"
            text=""
            subTitle="Cotejos / día hoy"
          >
            <DougnutHits
              data={doughnutTransactions}
              colors={["#5FBE2C", "#D50915", "#4572A7"]}
              refreshChart={refreshChart}
              setRefreshChart={setRefreshChart}
            />
          </RoundedCard>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={10}>
          <RoundedCard
            title="Consulta de Cotejos Hit, No hit y novedades por mes o día"
            text=""
          >
            <SelectInputRounded
              label="Selecciona tipo de fecha que deseas consultar"
              control={control}
              props={{
                helperText: errors.documentType?.message,
                error: errors.documentType ? true : false,
                disabled: false,
                autoComplete: "off",
              }}
              onChange={(e: any) => setSelectDate(e)}
              name="dateType"
              options={optionDate}
            />

            {!!selectDate && !loading ? (
              <>
                <DateTextField
                  onChangeDate={onChangeDate}
                  currentDate={formatDateIso()}
                  row={date}
                  errors={messageErrors}
                  typeDate={selectDate !== "day" ? "month" : "date"}
                />
                <VerticalBarHits data={verticalBarTransactions} />
              </>
            ) : (
              <></>
            )}
          </RoundedCard>
        </Grid>
      </Grid>
    </>
  );
}

export default SuperAdmin;
