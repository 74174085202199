import { IconProps } from "./DeviceIcon";

export const NoBusinessUnitIcon = ({ height, width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 91.43 80"
    >
      <defs>
        <style>{`.a,.c{fill:#fff;}.a,.d{stroke:#b2b2b2;}.b{fill:#b2b2b2;}.d,.h{fill:none;}.e,.f{fill:#fa5a1d;}.e{stroke:#fff;stroke-width:3px;}.g,.i{stroke:none;}.i{fill:#fff;}`}</style>
      </defs>
      <g transform="translate(0.354)">
        <g className="a" transform="translate(-0.354)">
          <circle className="g" cx="40" cy="40" r="40" />
          <circle className="h" cx="40" cy="40" r="39.5" />
        </g>
        <g transform="translate(17 21)">
          <g className="a" transform="translate(-0.355)">
            <path
              className="g"
              d="M2,0H19a2,2,0,0,1,2,2V38a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
            />
            <path
              className="h"
              d="M2,.5H19A1.5,1.5,0,0,1,20.5,2V37a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,37V2A1.5,1.5,0,0,1,2,.5Z"
            />
          </g>
          <path
            className="b"
            d="M2,0H27a2,2,0,0,1,2,2V38a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
            transform="translate(16.645)"
          />
          <path
            className="c"
            d="M1,0H8A1,1,0,0,1,9,1V11a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z"
            transform="translate(26.645 27)"
          />
          <rect
            className="c"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(22.999 7.131)"
          />
          <rect
            className="c"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(22.999 16.626)"
          />
          <rect
            className="c"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(33.681 7.131)"
          />
          <rect
            className="c"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(33.681 16.626)"
          />
          <rect
            className="a"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(6.382 5.944)"
          />
          <rect
            className="a"
            width="5.935"
            height="4.946"
            rx="1"
            transform="translate(6.382 15.439)"
          />
        </g>
        <path
          className="d"
          d="M-11419.8,5518.862h69.718"
          transform="translate(11425.305 -5460.362)"
        />
        <g transform="translate(65.657 22.311)">
          <g className="e" transform="translate(0 29.002)">
            <ellipse
              className="g"
              cx="5.366"
              cy="5.366"
              rx="5.366"
              ry="5.366"
            />
            <ellipse
              className="h"
              cx="5.366"
              cy="5.366"
              rx="6.866"
              ry="6.866"
            />
          </g>
          <g className="f" transform="translate(-372.186 -195.944)">
            <path
              className="g"
              d="M 382.13525390625 224.0639953613281 L 382.1344299316406 224.0639953613281 L 382.1336059570312 224.0639953613281 C 380.4476928710938 224.0621490478516 378.8435363769531 223.2511596679688 377.8424682617188 221.8945922851562 C 376.8414306640625 220.5379028320312 376.5396728515625 218.7658843994141 377.0352783203125 217.1544036865234 L 383.2005920410156 197.1017303466797 C 383.9230651855469 194.7517242431641 385.8730163574219 193.01025390625 388.2895202636719 192.5569000244141 C 388.6900634765625 192.4817504882812 389.0994262695312 192.4436492919922 389.5062561035156 192.4436492919922 C 391.5351867675781 192.4436492919922 393.4208374023438 193.3565368652344 394.6797485351562 194.9482269287109 C 396.2048950195312 196.8765411376953 396.532470703125 199.4703216552734 395.53466796875 201.7173767089844 L 387.0205078125 220.8908538818359 C 386.1619262695312 222.8190155029297 384.2445983886719 224.0639953613281 382.13525390625 224.0639953613281 Z"
            />
            <path
              className="i"
              d="M 382.13525390625 222.5639953613281 C 383.6547241210938 222.5639953613281 385.0316162109375 221.6698913574219 385.6495971679688 220.2820892333984 L 394.1637573242188 201.1086120605469 C 394.9322509765625 199.3779907226562 394.6779174804688 197.3639373779297 393.5032348632812 195.8787384033203 C 392.3285217285156 194.3935241699219 390.4270935058594 193.6820373535156 388.5661010742188 194.0311737060547 C 386.7049560546875 194.3803405761719 385.1907958984375 195.7325744628906 384.6343383789062 197.5425415039062 L 378.468994140625 217.5953369140625 C 378.1110229492188 218.7593078613281 378.326416015625 220.0240631103516 379.0494384765625 221.0039367675781 C 379.7725219726562 221.9838256835938 380.91748046875 222.5626678466797 382.13525390625 222.5639953613281 M 382.13525390625 225.5639953613281 L 382.1319885253906 225.5639953613281 C 379.9725036621094 225.5616302490234 377.9177551269531 224.5228424072266 376.6355285644531 222.7852325439453 C 375.3532409667969 221.0474090576172 374.9667358398438 218.7776336669922 375.6015319824219 216.7134704589844 L 381.7668151855469 196.6609039306641 C 382.6535949707031 193.7765350341797 385.0469665527344 191.6390533447266 388.012939453125 191.0826110839844 C 388.5044250488281 190.9904022216797 389.0068664550781 190.9436492919922 389.5062561035156 190.9436492919922 C 390.7138671875 190.9436492919922 391.9278564453125 191.2206573486328 393.0170288085938 191.7447357177734 C 394.1169738769531 192.2740173339844 395.0987548828125 193.0599975585938 395.8562316894531 194.0177001953125 C 397.7282104492188 196.384521484375 398.1302795410156 199.5681304931641 396.9055786132812 202.3261413574219 L 388.3914184570312 221.4996185302734 C 387.29150390625 223.9697418212891 384.8363037109375 225.5639953613281 382.13525390625 225.5639953613281 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
