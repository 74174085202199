import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonSelector } from "../store/slices/CommonSlice";
import React from "react";
import {
  BusinessUnitSelector,
  setBusinessUnits,
  setCurrentBusinessUnit,
  setEdit,
  setIsEditParametrization,
  setIsInherit,
  setIsSuccess,
  setShowModal,
} from "../store/slices/BusinessUnitSlice";
import { CommonListSelector } from "../store/slices/CommonListsSlice";
import {
  businessUnitAudateAC,
  createBusinessUnitAC,
  getBusinessUnitsAC,
} from "../store/action-creators/BusinessUnitActionCreators";
import {
  IBusinessUnit,
  ICreateBusinessUnit,
} from "../store/models/BusinessUnit";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import {
  validationCode,
  validationOnlyLetters,
  numberCellphone,
  email,
  validationAddress,
} from "../utils/general/masks";

const useBusinessUnitBasicData = ({
  reset,
  setValue,
}: {
  reset: UseFormReset<IBusinessUnit>;
  setValue: UseFormSetValue<IBusinessUnit>;
}) => {
  // local state
  const [message, setMessage] = React.useState("");
  const [listCities, setListCities] = useState<any>([]);
  // REDUX STATE
  const dispatch = useDispatch();

  const { loading, error, success } = useSelector(CommonSelector);

  const {
    currentBusiness,
    isEdit,
    isNew,
    paginatorBussiness,
    isSuccess,
    showForm,
    showModal,
  } = useSelector(BusinessUnitSelector);

  const {
    businessUnits: businessUnitsListCommon,
    statesAndCities,
    businessUnitsEdited,
  } = useSelector(CommonListSelector);

  /**
   * Charge data businessUnit on store
   */
  const listBusinessUnitCommon = useCallback(() => {
    if (error) {
      setMessage(error);
    } else if (isSuccess !== "") {
      if (currentBusiness?.city) {
        const departmentSel = statesAndCities.find((item) => {
          return item.id === currentBusiness?.department;
        });
        setListCities(departmentSel?.city);
        if (isNew) {
          // dispatch(
          //   getParametrizationAC("", currentBusiness.id, currentBusiness.id, 0)
          // );
          dispatch(setIsEditParametrization(true));
          dispatch(setIsInherit(true));
          document.getElementById("simple-tab-1")?.click();
        }
      }
      reset();
    }
  }, []);

  /**
   * Submit form to server, set data on store REDUX and show response message
   * @function Submit
   * @param {any} data  object to submit
   */
  const submitForm = (data: any) => {
    const businessUnit: ICreateBusinessUnit = {
      ...data,
      inheritSetting:
        !data.isDependent || data.businessUnit != null ? true : false,
      businessUnit: !data.isDependent ? null : data.businessUnit,
      company: "c6937b97-8a44-454e-84c4-71a9a6d13455",
    };
    if (
      businessUnit.code.trim().length === 0 ||
      businessUnit.name.trim().length === 0 ||
      businessUnit.phone.trim().length === 0 ||
      businessUnit.email.trim().length === 0 ||
      businessUnit.address.trim().length === 0
    ) {
      dispatch(
        setIsSuccess(
          "Validar los campos Código, Nombre, Teléfono, Email y Dirección, ninguno pueden estar vacios."
        )
      );
    } else {
      const businessUnitUpdate: IBusinessUnit = {
        ...currentBusiness,
        code: businessUnit.code,
        businessUnit:
          businessUnit?.businessUnit === undefined
            ? 0
            : businessUnit?.businessUnit,
        name: businessUnit.name,
        phone: businessUnit.phone,
        email: businessUnit.email,
        address: businessUnit.address,
        department: businessUnit.department,
        city: businessUnit.city,
        company: businessUnit.company,
        inheritSetting: businessUnit.inheritSetting,
      };
      //se lanza currentBusinessUnit para evitar que la informacion de el formulario se pierda mientras se crea la sede, despues de creada se setea nuevamente
      dispatch(setCurrentBusinessUnit(businessUnitUpdate));
      if (isNew) {
        dispatch(createBusinessUnitAC(businessUnit, paginatorBussiness));
        dispatch(setIsSuccess(""));
        dispatch(setBusinessUnits([]));
        dispatch(getBusinessUnitsAC(paginatorBussiness, "", false));
        setMessage("");
      } else {
        dispatch(setIsSuccess(""));
        setMessage("");
        dispatch(
          businessUnitAudateAC(businessUnitUpdate, businessUnitsEdited + 1)
        );
      }
      dispatch(setEdit(false));
    }
  };

  /**
   * set value formated to fields in form
   * @function
   * @param {any} event data to format
   */
  const handleForm = (event: any) => {
    switch (event.target.name) {
      case "code":
        setValue("code", validationCode(event.target.value));
        break;
      case "name":
        setValue("name", validationOnlyLetters(event.target.value));
        break;
      case "phone":
        setValue("phone", numberCellphone(event.target.value));
        break;
      case "email":
        setValue("email", email(event.target.value));
        break;
      case "address":
        setValue("address", validationAddress(event.target.value));
        break;
      default:
        break;
    }
  };

  /**
   * Switch state of modal
   * @function
   * @param {any} event data to format
   */
  const handleCloseModal = () => {
    dispatch(setShowModal(false));
    dispatch(getBusinessUnitsAC(paginatorBussiness));
  };

  // react hooks
  useEffect(() => {
    listBusinessUnitCommon();
  }, [error, isSuccess]);

  return {
    loading,
    message,
    listCities,
    submitForm,
    handleForm,
    showForm,
    isSuccess,
    handleCloseModal,
    businessUnitsListCommon,
    statesAndCities,
    currentBusiness,
    isEdit,
    isNew,
    paginatorBussiness,
    showModal,
  };
};

export default useBusinessUnitBasicData;
