import React from "react";
import { Box, CardContent, Typography } from "@material-ui/core";
import CaptchaGenerator from "../../components/CaptchaGenerator/CaptchaGenerator";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { GetBase64PDFATDPAC } from "../../store/action-creators/CreateAtdpActionCreators";
import { AtdpSelector } from "../../store/slices/AtdpSlice";
import ErrorIcon from "../../assets/errorLogo.png";

interface FormDocumentValidationProps {
  childrenStatus?: Node;
  status: boolean;
  messageStatus?: string;
}

const FormDocumentValidation = ({
  childrenStatus,
  status,
  messageStatus,
}: FormDocumentValidationProps) => {
  const { dowloandPdfBase64Atdp, objectCurrentAtdp } =
    useSelector(AtdpSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pdfNameAtdp, setPdfNameAtdp] = React.useState("");
  const [isValidated, setIsValidated] = React.useState(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  const MessageError = () => {
    return (
      <Typography className={styles.massageErrorAlert}>
        {messageStatus}
      </Typography>
    );
  };

  const handleSetIsValidated = (status: boolean) => {
    if (status === true && objectCurrentAtdp !== undefined) {
      dispatch(
        GetBase64PDFATDPAC(
          {
            Location: objectCurrentAtdp.locacion,
            Name: objectCurrentAtdp.nombre,
            Repository: objectCurrentAtdp.repositorio,
          },
          objectCurrentAtdp.nombre,
          true
        )
      );
      setPdfNameAtdp(objectCurrentAtdp.nombre);
      setIsValidated(status);
    }
  };

  React.useEffect(() => {
    if (dowloandPdfBase64Atdp !== undefined && isValidated) {
      const link = `data:application/pdf;base64,${dowloandPdfBase64Atdp[0].base64}`;
      const downloadLink = document.createElement("a");
      const fileName = dowloandPdfBase64Atdp[0].atdpName;
      downloadLink.href = link;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dowloandPdfBase64Atdp]);

  return (
    <div className={styles.containerCaptcha}>
      <div className={styles.captchaCard}>
        <CardContent style={{ alignItems: "center" }}>
          {status === true ? (
            <CaptchaGenerator
              isValidated={handleSetIsValidated}
              captchaLenght={6}
              textButton="Ver Documento"
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <img
                src={ErrorIcon}
                style={{
                  width: "80px",
                  height: "80px",
                  alignSelf: "center",
                  margin: "5px",
                }}
                alt="ReloadIcon"
              />
              <MessageError />
            </Box>
          )}
        </CardContent>
      </div>
    </div>
  );
};

export default FormDocumentValidation;
