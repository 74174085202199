import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { authSelector } from "../store/slices/AuthSlice";
import { BusinessUnitView } from "../views/BusinessUnits/BusinessUnitView";
import CompanyView from "../views/CompanyView";
import CompanyViewList from "../views/company/CompanyView";

import ComparisionView from "../views/Comparison/ComparisionView";
import ComputerEquipmentView from "../views/ComputerEquipment/ComputerEquipmentView";
import DashboardView from "../views/Dashboard/DashboardView";
import DeviceView from "../views/Devices/DeviceView";
import UsersView from "../views/Users/UsersView";
import FingerprintView from "../views/Fingerprint/FingerprintView";
import SearchAtdpView from "../views/SearchAtdp/SearchAtdpView";
import AdminUserView from "../views/AdminUser/AdminUserView";
import Home from "../views/Home";
import NotFoundView from "../views/NotFoundView";
import { TypeProcessView } from "../views/TypeProcess/TypeProcessView";
import PrivateRoute from "./PrivateRoute";
import DocumentValidationView from "../views/DocumentValidation";
import AuditLogView from "../views/AuditLog/AuditLogView";
import FNAReport from "../views/FNAReport";

const Routes: React.FC = React.memo(() => {
  const { tokens } = useSelector(authSelector);
  const token = tokens?.result.token ? tokens?.result.token : "";
  const dataCompany = sessionStorage.getItem("nitCompany") || "";
  const nitCompany = dataCompany.split(",");

  return (
    <Router>
      <Switch>
        <PrivateRoute
          path="/company"
          Component={() => (
            <CompanyView token={token} nitCompany={nitCompany[1]} />
          )}
          exact
        />
        <PrivateRoute
          path="/companylist"
          Component={() => (
            <CompanyViewList token={token} nitCompany={nitCompany[1]} />
          )}
          exact
          menuHide={true}
        />
        <PrivateRoute
          path="/procedure"
          Component={() => {
            return <TypeProcessView nitCompany={nitCompany[1]} />;
          }}
          exact
        />
        <PrivateRoute
          path="/devices"
          Component={() => {
            return <DeviceView nitCompany={nitCompany[1]} />;
          }}
          exact
        />
        <PrivateRoute
          path="/users"
          Component={() => {
            return <UsersView nitCompany={nitCompany[1]} />;
          }}
          exact
        />
        <PrivateRoute
          path="/computers"
          Component={() => {
            return <ComputerEquipmentView nitCompany={nitCompany[1]} />;
          }}
          exact
        />
        <PrivateRoute
          path="/businessUnit"
          Component={() => {
            return <BusinessUnitView nitCompany={nitCompany[1]} />;
          }}
          exact
        />
        <PrivateRoute
          path="/comparison"
          Component={() => <ComparisionView nitCompany={nitCompany[1]} />}
          exact
        />

        <PrivateRoute
          path="/fingerprint"
          Component={() => (
            <FingerprintView token={token} nitCompany={nitCompany[1]} />
          )}
          exact
        />
        <PrivateRoute
          path="/dashboard"
          Component={() => (
            <DashboardView token={token} nitCompany={nitCompany[1]} />
          )}
          exact
        />

        <PrivateRoute
          path="/searchatdp"
          Component={() => <SearchAtdpView nitCompany={nitCompany[1]} />}
          exact
        />

        <PrivateRoute
          path="/adminuser"
          Component={() => (
            <AdminUserView token={token} nitCompany={nitCompany[1]} />
          )}
          exact
        />
        <PrivateRoute
          path="/auditlog"
          Component={() => <AuditLogView nitCompany={nitCompany[1]} />}
          exact
        />

        <PrivateRoute
          path="/fnaReport"
          Component={() => <FNAReport token={token} nitCompany={nitCompany[1]}/>}
          exact
        />

        <PrivateRoute path="/" Component={() => <Home />} exact />

        <PrivateRoute
          path="/notFound"
          Component={(e: any) => {
            return <NotFoundView redirectTo={e.redirectTo} />;
          }}
          exact
        />
        <Route
          path="/documentValidation/:code"
          render={({ match }) => {
            const { code } = match.params;
            if (!code) {
              return <Redirect to="/" />;
            }
            return (
              <DocumentValidationView
                qrcode={code}
                nitCompany={nitCompany[1]}
              />
            );
          }}
        />
        <Route path="*">
          <NotFoundView redirectTo={"/"} />
        </Route>
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
});

export default Routes;
