import moment from "moment";
import { Column } from "../../../components/Table/types";

/**
 * @description Array de objetos que contiene las columnas para los registros del DataTable
 * @returns { Column[] } Column[]
 */
const columnsAuditLog = () => {
  const columns: Column[] = [
    {
      id: "comparisonId",
      label: "Comparison",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "nut",
      label: "NUT",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "operationDate",
      label: "Fecha creación",
      minWidth: 70,
      align: "center",
      haveDropDown: true,
      format: (value: string) => moment(value).format("DD/MM/YYYY, H:mm:ss"),
    },
  ];
  return columns;
};

export default columnsAuditLog;
