import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceMassiveResponse } from "../models/DeviceUnit";
import {
  Device,
  FieldsAdvanceSearch,
  IAudit,
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
} from "../types";
import { RootState } from "./rootReducer";

type DeviceState = {
  currentDevice: Device | undefined;
  devices: Device[];
  showForm: boolean;
  isDeviceEdit: boolean;
  diviceAudit: IAudit | undefined;
  deviceMassiveResponse: DeviceMassiveResponse | undefined;
  paginatorDevice: PaginatorWithFilterAndSort;
  size: number;
  advanceSearch: FieldsAdvanceSearch[];
  loadingDevice: boolean
};

const initialState: DeviceState = {
  currentDevice: undefined,
  devices: [],
  showForm: false,
  isDeviceEdit: false,
  diviceAudit: undefined,
  deviceMassiveResponse: undefined,
  paginatorDevice: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    sort: "recent",
  },
  size: 0,
  advanceSearch: [],
  loadingDevice: false,
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setCurrentDevice: (
      state,
      { payload }: PayloadAction<Device | undefined>
    ) => {
      state.currentDevice = payload;
      if (payload) {
        state.isDeviceEdit = false;
      } else {
        state.isDeviceEdit = true;
      }
    },
    setDevices: (state, { payload }: PayloadAction<Device[]>) => {
      state.devices = payload;
    },
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setIsDeviceEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeviceEdit = payload;
    },
    auditDivice: (state, diviceAudit: PayloadAction<IAudit>) => {
      state.diviceAudit = diviceAudit.payload;
    },
    setDeviceMassiveResponse: (
      state,
      { payload }: PayloadAction<DeviceMassiveResponse | undefined>
    ) => {
      state.deviceMassiveResponse = payload;
    },
    setPaginatorDevice: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorDevice.paginator = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setFilterDevice: (state, { payload }: PayloadAction<string>) => {
      state.paginatorDevice.filter = payload;
    },
    setSortDevice: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorDevice.sort = payload;
    },
    setUpdateStateDeviceById: (state, { payload }: PayloadAction<number>) => {
      return {
        ...state,
        devices: state.devices.map((item) => {
          if (item.id === payload) {
            return { ...item, state: !item.state };
          } else {
            return item;
          }
        }),
      };
    },
    setAdvanceSearch: (
      state,
      { payload }: PayloadAction<FieldsAdvanceSearch[]>
    ) => {
      state.advanceSearch = payload;
    },
    setLoadingDevice: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingDevice = payload;
    },
  },
});

export const {
  setCurrentDevice,
  setDevices,
  setShowForm,
  setIsDeviceEdit,
  auditDivice,
  setDeviceMassiveResponse,
  setPaginatorDevice,
  setSize,
  setFilterDevice,
  setSortDevice,
  setUpdateStateDeviceById,
  setAdvanceSearch,
  setLoadingDevice,
} = deviceSlice.actions;

export const DeviceSelector = (state: RootState) => state.device;

export default deviceSlice.reducer;
