import React from "react";
import { useForm } from "react-hook-form";
import { Theme } from "@material-ui/core";
import { Grid, Typography, Container, makeStyles } from "@material-ui/core";
import { InputRounded } from "../../components/Inputs/InputRounded";
import { SelectInputRounded } from "../../components/Inputs/SelectInputRounded";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MESSAGES } from "../../utils/types/const";
import { IDataFinger } from "../../store/models/FingerPrintModel";
import { FingerPrintSelector } from "../../store/slices/FingerPrintSlice";
import { useSelector } from "react-redux";

import {
  CC,
  email,
  numberCellphone,
  onlyLetters,
} from "../../utils/general/masks";
import { regex } from "../../utils/general/utils";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((_theme: Theme) => ({
  formParticipants: {
    boxShadow: "0px 3px 6px #00000033",
    border: "1px solid #009BDD",
    padding: "20px",
    borderRadius: "10px",
    margin: 0,
    marginBottom: "20px",
  },

  inputs: {
    marginBottom: "10px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
  },
  titleParticipant: {
    font: "normal normal bold 18px/23px Muli",
    color: "#009BDD",
    marginBottom: 20,
  },
}));

interface FingerFormDataProps {
  handleDataProp: Function;
  handleSubmitProp?: Function;
  handleValidateProp: Function;
  index: any;
  isOpen: boolean;
  documentTypeList?: any;
}

type SelectOption = {
  key: number;
  value: string | number;
  label: string;
};

interface IFingerPrintForm {
  nameuser: string;
  documentType: string;
  phone: string;
  surname: string;
  documentNumber: string;
  email: string;
}
const FingerPrintSchema = yup.object().shape({
  nameuser: yup
    .string()
    .max(100, `${MESSAGES.max} 100 caracteres`)
    .required(MESSAGES.required)
    .trim(),
  documentType: yup
    .string()
    .max(100, `${MESSAGES.max} 100 caracteres`)
    .required(MESSAGES.required)
    .trim(),
  phone: yup
    .string()
    .min(10, "El número debe tener 10 digitos")
    .typeError(`${MESSAGES.typeError} un número`)
    .matches(regex.Cellphone, `${MESSAGES.valueEntry} un número de celular`)
    .required(MESSAGES.required),
  surname: yup
    .string()
    .max(100, `${MESSAGES.max} 100 caracteres`)
    .required(MESSAGES.required)
    .trim(),
  documentNumber: yup
    .string()
    .min(5, `${MESSAGES.min} 5 digitos`)
    .required(MESSAGES.required),
  email: yup
    .string()
    .typeError(`${MESSAGES.typeError} un correo electrónico válido.`)
    .max(100, `${MESSAGES.max} 100 caracteres.`)
    .matches(regex.Email, `Ingrese un correo electrónico válido.`)
    .required(MESSAGES.required)
    .trim(),
});

const initialValues = {
  id: 0,
  nameuser: "",
  documentType: "",
  idDocumentType: "",
  phone: "",
  surname: "",
  documentNumber: "",
  email: "",
};

const FingerFormData = ({
  handleDataProp,
  index,
  handleValidateProp,
  isOpen,
}: FingerFormDataProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  //#region  states
  const {
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFingerPrintForm>({
    mode: "onChange",
    resolver: yupResolver(FingerPrintSchema),
    shouldUnregister: false,
  });

  const [dataList, setDataList] = React.useState<IDataFinger>({
    id: 0,
    nameuser: "",
    documentType: "",
    idDocumentType: "",
    phone: "",
    surname: "",
    documentNumber: "",
    email: "",
  });

  const { documentType } = useSelector(FingerPrintSelector);
  const [documentTypeState, setDocumentTypeState] = React.useState<
    Array<SelectOption>
  >([]);

  React.useEffect(() => {
    handleDataProp(dataList, validate, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  React.useEffect(() => {
    let initialValuesArray = [] as any;
    if (isOpen === false) {
      initialValuesArray = initialValues;
      sessionStorage.removeItem(index);
      reset(initialValuesArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    returnDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);

  const returnDocumentType = () => {
    if (documentTypeState.length === 0) {
      documentType?.map((itemDocument) => {
        if (itemDocument.idState === 1) {
          const newDocumentType: SelectOption = {
            key: itemDocument.idDocument,
            value: itemDocument.idDocument,
            label: itemDocument.name,
          };
          return setDocumentTypeState((prev) => [...prev, newDocumentType]);
        }
        return setDocumentTypeState((prev) => [...prev]);
      });
    }
  };

  const handleForm = (event: any) => {
    event.preventDefault();

    const setData = (mask: any) =>
      setDataList({
        ...dataList,
        id: index,
        [event.target.name]: mask(event.target.value),
      });

    switch (event.target.name) {
      case "nameuser":
        setValue("nameuser", onlyLetters(event.target.value));
        setData(onlyLetters);
        break;
      case "surname":
        setValue("surname", onlyLetters(event.target.value));
        setData(onlyLetters);
        break;
      case "phone":
        setValue("phone", numberCellphone(event.target.value));
        setData(numberCellphone);
        break;
      case "documentNumber":
        setValue("documentNumber", CC(event.target.value));
        setData(CC);
        break;
      case "email":
        setValue("email", email(event.target.value));
        setData(email);
        break;
      default:
        setDataList({
          ...dataList,
          id: index,
          [event.target.name]: event.target.value,
        });
        break;
    }
  };

  const validate = (id: any) => {
    const response = FingerPrintSchema.isValidSync(dataList);

    handleValidateProp({
      idParticipant: id,
      stateForm: response,
    });
  };

  const handleFormBlur = (event: any) => {
    event.preventDefault();
    if (event.target.name === "documentNumber") {
      let participants = Object.entries(sessionStorage)
        .filter((participant) => !isNaN(Number(participant[0])))
        .filter((participant) => participant[0] !== String(index))
        .map((participant) => JSON.parse(participant[1]))
        .map((participant) => participant.documentNumber);
      const existNumber = participants.some(
        (data) => data === event.target.value
      );
      if (existNumber) {
        enqueueSnackbar("Ya se ingresó este número de documento", {
          variant: "error",
        });
        setValue("documentNumber", "");
        setDataList({
          ...dataList,
          id: index,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  return (
    <>
      {isOpen ? (
        <form onChange={handleForm} onBlur={handleFormBlur}>
          <Container className={classes.formParticipants}>
            <Typography className={classes.titleParticipant}>
              {`Participante ${index}`}
            </Typography>
            <Grid container item spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    defaultValue={initialValues.nameuser}
                    name="nameuser"
                    label="Nombre"
                    props={{
                      name: "nameuser",
                      helperText: errors.nameuser?.message,
                      error: errors.nameuser ? true : false,
                      disabled: false,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    defaultValue={initialValues.surname}
                    name="surname"
                    label="Apellidos"
                    props={{
                      name: "surname",
                      helperText: errors.surname?.message,
                      error: errors.surname ? true : false,
                      disabled: false,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <SelectInputRounded
                    control={control}
                    props={{
                      helperText: errors.documentType?.message,
                      error: errors.documentType ? true : false,
                      disabled: false,
                      autoComplete: "off",
                    }}
                    onChange={(e: any) => {
                      setDataList({
                        ...dataList,
                        documentType: e,
                      });
                    }}
                    name="documentType"
                    label={"Tipo de documento"}
                    options={documentTypeState}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    defaultValue={initialValues.documentNumber}
                    name="documentNumber"
                    label="Número de documento"
                    props={{
                      name: "documentNumber",
                      helperText: errors.documentNumber?.message,
                      error: errors.documentNumber ? true : false,
                      disabled: false,
                      autoComplete: "off",
                    }}
                    control={control}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    defaultValue={initialValues.phone}
                    name="phone"
                    label="Celular"
                    props={{
                      name: "phone",
                      helperText: errors.phone?.message,
                      error: errors.phone ? true : false,
                      disabled: false,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputs}>
                  <InputRounded
                    defaultValue={initialValues.email}
                    name="email"
                    label="Correo electrónico"
                    props={{
                      name: "email",
                      helperText: errors.email?.message,
                      error: errors.email ? true : false,
                      disabled: false,
                    }}
                    control={control}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </form>
      ) : (
        <></>
      )}
    </>
  );
};
export default FingerFormData;
