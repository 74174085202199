import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { PrimaryButton } from "../button/PrimaryButton";

interface LoadModalProps {
  open: boolean;
  handleClose: Function;
  icon: JSX.Element;
  title: string;
  message: string;
  handleConfirm: any;
  handleCancel: any;
  isConfirm: boolean;
  confirmText: string;
  cancelText: string;
  isCancel: boolean;
}

/**
 * 
 * @param { boolean } prop.open Parametro de tipo boolean que representa el estado de apertura del modal
 * @param { Function } prop.handleClose Parametro de tipo Function que representa que retorna al componente padre la funcion de cierre de modal
 * @param { JSX } prop.icon Parametro de tipo JSX que representa el icono
 * @param { string } prop.title Parametro de tipo string que representa el titulo de el modal
 * @param { string } prop.message Parametro de tipo string que representa el mensaje principal
 * @param { any } prop.handleConfirm Parametro de tipo any que representa la accion de confirmar la carga
 * @param { any } prop.handleCancel Parametro de tipo any que representa la accion de cancelar la carga
 * @param { boolean } prop.isConfirm Parametro de tipo boolean que representa el estado de la carga
 * @param { string } prop.confirmText Parametro de tipo string que representa el texto del botón de confirmar la carga
 * @param { string } prop.cancelText Parametro de tipo string que representa el texto del botón de cancelar la carga
 * @param { boolean } prop.isCancel Parametro de tipo boolean que representa el estado de la cancelacion de la carga
 * @returns { React.FC<LoadModalProps> } LoadModalProps
 */
export const LoadModal: React.FC<LoadModalProps> = ({
  open,
  handleClose,
  icon,
  title,
  message,
  handleConfirm,
  handleCancel,
  isConfirm,
  confirmText,
  cancelText,
  isCancel,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="custom-dialog"
    >
      <Grid container direction="row-reverse">
        <Grid item lg={1} sm={1} xs={1} md={1}>
          <Close
            color="primary"
            onClick={() => handleClose()}
            style={{ cursor: "pointer", height: "36px", paddingLeft: "20px" }}
          />
        </Grid>
      </Grid>
      {isConfirm && isCancel ? (
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item>
              <PrimaryButton
                title={confirmText}
                props={{
                  onClick: handleConfirm,
                  style: { width: "100px", textTransform: "capitalize" },
                }}
              ></PrimaryButton>
            </Grid>
            <Grid item>
              <PrimaryButton
                title={cancelText}
                props={{
                  onClick: handleCancel,
                  style: { width: "100px", textTransform: "capitalize" },
                }}
              ></PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : isConfirm && !isCancel ? (
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item>
              <PrimaryButton
                title={confirmText}
                props={{
                  onClick: handleConfirm,
                  style: { width: "100px", textTransform: "capitalize" },
                }}
              ></PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography className={classes.titleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.icon}
            >
              {icon}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              justifyContent="center"
              className={classes.message}
            >
              <Typography
                component="h1"
                color="textPrimary"
                className={classes.messageTypography}
              >
                {message}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: any) => ({
  icon: {
    height: 80,
    width: 92,
    margin: 10,
  },
  dialogTitle: {
    height: 12,
  },
  message: {
    marginBottom: 15,
    marginTop: -11,
  },
  messageTypography: {
    textAlign: "center",
    width: "50%",
    marginTop: 30,
    fontWeight: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  titleTypography: {
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: 0,
  },
}));
