import moment from "moment";
import axios, { AxiosResponse } from "axios";
import "moment/locale/es";
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { defaultHeaders, TIME_REFRESH_TOKEN } from "../config/config";
import {
  API_AUTHENTICATION_ENDPOINT,
  BIOMETRICS_LOGIN,
  API_SECURITY_ENDPOINT,
  API_APPLICATION_UPDATER,
  API_SOCKET_DESKTOP_UPDATER,
} from "../config/const";
import { StandardResponse } from "../type";
import { IValidateVersion } from "../store/models/IApplicationUpdater";

export const urlAuthentication = `${API_AUTHENTICATION_ENDPOINT}/authentication`;

export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

/**
 * Redireccionar a login
 *
 * @method
 * @param
 */
export function redirectToLogin() {
  setAuthToken(false);
  window.location.href = BIOMETRICS_LOGIN;
}

export const validateTokenExpirationDate = (isPublic: Boolean = false) =>
  validateConcurrentSession(isPublic).then(
    (sessionValidation) =>
      sessionValidation &&
      new Promise<Boolean>((resolve, reject) => {
        try {
          if (!!isPublic) resolve(true);
          else {
            const jwtToken = sessionStorage.getItem("jwtToken");
            const refreshToken = sessionStorage.getItem("refreshToken");
            const decodedToken: any = jwt_decode(jwtToken || "");
            const currentDate = moment({});
            const dateExpiresToken = moment.unix(Number(decodedToken.exp));

            if (moment(dateExpiresToken).isAfter(currentDate) === false) {
              resolve(false);
              redirectToLogin();
            } else if (
              moment(dateExpiresToken).diff(currentDate, "seconds") <
              TIME_REFRESH_TOKEN
            ) {
              let userName = decodedToken.unique_name;
              let refreshData = {
                RefreshToken: refreshToken,
                UserName: userName,
                AppName: "BIOMETRIA",
              };
              try {
                instance
                  .post(
                    urlAuthentication + "/api/Login/RefreshToken",
                    refreshData,
                    defaultHeaders()
                  )
                  .then((response) => {
                    if (response.data.result.informationCode === "A10") {
                      sessionStorage.setItem(
                        "jwtToken",
                        response.data.result.token
                      );
                      sessionStorage.setItem(
                        "refreshToken",
                        response.data.result.refreshToken
                      );
                      resolve(true);
                    }
                    resolve(true);
                  })
                  .catch((err) => {
                    redirectToLogin();
                    resolve(false);
                  });
              } catch (err) {
                redirectToLogin();
                resolve(false);
              }
            }
            resolve(true);
          }
        } catch (err) {
          reject(false);
          redirectToLogin();
          console.warn(
            "Error al validar la fecha de vencimiento del token:",
            err
          );
        }
      })
  );

export const validateConcurrentSession = (isPublic: Boolean) =>
  new Promise<Boolean>((resolve, reject) => {
    try {
      if (!!isPublic) resolve(true);
      else {
        try {
          instance
            .post(
              API_SECURITY_ENDPOINT + "/user/validateSession",
              {},
              defaultHeaders()
            )
            .then((response) => {
              if (response.data.result.informationCode === "A10") {
                resolve(true);
              } else {
                redirectToLogin();
                resolve(true);
              }
            })
            .catch((err) => {
              resolve(false);
              redirectToLogin();
            });
        } catch (err) {
          redirectToLogin();
          console.warn("Error sesiones concurrentes:", err);
          resolve(false);
        }
      }
    } catch (err) {
      reject(true);
      redirectToLogin();
      console.warn("Error sesiones concurrentes:", err);
    }
  });

/**
 * Servicio que se lanza despues de que el usuario se loguea y consulta al servicio de
 * ValidateVersion para obtener informacion de si está pendiente una actualizacion del software de BiometricDesktop
 * @returns StandardResponse<IValidateVersion>
 */
export const validateVersion = async (): Promise<StandardResponse<any>> => {
  try {
    const response: AxiosResponse<StandardResponse<any>> = await instance.post(
      `${API_APPLICATION_UPDATER}/Updater/ValidateVersion`,
      { ApplicationName: "BiometricDesktop" },
      defaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Servicio que conecta con el Socket para lanzar la solicitud de actualizacion de software
 * @param payload IValidateVersion
 * @returns boolean
 */
export const NotifyActualizationSocket = async (
  payload: IValidateVersion
): Promise<boolean> => {
  try {
    let socket = new WebSocket(`${API_SOCKET_DESKTOP_UPDATER}/ValidateVersion`);

    socket.onopen = function (e) {
      socket.send(JSON.stringify(payload));
      console.log("🚀 ~ WebSocket socket:", e);
    };
    socket.onerror = function (e) {
      console.log("WebSocket ", e);
    };
    socket.onmessage = function (e) {
      console.log("WebSocket response ", e);
    };
    return true;
  } catch (error) {
    throw error;
  }
};
