import {
  getAllUsers,
  updateUserState,
  getAllUsersExcelService,
  advanceSearch,
  advanceSearchCsv,
} from "../../services/UsersService";
import { ResultResponse, StandardResponse } from "../../type";
import { UpdateState } from "../models/UpdateState";
import {
  setError,
  setHashCsv,
  setHashCsvModal,
  setLoading,
  setSuccess,
} from "../slices/CommonSlice";
import {
  setUser,
  setUsersExcel,
  setPaginatorUser,
  setSize,
  setFilterUser,
  setSortUser,
  setUpdateStateUserById,
} from "../slices/UserSlice";
import { AppThunk } from "../store";
import { PaginatorWithFilterAndSort, sortBy, AdvanceSearch } from "../types";

/**
 * Guarda la paginación
 * @param { number } page - numero de pagina
 * @param { number } itemsPage - limite de datos mostrados
 * @param { boolean } recharge - validación se recarga la paginación
 */
export const getPaginatorAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorUser({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };
/**
 * Guarde el texto por el que se quiere filtrar
 * @param { string } filter - texto a filtrar en la busqueda
 */
export const getFilterAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterUser(filter));
  };
/**
 * Guarda el ordenamiento 
 * @param { sortBy } sort - Tipo de ordenamiento
 */
export const getSortAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortUser(sort));
  };
/**
 * Obtiene y guarda los usuarios
 * @param { PaginatorWithFilterAndSort } paginator - informacion de la paginación el filtro y el ordenamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 */
export const getUsersAC =
  (
    paginator: PaginatorWithFilterAndSort,
    company?: string,
    businessUnit?: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    getAllUsers(paginator, company, businessUnit)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setUser(response.result.records));
          dispatch(setSize(response.result.count));
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => dispatch(setLoading(false)));
  };
/**
 * Obtiene y guarda los usuarios
 * @param { UpdateState } body - información del estado del usuario
 */
export const updateUserStateAC =
  (body: UpdateState): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    updateUserState(body)
      .then((response: StandardResponse<ResultResponse>) => {
        dispatch(setSuccess(response.result.information));
      })
      .catch((err) => {
        dispatch(setError("Ha ocurrido un error"));
      })
      .finally(() => dispatch(setLoading(false)));
  };

/**
 * Actualiza el estado del usuario mediante su ID
 * @param { number } body - id del usuario
 */
export const updateStateUserByIdAC =
  (body: number): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setUpdateStateUserById(body));
  };
/**
 * Obtiene los usuarios formateados para descargar en el excel
 */
export const getUsersExcelAC = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  getAllUsersExcelService()
    .then((response) => {
      if (response.statusCode === 200) {
        dispatch(setUsersExcel(response.result.records));
      }
    })
    .catch((error) => {
      dispatch(setError(error));
    })
    .finally(() => dispatch(setLoading(false)));
};
/**
 * Realiza la búsqueda avanzada y actualiza los dispositivos
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const advanceSearchAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    advanceSearch(body)
      .then((data) => {
        const { records } = data.result;
        dispatch(setUser(records));
        dispatch(setSize(data.result.count));
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information));
      })
      .finally(() => dispatch(setLoading(false)));
  };
/**
 * Realiza la búsqueda avanzada y descarga archivo csv con el resultado
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const advanceSearchCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true));
    advanceSearchCsv(body)
      .then((response) => {
        if (response.statusCode === 200) {
          const mimeType = "data:application/zip;base64,";
          const linkSource = `${mimeType}${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName = response.result.nameFile + ".zip";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          dispatch(setHashCsv(response.result.hash));
          dispatch(setHashCsvModal(true));
        }
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information));
      })
      .finally(() => dispatch(setLoading(false)));
  };
