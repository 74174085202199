import { CustomTypography } from "@dg-bucaramanga/react-components-dg-qa";
import { Grid } from "@material-ui/core";
import { NoDeviceIcon } from "../../../components/Icons/NoDeviceIcon";

/**
 * Retorna componente con mensaje cuando no encuentra o no tiene dispositivos
 * @returns { JSX.Element } - Mensaje cuando no hay dispositivos
 */
const notDataFoundUsers = () => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoDeviceIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography type="definition" children={"Aún no hay usuarios"} />
      </Grid>
    </Grid>
  );
};

export default notDataFoundUsers;
