import React from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useHistory } from "react-router-dom";

const NotFoundView: React.FC<{ redirectTo?: string }> = ({ redirectTo }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.container}>
      <Grid container item direction="row" className={classes.main}>
        <Grid
          container
          item
          lg
          md
          justifyContent="center"
          className={classes.message}
        >
          <SentimentVeryDissatisfiedIcon className={classes.icon} />
        </Grid>
        <Grid
          container
          item
          lg
          md
          direction="row"
          justifyContent="center"
          className={classes.message}
        >
          <Typography className={classes.titleTypography}>404</Typography>
          <Typography className={classes.messageTypography}>
            PÁGINA NO ENCONTRADA
          </Typography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          justifyContent="center"
          className={classes.message}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.rootButton}
            onClick={() => history.push(redirectTo || "/dashboard")}
          >
            IR A LA PÁGINA DE INICIO{" "}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  main: {
    height: "100%",
  },
  rootButton: {
    alignSelf: "center",
    height: "50%",
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 1,
    background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  container: {
    position: "absolute",
    padding: "20px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "40%",
    height: "60%",
    borderRadius: "20px",
    backgroundColor: theme.palette.common.white,
  },
  titleTypography: {
    width: "100%",
    textAlign: "center",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(70),
    letterSpacing: 2,
  },
  messageTypography: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
  },
  message: {
    maxHeight: "30%",
    minWidth: "100%",
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "150px",
  },
}));

export default NotFoundView;
