import React, { useEffect } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { SecondaryButton } from "../button/SecondaryButton";
import Fingerprint from "@material-ui/icons/Fingerprint";
import { IFingerUnit } from "../../store/models/FingerPrintModel";
import {
  IGeneralWebsocketResponse,
  ISpecificWebsocketResponse,
  responseSocket,
} from "../../store/models/FingerPrintModel";
import FullLoader from "../../components/Loader/FullLoader.component";
import {
  FingerPrintSelector,
  setResponseSocket,
  setLoadingFingerPrint,
} from "../../store/slices/FingerPrintSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Slide, SlideProps } from "@material-ui/core";
import fingerImage from "../../assets/finger.png";
import useFingerWebSocket from "../../hooks/useFingerWebSocket";

const useStyles = makeStyles((_theme: Theme) => ({
  title: {
    font: "normal normal bold 18px/23px Muli",
    color: "#FA5A00",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    marginTop: 31,
  },
  boxCapture: {
    width: "auto",
    height: "auto",
    maxHeight: "40vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    boxShadow: "0px 3px 6px #00000033",
    border: "1px solid #B2B2B2",
    borderRadius: "10px",
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    overflow: "hidden",
    [_theme.breakpoints.up("md")]: {
      maxWidth: "40vw",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
    gap: "1em",
  },
  fingerIcon: {
    fontSize: "100px",
    padding: "99px 0",
    color: "#00000008",
  },
  captureBtnContainer: {
    width: "185px",
    height: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  iconButton: {
    fontSize: "25px !important",
  },
  fingerCaptureContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      filter: "blur(4px)",
      width: "100%",
      [_theme.breakpoints.down("md")]: {
        width: "80%",
      },
    },
    position: "relative",
  },
  sensitiveData: {
    color: "#FFE200",
    zIndex: 1,
    position: "absolute",
    transform: "matrix(0.96, -0.29, 0.29, 0.96, 0, 0)",
    textShadow: "0px 2px 2px #00000080",
    fontSize: "22px",
    fontWeight: 500,
  },
}));

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  isClose: any;
  finger: any | null;
  hand: any | null;
  arrayFinger?: IFingerUnit[];
  handleAcceptButton?: Function;
  clearTimer?: Function;
  keyProp?: number;
  value: any;
  changeTab?: any;
}

const CaptureDialog = ({
  isOpen,
  isClose,
  finger,
  hand,
  arrayFinger,
  handleAcceptButton,
  keyProp,
  value,
  clearTimer,
}: Props) => {
  const classes = useStyles();
  // local states
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const [capture, setCapture] =
    React.useState<ISpecificWebsocketResponse | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [nextFinger, setNextFinger] = React.useState<boolean>(true);
  // REDUX
  const dispatch = useDispatch();
  const { responseSocket, fingerDevice, loadingFingerPrint } =
    useSelector(FingerPrintSelector);

  // react hooks

  const {
    sendRequestSocket,
    disconnect,
    connectToFingerWebSocket,
    returnResponseSend,
    returnResponseConnect,
  } = useFingerWebSocket(fingerDevice);

  // custom functions
  // handle accept button on capture modal
  const onConfirmClick = () => {
    setCapture(null);
    setNextFinger(true);
    if (arrayFinger) {
      if (keyProp !== undefined) {
        const item = arrayFinger[keyProp + 1]
          ? arrayFinger[keyProp + 1].value
          : null;
        if (handleAcceptButton) {
          handleAcceptButton(item);
        }
      }
    }
    disconnect(); //try disconnect socket
  };

  // prepare device to capture mode
  const handleCaptureFinger = () => {
    if (fingerDevice) {
      dispatch(setLoadingFingerPrint(true));
      connectToFingerWebSocket(
        onResponseConnect,
        onResponseSocket,
        enqueueSnackbar
      );
    }
  };

  // response device connection
  const onResponseConnect = (value: Boolean) => {
    if (value) {
      const connected = returnResponseConnect();
      if (connected === "CONNECTED") {
        if (fingerDevice) {
          sendRequestSocket(fingerDevice.result);
        }
      }
    }
  };

  // response by socket finger data
  const onResponseSocket = (value: Boolean) => {
    let response: IGeneralWebsocketResponse = returnResponseSend();
    if (value && response !== undefined) {
      if (!response.responseCode) {
        getResponseSocket(response);
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
      }
    }
    disconnect();
    dispatch(setLoadingFingerPrint(false));
  };

  // comparisson finger data and validate user fingerprint
  const getResponseSocket = (response: IGeneralWebsocketResponse) => {
    if (response.responsePrintFinger !== undefined) {
      let captureFinger: ISpecificWebsocketResponse =
        response.responsePrintFinger;
      if (captureFinger.imageBase64 !== null) {
        setCapture(captureFinger);
        let verify = responseSocket.some((item) => item.id === value);
        if (verify) {
          let index = responseSocket.findIndex((item) => item.id === value);
          let element: responseSocket[] = [...responseSocket];
          element[index] = { ...element[index], response: captureFinger };
          dispatch(setResponseSocket(element));
        } else {
          dispatch(
            setResponseSocket([
              ...responseSocket,
              { id: value, response: captureFinger },
            ])
          );
        }
        enqueueSnackbar("Captura de huella éxitosa", {
          variant: "success",
        });
        setNextFinger(false);
      } else {
        enqueueSnackbar("No se pudo capturar la huella", {
          variant: "error",
        });
        dispatch(setLoadingFingerPrint(false));
        setNextFinger(true);
      }
      disconnect(); //try disconnect socket after get response
    }
    dispatch(setLoadingFingerPrint(false));
  };

  useEffect(() => {
    if (!value) setCapture(null);
    return () => {
      !!clearTimer && clearTimer();
      dispatch(setLoadingFingerPrint(false));
    };
  }, [value]);

  // cancel capture modal and reset variables
  const handleCancel = () => {
    isClose();
    setCapture(null);
    setNextFinger(true);
    disconnect();
  };

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={isOpen}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <Typography className={classes.title}>
            {`Captura ${finger} mano ${hand}`}
          </Typography>
          <div className={classes.captureBtnContainer}>
            <PrimaryButton
              title={"Capturar"}
              props={{
                disabled: loadingFingerPrint || !!capture,
                endIcon: <Fingerprint className={classes.iconButton} />,
                onClick: handleCaptureFinger,
              }}
            ></PrimaryButton>
          </div>
          <Box className={classes.boxCapture}>
            {capture ? (
              <div className={classes.fingerCaptureContainer}>
                <p className={classes.sensitiveData}>DATO SENSIBLE</p>
                <img src={fingerImage} alt="imagebase64" />
              </div>
            ) : (
              <Fingerprint className={classes.fingerIcon} />
            )}
          </Box>
          <div className={classes.buttonsContainer}>
            <div style={{ width: "167px", height: "40px" }}>
              <SecondaryButton
                title={"Cancelar"}
                props={{
                  disabled: loadingFingerPrint,
                  onClick: handleCancel,
                }}
              ></SecondaryButton>
            </div>
            <div style={{ width: "167px", height: "40px" }}>
              <PrimaryButton
                title={"Aceptar"}
                props={{ onClick: onConfirmClick, disabled: nextFinger }}
              ></PrimaryButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <FullLoader open={loadingFingerPrint} />
    </>
  );
};

export default CaptureDialog;
