import { ChatSharp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENCRYPT_PHARAGRAPH } from "../config/const";
import {
  createBusinessUnitParametrizationAC,
  getBusinessUnitsAC,
  getParametrizationAC,
  updateBusinessUnitInheritAC,
  updateBusinessUnitParametrizationAC,
} from "../store/action-creators/BusinessUnitActionCreators";
import {
  getBusinessUnitAC as getBusinessUnitACCommon,
  getRepositoriesAC,
} from "../store/action-creators/CommonListActionCreators";
import {
  ICreateBusinessUnitParametrization,
  IParametrization,
  UpdateInherit,
} from "../store/models/BusinessUnit";
import { authSelector } from "../store/slices/AuthSlice";
import {
  BusinessUnitSelector,
  setBusinessUnits,
  setCurrentBusinessUnit,
  setParametrization,
  setShowForm,
  setIsInherit,
  setIsNewParametrization,
  setEdit,
  setShowModal,
} from "../store/slices/BusinessUnitSlice";
import { CommonListSelector } from "../store/slices/CommonListsSlice";
import {
  CommonSelector,
  setError,
  setSuccess,
} from "../store/slices/CommonSlice";

type IDataBool = {
  automaticEmail: boolean;
  signTyC: boolean;
  signATDP: boolean;
  generateATDP: boolean;
};

const useBusinessUnitParametrization = ({ edit }: { edit: boolean }) => {
  // dependencies
  var aesEcb = require("aes-ecb");

  // hooks
  const dispatch = useDispatch();

  // redux state
  const {
    currentBusiness,
    parametrization,
    isNew,
    isNewParametrization,
    isInerit,
    paginatorBussiness,
    loadingBusiness,
    isEdit,
    isEditParametrization,
    isSuccess,
    showModal,
  } = useSelector(BusinessUnitSelector);
  const { repositories } = useSelector(CommonListSelector);
  const {
    tokens: {
      result: { token },
    },
  } = useSelector(authSelector);
  const { loading, error, success } = useSelector(CommonSelector);

  // local state
  const [params, setParams] = useState<IParametrization | undefined>(
    parametrization
  );
  const [message, setMessage] = useState("");
  const [swithInheritDisable, setSwitchInheritDisable] = useState(false);

  // custom functions

  /**
   * Change the state on REDUX (setEdit)
   * @function
   * @param {boolean} edit data to save
   */
  const handleIsEdit = (edit: boolean) => dispatch(setEdit(edit));

  /**
   * Change the state of the local useState
   * @function
   * @param {string} textMessage data to save
   */
  const handleMessage = (textMessage: string) => setMessage(textMessage);

  /**
   * Change the state of the local useState
   * @function
   * @param {boolean} inheritDisable data to save
   */
  const handleSwitchInheritDisable = (inheritDisable: boolean) =>
    setSwitchInheritDisable(inheritDisable);

  /**
   * Switch the state on REDUX (setIsinherit)
   * @function
   * @param {boolean} showC data to save
   */
  const handleIsInherit = (checked: boolean) => {
    dispatch(setIsInherit(!isInerit));
  };

  /**
   * Object with boolean state of data business unit (IDataBool)
   */
  const dataBol: IDataBool = {
    automaticEmail:
      params?.automaticEmail?.toString() === "true" ||
      params?.automaticEmail?.toString() === "True"
        ? true
        : false,
    signTyC:
      params?.signTyC?.toString() === "true" ||
      params?.signTyC?.toString() === "True"
        ? true
        : false,
    signATDP:
      params?.signATDP?.toString() === "true" ||
      params?.signATDP?.toString() === "True"
        ? true
        : false,
    generateATDP:
      params?.generateATDP?.toString() === "true" ||
      params?.generateATDP?.toString() === "True"
        ? true
        : false,
  };

  /**
   * Load parametrization on Local state and REDUX (getParametrizationAC)
   * @function
   * @param {boolean} checked data to save
   */
  const loadParametrization = (checked: boolean) => {
    let id = undefined;
    let currentBusinessId = currentBusiness?.id;
    if (checked) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let id = currentBusiness
        ? currentBusiness.inheritSetting && currentBusiness.businessUnit != null
          ? currentBusiness.businessUnit
          : currentBusiness.id
        : undefined;
    }
    dispatch(getParametrizationAC("", id, currentBusinessId, checked ? 1 : 2));
    setParams(parametrization);
  };

  /**
   * Handle form submit
   * @function
   * @param {any} data data to submit
   */
  const formSubmit = (data: any) => {
    let processedData = {};
    // compare password original to handle if this has more of 150 Chars
    const comparePasswordSign =
      parametrization?.passwordSignOriginal?.substring(0, 150);
    const comparePasswordStamp =
      parametrization?.passwordStampOriginal?.substring(0, 200);

    if (comparePasswordSign === data.passwordSign) {
      data.passwordSign = data?.passwordSignOriginal;
      delete data.passwordSignOriginal;
    } else {
      delete data.passwordSignOriginal;

      const passwordSignEncripted = !!data.passwordSign
        ? aesEcb.encrypt(ENCRYPT_PHARAGRAPH, data.passwordSign || "")
        : "";
      data.passwordSign = passwordSignEncripted;
    }
    if (comparePasswordStamp === data.passwordStamp) {
      data.passwordStamp = data?.passwordStampOriginal;
      delete data.passwordStampOriginal;
    } else {
      delete data.passwordStampOriginal;
      const passwordStampEncripted = !!data.passwordStamp
        ? aesEcb.encrypt(ENCRYPT_PHARAGRAPH, data.passwordStamp || "")
        : "";
      data.passwordStamp = passwordStampEncripted;
    }

    for (const key in data) {
      processedData = {
        ...processedData,
        [key]: data[key] ? data[key].toString() : "",
      };
    }

    const paramsC: ICreateBusinessUnitParametrization = {
      companyId: "c6937b97-8a44-454e-84c4-71a9a6d13455",
      businessUnitId: currentBusiness?.id,
      isInheritSetting: isInerit,
    };
    const inheritParams: UpdateInherit = {
      company: "c6937b97-8a44-454e-84c4-71a9a6d13455",
      businessUnit: currentBusiness?.id ? currentBusiness?.id : 0,
      isInherit: isInerit,
    };

    if (isInerit) {
      // check if clientId is different to current clientId
      if (data.clientId !== parametrization?.clientId) {
        paramsC.parameters = { clientId: data.clientId };
      }
      if (!isNew) {
        dispatch(updateBusinessUnitInheritAC(inheritParams));
        dispatch(updateBusinessUnitParametrizationAC(paramsC));
      } else {
        dispatch(createBusinessUnitParametrizationAC(paramsC));
        dispatch(updateBusinessUnitInheritAC(inheritParams));
      }
    } else {
      paramsC.parameters = processedData;
      dispatch(setParametrization(paramsC.parameters));
      if (!isNew) {
        dispatch(updateBusinessUnitInheritAC(inheritParams));
        dispatch(updateBusinessUnitParametrizationAC(paramsC));
      } else {
        dispatch(createBusinessUnitParametrizationAC(paramsC));
        dispatch(updateBusinessUnitInheritAC(inheritParams));
      }
    }
    dispatch(setIsNewParametrization(true));
  };

  /**
   * Hndle close modal and reset to default REDUX states
   * @function
   */
  const handleCloseModal = () => {
    dispatch(setShowModal(false));
    dispatch(setShowForm(false));
    dispatch(setIsNewParametrization(false));
    dispatch(setBusinessUnits([]));
    dispatch(setParametrization(undefined));
    dispatch(setCurrentBusinessUnit(undefined));
    dispatch(getBusinessUnitsAC(paginatorBussiness));
    dispatch(getBusinessUnitACCommon());
  };

  // react hooks
  useEffect(() => {
    if (error) {
      setMessage(error);
    } else if (isNewParametrization) {
      setMessage(success);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success]);

  useEffect(() => {
    if (!repositories) dispatch(getRepositoriesAC(token));
    handleSwitchInheritDisable(isNew ? true : false);
    if (!params && !loadingBusiness && !loading) {
      !!parametrization && setParams(parametrization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!parametrization) {
      setParams(parametrization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametrization]);

  useEffect(() => {
    if (!!currentBusiness && !isNew && !edit) {
      dispatch(
        setIsInherit(
          currentBusiness?.inheritSetting
            ? true
            : currentBusiness?.businessUnit
            ? true
            : false
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness]);

  return {
    dataBol,
    isEdit,
    message,
    params,
    swithInheritDisable,
    formSubmit,
    loadParametrization,
    handleIsEdit,
    handleMessage,
    handleSwitchInheritDisable,
    isInerit,
    isNew,
    paginatorBussiness,
    loading,
    loadingBusiness,
    handleCloseModal,
    handleIsInherit,
    isEditParametrization,
    isSuccess,
    showModal,
  };
};

export default useBusinessUnitParametrization;
