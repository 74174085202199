import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import "../ProgressBar/ProgressBar.css";

type ProgressBarProps = {
  done: string;
  value?: string;
  title: string;
  onlyBars?: boolean;
  multipleColor?: boolean;
  green?: string;
  yellow?: string;
  red?: string;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const [style, setStyle] = useState({});

  const useStyles = makeStyles(() => ({
    linearGradientGreen: {
      backgroundImage: "linear-gradient(#62B916,#62B916)",
    },
    linearGradientYellow: {
      backgroundImage: "linear-gradient(#E99C00,#E99C00)",
    },
    linearGradientRed: {
      backgroundImage: "linear-gradient(#D91637,#D91637)",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${props.done}%`,
      };
      setStyle(newStyle);
    }, 200);
  }, [props.done]);

  if (props.multipleColor) {
    return (
      <Grid container direction="row">
        <Grid style={{ width: props.green + "%" }}>
          <div
            id={props.red && props.yellow && "green"}
            className={`${"progress-colors"} ${classes.linearGradientGreen}`}
            style={style}
          ></div>
        </Grid>
        <Grid style={{ width: props.yellow + "%" }}>
          <div
            className={`${"progress-colors"} ${classes.linearGradientYellow}`}
            style={style}
          ></div>
        </Grid>
        <Grid style={{ width: props.red + "%" }}>
          <div
            id={props.green && props.yellow && "red"}
            className={`${"progress-colors"} ${classes.linearGradientRed}`}
            style={style}
          ></div>
        </Grid>
      </Grid>
    );
  } else {
    return props.onlyBars ? (
      <div className="progress-done" style={style}></div>
    ) : (
      <div className="progress">
        <div className="progress-done" style={style}>
          <Typography
            style={{ paddingLeft: "10px", fontSize: "10px", flexGrow: 1 }}
          >
            {props.title}
          </Typography>
          <Typography
            style={{ margin: "0px auto", fontSize: "10px", paddingRight: "5%" }}
          >
            {props.value ? props.value : props.done}
          </Typography>
        </div>
      </div>
    );
  }
};
