import axios, { AxiosResponse } from "axios";
import { BIOMETRICS_URL } from "../config/const";
import { ListBasicResponse, StandardResponse } from "../type";
import { defaultHeaders } from "../config/config";
import { INotification, IReadNotification } from "../store/models/NotificationModel";
import { BasicResponse } from "../store/models/ListBasicResponse";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const USER_NOTIFICATIONS = "Notification";

/**
 * @description Servicio de biometria que retorna todas las notificaciones con estado no leido del usuario
 * @returns { StandardResponse<ListBasicResponse<INotification>> } INotification
 */
export const getUserNotifications = async (
): Promise<StandardResponse<ListBasicResponse<INotification>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<INotification>>
    > = await instance.get(USER_NOTIFICATIONS, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que verifica y almacena las notificaciones del usuario
 * @param { INotification[] } notifications Parametro de tipo INotification[] que contiene la informacion los mensajes de notificacion
 * @returns { StandardResponse<ListBasicResponse<BasicResponse>> } BasicResponse
 */
export const addNotifications = async (
    notifications: INotification[]
  ): Promise<StandardResponse<ListBasicResponse<BasicResponse>>> => {
    try {
      const response: AxiosResponse<
        StandardResponse<ListBasicResponse<BasicResponse>>
      > = await instance.post(USER_NOTIFICATIONS, notifications, defaultHeaders());
      return response.data;
    } catch (error) {
      throw error;
    }
};

/**
 * @description Servicio de biometria que cambia el estado de las notificaciones
 * @param { IReadNotification } body Parametro de tipo IReadNotification que contiene el ID de la notificacion y el estado nuevo
 * @returns { StandardResponse<ListBasicResponse<BasicResponse>> } BasicResponse
 */
export const readNotification = async (
    body: IReadNotification
  ): Promise<StandardResponse<ListBasicResponse<BasicResponse>>> => {
    try {
      const response: AxiosResponse<
        StandardResponse<ListBasicResponse<BasicResponse>>
      > = await instance.put(USER_NOTIFICATIONS, body , defaultHeaders());
      return response.data;
    } catch (error) {
      throw error;
    }
};