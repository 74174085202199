import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import SuccessIcon from "./../../assets/succes-icon.svg";
import { PrimaryButton } from "../button/PrimaryButton";

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },

  text: {
    font: "normal normal bold 16px/24px Muli",
    marginBottom: "17px",
    textAlign: "center",
  },
  iconContainer: {
    width: "83px",
    height: "80px",
    marginBottom: "17px",
  },
  buttonSuccess: {
    width: "147px",
    height: "40px",
    fontSize: "18px",
    marginBottom: "37px",
  },
}));

interface Props {
  isOpen: boolean;
  isClose: any;
  messageStatusAtdp: boolean | string
}

const ConfirmATDP = ({ isOpen, isClose, messageStatusAtdp }: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const classes = useStyles();
  return (
    <Dialog fullWidth={true} maxWidth={maxWidth} open={isOpen}>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            <img
              src={SuccessIcon}
              alt="success Icon"
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className={classes.text}>
            <p>{messageStatusAtdp}</p>
          </div>
          <div className={classes.buttonSuccess}>
            <PrimaryButton title={"Aceptar"} props={{ onClick: isClose }} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmATDP;
