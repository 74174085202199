import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AtdpErrorMailSend } from '../../../components/Icons/AtdpErrorMailSend';
import { AtdpMailSend } from '../../../components/Icons/AtdpMailSend';
import { AlertIconModal } from '../../../components/modal/AlertIconModal';
import { AtdpSelector, setCheckboxListChecks, setListAtdp, setMailAtdp, setSendEmailBase64Atdp } from '../../../store/slices/AtdpSlice';
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { AlertEmailModal } from './AlertEmailModal';
import { IListAtdps, IListChecks } from '../../../store/models/CreateAtdp';

interface IEmailResponseTableProps {
  finaMail: boolean;
  finaMailMany: boolean;
  setForShippingCode: Function;
  setStateOpenMail: Function;
  setStateOpenMailMany: Function;
  forShippingCode: IListChecks[];
}

/** Tabla con la respuesta del envio de los atdps enviados por correo. */

export default function EmailResponseTable({
  finaMail,
  finaMailMany,
  setStateOpenMail,
  setStateOpenMailMany,
  setForShippingCode,
  forShippingCode,
}: IEmailResponseTableProps) {
  const dispatch = useDispatch();

  const { listAtdp, mailAtdp, loadingAtdp } = useSelector(AtdpSelector);

  const [isOpenMail, setIsOpenMail] = useState(false);
  const [isOpenMailMore, setIsOpenMailMore] = useState(false);

  /**
   * Se ejecuta cuando finaliza el envio de correo individual verificando si el array de respuesta tiene tamaño activa el modal.
   */

  useEffect(() => {
    mailAtdp.length && setIsOpenMail(finaMail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finaMail]);

  /**
   * Se ejecuta cuando finaliza el envio de correos multiple y cambia el estado del modal.
   */

  useEffect(() => {
    setIsOpenMailMore(finaMailMany);
  }, [finaMailMany]);

  /**
   * Función que se lanza cada vez que se cierra el modal individual o multiple limpiando los estados.
   */

  const setValues = () => {
    setForShippingCode([]);
    dispatch(setSendEmailBase64Atdp([]));
    dispatch(setCheckboxListChecks([]));
    dispatch(setMailAtdp([]));
    let listBD: IListAtdps[] = [...listAtdp];
    // eslint-disable-next-line array-callback-return
    listBD.map((item, index) => {
      let element = { ...item, stateChecked: false };
      listBD.splice(index, 1, element);
    });
    dispatch(setListAtdp(listBD));
  };

  return forShippingCode.length === 1 ? (
    <>
      {/* Modal con mensaje de respuesta del envio del atdp */}
      <AlertIconModal
        open={isOpenMail}
        handleClose={() => {
          setIsOpenMail(false);
          setStateOpenMail(false);
          setValues();
        }}
        icon={
          mailAtdp[0]?.sucessfull ? (
            <AtdpMailSend width="90" height="90" />
          ) : (
            <AtdpErrorMailSend width="90" height="90" />
          )
        }
        message={
          mailAtdp[0]?.sucessfull
            ? "¡El correo ha sido enviado exitosamente!"
            : "¡El correo no ha sido enviado exitosamente!"
        }
      />
      <FullLoader open={loadingAtdp} viewLoader={true} />
    </>
  ) : (
    <>
      {/* Modal con mensaje de respuesta del envio del atdp */}
      <AlertEmailModal
        open={isOpenMailMore}
        handleClose={() => {
          setIsOpenMailMore(false);
          setStateOpenMailMany(false);
          setIsOpenMail(false);
          setStateOpenMail(false);
          setValues();
        }}
      />
      <FullLoader open={loadingAtdp} viewLoader={true} />
    </>
  );
}
