import axios, { AxiosResponse } from "axios";

// CONFIGURACIONES
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";

// MODELS
import {
  Comparison,
  ComparisonResponse,
} from "../store/models/ComparisonModel";
import { ListBasicResponse } from "../store/models/ListBasicResponse";

// TYPES
import {
  AdvanceSearch,
  IAudit,
  PaginatorWithFilterAndSort,
} from "../store/types";
import { ResponseTemplate, StandardResponse } from "../type";

// CONSTANTES
const GET_COMPARISON = "comparison/AllComparison";
const COMPARISON_AUDIT = "comparison/Audit";
const COMPARISON_EXCEL = "Comparison/GetComparisonExcel";
const ADVANCE_SEARCH = "Comparison/AdvanceSearch";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

/**
 * @description Servicio de biometria para obtener el registro de todos los comparison y filtrador mediante el paginador y el filtro basico
 * @param { AdvanceSearch } body parametro que representa a un objeto del tipo PaginatorWithFilterAndSort
 * @param { string } company Parametro de tipo string que representa el id de la compañia
 * @param { string } businessUnit Parametro de tipo string que representa el id de la sede
 * @returns { AxiosResponse<StandardResponse<ListBasicResponse<ComparisonResponse>>> } ComparisonResponse
 */
export const getAllComparison = async (
  body: PaginatorWithFilterAndSort,
  company?: string,
  businessUnit?: string
): Promise<StandardResponse<ListBasicResponse<ComparisonResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComparisonResponse>>
    > = await instance.post(GET_COMPARISON, body, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria para obtener la auditoria de un registro de comparison el cual se envia la informacion del comparison
 * @param { Comparison } body Parametro de tipo Comparison que contiene la informacion de el comparison
 * @returns { AxiosResponse<any> } any
 */
export const auditComparisonService = async (
  body: Comparison
): Promise<StandardResponse<IAudit>> => {
  try {
    const response = await instance.post(
      COMPARISON_AUDIT,
      body,
      defaultHeaders()
    );
    if (response.status === 200) {
      const result = response.data;
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que retorna los registros de el comparison de una compañia preparados con una estructura para su descarga en la extension de archivo excel
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene la configuracion para realizar un filtro avanzado
 * @returns { AxiosResponse<StandardResponse<ResponseTemplate>> } ResponseTemplate
 */
export const getComparisonExcelService = async (
  body: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> = await instance.post(COMPARISON_EXCEL, body, defaultHeaders());
    if (response.status === 200) {
      const result = response.data;
      return result; 
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que permite realizar una busqueda avanzada de los registros de comparison
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene la configuracion para realizar un filtro avanzado
 * @returns { AxiosResponse<StandardResponse<ListBasicResponse<ComparisonResponse>>> } ComparisonResponse
 */
export const advanceSearch = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ComparisonResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComparisonResponse>>
    > = await instance.post(ADVANCE_SEARCH, body, defaultHeaders());

    return response.data;
  } catch (error) {
    throw error;
  }
};
