import {
  createDevice,
  getAllDevices,
  updateDevice,
  deviceAuditService,
  getMassiveDeviceTemplate,
  validateLoadStatus,
  createMassiveDevice,
  getDevicesByBusinessUnit,
  getAdvanceSearchDeviceService,
  getAdvanceSearchDeviceCsvService,
} from "../../services/DeviceService";
import { ResultResponse, StandardResponse } from "../../type";
import { ILoadDevice } from "../models/DeviceUnit";
import {
  setError,
  setHashCsv,
  setHashCsvModal,
  setSuccess,
  setLoading,
} from "../slices/CommonSlice";
import {
  auditDivice,
  setDeviceMassiveResponse,
  setCurrentDevice,
  setDevices,
  setPaginatorDevice,
  setSize,
  setFilterDevice,
  setSortDevice,
  setLoadingDevice,
} from "../slices/DeviceSlice";
import { AppThunk } from "../store";
import {
  AdvanceSearch,
  Device,
  DeviceBusinessUnit,
  PaginatorWithFilterAndSort,
  sortBy,
} from "../types";
/**
 * Crea un dispositivo
 * @param { Device } body - información del dispositivo
 */
export const createDeviceAC =
  (body: Device): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      createDevice(body)
        .then((response: StandardResponse<ResultResponse>) => {
          if (response.statusCode === 200) {
            dispatch(setSuccess(response.result.information));
          } else {
            dispatch(
              setError(
                response.result.information || "Exception createDeviceAC"
              )
            );
          }
        })
        .catch((err) => {
          dispatch(setError(err || "Exception createDeviceAC"));
        })
        .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Actualiza un dispositivo
 * @param { Device } body - información del dispositivo
 */
export const updateDeviceAC =
  (body: Device): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true)) &&
      updateDevice(body)
        .then((response: StandardResponse<ResultResponse>) => {
          if (response.statusCode === 200) {
            dispatch(setSuccess(response.result.information));
            dispatch(setCurrentDevice(body));
            dispatch(setDevices([]));
          } else {
            dispatch(
              setError(
                response.result.information || "Exception updateDeviceAC"
              )
            );
          }
        })
        .catch((err) => {
          dispatch(setError(err || "Exception updateDeviceAC"));
        })
        .finally(() => dispatch(setLoading(false)));
  };
/**
 * Guarda la paginación
 * @param { number } page - numero de pagina
 * @param { number } itemsPage - limite de datos mostrados
 * @param { boolean } recharge - validación se recarga la paginación
 */
export const getPaginatorAC =
  (page: number, itemsPage: number, recharge: boolean): AppThunk =>
  (dispatch) => {
    dispatch(
      setPaginatorDevice({
        page: page,
        itemsPage: itemsPage,
        recharge: recharge,
      })
    );
  };
/**
 * Guarde el texto por el que se quiere filtrar
 * @param { string } filter - texto a filtrar en la busqueda
 */
export const getFilterAC =
  (filter: string): AppThunk =>
  (dispatch) => {
    dispatch(setFilterDevice(filter));
  };
/**
 * Guarda el ordenamiento
 * @param { sortBy } sort - Tipo de ordenamiento
 */
export const getSortAC =
  (sort: sortBy): AppThunk =>
  (dispatch) => {
    dispatch(setSortDevice(sort));
  };
/**
 * Obtiene y guarda los dispostivos
 * @param { PaginatorWithFilterAndSort } paginator - informacion de la paginación el filtro y el ordenamiento
 * @param { string } company - Nit de la compañia
 * @param { string } businessUnit - id de la sede
 * @param { ( number | undefined ) } equipment - id del equipo
 */
export const getDevicesAC =
  (
    paginator: PaginatorWithFilterAndSort,
    company?: string,
    businessUnit?: string,
    equipment?: number | undefined
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      getAllDevices(paginator, company, businessUnit, equipment)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setDevices(response.result.records));
            dispatch(setSize(response.result.count));
          }
        })
        .catch((err) => {
          dispatch(setError(err || "Exception getDevicesAC"));
        })
        .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Obtiene y guarda los dispostivos los dispostivos dependiendo la sede o el equipo
 * @param { DeviceBusinessUnit } body - datos
 */
export const getDevicesByBusinessUnitAC =
  (body: DeviceBusinessUnit): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      getDevicesByBusinessUnit(body)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(setDevices(response.result.records));
            dispatch(setSize(response.result.count));
          }
        })
        .catch((err) => {
          dispatch(setError(err || "Exception getDevicesByBusinessUnitAC"));
        })
        .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Obtiene y guarda la auditoria del dispostivo
 * @param { Device } body - datos para la auditoria
 * @param { Function } setLoadingT actualizar estado de loader según la vista.
 */

export const deviceAuditAC =
  (body: Device, setLoadingT: Function): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingT(true));
    deviceAuditService(body)
      .then((data) => {
        const audit = data.result;
        dispatch(auditDivice(audit));
      })
      .catch((err) => {
        dispatch(setError(err || "Exception deviceAuditAC"));
      })
      .finally(() => dispatch(setLoadingT(false)));
  };
/**
 * Descarga plantilla del caegue masivo
 */
export const getMassiveDeviceTemplateAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingDevice(true)) &&
    getMassiveDeviceTemplate()
      .then((response) => {
        if (response.statusCode === 200) {
          const mimeType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          const linkSource = `${mimeType}${response.result.downloadRoute}`;
          const downloadLink = document.createElement("a");
          const fileName =
            response.result.nameFile + "." + response.result.extensionFile;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          dispatch(
            setError(
              response.statusMessage || "Exception getMassiveDeviceTemplateAC"
            )
          );
        }
      })
      .catch((err) =>
        dispatch(setError(err || "Exception getMassiveDeviceTemplateAC"))
      )
      .finally(() => dispatch(setLoadingDevice(false)));
};
/**
 * Guarda la carga masivos
 * @param { Array<ILoadDevice> } body - datos del cargue masivo
 */
export const createMassiveDeviceAC =
  (body: Array<ILoadDevice>): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      createMassiveDevice(body)
        .then((response) => {
          if (response.statusCode === 200) {
          } else {
            dispatch(
              setError(
                response.statusMessage || "Exception createMassiveDeviceAC"
              )
            );
          }
        })
        .catch((err) =>
          dispatch(setError(err || "Exception createMassiveDeviceAC"))
        )
        .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Guarda la carga masivos
 * @param { Array<ILoadDevice> } body - datos del cargue masivo
 * @param { any } onResponse - funcion que valida el estado de la creacion del cargue masivo
 */
export const saveMassiveLoadAC =
  (body: Array<ILoadDevice>, onResponse: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      createMassiveDevice(body)
        .then((response) => {
          if (response.statusCode === 200) {
            onResponse(true);
            return true;
          } else {
            onResponse(false);
            return false;
          }
        })
        .catch((err) => {
          dispatch(setError(err || "Exception saveMassiveLoadAC"));
          return false;
        })
        .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Obtiene la validación si se puede subir o reemplazar o si esta mal y lo guarda
 * @param { Array<ILoadDevice> } body - datos del cargue masivo
 */
export const validateLoadStatusAC =
  (body: Array<ILoadDevice>): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true));
    return validateLoadStatus(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setDeviceMassiveResponse(response.result));
        } else {
          dispatch(
            setError(response.statusMessage || "Exception validateLoadStatusAC")
          );
        }
      })
      .catch((err) => {
        dispatch(setError(err || "Exception validateLoadStatusAC"))
        throw err
      }
      )
      .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Realiza la búsqueda avanzada y actualiza los dispositivos
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const advanceSearchDeviceAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true));
    getAdvanceSearchDeviceService(body)
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(setDevices(response.result.records));
          dispatch(setSize(response.result.count));
        }
      })
      .catch((error) => {
        const { data } = error;
        const { information } = data.result;
        dispatch(setError(information || "Exception advanceSearchDeviceAC"));
      })
      .finally(() => dispatch(setLoadingDevice(false)));
  };
/**
 * Realiza la búsqueda avanzada y descarga archivo csv con el resultado
 * @param { AdvanceSearch } body - datos de la búsqueda
 */
export const advanceSearchDeviceCsvAC =
  (body: AdvanceSearch): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingDevice(true)) &&
      getAdvanceSearchDeviceCsvService(body)
        .then((response) => {
          if (response.statusCode === 200) {
            const link = `data:application/zip;base64,${response.result.downloadRoute}`;
            const downloadLink = document.createElement("a");
            const fileName = response.result.nameFile + ".zip";
            downloadLink.href = link;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(setHashCsv(response.result.hash));
            dispatch(setHashCsvModal(true));
          }
        })
        .catch((err) =>
          dispatch(setError(err || "Exception advanceSearchDeviceCsvAC"))
        )
        .finally(() => dispatch(setLoadingDevice(false)));
  };
