export type IconProps = {
  width: string;
  height: string;
  active: boolean;
  className: any;
};

export const LaunchIcon = ({
  height,
  width,
  active,
  className = "",
}: IconProps) => {
  const viewBox = "0 0 24 24";
  const fill = active ? "#FFFFFF" : "#6d6e71";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      className={`svg-icon ${className || ""}`}
    >
      <path
        fill={fill}
        d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
      />
    </svg>
  );
};
