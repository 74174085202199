export const defaultDashboardByRole = [
  {
    role: "BIOMETRIA CAPTURADOR",
    default: 1,
    alter: 1
  },
  {
    role: "BIOMETRIA ADMIN",
    default: 2,
    alter: 2
  },
  {
    role: "BIOMETRIA SUPERADMIN",
    default: 3,
    alter: 2
  },
];