import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BusinessUnitMassiveResponse,
  IBusinessUnit,
  IParametrization,
} from "../models/BusinessUnit";
import {
  IAudit,
  Paginator,
  PaginatorWithFilterAndSort,
  sortBy,
} from "../types";
import { RootState } from "./rootReducer";

/**
 * @type business unit state definition
 *  */
type businessUnitState = {
  currentBusiness: IBusinessUnit | undefined;
  businessUnits: IBusinessUnit[];
  showForm: boolean;
  isEdit: boolean;
  parametrization: IParametrization | undefined;
  isNew: boolean;
  isNewParametrization: boolean;
  businessUnitAudit: IAudit | undefined;
  parametrizationAudit: IAudit | undefined;
  businessMassiveResponse: BusinessUnitMassiveResponse | undefined;
  paginatorBussiness: PaginatorWithFilterAndSort;
  sizeBussiness: number;
  isInerit: boolean;
  loadingBusiness: boolean;
  loadingBusinessForm: boolean;
  isEditParametrization: boolean;
  isSuccess: string;
  showModal: boolean;
};

/**
 * @property state initialization
 */
const initialState: businessUnitState = {
  businessUnits: [],
  currentBusiness: undefined,
  showForm: false,
  isEdit: true,
  parametrization: undefined,
  isNew: false,
  isNewParametrization: false,
  businessUnitAudit: undefined,
  parametrizationAudit: undefined,
  businessMassiveResponse: undefined,
  paginatorBussiness: {
    paginator: { page: 1, itemsPage: 10, recharge: true },
    filter: "",
    sort: "recent",
  },
  sizeBussiness: 0,
  isInerit: true,
  loadingBusiness: false,
  loadingBusinessForm: false,
  isEditParametrization: false,
  isSuccess: "",
  showModal: false,
};

/**
 * @function businessUnitSlice redux state slice
 *
 * control over business unit state
 */

const businessUnitSlice = createSlice({
  name: "business-unit",
  initialState,
  reducers: {
    setLoadingBusiness: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingBusiness = payload;
    },
    setLoadingBusinessForm: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingBusinessForm = payload;
    },
    setShowForm: (state, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
    },
    setCurrentBusinessUnit: (
      state,
      { payload }: PayloadAction<IBusinessUnit | undefined>
    ) => {
      state.currentBusiness = payload;
    },
    setBusinessUnits: (state, { payload }: PayloadAction<IBusinessUnit[]>) => {
      state.businessUnits = payload;
    },
    setEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdit = payload;
    },
    setParametrization: (
      state,
      { payload }: PayloadAction<IParametrization | undefined>
    ) => {
      state.parametrization = payload;
    },
    setIsNew: (state, { payload }: PayloadAction<boolean>) => {
      state.isNew = payload;
    },
    setIsNewParametrization: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewParametrization = payload;
    },
    auditBusinessUnits: (state, { payload }: PayloadAction<IAudit>) => {
      state.businessUnitAudit = payload;
    },
    auditParametrization: (state, { payload }: PayloadAction<IAudit>) => {
      state.parametrizationAudit = payload;
    },
    setBusinessMassive: (
      state,
      { payload }: PayloadAction<BusinessUnitMassiveResponse | undefined>
    ) => {
      state.businessMassiveResponse = payload;
    },
    setPaginatorBussiness: (state, { payload }: PayloadAction<Paginator>) => {
      if (isNaN(payload.page)) {
        payload.page = 1;
      }
      state.paginatorBussiness.paginator = payload;
    },
    setSizeBussiness: (state, { payload }: PayloadAction<number>) => {
      state.sizeBussiness = payload;
    },
    setFilterBussiness: (state, { payload }: PayloadAction<string>) => {
      state.paginatorBussiness.filter = payload;
    },
    setSortBussiness: (state, { payload }: PayloadAction<sortBy>) => {
      state.paginatorBussiness.sort = payload;
    },
    setIsInherit: (state, { payload }: PayloadAction<boolean>) => {
      state.isInerit = payload;
    },
    setIsEditParametrization: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditParametrization = payload;
    },
    setIsSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isSuccess = payload;
    },
    setShowModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showModal = payload;
    },
  },
});

export const {
  setBusinessUnits,
  setCurrentBusinessUnit,
  setShowForm,
  setEdit,
  setIsNew,
  setIsNewParametrization,
  setParametrization,
  auditBusinessUnits,
  auditParametrization,
  setBusinessMassive,
  setPaginatorBussiness,
  setSizeBussiness,
  setFilterBussiness,
  setSortBussiness,
  setIsInherit,
  setLoadingBusiness,
  setLoadingBusinessForm,
  setIsEditParametrization,
  setIsSuccess,
  setShowModal,
} = businessUnitSlice.actions;

export const BusinessUnitSelector = (state: RootState) => state.business;

export default businessUnitSlice.reducer;
