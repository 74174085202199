import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import { ComparisonLogResponse } from "../store/models/AuditLogModel";
import { AdvanceSearch } from "../store/types";
import { ListBasicResponse, ResponseTemplate, StandardResponse } from "../type";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const AUDITLOG_ALL_COMPARISON = "Log/ComparisonView";
const AUDITLOG_ALL_CONSULT = "Log/ConsultView";
const AUDITLOG_ALL_UNSATISFACTORYANSWER = "Log/UnsatisfactoryAnswerView";
const ADVANCE_SEARCH_CSV_COMPARISON = "RNEC/ComparisonFileView";
const ADVANCE_SEARCH_CSV_CONSULT = "RNEC/ConsultFileView";
const ADVANCE_SEARCH_CSV_UNSATISFACTORYANSWER =
  "RNEC/UnsatisfactoryAnswerFileView";

/**
 * @description Servicio de biometria que obtiene la auditoria de los comparison
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns { StandardResponse<ListBasicResponse<ComparisonLogResponse>> } ComparisonLogResponse
 */
export const getAllAuditLogComparison = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ComparisonLogResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComparisonLogResponse>>
    > = await instance.post(AUDITLOG_ALL_COMPARISON, body,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene la auditoria de consultas
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns { StandardResponse<ListBasicResponse<ComparisonLogResponse>> } ComparisonLogResponse
 */
export const getAllAuditLogConsult = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ComparisonLogResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComparisonLogResponse>>
    > = await instance.post(AUDITLOG_ALL_CONSULT, body,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene la auditoria de las respuestas no satisfactorias
 * @param { AdvanceSearch } body Parametro de tipo AdvanceSearch que contiene los datos para la busqueda avanzada
 * @returns { StandardResponse<ListBasicResponse<ComparisonLogResponse>> } ComparisonLogResponse
 */
export const getAllAuditLogUnsatisfactoryAnswer = async (
  body: AdvanceSearch
): Promise<StandardResponse<ListBasicResponse<ComparisonLogResponse>>> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<ComparisonLogResponse>>
    > = await instance.post(AUDITLOG_ALL_UNSATISFACTORYANSWER, body,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene la auditoria de comparison para la descarga de CSV
 * @param { AdvanceSearch } date Parametro de tipo AdvanceSearch que contiene el filtro por fechas para la auditoria
 * @returns { StandardResponse<ResponseTemplate> } ResponseTemplate
 */
export const getAuditLogComparisonCsvService = async (
  date: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV_COMPARISON, date,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene la auditoria para el log de consultas para la descarga CSV
 * @param { AdvanceSearch } date parametro de tipo AdvanceSearch que contiene el filtro por fechas para la auditoria
 * @returns { StandardResponse<ResponseTemplate> } ResponseTemplate
 */
export const getAuditLogConsultCsvService = async (
  date: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV_CONSULT, date,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @description Servicio de biometria que obtiene la auditoria de las respuestas no satisfactorias para descargar el CSV
 * @param { AdvanceSearch } date Parametro de tipo AdvanceSearch que contiene el filtro por fechas para la auditoria
 * @returns { StandardResponse<ResponseTemplate> } ResponseTemplate
 */
export const getAuditLogUnsatisfactoryAnswerCsvService = async (
  date: AdvanceSearch
): Promise<StandardResponse<ResponseTemplate>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResponseTemplate>> =
      await instance.post(ADVANCE_SEARCH_CSV_UNSATISFACTORYANSWER, date,defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};
