export const templateATDP = (header: any, footer: any, image: any) => {
  const tagImg = image ? `<img src=${image} id ='imageATDP' alt='preview image' class="imageLogo" width= "130px"/>` : '';
  const styleHTML = `
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
      .header{width:"100%"; min-height: 100px; height:auto}
      .navbar{width:"100%"; height:"90px"}
      .footerSign{font-size:9pt; margin: 0;}
      #qrImage {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      #toolbar {
        top: 166px;
        left: 357px;
        width: 580px;
        height: 30px;
        border: none;
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 1fr 2fr 2fr 2fr;
        grid-auto-flow: row;
        align-content: center;
        padding-right: 0.6em;
        text-align: center;
      }
      .ql-toolbar.ql-snow {
        border: 1px solid #ccc;
        box-sizing: border-box;
        font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        padding: 8px;
      }
      
      .custom-buttons {
        background: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 24px;
        padding: 3px 5px;
        width: 28px;
      }
      
      .custom {
        border-color: transparent;
        background-color: transparent;
      }
      
      /* buttons section*/
      
      .ql-icon {
        margin-top: 10%;
        background-repeat: no-repeat !important;
      }
      
      .ql-color {
        background-image: url("../../assets/gridIcon.svg") !important;
        background-size: 14px 14px !important;
        border-color: transparent;
        width: 18px;
      }
      .ql-italic {
        background: transparent 0% 0% no-repeat padding-box;
      }
      
      .ql-underline {
        background: transparent 0% 0% no-repeat padding-box;
      }
      
      .ql-left {
        background: transparent 0% 0% no-repeat padding-box;
      }
      .ql-center {
        background: transparent 0% 0% no-repeat padding-box;
      }
      .ql-right {
        background: transparent 0% 0% no-repeat padding-box;
      }
      .ql-align-center {
        text-align: center;
      }
      .ql-align-right {
        text-align: right;
      }
      .ql-align-left {
        text-align: left;
      }
      .ql-align-justify {
        text-align: justify;
      }
      .size-select {
        top: 172px;
        left: 417px;
        width: 45px;
        height: 18px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #b2b2b2;
        border-radius: 4px;
        opacity: 1;
      }
      
      .list-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 2em;
        margin-left: 1em;
      }
      
      .grid-container {
        display: grid;
        grid-template-columns: 9fr 3fr;
        background: #efefef 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 4px #00000033;
        border-radius: 5px;
        opacity: 1;
        margin-left: 40px;
        margin-right: 40px;
      }
      
      .size-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
      }
      
      .style-section {
        display: flex;
        flex-direction: row;
        margin-right: 1.5em;
        align-self: center;
      }
      
      .align-section {
        display: flex;
        flex-direction: row;
        margin-right: 1.5em;
      }
      
      .list-section {
        display: flex;
        flex-direction: row;
        margin-right: 1.5em;
      }
      
      .indent-section {
        display: flex;
        flex-direction: row;
        margin-right: 1.5em;
      }
      
      .ql-select-size {
        width: 118px !important;
      }
      
      .ql-snow .ql-picker.ql-size {
        width: 118px;
      }
      
      .custom-hr {
        border: none;
        border-left: 1px solid hsla(200, 10%, 50%, 100);
        height: 10px;
        width: 1px;
      }
      
      .tools-container {
        display: grid;
        grid-template-columns: 10px 70px 30px 30px 30px 30px;
      }
      
      .icon-names {
        background-image: url("../../assets/Icon awesome-user-alt.svg") !important;
        background-size: 15px 15px !important;
      }
      .icon-surnames {
        background-image: url("../../assets/Grupo 3595 (1).svg") !important;
        background-size: 17px 17px !important;
      }
      .icon-address {
        background-image: url("../../assets/Icon awesome-address-card.svg") !important;
        background-size: 18px 18px !important;
      }
      
      .icon-color {
        background-image: url("../../assets/fill-drip-icon.svg") !important;
        background-size: 18px 18px !important;
      }
      
      #footer-hr {
        border: 0 none;
        border-top: 2px dashed #00000033;
        background: none;
        height: 0;
      }
      
      #footer-bold {
        border: 0 none;
        border-bottom: 3px solid #000000;
        max-width: '80%';
        background: none;
        height: 0;
        margin: 0 auto;
      
      }
      
      #header-hr {
        border: 0 none;
        border-top: 2px dashed #00000033;
        background: none;
        height: 0;
      }
      
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
      }
      
      /* width */
      .ql-editor::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Handle */
      .ql-editor::-webkit-scrollbar-thumb {
        background: #aaaaaa66; 
      }
      
      /* Handle on hover */
      .ql-editor::-webkit-scrollbar-thumb:hover {
        background: #b7b7b7; 
      }
      
      /* Set content for font-families */
      .ql-font-arial,
      .ql-font span[data-value="arial"]::before {
        font-family: Arial, sans-serif;
      }
      .ql-font-comic-sans,
      .ql-font span[data-value="comic-sans"]::before {
        font-family: "Comic Sans MS", cursive, sans-serif;
      }
      .ql-font-courier-new,
      .ql-font span[data-value="courier-new"]::before {
        font-family: "Courier New";
      }
      .ql-font-georgia,
      .ql-font span[data-value="georgia"]::before {
        font-family: Georgia, serif;
      }
      .ql-font-helvetica,
      .ql-font span[data-value="helvetica"]::before {
        font-family: Helvetica, sans-serif;
      }
      .ql-font-lucida,
      .ql-font span[data-value="lucida"]::before {
        font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
      }
      
      /* Set content for sizes */
      .ql-size-extra-small,
      .ql-size span[data-value="extra-small"]::before {
        font-size: 13px !important;
      }
      .ql-size-small,
      .ql-size span[data-value="small"]::before {
        font-size: 14px !important;
      }
      .ql-size-medium,
      .ql-size span[data-value="medium"]::before {
        font-size: 18px !important;
      }
      .ql-size-large,
      .ql-size span[data-value="large"]::before {
        font-size: 20px !important;
      }
      .ql-font-Verdana,
      .ql-font span[data-value="Verdana"]::before {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }
      .ql-font-Ubuntu,
      .ql-font span[data-label="Ubuntu"]::before {
        font-family: "Ubuntu";
      }
      .ql-font-Raleway,
      .ql-font .ql-font span[data-label="Raleway"]::before {
        font-family: "Raleway";
      }
      .ql-font-Roboto,
      .ql-font span[data-label="Roboto"]::before {
        font-family: "Roboto" !important;
      }
      .ql-font-Montserrat,
      .ql-font span[data-label="Montserrat"]::before {
        font-family: 'Montserrat', sans-serif;
      }
      .ql-font-Inconsolata,
      .ql-font span[data-label="Inconsolata"]::before {
        font-family: 'Inconsolata', monospace;
      }
    </style>
    `;
  return /*html*/ `
  ${styleHTML}
  <meta charset="utf-8">
    <div class="header">
      <div class="navbar">
        ${tagImg}
      </div>
    </div>
    <span class="qrAtdp"></span>
    <div class="body">
      <span>${header}</span>
    </div>
    <div class="footer">
      <div class="signAtdp"></div>
      <span> ${footer} </span>
    </div>`;
  }