import FingerprintCapture from "./FingerprintCapture";
import FingerprintCaptureMobile from "./FingerprintCaptureMobile";

interface IFingerprintCaptureMainProps {
  isMobile: boolean;
  handleChangeTab: Function;
  companyId: string;
  roleLogged: string;
}

const FingerprintCaptureMain = ({
  isMobile,
  handleChangeTab,
  companyId,
  roleLogged,
}: IFingerprintCaptureMainProps) => {
  return (
    <>
      {isMobile ? (
        <FingerprintCaptureMobile
          changeTab={handleChangeTab}
          companyId={companyId}
          roleLogged={roleLogged === "FNA"}
        />
      ) : (
        <FingerprintCapture
          changeTab={handleChangeTab}
          companyId={companyId}
          roleLogged={roleLogged === "FNA"}
        />
      )}
    </>
  );
};
export { FingerprintCaptureMain };
