import axios from "axios";

/**
 * setAuthToken constant function (verify if token exists and set this to axios Authorization header and sessionStorage Token,
 * else, remove axios authorization header and sessionStorage Token)
 * @param {*} token
 */
const setAuthToken = (token: any) => {
  if (token) {
    axios.defaults.headers["Authorization"] = token;
    sessionStorage.setItem("jwtToken", token);
  } else {
    sessionStorage.clear();
  }
};

export default setAuthToken;
