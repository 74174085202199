import useAuditLog from "../../hooks/useAuditLog";
import DataTable from "../../components/Table/DataTable";
import columnsAuditLog from "./data/columnsAuditLog";
import getButtonsAuditLog from "./data/getButtonsAuditLog";
import notDataFoundAuditLog from "./data/notDataFoundAuditLog";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { HashDowloandModal } from "../../components/modal/HashDowloandModal";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { AdvancedSearchModal } from "../../components/modal/AdvanceSearchModal";

type AuditLogViewProps = {
  nitCompany?: string;
  updateTablet?: boolean;
};

/**
 * @description Componente que representa la vista principal de la vista de audit log
 * @param { string } param.nitCompany Parametro de tipo string que representa el numero de identificacion tributaria de la empresa
 * @param { boolean } param.updateTablet Parametro de tipo boolean
 * @returns { JSX.Element } JSX.Element
 */
const AuditLogView = ({ nitCompany, updateTablet }: AuditLogViewProps) => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortOptionLog,
    handleFilter,
    handleSort,
    auditLogComparison,
    paginatorAuditLog,
    size,
    onDownloadData,
    privilege,
    onAdvanceSearch,
    setShowFormAdvAudit,
    showFormAdvAudit,
    rol,
    handleOnOpen,
    handleOnClose,
    fieldsAdvanced,
    loadingAuditLog,
    advanceSearchState,
    fieldsAdvancedSearch,
  } = useAuditLog(nitCompany);

  /**
   * Componente que renderiza un DataTable con los registros de los audit log
   * @returns { JSX.Element } JSX.Element
   */
  const TabletComponent = () => {
    return (
      <DataTable
        buttons={getButtonsAuditLog()}
        columns={columnsAuditLog()}
        download={onDownloadData}
        data={auditLogComparison}
        paginator={paginatorAuditLog}
        havePagination={size > 10 ? true : false}
        option={undefined} //Pendiente
        page={paginatorAuditLog.paginator.page - 1}
        setOption={() => undefined} //Pendiente
        setPage={(_event: any, newPage: any) =>
          handleChangePage(_event, newPage)
        }
        size={size}
        notDataFound={
          (paginatorAuditLog.filter && auditLogComparison.length === 0) ||
          advanceSearchState
            ? undefined
            : notDataFoundAuditLog()
        }
        readDisabled={
          privilege?.find((privil) => privil === "BIOMETRIA_TRAMITE_READ") ===
          undefined
            ? true
            : false
        }
        rowsPerPage={paginatorAuditLog.paginator.itemsPage}
        setShowFormAdvance={(value: boolean) => setShowFormAdvAudit(value)}
        rol={rol}
        handleChangePage={(_event, newPage) =>
          handleChangePage(_event, newPage)
        }
        handleFilter={(_event) => handleFilter(_event)}
        handleSort={(_event) => handleSort(_event)}
        handleOnOpenAdvSearch={handleOnOpen}
        handleSortLog={(_event: any) => handleSortOptionLog(_event)}
        handleChangeRowsPerPage={(_event) => handleChangeRowsPerPage(_event)}
        loading={loadingAuditLog}
      />
    );
  };

  return (
    <>
      {!updateTablet ? (
        <>
          <AdvancedSearchModal
            open={showFormAdvAudit}
            handleOnSearch={onAdvanceSearch}
            handleOnClose={handleOnClose}
            fieldsAdvancedSearch={fieldsAdvancedSearch}
            fieldsAdvanced={fieldsAdvanced}
          />
          <ViewLayout isNew={true} headerTitle="Log Auditoria">
            <TabletComponent />
          </ViewLayout>
        </>
      ) : (
        <TabletComponent />
      )}
      <HashDowloandModal />
      <FullLoader open={loadingAuditLog} viewLoader={true} />
    </>
  );
};

export default AuditLogView;
