import { useDispatch, useSelector } from "react-redux";
import {
  IDataFinger,
  IFingerUnit,
  IMultipleParticipantInfo,
} from "../store/models/FingerPrintModel";
import {
  FingerPrintSelector,
  setMultipleParticipantInfo,
} from "../store/slices/FingerPrintSlice";

const useSocket = () => {
  const dispatch = useDispatch();
  const { responseCapture, multipleParticipantInfo } =
    useSelector(FingerPrintSelector);

  /**
   * Return Id number from finger id-string
   * @function
   * @param {string} finger Finger ID string
   * @returns {number} Finger ID
   */
  const returnIdFinger = (finger: string) => {
    switch (finger) {
      case "meñiqued":
        return 5;
      case "anulard":
        return 4;
      case "corazond":
        return 3;
      case "indiced":
        return 2;
      case "pulgard":
        return 1;
      case "meñiquei":
        return 10;
      case "anulari":
        return 9;
      case "corazoni":
        return 8;
      case "indicei":
        return 7;
      case "pulgari":
        return 6;
      default:
        return 0;
    }
  };

  /**
   * Return validation check
   * @function
   * @param {number} index Index finger
   * @param {any} responseCaptureProp Reponse capture
   * @returns {Boolean} Is finger valide?
   */
  const returnValidationCheck = (index: number, responseCaptureProp: any) => {
    let fingerResponse = false;
    let arrayResponse = responseCaptureProp?.validationResult;
    // eslint-disable-next-line array-callback-return
    arrayResponse?.map((element: any) => {
      if (index === element.finger) {
        fingerResponse = element.result;
      }
    });
    return fingerResponse;
  };

  /**
   * Save info participant on State global
   * @function
   * @param {IDataFinger | null} dataParticipant Data participant
   * @param {IFingerUnit[]} arrayFinger Array fingers
   * @param {resultMessage} string String describes response message
   * @returns {number} Finger ID
   */
  const saveInfoParticipant = (
    dataParticipant?: IDataFinger | null,
    arrayFinger?: IFingerUnit[],
    resultMessage?: string | null | undefined
  ) => {
    if (dataParticipant && responseCapture && arrayFinger) {
      let info: IMultipleParticipantInfo = {
        infoPerson: {
          id: dataParticipant.id,
          nameuser: dataParticipant.nameuser,
          documentType: dataParticipant.documentType,
          idDocumentType: !!Number(dataParticipant.documentType)
            ? dataParticipant.documentType
            : dataParticipant.idDocumentType,
          documentNumber: dataParticipant.documentNumber,
          phone: dataParticipant.phone,
          surname: dataParticipant.surname,
          email: dataParticipant.email,
        },
        infoRenec: {
          nut: responseCapture.nut || "",
          initialDate: responseCapture.initialDate || "",
          finaldate: responseCapture.finaldate || "",
          processId: responseCapture.processId || 0,
          nameTypeProcess: responseCapture.nameTypeProcess || "",
          validationResult: responseCapture.validationResult || null,
          candidate: {
            firstName1: responseCapture.candidate?.firstName1 || "",
            lastName1: responseCapture.candidate?.lastName1 || "",
            nuip: responseCapture.candidate?.nuip || "",
            validity: responseCapture.candidate?.validity || "",
          },
        },
        infoFingers: arrayFinger,
        resultMessage: resultMessage || "Sin repuesta de registraduria",
      };

      let verify = multipleParticipantInfo.some(
        (item) => item.infoPerson.id === info.infoPerson.id
      );

      if (verify) {
        const newParticipant = multipleParticipantInfo.map((user) => {
          // eslint-disable-next-line eqeqeq
          if (user.infoPerson.id == info.infoPerson.id) {
            return {
              ...user,
              infoPerson: info.infoPerson,
              infoFingers: info.infoFingers,
              infoRenec: info.infoRenec,
            };
          }
          return user;
        });
        dispatch(setMultipleParticipantInfo(newParticipant));
      } else {
        dispatch(
          setMultipleParticipantInfo([...multipleParticipantInfo, info])
        );
      }
    }
  };

  return {
    returnIdFinger,
    returnValidationCheck,
    saveInfoParticipant,
  };
};

export default useSocket;
