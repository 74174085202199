import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//COMPONENTS
import { Column } from "../components/Table/types";

//ACTIONS
import {
  advanceSearchEquipmentCsvAC,
  AdvanceSearhComputerEquipmentAC,
  getAllComputerEquipmentsAC,
  getComputerEquipmentsByBusinessUnitAC,
  getFilterAC,
  getMassiveComputerEquipmentTemplateAC,
  getPaginatorAC,
  getSortAC,
  saveMassiveLoadAC,
  validateLoadStatusAC,
} from "../store/action-creators/ComputerEquipmentActionCreator";

//SLICES
import { CommonSelector, setPrivilege } from "../store/slices/CommonSlice";
import {
  ComputerEquipmentSelector,
  setAdvanceSearch,
  setComputerEquipamentMassive,
  setCurrentComputerEquipment,
  setLoadingComputerEquipment,
  setPaginatorEquipment,
  setShowForm,
} from "../store/slices/ComputerEquipmentSlice";

//MODELS
import { ComputerEquipment } from "../store/models/ComputerEquipmentModel";
import { UpdateState } from "../store/models/UpdateState";
import {
  IComputerEquipmentError,
  ILoadComputerEquipmentUnit,
} from "../store/models/ComputerEquipmentUnit";

//TYPES
import { FieldsAdvanceSearch, sortBy, StateLoad } from "../store/types";

//SERVICES
import {
  getComputerEquipmentsExcelService,
  updateComputerEquipmentState,
} from "../services/ComputerEquipmentServices";

//UTILS
import { createExcel } from "../utils/general/utils";

//IMAGES
import loadLogo from "../assets/loadLogo.png";
import errorLogo from "../assets/errorLogo.png";
import uploadLogo from "../assets/onUpload.png";
import backLogo from "../assets/onBack.png";
import { setPaginatorDevice } from "../store/slices/DeviceSlice";
import { setComputerEquipmentEdit } from "../store/slices/CommonListsSlice";
import { useMassiveLoad } from "./useMassiveLoad";
import { moduleNamesPluralMin } from "../utils/general/const";

interface ComputerEquipmentProps {
  nitCompany?: string;
  businessUnitId?: number;
}
/**
 * Retorna las funciones y estados que se usuan en la vista principal
 * @param { Object } options - parametros
 * @param { ( string | undefined) } nitCompany - NIT de la compañia
 * @param { ( number | undefined) } businessUnitId - id de la sede.
 * @returns { ReactElement } funciones y estados
 */
const useComputerEquipment = ({
  nitCompany,
  businessUnitId,
}: ComputerEquipmentProps) => {
  //Custom Hooks
  const { onUpload } = useMassiveLoad<ILoadComputerEquipmentUnit>();
  // Redux
  const dispatch = useDispatch();
  const {
    computerEquipments,
    showForm,
    computerEquipamentMassiveResponse,
    paginatorEquipment,
    size,
    advanceSearch,
    loadingComputerEquipment,
  } = useSelector(ComputerEquipmentSelector);
  const { privilege, rol } = useSelector(CommonSelector);
  // Estados locales
  const [option, setOption] = useState(null);
  const [modal, setModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [imageModal, setImageModal] = useState(loadLogo);
  const [messageModal, setMessageModal] = useState("");
  const [confirmate, setConfirmate] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [tab, setTab] = useState(0);
  const [isLoad, setLoad] = useState(false);
  const [itemsList, setItemsList] = useState<Array<StateLoad | undefined>>();
  const [showFormEquipment, setShowFormEquipment] = useState(false);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);
  const { paginator, filter, sort } = paginatorEquipment;
  //#region  table aditional data
  const fieldsAdvanced: FieldsAdvanceSearch[] =
    rol === "BIOMETRIA RNEC"
      ? [
          {
            name: "NameEquipment",
            title: "Nombre",
            type: "text",
            value: "",
          },
          {
            name: "Ip",
            title: "IP",
            type: "text",
            value: "",
          },
          {
            name: "Mac",
            title: "MAC",
            type: "text",
            value: "",
          },
          {
            name: "TypeEquipment",
            title: "Tipo de equipo",
            type: "selectTypeEquipment",
            value: "",
          },
          {
            name: "Codesede",
            title: "Cod. Sede",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "ModificationDate",
            title: "Fecha de modificación",
            type: "date",
            value: undefined,
          },
          {
            name: "State",
            title: "Estado",
            type: "status",
            value: "",
          },
        ]
      : [
          {
            name: "NameEquipment",
            title: "Nombre",
            type: "text",
            value: "",
          },
          {
            name: "Ip",
            title: "IP",
            type: "text",
            value: "",
          },
          {
            name: "Mac",
            title: "MAC",
            type: "text",
            value: "",
          },
          {
            name: "TypeEquipment",
            title: "Tipo de equipo",
            type: "selectTypeEquipment",
            value: "",
          },
          {
            name: "NameSede",
            title: "Sede",
            type: "text",
            value: "",
          },
          {
            name: "CreationDate",
            title: "Fecha de creación",
            type: "date",
            value: undefined,
          },
          {
            name: "State",
            title: "Estado",
            type: "status",
            value: "",
          },
        ];
  /**
   * Integra los datos de la búsqueda avanzada en Redux
   * @param fields - campos de la búsqueda avanzada
   */
  const setAdvanceSearchEquipment = (fields: FieldsAdvanceSearch[]) => {
    dispatch(getPaginatorAC(1, paginatorEquipment.paginator.itemsPage, true));
    dispatch(setAdvanceSearch(fields));
  };

  //#endregion
  /**
   * Obtiene los equipos de la búsqueda normal.
   * Elimina un peticion demas.
   */
  const fillEquipmentList = useCallback(() => {
    if (!loadingComputerEquipment) {
      if (filter !== undefined && filter.length < 3) {
        dispatch(
          getAllComputerEquipmentsAC(
            { ...paginatorEquipment, filter: "" },
            nitCompany,
            businessUnitId
          )
        );
      } else {
        dispatch(
          getAllComputerEquipmentsAC(
            paginatorEquipment,
            nitCompany,
            businessUnitId
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorEquipment, dispatch, nitCompany, loadingComputerEquipment]);

  //#region  handling Effects

  /**
   * Asegura que cada vez que entre al módulo muestre la tabla
   * en la primera página el filtro este vacio y el ordenamieto sea reciente
   */
  useEffect(() => {
    if (businessUnitId === undefined) {
      dispatch(getPaginatorAC(1, 10, true));
      /* Is added to update the initial array in advancedSearch status, when exporting */
      setAdvanceSearchEquipment(fieldsAdvanced);
    }
    dispatch(setShowForm(false));
    dispatch(setComputerEquipmentEdit(0));
    return () => {
      dispatch(getFilterAC(""));
      dispatch(getSortAC("recent"));
      if (businessUnitId === undefined) dispatch(getPaginatorAC(1, 10, true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, businessUnitId]);

  useEffect(() => {
    if (!privilege?.length) {
      dispatch(setPrivilege(""));
    }
  }, [privilege, dispatch]);

  useEffect(() => {
    if (computerEquipamentMassiveResponse !== undefined) {
      setModal(false);
      const items = new Array<StateLoad>();
      Promise.all(
        // eslint-disable-next-line array-callback-return
        computerEquipamentMassiveResponse.details?.map((item) => {
          items.push({ name: item.nombre, state: item.state });
        })
      ).then(() => {
        setTab(1);
        setItemsList(items);
      });
    }
  }, [computerEquipamentMassiveResponse]);

  useEffect(() => {
    if (paginatorEquipment && paginator.recharge) {
      if (businessUnitId !== undefined) {
        if (advanceSearchState) {
          dispatch(
            AdvanceSearhComputerEquipmentAC({
              businessUnitId,
              fields: advanceSearch,
              paginator: paginatorEquipment,
              company: nitCompany,
            })
          );
        } else if (!loadingComputerEquipment) {
          dispatch(
            getComputerEquipmentsByBusinessUnitAC({
              BusinessUnitId: businessUnitId,
              Paginator: paginator,
              filter: filter,
              sort: sort,
            })
          );
        }
        dispatch(getPaginatorAC(paginator.page, paginator.itemsPage, false));
      } else {
        if (advanceSearchState) {
          dispatch(
            AdvanceSearhComputerEquipmentAC({
              fields: advanceSearch,
              paginator: paginatorEquipment,
              company: nitCompany,
            })
          );
        } else {
          fillEquipmentList();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorEquipment]);

  //#endregion

  //#region  handling events

  /**
   * Muestra un modal de alerta con un mensaje y opciones de confirmación o cancelación.
   * @param { string } titleModal - titulo del modal
   * @param { string } messageModal - mensaje del modal
   * @param { any } imageModal - imagen del modal
   * @param { Object } validate - objeto con las opciones y el texto de las mismas
   */
  const alertModal = (
    titleModal: string,
    messageModal: string,
    imageModal: any,
    validate?: {
      confirmate: boolean;
      cancel: boolean;
      confirmText: string;
      cancelText: string;
    }
  ) => {
    setModal(true);
    setImageModal(imageModal);
    setTitleModal(titleModal);
    setMessageModal(messageModal);
    if (validate) {
      setConfirmate(validate?.confirmate);
      setCancel(validate?.cancel);
      setConfirmText(validate?.confirmText);
      setCancelText(validate?.cancelText);
    }
  };

  /**
   * Cancela la accion del modal y cierra el modal
   */
  const onCancel = () => {
    setModal(false);
    setConfirmate(false);
  };

  const onContinue = () => {
    dispatch(setComputerEquipamentMassive(undefined));
    setLoad(false);
    setModal(false);
    setConfirmate(false);
    setTab(0);
  };
  /**
   * Descarga la pantilla excel para el cargue masivo
   */
  const onDownload = async () => {
    dispatch(getMassiveComputerEquipmentTemplateAC());
  };
  /**
   * Descarga los errores generados por el cargue masivo
   */
  const onDownloadErrors = () => {
    if (computerEquipamentMassiveResponse !== undefined) {
      const valores = computerEquipamentMassiveResponse.details;
      let data: any = [];

      Object.keys(valores)?.forEach((key) => {
        const item = valores[parseInt(key, 10)];

        if (item.errors !== undefined && item.errors?.length) {
          const computerEquipmentError: IComputerEquipmentError = {
            nombre: item.nombre,
            ip: item.ip ?? "",
            mac_imei: item.mac_imei ?? "",
            sede: item.sede,
            estacion: item.estacion,
            tipoEquipo: item.tipoEquipo,
            sistemaOperativo: item.sistemaOperativo,
            error: item.errors ?? "",
          };
          data.push(computerEquipmentError);
        }
      });
      // export excel by helper method
      const fill = (number: any, len: any) =>
        "0".repeat(len - number.toString().length) + number.toString();
      const currentDate =
        new Date().getFullYear() +
        "-" +
        fill(new Date().getMonth() + 1, 2) +
        "-" +
        new Date().getDate();
      createExcel(data, "Errores_Equipos_" + currentDate);
    }
  };
  /**
   * Guarda los datos masivos suministrados en el excel
   */
  const onSave = () => {
    if (computerEquipamentMassiveResponse !== undefined) {
      let bo = computerEquipamentMassiveResponse.details.filter(
        (b) => b.state === "exitoso" || b.state === "reemplazado"
      );
      dispatch(saveMassiveLoadAC(bo, onSucess));
      alertModal(
        "Cargue masivo de equipos",
        "La informacion de tus equipos esta siendo cargada a la plataforma, recuerda revisarla",
        loadLogo,
        { confirmate: false, cancel: false, cancelText: "", confirmText: "" }
      );
    }
  };
  /**
   * Muestra el alert modal y actualiza los equipos
   * @param { boolean } value - validación para saber si fue exitoso el cargue masivo
   */
  const onSucess = (value: Boolean) => {
    if (value) {
      alertModal(
        "Cargue exitosamente",
        `Tu carga masiva fue un exito`,
        uploadLogo
      );
      dispatch(setComputerEquipamentMassive(undefined));
      dispatch(getAllComputerEquipmentsAC({ ...paginatorEquipment }, ""));
      setLoad(false);
    } else {
      alertModal("Error", "Error al guardar los datos", errorLogo);
    }
  };

  /**
   * Función que ejecuta la función que valida el archivo seleccionado y valida si se puede subir o reemplazar o si esta mal
   * @param { any } e - datos del campo
   */
  const onUploadDataEvent = (e: any) =>
    onUpload(
      e,
      alertModal,
      validateLoadStatusAC,
      "ip",
      moduleNamesPluralMin.computerEquipment
    );

  /**
   * Descarga archivo Xlxs o CSV segun el rol y el criterio de busqueda actual
   */
  const onDownloadData = () => {
    if (rol === "BIOMETRIA RNEC") {
      dispatch(
        advanceSearchEquipmentCsvAC({
          fields: advanceSearch,
          paginator: paginatorEquipment,
          company: nitCompany,
        })
      );
    } else {
      // generate excel launching equipment service

      let params = !!businessUnitId
        ? {
            businessUnitId: businessUnitId,
            fields: advanceSearch,
            paginator: paginatorEquipment,
          }
        : {
            fields: advanceSearch,
            paginator: paginatorEquipment,
          };
      dispatch(setLoadingComputerEquipment(true));
      getComputerEquipmentsExcelService(params)
        .then((response) => {
          const data = response.result.records.map((item) => {
            return {
              ...item,
              fecha_Creacion: new Date(item.fecha_Creacion).toLocaleString(),
            };
          });
          // export excel by helper method
          createExcel(data, "Listado de Equipos");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => dispatch(setLoadingComputerEquipment(false)));
    }
  };
  /**
   * Cancela la carga masiva y retrocede a la tabla de equipos
   */
  const handleBack = () => {
    if (isLoad && computerEquipamentMassiveResponse !== undefined) {
      alertModal(
        "",
        "¿Estás seguro que deseas realizar esta acción?\n Si lo haces, perderás la información cargada",
        backLogo,
        { confirmate: true, cancel: true, confirmText: "SI", cancelText: "NO" }
      );
    } else {
      setLoad(false);
    }
  };
  /**
   * Actualiza los equipos con la siguiente pagina
   * @param { any } _event - datos del componente
   * @param { any } newPage - página actual
   */
  const handleChangePage = (_event: any, newPage: any) => {
    dispatch(
      getPaginatorAC(newPage + 1, paginatorEquipment.paginator.itemsPage, true)
    );
  };
  /**
   * Maneja la cantidad de datos mostrados en la tabla
   * @param { any } event - datos del componente
   */
  const handleChangeRowsPerPage = (event: any) => {
    dispatch(getPaginatorAC(1, +event.target.value, true));
  };
  /**
   * Maneja el cambio de tabs
   * @param { any } evt - datos del componente tab
   * @param { any } value - posicion de la tab
   */
  const handleChangeTab = (evt: any, value: any) => {
    computerEquipamentMassiveResponse !== undefined && setTab(value);
  };
  /**
   * Obtiene toda la informacion del equipo
   * la guarda en redux
   * @param { React.MouseEvent<HTMLSpanElement, MouseEvent> } e - datos del formulario que se enviaran
   * @param { any } value - información del equipo
   * @param _column - datos acerca de la columna
   */
  const handleCellClick = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: any,
    column: Column
  ) => {
    const currentComputer: ComputerEquipment = {
      id: value.id,
      businessUnit: value.businessUnit,
      state: value.state,
      equipment: value.type,
      ip: value.ip,
      mac: value.mac,
      imei: value.mac,
      name: value.name,
      fixedStation: value.fixedStation,
      os: value.os,
      idState: value.idState,
      haveProcess: value.haveProcess
    };
    dispatch(setShowForm(true));
    dispatch(setCurrentComputerEquipment(currentComputer));
    dispatch(setComputerEquipmentEdit(value.id));
    dispatch(setPaginatorDevice({ page: 1, itemsPage: 10, recharge: true }));
  };
  /**
   * Actualiza el estado del equipo
   * @param e - datos enviados por la fila de la tabla
   * @param value - id del equipo
   */
  const handleConfirmClick = (e: any, value: any) => {
    const state: UpdateState = {
      id: value,
      state: e.target.checked,
      paginator: {
        page: paginatorEquipment.paginator.page,
        itemsPage: paginatorEquipment.paginator.itemsPage,
        recharge: true,
      },
    };
    dispatch(setLoadingComputerEquipment(true));
    updateComputerEquipmentState(state)
      .then()
      .catch((err) =>
        console.error(err || "Exception updateComputerEquipmentState")
      )
      .finally(() => {
        dispatch(setLoadingComputerEquipment(false));
        // Paginator is thrown to refresh the data in datatable
        dispatch(
          setPaginatorEquipment(
            state.paginator === undefined
              ? { page: 1, itemsPage: 10 }
              : state.paginator
          )
        );
      });
  };
  /**
   * Maneja la busqueda normal y actualiza la tabla de equipos del modulo
   * @param { string } query - Texto a buscar
   */
  const handleFilter = (query: string) => {
    setAdvanceSearchState(false);
    dispatch(setAdvanceSearch(fieldsAdvanced));
    dispatch(getFilterAC(query.trim()));
    dispatch(getPaginatorAC(1, paginatorEquipment.paginator.itemsPage, true));
  };
  /**
   * Cierra el formulario para creación o edición de un equipo
   */
  const handleOnClose = () => {
    setShowFormEquipment(false);
  };
  /**
   * Cierra el modal
   */
  const handleOnCloseLoadModal = () => {
    setModal(false);
  };
  /**
   * Cierra el boton de carga
   */
  const handleOnLoadButton = () => {
    setLoad(true);
    setTab(0);
  };
  /**
   * Abre el formulario para creación o edición de un equipo
   */
  const handleOnOpen = () => {
    setShowFormEquipment(true);
  };
  /**
   * MAneja la busqueda avanzada y actualiza la tabla de equipos del módulo
   * @param { FieldsAdvanceSearch[] } fieldsPassedAdvancedSearch - campos y valores de la busqueda avanzada
   */
  const handleOnSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAC(""));
    setAdvanceSearchState(true);
    !!businessUnitId
      ? dispatch(
          AdvanceSearhComputerEquipmentAC({
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorEquipment,
              paginator: {
                page: 1,
                itemsPage: paginator.itemsPage,
              },
            },
            company: nitCompany,
            businessUnitId,
          })
        )
      : dispatch(
          AdvanceSearhComputerEquipmentAC({
            fields: fieldsPassedAdvancedSearch,
            paginator: {
              ...paginatorEquipment,
              paginator: {
                page: 1,
                itemsPage: paginator.itemsPage,
              },
            },
            company: nitCompany,
          })
        );
    setShowFormEquipment(false);
    dispatch(setAdvanceSearch(fieldsPassedAdvancedSearch));
    dispatch(getPaginatorAC(1, paginatorEquipment.paginator.itemsPage, true));
  };
  /**
   * Maneja el tipo de ordenamiento y actualiza la tabla segun este
   * @param { sortBy } sort - tipo de ordenamiento
   */
  const handleSort = (sort?: sortBy) => {
    if (sort) {
      dispatch(getSortAC(sort));
      dispatch(getPaginatorAC(1, paginatorEquipment.paginator.itemsPage, true));
    }
  };

  //#endregion

  return {
    advanceSearch,
    advanceSearchState,
    cancel,
    cancelText,
    computerEquipamentMassiveResponse,
    computerEquipments,
    confirmText,
    confirmate,
    fieldsAdvanced,
    handleBack,
    handleCellClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeTab,
    handleConfirmClick,
    handleFilter,
    handleOnClose,
    handleOnCloseLoadModal,
    handleOnLoadButton,
    handleOnOpen,
    handleOnSearch,
    handleSort,
    imageModal,
    isLoad,
    itemsList,
    loadingComputerEquipment,
    messageModal,
    modal,
    onCancel,
    onContinue,
    onDownload,
    onDownloadData,
    onDownloadErrors,
    onSave,
    onUploadDataEvent,
    option,
    paginatorEquipment,
    privilege,
    rol,
    setAdvanceSearchEquipment,
    setLoad,
    setModal,
    setOption,
    setShowFormEquipment,
    setTab,
    showForm,
    showFormEquipment,
    size,
    tab,
    titleModal,
  };
};

export default useComputerEquipment;
