import { CustomTypography } from "@dg-bucaramanga/react-components-dg-qa";
import { Grid } from "@material-ui/core";
import { NoDeviceIcon } from "../../../components/Icons/NoDeviceIcon";

/**
 * @description Componente que renderiza un mensaje predefinido cuando no existe ningun registro
 * @returns { JSX.Element } JSX.Element
 */
const notDataFoundAuditLog = () => {
  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <NoDeviceIcon />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="center"
      >
        <CustomTypography
          type="definition"
          children={"No se encontraron logs"} // Pendiente por cambiar
        />
      </Grid>
    </Grid>
  );
};

export default notDataFoundAuditLog;
