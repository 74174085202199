import React from "react";
import { useSelector } from "react-redux";

import { AdminUsers } from "@dg-bucaramanga/react-components-dg-qa";

import { decodeToken } from "../../utils/general/utils";
import { PersonalizationSelector } from "../../store/slices/PersonalizationSlice";

interface AdminUserViewProps {
  token: string;
  nitCompany?: String;
}

const AdminUserView = ({ token }: AdminUserViewProps) => {
  const { principalColor, secondaryColor, buttonColor, buttonTextColor } =
    useSelector(PersonalizationSelector);
  const decodeNewToken: any = decodeToken(token);
  const rol = decodeNewToken
    ? typeof decodeNewToken["BIOMETRIA_ROLE"] === "string"
      ? [decodeNewToken["BIOMETRIA_ROLE"]]
      : decodeNewToken["BIOMETRIA_ROLE"]
    : [];

  return (
    <>
      <AdminUsers
        token={`Bearer ${token}`}
        appName={"biometria"}
        useSharedRoles={true}
        rolesByFilter={
          rol.includes("BIOMETRIA SUPERADMIN")
            ? ["BIOMETRIA SUPERADMIN"]
            : ["BIOMETRIA ADMIN"]
        }
        color={{
          primaryColor: principalColor,
          secondaryColor: secondaryColor,
          buttonColor: buttonColor,
          textButtonColor: buttonTextColor,
        }}
        personalizationColor={{
          primaryColor: principalColor,
          secondaryColor: secondaryColor,
          buttonColor: buttonColor,
          textButtonColor: buttonTextColor,
        }}
      />
    </>
  );
};
export default AdminUserView;
