import {
  Dialog,
  DialogContent,
  DialogProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import PageSegmentAtdp from "./PageSegmentAtdp";
import { useDispatch, useSelector } from "react-redux";
import { AtdpSelector, setCurrentHtml } from "../../store/slices/AtdpSlice";

type CreateAtdpFormProps = {
  isOpen: boolean;
  isClose: any;
  handleSuccessProp: any;
};

const CreateAtdpForm: React.FC<CreateAtdpFormProps> = ({
  isOpen,
  isClose,
  handleSuccessProp,
}) => {
  const classes = useStyles();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const { currentATDP } = useSelector(AtdpSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    decodeHtml(currentATDP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentATDP]);

  const decodeHtml = (Base64: any) => {
    if (Base64 !== undefined) {
      let nativeHtml = atob(Base64);
      dispatch(setCurrentHtml(nativeHtml));
    } else {
      dispatch(setCurrentHtml(undefined));
    }
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        className={classes.root}
        open={isOpen}
      >
        <DialogContent
          style={{ position: "relative", height: "100%", padding: "0px" }}
        >
          <PageSegmentAtdp isClosePage={isClose} isSave={handleSuccessProp} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateAtdpForm;

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  pageSegment: {},
}));
