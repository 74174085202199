import React from "react";
import {
  makeStyles,
  Typography,
  DialogTitle,
  styled,
  IconButton,
} from "@material-ui/core";
import { Theme, Dialog, DialogContent, DialogProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import imagenError from "../../assets/Imagen_Error.svg";
import { Slide, SlideProps } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) => ({
  text: {
    textAlign: "center",
    font: "normal normal bold 16px/24px Muli",
    letterSpacing: "0px",
    color: "#575757",
    marginBottom: "40px",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: 20,
  },
}));

interface Props {
  isOpen: boolean;
  isClose: any;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle style={{ margin: 0, padding: 2, height: "30px" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            color: "#009BDD",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

const DeviceAlert = ({ isOpen, isClose }: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const classes = useStyles();

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={isClose}
      TransitionComponent={Transition}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={isClose}
      ></BootstrapDialogTitle>
      <DialogContent>
        <div className={classes.imageContainer}>
          <img src={imagenError} style={{ width: 100 }} alt="imagenError" />
        </div>
        <Typography className={classes.text}>
          ¡El biometria desktop no se esta ejecutando! por favor verifica y
          vuelve a intentarlo.
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeviceAlert;
