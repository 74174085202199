import { Column } from "../../../components/Table/types";
import moment from "moment";

/**
 * @description Array de objetos que contienen los nombres y las configuraciones de las columnas para el Datatable de comparison
 * @return { Column[] } Column[]
 */
export const columns: Column[] = [
  {
    id: "fullName",
    label: "Nombres y Apellidos",
    minWidth: 135,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "sede",
    label: "Sede",
    minWidth: 135,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "documentNumber",
    label: "Documento",
    minWidth: 105,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "nut",
    label: "NUT",
    minWidth: 105,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "initialDate",
    label: "Fecha inicio",
    minWidth: 105,
    align: "center",
    haveDropDown: true,
    format: (value: string) => moment(value).format("YYYY-MM-DD H:mm:ss"),
  },
  {
    id: "process",
    label: "Trámite",
    minWidth: 135,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "typeStation",
    label: "Tipo Estación",
    minWidth: 115,
    align: "center",
    haveDropDown: true,
  },
  {
    id: "result",
    label: "Resultados (Hit / no hit)",
    align: "center",
    haveDropDown: true,
    minWidth: 135,
    component: {
      handleChange: (e: any, value: any) => {},
      tooltipMessage: "",
      withIcon: true,
      wordLength: 20,
      handleIconClick: () => {},
    },
  },
];
