import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  containerCaptcha: {
    padding: "6px",
    alignContent: "center",
  },
  captchaCard: {
    width: "100%",
  },
  captchaAction: {
    padding:"6px"
  },
  btnReload: {
    boxShadow: '0px 3px 3px #0000001A',
    border: '1px solid #D5D5D5',
    borderRadius: '10px',
    padding: '2px',
    '& span': {
      fontSize: '12px',
      font: 'normal normal normal Muli',
    },
  },
  successAlert: {
    color: "green",
  },
  errorAlert: {
    color: "red",
  }
}));