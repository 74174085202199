import { ChangeEvent, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  CitiesResponse,
  StatesAndCitiesResponse,
} from "../store/models/ListBasicResponse";
import { FieldsAdvanceSearch } from "../store/types";
import { filters } from "../utils/general/masks";
import DateTextField from "../components/Inputs/DateTextField";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonListSelector,
  setDocumentTypes,
  setEquipmentsType,
} from "../store/slices/CommonListsSlice";
import {
  getAllModels,
  getDeviceTypesByModel,
  getTypeComputerEquipments,
} from "../services/CommonListService";
import { DocumentType } from "../services/FingerprintService";
interface AdvanceSearchModalProps {
  fieldsAdvancedSearch?: Array<FieldsAdvanceSearch>;
  handleOnClose?: Function;
  handleOnSearch?: any;
  fieldsAdvanced?: Array<FieldsAdvanceSearch>;
  isExport?: any;
  statesAndCities?: StatesAndCitiesResponse<CitiesResponse>[];
}

interface IGenericSelector {
  id: number;
  name: string;
  value: any;
  subOptions?: IGenericSelector[];
}

/**
 * @description Hook que contiene los estados, useEffect y metodos que controlan el formulario de busqueda avanzada
 * @param { Array<FieldsAdvanceSearch> } param.fieldsAdvancedSearch
 * @param { Function } param.handleOnClose
 * @param { any } param.handleOnSearch
 * @param { Array<FieldsAdvanceSearch> } param.fieldsAdvanced
 * @param { any } param.isExport
 * @param { StatesAndCitiesResponse<CitiesResponse>[] } param.statesAndCities
 */
const useAdvanceSearchModal = ({
  fieldsAdvancedSearch,
  handleOnClose,
  handleOnSearch,
  fieldsAdvanced,
  isExport,
  statesAndCities,
}: AdvanceSearchModalProps) => {
  // Redux
  const { brands, documentTypes, equipmentTypes } =
    useSelector(CommonListSelector);
  const dispatch = useDispatch();

  /** Texto predefinido para configurar campos de fecha */
  const creationDate: string = "CreationDate";
  const modifiedDate: string = "ModificationDate";
  const nameCreationDate: string = "Fecha de creación";

  // Custom Constants
  const fill = (number: any, len: any) =>
    "0".repeat(len - number.toString().length) + number.toString();

  /** Fecha Formateada */
  const currentDate =
    new Date().getFullYear() +
    "-" +
    fill(new Date().getMonth() + 1, 2) +
    "-" +
    new Date().getDate();

  const CategoryListSelector: IGenericSelector[] = [
    { id: 1, name: "Sede", value: "Sede" },
    { id: 2, name: "SubSede", value: "SubSede" },
  ];
  const TypeStationListSelector: IGenericSelector[] = [
    { id: 1, name: "Estación móvil", value: "Estación móvil" },
    { id: 2, name: "Estación fija", value: "Estación fija" },
  ];
  const TypesDeviceSelector: IGenericSelector[] = [
    { id: 1, name: "USB - ESTACIONES FIJAS", value: "USB - ESTACIONES FIJAS" },
  ];

  // Styles
  const classes = useStyles();

  // Local States
  const [listCities, setListCities] = useState<CitiesResponse[]>([]);
  const [fieldsAdvancedSearchLocal, setFieldsAdvancedSearchLocal] =
    useState(fieldsAdvancedSearch);
  const [messageErrorsCreationDate, setMessageErrorsCreationDate] =
    useState<string>("");
  const [messageErrorsModifiedDate, setMessageErrorsModifiedDate] =
    useState<string>("");
  const [modelSelector, setModelSelector] = useState<any[]>([]);

  /**CUSTOM METHODS**/

  /** Metodo que cierra el formulario de busqueda avanzada */
  const onClose = () => {
    typeof handleOnClose === "function"
      ? handleOnClose()
      : console.warn("handleOnClose debe ser una función");
  };

  /** Metodo que limpia la informacion de el formulario */
  const onClearForm = () => {
    setMessageErrorsCreationDate("");
    setMessageErrorsModifiedDate("");
    setFieldsAdvancedSearchLocal(fieldsAdvanced);
    !isExport && handleOnSearch(fieldsAdvanced);
  };

  /** Metodo que lanza la busqueda avanzada */
  const executeSearch = () => {
    const getValueArray = (fieldName: string) => {
      const field = fieldsAdvancedSearchLocal?.find(
        (field) => field.name === fieldName
      );
      return field?.value ? field.value.toString().split(":") : ["", ""];
    };

    const valueStateCreationDate = getValueArray(creationDate);
    const valueStateModifiedDate = getValueArray(modifiedDate);

    const errorsCreationDate = validationDates(valueStateCreationDate);
    const errorsModifiedDate = validationDates(valueStateModifiedDate);

    if (errorsCreationDate === "") {
      setMessageErrorsCreationDate("");
    } else {
      setMessageErrorsCreationDate(errorsCreationDate);
    }
    if (errorsModifiedDate === "") {
      setMessageErrorsModifiedDate("");
    } else {
      setMessageErrorsModifiedDate(errorsModifiedDate);
    }

    if ((errorsCreationDate === "" && errorsModifiedDate === "") || isExport) {
      /* limpiar cadena de string en value de advance search para eliminar los espacios en blanco al final */
      handleOnSearch(
        fieldsAdvancedSearchLocal?.map((el) => {
          return {
            ...el,
            value: el.value?.toString().trim(),
          };
        })
      );
    }
  };

  /** Metodo de validacion las fechas */
  const validationDates = (dates: string[]) => {
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);
    let errors = "";

    if (!dates[0] && dates[1]) {
      errors +=
        "La fecha inicial está vacía. Debes ingresar una fecha inicial.";
    } else if (dates[0] && !dates[1]) {
      errors += "La fecha final está vacía. Debes ingresar una fecha final.";
    } else if (startDate > endDate) {
      errors += "La fecha inicial es mayor que la fecha final.";
    } else {
      const milisecondsPerDay = 1000 * 60 * 60 * 24;
      const daysDifference = Math.abs(
        (endDate.getTime() - startDate.getTime()) / milisecondsPerDay
      );

      if (daysDifference > 30) {
        errors += "El rango de fechas es mayor a 31 días.";
      }
    }

    return errors;
  };

  /** Metodo para obtener los mensajes de error de las fechas */
  const getErrors = (row: FieldsAdvanceSearch) => {
    return DateTextField({
      onChangeDate,
      currentDate,
      row,
      errors:
        row.title === nameCreationDate
          ? messageErrorsCreationDate
          : messageErrorsModifiedDate,
    });
  };
  /** Metodo que setea en los campos en los datos de busqueda avanzada en el estado local */
  const setValueField = ({
    fieldPass,
    valuePass,
    type,
    helperText,
  }: {
    fieldPass: string;
    valuePass: string | Date | boolean | number | undefined;
    type?: string;
    helperText?: string;
  }) => {
    setFieldsAdvancedSearchLocal(
      (prevState) =>
        prevState &&
        prevState.map((row) => {
          if (row.name === fieldPass) {
            let valueState: string[] = !!row.value
              ? row.value.toString().split(":")
              : ["", ""];
            const start = valueState[0];
            const finish = valueState[1];

            if (!!type) {
              if (type === "only") {
                return {
                  ...row,
                  value: `${valuePass || ""}`,
                };
              }

              if (
                (start.trim() === "" || finish.trim() === "") &&
                valuePass === ""
              ) {
                return {
                  ...row,
                  value: "",
                };
              }

              if (type === "st") {
                return {
                  ...row,
                  value: `${valuePass || ""}:${finish}`,
                };
              }
              if (type === "fn") {
                return {
                  ...row,
                  value: `${start}:${valuePass || ""}`,
                };
              }
            }

            return {
              ...row,
              value: valuePass,
              helperText,
            };
          } else {
            return {
              ...row,
            };
          }
        })
    );
  };

  /** Metodo para setear valor de el input del formulario */
  const onChangeText = (field: any, value: any) => {
    const accentuationList = ["á", "é", "í", "ó", "ú"];
    const alertAccentuation = accentuationList.includes(value[value.length - 1])
      ? "No se permiten tildes en el campo"
      : "";

    if (fieldsAdvancedSearchLocal != null) {
      setValueField({
        fieldPass: field,
        valuePass: filters(value),
        helperText: alertAccentuation,
      });
    }
  };

  /** Metodo generico que setea el valor de entrada */
  const onChangeGenericInput = (field: any) => {
    if (fieldsAdvancedSearch != null) {
      setValueField({
        fieldPass: field.target.name,
        valuePass: field.target.value,
      });
    }
  };

  /** Metodo que setea la ciudad*/
  const onChangeBranchCity = (field: any) => {
    const selectedBranch =
      !!statesAndCities &&
      statesAndCities.find((data) => data.id === field.target.value);
    if (fieldsAdvancedSearch != null) {
      setValueField({
        fieldPass: field.target.name,
        valuePass: field.target.value,
      });
    }
    selectedBranch && setListCities(selectedBranch.city);
  };

  /** Metodo que setea el estado*/
  const onChangeStatus = (field: string, checked: string) => {
    if (fieldsAdvancedSearchLocal != null) {
      setValueField({ fieldPass: field, valuePass: checked });
    }
  };
  /** Metodo que setea los campos de fecha*/
  const onChangeDate = (
    field: any,
    event: ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    const { value } = event.target;

    if (fieldsAdvancedSearch != null) {
      setValueField({ fieldPass: field, valuePass: value, type });
    }
  };

  /**END CUSTOM METHODS**/

  // SERVICIOS API

  /**
   * llama servicio para tipo de documento
   */
  const getListDocumentTypes = () => {
    DocumentType()
      .then((response) => {
        if (response.statusCode === 200) {
          const _arraySelector = response.result.records.map((item: any) => {
            return {
              id: item.idDocument,
              name: item.code,
              code: item.idDocument,
            };
          });
          const _arrayFiltered = _arraySelector.filter(
            (data) => data.id === 1 || data.id === 9
          );
          dispatch(setDocumentTypes(_arrayFiltered));
        }
      })
      .catch((err) => console.error(err));
  };

  /**
   * llama servicio para tipo de equipos
   */
  const getTypeComputerEquipment = () => {
    getTypeComputerEquipments()
      .then((response) => {
        if (response.statusCode === 200) {
          const _arraySelector = response.result.records.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
              code: item.id,
            };
          });
          dispatch(setEquipmentsType(_arraySelector));
        }
      })
      .catch((err) => console.error(err));
  };

  /**
   * llama servicio modelos por id de marca
   */
  const getModelsByBranchService = () => {
    getAllModels(0)
      .then((response) => {
        if (response.statusCode === 200) {
          setModelSelector(response.result.records);
        }
      })
      .catch((err) => console.error(err));
  };

  /***** END SERVICES ***************/

  // React hooks
  /** Se espera el cambio en el hook fieldsAdvanced para lanzar el action que ejecuta el servicio para busqueda avanzada */
  useEffect(() => {
    setFieldsAdvancedSearchLocal(fieldsAdvancedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsAdvancedSearch]);

  useEffect(() => {
    if (!documentTypes.length || !equipmentTypes.length) {
      getListDocumentTypes();
      getTypeComputerEquipment();
      getModelsByBranchService();
    }
  }, []);

  return {
    currentDate,
    onChangeGenericInput,
    onChangeText,
    onChangeBranchCity,
    onChangeStatus,
    onChangeDate,
    onClose,
    onClearForm,
    executeSearch,
    getErrors,
    fieldsAdvancedSearchLocal,
    classes,
    listCities,
    CategoryListSelector,
    documentTypes,
    equipmentTypes,
    TypeStationListSelector,
    TypesDeviceSelector,
    brands,
    modelSelector,
  };
};

/** Estilos en cascada de los inputs */
const useStyles = makeStyles((theme: any) => ({
  dialog: {
    position: "absolute",
    margin: "0 auto",
    overflow: "auto",
    padding: "15px",
  },
  dialogCompanySelector: {
    position: "absolute",
    left: 100,
    top: 200,
    width: 479,
    maxWidth: 479,
  },
  root: {
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 1,
    background: "#1D71B8 0% 0% no-repeat padding-box",
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  disabledButton: {
    background: "#1D71B8 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: "10px",
    opacity: 0.5,
    color: `${theme.palette.common.white} !important`,
  },
  dialogTitle: {
    height: 12,
    color: theme.palette.secondary.dark,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 40,
  },
  messageTypography: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
  hr: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.info.light,
    paddingTop: "1px",
  },
  datosTypography: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0,
  },
  containerDate: {
    paddingRight: theme.spacing(1),
    paddingTop: 20,
    paddingLeft: theme.spacing(1),
  },
  iconContainer: {
    marginLeft: "auto",
    cursor: "pointer",
  },
  classesButton: {
    width: "95%",
    margin: "1em 0",
  },
}));

export default useAdvanceSearchModal;
