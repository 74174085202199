import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginatorAuditLogAC,
  getAllAuditLogComparisonAC,
  getAllAuditLogUnsatisfactoryAnswerAC,
  getAllAuditLogConsultAC,
  getSortOptionAuditLogAC,
  getFilterAuditLogAC,
  getSortAuditLogAC,
  getAuditLogUnsatisfactoryAnswerCsvAC,
  getAuditLogConsultCsvAC,
  getAuditLogComparisonCsvAC,
} from "../store/action-creators/AuditLogActionCreators";
import { AuditLogSelector } from "../store/slices/AuditLogSlice";
import { CommonSelector, setPrivilege } from "../store/slices/CommonSlice";
import {
  AdvanceSearch,
  FieldsAdvanceSearch,
  sortBy,
  SortOptionLog,
} from "../store/types";

/**
 * Este Hook encapsula las funciones, useEffect y busqueda de la vista de audit logs
 * @param { string } nitCompany - representa el Numero de Identificacion Tributaria de la compañia
 */
const useAuditLog = (nitCompany?: string) => {
  const dispatch = useDispatch();
  const { privilege, rol } = useSelector(CommonSelector);
  const { paginatorAuditLog, auditLogComparison, size, loadingAuditLog } =
    useSelector(AuditLogSelector);

  /** Array de objetos que contienen los inputs para el formulario de busqueda avanzada */
  const fieldsAdvanced: FieldsAdvanceSearch[] = [
    {
      name: "Comparison",
      title: "Comparison",
      type: "text",
      value: "",
    },
    {
      name: "Nut",
      title: "Nut",
      type: "text",
      value: "",
    },
    {
      name: "CreationDate",
      title: "Fecha de creación",
      type: "date",
      value: undefined,
    },
  ];
  const [fieldsAdvancedSearch, setFieldsAdvancedSearch] =
    useState(fieldsAdvanced);
  const [showFormAdvAudit, setShowFormAdvAudit] = useState(false);
  const [advanceSearchState, setAdvanceSearchState] = useState(false);

  /**
   * Asegura que cada vez que entre al módulo el filtro este vacio,
   * en la primera página y el ordenado sea reciente
   */
  useEffect(() => {
    return () => {
      dispatch(getFilterAuditLogAC(""));
      dispatch(getSortAuditLogAC("recent"));
      dispatch(getPaginatorAuditLogAC(1, 10, true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!privilege?.length) {
      dispatch(setPrivilege(""));
    }
  }, [privilege, dispatch]);

  /** Se espera un cambio en el hook de paginatorAuditLog y se lanza el action respecto a la opcion del ordenado*/
  useEffect(() => {
    const params: AdvanceSearch = {
      fields: fieldsAdvancedSearch,
      paginator: paginatorAuditLog,
      company: nitCompany,
    };
    switch (paginatorAuditLog.sortOptionLog) {
      case "comparison":
        dispatch(getAllAuditLogComparisonAC(params));
        break;
      case "consult":
        dispatch(getAllAuditLogConsultAC(params));
        break;
      case "unsatisfactoryAnswer":
        dispatch(getAllAuditLogUnsatisfactoryAnswerAC(params));
        break;
      default:
        dispatch(getAllAuditLogComparisonAC(params));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorAuditLog]);

  /** Metodo para cambiar numero de pagina */
  const handleChangePage = (_event: any, newPage: any) => {
    dispatch(
      getPaginatorAuditLogAC(
        newPage + 1,
        paginatorAuditLog.paginator.itemsPage,
        true
      )
    );
  };

  /** Metodo para cambiar cantidad de registros por pagina y reiniciar el numero de pagina */
  const handleChangeRowsPerPage = (_event: any) => {
    dispatch(getPaginatorAuditLogAC(1, +_event.target.value, true));
  };

  /** Ordenar los resultados mediante la seleccion del sort option */
  const handleSortOptionLog = (sortLog?: SortOptionLog) => {
    if (sortLog) {
      setFieldsAdvancedSearch(fieldsAdvanced);
      dispatch(getSortOptionAuditLogAC(sortLog));
      dispatch(
        getPaginatorAuditLogAC(1, paginatorAuditLog.paginator.itemsPage, true)
      );
      dispatch(getFilterAuditLogAC(""));
      dispatch(getSortAuditLogAC("recent"));
    }
  };

  /** Metodo que lanza la busqueda basica y se posiciona en la primera pagina */
  const handleFilter = (query: string) => {
    setFieldsAdvancedSearch(fieldsAdvanced);
    dispatch(getFilterAuditLogAC(query.trim()));
    dispatch(
      getPaginatorAuditLogAC(1, paginatorAuditLog.paginator.itemsPage, true)
    );
  };
  /** Ordenar los resultados mediante la seleccion del sort */
  const handleSort = (sort?: sortBy) => {
    setAdvanceSearchState(false);
    if (sort === "active" || sort === "a-z") {
      dispatch(getSortAuditLogAC("recent"));
    } else if (sort === "inactive" || sort === "z-a") {
      dispatch(getSortAuditLogAC("old"));
    }
    dispatch(
      getPaginatorAuditLogAC(1, paginatorAuditLog.paginator.itemsPage, true)
    );
  };

  /** Metodo que lanza el action que con el servicio que descarga de audit log*/
  const onDownloadData = () => {
    const params: AdvanceSearch = {
      fields: fieldsAdvancedSearch,
      paginator: paginatorAuditLog,
      company: nitCompany,
    };
    switch (paginatorAuditLog.sortOptionLog) {
      case "comparison":
        dispatch(getAuditLogComparisonCsvAC(params));
        break;
      case "consult":
        dispatch(getAuditLogConsultCsvAC(params));
        break;
      case "unsatisfactoryAnswer":
        dispatch(getAuditLogUnsatisfactoryAnswerCsvAC(params));
        break;
    }
  };

  /** Metodo que ejecuta la busqueda avanzada de audit log y clasifica dependiendo de la opcion de sort seleccionada en el hook paginatorAuditLog.sortOptionLog */
  const onAdvanceSearch = (
    fieldsPassedAdvancedSearch: FieldsAdvanceSearch[]
  ) => {
    dispatch(getFilterAuditLogAC(""));

    const parameters: AdvanceSearch = {
      fields: fieldsPassedAdvancedSearch,
      paginator: {
        ...paginatorAuditLog,
        paginator: {
          page: 1,
          itemsPage: 10,
        },
      },
      company: nitCompany,
    };

    switch (paginatorAuditLog.sortOptionLog) {
      case "comparison":
        dispatch(getAllAuditLogComparisonAC(parameters));
        break;
      case "consult":
        dispatch(getAllAuditLogConsultAC(parameters));
        break;
      case "unsatisfactoryAnswer":
        dispatch(getAllAuditLogUnsatisfactoryAnswerAC(parameters));
        break;
    }
    setAdvanceSearchState(true);
    setShowFormAdvAudit(false);
    setFieldsAdvancedSearch(fieldsPassedAdvancedSearch);
    dispatch(getPaginatorAuditLogAC(1, 10, true));
  };

  const handleOnOpen = () => {
    setShowFormAdvAudit(true);
  };

  const handleOnClose = () => {
    setShowFormAdvAudit(false);
  };

  return {
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortOptionLog,
    handleFilter,
    handleSort,
    auditLogComparison,
    paginatorAuditLog,
    size,
    fieldsAdvancedSearch,
    setFieldsAdvancedSearch,
    onDownloadData,
    privilege,
    onAdvanceSearch,
    showFormAdvAudit,
    setShowFormAdvAudit,
    rol,
    handleOnOpen,
    handleOnClose,
    fieldsAdvanced,
    loadingAuditLog,
    advanceSearchState,
  };
};

export default useAuditLog;
