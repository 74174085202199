import { useDispatch, useSelector } from "react-redux";
import { DashboardSelector, setSwitchCompany } from "../store/slices/DashboardSlice";
import { useEffect } from "react";

const useDashboard = () => {
    // REDUX state
    const dispatch = useDispatch();

    const { switchCompany } = useSelector(DashboardSelector);

    const handleChangeSwitchCompany = (status: boolean) => {
      dispatch(setSwitchCompany(status));
    }

    return {
      switchCompany,
      handleChangeSwitchCompany
    }
}

export default useDashboard;