import { Company } from "../../../store/models/Company";
import { DataToken } from "../../../store/types";
import Admin from "../Admin";
import Grabber from "../Grabber";
import SuperAdmin from "../SuperAdmin";

export interface IMultiDashboard {
  dataToken: DataToken | undefined,
  currentCompany: Company,
  dashboardType: number | undefined,
  SwitchCompany: boolean;
  Role: string | undefined
}

export const MultiDashboard = ({dataToken, currentCompany, dashboardType, SwitchCompany, Role } : IMultiDashboard) => {

  switch (dashboardType) {
    case 1:
      return <Grabber />

    case 2:
      return <Admin
          DocumentType="nit"
          ServiceName="Biometria"
          DocumentNumber={currentCompany.documentnumber}
          Role={Role}
        />

    case 3:
      return <SuperAdmin SwitchCompany={SwitchCompany} Role={Role}/>

    default:
      return <></>
  }
};