import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import React from "react";
import { SelectFileButton } from "../button/SelectFileButton";
import EditableSection from "./EditableSection";
import OtherEditableSection from "./EditableSection";
import ActiveATDP from "./ActiveATDP";
import "./toolbar.css";
import { useSelector } from "react-redux";
import { AtdpSelector } from "../../store/slices/AtdpSlice";

type PageSegmentAtdpProps = {
  isClosePage: any;
  isSave: Function;
};

const PageSegmentAtdp: React.FC<PageSegmentAtdpProps> = ({
  isClosePage,
  isSave,
}) => {
  const classes = useStyles();
  const headerRef = React.createRef<any>();
  const footerRef = React.createRef<any>();
  const [activeHeader, setActiveHeader] = React.useState(true);
  const [activeFooter, setActiveFooter] = React.useState(true);
  const { currentHtmlATDP } = useSelector(AtdpSelector);
  const [currentSrc, setCurrentSrc] = React.useState<string | null>(null);

  let htmlHeader: string | null = null;
  let htmlFooter: string | null | undefined = null;
  let image: string | null = null;

  const handleCloseHeader = (evt: any, param?: any) => {
    const editor = headerRef.current.getEditor();
    let index = getLastIndex(editor);
    if (index !== null) {
      editor.insertText(
        index,
        ` ${param.toString().toUpperCase()} `,
        "bold",
        true
      );
    }
  };

  React.useEffect(() => {
    if (activeFooter) {
      setActiveHeader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFooter]);

  React.useEffect(() => {
    if (headerRef.current !== null) {
      insertHtml();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);

  const insertHtml = () => {
    if (currentHtmlATDP) {
      //header
      let tmp = document.createElement("html");
      tmp.innerHTML = currentHtmlATDP;
      if (tmp.getElementsByTagName("img")[0]) {
        let img = tmp.getElementsByTagName("img")[0];
        let src = img.src;
        setCurrentSrc(src);
        saveImage(src);
      }

      //borrarimagen
      let newCurrent = currentHtmlATDP.replace(/<img[^>]*>/g, "");
      let newHtml = document.createElement("html");
      newHtml.innerHTML = newCurrent;
      //cuerpo
      let body = newHtml.getElementsByClassName("body")[0];
      let divBody = body.getElementsByTagName("span")[0];

      let editor = headerRef.current.getEditor();
      const deltaBody = editor.clipboard.convert(divBody.outerHTML);
      editor.setContents(deltaBody, "silent");

      //footer
      let footer = newHtml.getElementsByClassName("footer")[0];
      let footerP = footer.getElementsByTagName("span")[0];
      let footerEditor = footerRef.current.getEditor();
      if (footerP) {
        const deltaFooter = footerEditor.clipboard.convert(footerP.outerHTML);
        footerEditor.setContents(deltaFooter, "silent");
      }
    }
  };

  const handleCloseFooter = (evt: any, param?: any) => {
    const editor = footerRef.current.getEditor();
    let index = getLastIndex(editor);
    if (index !== null) {
      editor.insertText(
        index,
        ` ${param.toString().toUpperCase()} `,
        "bold",
        true
      );
    }
  };

  const insertVariablesHeader = (param: any) => {
    const editor = headerRef.current.getEditor();
    let index = getLastIndex(editor);
    if (index !== null) {
      editor.insertText(
        index,
        ` ${param.toString().toUpperCase()} `,
        "bold",
        true
      );
    }
  };

  const insertVariablesFooter = (param: any) => {
    const editor = footerRef.current.getEditor();
    let index = getLastIndex(editor);
    if (index !== null) {
      editor.insertText(
        index,
        ` ${param.toString().toUpperCase()} `,
        "bold",
        true
      );
    }
  };

  let getLastIndex = function (editor: any) {
    editor.focus();
    let range = editor.getSelection(true);
    let index = range.index === 0 ? 0 : range.index;
    return index;
  };

  const handleQuillClickHeader = () => {
    setActiveFooter(true);
  };

  const handleQuillClickFooter = () => {
    setActiveFooter(true);
  };

  const saveImage = (param: any) => {
    image = param;
  };

  const updateHtml = () => {
    let editorHeader = headerRef.current.getEditor();
    htmlHeader = editorHeader.root.innerHTML;
    let editorFooter = footerRef.current.getEditor();
    htmlFooter = editorFooter.root.innerHTML;
    htmlFooter = htmlFooter?.replace('<span class="ql-cursor">﻿</span>', "");
  };

  return (
    <div className={classes.root}>
      <div style={{ padding: "0 24px" }}>
        <div className={classes.header}>
          <Grid container style={{ height: "100%" }}>
            <Grid
              container
              item
              lg={6}
              sm={6}
              md={6}
              xs={6}
              alignItems="center"
            >
              <SelectFileButton
                label="Click aqui para insertar logo"
                handleSaveImage={saveImage}
                setSrc={currentSrc}
                disabled={!activeHeader}
                accept=".jpg,.jpeg,.png"
              />
            </Grid>
            <Grid
              container
              item
              lg={6}
              sm={6}
              md={6}
              xs={6}
              justifyContent="flex-end"
            >
              <Grid
                item
                style={{ height: "50px" }}
                lg={12}
                sm={12}
                md={12}
                xs={12}
              >
                <div></div>
              </Grid>
              <Grid container item justifyContent="flex-end">
                <Typography className={classes.appLink}>
                  {"www.amazingLink.com"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "70%" }}>
                <Typography className={classes.advertisement}>
                  Esta es una copia impresa de un documento cuyo original es
                  electrónico, para consultar el documento original escanee el
                  código QR o haga clic en el vínculo.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <hr id="header-hr" />
        <div className={classes.body}>
          {!activeHeader ? (
            <ActiveATDP
              active={handleQuillClickHeader}
              label={"Click aquí para incluir contenido del ATDP"}
            />
          ) : (
            <EditableSection
              handleClose={handleCloseHeader}
              insertVariables={insertVariablesHeader}
              refProp={headerRef}
              sizeProp={"40vh"}
              user={{
                documentNumber: "{{No.cedula}}",
                name: "{{Nombres}}",
                surnames: "{{Apellidos}}",
                qrCode: "{{QRCODE}}",
              }}
              id="headerSection"
            />
          )}
        </div>
        <div id="footer-hr" />
        <div style={{ width: "80%", margin: "0 auto" }}>
          <div id="footer-bold" />
        </div>

        <div className={classes.footer}>
          {!activeFooter ? (
            <ActiveATDP
              active={handleQuillClickFooter}
              label={"Click aquí para incluir pie de página"}
            />
          ) : (
            <OtherEditableSection
              handleClose={handleCloseFooter}
              insertVariables={insertVariablesFooter}
              refProp={footerRef}
              sizeProp={"10vh"}
              user={{
                documentNumber: "{{No.cedula}}",
                name: "{{Nombres}}",
                surnames: "{{Apellidos}}",
                qrCode: "{{QRCODE}}",
              }}
              id="footerSection"
            />
          )}
        </div>
      </div>
      <div className={classes.modalFooter}>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            style={{
              border: "solid 1px #FFFFFF",
              color: "#FFFFFF",
              marginRight: "15px",
              boxShadow: "none",
            }}
            className={classes.buttonATDP}
            onClick={isClosePage}
          >
            Cancelar
          </Button>
          <Button
            className={
              activeHeader === false
                ? classes.buttonATDPDisabled
                : classes.buttonSaveATDP
            }
            disabled={activeHeader ? false : true}
            onClick={() => {
              if (activeFooter && activeHeader) {
                updateHtml();
                return isSave(htmlHeader, htmlFooter, image);
              }
            }}
          >
            Guardar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageSegmentAtdp;
const useStyles = makeStyles(() => ({
  root: {
    maxHeight: "100%",
    position: "relative",
  },
  header: {
    height: "120px",
    paddingRight: "1em",
    paddingLeft: "1em",
  },
  body: {
    height: "50vh",
  },
  footer: {
    height: "15vh",
  },
  logo: {
    width: "50%",
    height: "100%",
    backgroundColor: "white",
  },
  advertisement: {
    textAlign: "right",
    font: "normal normal normal 8px/11px Muli",
    letterSpacing: "0px",
    color: "#000000",
  },
  appLink: {
    textAlign: "right",
    textDecoration: "underline",
    font: "normal normal 600 9px/19px Muli",
    letterSpacing: "0px",
    color: "#009BDD",
  },
  modalFooter: {
    background: "#009BDD",
    height: "10vh",
    maxHeight: "53px",
    maxWidth: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonsContainer: {
    margin: "14px 20px",
    color: "#FFFFFF",
  },

  buttonATDP: {
    width: "94px",
    height: "26px",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 4px 3px #0000004D",
    font: "Muli-Bold",
  },

  buttonSaveATDP: {
    width: "94px",
    height: "26px",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 4px 3px #0000004D",
    font: "Muli-Bold",
    backgroundColor: "#FFFFFF",
    border: "none",
    color: "#1D71B8",
  },

  buttonATDPDisabled: {
    width: "94px",
    height: "26px",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0px 4px 3px #0000004D",
    font: "Muli-Bold",
    backgroundColor: "#77cbef",
  },
}));
