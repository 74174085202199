import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/es";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  instance,
  redirectToLogin,
  urlAuthentication,
} from "../services/AuthServices";
import { getCompanyByIdAC } from "../store/action-creators/CompanyActionCreators";
import { TIME_REFRESH_TOKEN, defaultHeaders } from "../config/config";
import { API_SECURITY_ENDPOINT } from "../config/const";
import { debounceded } from "../utils/general/utils";

const useEventListener = () => {
  const [currentCustomer, setCurrentCustomer] = useState<
    string | undefined | null
  >(undefined);
  const dispatch = useDispatch();

  /**verify function refresh or not depending token expiration
   * @function
   * */
  const verifyCurrentSession = () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const decodedToken: any = jwt_decode(jwtToken || "");
    const currentDate = moment({});
    const dateExpiresToken = moment.unix(Number(decodedToken.exp));

    validateConcurrentSession().then((res) => {
      if (res) {
        if (moment(dateExpiresToken).isAfter(currentDate) === false) {
          redirectToLogin();
        } else if (
          moment(dateExpiresToken).diff(currentDate, "seconds") <=
          TIME_REFRESH_TOKEN
        ) {
          let userName = decodedToken.unique_name;
          let refreshData = {
            RefreshToken: refreshToken,
            UserName: userName,
            AppName: "BIOMETRIA",
          };
          try {
            instance
              .post(
                urlAuthentication + "/api/Login/RefreshToken",
                refreshData,
                defaultHeaders()
              )
              .then((response) => {
                if (response.data.result.informationCode === "A10") {
                  sessionStorage.setItem(
                    "jwtToken",
                    response.data.result.token
                  );
                  sessionStorage.setItem(
                    "refreshToken",
                    response.data.result.refreshToken
                  );
                }
              })
              .catch((err) => {
                redirectToLogin();
              });
          } catch (err) {
            redirectToLogin();
          }
        }
      } else {
        redirectToLogin();
      }
    });
  };

  /**
   * validate concurrent session before refresh
   * @param {boolean} isPublic - if not need validation of concurrence
   */
  const validateConcurrentSession = (isPublic: Boolean = false) =>
    new Promise<Boolean>((resolve, reject) => {
      try {
        if (!!isPublic) resolve(true);
        else {
          try {
            instance
              .post(
                API_SECURITY_ENDPOINT + "/user/validateSession",
                {},
                defaultHeaders()
              )
              .then((response) => {
                if (response.data.result.informationCode === "A10") {
                  resolve(true);
                } else {
                  var delay = 5000;
                  setTimeout(function () {
                    redirectToLogin();
                  }, delay);
                  console.warn("error validacion sesion", response);
                  alert(
                    "Hemos cerrado tu sesión por motivos de seguridad, ya sea por inactividad o porque se ha detectado un inicio de sesión desde otro dispositivo."
                  );
                  resolve(false);
                }
              })
              .catch((err) => {
                var delay = 5000;
                setTimeout(function () {
                  redirectToLogin();
                }, delay);
                alert(
                  "Hemos cerrado tu sesión por motivos de seguridad, ya sea por inactividad o porque se ha detectado un inicio de sesión desde otro dispositivo."
                );
                resolve(false);
              });
          } catch (err) {
            redirectToLogin();
            resolve(false);
          }
        }
      } catch (err) {
        reject(true);
        redirectToLogin();
      }
    });

  // call function debounced 2 sec "agrupamiento"
  const validateSesion = debounceded(verifyCurrentSession, 2000);

  /**
   * Set current customer id for management reactivity
   * @function
   * @param {string} token - token to set in local state
   */
  const setCurrentCustomerId = (token: string) => {
    if (!!token && token !== currentCustomer) setCurrentCustomer(token);
  };

  /**
   * callback to action creator for set customer id
   * @function
   */
  const handleChangeClient = useCallback(() => {
    dispatch(getCompanyByIdAC());
  }, [dispatch]);
  // validate session
  function validateSessionIsActive() {
    validateSesion();
  }
  // REACT HOOKS

  // activate once the visor for user events
  useEffect(() => {
    const element = document.getElementsByTagName("body");

    validateConcurrentSession().then((res) => {
      if (!res) {
        redirectToLogin();
      } else {
        if (!!element) {
          element[0].addEventListener("mousemove", validateSessionIsActive);
          element[0].addEventListener("mousedown", validateSessionIsActive);
          element[0].addEventListener("keypress", validateSessionIsActive);
          element[0].addEventListener(
            "DOMMouseScroll",
            validateSessionIsActive
          );
          element[0].addEventListener("mousewheel", validateSessionIsActive);
          element[0].addEventListener("touchmove", validateSessionIsActive);
          element[0].addEventListener("MSPointerMove", validateSessionIsActive);
        }
      }
    });
  }, []);

  /**
   * reactivity to change client in local state for callback action creator
   */
  useEffect(() => {
    !!currentCustomer && handleChangeClient();
  }, [currentCustomer, handleChangeClient]);

  return { setCurrentCustomerId };
};

export default useEventListener;
