import axios, { AxiosResponse } from "axios";
import { defaultHeaders } from "../config/config";
import { BIOMETRICS_URL } from "../config/const";
import {
  IDataParticipant,
  IDocumentType,
  IBusinessUnitFinger,
  IFingerDevice,
} from "../store/models/FingerPrintModel";
import { ListBasicResponse } from "../store/models/ListBasicResponse";
import { ResultResponse, StandardResponse } from "../type";

const instance = axios.create({
  baseURL: BIOMETRICS_URL,
  headers: {
    "Content-Type": "application/json; charset=utf8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const RENEC_ENDPOINT = "FingerprintValidation/FingerprintValidation";
const DOCUMENTTYPE_ENDPOINT = "FingerprintValidation/DocumentType";
const BUSINESSUNITFINGER_ENDPOINT = "FingerprintValidation/BusinessUnit";
const FINGERDEVICE_ENDPOINT = "ComputerEquipment/GetFingerprintDevice";
const FINGERPRINT_JUSTIFICATION =
  "FingerprintValidation/ManualCaptureJustification";

export const ValidateParticipant = async (
  body: IDataParticipant
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(RENEC_ENDPOINT, body, defaultHeaders());
    if (!!response && response.data) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

export const DocumentType = async (): Promise<
  StandardResponse<ListBasicResponse<IDocumentType>>
> => {
  try {
    const response: AxiosResponse<
      StandardResponse<ListBasicResponse<IDocumentType>>
    > = await instance.get(DOCUMENTTYPE_ENDPOINT, defaultHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const BusinessUnitFinger = async (
  body: IBusinessUnitFinger
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(BUSINESSUNITFINGER_ENDPOINT, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

export const FingerDevice = async (
  body: IFingerDevice
): Promise<StandardResponse<ResultResponse>> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.post(FINGERDEVICE_ENDPOINT, body, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};

export const FingerprintJustification = async (): Promise<
  StandardResponse<ResultResponse>
> => {
  try {
    const response: AxiosResponse<StandardResponse<ResultResponse>> =
      await instance.get(FINGERPRINT_JUSTIFICATION, defaultHeaders());
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw response;
    }
  } catch (err) {
    throw err;
  }
};
