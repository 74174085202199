import {
  ValidateParticipant,
  DocumentType,
  BusinessUnitFinger,
  FingerDevice,
  FingerprintJustification,
} from "../../services/FingerprintService";
import { setError } from "../slices/CommonSlice";
import { AppThunk } from "../store";
import {
  IDataParticipant,
  IBusinessUnitFinger,
  IFingerDevice,
} from "../../store/models/FingerPrintModel";
import {
  setBusinessUnitFinger,
  setDocumentType,
  setFingerCapture,
  setFingerDevice,
  setFingerprintJustification,
  setLoadingFingerPrint,
} from "../slices/FingerPrintSlice";

/**
 *
 * @param body
 * Validate data fingerprint and save the fingerprint capture information
 */

export const setAndValidateDataParticipantAC =
  (body: IDataParticipant, enqueueSnackbar: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingFingerPrint(true));
    ValidateParticipant(body)
      .then((response: any) => {
        dispatch(setFingerCapture(response.result));
        if (response.result.informationCode.trim() !== "RNEC1") {
          enqueueSnackbar(response.result.information, {
            variant: "error",
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingFingerPrint(false)));
  };

export const GetDocumentTypeAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingFingerPrint(true));
  DocumentType()
    .then((response: any) => {
      if (response.statusCode === 200) {
        dispatch(setDocumentType(response.result.records));
      } else {
        dispatch(setError(response.result.information));
      }
    })
    .catch((err) => dispatch(setError(err)))
    .finally(() => dispatch(setLoadingFingerPrint(false)));
};

export const GetFingerprintJustificationAC = (): AppThunk => (dispatch) => {
  dispatch(setLoadingFingerPrint(true));
  FingerprintJustification()
    .then((response: any) => {
      if (response.statusCode === 200) {
        dispatch(setFingerprintJustification(response.result.records));
      } else {
        dispatch(setError(response.result.information));
      }
    })
    .catch((err) => dispatch(setError(err)))
    .finally(() => dispatch(setLoadingFingerPrint(false)));
};

export const setBusinessUnitFingerAC =
  (
    body: IBusinessUnitFinger,
    setErrorMessage: (message: string) => void,
    setBusinessAlert: (isAlertOpen: boolean) => void
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingFingerPrint(true));
    BusinessUnitFinger(body)
      .then((response: any) => {
        if (response.statusCode === 200) {
          dispatch(setBusinessUnitFinger(response.result));
        } else {
          setErrorMessage(response.statusMessage);
          setBusinessAlert(true);
          dispatch(setError(response.statusMessage));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingFingerPrint(false)));
  };

export const GetFingerDeviceAC =
  (
    body: IFingerDevice,
    enqueueSnackbar: any,
    disconnectClientInfo: Function
  ): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingFingerPrint(true));
    FingerDevice(body)
      .then((response: any) => {
        if (response.statusCode === 200) {
          dispatch(setFingerDevice(response));
        } else {
          sessionStorage.setItem("aes-encrypt", "0");
          dispatch(setFingerDevice(response));
          dispatch(setError(response.statusMessage));
          enqueueSnackbar(`${response.statusMessage}`, {
            variant: "error",
          });
          disconnectClientInfo();
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoadingFingerPrint(false)));
  };
