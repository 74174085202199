import { Theme, createTheme } from "@material-ui/core";

import { defaultPersonalization, valueOrDefault } from "./utils";
import gseBackground from "../../assets/gseBackground.jpg";
import { Palette } from "../theme/Palette";

/**
 * charge backgroud on app
 * @param {any} personalization - object personalization
 */
export function ChargeBackground(personalization: any) {
  if (personalization !== "") {
    const backgroundImage = personalization?.backgroundImage.blob
      ? `data:image;base64,${personalization?.backgroundImage.blob}`
      : undefined;
    const backgroundColor = valueOrDefault(
      personalization?.backgroundColor,
      "FFFFFE"
    );

    if (backgroundImage === undefined && backgroundColor === "FFFFFE") {
      document.body.style.backgroundImage = `url('${gseBackground}')`;
    }
    if (!!backgroundImage) {
      document.body.style.backgroundImage = `url('${backgroundImage}')`;
    }
    if (backgroundColor !== "FFFFFE") {
      document.body.style.backgroundColor = `${backgroundColor}`;
    }
  }
}

/**
 * charge backgroud on app
 * @param {object} logo  - object logo to change
 */
export function changeLogo(logo: {
  blob: string;
  name: string;
  path: null;
  extension: null;
}) {
  if (logo !== undefined) {
    var urllogo = "/assets/images/gseLogo.png";
    var logoElement = document.getElementById("companyLogo");
    if (logo.blob) {
      logoElement?.setAttribute("src", `data:image;base64,${logo.blob}`);
    } else {
      logoElement?.setAttribute("src", urllogo);
    }
  }
}

/**
 * Create and set personalization on state passed
 * @param {any} personalization - object personalization
 * @param setTheme - state to set personalization
 */
export function setPersonalization(
  response: any,
  setTheme: React.Dispatch<React.SetStateAction<Theme>>
) {
  setTheme(
    createTheme(
      Palette({
        logo: "/assets/gseBackground.jpg",
        isMobile: false,
        paleta: !!response?.data?.result?.entityDto
          ? response.data.result.entityDto
          : defaultPersonalization,
      })
    )
  );
  ChargeBackground(
    !!response?.data?.result?.entityDto
      ? response.data.result.entityDto
      : defaultPersonalization
  );
  // changeLogo(
  //   !!response?.data?.result?.entityDto?.logo
  //     ? response.data.result.entityDto.logo
  //     : "/assets/gseLogo.png"
  // );
}
