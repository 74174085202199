export const DRAWER_WIDTH = 300;
export const MESSAGES = {
  required: "Campo obligatorio *",
  max: "longitud sobrepasada maximo:",
  min: "longitud minima:",
  typeError: "el valor ingresado debe ser:",
  valueEntry: "El valor ingresado no corresponde a ",
};

export const Roles = Object.freeze({
  BIOMETRIA_ADMIN: "BIOMETRIA ADMIN",
  BIOMETRIA_RNEC: "BIOMETRIA RNEC",
  BIOMETRIA_SUPERADMIN: "BIOMETRIA SUPERADMIN",
  BIOMETRIA_CAPTURADOR: "BIOMETRIA CAPTURADOR",
  BIOMETRIA_CAPTURADOR_MOVIL: "BIOMETRIA CAPTURADOR MOVIL",
  BIOMETRIA_CAPTURA_MOVIL: "BIOMETRIA CAPTURA MOVIL",
  BIOMETRIA_INTEGRACION: "BIOMETRIA INTEGRACION",
});
