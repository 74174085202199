export const DRAWER_WIDTH = 240;

export const months = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SEP",
  "OCT",
  "NOV",
  "DIC",
];
/**
 * Constante que devuelve el nombre de los modulos en plural y minuscula.
 */
export const moduleNamesPluralMin = {
  businessUnit: "sedes",
  device: "dispositivos",
  computerEquipment: "equipos",
  typeprocess: "tipos de tramite",
};
