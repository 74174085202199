import {
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";

type InputRoundedProperties = {
  label: string;
  options: Array<SelectOption>;
  ref?: any;
  props?: TextFieldProps;
  name: string;
  control: any;
  rules?: any;
  onChange?: Function;
};

type SelectOption = {
  key: number | string;
  value: string | number;
  label: string;
};

export const SelectInputRounded = ({
  label,
  options,
  control,
  name,
  rules,
  onChange: onChangeFunction,
  props,
}: InputRoundedProperties) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange: customChange, value } }) => {
        // eslint-disable-next-line
        let optionFind = options.find((op) => op.value == value);
        return (
          <Autocomplete
            id={name}
            options={options}
            value={optionFind || null}
            getOptionLabel={(option) => option.label || ""}
            getOptionSelected={(option, valueNew) => {
              return option.value === valueNew.value;
            }}
            onChange={(e, valueChange) => {
              e.preventDefault();
              const flag = valueChange?.value;
              if (onChangeFunction) onChangeFunction(flag);
              customChange(valueChange?.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                value={optionFind?.label || null}
                fullWidth
                label={label}
                variant="filled"
                error={props?.error}
                helperText={props?.helperText}
              />
            )}
            classes={{
              root: classes.root,
            }}
            noOptionsText="No hay opciones"
            disabled={props?.disabled}
            disableClearable={value !== null}
            style={{ color: "primary" }}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#FFFFFF !important",

    "& .MuiFilledInput-root": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 3px #0000001A",
      border: "1px solid #D5D5D5",
      borderRadius: "10px",
      opacity: 1,
      margin: 2,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.light,
    },
    "& .MuiInputBase-input": {
      textAlign: "left",
      font: "normal normal normal 16px/17px Muli",
      letterSpacing: "0px",
      // color: "#575757",
      opacity: 1,
    },
    "& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled": {
      color: theme.palette.primary.light,
    },
    "& .MuiFilledInput-underline.Mui-disabled:before,& .MuiFilledInput-underline.Mui-disabled:after, & .MuiFilledInput-underline:before,& .MuiFilledInput-underline:after ":
      {
        borderBottomStyle: "none",
      },
  },
  selectDisabled: {
    opacity: "0.8 !important",
  },
}));
